import { Component, Inject, Input, inject } from '@angular/core';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { SmartTableComponent } from '@components/smart-table/smart-table.component';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { BaseStepComponent } from '../test';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-review-direct-reports',
  standalone: true,
  imports: [SmartTableComponent, AvatarComponent],
  templateUrl: './review-direct-reports.component.html',
  styleUrl: './review-direct-reports.component.scss'
})
export class ReviewDirectReportsComponent extends BaseStepComponent {
  userDic = inject(UserService).managedOrgUserDictionaryByOrgUserID;
  manager: IOrgUser;
  members: IOrgUser[];
  settings = {
    actions: false,
    pager: {
      display: false
    },
    columns: {
      user: {
        ...inject(SmartTableColumnsService).getColumns(['user'], {local: true}).user,
        title: '',
        sort: false,
        filter: false
      }
    }
  };
  source = new LocalDataSource();

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.manager = this.userDic[this.allValues.manager];
    this.source.load(orderBy(this.allValues.members.map(id => this.userDic[id]), ['status', 'lastName']));

  }
}
