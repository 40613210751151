import { Subject } from 'rxjs';
import { BaseInjectorConstructor } from './clearRoute';

export type Constructor<T>= new(...args: any[]) => T;


export function WithDestroy<TBase extends BaseInjectorConstructor>(Base: TBase = class {} as any) {
    return class extends Base {
      destroy$ = new Subject();

      ngOnDestroy() {
        if (super.ngOnDestroy) {
          super.ngOnDestroy();
        }
        this.destroy$.next();
        this.destroy$.complete();
      }
    };
}
