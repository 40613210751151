import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IOrgAssign } from 'src/app/services/org-assign.service';
import { IOrgUser } from 'src/app/services/user.service';
import { mandatoryRecordSectionComponent } from './mandatory-record-section/mandatory-record-section.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-side-panel-compliance-record-section',
    templateUrl: 'side-panel-compliance-record-section.component.html',
    styleUrls: ['side-panel-compliance-record-section.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, mandatoryRecordSectionComponent]
})

export class SidePanelComplianceRecordSectionComponent implements OnInit {
    @Input() group: any;
    @Input() data: IOrgAssign[];
    @Input() orgActivityID: string;
    @Input() user: IOrgUser;
    @Input() topMargin = 80;
    mandatoryRecords: IOrgAssign[];
    optionalRecords: IOrgAssign[];
    optionalNum: number;
    mandatoryNum: number;


    constructor(
    ) { }

    ngOnInit() {
      this.mandatoryRecords = this.data.filter(r => r.complianceType !== 'Optional');
      this.optionalRecords = this.data.filter(r => r.complianceType === 'Optional');
      this.optionalNum = this.optionalRecords.length;
      this.mandatoryNum = this.mandatoryRecords.length;
    }
}
