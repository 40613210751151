import {GroupService} from '../../../../services/group.service';
import {PolicyFromBackend, SharedPPData} from '../../interfaces/policy-and-procedure.interface';
import * as dayjs from 'dayjs';
import {ResourceService} from '../../../../services/resource.service';

export default function generatePolicyEventTrackField(rawPolicy: PolicyFromBackend, groupService: GroupService, resourceService: ResourceService, localSharedData?: SharedPPData) {
  try {
    const jobRoleTags = rawPolicy.governanceVersion?.relevancy?.jobRoles?.map
    ((jobRoleID) => groupService.globalJobRolesDict[jobRoleID]?.title ?? '') || [];
    const standardTags = rawPolicy.governanceVersion?.relevancy?.standards?.map
    ((standardID) => resourceService?.guidelineDict[standardID]?.name ?? '') || [];
    const topicTags = (rawPolicy.governanceVersion?.relevancy?.topics ?? [])
        .map((topicID) => resourceService?.topicDictionary[topicID]?.name ?? '');
    return {
      title: rawPolicy.title,
      itemID: rawPolicy.code,
      createDate: rawPolicy.createDate,
      reviewDate: dayjs(rawPolicy.reviewDate).diff(dayjs(), 'day'),
      additionalDocuments: rawPolicy.governanceVersion.governanceDocs?.length ?? (localSharedData?.linkedFormsEntities?.length ?? 0),
      jobRoleTags,
      standardTags,
      topicTags,
    };
  }
  catch (e) {
    console.error(e);
    return null;
  }
}
