import { Pipe, PipeTransform } from '@angular/core';
import {AlertDisplayState} from '../animations/couse-builder-menu-animations';

@Pipe({
    name: 'showAlertStatus',
    standalone: true
})
export class ShowAlertStatusPipe implements PipeTransform {

  transform(show?: boolean): AlertDisplayState {
    return show ? 'open' : 'closed';
  }

}
