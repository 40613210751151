<div class="card p-4 card-hover" appHoverToggle=".hover-container" (click)="openFeedbackModal($event, feedbackModal)">
    <p class="f-small m-feedback-content mb-2">{{plainFeedback()}}</p>
    <p class="f-caption text-neutral-700">Source: {{feedback().source}}</p>
    <p class="f-caption text-neutral-700">Added by: {{addedBy()}}</p>
    <p class="f-caption text-neutral-700">Date Added: {{dateAdded()}}</p>
</div>

<ng-template #feedbackModal let-modal>
  <ui-modal-single
      heading="Feedback"
      [templateModalInstance]="modal">
      <ng-template #contentSlot>
        <div class="mb-2" [innerHTML]="feedback().feedbackContent"></div>
        <p class="f-caption text-neutral-700">Source: {{feedback().source}}</p>
        <p class="f-caption text-neutral-700">Added by: {{addedBy()}}</p>
        <p class="f-caption text-neutral-700">Date Added: {{dateAdded()}}</p>
      </ng-template>

      <ng-template #rightSlot>
        <button class="btn btn-outline-light" (click)="modal.close()">Close</button>
    </ng-template>
    </ui-modal-single>
</ng-template>
