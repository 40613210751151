import { Component, computed, inject, Input, signal } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AnalyticsPrService } from 'src/app/pages/performance-review/analytics-pr.service';
import { ITablePerformanceReviewAssign, PerformanceReviewService } from 'src/app/services/performance-review.service';
import { ConfirmationModalComponent } from 'src/app/shared/popups/confirmation-modal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-task-remind-modal',
  standalone: true,
  imports: [ConfirmationModalComponent],
  templateUrl: './task-remind-modal.component.html',
  styleUrl: './task-remind-modal.component.scss'
})
export class TaskRemindModalComponent {
  task = signal<ITablePerformanceReviewAssign>(null);
  @Input() context = 'my tasks';


  prService = inject(PerformanceReviewService);
  analyticsPRService = inject(AnalyticsPrService);

  // request$ = computed(() => of(1).pipe(delay(1000)))
  request$ = computed(() => this.prService.taskRemind([this.task().taskID]).pipe(
    tap(() => this.analyticsPRService.sendTrack('PR-task-reminder-sent', {
      reminderType: 'single',
      ...this.analyticsPRService.getTaskInfo(this.task(), this.context),
    }))
  ))


}
