// UserID
export const ALL_TAB_ON_USER_MANAGEMENT_TABLE_USER_LIST = [
  'ae1ba951-2274-43bc-b7af-598a2e8e010d', // irtlms - iris.dsouza@respect.com.au
  '78a498fd-50f7-4d14-b1e4-4eda6b8485f1', // (staging for testing) Eddy
]

export function isUserIncluded(list: string[]){
  const userID = localStorage.getItem('apc_user_id');
  return list.includes(userID);
}
