import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { combineLatest, switchMap } from 'rxjs/operators';
import { ComplianceDateType } from 'src/app/pages/reporting/reporting-type';
import { ITrainingPlan, MandatoryTrainingService } from 'src/app/services/mandatory-training.service';
import { OrgAssignService } from 'src/app/services/org-assign.service';
import { IOrgSGReportMTAssignQueryForm, ReportLearningRecordService } from 'src/app/services/report-learning-record.service';
import { getTotalCompletedPercent } from 'src/app/shared/utils/calculations/completed-percent';
import { removeEmptyReturn } from 'src/app/shared/utils/remove-empty-fields';
import { pluck } from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class UserComplianceService {

  assignService = inject(OrgAssignService);
  reportService = inject(ReportLearningRecordService);
  mtService = inject(MandatoryTrainingService);
  plans$ = inject(MandatoryTrainingService).fetchCachedPlans().pipe(map(plans => pluck(plans, 'mtPlanID')));

  getAssignCompletionByPlan(userID: string): Observable<CompletionByPlan[]> {
    if (!userID) {
      return of([]);
    }
    return this.plans$.pipe(
      switchMap(plans =>
        this.reportService.fetchAssignsByUserIDsGroupByPlan(
          ...this.getAssignParams(userID, plans)
        )
      ),
      map(grouped => {
        return grouped.map(g => ({
          completion: getTotalCompletedPercent(g),
          plan: this.mtService.globalPlanDict[g.planID]
        }))
      })
    );
  }

  getNonCompliantAssigns(userID: string) {
    if (!userID) {
      return of([]);
    }
    return this.plans$.pipe(
      switchMap(plans =>
        this.reportService.fetchAssignsForTable(
          ...this.getAssignParams(userID, plans)
        )
      )
    );
  }

  getAssignParams(userID, plans): [start: string, end: string, payload: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, params: HttpParams] {
    return ['',
      '',
      {
        filteredUsers: [userID],
        filteredStatuses: ['Incomplete', 'Overdue'],
        filteredPlans: plans
      },
      ComplianceDateType.dueDate,
      new HttpParams({
        fromObject: removeEmptyReturn({
          complianceTypes: 'Compliance',
          assignType: 'Plan,Manual',
        })
      })
    ]
  }
}

export interface CompletionByPlan {
  completion: number;
  plan: ITrainingPlan
}