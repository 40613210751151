import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { EvidenceService } from 'src/app/services/evidence.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationModalComponent } from 'src/app/shared/popups/confirmation-modal/confirmation-modal/confirmation-modal.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router } from '@angular/router';
import { UISpinnerComponent } from '../../../../ui/ui-spinner/ui-spinner.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-evidence-actions-component',
    template: `
      <span placement="left auto" [ngbTooltip]="!isDisableButton ? '' : 'You do not have permission to delete this evidence'"
        [container]="'body'"
      >
          <button class="btn btn-outline-primary f-small btn-sm me-2" style="background: white !important;"
              (click)="deleteEvidence()"
              [disabled]="isDisableButton"><span *ngIf="submitting">
                  <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
              </span> Delete
          </button>
      </span>
    `,
    styleUrls: ['evidence-actions.component.scss'],
    standalone: true,
    imports: [
        NgbTooltip,
        NgIf,
        UISpinnerComponent,
    ],
})

export class EvidenceActionsComponent implements OnInit {
    @Input() rowData;
    @Input() orgActivityID;
    @Input() userID;
    isDisableButton = true;
    submitting = false;
    constructor(
      private userService: UserService,
      private evidenceService: EvidenceService,
      private modalService: NgbModal,
      private analytics: AnalyticsService,
      private router: Router,
    ) { }

    ngOnInit() {
      this.userService.getOrgUser().pipe(take(1)).subscribe(
        user => {
          this.isDisableButton = !user.isManager && (user.userID != this.rowData.addedBy)
        }
      )
    }

    deleteEvidence() {
      const modal = this.modalService.open(ConfirmationModalComponent, {size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'm-top-modal-class' }); //'m-top-modal-class' is in style.scss
      modal.componentInstance.modalHeading = 'Delete evidence?';
      modal.componentInstance.content = 'You are about to permanently this evidence. This action cannot be reversed. Please confirm the selection.';
      modal.componentInstance.buttonText = 'Delete';
      modal.componentInstance.toasterText = 'Evidence deleted';
      modal.componentInstance.request$ = this.evidenceService.deleteEvidence(this.orgActivityID, this.userID, this.rowData.noteID);
      modal.result.then(res => {
        if (res) {
          const context = this.analytics.getContext(this.router.url);
          this.analytics.track('L-record-evidence_deleted', {
            context: context,
            ...(context === 'reporting' ? {reportName: this.evidenceService.getEventReportName()} : {})
          })
          this.evidenceService.recordsDataRefresh.next(this.orgActivityID);
        }
      });
    }
}
