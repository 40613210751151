<app-multi-select-container [selectedItems]="selectedItems" [availableItems]="tableSource.count()"
    [shownItems]="shownItems" (selectAll)="onSelectAll()" (deselectAll)="onDeselectAll()" [itemName]="itemName"
    [tableID]="divID" [inCard]="inCard" [hideDefaultText]="hideDefaultText" [hideBulkAction]="hideBulkAction">
    <ng-container buttons>
        <ng-content></ng-content>
    </ng-container>

    <app-smart-table class="sticky-multi-select" [ngClass]="smartTableClasses" [settings]="tableSettings" [downloadSettings]="downloadSettings"
        [source]="tableSource" (userRowSelect)="onRowSelect($event)" [stickyLeft]="stickyLeft" [stickyRight]="stickyRight" [tableDiv]="divID"></app-smart-table>


</app-multi-select-container>
