<ui-modal-single [heading]="modalHeading" [scrollable]="!alwaysHideTable">
    <ng-template #contentSlot>
        <div [ngClass]="{'scroll-area': !alwaysHideTable}">
            <div class="my-5">
                <ng-container [ngSwitch]="action">
                    <p class="pb-4" *ngSwitchCase="'deactivate'">You are about to <span class="fw-semibold">{{ action }} {{ UserNum }} {{ initUserForm }}</span>
                         from your organisation’s account on Ausmed. Deactivating users means they will:</p>
                    <ng-container *ngSwitchCase="'send reminders'">
                        <div class="pb-4" *ngIf="UserNum === 1">
                            <p class="mb-4">You are about to <span class="fw-semibold">send {{ singleUserName }} a reminder</span>
                                to accept their invitation to join your organisation on Ausmed. 
                            </p>
                            <p> Do not send reminder if the user has already been sent a reminder in the last
                                    <input type="number" class="col-1 form-control d-inline-flex" id="skipDay" name="skipDay" [(ngModel)]="skipDay" min="0"> days. 
                            </p>
                        </div>
                       <div class="pb-4" *ngIf="UserNum > 1">
                            <p class="pb-4">You are about to <span class="fw-semibold">{{ action }} to {{ UserNum }} {{ initUserForm }}</span>
                                    to accept their invitation to join your organisation on Ausmed. 
                            </p>
                            <p>
                                Do not send reminder if the user has already been sent a reminder in the last
                                <input type="number" class="col-1 form-control d-inline-flex" id="skipDay" name="skipDay" [(ngModel)]="skipDay" min="0"> days. 
                            </p>
                       </div>
                       
                        <p class="mb-4">
                            If you would also like to send SMS reminders please contact Manager Support. Requests usually take 1-2 business days to process. 
                            <a (click)="trackRequestSMSEvent()" class="link">
                                <p class="fw-semibold d-inline-flex">Request SMS
                                    <ui-streamline-icon [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'sm'}" class="ps-1 sl-icon-pb-1px"></ui-streamline-icon>
                                </p>
                            </a>
                        </p>       
                    </ng-container>
                    
                    <p class="pb-4" *ngSwitchCase="'delete'">You are about to <span class="fw-semibold">{{ action }} {{ UserNum }} {{ initUserForm }}</span>
                         from your organisation’s account on Ausmed. By deleting a user:</p>
                    <div *ngSwitchCase="'invite'">
                        <p class="pb-4" *ngIf="UserNum === 1">You are about to <span class="fw-semibold">{{ action }} {{ singleUserName }}</span>
                            to join your organisation on Ausmed.
                        </p>
                        <p class="pb-4" *ngIf="UserNum > 1">You are about to <span class="fw-semibold">{{ action }} {{ UserNum }} {{ initUserForm }}</span>
                            to join your organisation on Ausmed.
                        </p>
                        <p class="pb-4">
                            If you would also like to send SMS invitations please submit a request to  Manager Support. Requests usually take 1-2 business days to process. 
                            <a (click)="trackRequestSMSEvent()" class="link">
                                <p class="fw-semibold d-inline-flex">Request SMS
                                    <ui-streamline-icon [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'sm'}" class="ps-1 sl-icon-pb-1px"></ui-streamline-icon>
                                </p>
                            </a>
                        </p>
                    </div>
                    <p class="pb-4" *ngSwitchCase="'reinvite'">You are about to <span class="fw-semibold">send invitation{{ pluralS }} to {{ UserNum }} {{ initUserForm }}</span>
                         to join your organisation on Ausmed.</p>
                         
                    <!-- Team/job role management -->

                    <p class="pb-4" *ngSwitchCase="'addToTeam'">You are about to <span class="fw-semibold">add {{ subjectUser }}</span> to
                        <span class="fw-semibold">{{ groupAdded.name }}.</span> Please confirm the selection.</p>
                    <p class="pb-4" *ngSwitchCase="'removeFromTeam'">
                        You are about to <span class="fw-semibold">remove {{ subjectUser }}</span> from
                        <span class="fw-semibold">{{ groupAdded.name }}.</span> Once removed, 
                        {{UserNum > 1 ? "these users won't" : 'this user will no longer be'}} be included in any reporting for this team.
                    </p>
                    <p class="pb-4" *ngSwitchCase="'addToJobRole'">You are about to <span class="fw-semibold">add {{ subjectUser }}</span> to
                        <span class="fw-semibold">{{ groupAdded.title }}.</span> Please confirm the selection.</p>
                    <p class="pb-4" *ngSwitchCase="'removeFromJobRole'">
                        You are about to <span class="fw-semibold">remove {{ subjectUser }}</span> from
                        <span class="fw-semibold">{{ groupAdded.title }}.</span> Once removed, 
                        {{UserNum > 1 ? "these users won't" : 'this user will no longer be'}}
                        be included in any reporting for this job role.
                    </p>

                    <p *ngSwitchCase="'send ack reminders'" class="pb-4">
                        <span *ngIf="UserNum > 1">
                            You are about to send {{selectedUsers?.length}} users a reminder to acknowledge {{policyDisplay}}. 
                        </span>
                        <span *ngIf="UserNum === 1">
                            <span *ngIf="!numNonactive">
                                You are about to send {{singleUserName}} a reminder to acknowledge {{policyDisplay}}. 
                            </span>
                            <span *ngIf="numNonactive">
                                You are about to send a reminder to 1 user to acknowledge {{policyDisplay}}. 
                            </span>
                        </span>
                        <span *ngIf="!UserNum">
                            <span *ngIf="numNonactive === 1">Reminder can't be sent because the user you selected hasn't activated their account yet.</span>
                            <span *ngIf="numNonactive > 1">Reminders can't be sent because the users you selected haven't activated their account yet.</span>
                        </span>

                    </p>
                </ng-container>
                <ul *ngFor="let bulletPoint of bulletPoints">
                    <li class="f-paragraph pb-2">{{ bulletPoint }}</li>
                </ul>

                <ui-alert-banner *ngIf="numNonactive && UserNum" [type]="'Warning'" [copy]="alertCopy"
                    [iconSettings]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-warning'}"
                    ></ui-alert-banner>
    
                
                <div *ngIf="!alwaysHideTable" class="my-4">
                    <span *ngIf="hideTable"
                        (click)="hideTable = !hideTable" class="text-neutral-900 f-paragraph fw-medium underline cursor-pointer">
                        <ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size:'xs'}" class="f-paragraph me-2"></ui-streamline-icon>
                        View {{ UserNum }} {{ initUserForm }}
                    </span>
                     <span *ngIf="!hideTable" (click)="hideTable = !hideTable"
                         class="text-neutral-900 f-paragraph fw-medium underline cursor-pointer">
                         <ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-up', theme: 'micro-bold', size:'xs'}" class="f-paragraph me-2"></ui-streamline-icon>
                         Hide {{ initUserForm }}
                     </span>
                </div>
     
                 <ng-container *ngIf="!hideTable">
                     <div class="table-wrap mt-4">
                         <ng2-smart-table
                             [settings]="tableSettings"
                             [source]="tableSource"
                         ></ng2-smart-table>
                     </div>
                 </ng-container>    
     
    
            </div>

            <div *ngIf="errorMessage" class="text-danger f-small mb-4">{{errorMessage}}</div>
        </div>
    </ng-template>

    <ng-template #rightSlot>
        <button type="button" class="btn btn-outline-light" [disabled]="isSubmitting" (click)="onCancel()">{{UserNum ? 'Cancel': 'Close'}}</button>
        <button [disabled]="isSubmitting" *ngIf="(action === 'send reminders' || action === 'send ack reminders') && UserNum"
            type="button" class="btn btn-secondary" (click)="confirmAction()">
            <span *ngIf="isSubmitting" style="color: inherit">
                <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            Send Reminder{{ pluralS }}
        </button>
        <button [disabled]="isSubmitting" *ngIf="action === 'invite' || action === 'reinvite'" type="button" class="btn btn-secondary" (click)="confirmAction()">
            <span *ngIf="isSubmitting" style="color: inherit">
                    <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
             </span>
            Send Invitation{{ pluralS }}
        </button>
        <button *ngIf="action === 'deactivate'" type="button" class="btn btn-danger" (click)="confirmAction()"
            [disabled]="isSubmitting"><span *ngIf="isSubmitting" style="color: inherit">
                <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
</span>Deactivate
        </button>
        <button *ngIf="action === 'delete'" type="button" class="btn btn-danger" (click)="confirmAction()"
            [disabled]="isSubmitting"><span *ngIf="isSubmitting" style="color: inherit">
           <ui-spinner size="sm" class="me-1" color="light"></ui-spinner></span>Delete
        </button>

        <!-- group management -->
        <ng-container *ngIf="isManagingGroup">
            <button *ngIf="action.startsWith('add')" type="button" class="btn btn-secondary" (click)="confirmGroupEdit()"
                [disabled]="isSubmitting"><span *ngIf="isSubmitting" style="color: inherit">
               <ui-spinner size="sm" class="me-1" color="light"></ui-spinner></span>Add User{{pluralS}} to {{groupType}}
            </button>
            <button *ngIf="action.startsWith('remove')" type="button" class="btn btn-danger" (click)="confirmGroupEdit(false)"
                [disabled]="isSubmitting"><span *ngIf="isSubmitting" style="color: inherit">
               <ui-spinner size="sm" class="me-1" color="light"></ui-spinner></span>Remove from {{groupType}}
            </button>
        </ng-container>
        

    </ng-template>



</ui-modal-single>

