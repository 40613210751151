import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { HttpClient } from '@angular/common/http';
import { IOrgUser } from './user.service';
import {Observable} from 'rxjs';

export interface IOrgBulkSendInvitationsResult {
  message: string;
  updatedOrgUsers?: IOrgUser[];
  skippedOrgUsers?: IOrgUser[];
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
      private http: HttpClient,
      private customErrorHandler: ErrorHandlingService,
  ) {
  }

  sendOnboardingEmail(orgID: string, user: any, skipDay?: number) {
    let url = '';
    if (user.invitation) {
      url = `${environment.accountServiceEndpoint}/orgs/${orgID}/orgUsers/${user.orgUserID}/sendInvitation`;
      if (skipDay) {
        url = url + `?skipDay=${skipDay}`;
      }
    } else {
      if (!!user.userID) {
        url = `${environment.accountServiceEndpoint}/orgs/${orgID}/users/${user.userID}/sendOnboarding`;
      }
    }

    return this.http
        .get(url)
        .pipe(
            tap(() => {
              try {
                (window as any).Appcues.track('send-invitation', {
                  invitation: {
                    fullName: user.fullName,
                    staffID: user.staffID,
                    email: user.orgEmail || user.email,
                  }
                });
              } catch (e) {
                console.log(e);
              }
              console.log({
                fullName: user.fullName,
                staffID: user.staffID,
                email: user.orgEmail || user.email,
              });
            }),
            catchError(this.customErrorHandler.handleHttpError),
        );
  }

  inviteOrganisationUser(orgId: string, userId: string) {
    return this.http
        .get(environment.accountServiceEndpoint + '/orgs/' + orgId + '/users/' + userId + '/invite')
        .pipe(
            catchError(this.customErrorHandler.handleHttpError),
        )
        .toPromise();
  }

  bulkInvitation(skipDays: number = 0, orgUserIDs: string[] = []): Observable<IOrgBulkSendInvitationsResult> {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/invite?skipDay=${skipDays}`;
    return this.http.post<IOrgBulkSendInvitationsResult>(url, orgUserIDs)
        .pipe(catchError(this.customErrorHandler.handleHttpError));
  }
}
