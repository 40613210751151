import { Component, input } from '@angular/core';

@Component({
  selector: 'app-progress-bar-basic',
  standalone: true,
  imports: [],
  templateUrl: './progress-bar-basic.component.html',
  styleUrl: './progress-bar-basic.component.scss'
})
export class ProgressBarBasicComponent {
  percentage = input(0)
}
