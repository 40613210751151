import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, share, tap, map, delay, concatMap, toArray } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { cloneDeep } from 'lodash';
import { BroadcastService } from './broadcast.service';
import { IComplianceRecord } from './report-learning-record.service';
import { OrganisationService } from './organisation.service';
import { Dictionary, indexBy, pluck } from 'underscore';
import { EvidencePayloadType } from './evidence.service';
import { DataCacher } from '../shared/utils/class/DataCacher';
import { COMPLIANCE_TYPE } from '../pages/mandatory-training/ITrainingPlan';

export interface IMTDueExtensionForm {
  manager: string;
  createDate: string;
  assignDate: string;
  dueDate: string;
}

export const LOCAL_CACHE_KEY_UNSCHEDULED_ITEM = 'unscheduled_items';

export interface IBulkMTAssignMarkAsDoneOrSkippedResult {
  message: string;
  updatedAssigns?: IComplianceRecord[];
}

export interface IMTDueExtension extends IMTDueExtensionForm {
  orgID?: string;
  mtPlan?: string;
  extensionID?: string;
  mtSchedule?: string;
  assignee?: string;
  remindDate?: string;
}

export interface IPlanUser {
  mtPlanUserID: string;
  mtPlan: string;
  orgUser: string;
  userID: string;
  complianceStatus: 'Compliant' | 'Non-Compliant';
  last12mComplianceStat: ILast12mStat;
  enrolDate: string;
}

export interface ILast12mStat {
  totalNum: number;
  scheduledNum: number;
  assignedNum: number;
  overdueNum: number;
  completedNum: number;
  doneNum: number;
  skippedNum: number;
  incompleteNum: number;
  compliantRate: number;

}

export interface IMTBulkExtensionForm {
  manager: string;
  assignIDs: string[];
  extensionDuration?: string;
  extensionDate?: string;
  notifyAssignees?: boolean;
}

export interface IMTBulkExtension {
  message: string;
  updatedAssigns?: any[];
  skippedAssigns?: any[];
}

export type MTScheduleType = 'Induction' | 'Once' | 'Recurring';
export type ResourceProviderType = 'Ausmed' | '3rd Party' | 'Internal';

/* All the | string are added to the compatibility concern */
export interface OrgMTScheduleForm {
  scheduleType: MTScheduleType | string;
  resourceTitle: string;
  resourceMin: number | string;
  resourceType: string;
  resourceID: string;
  providerType: ResourceProviderType | string;
  order?: number;
  jobRoles?: string[];
  startDate?: string;
  endDate?: string;
  dueDay?: number;
  dueMonth?: number;
  scheduleDuration?: string;
  assignDuration?: string;
  earlyRecogDuration?: string;
  lateRecogDuration?: string;
}

export interface OrgMTUnfinishedScheduleToPlansForm {
  planIDs: string[];
  scheduleType: MTScheduleType | string;
  resourceTitle: string;
  resourceMin: number | string;
  resourceType: string;
  resourceID: string;
  providerType: ResourceProviderType | string;
  order?: number;
  jobRoles?: string[];
  startDate?: string;
  endDate?: string;
  dueDay?: number;
  dueMonth?: number;
  scheduleDuration?: string;
  assignDuration?: string;
  earlyRecogDuration?: string;
  lateRecogDuration?: string;
}

export interface OrgMTScheduleToPlanForm {
  planID: string;
  scheduleType: MTScheduleType | string;
  resourceTitle: string;
  resourceMin: string;
  resourceType: string;
  resourceID: string;
  providerType: ResourceProviderType | string;
  order?: number;
  jobRoles?: string[];
  startDate?: string;
  endDate?: string;
  dueDay?: number;
  dueMonth?: number;
  scheduleDuration?: string;
  assignDuration?: string;
  earlyRecogDuration?: string;
  lateRecogDuration?: string;
}


export interface OrgMTScheduleFormPostBackPayload extends OrgMTScheduleForm {
  mtScheduleID: string;
  mtPlan: string;
}

export interface CacheScheduleLocal {
  [key: string]: OrgMTScheduleFormPostBackPayload[];
}

export interface TrainingPlanTemplateSchedule {
  scheduleID: string;
  templateID: string;
  scheduleType: string;
  resourceID: string;
  providerType: string;
  scheduleDuration?: string;
  assignDuration?: string;
  earlyRecogDuration?: string;
  lateRecogDuration?: string;
  resource?: any;
}

export interface TrainingPlanTemplate {
  templateID: string;
  name: string;
  createDate?: string;
  updateDate?: string;
  status: string;
  createdBy?: string;
  description?: string;
  schedules?: TrainingPlanTemplateSchedule[];
  coverImageURL?: string;
}
@Injectable({
  providedIn: 'root'
})

export class MandatoryTrainingService {
  mtPlans = new BehaviorSubject(null);
  private pendingMtPlans: boolean;
  globalPlanDict: Dictionary<ITrainingPlan>;

  private planCacher = new DataCacher<ITrainingPlan[]>(this.getPlans(localStorage.getItem('orgID')), this.broadcastService);
  private scheduleCacher = new DataCacher<ITrainingSchedule[]>(this.getAllSchedules(), this.broadcastService);

  trainingPlanTemplates = undefined;

  constructor(
    // private userService: UserService,
    // private auth: AuthService,
    private http: HttpClient,
    private customErrorHandler: ErrorHandlingService,
    private broadcastService: BroadcastService,
    private orgService: OrganisationService
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.mtPlans.next(null);
      this.pendingMtPlans = false;
    });
  }

  getPlanUsers(orgID, planID): Observable<IPlanUser[]> {
    return this.http.get<IPlanUser[]>(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/users')
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  addPlan(orgID, plan) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans', plan)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  copyPlan(orgID, planID, plan) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/copy', plan)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  deletePlan(orgID, planID) {
    return this.http
      .delete(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }


  getPlans(orgID) {
    return this.http
      .get<ITrainingPlan[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans`)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
        tap(plans => this.globalPlanDict = indexBy(plans, 'mtPlanID')),
        // map((plans) => plans.filter(p => p.status !== 'Active'))
      );
  }

  fetchCachedPlans(refresh = false) {
    return this.planCacher.fetchCachedData(refresh)
  }


  getPlanStats(orgID): Observable<IPlanWithStats[]> {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlanStats')
      .pipe(
        // map((plans: any[]) => plans.filter(p => p.plan.status !== 'Active')),
        tap((plans: any) => {
          this.globalPlanDict = indexBy(pluck(plans, 'plan'), 'mtPlanID');
          this.mtPlans.next(plans);
        }),
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  getPlanStatsByID(orgID, planID) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlanStats/' + planID)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  getPlanById(orgID, planID) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  updatePlan(orgID, plan) {
    return this.http
      .put(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + plan.mtPlanID, plan)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  getSchedulesByPlan(orgID, planID) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/schedules')
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  getAllSchedules() {
    return this.http
    .get<ITrainingSchedule[]>(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/schedules')
    .pipe(
      catchError(this.customErrorHandler.handleHttpError),
    );
  }

  fetchCachedAllSchedules(refresh = false) {
    return this.scheduleCacher.fetchCachedData(refresh);
  }


  addSchedule(orgID, planID, schedule) {
    // empty array will cause backend error
    if (schedule.jobRoles && schedule.jobRoles.length < 1) {
      delete schedule.jobRoles;
    }
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/schedules', schedule)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
        tap(() => this.orgService.saveAppcueEvent('appcues-plan-item-added'))
      );
  }

  addTypedSchedule(planID: string, scheduleForm: OrgMTScheduleForm): Observable<OrgMTScheduleFormPostBackPayload> {
    const orgID = localStorage.getItem('orgID');
    return this.http.post<OrgMTScheduleFormPostBackPayload>(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/schedules`, scheduleForm)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  addUnfinishedScheduleToPlans(scheduleToPlansForm: OrgMTUnfinishedScheduleToPlansForm): Observable<OrgMTScheduleFormPostBackPayload> {
    const orgID = localStorage.getItem('orgID');
    return this.http.post<OrgMTScheduleFormPostBackPayload>(`${environment.accountServiceEndpoint}/orgs/${orgID}/scheduleToPlans`, scheduleToPlansForm)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  addFinishedItemToPlans(scheduleForms: OrgMTScheduleToPlanForm[]): Observable<OrgMTScheduleFormPostBackPayload[]> {
    const orgID = localStorage.getItem('orgID');
    return this.http.post<OrgMTScheduleFormPostBackPayload[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/schedules`, scheduleForms)
      .pipe(catchError(this.customErrorHandler.handleHttpError),
        tap(() => this.orgService.saveAppcueEvent('appcues-plan-item-added'))
      );
  }

  updateSchedule(orgID, schedule) {
    // empty array will cause backend error
    const s = cloneDeep(schedule);
    if (s.jobRoles && s.jobRoles.length < 1) {
      delete s.jobRoles;
    }
    delete s.assigns;
    return this.http
      .put(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + schedule.mtPlan + '/schedules/' + schedule.mtScheduleID, s)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }
  // For bulk updates, if the mtScheduleID is empty or didn’t match with mtScheduleID will create new schedules instead.
  bulkUpdateSchedules(orgID, mtPlanID, schedules) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + mtPlanID + '/schedules/bulk', schedules)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  deleteSchedule(orgID, schedule) {
    return this.http
      .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${schedule.mtPlan}/schedules/${schedule.mtScheduleID}`)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  enrolUsersToPlan(orgID, planID, userIDs) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/enrol', userIDs)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  enrolIndividualUsersToPlan(orgID, planID, users) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/enrolAll', users)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  unenrolUsersToPlan(orgID, planID, userIDs) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/unenrol', userIDs)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  deleteTypedSchedule(schedule: Partial<ITrainingSchedule>): Observable<{ message: string }> {
    const orgID = localStorage.getItem('orgID');
    return this.http
      .delete<{ message: string }>(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${schedule.mtPlan}/schedules/${schedule.mtScheduleID}`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  unenrolPlanByOrgUserIDs(planID: string, orgUserIDs: string[]) {
    if (!planID || !orgUserIDs?.length) {
      throw TypeError('Unenrol planID or orgUserIDs cannot be empty');
    }
    const orgID = localStorage.getItem('orgID');
    return this.http
      .post(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/unenrol`, { orgUserIDs })
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  processPlan(orgID, planID) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/process')
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  getAssignsByPlan(orgID, planID) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/assigns')
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  // not used. Replaced by bulk API
  getAssignByID(orgID, planID, assignID): Observable<IComplianceRecord> {
    return this.http
      .get<IComplianceRecord>(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/assigns/' + assignID)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  getAssignsByIDs(assignIDs: string[]) {
    return this.http.post<IComplianceRecord[]>(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/assigns/byIDs', assignIDs)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  // unused
  getAssignsByUser(orgID: string, userID: string) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/user/${userID}/mtAssigns`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  addNote(orgID, planID, note) {
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/mtPlans/' + planID + '/notes', note)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  // still use old mtAssign API because only used in training plan pages
  markAsDone(orgID, planID, assignID) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/assigns/${assignID}/markAsDone`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  bulkMarkAsDone(assignIDs: string[]): Observable<IBulkMTAssignMarkAsDoneOrSkippedResult> {
    const orgID = localStorage.getItem('orgID');
    return this.http.post<IBulkMTAssignMarkAsDoneOrSkippedResult>(`${environment.accountServiceEndpoint}/orgs/${orgID}/assigns/markAsDone`, assignIDs)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  bulkMarkAsDoneWithNote(assignIDs: string[], evidencePayload: EvidencePayloadType, hasEvidence: boolean = false) {
    const orgID = localStorage.getItem('orgID');
    const payload = {
      assignIDs: assignIDs,
      ...(hasEvidence ? { note: evidencePayload } : {}),
    }
    return this.http.post<IBulkMTAssignMarkAsDoneOrSkippedResult>(`${environment.accountServiceEndpoint}/orgs/${orgID}/assigns/markAsDoneWithNote`, payload)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  bulkMarkAsSkipped(assignIDs: string[]): Observable<IBulkMTAssignMarkAsDoneOrSkippedResult> {
    const orgID = localStorage.getItem('orgID');
    return this.http.post<IBulkMTAssignMarkAsDoneOrSkippedResult>(`${environment.accountServiceEndpoint}/orgs/${orgID}/assigns/markAsSkipped`, assignIDs)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  bulkDeleteManualAssigns(assignIDs: string[]) {
    const orgID = localStorage.getItem('orgID');
    return from(assignIDs)
      .pipe(
        concatMap(assignID =>
          this.http.delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/assigns/${assignID}`).pipe(
            map(() => assignID)
          )
        ),
        toArray(),
        tap(res => console.log(res)),
        catchError(this.customErrorHandler.handleHttpError));
  }

  // not used
  markAsSkipped(orgID, planID, assignID) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/assigns/${assignID}/markAsSkipped`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }


  // still used for plan assigns
  bulkDueExtension(payload: IMTBulkExtensionForm) {
    return this.http.post<IMTBulkExtension>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/extensions`, payload)
      .pipe(
        map(r => {
          return {
            ...r,
            updatedAssigns: r.updatedAssigns?.map(a => ({ ...a, assignID: a.assignID }))
          };
        }),
        catchError(this.customErrorHandler.handleHttpError));
  }

  // for now, use for manual only
  bulkExtendManual(payload: IMTBulkExtensionForm) {
    return this.http.post<IMTBulkExtension>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/assignExtension`, payload)
      .pipe(catchError(this.customErrorHandler.handleHttpError));

  }

  deleteDueExtension(orgID: string, planID: string, extensionID: string): Observable<{ message: string }> {
    return this.http.delete<{ message: string }>(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/extensions/${extensionID}`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  // Not used
  getAssigneeDueExtensions(orgID: string, planID: string, scheduleID: string, assigneeID: string): Observable<IMTDueExtension[]> {
    return this.http.get<IMTDueExtension[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/schedules/${scheduleID}/assignees/${assigneeID}/extensions`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  // Not used
  dueDateExtensionReminder(orgID: string, planID: string, assignID: string, extensionID?: string) {
    const rearPiece = extensionID ? `email?extensionID=${extensionID}` : 'email';
    return this.http.get<IMTDueExtension[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/assigns/${assignID}/${rearPiece}`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  // Not used
  // sendReminders(orgID: string, planID: string, assignIDs: IOrgMTAssignReminderForm) {
  //   const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/mtPlans/${planID}/remindAssigns`;
  //   return this.http.post(url, assignIDs).pipe(
  //     catchError(this.customErrorHandler.handleHttpError),
  //   );
  // }

  sendReminders(orgID: string, payload: IOrgMTAssignReminderForm) {
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/remindAssigns`;
    return this.http.post<any[]>(url, (payload)).pipe(
      catchError(this.customErrorHandler.handleHttpError),
    );
  }


  // unused
  fetchAssignsByUser(orgID: string, userID: string): Observable<any> {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/user/${userID}/mtAssigns`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  fetchUserPlans(userID): Observable<IOrgMTPlanUser[]> {
    return this.http.get<IOrgMTPlanUser[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/mtPlanUsers`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  fetchOrgUserPlans(orgUserID: string): Observable<IOrgMTPlanUser[]> {
    return this.http.get<IOrgMTPlanUser[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgUsers/${orgUserID}/mtPlanUsers`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  fetchSchedulesByResource(resourceID) {
    return this.http.get<IOrgMTPlanUser[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/resources/${resourceID}/schedules`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  updateScheduleOrder(planID, data) {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/mtPlans/${planID}/schedules/orders`;
    return this.http.post(url, data).pipe(
      catchError(this.customErrorHandler.handleHttpError),
    );
  }

  getTrainingPlanTemplates(): Observable<TrainingPlanTemplate[]> {
    if (!this.trainingPlanTemplates) {
      const url = `${environment.resourceReadApiHost}/trainingPlanTemplates/active`;
      return this.http.get<TrainingPlanTemplate[]>(url).pipe(
        map(templates => {
          const tp = templates.map(t => {
            const schedules = t.schedules.filter(s => s.resource && s.resource?.status === 'Published' && (!s.resource?.expiryDate || (s.resource?.expiryDate && new Date(s.resource?.expiryDate) >= new Date())));
            return { ...t, schedules }
          });
          return tp.filter(t => t.schedules && t.schedules.length > 0)
        }),
        tap(templates => this.trainingPlanTemplates = templates),
        catchError(this.customErrorHandler.handleHttpError),
      );
    } else {
      return of(this.trainingPlanTemplates);
    }
  }

  getTrainingPlanTemplateByID(templateID): Observable<TrainingPlanTemplate> {
    const url = `${environment.resourceReadApiHost}/trainingPlanTemplates/${templateID}`;
    return this.http.get<TrainingPlanTemplate>(url).pipe(
      map((template: TrainingPlanTemplate) => {
        const schedules = template.schedules.filter(s => s.resource && s.resource?.status === 'Published' && (!s.resource?.expiryDate || (s.resource?.expiryDate && new Date(s.resource?.expiryDate) >= new Date())));
        return { ...template, schedules };
      }),
      catchError(this.customErrorHandler.handleHttpError),
    );
  }


  public fetchedCacheMtPlans(refresh?: boolean): Observable<any> {
    if (refresh || (!this.mtPlans.value && !this.pendingMtPlans)) {
      this.pendingMtPlans = true;
      const fetch$ = this.getPlanStats(localStorage.getItem('orgID')).pipe(
        tap(() => this.pendingMtPlans = false),
        catchError((e) => {
          this.pendingMtPlans = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.mtPlans.pipe(filter(x => !!x)); // emit pending / cached value (wihout null and only once)
  }

  getTeamComplianceStats(): Observable<ITeamStats[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/mtTeamPlanStats`;
    return this.http.get<ITeamStats[]>(url).pipe(
      catchError(this.customErrorHandler.handleHttpError),
    );

  }
}

export interface ITrainingPlan {
  complianceType?: COMPLIANCE_TYPE;
  title: string;
  mtPlanID?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  status?: string;
  orgUsers?: string[];
  startDateUTC?: string;
  endDateUTC?: string;
  orgID?: string;
}

export interface IPlanWithStats {
  plan: ITrainingPlan;
  complianceRate?: number;
  scheduleNum?: number;
  enrolledNum?: number;
  jobRoles?: any[];
}

export interface ITrainingSchedule {
  mtScheduleID?: string;
  mtPlan: string;
  scheduleType: string;
  resourceID: string;
  resourceTitle?: string;
  resourceType?: string;
  resourceMin?: string;
  providerType: string;
  jobRoles?: string[];
  startDate?: string;
  endDate?: string;
  dueDay?: number;
  dueMonth?: number;
  scheduleDuration?: string;
  assignDuration?: string;
  earlyRecogDuration?: string;
  lateRecogDuration?: string;
  resource?
}

export interface IJobRoleEstimatedTime {
  jobRole?: string;
  estimatedMins?: string;
}

export interface UTC {
  assignDateUTC: string;
  dueDateUTC: string;
  earlyRecogDateUTC: string;
  lateRecogDateUTC: string;
  completeDateUTC?: string;
}

export interface IOrgMTAssignReminderForm {
  assignIDs: string[];
  channelType?: ReminderChannelType;
}

export type ReminderChannelType = 'email' | 'push' | 'both'

export interface IOrgMTPlanUser {
  mtPlanUserID: string;
  mtPlan: string;
  orgUser: string;
  userID: string;
  complianceStatus?: string; // Complied, Not Complied, Not Available
  nextDueDate?: string;
  enrolDate: string;
}

export interface IPlanUsage {
  planID: string;
  enrolledUserNum: number;
  planTitle: string;
}

export interface ITeamStats {
  achievementRate: number;
  enrolledNum: number;
  last12mComplianceStat: ILast12mStat;
  teamID: string;
  planID: string;
  activeEnrolledNum: number;
}
