import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { keyBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import { ACK_STATUS, PolicyFromBackend } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { GroupService } from 'src/app/services/group.service';
import { MakeColumnStickyService } from 'src/app/services/make-column-sticky.service';
import { SmartTableService } from 'src/app/services/smart-table.service';
import { UserService } from 'src/app/services/user.service';
import { removeEmptyFields } from 'src/app/shared/utils/remove-empty-fields';
import { environment } from 'src/environments/environment';
import { clone, pluck, uniq } from 'underscore';
import { IPPAckQueryForm } from '../pp-all-ack-records.component';
import { ackBackendToTableFn, PPServerDataSource } from './PPServerDataSource';
import { SmartTableComponent } from '@components/smart-table/smart-table.component';
import { SharedTableActionsComponent } from '@components/shared-table-actions/shared-table-actions.component';
import { MultiSelectTableComponent } from '@components/multi-select-container/multi-select-table/multi-select-table.component';
import { IAckTableData } from 'src/app/pages/policies-procedures/policies/pp-detail/pp-detail-ack/pp-detail-ack.component';
import { BulkStaffModalComponent, IBulkPoliciedData } from 'src/app/shared/popups/bulk-staff-modal/bulk-staff-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PolicyProcedureApiService } from 'src/app/pages/policies-procedures/services/policy-procedure-api.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { PnpService } from 'src/app/services/pnp.service';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
/**
 * for unack table, it uses local table since bulk action is needed
 */
@Component({
  selector: 'app-pp-server-table',
  templateUrl: './pp-server-table.component.html',
  styleUrls: ['./pp-server-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SmartTableComponent,
    SharedTableActionsComponent,
    MultiSelectTableComponent
  ]
})
export class PpServerTableComponent extends WithDestroy() {

  @Input() tableChange: IPPAckQueryForm;
  @Input() tableSettings;

  @Input() policies: PolicyFromBackend[];
  @Input() stickyLeft = true;
  @Input() hideTableActions = false;
  @Output() numResults = new EventEmitter();


  // endPoint = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/all?_sort=-assignLocalDate`;
  endPoint = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/all?`;

  tableSource: PPServerDataSource<any>;
  localTableSource = new LocalDataSource();
  subscription: Subscription;
  queries: IPPAckQueryForm;

  // table actions
  refreshFlag = true;
  downloading: boolean;
  finalPayload: IPPAckServersidePayload;
  finalEndpoint: string;
  destroy$ = new Subject();

  selectedItems: IAckTableData[];

  constructor(
    private groupService: GroupService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private _toaster: ToastrService,
    private _cdr: ChangeDetectorRef,
    private smartTableService: SmartTableService,
    private stickyColumn: MakeColumnStickyService,
    private modalService: NgbModal,
    private ppAPIService: PolicyProcedureApiService,
    private pnpService: PnpService
  ) { super() }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.pnpService.ackUpdate$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(_ => this.loadLocalTable(this.queries));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableChange) {
      const payload = clone(changes.tableChange.currentValue) as IPPAckQueryForm
      if(!payload.filteredGovs) {
        payload['filteredGovs'] = uniq(this.policies.map(p => p.governanceID));
      }
      if(!payload.filteredStatuses) {
        payload['filteredStatuses'] = Object.values(ACK_STATUS);
      }  
      this.queries = payload;
      removeEmptyFields(this.queries);
      const { globalJobRolesDict, globalTeamsDict } = this.groupService;

      if (this.tableSettings.selectMode) {
        this.loadLocalTable(this.queries)
        return;
      }
      this.tableSource = new PPServerDataSource<IPPAckQueryForm>(this.http, this.queries,
        { endPoint: this.endPoint },
        {
          groupService: this.groupService,
          userService: this.userService,
          policyDict: this.policyDict,
        });
      this.subscription?.unsubscribe();
      this.subscription = this.tableSource.governData.pipe()
        .subscribe((governData) => {
          this.spinner.hide('sgr-spinner');
          this.numResults.emit(governData.numResult);
          this.finalPayload = governData.finalPayload;
          this.finalEndpoint = governData.finalEndpoint;
          // this.stickyColumn.floatSingleColumn({ fromLeft: true, includingHeader: true, tableID: 'table-ack-records' });
          setTimeout(() => {
            if (!governData.hasResult) {
              changeLoadingTextAsync();
            }
          }, 2000);
        });
    }
  }

  loadLocalTable(queries: IPPAckQueryForm) {
    this.ppAPIService.getAllAcks(queries).pipe(
      map(acks => ackBackendToTableFn(this.userService.managedOrgUserDictionaryByOrgUserID, this.policyDict)(acks))
    )
    .subscribe(data => {
      this.localTableSource.load(data)
      this.spinner.hide('sgr-spinner');
      this.numResults.emit(data.length);
    })
  }

  // for unack only
  sendAckReminder() {
    const selectedItems: IAckTableData[] = this.selectedItems;
    const numPolicies = uniq(pluck(selectedItems, 'governanceID')).length;
    const policies: IBulkPoliciedData = numPolicies === 1 ? { name: selectedItems[0].fn_title } : { num: numPolicies };
    policies.ackIDs = pluck(selectedItems, 'ackID');
    const activeModal: NgbModalRef = this.modalService.open(BulkStaffModalComponent, { size: 'lg' });
    activeModal.componentInstance.action = 'send ack reminders';
    activeModal.componentInstance.selectedUsers = uniq(selectedItems.map(i => i.orgUser?.orgUserID)).map(id => this.userService.managedOrgUserDictionaryByOrgUserID[id]);
    activeModal.componentInstance.policies = policies;
    activeModal.result.then(res => {
      if (res) {
        this.loadLocalTable(this.queries);
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.destroy$.next();
  }

  resetSettings(settings) {
    this.tableSettings = settings;
  }

  downloadReport(settings) {
    const fileName = 'Acknowledgement Records';
    this.downloading = true;
    const downloadURL = this.finalEndpoint.replace(/&_page=(\d*)/g, '').replace(/&_limit=(\d*)/g, '');

    this.smartTableService.downloadServerTable(downloadURL, this.finalPayload, fileName,
      { ...settings.columns },
      ackBackendToTableFn(this.userService.managedOrgUserDictionaryByOrgUserID, this.policyDict)
    ).subscribe(() => {
      this.downloading = false;
      this._toaster.success(`Downloaded file`);
      this._cdr.markForCheck();
    });
  }

  get policyDict() {
    return keyBy(this.policies, 'governanceID');
  }
}

export interface IPPAckServersidePayload {
  filteredUsers: string[];
  filteredStaffIDs: string[];
}

export function changeLoadingTextAsync() {
  const emptyNodes = document.querySelectorAll('td');
  const loadingNodes = Array.from(emptyNodes).filter(node => node.innerText === 'Loading');
  if (loadingNodes.length) {
    loadingNodes.forEach(node => {
      node.innerText = 'No Data Found';
      node.classList.add('no-data');
    });
  }
}



