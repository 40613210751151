import { inject, Injectable } from '@angular/core';
import {  intersection, pluck, uniq } from 'underscore';
import { LearningGroupType } from '../reporting-type';
import { CATEGORY_ENUM } from './interfaces';
import { IOrgUser, USER_STATUS, UserService } from 'src/app/services/user.service';
import { GroupService } from 'src/app/services/group.service';
import { FacilitiesService } from 'src/app/services/facilities.service';

@Injectable({
  providedIn: 'root'
})
export class FilterPackService {
  userService = inject(UserService);
  groupService = inject(GroupService);
  facilitiesService = inject(FacilitiesService);

  constructor() { }

  getUsersFromFilter(filterBy: CATEGORY_ENUM, userStatus: USER_STATUS[], items: string[]) {
    const users = this.userService.userList;
    const allUsers = this._getFilteredUsersByStatusInForm(users, userStatus);
    const allUserIDs = this._getClassifiedUserIDs(allUsers);
    let userIDs = {
      active: [],
      inactive: [],
      pending: [],
      invited: [],
    };
    let staffIDs = [],
      filteredOrgUserIDs = [];
    switch (filterBy) {
      case CATEGORY_ENUM.jobRoles:
        const jobRoleUsers = this._getFilteredUsersByStatusInForm(users.filter(u => u.jobRoleTakens &&
          (intersection(u.activeJobRoleTakens.map(t => t.jobRole), items).length)), userStatus);
        userIDs = this._getClassifiedUserIDs(jobRoleUsers);
        staffIDs = jobRoleUsers.filter(u => u.staffID).map(u => u.staffID);
        filteredOrgUserIDs = jobRoleUsers.map(u => u.orgUserID);
        break;
      case CATEGORY_ENUM.jobRoleTypes:
        const filteredJobRoles = pluck(this.groupService.getJobRoleByTypes(items), 'jobRoleID');
        const jobTypeUsers = this._getFilteredUsersByStatusInForm(users.filter(u => u.jobRoleTakens &&
          (intersection(u.activeJobRoleTakens.map(t => t.jobRole), filteredJobRoles).length)), userStatus);
        userIDs = this._getClassifiedUserIDs(jobTypeUsers);
        staffIDs = jobTypeUsers.filter(u => u.staffID).map(u => u.staffID);
        filteredOrgUserIDs = jobTypeUsers.map(u => u.orgUserID);
        break;
      case CATEGORY_ENUM.teams:
        const teamUsers = this._getFilteredUsersByStatusInForm(users
          .filter(u => u.teams && intersection(u.teams, items).length), userStatus);
        userIDs = this._getClassifiedUserIDs(teamUsers);
        staffIDs = teamUsers.filter(u => u.staffID).map(u => u.staffID);
        filteredOrgUserIDs = teamUsers.map(u => u.orgUserID);
        // console.log('🍣 users', teamUsers);
        // console.log('🍠 userIDs: ', userIDs);
        break;
      case CATEGORY_ENUM.directManagers:
        // console.log('this.users',this.users)
        const managersUsers = this._getFilteredUsersByStatusInForm(users
          .filter(u => u.hierarchyTeamModel?.teamID && intersection([u.hierarchyTeamModel?.teamID], items).length), userStatus);
        userIDs = this._getClassifiedUserIDs(managersUsers);
        staffIDs = managersUsers.filter(u => u.staffID).map(u => u.staffID);
        filteredOrgUserIDs = managersUsers.map(u => u.orgUserID);
        // console.log('🍣 users', managersUsers);
        // console.log('🍠 userIDs: ', userIDs);
        break;
      case CATEGORY_ENUM.facilities:
        const facilityOrgUsersIDs = uniq(items.map(facilityID => this.facilitiesService.globalOrgFacilities[facilityID]?.allMembers).flat());
        const facilityUsers = this._getFilteredUsersByStatusInForm(
          facilityOrgUsersIDs.map(orgUserID => {
            const user = users.find(u => u.orgUserID === orgUserID);
            if (!user) {
              console.log(orgUserID)
            }
            return user; 
          }).filter(u => u),
          userStatus);
        userIDs = this._getClassifiedUserIDs(facilityUsers);
        staffIDs = facilityUsers.filter(u => u.staffID).map(u => u.staffID);
        filteredOrgUserIDs = facilityUsers.map(u => u.orgUserID);
        // console.log('🍣 facilityUsers', facilityUsers);
        // console.log('🍠 userIDs: ', userIDs);
        break;
      case CATEGORY_ENUM.individual:
        // all users
        if (items?.length === allUsers.length) {
          userIDs = this._getClassifiedUserIDs(allUsers);
          items = allUsers.map(u => u.orgUserID);
          filterBy = CATEGORY_ENUM.allUsers;
          staffIDs = allUsers.filter(u => u.staffID).map(u => u.staffID);
          filteredOrgUserIDs = allUsers.map(u => u.orgUserID);
          break;
        }
        // individual user
        // const selectedUsers = users.find(u => u.orgUserID === items);
        const selectedUsers = (Array.isArray(items) ? items : [items]).map(i => users.find(u => u.orgUserID === i)).filter(u => u);
        userIDs = this._getClassifiedUserIDs(selectedUsers);
        staffIDs = pluck(selectedUsers, 'staffID').filter(u => u);
        filteredOrgUserIDs = pluck(selectedUsers, 'orgUserID').filter(u => u);
        // console.log('🍣 ', user);
        // console.log('🍠 userIDs: ', userIDs);
        break;
      default:
        break;
    }
    return {userIDs, staffIDs, filteredOrgUserIDs, allUserIDs, filterBy};
  }

  private _getFilteredUsersByStatusInForm(enabledUsers: IOrgUser[], statuses: USER_STATUS[]): IOrgUser[] {
      return enabledUsers.filter(u => statuses.includes(u.status));
  }

  private _getClassifiedUserIDs(filteredUsers = []): {
    active: string[];
    inactive: string[],
    pending: string[];
    invited: string[]
  } {
    return ({
      active: filteredUsers.filter(u => u.status === USER_STATUS.active).filter(u => u.userID).map(u => u.userID),
      inactive: filteredUsers.filter(u => u.status === USER_STATUS.inactive).filter(u => u.userID).map(u => u.userID),
      pending: filteredUsers.filter(u => u.status === USER_STATUS.pending).filter(u => u.userID).map(u => u.userID),
      invited: filteredUsers.filter(u => u.status === USER_STATUS.invited).filter(u => u.userID).map(u => u.userID),
    });
  }

}
