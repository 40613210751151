import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IFilterChange } from '../reporting-type';

// used together with GeneralReportComponent.
// Main report component need to provide this
@Injectable()
export class GeneralReportService<T = any> {
  private _filterChange = new Subject<IFilterChange>()
  filterChange$ = this._filterChange.asObservable();
  sharedReportData: T;
  constructor() { }
  setFilter(filterChange: IFilterChange) {
    this._filterChange.next(filterChange);
  }

  ngOnDestroy(): void {
    this._filterChange.complete();
  }
}
