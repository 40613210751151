import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('satellizer_token');
    req.headers.set('Content-Type', 'application/json');
    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('X-Auth-Token', token)
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
