import { cloneDeep } from 'lodash';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { sortBy } from 'underscore';
import { DataCacher } from '../shared/utils/class/DataCacher';
import { BroadcastService } from './broadcast.service';
import { UserService } from './user.service';
import { HierarchyService } from '../pages/admin/hierarchy/hierarchy.service';
import * as dayjs from 'dayjs';
import { AnalyticsPrService } from '../pages/performance-review/analytics-pr.service';
import { IAnalyticsEventWithArgs } from '@ausmed/cpdo-analytics-types';
import { PerfReviewPermissionsService } from '../pages/performance-review/perf-review-permissions.service';


@Injectable({
  providedIn: 'root',
})
export class GoalService {
  private goal: BehaviorSubject<ILearningGoal> = new BehaviorSubject(null);
  private _goalsCacher: DataCacher<ILearningGoal[]> = new DataCacher<ILearningGoal[]>(this.fetchAllOrgGoalsForDirectManager(localStorage.getItem('orgID')), this.broadcastService);

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private hierarchyService: HierarchyService,
    private customErrorHandler: ErrorHandlingService,
    private userService: UserService,
    private analyticsPrService: AnalyticsPrService,
    private perfReviewPermissionsService: PerfReviewPermissionsService
  ) {
  }

  fetchCacheGoalsForDirectManager(refresh = false): Observable<ILearningGoal[]> {
    return this._goalsCacher.fetchCachedData(refresh);
  }


  fetchAllOrgGoalsForDirectManager(orgID: string,) {
    return this.http
      .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/goals?goalType=org`)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
        map((goals: any) => {
          if (this.perfReviewPermissionsService.hasAllUserAccess()) {
            return this._setGoals(goals);
          }
          return this._setGoals(
            goals.filter(g => {
              if (g.owner === this.userService.plainOrgUser?.userID) {
                return true;
              }
              return this.perfReviewPermissionsService.accessibleUsersSet().has(this.userService.managedOrgUserDictionaryByUserID[g.owner]?.orgUserID)
              }));
        }),
        tap(goals => console.log(goals))
      );
  }

  fetchStaffGoals(orgID: string, userID: string, goalType: 'personal' | 'org' | 'all'): Observable<any> {
    return this.http
      .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/users/${userID}/goals?goalType=${goalType}`)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
        map((goals: any) => (this._setGoals(goals)))
      );
  }

  addGoal(orgID: string, userID: string, goal: IGoalNew) {
    return this.http
      .post<ILearningGoal>(`${environment.accountServiceEndpoint}/orgs/${orgID}/users/${userID}/orgGoals`, goal)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
        tap((newGoal) => {
          this.analyticsPrService.sendTrack('PR-goal-added', {
            ...this.analyticsPrService.getGoalInfo(newGoal),
            ...this.analyticsPrService.getOrgInfo()
          })

        })
      );
  }

  deleteGoal(orgID: string, userID: string, goal: ILearningGoal) {
    return this.http
      .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/users/${userID}/orgGoals/${goal.goalID}`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  updateGoal(orgID: string, userID: string, goal: ILearningGoal, markType = '') {
    return this.http
      .put<ILearningGoal>(`${environment.accountServiceEndpoint}/orgs/${orgID}/users/${userID}/orgGoals/${goal.goalID}`, goal)
      .pipe(
        catchError(this.customErrorHandler.handleHttpError),
        tap((newGoal) => {
          const trackPayload = {
            ...this.analyticsPrService.getGoalInfo(newGoal),
            ...this.analyticsPrService.getOrgInfo()
          }
          const event: IAnalyticsEventWithArgs = !markType ? 'PR-goal-updated' : markType === 'marking' ? 'PR-goal-marked_achieved' : null;
          if (event) {
            this.analyticsPrService.sendTrack(event, {
              ...this.analyticsPrService.getGoalInfo(newGoal),
              ...this.analyticsPrService.getOrgInfo()
            })
          }
        })
      );
  }

  markGoal(orgID: string, userID: string, goal: ILearningGoal) {
    const markedGoal = cloneDeep(goal);
    markedGoal.isAchieved = true;
    markedGoal.orgID = markedGoal.org.orgID;
    return this.updateGoal(orgID, userID, markedGoal, 'marking');
  }

  unmarkGoal(orgID: string, userID: string, goal: ILearningGoal) {
    const markedGoal = cloneDeep(goal);
    markedGoal.isAchieved = false;
    return this.updateGoal(orgID, userID, markedGoal, 'unmarking');
  }



  setGoal(goal: ILearningGoal): void {
    this.goal.next(goal);
  }

  getGoal(): Observable<ILearningGoal> {
    return this.goal.asObservable().pipe(
      filter(g => !!g),
    );
  }

  getGoalStatus(goal: ILearningGoal): GOAL_STATUS {
    // const today = dayjs();
    // if(!goal.isAchieved && (!goal.endDateLocal || !(dayjs(goal.endDateLocal).isBefore(today)))){
    //   return GOAL_STATUS.inProgress;
    // }
    if (goal.isAchieved) {
      return GOAL_STATUS.completed;
    } else {
      return GOAL_STATUS.inProgress;
    }
  }

  private _setGoals(goals) {
    const personalGoals = sortBy(goals.filter(g => !g.org), 'createDate').reverse().map(g => ({
      ...g,
      type: GOAL_TYPE.personal
    }));
    const orgGoals = goals.filter(goal => !!goal.org).map(g => ({ ...g, type: GOAL_TYPE.org }));
    return [...orgGoals];
    // return [...orgGoals, ...personalGoals];
  }
}

export interface ILearningGoal {
  goalID: string;
  orgID?: string;
  org?: {
    orgID: string;
    orgName?: string;
    orgAchieveDateLocal?: string;
  };
  owner: string;
  title: string;
  startDate?: Date;
  endDate?: Date;
  startDateLocal?: string;
  endDateLocal?: string;
  updateDate?: string;
  priority?: number;
  isAchieved: boolean;
  achieveDate?: string;
  action?: string;
  type?: GOAL_TYPE;
}



export enum GOAL_TYPE {
  org = 'Organisation Goal',
  personal = 'Personal Goal',
}

export enum GOAL_STATUS {
  inProgress = 'inProgress',
  completed = 'completed',
}

export interface IGoalNew {
  title: string;
  priority?: number;
  isAchieved?: false;
  startDateLocal: string;
  endDateLocal: string;
}

export interface LoadStatus {
  done?: boolean;
  error?: boolean;
  pending?: boolean;
}


