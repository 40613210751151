import { Directive, HostBinding, HostListener } from '@angular/core';

/**
 * This directive is used for applying horizontal scrolling shadow.
 */
@Directive({
    selector: '[appScrollShadow]',
    standalone: true
})
export class ScrollShadowDirective {
  @HostBinding('class.sticky-column-left-shadow') shadowX: boolean;
  @HostBinding('class.sticky-column-bottom-shadow') shadowY: boolean;


  constructor() { }

  @HostListener('scroll', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.fileOver = true;
    // this.fileOutside = false;
    const scrollX = evt.target.scrollLeft;
    const scrollY = evt.target.scrollTop;
    this.shadowX = true;
    // if (evt.target.scrollLeft + evt.target.offsetWidth >= evt.target.scrollWidth) {
    this.shadowX = !!scrollX;
    this.shadowY = !!scrollY;
  }







}
