import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { EvidenceService, EvidenceType } from 'src/app/services/evidence.service';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { EvidenceActionsComponent } from '../../../evidence-components/shared-evidence-cell/evidence-actions.component';

import { NgIf } from '@angular/common';
import { AvatarComponent } from '../../../avatar/avatar.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-side-panel-evidence-section',
  templateUrl: 'side-panel-evidence-section.component.html',
  styleUrls: ['side-panel-evidence-section.component.scss'],
  standalone: true,
  imports: [AvatarComponent, NgIf, EvidenceActionsComponent, UIStreamlineIconComponent]
})

export class SidePanelEvidenceSection implements OnInit {
  @Input() evidence: EvidenceType;
  @Input() context: { orgActivityID: string, user: IOrgUser };
  addBy: IOrgUser;
  addDate: string;
  showDeleteBtn = false;


  constructor(
    private userService: UserService,
    private evidenceService: EvidenceService,
    private analytics: AnalyticsService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.addBy = this.userService.allOrgUserDictionaryByUserID[this.evidence.addedBy];
    this.addDate = dayjs(this.evidence.addedDate).format('DD MMM YYYY') + ' at ' + dayjs(this.evidence.addedDate).format('hh:mma');
    const context = this.analytics.getContext(this.router.url);
    this.analytics.track('L-record-evidence_viewed', {
      context: context,
      ...(context === 'reporting' ? { reportName: this.evidenceService.getEventReportName() } : {})
    })
  }

  jumpToNewTab() {
    window.open('/pages/staff-transcript/' + this.addBy.orgUserID, '_blank')
  }

  moveIn() {
    this.showDeleteBtn = true;
  }

  moveOut() {
    this.showDeleteBtn = false;
  }


}
