import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { FilterEnables } from '../filter-set/filter-set.component';
import { ShortcutService } from 'src/app/services/shortcut.service';
import { SPINNER_BUTTON_STATUS_ENUM } from '@components/spinner-button/spinner-button.service';
/**
 * for communication between filter pack and shortcut button
 */
@Injectable({
  providedIn: 'root'
})
export class ShortcutButtonService {
  filterChanged = false;
  filterForm: UntypedFormGroup;
  dateOption$ = new ReplaySubject();
  filterEnables: FilterEnables;
  _spinnerButtonStatus;


  constructor(
    private shortcutService: ShortcutService
  ) { }

  initiate(filterEnables: FilterEnables) {
    this.filterChanged = false;
    this.filterEnables = filterEnables
  }

  set spinnerButtonStatus(s) {
    if (s === SPINNER_BUTTON_STATUS_ENUM.reset) {
      this.shortcutService.setActiveShortcut(null);
    }
    this._spinnerButtonStatus = s;

  }
}
