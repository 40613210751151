import { IMenuItem } from "../menu.service";
import { SHORTCUT_TYPE } from "../shortcut.service";

export function getSavedFilterMenu(type: SHORTCUT_TYPE): IMenuItem {
  const menuItem: IMenuItem = {
    displayName: 'Saved Filters',
    route: null,
    // queryParams: {type: type},
    show: true,
    slIcon: { name: 'interface-bookmark', theme: 'micro-bold' },
    // bottomBorder: true
  }
  if (type.startsWith('policy')) {
    menuItem.route = `policies/saved-filters/${type}`;
  } else if (type.startsWith('performance')) {
    menuItem.route = `performance/saved-filters/${type}`;
  } else {
    menuItem.route = `saved-filters/${type}`;
  }
  return menuItem;

}