import { Component, Input, OnInit } from '@angular/core';

import { getBadgeFromUserStatus } from '../../utils/get-badge-from-user-status';
import { ViewCell } from 'src/app/shared-modules/ng2-smart-table/components/cell/cell-view-mode/view-cell';

@Component({
    selector: 'staff-status-view',
    template: `
      <div>
          <span [class]="badgeClass">{{value}}</span>
      </div>
  `,
    standalone: true,
})
export class StaffStatusDisplayComponent implements ViewCell, OnInit {

  userID: string;
  fullName: string;
  profilePicURL: string;
  status: string;
  openNewTab = false;
  isNewTab: boolean;
  badgeClass: string;
  colourDict: any; // for other than user account status

  @Input() value: any;
  @Input() rowData: any;

  constructor() {
  }

  ngOnInit() {
    if (this.colourDict) {
      this.badgeClass = `badge badge-${this.colourDict[this.value] || 'light'}`;
      return;
    }
    this.badgeClass = getBadgeFromUserStatus(this.value);
  }
}

