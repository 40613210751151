<div (click)="onCollapseClick()" class="card p-4 hover-container children-gap" tabindex="0"
role="button" (keydown)="0" [ngClass]="{'card-hover': !noItems(), 'cursor-default': noItems()}" >
<div class="d-flex justify-content-between" >
    <div class="d-flex align-items-center gap-2">
        @if(!isCollapse()) {
            <ui-streamline-icon class="text-neutral-700" [slIcon]="{ name: 'interface-arrows-button-left', theme: 'micro-bold', size: 'sm' }"></ui-streamline-icon>
        }
        <ui-streamline-icon class="text-neutral-700" [slIcon]="icon()"></ui-streamline-icon>
        <div>
            <p class="f-small fw-semibold">{{cardTitle()}}</p>
            @if(noItems()) {
                <p class="f-small text-neutral-700">Not Assigned</p>
            } @else {
              @switch (completionEntity()) {
                @case ('Goals') {
                  <p class="f-small text-neutral-700">{{completionNum()}} In Progress</p>
                }
                @case ('Feedback') {
                  <p class="f-small text-neutral-700">{{completionNum()}} Added</p>
                }
                @default {
                  <p class="f-small text-neutral-700">{{completionNum() | number: '1.0-1'}}% Complete</p>
                }
              }
            }
        </div>

    </div>
    @if(isCollapse() && !noItems()) {
        <ui-streamline-icon class="text-neutral-700" [slIcon]="{ name: 'interface-arrows-button-right', theme: 'micro-bold', size: 'sm' }"></ui-streamline-icon>
    }

</div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapse" [ngClass]="{'mt-4': !isCollapse()}">
        <ng-content></ng-content>
    </div>
</div>
