<div *ngIf="displayedNum > 0;else emptyContent" [ngbPopover]="popContent" placement="top" [popoverTitle]="popTitle"
  [container]="noContainer ? '' : 'body'">
  <p class="fw-medium btn-link f-small cursor-pointer">{{ displayedNum + ' Standard' | addPluralS }}</p>
</div>

<ng-template #btnDown #emptyContent>
  <div class="fw-medium text-neutral-900">
    <a> - </a>
  </div>
</ng-template>

<ng-template #popTitle>
  <div class="f-small fw-medium text-neutral-900" *ngIf="displayedNum > 0"> {{ displayedNum + ' Related Standard' |
    addPluralS }} </div>
</ng-template>

<ng-template #popContent>
  <div class="m-scrollable" *ngIf="!isLoading;else loading">
    <ng-container *ngIf="relatedStandards.length">
      <div *ngFor="let groupedItems of displayedStandards;">
        <div class="fw-semibold">{{ FRAMEWORK_DICT[groupedItems[0].provider]?.title || groupedItems[0].provider }}</div>
        <div *ngFor="let item of groupedItems">
          <div class="text-truncate" [ngbTooltip]="item.standardName.length>31 ? item.standardName : ''"
            placement="top-left" container="">
            <a *ngIf="item.url" [href]="item.url" class="fw-medium text-neutral-900" target="_blank"
              [class.ms-4]="item.parentGuidelineID">
              {{ item.standardName }}
            </a>
            <p *ngIf="!item.url" class="f-small fw-medium text-neutral-900" [class.ms-4]="item.parentGuidelineID">
              {{ item.standardName }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="relatedOrgStandards.length">
      <div *ngFor="let groupedItems of displayedOrgStandards;">
        <div class="fw-semibold">{{ groupedItems[0].provider }}</div>
        <div *ngFor="let item of groupedItems">
          <div class="text-truncate" [ngbTooltip]="item.standardName.length>31 ? item.standardName : ''"
            placement="top-left" container="body">
            <a *ngIf="item.url" [href]="item.url" class="fw-medium text-neutral-900" target="_blank">
              {{ item.standardName }}
            </a>
            <span *ngIf="!item.url" class="f-small fw-medium text-neutral-900">
              {{ item.standardName }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>


  </div>
  <ng-template #loading>
    <div class="d-flex align-items-center justify-content-center">
      <ui-spinner></ui-spinner>
    </div>
  </ng-template>
</ng-template>