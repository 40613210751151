import { TASK_ASSIGN_ACTION_STATUS_ENUM, TASK_ASSIGN_STATUS_ENUM } from "src/app/services/performance-review.service";

/**
 * calculate PR assign new status based on the action and status attributes.
 * @param status 
 * @param action 
 * @returns 
 */
export function getPrAssignStatus(
    status: TASK_ASSIGN_STATUS_ENUM, 
    action: TASK_ASSIGN_ACTION_STATUS_ENUM
): PR_ASSIGN_STATUS_ENUM {
    switch(status) {
      case TASK_ASSIGN_STATUS_ENUM.completed:
        return PR_ASSIGN_STATUS_ENUM.completed;
      case TASK_ASSIGN_STATUS_ENUM.scheduled:
        return PR_ASSIGN_STATUS_ENUM.scheduled;
      case TASK_ASSIGN_STATUS_ENUM.skipped:
        return PR_ASSIGN_STATUS_ENUM.skipped;
      case TASK_ASSIGN_STATUS_ENUM.open:
        if (action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToComplete || action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToReview) {
          return PR_ASSIGN_STATUS_ENUM.openToManager;
        }
        if (action === TASK_ASSIGN_ACTION_STATUS_ENUM.staffToComplete) {
          return PR_ASSIGN_STATUS_ENUM.openToStaff;
        }
        return null;
      case TASK_ASSIGN_STATUS_ENUM.overdue:
        if (action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToComplete || action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToReview) {            
          return PR_ASSIGN_STATUS_ENUM.overdueToManager;
        }
        if (action === TASK_ASSIGN_ACTION_STATUS_ENUM.staffToComplete) {
          return PR_ASSIGN_STATUS_ENUM.overdueToStaff;
        }
        return null;
      default:
        console.error('There are some errors with the assign action and status.');
    }    
  }

export enum PR_ASSIGN_STATUS_ENUM {
  completed = 'Completed',
  scheduled = 'Scheduled',
  skipped = "Skipped",
  openToStaff = 'Open (Staff)',
  openToManager = 'Open (Manager)',
  overdueToStaff = 'Overdue (Staff)',
  overdueToManager = 'Overdue (Manager)',
}

export const PR_ASSIGN_COLOUR_DICT: Record<PR_ASSIGN_STATUS_ENUM, string> = {
  [PR_ASSIGN_STATUS_ENUM.openToManager]: 'neutral-500',
  [PR_ASSIGN_STATUS_ENUM.openToStaff]: 'neutral-200',
  [PR_ASSIGN_STATUS_ENUM.overdueToManager]: 'yellow-600',
  [PR_ASSIGN_STATUS_ENUM.overdueToStaff]: 'yellow-800',
  [PR_ASSIGN_STATUS_ENUM.completed]: 'green-600',
  [PR_ASSIGN_STATUS_ENUM.scheduled]: 'neutral-800',
  [PR_ASSIGN_STATUS_ENUM.skipped]: 'neutral-300',
}

export const PR_ASSIGN_STATUS_BRACKET_DICT: Record<PR_ASSIGN_STATUS_ENUM, string> = {
  [PR_ASSIGN_STATUS_ENUM.completed]: 'Completed',
  [PR_ASSIGN_STATUS_ENUM.scheduled]: 'Scheduled',
  [PR_ASSIGN_STATUS_ENUM.skipped]: 'Skipped',
  [PR_ASSIGN_STATUS_ENUM.openToManager]: 'Open (Manager)',
  [PR_ASSIGN_STATUS_ENUM.openToStaff]: 'Open (Staff)',
  [PR_ASSIGN_STATUS_ENUM.overdueToManager]: 'Overdue (Manager)',
  [PR_ASSIGN_STATUS_ENUM.overdueToStaff]: 'Overdue (Staff)',
}
