<button class="btn btn-link fw-medium" [disabled]="!activityID || rowData.status==='Skipped'" (click)="viewRecord()"><span class="badge badge-light">{{displayText}}</span></button>
<button class="btn btn-sm btn-outline-light fw-medium ms-1 d-none" *ngIf="(rowData.assignType==='Manual' || activityID) && rowData.status==='Skipped'" (click)="undoSkipped()">Undo Skipped</button>
<app-shared-sidebar #appSharedSidebar
  showHeaderUnderline="true"
  [isLoading]="isLoading"
  sidebarWidth="532px"
  >
  <ng-container sideBarBodySlot *ngIf="!isLoading">
    <!-- compliance banner -->
    <div class="m-compliance-banner-container">
      <p class="m-label-text mb-2 text-align-center">Showing learning record linked to</p>
      <div class="row">
        <p class="m-item-title col-5">{{activity.title || '-'}}</p>
        <p class="col-4">
          <staff-link-display *ngIf="user" [rowData]="user" textStyle="font-weight: 400 !important; font-size: 12px !important; color: #6F7786 !important;"></staff-link-display>
        </p>
        <div class="col d-flex justify-content-center align-items-center">
          <span class="badge {{complianceStatusBadgeStyle}}">{{rowData.status || '-'}}</span>
        </div>
      </div>
    </div>

    <!-- learning records -->
    <div *ngIf="user && activity" style="margin-top: 80px;">
      <app-side-panel-learning-record-section [user]="user" [activity]="activity" [isOrgActivity]="rowData.isOrgActivity"></app-side-panel-learning-record-section>
    </div>
  </ng-container>
</app-shared-sidebar>

