import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ui-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class EmptyStateComponent implements OnInit {
  @Input() heading: string;
  @Input() content: string;
  @Input() border = true;
  @Input() inCard = true;
  @Input() maxWidth = '800';
  @Input() emptyStateHeader: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
