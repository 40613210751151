import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, HostListener, Input, computed, inject, output, signal, viewChild } from '@angular/core';
import { SuccessBannerComponent } from '@components/banners/success-banner/success-banner.component';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { NavigationTabsComponent } from '@components/navigation/navigation-tabs/navigation-tabs.component';
import { CollapseHorizontalComponent } from '@components/ngb/collapse/collapse-horizontal/collapse-horizontal.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ProcessingButtonComponent } from '@ui/buttons/processing-button/processing-button.component';
import { LoadingContainerComponent } from '@ui/loading-container/loading-container.component';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import * as dayjs from 'dayjs';
import { WarningBannerComponent } from 'src/app/pages/compliance/compliance-workroom/mark-complete-bulk/warning-banner/warning-banner.component';
import { AnalyticsPrService } from 'src/app/pages/performance-review/analytics-pr.service';
import { TaskTitleAvatarComponent } from 'src/app/pages/performance-review/performance-review-task-title-template/task-title-avatar/task-title-avatar.component';
import { ITablePerformanceReviewAssignFull, PerformanceReviewService, TASK_ASSIGN_ACTION_STATUS_ENUM, TASK_ASSIGN_STATUS_ENUM, TASK_TEMPLATE_ENUM } from 'src/app/services/performance-review.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { Dictionary } from 'underscore';
import { TaskInfoCardComponent } from './task-info-card/task-info-card.component';
import { TaskManagerInputComponent } from './task-manager-input/task-manager-input.component';
import { TaskModalSideComponent } from './task-modal-side/task-modal-side.component';
import { HtmlSanitizerPipe } from 'src/app/shared/pipes/html-sanitizer.pipe';

@Component({
  selector: 'app-task-modal',
  standalone: true,
  imports: [ModalSingleComponent, TitleCasePipe, TaskTitleAvatarComponent, StaffLinkDisplayComponent, CommonModule,
    UIStreamlineIconComponent, TaskInfoCardComponent, SuccessBannerComponent, TaskManagerInputComponent,
    LoadingContainerComponent, WarningBannerComponent, NavigationTabsComponent, TaskModalSideComponent,
    ProcessingButtonComponent, NgbTooltip, CollapseHorizontalComponent, HtmlSanitizerPipe],
  templateUrl: './task-modal.component.html',
  styleUrl: './task-modal.component.scss'
})
export class TaskModalComponent extends ConfirmationModal {
  @HostListener('window:beforeunload', ['$event'])
  defaultConfirmation($event) {
    $event.preventDefault();
    return $event.returnValue = false;
  }
  // @Input() task: ITablePerformanceReviewAssign;
  @Input() taskID: string;
  @Input() context = 'my tasks';
  task = signal<ITablePerformanceReviewAssignFull>(null);
  performanceReviewService = inject(PerformanceReviewService);
  analyticsPrService = inject(AnalyticsPrService);
  userService = inject(UserService);

  areValidForms = true;
  isLoad = false;

  taskUpdated = output();

  isMeetingWithTextBox = computed(() => {
    return this.performanceReviewService.isMeetingWithTextBox(this.task()?.taskTemplate);
  });

  templateDescription = computed(
    () => this.task().taskTemplate.description
  )

  TAB_ITEMS = [{ name: 'Task' }, { name: 'Details' }]
  currentTab = signal(0);

  taskInputComp = viewChild(TaskManagerInputComponent);
  isScheduled = computed(() => this.task().status === TASK_ASSIGN_STATUS_ENUM.scheduled);
  isSkipped = computed(() => this.task().status === TASK_ASSIGN_STATUS_ENUM.skipped);
  isDraft = computed(() => this.task().managerComment && !this.task().managerComment?.responseDate);
  canComment = computed(() => this.isDraft() || (this.task().managerUserID === this.userService.plainOrgUser.userID && !this.isScheduled() && !this.isSkipped()));
  canPublish = computed(() => (this.isDraft() || (!this.task().managerComment && !this.isScheduled() && !this.isSkipped() &&
    (this.task().status===TASK_ASSIGN_STATUS_ENUM.completed ||
    this.task().action===TASK_ASSIGN_ACTION_STATUS_ENUM.managerToComplete ||
    this.task().action===TASK_ASSIGN_ACTION_STATUS_ENUM.managerToReview))));
  isCollapsed = false;
  TASK_ASSIGN_ACTION_STATUS_ENUM = TASK_ASSIGN_ACTION_STATUS_ENUM;
  TASK_ASSIGN_STATUS_ENUM = TASK_ASSIGN_STATUS_ENUM;

  TASK_TEMPLATE_ENUM = TASK_TEMPLATE_ENUM;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.performanceReviewService.fetchFullTaskByID(this.taskID)?.subscribe(t => {
      this.task.set(t);
      this.analyticsPrService.sendTrack('PR-task-viewed', this.analyticsPrService.getTaskInfo(t, this.context));
    })
  }

  commentSuccess(task: ITablePerformanceReviewAssignFull) {
    if (task.status === 'Completed' && this.task().status !== 'Completed') {
      this.analyticsPrService.sendTrack('PR-task-marked_completed', {
        ...this.analyticsPrService.getTaskInfo(this.task(), this.context),
        dateCompleted: dayjs().format('YYYY-MM-DD')
      });
    }
    this.task.set(task);
    this.taskUpdated.emit();
  }

  validForms(isValid: boolean) {
    this.areValidForms = isValid;
  }

  loading(isLoading: boolean) {
    this.isLoad = isLoading;
  }

  remind() {
    this.performanceReviewService.remindModal(this.task(), this.context);
  }

  onCloseClicked() {
    if (this.taskInputComp()) {
      this.taskInputComp()?.onCloseClicked();
      return;
    }
    this.dismiss()
  }

  // tech debt: handle the submit in child
  submit() {
    this.taskInputComp()?.submit();
  }

  onSaveAsDraft(){
    this.taskInputComp()?.openSaveAsDraftModal();
  }

  get completionNum() {
    if (this.task().response) {
      if (this.task().managerComment) {
        return 2
      }
      return 1
    }
    return 0;
  }

  get circle1Icon() {
    return this.task().response ? CIRCLE_ICON_DICT.done : CIRCLE_ICON_DICT.open

  }

  get circle2Icon() {
    if (!this.task().response) {
      return CIRCLE_ICON_DICT.unavailable;
    }
    return this.task().managerComment ? CIRCLE_ICON_DICT.done : CIRCLE_ICON_DICT.open

  }

  get item1Text() {
    return this.task().response ? 'Review Completed by the Reviewee' : 'Reviewee Haven’t Completed a Review'

  }

  get item2Text() {
    return 'Manager is required to comment on self-reflection response'

  }

}

export const CIRCLE_ICON_DICT: Dictionary<IUIStreamlineIcon> = {
  open: { name: 'circle-outline', theme: 'bold', color: 'success', size: 'sm' },
  done: {
    "name": "interface-validation-check-circle",
    "theme": "micro-bold",
    "color": "fill-success",
  },
  unavailable: { name: 'circle-outline', theme: 'bold', color: 'muted', size: 'sm' }
}
