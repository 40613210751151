<div>
  <!-- learning record section -->
  <div class="m-sidepanel-section p-4">
    <h3 class="fw-semibold mb-2">Learning Record</h3>
    <p class="m-label mb-1">Item Title</p>
    <p class="m-title-text-style mb-4">{{activity.title || '-'}}</p>

    <p class="m-label mb-1">User</p>
    <div class="d-flex align-items-center mb-4 cursor-pointer" (click)="jumpToNewTab()">
      <ui-avatar size="md" [fullName]="user.userDetail?.fullName" [url]="user.profilePicURL" class="me-3"></ui-avatar>
      <div>
        <p class="m-user-name">{{user.userDetail?.fullName || '-'}}</p>
        <span class="m-user-email">{{user.userDetail?.email || '-'}}</span>
      </div>
    </div>


    <p class="m-label mb-1">Completion Date</p>
    <p class="fw-semibold">{{activity.endDateLocal || activity.detail?.endDateLocal || '-'}}</p>
  </div>

  <!-- record details -->
  <div class="m-sidepanel-section p-4">
    <div class="d-flex justify-content-between">
      <h4 class="fw-semibold mb-2">Details</h4>
      <app-download-certificate [rowData]="learningRecordMap"></app-download-certificate>
    </div>
    <p *ngIf="managerName" class="m-label mb-2">Record Created by: <span class="m-detail-content">{{ managerName
        }}</span></p>
    <p class="m-label mb-2">Reported Time Spent Learning: <span class="m-detail-content">{{ reportedTimeSpentLearning
        }}</span></p>
    <p class="m-label mb-2">Record Created Date: <span class="m-detail-content">{{ createdDate }}</span></p>
    <p class="m-label mb-2">Record Type: <span class="m-detail-content">{{recordType || '-'}}</span></p>
  </div>

  <!-- evidence section -->
  <div class="p-4 m-sidepanel-section">
    <h4 class="fw-semibold mb-2">Evidence</h4>
    <!-- Add new evidence btn -->
    <div *ngIf="isInternalRecord; else tipForUserDocumentedReport">
      <!-- Evidences sections -->
      <ng-container *ngIf="evidences; else emptyEvidence">
        <ng-container *ngFor="let evidence of evidences">
          <ng-container *ngIf="evidence">
            <app-side-panel-evidence-section [evidence]="evidence"
              [context]="{orgActivityID: activity.orgActivityID, user: user}"></app-side-panel-evidence-section>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #emptyEvidence>
        <p class="mb-2">No evidence to display</p>
      </ng-template>

      <button *ngIf="!hasAddEvidenceSection; else addEvidenceSection" class="btn btn-outline-light"
        (click)="showAddSection()" type="button">
        <span class="text-neutral-900 fw-semibold f-small">
          + Add Evidence
        </span>
      </button>

      <ng-template #addEvidenceSection>
        <div id="add-evidence-section-container">
          <app-add-evidence context="sidepanel" (evidenceFormChange)="evidenceFormValue = $event"
            (addEvidenceChange)="addEvidence($event)" (cancelStatusChange)="cancel()"></app-add-evidence>
        </div>
      </ng-template>
    </div>

    <ng-template #tipForUserDocumentedReport>
      <p class="f-small">Evidence can only be attached to Internal Records</p>
    </ng-template>
  </div>

</div>
