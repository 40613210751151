import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SPINNER_BUTTON_STATUS_ENUM, SpinnerButtonService } from './spinner-button.service';

import { UISpinnerComponent } from '../../../ui/ui-spinner/ui-spinner.component';
import { NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-spinner-button',
  template: `
      <button [style.width]="btnWidth"
              class="btn {{buttonClass}} {{displayStatusDict[status]?.btnClass}} {{status===spinnerButtonStatus.processing ? 'active has-not-allowed-cursor' : ''}}"
              (click)="startProcessing();">
          <span style="color: inherit;"
                *ngIf="status===spinnerButtonStatus.processing && displayStatusDict.processing.icon">
    <ui-spinner size="sm" class="me-1" [color]="spinnerColor"></ui-spinner></span>
          <span style="color: inherit;" *ngIf="status===spinnerButtonStatus.after && displayStatusDict.after.icon">
          <ui-streamline-icon class="pe-1" [slIcon]="{name: 'interface-validation-check', theme: 'mini-bold'}"></ui-streamline-icon></span>
          {{displayStatusDict[status].text}}
      </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    UISpinnerComponent,
    UIStreamlineIconComponent
  ],
})
export class SpinnerButtonComponent implements OnInit, OnDestroy {
  @Input() displayStatusDict: {
    [statusName in SPINNER_BUTTON_STATUS_ENUM]: { text: string, icon?: boolean, btnClass?: string };
  };
  @Input() buttonClass = 'btn-secondary';
  @Input() btnWidth;
  @Input() isValid = true;
  @Input() reset: Observable<void | 'default'>;
  @Input() spinnerColor: 'light' | 'dark' = 'light';
  private _status: SPINNER_BUTTON_STATUS_ENUM = SPINNER_BUTTON_STATUS_ENUM.before;
  get status(): SPINNER_BUTTON_STATUS_ENUM {
    return this._status;
  }

  // init status
  @Input()
  set status(value: SPINNER_BUTTON_STATUS_ENUM) {
    this._status = value;
    this.spinnerButtonService.setStatus(value);
  }

  SPINNER_BUTTON_STATUS_ENUM = SPINNER_BUTTON_STATUS_ENUM;
  destroyed$ = new Subject<void>();

  private _done;
  get done() {
    return this._done;
  }

  @Input()
  set done(value) {
    if (value) {
      this.status = SPINNER_BUTTON_STATUS_ENUM.after;
    }
    this._done = value;
  }

  @Output() clickEvent = new EventEmitter();
  spinnerButtonStatus = SPINNER_BUTTON_STATUS_ENUM;

  constructor(
    private spinnerButtonService: SpinnerButtonService,
  ) {
  }

  ngOnInit(): void {
    if (this.reset) {
      this.reset.pipe(
        takeUntil(this.destroyed$),
      ).subscribe((resetType) => {
        // console.log('reset type 🥬', resetType);
        // console.log(this.status);

        if (this.status === SPINNER_BUTTON_STATUS_ENUM.after) {
          if (resetType === 'default') {
            this.status = SPINNER_BUTTON_STATUS_ENUM.resetToDefault;
          } else {
            this.status = SPINNER_BUTTON_STATUS_ENUM.reset;
          }
        } else if (this.status === SPINNER_BUTTON_STATUS_ENUM.reset) {
          if (resetType === 'default') {
            this.status = SPINNER_BUTTON_STATUS_ENUM.resetToDefault;
          }
        } else if (this.status === SPINNER_BUTTON_STATUS_ENUM.resetToDefault) {
          if (resetType !== 'default') {
            this.status = SPINNER_BUTTON_STATUS_ENUM.reset;
          }
        }


      });
    }
  }

  startProcessing() {
    if (this.status === SPINNER_BUTTON_STATUS_ENUM.processing) {
      return;
    }
    this.clickEvent.emit(null);
    if (this.isValid) {
      this.status = SPINNER_BUTTON_STATUS_ENUM.processing;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}


