import { Component, input, Input, OnInit, signal, ViewChild } from '@angular/core';
import { GOAL_STATUS, GoalService, ILearningGoal } from 'src/app/services/goal.service';
import { AvatarComponent } from "../../../../shared/components/avatar/avatar.component";
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { StaffLinkDisplayComponent } from "../../../../shared/components/staff-link-display/staff-link-display.component";
import * as dayjs from 'dayjs';
import { NgClass, NgIf } from '@angular/common';
import { SharedSidebarComponent } from "../../../../shared/components/shared-sidebar/shared-sidebar.component";
import { UISpinnerComponent } from "../../../../ui/ui-spinner/ui-spinner.component";
import { switchMap, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerformanceReviewAddEditGoalModalComponent } from '../performance-review-add-edit-goal-modal/performance-review-add-edit-goal-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsPrService } from '../../analytics-pr.service';
import { TaskModalGoalCardComponent } from '../../reviews/progress/task-activity-tables/task-modal/task-modal-side/task-modal-completion-item/task-modal-goal-card/task-modal-goal-card.component';

@Component({
  selector: 'app-performance-review-goal-list-item',
  standalone: true,
  imports: [AvatarComponent, StaffLinkDisplayComponent, NgClass, 
    SharedSidebarComponent, NgIf, UISpinnerComponent, TaskModalGoalCardComponent],
  templateUrl: './performance-review-goal-list-item.component.html',
  styleUrl: './performance-review-goal-list-item.component.scss'
})
export class PerformanceReviewGoalListItemComponent implements OnInit {

  @Input() goal!: ILearningGoal;
  showCard = input(false);
  @ViewChild('appSharedSidebar', { static: true }) appSharedSidebar: SharedSidebarComponent;
  GOAL_STATUS = GOAL_STATUS;
  user: IOrgUser;
  open = signal(false);
  isMarking = signal(false);

  constructor(
    private userService: UserService,
    private goalService: GoalService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private analyticsPrService: AnalyticsPrService
  ){}



  ngOnInit(): void {
    this.user = this.userService.managedOrgUserDictionaryByUserID[this.goal.owner];
  }

  openGoalSidePanel(){
    // this.offcanvasService.open(this.content(), {container: '.modal-content'});
    // return;
    this.open.set(true);
    this.appSharedSidebar.toggleShowSidebar();
    this.analyticsPrService.sendTrack('PR-goal-viewed', {
      ...this.analyticsPrService.getGoalInfo(this.goal),
    })
  }

  markGoal(){
    this.isMarking.set(true);
    this.goalService.markGoal(localStorage.getItem('orgID'), this.user.userID, this.goal).pipe(
      take(1),
      switchMap(goal => this.goalService.fetchCacheGoalsForDirectManager(true))
    ).subscribe(
      goals => {
        this.goal = goals.find(g => g.goalID === this.goal.goalID);
        console.log('goal',this.goal)
        this.isMarking.set(false);
        this.goalService.setGoal(this.goal);
        this.toaster.success('Goal marked as complete');
        this.appSharedSidebar.closeSidebar();
      }
    )
  }

  unmarkGoal(){
    this.isMarking.set(true);
    this.goalService.unmarkGoal(localStorage.getItem('orgID'), this.user.userID, this.goal).pipe(
      take(1),
      switchMap(goal => this.goalService.fetchCacheGoalsForDirectManager(true))
    ).subscribe(
      goals => {
        this.goal = goals.find(g => g.goalID === this.goal.goalID);
        console.log('goal',this.goal)
        this.isMarking.set(false);
        this.goalService.setGoal(this.goal);
        this.appSharedSidebar.closeSidebar();
      }
    )
  }

  editGoal(){
    const activeModal = this.modalService.open(PerformanceReviewAddEditGoalModalComponent, { size: 'lg' });
    activeModal.componentInstance.action = 'Edit';
    activeModal.componentInstance.currentGoal = this.goal;
  }

  getGoalStatus(){
    return this.goalService.getGoalStatus(this.goal);
  }

  displayedDate(date: string){
    // return dayjs(date.split('T')[0]).format('DD MMM YYYY');
    return dayjs(date).format('DD MMM YYYY')
  }

  onCardClicked(e) {
    e.stopPropagation();
    this.openGoalSidePanel()
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

  get displayedDueDate(){
    if(this.getGoalStatus() === 'inProgress'){
      return `Due ${dayjs(this.goal?.endDateLocal).format('DD MMM YYYY')}`
    } else {
      return `Completed ${dayjs(this.goal?.achieveDate).format('DD MMM YYYY')}`
    }
  }



  get displayedBadgeText(){
    if(this.getGoalStatus() === 'inProgress'){
      return 'In Progress';
    } else {
      return 'Completed';
    }
  }


}
