import { some } from "underscore";
import 'bootstrap';
import * as $ from 'jquery';

declare var bootstrap: any;


export enum COL_WIDTHS {
    SMALL = 134,
    MEDIUM = 216,
    LARGE = 299,
}

// this function will still check document if modal is being displayed
export function getMaxWidth(className: string, modal = false, defaultMaxWidth = 216) {
    // make sure if there's modal
    if (modal || isModal()) {
        // comment this in if need to get accurate width for modal
        // const elementInModal: any = document.querySelector('.modal-body .' + className);
        // if ((elementInModal)) {
        //     return elementInModal.offsetWidth - 32;
        // }
        return defaultMaxWidth;
    }
    const selector = '.' + className;
    const title: any = document.querySelector(selector);
    return (title?.offsetWidth - 32);
}

export function isModal() {
    return document.querySelector('.modal-header');
}

export function getWidthFromClass(className, defaultMaxWidth = COL_WIDTHS.SMALL) {
    if (some(['resource', 'title', 'content'], el => className.includes(el))) {
        return 299;
    }
    if (some(['educator', 'answer'], el => className.includes(el))) {
        return 216;
    }
    if (some(['staffID'], el => className.includes(el))) {
        return COL_WIDTHS.SMALL;
    }
    return null;
}

// add tooltip to table header using bootstrap
export function addHeaderTooltip(colName, colWidth, newTitleClass?) {
    const headerContainer = $(`.${colName} .ng2-smart-title`);
    // add class to ng-smart-title (eg to set the col width)
    if (newTitleClass) {
        // const headerContainer = document.querySelector(`.${colName} .ng2-smart-title`);
        if (headerContainer) {
            // this.renderer.addClass(headerContainer, newTitleClass);
            headerContainer.addClass(newTitleClass);
        }
    }
    const headerSpan = $(`.${colName} span`);
    if (headerSpan) {
        if (headerSpan.width() > colWidth) {
            headerContainer.attr({
                'data-bs-title': headerSpan.text(),
                'data-bs-toggle': 'tooltip',
                'data-bs-template': '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
                'data-bs-placement': 'top',
                'data-toggle': 'tooltip',
            });
        }
    }
}

// please call it after addHeaderTooltip() loop
export function triggerBootstrapTooltips() {
    // Trigger the bootstrap tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {
        });
    });
}
