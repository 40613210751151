import { Injectable, Injector, OnDestroy, OnInit,  } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomRouteReuseStrategy } from 'src/app/services/custom-route-reuse-strategy.service';
import { Constructor, WithDestroy } from './withDestroy';

@Injectable()
export abstract class BaseClassInjector implements OnInit, OnDestroy {
    constructor(public injector: Injector) {}
    ngOnInit(): void {}
    ngOnDestroy(): void {}
}


export type BaseInjectorConstructor = Constructor<BaseClassInjector>;

export class Base extends BaseClassInjector {}

export const ClearRouteMixin = <Tbase extends BaseInjectorConstructor>(superClass: Tbase, path) => {

    @Injectable()
    abstract class ClearRoute extends superClass implements OnInit {
        private routerCl = this.injector.get(Router);
        destroy$;
        ngOnInit(): void {
            this.routerCl.events.pipe(
                filter(e => e instanceof NavigationStart),
                filter((e: NavigationStart) => !e.url.includes(path)),
                takeUntil(this.destroy$)
            ).subscribe(_ => {
                CustomRouteReuseStrategy.clearStoredRoutes();
                CustomRouteReuseStrategy.shouldDetachToggle = false;
                console.log('here');
            });
        }

    }
    return ClearRoute;
  };
