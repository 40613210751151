import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { NgbDatepickerHelpers } from '../../utils/ngb-datepicker-helpers';
import { CustomDateParserFormatter } from '../../utils/custom-date-parser-formatter';
import { CustomAdapter } from '../../utils/custom-adapter';

import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
/* [technical debt] suggest to use NG_VALUE_ACCESSOR */
@Component({
  selector: 'app-ngb-date-selector',
  templateUrl: './ngb-date-selector.component.html',
  styleUrls: ['./ngb-date-selector.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
  ],
  standalone: true,
  imports: [NgIf, NgbInputDatepicker, FormsModule, ReactiveFormsModule, NgClass, UIStreamlineIconComponent]
})
export class NgbDateSelectorComponent implements OnInit, AfterViewInit {
  @Input() control?: FormControl; // for reactive forms
  @Input() model?: string; // for template-driven forms
  @Input() required = false;
  @Input() futureDates = true;
  @Input() id?: string;
  @Input() name?: string;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() min?: string;
  @Input() style?: string;
  @Input() showIcon = false;
  @Input() showError = false;

  @Output() modelChange = new EventEmitter<string>();
  today: NgbDateStruct;
  minDate: NgbDateStruct;

  constructor(
    private dateAdapter: NgbDateAdapter<string>,
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    if (!this.futureDates) {
      this.today = NgbDatepickerHelpers.getToday();
    }
    if (this.min) {
      this.minDate = NgbDatepickerHelpers.fromModel(this.min);
    }
  }

  ngAfterViewInit() {
    // So that label for=id in parent component still works as expected
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
  }

  onDateSelect(date) {
    const formattedDate = this.dateAdapter.toModel(date);
    this.modelChange.emit(formattedDate);
  }

  // When clear button is clicked
  onChange(event) {
    if (event.target.value === '') {
      this.modelChange.emit(null);
    }
  }

  onIconClick() {
    document.getElementById(this.id).click();
  }

  get isShowIcon() {
    return this.showIcon && (!this.model && !this.control?.value && (!this.control || !this.control.disabled));
  }

}
