import { IConvertedOrgAssign } from "src/app/services/org-assign.service";
import { ITablePerformanceReviewAssign } from "src/app/services/performance-review.service";
import { IOrgMTAssignRecordGrouped, IRecordStatus } from "src/app/services/report-learning-record.service";


export function getTotalCompletedPercent(groupedAssign: IOrgMTAssignRecordGrouped) {
    const totalMinusScheduled = getTotalMinusScheduled(groupedAssign);
    if (!totalMinusScheduled) {
      return 0;
    }
    return Math.round(((groupedAssign.completedNum + groupedAssign.doneNum + groupedAssign.skippedNum) || 0)/totalMinusScheduled * 10000)/100;
  }
  
  export function getTotalMinusScheduled(groupedAssign: IOrgMTAssignRecordGrouped, includeScheduled = false) {
    if (includeScheduled) {
      return groupedAssign.recordTotalNum || 0;
    }
    return (groupedAssign.recordTotalNum || 0) - ( groupedAssign.scheduleNum || 0);
  }

export function getCompletedPercentFromArray(assigns: IConvertedOrgAssign[]) {
  if (!assigns?.length) {
    return 100;
  }
  const totalMinusScheduled = assigns.filter(a => a.status !== 'Scheduled').length;
  const totalCompleted = assigns.filter(a => DONE_STATUSES.includes(a.status)).length;
  return Math.round((totalCompleted)/totalMinusScheduled * 10000)/100;

}

export function getTaskCompletedPercentFromArray(tasks: ITablePerformanceReviewAssign[]) {
  if (!tasks?.length) {
    return 100;
  }
  return tasks.filter(t => t.status === 'Completed').length/tasks.length * 100
}

export const DONE_STATUSES: IRecordStatus[] = ['Completed', 'Done', 'Skipped']