import { inject, Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ITableUser, SmartTableColumnsService } from './smart-table-columns.service';
import { IOrgUser, UserService } from './user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackModalComponent } from '../pages/performance-review/feedback/feedback-modal/feedback-modal.component';
import { ListCacher } from '../shared/utils/class/ListCacher';
import { ErrorHandlingService } from './error-handling.service';
import { FeedbackDeleteComponent } from '../pages/performance-review/feedback/feedback-delete/feedback-delete.component';
import { HttpClient } from '@angular/common/http';
import { OrganisationService } from './organisation.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from 'src/environments/environment';
import { OrgFrameworksService } from './org-frameworks.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  colService = inject(SmartTableColumnsService);
  userService = inject(UserService);
  orgFrameworkService = inject(OrgFrameworksService);
  private modalService = inject(NgbModal);
  private errorHandlingService = inject(ErrorHandlingService);
  private http = inject(HttpClient);
  orgID = toSignal(inject(OrganisationService).getOrgID());

  private feedbackCacher = new ListCacher<Feedback>(this.fetchAll());


  fetchAll() {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID()}/feedbacks`;
    return combineLatest([
      this.http.get<FeedbackRaw[]>(url),
      this.orgFrameworkService.fetchCachedOrgStandardFrameworks().pipe(take(1))
    ]).pipe(
      catchError(this.errorHandlingService.handleHttpError),
      map(([feedbacks,]) => feedbacks),
      map(feedbacks => feedbacks.filter(f => this.userService.managedOrgUserDictionaryByOrgUserID[f.receiverOrgUserID])),
      map(rawFeedbacks => rawFeedbacks.map(f => this.mapFeedback(f)))
    )
    // return of(MOCK_FEEDBACK).pipe(
    //   delay(1000),
    //   map(feedbacks => feedbacks.filter(f => this.userService.managedOrgUserDictionaryByUserID[f.receiver]).map(f => this.mapFeedback(f)))
    // )
  }

  fetchCachedFeedbacks(refresh = false) {
    return this.feedbackCacher.fetchCachedData(refresh);
  }

  addFeedback(f: FeedbackPayload[]) {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID()}/feedbacks/bulk`;
    // return of(f).pipe(
    return this.http.post<FeedbackRaw[]>(url, f).pipe(
      catchError(this.errorHandlingService.handleHttpError),
      // delay(1000),
      // map(f => ({...f, giver: this.userService.plainOrgUser.userID, completeDate: dayjs().format('YYYY-MM-DD'), feedbackID: f.feedbackID || generateRandomString()})),
      map(feedbacks => feedbacks.map(f => this.mapFeedback(f))),
      tap(f => this.feedbackCacher.pushNew(f))
    )
  }

  updateFeedback(f: FeedbackPayload, feedbackID: string) {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID()}/feedbacks/${feedbackID}`;
    // return of(f).pipe(
    return this.http.put<FeedbackRaw>(url, f).pipe(
      catchError(this.errorHandlingService.handleHttpError),
      // delay(1000),
      // map(f => ({...f, giver: ori.createdBy, completeDate: ori.completeDate, feedbackID: f.feedbackID || generateRandomString()})),
      map(f => this.mapFeedback(f)),
      tap(f => this.feedbackCacher.updateEl(f, 'feedbackID'))
    )
  }

  deleteFeedback(feedbackID: string) {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID()}/feedbacks/${feedbackID}`;
    return this.http.delete(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
      tap(() => this.feedbackCacher.deleteEl(feedbackID, 'feedbackID'))
    )
  }

  mapFeedback(feedback: FeedbackRaw): Feedback {
    return {
      ...feedback,
      ...this.colService.getUserTableSimple(feedback.receiverOrgUserID),
      taskManager: this.userService.getTaskManager(feedback.createdBy),
      feedbackContent: feedback.content,
      fn_relatedStandards: feedback.standards,
      // edit: feedback writer only. delete: writer/their manager + OM
      canEdit: this.userService.plainOrgUser.userID === feedback.createdBy,
      // canDelete: this.userService.isManager
      // || this.userService.plainOrgUser.userID === feedback.createdBy
      // || !!this.userService.managedOrgUserDictionaryByUserID[feedback.createdBy],
      canDelete: this.userService.plainOrgUser.userID === feedback.createdBy,
      completeDate: feedback.createDate
    }
  }

  feedbackModal(editedFeedback?: Feedback) {
    const modal = this.modalService.open(FeedbackModalComponent, { size: 'lg' });
    if (editedFeedback) {
      (modal.componentInstance as FeedbackModalComponent).editedFeedback.set(editedFeedback);
    }
  }

  feedbackDeleteModal(feedback: Feedback) {
    const modal: FeedbackDeleteComponent = this.modalService.open(FeedbackDeleteComponent, { size: 'lg' }).componentInstance;
    modal.feedbackID.set(feedback.feedbackID);
  }
}

export interface FeedbackRaw extends FeedbackPayload {
  completeDate: string;
  feedbackID: string;
  createDate: string;
  createdBy: string; // userID
  updatedBy: string;
  giverName?: string;
  updateDate: string;
}

export interface FeedbackPayload {
  receiverOrgUserID: string;
  source?: FeedbackSourceType;
  content: string;
  standards?: string[];
  orgStandards?: string[];
  sentiment: FeedbackSentiment;
  receiverUserID: string;
  // feedbackID?: string;
}

export interface Feedback extends FeedbackRaw, ITableUser {
  taskManager: IOrgUser; // feedback giver
  feedbackContent: string;
  completeDate: string;
  fn_relatedStandards?: string[];
  canEdit: boolean;
  canDelete: boolean;
}

export type FeedbackSourceType = 'Manager' | 'Client' | 'Resident' | 'Family' | 'Co-worker' | 'Other';
export type FeedbackSentiment = 'Positive' | 'Negative' | 'Neutral';
export const FeedbackSourceTypeList: FeedbackSourceType[] = ['Manager', 'Client', 'Resident', 'Family', 'Co-worker', 'Other'];
export const FeedbackSentimentList: FeedbackSentiment[] = ['Positive', 'Neutral', 'Negative']
export const FeedbackSentimentDisplay: { [k in FeedbackSentiment]: string } = {
  'Neutral': '😶 Neutral',
  'Positive': '👍 Positive',
  'Negative': '👎 Negative',
}

export const FEEDBACK_SOURCE_OPTIONS = FeedbackSourceTypeList.map(el => ({ label: el, value: el }));
export const FEEDBACK_SENTIMENT_OPTIONS = FeedbackSentimentList.map(el => ({ label: FeedbackSentimentDisplay[el], value: el }));



// export const MOCK_FEEDBACK: FeedbackRaw[] = [
//   {
//     feedbackID: '1',
//     receiver: "61f531b7-9ddc-479f-9cde-5398622cc62c",
//     giver: "61f531b7-9ddc-479f-9cde-5398622cc62c",
//     source: 'Manager',
//     content: 'A short feedback',
//     standards: [
//       "3bd25140-fee3-4fcf-936d-e224c53b1cb5",
//       "40a58630-3c02-4196-89c7-15b794b961e1",
//       "65696b81-a9df-479a-ab11-f24f51ecbed5",
//       "6bd8b153-153b-488a-9be8-c364fd935f2c",
//     ],
//     sentiment: 'Positive',
//     completeDate: '2024-10-02'
//   },
//   {
//     feedbackID: '2',
//     receiver: "d8430c24-a6f3-4aab-8244-3988b0194da9",
//     giver: "61f531b7-9ddc-479f-9cde-5398622cc62c",
//     source: 'Manager',
//     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
//     standards: [],
//     sentiment: 'Negative',
//     completeDate: '2024-10-02'
//   },
//   {
//     feedbackID: '3',
//     "receiver": "af8e2035-0cf6-43e3-bb81-7be3ecc5568e",
//     "content": '<p><strong>bold header</strong></p>\n\n<p>normal second line</p>\n',
//     "sentiment": "Neutral",
//     "standards": [],
//     "giver": "61f531b7-9ddc-479f-9cde-5398622cc62c",
//     "completeDate": "2024-10-17",
//     source: 'Manager',
// },
//   {
//     feedbackID: '4',
//     "receiver": '6a62033b-f9ad-4640-9075-834a558b676f',
//     "content": '<p><strong>bold header</strong></p>\n\n<p>normal second line</p>\n',
//     "sentiment": "Neutral",
//     "standards": [],
//     "giver": "6a62033b-f9ad-4640-9075-834a558b676f",
//     "completeDate": "2024-10-17",
//     source: 'Manager',
// },
// ]


