<div class="modal-body" style="min-height: 350px;">
    <div class="mb-5">
        <h3 class="text-center mb-2">Assign policy to
            {{revokePolicyFlowSharedData.selectedUsers.length===1?revokePolicyFlowSharedData.selectedUsers[0].fullName:revokePolicyFlowSharedData.selectedUsers.length+'
            users'}}</h3>
    </div>
    <div class="mb-5">
        <app-addflow-confirm-users [selectedUsers]="revokePolicyFlowSharedData.selectedUsers">
            <p paragraph>You are about to ask <span
                    class="fw-semibold">{{revokePolicyFlowSharedData.selectedUsers.length===1?revokePolicyFlowSharedData.selectedUsers[0].fullName:revokePolicyFlowSharedData.selectedUsers.length+'
                    people'}}</span> to acknowledge <span
                    class="fw-semibold">{{revokePolicyFlowSharedData.selectedPolicy.title}}</span>.
                {{revokePolicyFlowSharedData.selectedUsers.length===1?'The user':'They'}} will be notified to
                acknowledge the policy by email. Please confirm your selection.
            </p>


        </app-addflow-confirm-users>
    </div>
    <div class="d-flex justify-content-center" *ngIf="error">
        <p class="text-danger f-small">{{error}}</p>
    </div>
</div>

<!-- FOOTER -->
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-light" [disabled]="revokingPolicy"
        (click)="stepsControl.previousStep();">
        Back
    </button>
    <button type="button" class="btn btn-secondary" [disabled]="revokingPolicy" (click)="revokePolicy()"><span
            *ngIf="revokingPolicy" style="color: inherit">
            <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
        </span>Assign</button>
</div>