import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateFormatter } from './custom-date-formatter';
import { LocaliserService } from '../../services/localiser.service';
import { LocalDatePipe } from '../pipes/local-date.pipe';
import { CommonModule } from '@angular/common';
import * as dayjs from 'dayjs';

@Component({
  standalone: true,
  imports: [NgbDatepickerModule, CommonModule],
  selector: 'app-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  providers: [LocalDatePipe, CustomDateFormatter]
})
export class DateRangeSelectorComponent implements OnInit {
  @Input() format: 'jsDate' | 'date';
  @Input() hasDefaultDate: boolean;
  @Input() label = 'Select a Date Range';
  @Input() showLabel = true;
  @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();
  // @Output() dateInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearSelection: EventEmitter<any> = new EventEmitter<any>();
  @Input() submitted = false;

  hoveredDate: NgbDate | null = null;
  @Input() fromDate: NgbDate | null;
  @Input() toDate: NgbDate | null;
  @ViewChild('datepicker') datepicker;
  @Input() buttonText;
  @Input() buttonClass = '';
  @Input() maxDate;
  @Input() minDate;
  @Input() oneMonthRange = false; // limited range eg on payroll report

  constructor(
    private calendar: NgbCalendar,
    public localiser: LocaliserService,
    private formatter: NgbDateParserFormatter, // format the selected date range to ISO format which then can be emitted.
    public customDateFormatter: CustomDateFormatter, // only use it to format the display of the date in the date input based on locale.
  ) {
  }

  ngOnInit() {
    if (this.hasDefaultDate) {
      const now = new Date(),
        priorDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      this.fromDate = new NgbDate(priorDate.getFullYear(), priorDate.getMonth() + 1, priorDate.getDate());
      this.toDate = new NgbDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
      // console.log(this.toDate);
      this.dateChange.emit({
        startDate: this.format === 'jsDate' ? new Date(now.getFullYear(), now.getMonth(), 1) : this.formatter.format(this.fromDate),
        endDate: this.format === 'jsDate' ? now : this.formatter.format(this.toDate)
      });
    }
    if (this.maxDate) {
      const date = new Date(this.maxDate);
      this.maxDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
    if (this.minDate) {
      const date = new Date(this.minDate);
      this.minDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.setMaxDate();
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.setMaxDate();
    }
    if (this.toDate && this.fromDate) {
      this.dateChange.emit({
        startDate: this.format === 'jsDate' ? new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day) : this.formatter.format(this.fromDate),
        endDate: this.format === 'jsDate' ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : this.formatter.format(this.toDate),
      });
      this.datepicker.close();
      if (this.oneMonthRange) {
        this.maxDate = null; // dynamic max date needs to be removed
      }
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): void {
    console.log(currentValue);
    console.log(input);
    const [fromDate, toDate] = input.split('-').map(d => d.trim());
    if (fromDate && toDate) {
      const parsedFromDate = this.formatter.parse(fromDate),
        parsedToDate = this.formatter.parse(toDate);
      if (this.calendar.isValid(NgbDate.from(parsedFromDate)) && this.calendar.isValid(NgbDate.from(parsedToDate))) {
        this.fromDate = NgbDate.from(parsedFromDate);
        this.toDate = NgbDate.from(parsedToDate);
      }
    }
  }

  getDateRangeValue(fromDate, toDate) {
    if (!fromDate && !toDate) {
      return null;
    }
    return this.customDateFormatter.format(fromDate) + ' - ' + this.customDateFormatter.format(toDate);
  }

  clearSelectedDate() {
    this.fromDate = null;
    this.toDate = null;
    this.dateChange.emit({
      startDate: null,
      endDate: null
    });
  }

  setMaxDate() {
    if (this.oneMonthRange) {
      const newMax = dayjs({...this.fromDate, month: this.fromDate.month - 1}).add(30, 'day');
      // console.log(this.fromDate)
      // console.log(newMax)
      this.maxDate = new NgbDate(newMax.year(), newMax.month() + 1, newMax.date());
    }
  }
}
