/// <reference types="@angular/localize" />

import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { InitGuard } from './app/core/guards/init.guard';
import { AuthLogoutGuard } from './app/core/guards/auth-logout.guard';
import { AuthLoginGuard } from './app/core/guards/auth-login.guard';
import { AuthInterceptor } from './app/core/authentication/auth-interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AccountStatusPipe } from './app/shared/pipes/account-status.pipe';
import { ComplianceStatusPipe } from './app/shared/pipes/compliance-status.pipe';
import { LocalDatePipe } from './app/shared/pipes/local-date.pipe';
import { CpdTimePipe } from './app/shared/pipes/cpd-time.pipe';
import { AddPluralSPipe } from './app/shared/pipes/add-plural-s.pipe';
import { SmartTableColumnsService } from './app/services/smart-table-columns.service';
import { LocalDateTimePipe } from './app/shared/pipes/local-date-time.pipe';
import { ToMonthPipe } from './app/shared/pipes/toMonth.pipe';

import { Amplify } from 'aws-amplify';
import * as outputs from './amplify_outputs.json';

Amplify.configure(outputs);

if (environment.production) {
    console.log = function () { }; // Disable console.log in production
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, ToastrModule.forRoot(), NgSelectModule, AngularSvgIconModule.forRoot(), ToastrModule.forRoot({
            closeButton: true,
            titleClass: 'd-none' // hide title
        }), NgSelectModule),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'en-gb' },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: ResponseInterceptor,
        //   multi: true,
        // },
        AuthLoginGuard,
        AuthLogoutGuard,
        InitGuard,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),

        // pipes
        AccountStatusPipe,
        ComplianceStatusPipe,
        LocalDatePipe,
        LocalDateTimePipe,
        CpdTimePipe,
        AddPluralSPipe,
        SmartTableColumnsService,
        ToMonthPipe
    ]
})
    .catch(err => console.error(err));
