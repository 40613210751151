<div style="width: 225px;" id="selectDate" class="btn-group" ngbDropdown #dateDropdown="ngbDropdown"
    [autoClose]="false">
    <button aria-label="choose date" style="height: 36px;" class="btn border-neutral-500 form-control" id="date"
        ngbDropdownToggle type="button" aria-controls="event-date">
        <span class="d-flex justify-content-between align-items-center icon">
            <span class="f-small r-name-limiter-width">{{getDateOptionDisplay(selectedDate, isFullDateRange)}}</span>
        </span>
    </button>
    <ul ngbDropdownMenu class="position-absolute dropdown-menu overflow-y-auto" role="menu" aria-labelledby="button-basic" [style.max-height]="'30vh'" >
        <li *ngFor="let dateOption of DATE_OPTIONS; let index = index " class="width-380" role="menuitem"
            [class.border-top]="dateOption === DATE_OPTION_ENUM.lastWeek"
            [class.border-bottom]="dateOption === DATE_OPTION_ENUM.lastYear">
            <ng-container *ngIf="dateOption !== DATE_OPTION_ENUM.custom">
                <button ngbDropdownItem class="dropdown-item f-small d-flex justify-content-between"
                    (click)="dateDropdown.close(); chooseDate(dateOption)">
                    <span class="d-flex flex-row align-items-center">
                        {{ getDateOptionDisplay(dateOption, isFullDateRange) }}
                        <span *ngIf="dateOption === DATE_OPTION_ENUM.allTimeReport" class="ms-2 pb-1">
                            <ui-streamline-icon [ngbTooltip]="'\'All Time\' may extend loading times due to the large volume of data'"
                                [slIcon]="{ name: 'interface-alert-warning-triangle', theme: 'micro-bold', size: 'sm' }"></ui-streamline-icon>
                        </span>

                        <span class="ms-2 pb-1" *ngIf="dateOption===selectedDate">
                            <ui-streamline-icon
                                [slIcon]="{name: 'interface-validation-check', theme: 'mini-bold', size: 'sm'}">
                            </ui-streamline-icon>
                        </span>
                    </span>
                    <span class="text-neutral-700">{{ DATE_OPTION_DICT[dateOption]?.startDate.display}} -
                        {{DATE_OPTION_DICT[dateOption]?.endDate.display}}</span>
                </button>
            </ng-container>

        </li>
        <li ngbDropdownItem role="menuitem">
            <app-date-range-selector format="date" [showLabel]="false"
                (dateChange)="datePickerChanged($event);dateDropdown.close();" buttonText="Custom Date Range"
                buttonClass="btn btn-link text-decoration-none" [maxDate]="maxDate" [oneMonthRange]="oneMonthRange">
            </app-date-range-selector>
        </li>
    </ul>
</div>

<button (click)="enableAllTime()" class="d-none btn btn-success">Enable all time</button>
<button type="button" class="d-none btn btn-outline-light" (click)="oneMonthRange=!oneMonthRange">Remove date range limitation</button>

