import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaliserService {
  private _locale: string;
  private _dateFormat: string;
  private _timezone: string;

  constructor() { }

  set locale(value: string) {
    this._locale = value;
  }

  get locale() {
    return this._locale || 'en-AU';
  }

  set dateFormat(value: string) {
    this._dateFormat = value;
  }

  get dateFormat() {
    return this._dateFormat || 'DD MMM YYYY';
  }

  set timezone(value: string) {
    this._timezone = value;
  }

  get timezone() {
    return this._timezone || 'Australia/Melbourne';
  }
}
