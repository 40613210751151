import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortcutButtonNewComponent } from '../shortcut-button-new.component';
import { FilterEnables } from '../../../filter-set/filter-set.component';
import { ShortcutService } from 'src/app/services/shortcut.service';
import { ShortcutButtonService } from '../../shortcut-button.service';
import { SPINNER_BUTTON_STATUS_ENUM } from '@components/spinner-button/spinner-button.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-shortcut-button-container',
  standalone: true,
  imports: [CommonModule, ShortcutButtonNewComponent],
  templateUrl: './shortcut-button-container.component.html',
  styleUrls: ['./shortcut-button-container.component.scss']
})
export class ShortcutButtonContainerComponent {

  SPINNER_BUTTON_STATUS_ENUM = SPINNER_BUTTON_STATUS_ENUM;


  activeShortcut$ = this.shortcutService.getFilteredActiveShortcut().pipe(tap(c => console.log(c)));


  constructor(
    private shortcutService: ShortcutService,
    protected shortcutButtonService: ShortcutButtonService
  ) {}

  get filterForm() {
    return this.shortcutButtonService.filterForm;
  }
}
