import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchItemPolicyComponent } from '@components/search-item-policy/search-item-policy.component';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        SearchItemPolicyComponent
    ],
    selector: 'app-choose-policies-dropdown',
    templateUrl: 'choose-policies-dropdown.component.html',
    styleUrls: ['./choose-policies-dropdown.component.scss']
})

export class ChoosePoliciesDropdownComponent implements OnInit {
    @Input() allPolicies = [];
    @Output() policyChange = new EventEmitter();
    @Input() selectedPolicy;
    @Input() label = 'Select a policy*';
    @Input() placeholder = 'Search library for a policy';
    @Input() enableSelection = true;
    @Input() showError = false;
    @Input() multiple = false;
    @Input() selectedPolicies: any[];
    @Input() loading = false;

    constructor() {
    }

    ngOnInit() {
    }

    onPolicyChange($event) {
        this.policyChange.emit($event);
    }

    customSearchFn(term: string, item) {
        term = term.toLowerCase().trim();
        return item.title.toLowerCase().indexOf(term) > -1 || item?.code?.toLowerCase().indexOf(term) > -1 || item?.governanceID.toLowerCase().indexOf(term) > -1;
    }

    removePolicy(governanceID: string) {
        this.selectedPolicies = this.selectedPolicies.filter(item => item.governanceID !== governanceID);
        this.policyChange.emit(this.selectedPolicies);
    }
}
