<!-- <div class="m-evidence-block-container form-group" [formGroup]="evidenceForm" [ngClass]="{'evidence-background-color' : context === 'modal'}">
  <div class="p-4 rounded" >
    <p class="d-flex fw-semibold pb-4">
      New Evidence
      <app-tooltip triggers="hover" placement="right" [tooltipClass]="'custom-tooltip'" class="ms-1"
                  text="Evidence includes notes and web links to files, such as a PDF copy of a certificate. Use evidence to add context and prove learning performance. ">
      </app-tooltip>
    </p>

    <div class="pb-4">
      <p class="fw-bold pb-1 f-caption">Notes</p>
      <textarea formControlName="notes" name="evcidence-notes" id="evcidence-notes" placeholder="Add notes" maxlength="280" class="note-textarea pb-1 form-control"></textarea>
      <p class="notes-textarea-count" [class]="charactersLeft === 0 ? 'text-danger' : ''">Characters left: {{charactersLeft}}</p>
    </div>

    <div>
      <p class="fw-bold pb-2 f-caption">Weblink</p>
      <div class="d-flex justify-content-between">
        <div class="weblink-container">
          <p class="weblink-container-label pb-1">URL</p>
          <input formControlName="url" class="w-100 form-control" type="text" placeholder="https://www.example.com">
          <div *ngIf="isInvalid">
            <span class="f-caption text-danger pt-1">URL is required. Add URL or remove Title</span>
          </div>
        </div>
        <div class="weblink-container">
          <p class="weblink-container-label pb-1">Title</p>
          <input formControlName="title" class="w-100 form-control" type="text" placeholder="Add title">
        </div>
      </div>
    </div>

    <div *ngIf="context === 'popover'" class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-2" [disabled]="isInvalid || isEmpty || isSubmitting" (click)="addEvidence()">
        <ui-spinner *ngIf="isSubmitting" size="sm" class="me-1" color="light"></ui-spinner>
        Add
      </button>
    </div>

    <div *ngIf="context === 'sidepanel'" class="d-flex justify-content-start mt-2">
      <button type="button" class="btn btn-secondary me-2 fw-semibold" [disabled]="isInvalid || isEmpty || isSubmitting" (click)="addEvidence()">
        <ui-spinner *ngIf="isSubmitting" size="sm" class="me-1"></ui-spinner>
        Add
      </button>

      <button class="btn btn-outline-light fw-semibold" (click)="hiddenAddSection()">
        Cancel
      </button>
    </div>
  </div>
</div> -->

<div class="position-relative form-group" [formGroup]="evidenceForm">
  <div class="d-flex align-items-start mb-4">
    @if(showAvatar){
      <ui-avatar size="md" [fullName]="manager.userDetail?.fullName" [url]="manager.profilePicURL" class="me-3 cursor-pointer"></ui-avatar>
    }
    <div class="w-100">
      <div class="d-flex justify-content-between w-100 pb-4 gap-2">
        <div class="pe-2 w-100">
          <p class="text-neutral-700 f-small pb-1">Weblink to the Evidence</p>
          <input formControlName="url" class="w-100 form-control" type="text" placeholder="https://www.example.com">
          <div *ngIf="isInvalid">
            <span class="f-caption text-danger pt-1" style="width: 170px;">Weblink is required.Add Weblink or remove Display text</span>
          </div>
        </div>
        <div class="w-100">
          <p class="text-neutral-700 f-small pb-1">Display text for website link (Optional)</p>
          <input formControlName="title" class="w-100 form-control" type="text" placeholder="Add title">
        </div>
      </div>

      <div class="pb-4">
        <p class="text-neutral-700 f-small pb-1">Notes</p>
        <textarea formControlName="notes" name="evcidence-notes" id="evcidence-notes" cols="5"
          placeholder="Add notes" maxlength="280" class="note-textarea pb-1 form-control"></textarea>
        <p class="text-neutral-700 f-caption" [class]="charactersLeft === 0 ? 'text-danger' : ''">Characters left: {{charactersLeft}}</p>
      </div>

      <div>
        <div *ngIf="context === 'popover'" class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary me-2 btn-sm" [disabled]="isInvalid || isEmpty || isSubmitting" (click)="addEvidence()">
            <ui-spinner *ngIf="isSubmitting" size="sm" class="me-1" color="light"></ui-spinner>
            Add Evidence
          </button>
        </div>

        <div *ngIf="context === 'sidepanel'" class="d-flex justify-content-start mt-2">
          <button type="button" class="btn btn-secondary me-2 fw-semibold btn-sm" [disabled]="isInvalid || isEmpty || isSubmitting" (click)="addEvidence()">
            <ui-spinner *ngIf="isSubmitting" size="sm" class="me-1" color="light"></ui-spinner>
            Add Evidence
          </button>

          <button class="btn btn-outline-light fw-semibold btn-sm" (click)="hiddenAddSection()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
