import { ChangeDetectorRef, Component, computed, contentChild, effect, EventEmitter, Input, OnInit, Output, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerButtonService, SPINNER_BUTTON_STATUS_ENUM } from '@components/spinner-button/spinner-button.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { IFilterChange } from '../reporting-type';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { FilterEnables } from '../filter-set/filter-set.component';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { GroupService } from 'src/app/services/group.service';
import { combineLatest } from 'rxjs';
import { clone, isEmpty, pluck } from 'underscore';
import { AnalyticsService, PAGE_CONTEXT_TYPES } from 'src/app/services/analytics.service';
import { AlertBannerComponent } from '@components/alert-banner/alert-banner.component';
import { BackButtonComponent } from '@components/back-button/back-button.component';
import { EmptyStateComponent } from '@components/empty-state/empty-state.component';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';
import { DEFAULT_BUTTON_STATUS_DICT, FilterPackComponent, MANDATORY_TYPE_OPTIONS } from '../filter-pack/filter-pack/filter-pack.component';
import { GeneralReportService } from './general-report.service';
import { translateLearningGroupType } from 'src/app/shared/utils/get-analytics-body';
import { CustomDateFormatter } from 'src/app/shared/date-range-selector/custom-date-formatter';
import { ShortcutButtonContainerComponent } from '../filter-pack/shortcut-button-new/shortcut-button-container/shortcut-button-container.component';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbComponent, IMTBreadcrumbConfig } from '@components/header/breadcrumb/breadcrumb.component';
import { ReportFilterPack } from 'src/app/shared/utils/base-class/report-filter-pack';
import { ShortcutButtonService } from '../filter-pack/shortcut-button.service';

export abstract class AbstractReport {
  abstract showReport(): void
  abstract rerun(): void
}

@Component({
  selector: 'app-general-report',
  standalone: true,
  imports: [CommonModule, NgxSpinnerModule, UISpinnerComponent, BackButtonComponent, FilterPackComponent, AlertBannerComponent, EmptyStateComponent, ShortcutButtonContainerComponent, BreadcrumbComponent],
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.scss'],
  providers: [{
    provide: AbstractReport,
    useExisting: GeneralReportComponent
  }, CustomDateFormatter]
})
export class GeneralReportComponent extends WithDestroy() implements OnInit, AbstractReport {
  @Input() filterEnables: FilterEnables;
  @Input() pageContext: PAGE_CONTEXT_TYPES;
  @Input() runReportOnInit = false;
  @Input() displayStatusDict = DEFAULT_BUTTON_STATUS_DICT;
  @Input() initMandatoryTypes = pluck(MANDATORY_TYPE_OPTIONS, 'value');
  @Input() dateFilterOptions;
  @Input() filterParams: any = {};
  @Input() hideDefaultSaveFilter = false;
  @Input() contentClass = 'p-7';
  @Input() filterLabel;
  @Input() breadcrumbConfigs?: IMTBreadcrumbConfig[];
  @Output() filterChange = new EventEmitter<IFilterChange>()

  extraFilterPack = contentChild<ReportFilterPack>('extraFilterPack');
  filterPack = viewChild(FilterPackComponent);
  extraFilterForm = computed(() => this.extraFilterPack()?.filterForm)

  filterSubmitted = false;
  isDataFetched = false;
  spinnerButtonStatus;
  SPINNER_BUTTON_STATUS_ENUM = SPINNER_BUTTON_STATUS_ENUM;
  hideToast = true;
  initFilter = false;
  currentFilter: IFilterChange;

  constructor(
    private spinner: NgxSpinnerService,
    private spinnerButtonService: SpinnerButtonService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private userService: UserService,
    private groupService: GroupService,
    private analytics: AnalyticsService,
    private generalReportService: GeneralReportService,
    private route: ActivatedRoute,
    private shortcutButtonService: ShortcutButtonService,
  ) {
    super();
    effect(() => {
      this.shortcutButtonService.extraFilterForm.set(this.extraFilterForm())
    }, {allowSignalWrites: true})
  }

  ngOnInit(): void {
    this.spinnerButtonService.statusUpdate$.pipe(
      takeUntil(this.destroy$),
      filter(s => s !== undefined),
      delay(100)
    ).subscribe(status => {
      this.spinnerButtonStatus = status;
    });
    const classifiedOrgUsers$ = this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'));
    const orgTeams$ = this.groupService.fetchedCacheOrgTeams();
    const orgJobRoles$ = this.groupService.fetchedCacheOrgJobRoles();
    combineLatest([classifiedOrgUsers$, orgTeams$, orgJobRoles$]).pipe(take(1)).subscribe(() => {
      this.initFilter = true;
    })

    this.reportViewEvent()

    this.filterParams = isEmpty(this.route.snapshot.queryParams) ? this.filterParams : this.route.snapshot.queryParams;

  }

  filterTypeHandler(filterChange: IFilterChange) {
     if (this.pageContext) {
      this.analytics.resetTimer(this.pageContext);
      this.reportRunEvent(filterChange)
    }
    this.isDataFetched = false;
    this.filterSubmitted = true;
    this.spinner.show('sgr-spinner');
    this.currentFilter = clone(filterChange);
    this.filterChange.emit({...filterChange, ...this.extraFilterForm()?.value});
    this.generalReportService.setFilter({...filterChange, ...this.extraFilterForm()?.value});
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 0);
  }

  showReport() {
    this.isDataFetched = true;
    this.spinner.hide('sgr-spinner');
    if (!this.hideToast) {
      this.toastr.success('Results updated');
    }
    this.hideToast = false;
    this.timerEvent();
  }

  rerun() {
    this.hideToast = true;
    this.filterTypeHandler(this.currentFilter);
  }

  timerEvent() {
    if (this.pageContext) {
      this.analytics.pauseTimer(this.pageContext);
      this.analytics.track('Z-page-loaded', {
        context: this.pageContext,
        loadTime: this.analytics.getPageTimeDiff(this.pageContext),
      });
      this.analytics.stopTimer(this.pageContext);
    }
  }

  reportViewEvent() {
    if (this.pageContext) {
      this.analytics.track('R-report-viewed', { type: this.pageContext });
    }
  }

  reportRunEvent(filters: IFilterChange) {
    this.analytics.track('R-report-run', {
      name: this.pageContext,
      filter: translateLearningGroupType(filters.filterBy),
    });

  }


}
