<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="h-100" [ngbTooltip]="attrTitle">
    <div class="h-100" (mouseenter)="hideActionsBtn=false" (mouseleave)="hideActionsBtn=true">
        <a (click)="jumpToLink()" class="text-decoration-none" [class.no-access-link]="noAccess">
            <div class="card card-hover h-100 br-4" [ngClass]="{'p-4': !noPadding}">
                <div *ngIf="!hideIcon" class="d-flex justify-content-between w-100 pb-5" [ngClass]="{'p-4': noPadding}">
                    <ui-avatar  [slIcon]="slIcon" [url]="url" [fullName]="iconFullName"></ui-avatar>
                    <div *ngIf="tag">
                        <span class="badge badge-{{badgeColor}}" [innerHTML]="tag"></span>
                    </div>
                    <div *ngIf="actionsBtn" (click)="stopPropagation($event)" [style]="hideActionsBtn? 'opacity:0;':''">
                        <ng-content select="actionsBtn" class="zindex-popover"></ng-content>
                    </div>
                </div>
                <ng-content select="[cardTop]"></ng-content>
                <div [ngClass]="{'p-4': noPadding}">
                    <p>
                        <ng-content select="[preTitle]"></ng-content>
                       <span [ngClass]="{'card-title-underline': underlineTitleOnHover}"
                            class="fw-semibold f-{{titleSize}} text-neutral-900 me-2" [class.class-when-clickable]="route && route.length">{{title}}</span>
                       <span><ng-content select="[subheading]"></ng-content></span>
                    </p>
                    <div *ngIf="description" class="mt-2 f-caption text-neutral-800" [innerHTML]="description">
                    </div>
                    <div class="mt-2">
                        <ng-content select="[description]"></ng-content>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>
