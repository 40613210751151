import { Directive, HostBinding, HostListener } from '@angular/core';

/**
 * This directive is used for applying horizontal scrolling shadow.
 */
@Directive({
    selector: '[appScrollShadow]',
    standalone: true
})
export class ScrollShadowDirective {
  @HostBinding('class.sticky-column-left-shadow') shadow: boolean;


  constructor() { }

  @HostListener('scroll', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.fileOver = true;
    // this.fileOutside = false;
    this.shadow = true;
    // if (evt.target.scrollLeft + evt.target.offsetWidth >= evt.target.scrollWidth) {
    if (evt.target.scrollLeft === 0) {
      // console.log('end');
      this.shadow = false;

    }
  }




}
