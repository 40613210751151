export enum CATEGORY_ENUM {
  individual = 'users',
  teams = 'teams',
  jobRoles = 'jobRoles',
  jobRoleTypes = 'jobRoleTypes',
  facilities = 'Facilities',
  directManagers = 'directManagers',
}

// interface IUserCategory {
//   label: 'User';
//   value: CATEGORY_ENUM.individual;
// }
//
// interface ITeamCategory {
//   label: 'Team';
//   value: CATEGORY_ENUM.teams;
// }
//
// interface IJobRoleCategory {
//   label: 'Job Role';
//   value: CATEGORY_ENUM.jobRoles;
// }

export enum DATE_OPTION_ENUM {
  allTime = 'All Time',
  allTimeReport = 'All Time Report',
  thisWeek = 'This Week',
  lastWeek = 'Last Week',
  thisMonth = 'This Month',
  lastMonth = 'Last Month',
  // thisYear = 'Year To Date',
  thisYear = 'This Year',
  lastYear = 'Last Year',
  custom = 'Custom Date Range',
  nextYear = 'Next 1 year',
  nextMonth = 'Next 1 month',
  nextWeek = 'Next 1 week',
  thisQuarter = 'This Quarter',
  lastQuarter = 'Last Quarter'
}

// export type Category = IUserCategory | ITeamCategory | IJobRoleCategory;

