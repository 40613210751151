import { FEATURES } from 'src/app/core/features.config';

export interface IOrgResource {
  resourceID?: string;
  orgID: string;
  title?: string;
  activityType?: string;
  website?: string;
  min: number;
  status?: Status;
  createDate?: string;
  updateDate?: string;
  topicsText: string[];
  resourceLocation?: 'External' | 'Internal';
  teams?: string[];
  isPublic?: boolean;
  videoProvider?: string;
  videoUrl?: string;
  coverImageUrl?: string;
  guidelines: string[];
  description?: string;
  resourceType?: RESOURCE_TYPE_ENUM;
  expiryDate?: string;
  orgResourceDetail: IOrgResourceDetail;
  orgStandards?: string[];
  orgCapabilities?: string[];

  // for Ausmed resource
  providerType?: string;
}

export interface IOrgResourceDetail {
  content?: string;
  outcomes?: string[];
  units?: IUnit[];
  evaluation?: IOrgEvaluation;
  accreditations?: IAccreditation[];
  educators?: string[];
  code?: string;
  revisedDate?: string;
  publishDate?: string;
  scormUploaded?: Boolean;
  isShareable?: Boolean; // if true: content partner resource
  keywords?: string[];
  orgResourceExtraDetail?: {
    updatedBy?: string;
    jobRoles?: string[];
    masterResource?: string;
    subType?: SURVEY_TYPE_ENUM;
  };
}

export interface IAccreditation {
  accreditationID?: string;
  accreditorID?: string;
  accreditor?: IAccreditor;
  creditNum?: string;
  accreditedProviderName?: string;
  authIndividualName?: string;
  authIndividualPosition?: string;
  authIndividualSigImgPath?: string;
  authIndividualPreNominal?: string;
  authIndividualPostNominal?: string;
}

export interface IAccrediationConfig {
  accreditorID: string;
  isEnabled: boolean;
  accreditedProviderName: string;
  authIndividualName: string;
  authIndividualPosition: string;
  authIndividualSigImgPath: string;
  authIndividualPreNominal?: string;
  authIndividualPostNominal?: string;
}

export interface IAccreditor {
  accreditorID: string;
  name: string;
  code: string;
  creditUnit: string;
  creditUnitAbbr: string;
  creditFormat: string;
}

export interface IOrgEvaluation {
  resourceID: string;
  evaluationID?: string;
  sections: IOrgEvaluationSection[];
}

export interface IOrgEvaluationSection {
  sectionID: string;
  evaluationID: string;
  order: number;
  title: string;
  questions: IOrgEvaluationQuestion[];
}

export interface IOrgEvaluationQuestion {
  questionID?: string;
  sectionID?: string;
  title?: string;
  order?: number;
  qType?: 'single-answer' | 'multi-answers' | 'short-answer' | 'choice-with-text';
  options?: string[];
  choiceRequiresText?: number; // index of the option which is a option require text
}

export interface IUnitForm {
  unitID?: string;
  resourceID: string;
  order: number;
  name: string;
  unitContent?: string;
}

export interface IUnit {
  unitID: string;
  resourceID: string;
  order?: number;
  name?: string;
  unitContent?: string;
  learningObjects?: ILearningObject[];
}

export interface ILearningObjectForm {
  objectID?: string;
  unitID: string;
  learningType: RESOURCE_TYPE_ENUM;
  name?: string;
  content?: string;
  videoProvider?: string;
  videoUrl?: string;
  webUrl?: string;
  fileUrl?: string;
  min?: number;
  references?: string[];
  linkedResource?: string;
  linkedResourceObj?: any;
  isGraded?: boolean;
  passMark?: number;
  attemptTime?: number;
  recoverProgressPoint?: string; // objectID
  inlineAssessment?: string;
  order?: number;
}

export interface ILearningObject {
  objectID: string;
  unitID: string;
  learningType: RESOURCE_TYPE_ENUM;
  name?: string;
  min?: number;
  references?: string[];
  linkedResource?: string;
  linkedResourceObj?: any;
  order?: number;
  videoProvider?: string; // Temporary solution, bring back all fields.
  videoUrl?: string;
  content?: string;
  webUrl?: string;
  fileUrl?: string;
  isGraded?: boolean;
  passMark?: number;
  attemptTime?: number;
  recoverProgressPoint?: string;
  inlineAssessment?: boolean;
  questions?: IQuizQuestion[];
}


export type LearningObject = ITextObject | IVideoObject | IWeblinkObject | IFileObject | IAssessmentObject;

export interface IVideoObject extends ILearningObject {
  videoProvider: string;
  videoUrl: string;
}

export interface ITextObject extends ILearningObject {
  content: string;
}

export interface IWeblinkObject extends ILearningObject {
  webUrl: string;
}

export interface IFileObject extends ILearningObject {
  fileUrl: string;
}

export interface IAssessmentObject extends ILearningObject {
  passMark?: number;
  attemptTime?: number;
  recoverProgressPoint?: string;
  inlineAssessment?: boolean;
  questions?: IQuizQuestion[];
}

export type IQuizQuestionType =
  'single-answer'
  | 'multi-answers'
  | 'true/false'
  | 'private-reflection'
  | 'short-answer';

export type IQuestionTypeDisplayString = 'Multiple Choices - Single Answer' | 'Multiple Choices - Multiple Answers' | 'True or False' | 'Short Answer';

export type IFormQuestionType =
  IQuizQuestionType
  | 'dropdown'
  | 'number'
  | 'ratings'
  | 'date-picker'
  | 'upload';

export type ISurveyQuestionTypeDisplay = 'Short Answer' | 'Long Answer' | 'Multiple choice' | 'Checkbox' | 'Dropdown' |
  'Number Field' | 'Rating (1 to 10)' | 'Date Picker' | 'File Upload' | 'True or False'

export interface IQuizQuestion {
  questionID?: string;
  objectID?: string;
  qType?: IFormQuestionType;
  optRationales?: string[];
  order?: number;
  text?: string;
  rationale?: string;
  options?: string[];
  answer?: number;
  multipleAnswers?: number[];
  isSkippable?: boolean; // forms only
  isRequired?: boolean; // forms only, not a backend attribute, just used to create reactive form
}

export interface ICourseAttempt {
  attemptID: string;
  attemptNum?: number;
  completedDate: string;
  isPassed: boolean;
  learningObjectID: string;
  progress: string[];
  progressID: string;
  resourceID: string;
  savedAnswer: string;
  startDate: string;
  userID: string;
}

export interface IOrgCourseAnswer {
  answerOption?: number | string;
  multipleAnswers?: number[];
  answerText?: string;
  questionID: string;
  isAnonymous?: boolean;
  isPretest?: boolean;
  answerDate?: string;
  answerVerification?: 'indeterminate' | 'incorrect' | 'correct';
}

export interface ICopyOrgResourceForm {
  title?: string;
  description?: string;
}

export enum RESOURCE_TYPE_ENUM {
  practicalActivity = 'practical', // a special byo resource
  article = 'article',
  video = 'video',
  onlineCourse = 'onlineCourse', // BYO
  ausmedCourse = 'OnlineCourse',
  form = 'form',
  weblink = 'weblink',
  file = 'file',
  assessment = 'assessment',
  // below are Ausmed Resource Type:
  lecture = 'onlineResource',
  blog = 'blog',
  explainer = 'explainer',
  scorm = 'SCORM', // org scorm on scorm cloud
  guideToPractice = 'guideToPractice',
  podcast = 'podcast',
  // 3rd party
  thirdVideo = 'thirdVideo',
  thirdPodcast = 'thirdPodcast',
  thirdWebinar = 'webinar',
  thirdInService = 'in-service',
  thirdProcedure = 'procedure',
  thirdMentoring = 'mentoring',
  thirdCompetency = 'competency',
  thirdPostgraduate = 'postgraduate',
  thirdMeeting = 'meeting',
  thirdTeaching = 'teaching',
  thirdPeer = 'peer consultation',
  thirdOther = 'other',
}

export enum RESOURCE_TYPE_DISPLAY_ENUM {
  practicalActivity = 'Practical Activity', // a special byo resource
  course = 'Course',
  form = 'Survey',
  article = 'Article',
  video = 'Video',
  lecture = 'Lecture',
  guideToPractice = 'Guide to Practice',
  weblink = 'Weblink',
  file = 'File',
  assessment = 'assessment',
  explainer = 'Explainer',
  scorm = 'SCORM', // org scorm on scorm cloud
  podcast = 'Podcast',
  // 3rd party
  thirdVideo = 'Video',
  thirdPodcast = 'Podcast',
  thirdWebinar = 'Webinar',
  thirdInService = 'In-Service',
  thirdProcedure = 'Procedure',
  thirdMentoring = 'Mentoring',
  thirdCompetency = 'Competency',
  thirdPostgraduate = 'Postgraduate',
  thirdMeeting = 'Meeting',
  thirdTeaching = 'Teaching',
  thirdPeer = 'Peer Consultation',
  thirdOther = 'Other',
}

export const resourceTypeDict: Record<RESOURCE_TYPE_ENUM, RESOURCE_TYPE_DISPLAY_ENUM> = {
  [RESOURCE_TYPE_ENUM.practicalActivity]: RESOURCE_TYPE_DISPLAY_ENUM.practicalActivity,
  [RESOURCE_TYPE_ENUM.ausmedCourse]: RESOURCE_TYPE_DISPLAY_ENUM.course, // Ausmed course
  [RESOURCE_TYPE_ENUM.onlineCourse]: RESOURCE_TYPE_DISPLAY_ENUM.course, // byo course
  [RESOURCE_TYPE_ENUM.form]: RESOURCE_TYPE_DISPLAY_ENUM.form, // form
  [RESOURCE_TYPE_ENUM.article]: RESOURCE_TYPE_DISPLAY_ENUM.article, // byo
  [RESOURCE_TYPE_ENUM.blog]: RESOURCE_TYPE_DISPLAY_ENUM.article, // Ausmed
  [RESOURCE_TYPE_ENUM.video]: RESOURCE_TYPE_DISPLAY_ENUM.video, // byo
  [RESOURCE_TYPE_ENUM.lecture]: RESOURCE_TYPE_DISPLAY_ENUM.lecture, // Ausmed
  [RESOURCE_TYPE_ENUM.guideToPractice]: RESOURCE_TYPE_DISPLAY_ENUM.guideToPractice, // Ausmed
  [RESOURCE_TYPE_ENUM.weblink]: RESOURCE_TYPE_DISPLAY_ENUM.weblink,
  [RESOURCE_TYPE_ENUM.file]: RESOURCE_TYPE_DISPLAY_ENUM.file,
  [RESOURCE_TYPE_ENUM.assessment]: RESOURCE_TYPE_DISPLAY_ENUM.assessment,
  [RESOURCE_TYPE_ENUM.explainer]: RESOURCE_TYPE_DISPLAY_ENUM.explainer,
  [RESOURCE_TYPE_ENUM.scorm]: RESOURCE_TYPE_DISPLAY_ENUM.scorm, // org scorm on scorm cloud
  [RESOURCE_TYPE_ENUM.podcast]: RESOURCE_TYPE_DISPLAY_ENUM.podcast,
  // 3rd party types
  [RESOURCE_TYPE_ENUM.thirdVideo]: RESOURCE_TYPE_DISPLAY_ENUM.thirdVideo,
  [RESOURCE_TYPE_ENUM.thirdPodcast]: RESOURCE_TYPE_DISPLAY_ENUM.thirdPodcast,
  [RESOURCE_TYPE_ENUM.thirdWebinar]: RESOURCE_TYPE_DISPLAY_ENUM.thirdWebinar,
  [RESOURCE_TYPE_ENUM.thirdInService]: RESOURCE_TYPE_DISPLAY_ENUM.thirdInService,
  [RESOURCE_TYPE_ENUM.thirdProcedure]: RESOURCE_TYPE_DISPLAY_ENUM.thirdProcedure,
  [RESOURCE_TYPE_ENUM.thirdMentoring]: RESOURCE_TYPE_DISPLAY_ENUM.thirdMentoring,
  [RESOURCE_TYPE_ENUM.thirdCompetency]: RESOURCE_TYPE_DISPLAY_ENUM.thirdCompetency,
  [RESOURCE_TYPE_ENUM.thirdPostgraduate]: RESOURCE_TYPE_DISPLAY_ENUM.thirdPostgraduate,
  [RESOURCE_TYPE_ENUM.thirdMeeting]: RESOURCE_TYPE_DISPLAY_ENUM.thirdMeeting,
  [RESOURCE_TYPE_ENUM.thirdTeaching]: RESOURCE_TYPE_DISPLAY_ENUM.thirdTeaching,
  [RESOURCE_TYPE_ENUM.thirdPeer]: RESOURCE_TYPE_DISPLAY_ENUM.thirdPeer,
  [RESOURCE_TYPE_ENUM.thirdOther]: RESOURCE_TYPE_DISPLAY_ENUM.thirdOther,
};

export const resourceTypeIconDict = {
  [RESOURCE_TYPE_ENUM.assessment]: 'fa-clipboard',
  [RESOURCE_TYPE_ENUM.practicalActivity]: 'fa-users-medical',
  [RESOURCE_TYPE_ENUM.ausmedCourse]: 'fa-books', // Ausmed course
  [RESOURCE_TYPE_ENUM.onlineCourse]: 'fa-books', // byo course
  [RESOURCE_TYPE_ENUM.article]: 'fa-newspaper', // byo
  [RESOURCE_TYPE_ENUM.blog]: 'fa-newspaper', // Ausmed
  [RESOURCE_TYPE_ENUM.video]: 'fa-video', // byo
  [RESOURCE_TYPE_ENUM.lecture]: 'fa-books', // Ausmed
  [RESOURCE_TYPE_ENUM.guideToPractice]: 'fa-books', // Ausmed
  [RESOURCE_TYPE_ENUM.weblink]: 'fa-link',
  [RESOURCE_TYPE_ENUM.file]: 'fa-file-alt',
  [RESOURCE_TYPE_ENUM.explainer]: 'fa-books',
  [RESOURCE_TYPE_ENUM.scorm]: 'fa-file-archive', // org scorm on scorm cloud
  [RESOURCE_TYPE_ENUM.podcast]: 'fa-podcast',
  [RESOURCE_TYPE_ENUM.form]: 'sl-task-list-pen' // not an fa icon
};

export enum EVALUATION_QUESTION_TYPE_ENUM {
  singleAnswer = 'single-answer',
  multipleAnswers = 'multi-answers',
  shortAnswer = 'short-answer',
  choiceWithText = 'choice-with-text'
}

export enum SURVEY_TYPE_ENUM {
  selfAssessment = 'selfAssessment',
  needsAssessment = 'needsAssessment',
  evaluation = 'evaluation',
  performanceReview = 'performanceReview',
  other = 'other',
}

export const SURVEY_DISPLAY_DICT: Record<SURVEY_TYPE_ENUM, string> = {
  [SURVEY_TYPE_ENUM.evaluation]: 'Evaluation',
  [SURVEY_TYPE_ENUM.needsAssessment]: 'Needs Assessment',
  [SURVEY_TYPE_ENUM.selfAssessment]: 'Self Assessment',
  [SURVEY_TYPE_ENUM.performanceReview]: 'Performance Review',
  [SURVEY_TYPE_ENUM.other]: 'Other',
};

export enum PRACTICAL_TYPE_ENUM {
  competency = 'Competency',
  inService = 'In-Service',
  other = 'Other',
}

export const PRACTICAL_DISPLAY_DICT: Record<PRACTICAL_TYPE_ENUM, string> = {
  [PRACTICAL_TYPE_ENUM.competency]: 'Competency',
  [PRACTICAL_TYPE_ENUM.inService]: 'In-Service',
  [PRACTICAL_TYPE_ENUM.other]: 'Other',
};

export type ALL_SUBTYPE_ENUM = SURVEY_TYPE_ENUM | PRACTICAL_TYPE_ENUM;

export type Status = 'Published' | 'Draft' | 'Unpublished';

export const RESOURCE_NAME_TYPE = [
  {
    name: 'Article',
    slIcon: { name: 'newspaper-fold', theme: 'bold' },
    type: RESOURCE_TYPE_ENUM.article,
    feature: FEATURES.byoLibrary,
    description: 'Write an article for your organisation\'s library',
  }, {
    name: 'Course',
    slIcon: { name: 'book-open-bookmark', theme: 'bold' },
    type: RESOURCE_TYPE_ENUM.onlineCourse,
    feature: FEATURES.courseBuilder,
    description: 'Create a course for your organisation\'s library',
  },
  // {
  //   name: 'Form',
  //   slIcon: { name: 'task-list-pen', theme: 'bold' },
  //   type: RESOURCE_TYPE_ENUM.form,
  //   feature: FEATURES.courseBuilder, // TODO: @Eddy just need to be decided future
  //   beta: true
  //   // isNew: true
  // },
  {
    name: 'File',
    slIcon: { name: 'common-file-text-alternate', theme: 'bold' },
    // icon: 'far fa-file-alt',
    type: RESOURCE_TYPE_ENUM.file,
    feature: FEATURES.byoLibrary,
    description: 'Upload a file to your organisation\'s library',
  }, {
    name: 'Weblink',
    slIcon: { name: 'hyperlink-3', theme: 'bold' },
    type: RESOURCE_TYPE_ENUM.weblink,
    feature: FEATURES.byoLibrary,
    description: 'Link to a website from your organisation\'s library',
  }, {
    name: 'Video',
    slIcon: { name: 'video-player-alternate', theme: 'bold' },
    type: RESOURCE_TYPE_ENUM.video,
    feature: FEATURES.byoLibrary,
    description: 'View a video in your organisation\'s library',
  }, {
    name: 'SCORM',
    slIcon: { name: 'folder-upload-alternate', theme: 'bold' },
    type: RESOURCE_TYPE_ENUM.scorm,
    feature: FEATURES.scormCloud,
    description: 'Add a SCORM file to your organisation\'s library',
  }
];

export enum BYO_RESOURCE_STATUS_ENUM {
  draft = 'Draft',
  published = 'Published',
  unpublished = 'Unpublished',
}

export function isPartnerSharedResource(resource: IOrgResource): boolean {
  return Boolean(resource.orgID && resource.orgID !== localStorage.getItem('orgID'));
}

export function isPartnerResource(resource: IOrgResource): boolean {
  return Boolean(resource.orgID && resource?.orgResourceDetail?.isShareable);
}

export function isOrgResource(resource: IOrgResource): boolean {
  return resource.orgID === localStorage.getItem('orgID');
}

