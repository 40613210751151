import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { IOrgUser, UserService } from './user.service';
import { IContract, OrganisationService } from './organisation.service';
import { BroadcastService } from './broadcast.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { MENU_TYPES, PRODUCT_TYPES } from '../core/guards/mode.constants';
import { ScheduleService } from './schedule.service';
import { IOrganisation } from '../pages/settings/interfaces/IOrganisation';
import { LocaliserService } from './localiser.service';
import { AnalyticsService } from './analytics.service';
import { FeatureFlagService } from './feature-flag.service';
import { featureConfigs, FEATURES } from '../core/features.config';
import { MenuService } from './menu.service';
import { DayjsService } from './dayjs.service';
import { getIdentifyBody } from '../shared/utils/get-analytics-body';
import { camelCase } from 'lodash';
import { deepClone } from '../shared/utils/deepclone';
import { OrgSubscriptionService } from './org-subscription.service';
import { OrgResourceService } from './org-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { appcueTrack } from '../pages/settings/organisation-settings/organisation.component';
import { pluck } from 'underscore';
import { environment } from 'src/environments/environment';
import { orgInTheFeatureOrgList, PERFORMANCE_REVIEW_ORG_LIST } from '../shared/utils/orgList/org-list-by-feature';

declare var ChurnZero: any;

export const churnZeroOrgBlacklist = ['0', '2MTRIAL', 'ACCOUNTS', 'ACP', 'ACT', 'ACV', 'ADS', 'AH', 'AMAK', 'ANDROGOGIC', 'ANGSA', 'AOHC', 'APAVIC', 'ARMY.TRIAL', 'AROHA', 'AUSMED', 'AUSMED - PRESDEMO', 'AUSMEDCR', 'AUSMEDLMS', 'AUSMEDRAC', 'BCWA', 'BELLEVUE', 'BES', 'BETTER', 'Blue', 'BRAB', 'BRD', 'BRENT', 'BWRAC', 'CAB', 'CBRDG', 'CCSS', 'CHRON', 'CIRCLESC', 'CIT', 'COMSSA', 'CROWNM', 'CROWNP', 'CSI', 'CTEC', 'CTOLIBRARY', 'CUTIS', 'CXDEMO', 'CXOB', 'DDAC', 'DEMO', 'DESIGNTEAM', 'DISOPAUS', 'DUN', 'EAH', 'ECSC', 'EIP', 'ELROI', 'EMRLD', 'ELEVATE', 'EVO', 'FEROS', 'GARDCARE', 'GBMS', 'GUPSF', 'HCPC', 'HDI', 'HFHL', 'HNSDH', 'HOLI', 'HSW', 'ITC', 'IRT', 'JACA', 'JAH', 'JASMINE', 'JDFPGMed', 'JHC', 'KYC', 'LDK', 'LHC', 'LONG', 'LSCS', 'MACG', 'MCUNIC', 'MEDIBANK - PILOT', 'MHAC', 'MINK', 'MMG', 'MRH', 'MYCARE', 'NAPSA', 'NCM', 'NHCP', 'NMS', 'NPLS', 'NWRH', 'NYP.TRIAL', 'nz - demo', 'OAP', 'OAPC', 'OCHL', 'PAED', 'PARC', 'PIHC', 'PRFCT', 'PTERRY', 'PYRMONTE', 'QCNA', 'QUIC', 'RAHCQ', 'RAHSYDCE', 'RALAC', 'RCARE', 'REXC', 'RFDSSE', 'RGARD', 'RIVER', 'RSNS', 'SAL', 'SCORM', 'SDKCR', 'SHF', 'SIMPHI', 'SOH', 'SOMH', 'SRSL', 'SSC', 'SUNNY', 'SWHMC', 'TCCH', 'TDSS', 'TOORAK', 'testsola', 'TLCD', 'TRED', 'TRICOL', 'TSS', 'TTHA', 'TTHC', 'VDS', 'VHHS', 'WACHS', 'WAVE', 'WCCS', 'WCIHC', 'WRS', 'XIMTEST', 'YVAC'];

@Injectable({
  providedIn: 'root'
})
export class InitializeService {

  constructor(
    private localiser: LocaliserService,
    private userService: UserService,
    private orgService: OrganisationService,
    private broadcastService: BroadcastService,
    private scheduleService: ScheduleService,
    private analytics: AnalyticsService,
    private featureFlag: FeatureFlagService,
    private menuService: MenuService,
    private dayJsService: DayjsService,
    private featureFlagService: FeatureFlagService,
    private orgSubscriptionService: OrgSubscriptionService,
    private orgResourceService: OrgResourceService,
    private spinnerService: NgxSpinnerService
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.initStatus$.next(false);
      this.initLearningStatus$.next(false);
    });
  }

  initStatus$ = new BehaviorSubject<boolean>(false);
  initLearningStatus$ = new BehaviorSubject<boolean>(false);

  // decoupled version of init function
  loginInit(initPayload: InitPayload) {
    // sync actions
    // console.log(initPayload);
    if (!initPayload.currentContract) {
      return throwError('No active contract found');
    }


    const { user, org, orgUser, currentContract: contract } = initPayload;
    const orgID = org.orgID;
    const { contractType, enableMT, features } = contract;

    this.userService.setUser(user);
    this.userService.setOrgUser(orgUser);
    this.orgService.setOrganisation(org);
    this.orgService.setActiveContract(contract);
    this.orgService.setContractType(contractType);
    this._setFeatures(contractType, features, enableMT, contract);
    this.setMenuItems(contractType, orgUser.isManager);
    this._setTimeZone(org.timezone);
    this.analytics.identify(orgUser.userID, getIdentifyBody(orgUser, org, contract, {
      orgService: this.orgService,
      featureFlagService: this.featureFlagService,
      userService: this.userService
    }));
    this.initChurnZero(org.code, orgUser.orgEmail);
    this.userService.isManager = initPayload.orgUser.isManager;
    this.userService.managedTeams = initPayload.orgUser.orgUserDetail?.managedAllTeams || [];
    this.userService.managedTeamModels = initPayload.orgUser.orgUserDetail?.managedTeamModels || [];

    return of(initPayload);
  }

  // data for learning/admin tab
  initLearning(initPayload?: InitPayload) {
    // const { user, org, orgUser, currentContract: contract } = initPayload;
    // const { contractType, enableMT, features } = contract;
    // const orgID = org.orgID;

    if (this.initLearningStatus$.getValue()) {
      return of(null);
    }
    const contractType = this.orgService.getContractType();
    const contract = this.orgService.plainActiveContract;
    const orgUser = this.userService.orgUser;

    const orgUsers$ = this.userService.fetchOrgUsers(localStorage.getItem('orgID'));
    const schedule$ = contractType === PRODUCT_TYPES.LIBRARY ? this.scheduleService.fetchAllScormScheduleByOrg(localStorage.getItem('orgID')) : of(null);
    const orgResourceKeywordGroups$ = (contract?.billing?.products || []).find(p => p.contentOrgID) ? this.orgResourceService.fetchOrgResourceKeywordGroups() : of([])
    this._setInitStatus(true);

    return combineLatest([orgUsers$, schedule$, orgResourceKeywordGroups$]).pipe(
      tap(
        ([orgUsers, schedules, keywordGroups]) => {
          // this._setInitStatus(true);

          // appcues
          if (orgUsers.active?.length || orgUsers.invited?.length) {
            try {
              (window as any).Appcues.track('appcues-org-send-invitation-found');
            } catch (e) {
              console.log(e);
            }
          }

          if (this.userService.isManager && this.featureFlag.featureOn('stripeBilling')) {
            this.orgSubscriptionService.getOrgStripeUser(localStorage.getItem('orgID')).pipe(
              switchMap(() => this.orgSubscriptionService.getOrgSubscriptionInvoices(localStorage.getItem('orgID'))),
              switchMap(() => this.orgSubscriptionService.processingUnpaidInvoices)
            ).subscribe(
              (processing) => {
                if (!processing) {
                  this.setMenuItems(contractType, this.userService.isManager, true, keywordGroups.length > 0 ? keywordGroups : null);
                }
              }
            );
          }

          if (keywordGroups.length > 0) {
            this.setMenuItems(contractType, this.userService.isManager, true, keywordGroups);
          }
          this.initLearningStatus$.next(true);

          // Fire appcues events for all saved events
          const existingAppcuesEvents = initPayload?.org?.lmsSetting?.appcuesEvents || [];
          existingAppcuesEvents.forEach(event => {
            appcueTrack(event);
          });

          //   (window as any).Appcues.group(
          //     orgID, // unique, required
          //     {
          //       appcuesEvents: existingAppcuesEvents.join(', '),
          //     }
          // );
        }

      ));
  }

  init(initPayload: InitPayload): Observable<InitPayload> {
    return this.loginInit(initPayload).pipe(
      switchMap(() => this.initLearning(initPayload)),
      map(() => initPayload),
      tap(() => {
        this._setInitStatus(true);
      })
    );
  }


  public getInitStatus(): Observable<any> {
    return this.initStatus$.asObservable();
  }

  public getInitLearningStatus(): Observable<any> {
    return this.initLearningStatus$.asObservable();
  }

  private _setInitStatus(status: boolean): void {
    this.initStatus$.next(status);
  }

  private _setFeatures(contractType: PRODUCT_TYPES, customisedFeatures: string[] = [], enableMT = false, contract: IContract) {
    // set feature configs
    if (!Object.values(PRODUCT_TYPES).includes(contractType)) {
      throw new Error('The contract type does not exist.');
    } else {
      if (!featureConfigs[contractType]) {
        throw new Error('The configuration for this contract type does not exist.');
      }
      const featureConfig = featureConfigs[contractType] ? deepClone(featureConfigs[contractType]) : deepClone(featureConfigs[PRODUCT_TYPES.LMS]);

      // disable MT if enableMT flag == false OR no LMS product in billing.products when contractType is premium
      featureConfig.features[FEATURES.MT] =
        enableMT === false ||
          (contractType === PRODUCT_TYPES.LMS && !(pluck(contract.billing?.products || [], 'productName').includes('LMS')))
          ? false
          : true;
      if ((contract?.billing?.products || []).find(p => p.contentOrgID)) {
        featureConfig.features[FEATURES.partnerContent] = true;
      }
      if ((contract?.billing?.products || []).find(p => p.productName === 'Policy')) {
        featureConfig.features[FEATURES.policyAndProcedure] = true;
      } else {
        featureConfig.features[FEATURES.policyAndProcedure] = false;
      }
      if ((contract?.billing?.products || []).find(p => p.productName === 'Ausmed Competency')) {
        featureConfig.features[FEATURES.competencyLibrary] = true;
      } else {
        featureConfig.features[FEATURES.competencyLibrary] = false;
      }
      if (customisedFeatures?.length) {
        for (const feature of customisedFeatures) {
          if (feature.slice(0, 7) === 'disable') {
            const disabledFeature = camelCase(feature.slice(7));
            featureConfig.features[disabledFeature] = false;
          } else {
            featureConfig.features[feature] = true;
          }
        }
      }
      console.log(contract)
      if (contract?.metaData?.bundles || contract?.metaData?.purchasedResources) {
        featureConfig.features[FEATURES.limitedResourceAccess] = true;
      }
      if (pluck(contract.billing?.products || [], 'productName').includes('Performance Review')) {
        featureConfig.features[FEATURES.performance] = true;
      }
      if (pluck(contract.billing?.products || [], 'productName').includes('Analytics')) {
        featureConfig.features[FEATURES.analyticsReport] = true;
      }
      console.log(featureConfig)
      this.featureFlag.setConfig(featureConfig);
    }
  }

  public setMenuItems(contractType: PRODUCT_TYPES, isManager: boolean, update: boolean = false, orgResourceKeywordGroups = []) {
    if (!Object.values(PRODUCT_TYPES).includes(contractType)) {
      throw new Error('The contract type does not exist.');
    } else {
      this.menuService.setMenuItems(contractType, isManager, update, orgResourceKeywordGroups);
    }
  }

  private _setTimeZone(orgTimezone) {
    const country = orgTimezone ? orgTimezone.split('/').shift() : '';
    switch (country) {
      case 'America':
        this.localiser.dateFormat = 'YYYY-MM-DD';
        this.localiser.locale = 'en-US';
        break;
      default:
        this.localiser.dateFormat = 'DD MMM YYYY';
        this.localiser.locale = 'en-AU';
    }
    this.localiser.timezone = orgTimezone;
    this.dayJsService.dayJsInit();
  }


  private initChurnZero(orgCode, orgEmail = '') {
    if (environment.production && churnZeroOrgBlacklist.map(o => o.toUpperCase()).indexOf(orgCode.toUpperCase()) < 0) {
      ChurnZero.push(['setAppKey', environment.ChurnZeroKey]); // AppKey from ChurnZero
      ChurnZero.push(['setContact', orgCode, orgEmail]);
    }
  }
}


export interface InitPayload {
  user: any;
  orgUser: IOrgUser;
  currentContract: IContract;
  org: IOrganisation;
}
