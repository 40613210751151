import { Injectable } from '@angular/core';
import { GroupService, ITeam } from './group.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Dictionary, clone, indexBy, keys, sortBy, values } from 'underscore';
import { IFacility, IFacilityMultiSelect, LOCATION_TYPE_DISPLAY, locationType } from '../pages/manage-location/admin-location-types';
import { IOrgUser, UserService } from './user.service';
import { Observable, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService {
  globalOrgFacilities: Dictionary<IFacility>;
  sortedGlobalFacilities: IFacility[];
  facilitiesOnly: Dictionary<IFacility>;

  constructor(
    private groupService: GroupService,
    private userService: UserService,
  ) {
    // ensure facility dic is initialised as soon as org team is fetched
    this.groupService.getOrgTeams().pipe(
      filter(v => !!v),
      switchMap(() => this.fetchCachedFacilities().pipe(take(1))),
      // tap(() => console.log('refreshing'))
    ).subscribe()
  }

  fetchCachedFacilities() {
    return combineLatest([this.userService.fetchCachedOrgUsers(), this.groupService.fetchedCacheOrgTeams(false)]).pipe(
      map(([u, teams]) => values(this.groupService.globalTeamsLocationDict)),
      map((teams: ITeam[]) => teams.filter(t => t.teamType?.startsWith('org_location_'))),
      map((locations: IFacility[]) => {
        return this._extendLocation(locations);
      }),
      tap((facilities: IFacility[]) => {
        this.sortedGlobalFacilities = this.sortFacilities(facilities)
        this.globalOrgFacilities = indexBy(facilities, 'teamID');
        this.facilitiesOnly = indexBy(facilities
          // .filter(f => this.userService.isManager || this.userService.managedTeams.includes(f.teamID))
          .filter(f => f.teamType === 'org_location_facility')
          , 'teamID')
      }),
    )
  }

  private _extendLocation(locations: IFacility[]): IFacility[] {
    const dict = indexBy(locations, 'teamID');
    return locations.map(l => {
      const { descendantFacilities, allDescendants } = this.getDescendantFacilities(l, dict);
      const allMembers = this.getAllMembers(descendantFacilities);
      const allManagers = this.getAllTeamManagers(allDescendants);
      return {
        ...l,
        descendantFacilities,
        allMembers,
        allDescendants,
        allManagers,
        managerIDs: this.userService.userList.filter(u => u.managedTeams?.includes(l.teamID)).map(u => u.orgUserID),
        upperStructure: this.getUpperStructure(l, dict),
        upperManagedTeam: this.userService.isManager ? l.upperTeam : (this.userService.managedTeams.includes(l.upperTeam) ? l.upperTeam : null),
        locationTypeDisplay: LOCATION_TYPE_DISPLAY[l.teamType]
      }
    })
  }

  fetchManagedFacilities() {
    return this.fetchCachedFacilities().pipe(
      map((locations: IFacility[]) => {
        const managedLocations = locations.filter(l => this.userService.isManager ? true : this.userService.managedTeams.includes(l.teamID));
        return managedLocations;
      })
    )
  }

  // bfs traverse to get all descendant facilities including itself
  getDescendantFacilities(team: ITeam, facilityDic: Record<string, ITeam>) {
    const queue = [team.teamID];
    const visited = new Set();
    const descendantFacilities = [];
    const allDescendants = [];
    while (queue.length) {
      const vertex = facilityDic[queue.shift()];
      if (!visited.has(vertex.teamID)) {
        visited.add(vertex.teamID);
        allDescendants.push(vertex.teamID);
        if (vertex.teamType === 'org_location_facility') {
          descendantFacilities.push(vertex.teamID);
        }
        if (vertex.lowerTeams?.length) {
          for (const child of vertex.lowerTeams) {
            queue.push(child);
          }
        }
      }
    }
    return { descendantFacilities, allDescendants };
  }

  // both team and facilities
  getAllTeamUsers(team: string) {
    return this.globalOrgFacilities[team]?.allMembers?.map(u => this.userService.managedOrgUserDictionaryByOrgUserID[u])
      || this.groupService.getTeamMembers([team])
  }


  getAllMembers(teamIDs: string[]) {
    return values(this.userService.managedOrgUserDictionaryByOrgUserID)
      .filter(u => u.facilities)
      .filter(u => u.facilities.some(t => teamIDs.includes(t)))
      .map(u => u.orgUserID)
  }

  // generate facilities options,
  // Example: this.locationOptions = facilities.map(f => this.facilitiesService.convertFacilityToFlatArray(f)).filter(fs => fs.length).flat();
  convertFacilityToFlatArray(facility: IFacility): IFacility[] {
    if (facility.upperManagedTeam) {
      return [];
    }
    const DFSOrderedFacilities: IFacility[] = [];
    const level = 0;

    const DFSFunc = (f, level) => {
      f['level'] = level;
      DFSOrderedFacilities.push(f);
      level++;
      if (f.lowerTeams?.length) {
        for (let fChildID of f.lowerTeams) {
          const fChild = this.globalOrgFacilities[fChildID];
          DFSFunc(fChild, level);
        }
      } else {
        return;
      }
    }
    DFSFunc(facility, level);
    return DFSOrderedFacilities;
  }

  isLocation(facilityOrTeam: ITeam): boolean {
    if (!facilityOrTeam?.teamType) {
      return false;
    }
    return !!LOCATION_TYPE_DISPLAY[facilityOrTeam.teamType];
  }

  getUserPermission(u: IOrgUser) {
    return u.isManager ? 'Organisation Manager'
      : !u.managedTeams?.length ? 'Learner'
        : u.managedTeams.some(t => this.groupService.globalTeamsDict[t]) ? 'Team Manager'
          : 'Location Manager';
  }

  getAllTeamManagers(teamIDs: string[]) {
    return values(this.userService.allTeamManagersOfManagedTeams)
      .filter(u => u.managedTeams)
      .filter(u => u.managedTeams.some(t => teamIDs.includes(t)))
    // .map(u => u.orgUserID)
  }

  getUpperStructure(location: IFacility, dict: Dictionary<IFacility>) {
    let ans = location.name;
    let v = location;
    while (v?.upperTeam) {
      const parent = dict[v?.upperTeam];
      ans = parent?.name + ' / ' + ans;
      v = parent
    }
    return ans;
  }

  sortFacilities(facilities: IFacility[]) {
    return facilities
      // highest level first
      .sort((a, b) => {
        const aIndex = keys(LOCATION_TYPE_DISPLAY).findIndex(l => l === a.teamType);
        const bIndex = keys(LOCATION_TYPE_DISPLAY).findIndex(l => l === b.teamType);
        return aIndex - bIndex;
      })
      // location with children first
      .sort((a, b) => {
        const aChildren = a.lowerTeams?.length || 0;
        const bChildren = b.lowerTeams?.length || 0
        return bChildren - aChildren;
      })
  }

  getLocationStructureData(managedOnly = false): Observable<
    { fn_team; fn_teamName; fn_teamID; fn_isParent; fn_isChild }[]> {
    return (managedOnly ? this.fetchManagedFacilities() : this.fetchCachedFacilities()).pipe(
      map(teams => {
        const locations = teams.map(f => this.convertFacilityToFlatArray(f)).filter(fs => fs.length).flat();
        let childrenLevelCount = 0;
        let teamData = locations.map(team => {
          childrenLevelCount++;
          if (!team.upperTeams) { childrenLevelCount = 0 };
          const result = {
            fn_team: team,
            fn_teamName: team.name,
            fn_teamID: team.teamID,
            fn_isParent: team.lowerTeams ? true : false,
            fn_isChild: team.upperTeams ? true : false,
            fn_level: team.level,
          };
          return result;
        });
        return teamData;
      })
    )
  }

  // also for normal team
  getTeamOrLocMembers(teamID) {
    const team = this.groupService.globalTeamsLocationDict[teamID]
    if (this.isLocation(team)) {
      return this.getAllMembers([teamID]).map(orgUser => this.userService.managedOrgUserDictionaryByOrgUserID[orgUser])
    }
    return this.groupService.getTeamMembers([teamID]);
  }

  getFacilityMultiSelectOptions(): Observable<IFacilityMultiSelect[]> {
    const facilityOptions$ = this.fetchCachedFacilities().pipe(
      map(facilities => {
        let flattenFacilities = [];
        const managedLocations = facilities.filter(l => this.userService.isManager ? true : this.userService.managedTeams.includes(l.teamID));
        facilities.map(f => this.convertFacilityToFlatArray(f)).filter(fs => fs.length).flat()
        .forEach(facility => {
          facility = clone(facility); // prevent mutating cache data
          facility['disabled'] = true;
          if(managedLocations.find(l=>l.teamID===facility.teamID && facility.locationTypeDisplay === 'Facility')){
            facility['disabled'] = false;
          }
          flattenFacilities.push(facility);
        });
        return flattenFacilities;
      })
    );
    return facilityOptions$;
  }
}

