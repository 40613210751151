import { Component, Input } from '@angular/core';
import { CustomisedSingleSelectComponent } from "../../../../shared/components/customised-single-select/customised-single-select.component";
import { GOAL_STATUS } from 'src/app/services/goal.service';

@Component({
  selector: 'app-goal-status-selector',
  standalone: true,
  imports: [CustomisedSingleSelectComponent],
  templateUrl: './goal-status-selector.component.html',
  styleUrl: './goal-status-selector.component.scss'
})
export class GoalStatusSelectorComponent {
  @Input() controls;
  @Input() payrollDate = false;
  options = [{
    label: 'All',
    value: 'all',
    },
    {
      label: 'Completed',
      value: GOAL_STATUS.completed,
    },
    {
      label: 'In Progress',
      value: GOAL_STATUS.inProgress,
    },
  ];

  reset() {
    this.controls.goalStatus.setValue(this.options[0].value);
  }
}
