import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ui-breadcrumb',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgFor,
    NgIf,
    CommonModule,
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: IMTBreadcrumbConfig[];
  @Input() showMarginBottom = true;
}

export interface IMTBreadcrumbConfig {
  label: string;
  url: string;
}

export enum EBreadcrumbCommonLevels {
  users = 'users',
  groups = 'groups',
  frameworks = 'frameworks',
  plan = 'plan',
  yourLibrary = 'yourLibrary',
  manage = 'manage',
  learningReports = 'learningReports',
  policyLibrary = 'policyLibrary',
  compliance = 'compliance',
  policyReports = 'policyReports',
  reviews = 'reviews',
  capability = 'capability',
  performanceReports = 'performanceReports',
  competencyLibrary = 'competencyLibrary'
}

export const commonBreadcrumbsConfigs: { [key in EBreadcrumbCommonLevels]: IMTBreadcrumbConfig } = {
  [EBreadcrumbCommonLevels.users]: { label: 'Users', url: '/pages/admin/manage-staff' },
  [EBreadcrumbCommonLevels.groups]: { label: 'Groups', url: '/pages/admin/structure-overview' },
  [EBreadcrumbCommonLevels.frameworks]: { label: 'Frameworks', url: '/pages/admin/frameworks/overview' },
  [EBreadcrumbCommonLevels.plan]: { label: 'Plan', url: '/pages/plan/overview' },
  [EBreadcrumbCommonLevels.yourLibrary]: { label: 'Your Library', url: '/pages/staff-portal/byo/your-library' },
  [EBreadcrumbCommonLevels.manage]: { label: 'Manage', url: '/pages/manage/overview' },
  [EBreadcrumbCommonLevels.learningReports]: { label: 'Reports', url: '/pages/reporting/overview/overview-page' },
  [EBreadcrumbCommonLevels.policyLibrary]: { label: 'Policy Library', url: '/pages/policies/manage/overview' },
  [EBreadcrumbCommonLevels.compliance]: { label: 'Compliance', url: '/pages/policies/acknowledgement-manage/overview' },
  [EBreadcrumbCommonLevels.policyReports]: { label: 'Reports', url: '/pages/policies/reporting' },
  [EBreadcrumbCommonLevels.reviews]: { label: 'Reviews', url: '/pages/performance/reviews/overview' },
  [EBreadcrumbCommonLevels.capability]: { label: 'Capability Frameworks', url: '/pages/admin/frameworks/capability-frameworks' },
  [EBreadcrumbCommonLevels.performanceReports]: { label: 'Reports', url: '/pages/performance/reporting' },
  [EBreadcrumbCommonLevels.competencyLibrary]: { label: 'Competency Assessment Templates', url: '/pages/competency/competency-library' },
}

