<div *ngIf="hasSidebar" style="--sidebarWidth: {{ sidebarWidth }}">
  @if(showSidebar){
    <div class="m-curtain"></div>
  }
  <div #sidebar class="{{showSidebar? 'show shadow' : 'hide'}} sidebar bg-white d-flex flex-column" [id]="sidePanelID">
    <div class="{{showHeaderUnderline? 'border-bottom' : ''}} d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center py-4 px-4">
        <!-- header slot -->
        <ng-content select="[sidebarHeaderSlot]"></ng-content>
        <div></div>
        <button (click)="closeSidebar()" type="button" class="btn btn-icon btn-square"
          data-dismiss="modal" arial-label="Close" id="side-panel-close-btn">
          <ui-streamline-icon
            [slIcon]="{name: 'interface-delete-1', theme: 'micro-bold', size: 'xs'}"
           >
          </ui-streamline-icon>
        </button>
      </div>
    </div>

    <div class="h-100 w-100 overflow-auto infinite-scroll-container">
      <!-- body slot -->
      <ng-container *ngIf="!isLoading; else spinner">
        <ng-content select="[sideBarBodySlot]"></ng-content>
      </ng-container>
      <ng-template #spinner>
        <div class="d-flex justify-content-center align-items-center h-100">
          <ui-spinner></ui-spinner>
        </div>
      </ng-template>
    </div>
  </div>
</div>
