<div style="width: 200px">
    <label class="f-small text-neutral-800 mb-1" for="selectResourceType">Record Type</label>
    <app-customised-multi-select
            placeholder='Select Record Type'
            id="selectResourceType"
            controlName="resourceTypes"
            [submitted]="submitted"
            [controls]="controls"
            [options]="options"
            displayName="Record Type"
    ></app-customised-multi-select>
</div>
