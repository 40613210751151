import { StepsControl, IWorkflowStep } from './workflow-steps-control';
import { map, isArray, compact, each, Dictionary } from 'underscore';
import { map as mapRx, tap } from 'rxjs/operators';
import { Observer, PartialObserver, Subscription, of } from 'rxjs';
import { ComponentHostDirective } from '../../directives/component-host/component-host.directive';


export interface IHostedStep<StepComponent> extends IWorkflowStep {
  componentClass: new(...any: any[]) => StepComponent;

  /**
   * Properties from the parent component that should be set on the `StepComponent` once it's constructed.
   *
   * These inputs are not rebound (because Angular does not allow @Input() decorations for data-binding for dynamically generated components);
   * therefore, if inputs are expected to change, we should pass in an Observable
   */
  input?: string | string[];

  /**
   * Dictionary of callbacks whose key corresponds to an Observable/EventEmitter on `StepComponent` that we should subscribe to,
   * and whose value corresponds to the observer / next callback for the subscription.
   *
   * Note: does not trigger change detection for OnPush components, so in these cases, change detection should be triggered manually
   *
   * @example
   *
   * output: {
   *    'countryChanges': () => {},
   *    'stateChanges': {
   *       next: () => {},
   *       error: () => {},
   *       complete: () => {},
   *    },
   *    'postcodeChanges': PostcodeSubject, // can pass in a Subject since it implements Observer
   * },
   *
   */
  output?: Dictionary<Function | PartialObserver<any> | Observer<any>>;
}

/**
 * Helper class that makes bridges `ComponentHost` directive and `StepsControl` class.
 *
 * `StepsControl` manages the model for the current step we're in
 * `ComponentHost` renders the correct component depending on the state of `StepsControl`
 *
 * `S` refers to the type of the step
 * `C` refers to the type of the step component
 */
export class ComponentHostStepControl<Step extends IHostedStep<StepComponent>, StepComponent> {

  private _host: ComponentHostDirective;
  private _subs: Subscription[] = [];

  /**
   * @param _control StepsControl we want to react to
   * @param _parent Parent component of steps. used as source for setting component inputs
   */
  constructor(private _control: StepsControl<Step>, private _parent: any) {}

  private _loadComponent(step: Step): StepComponent {

    // unsubscribe as a precaution
    each(this._subs, s => s.unsubscribe());
    this._subs.length = 0;
    // construct component
    const component = this._host.loadComponent(step.componentClass).instance as StepComponent;

    // set inputs
    const inputs = isArray(step.input) ? step.input : compact([step.input]);
    const p = this._parent;
    each(inputs, (input) => component[input] = p[input]);

    // subscribe to outputs
    const outputs = step.output;
    if (outputs) {
      this._subs = map(Object.keys(outputs), (key) => {
        const observer = outputs[key];
        return component[key].subscribe(observer);
      });
    }

    return component;
  }

  /**
   * Start reacting to changes to StepsControl
   * @return cold observable of current step component
   */
  init$(host: ComponentHostDirective) {
    this._host = host;
    return this._control.getCurrentStep$().pipe(
        mapRx((step) => this._loadComponent(step)),
    );
  }

}
