import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
/**
 * Removes the card-hover class from parent when the host is hovered 
 * parent el needs to have the same selector as input eg .hover-container
 */
@Directive({
  selector: '[appHoverToggle]',
  standalone: true
})
export class HoverToggleDirective {
  @Input('appHoverToggle') parentSelector = '.hover-container';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    const parentElement = this.el.nativeElement.closest(this.parentSelector);
    if (parentElement) {
      this.renderer.removeClass(parentElement, 'card-hover');
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    const parentElement = this.el.nativeElement.closest(this.parentSelector);
    if (parentElement) {
      this.renderer.addClass(parentElement, 'card-hover');
    }
  }
}