<div class="children-horizontal-gap-4">
    <p class="fw-medium">Please confirm the following reporting line:</p>
    <p class="fw-medium">Manager</p>
    <div class="d-flex gap-2 align-items-center">
        <ui-avatar [fullName]="manager.fullName" [url]="manager.userDetail?.profilePicURL"></ui-avatar>
        <p class="fw-semibold">{{manager.fullName}}</p>
    </div>
    <p class="fw-medium">Reports</p>
    <app-smart-table [source]="source" [settings]="settings" [tableDiv]="'review'" [withWrapper]="true" maxHeight="50vh"></app-smart-table>
</div>
