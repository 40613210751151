<div *ngIf="header" class="bg-white custom-border-header p-4 d-flex justify-content-between align-items-center">
    <p class="f-caption fw-medium">{{header}}</p>
    <app-shared-table-actions [tableSettings]="settings"></app-shared-table-actions>
</div>
<ng-container *ngIf="!withWrapper">
    <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>


<!-- with wrapper div -->
<div *ngIf="withWrapper" class="table-wrap table-overflow"  [id]="tableDiv" appScrollShadow [style.max-height]="maxHeight">
    <ng-content></ng-content>
    <ng-container *ngTemplateOutlet="table"></ng-container>
</div>

<ng-template #table>
    <div [ngClass]="{'no-header': hideHeader}">
        <ng2-smart-table *ngIf="refreshFlag" [settings]="currentSettings" [source]="source"
         (userRowSelect)="onSelect($event)" >
        </ng2-smart-table>    
    </div>
</ng-template>