import { Pipe, PipeTransform } from '@angular/core';
import { LocaliserService } from '../../services/localiser.service';
import { DayjsService } from '../../services/dayjs.service';
import { Dayjs } from 'dayjs';
import { COLUMN_VALUES } from 'src/app/core/table-headers.dict';

@Pipe({
    name: 'localDate',
    standalone: true
})
export class LocalDatePipe implements PipeTransform {
  tz: any;

  constructor(
      private localiser: LocaliserService,
      private dayjsService: DayjsService) {
  }

  transform(value: string | Dayjs, returnType?: 'string' | 'date', withTime = false): string | Dayjs {
    if (!value || value === '-') {
      return COLUMN_VALUES.NO_RECORD_SYMBOL;
    }
    let parsed;
    if (typeof value === 'string' && value.length > 10) {
      parsed = this.dayjsService.dayjs.utc(value.substring(0, 23));
    } else {
      // console.log(value);
      parsed = value;
    }
    let result;
    // console.log( dayjs.tz(parsed).format(this.localiser.dateFormat))
    switch (returnType) {
      case 'string':
        result = this.dayjsService.dayjs.tz(parsed).format(this.localiser.dateFormat);
        break;
      case 'date':
        result = this.dayjsService.dayjs.tz(parsed);
        break;
      default:
        result = this.dayjsService.dayjs.tz(parsed, this.localiser.timezone).format(this.localiser.dateFormat);
        return result;

    }
    if (withTime) {

      result += ' ' + this.dayjsService.dayjs.tz(parsed, this.localiser.timezone).format('HH:mm')
    }
    return result;


    // return dayjs.tz(value).format(this.localiser.dateFormat);
  }

}
