import {Component, Input} from '@angular/core';

import {FilterDefault} from "./filter-default";
import { InputFilterComponent } from './filter-types/input-filter.component';
import { CompleterFilterComponent } from './filter-types/completer-filter.component';
import { CheckboxFilterComponent } from './filter-types/checkbox-filter.component';
import { SelectFilterComponent } from './filter-types/select-filter.component';
import { NgSwitch, NgSwitchCase, NgClass, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'default-table-filter',
    template: `
    <ng-container [ngSwitch]="column.getFilterType()">
      <select-filter *ngSwitchCase="'list'"
                     [query]="query"
                     [ngClass]="inputClass"
                     [column]="column"
                     (filter)="onFilter($event)">
      </select-filter>
      <checkbox-filter *ngSwitchCase="'checkbox'"
                       [query]="query"
                       [ngClass]="inputClass"
                       [column]="column"
                       (filter)="onFilter($event)">
      </checkbox-filter>
      <completer-filter *ngSwitchCase="'completer'"
                        [query]="query"
                        [ngClass]="inputClass"
                        [column]="column"
                        (filter)="onFilter($event)">
      </completer-filter>
      <input-filter *ngSwitchDefault
                    [query]="query"
                    [ngClass]="inputClass"
                    [column]="column"
                    (filter)="onFilter($event)">
      </input-filter>
    </ng-container>
  `,
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        SelectFilterComponent,
        NgClass,
        CheckboxFilterComponent,
        CompleterFilterComponent,
        NgSwitchDefault,
        InputFilterComponent,
    ],
})
export class DefaultFilterComponent extends FilterDefault {
  @Input() query: string;
}
