
export const TABLE_HEADERS = {
    USER: {
        user: 'User',
        staffID: 'User ID',
        accountStatus: 'Account Status',
        email: 'Email',
        teams: 'Teams',
        jobRoles: 'Job Roles',
        actions: 'Actions',
        username: 'Username',
        workStartDate: 'Work Start Date',
    },
    RESOURCE: {
        resource: 'Resource',
        title: 'Resource Title',
        resourceCode: 'Resource ID',
        status: 'Status',
        min: 'Resource Duration',
        topics: 'Topic(s)',
        resourceType: 'Resource Type',
        standards: 'Standard(s)',
        availableDate: 'Last Updated',
        expiryDate: 'Expiry Date',
        publishDate: 'First Published',
        resourceProvider: 'Provider',
    },
    LEARNING_RECORD: {
        title: 'Learning Record Title',
        endDate: 'Completion Date',
        min: 'Reported Time Spent Learning',
        id: 'Learning Record ID',
        createDate: 'Record Create Date',
        createBy: 'Record Created By',
        recordType: 'Record Type'
    },
    COMPLIANCE_RECORD: {
        status: 'Status',
        assignID: 'Compliance Record ID',
        assignDate: 'Open Date',
        dueDate: 'Due Date',
        originDueDate: 'Original Due Date',
        lateRecogDate: 'Overdue End Date',
        completeDate: 'Date Completed'
    },
    MT_PLAN:{
        title:'Training Plan Title'
    },
    MANUAL_ASSIGN:{
        assignDate:'Date Assigned',
        status:'Status',
        dueDate:'Due Date',
        
    }
}

export const COLUMN_VALUES = {
    NO_RECORD_SYMBOL: '-'
};
