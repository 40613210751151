import { Component, computed, inject, input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Feedback } from 'src/app/services/feedback.service';
import { UserService } from 'src/app/services/user.service';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { SharedConfirmationModalComponent } from 'src/app/shared/popups/shared-confirmation-modal/shared-confirmation-modal.component';
import { removeHtml } from 'src/app/shared/utils/string/remove-html';
import { ModalSingleComponent } from "../../../../../../../../../shared/components/modal-single/modal-single.component";

@Component({
  selector: 'app-task-modal-feedback-card',
  standalone: true,
  imports: [ModalSingleComponent],
  templateUrl: './task-modal-feedback-card.component.html',
  styleUrl: './task-modal-feedback-card.component.scss'
})
export class TaskModalFeedbackCardComponent{
  private _userService = inject(UserService);
  private _localDatePipe = inject(LocalDatePipe);
  private _modalService = inject(NgbModal);
  feedback = input<Feedback>();
  plainFeedback = computed(() => removeHtml(this.feedback().feedbackContent));
  addedBy = computed(() => this._userService.managedOrgUserDictionaryByUserID[this.feedback().createdBy]?.fullName || '-');
  dateAdded = computed(() => this._localDatePipe.transform(this.feedback().createDate, 'string'));


  openFeedbackModal(e, content){
    e.stopPropagation();
    this._modalService.open(content, { backdrop: 'static' });
  }


}
