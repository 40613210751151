<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<span class="text-neutral-900 fw-semibold cursor-pointer hover-underline" (click)="openSidePanel()">
    {{buttonText}}
</span>


<app-shared-sidebar #appSharedSidebar
  showHeaderUnderline="true"
  sidebarWidth="532px"
  [isLoading]="false"
  >
  <ng-container sideBarBodySlot>
    <ng-content></ng-content>
    
  </ng-container>
  
</app-shared-sidebar>