import { Pipe, PipeTransform } from '@angular/core';
import { DayjsService } from 'src/app/services/dayjs.service';
import { LocaliserService } from 'src/app/services/localiser.service';

@Pipe({
  name: 'localDateTime',
  standalone: true
})
export class LocalDateTimePipe implements PipeTransform {

  constructor(
    private localiser: LocaliserService,
    private dayjsService: DayjsService) {
}

  // uses org timezone from localiserService
  transform(value: string, format?: string): string {
    if (!value) {
      return '-'
    }
    let parsed;
    if (typeof value === 'string' && value.length > 10) {
      parsed = this.dayjsService.dayjs.utc(value.substring(0, 23));
    } else {
      // console.log(value);
      parsed = value;
    }
    if (format) {
      return this.dayjsService.dayjs.tz(parsed, this.localiser.timezone).format(format)
    }
    return this.dayjsService.dayjs.tz(parsed, this.localiser.timezone).format(this.localiser.dateFormat)
      + ' ' + this.dayjsService.dayjs.tz(parsed, this.localiser.timezone).format('HH:mm:ss');

  }

}
