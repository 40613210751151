<ui-modal-single heading="{{action}} Goal">
    <ng-template #contentSlot>
        <form [formGroup]="goalForm">
            <div class="form-group">
                <label class="f-small text-neutral-800" for="description">Goal Description</label>
                <textarea placeholder="Think about a goal using the Smart method:
          · Specific - is it clear and precise?
          · Measureable - can progress be measured effectively?
          · Achievable - is it realistic?
          · Relevant - does it relate to a certain context of pratice?
          · Timely - has a time frame for completion been defined?" class="form-control" rows="8" cols="50" name="goal"
                    formControlName="goal"></textarea>
                <ng-container *ngIf="f.goal.invalid && !f.goal.pristine">
                    <p class="text-error-input" *ngIf="f.goal.errors.required">Please provide a learning goal</p>
                </ng-container>
            </div>

            <div class="form-group" *ngIf="action === 'Add'">
                <label class="f-small text-neutral-800 mb-1" for="selectUserId">Who owns this goal?</label>
                <app-customised-single-select [controls]="goalForm.controls" [controlName]="'user'" id="selectUserId"
                    [options]="activeUsersOption" [showAvatar]="true"
                    placeholder="Select a user"></app-customised-single-select>
                <!-- <p class="f-small text-neutral-700" *ngIf="user">{{user?.userDetail?.fullName}}’s Primary Manager (if
                    any) will also be responsible for this goal</p> -->
            </div>


            <div class="form-group w-30">
                <label class="f-small text-neutral-800" for="dueDate">Due Date</label>
                <!-- <input class="form-control" id="dueDate" name="dueDate" formControlName="dueDate" type="date"
                 [min]="min"> -->
                <app-ngb-date-selector id="dueDate" name="dueDate" [control]="goalForm.get('dueDate')" [min]="min"
                    [showError]="f.dueDate.invalid && !f.dueDate.pristine"></app-ngb-date-selector>
                <ng-container *ngIf="f.dueDate.invalid && !f.dueDate.pristine">
                    <p class="text-error-input" *ngIf="f.dueDate.errors.required">Please provide a due date</p>
                </ng-container>
            </div>
        </form>
    </ng-template>
    <ng-template #rightSlot>
        <button class="btn btn-secondary" [disabled]="goalForm.pristine || goalForm.invalid || isProcessing()"
            (click)="createOrSaveGoal()">
            <span *ngIf="isProcessing()">
                <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            {{action}} Goal
        </button>
    </ng-template>
    <ng-template #leftSlot>
        <button class="btn btn-outline-light" (click)="back()">Back
        </button>
    </ng-template>
    <!-- <ng-template #msgSlot>
      <ui-alert-banner *ngIf="processError" [copy]="processError" alertType="error" type="New"
          [includeDismiss]="false">
      </ui-alert-banner>
  </ng-template> -->

</ui-modal-single>
