// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const domainEndpoint = 'https://api-staging.ausmed.com/';
const readNodeEndpoint = 'https://api-read-staging.ausmed.com/'
const host = 'https://lms-staging.ausmed.com';

export const environment = {
  hippotlian: false,
  production: false,
  env: 'staging',
  accountServiceEndpoint: domainEndpoint + 'account',
  resourceApiHost: domainEndpoint + 'resource',
  resourceReadApiHost: readNodeEndpoint + 'resource',
  uploadEndpoint: 'https://api-staging.ausmed.com/upload',
  host: host,
  awsCloudSearch: 'https://4rm9zffn4l.execute-api.ap-southeast-2.amazonaws.com/search_beta',
  frontendDomain: 'https://staging.ausmed.com.au',
  scormEndpoint: 'https://scorm.ausmed.com',
  scormAnalytics: 'https://ubpw377kwg.execute-api.ap-southeast-2.amazonaws.com/production/scorm-analytics',
  segment: '2Axih0ZHTbk456Use8sudlUbrBw0DUe6',
  scormCloudApi: 'https://cloud.scorm.com/api/v2',
  scormCloudApiKey: 'Basic RjBIMlRMVDZENjp1clhsZHpscTlzSzdNUEVLNHBpekdMT01JQkRlWUdKMmxNSXpvUGhW',
  scormCloudPostbackUrl: 'https://cyvkn8aar1.execute-api.ap-southeast-2.amazonaws.com/prod',
  scormCloudSocketUrl: 'wss://cvsmaxmxeb.execute-api.ap-southeast-2.amazonaws.com/production',
  htmpToPdfAPIGateway: 'https://ft720qqf62.execute-api.ap-southeast-2.amazonaws.com/dev/html-to-pdf',
  stripeKey: 'pk_test_51JGDqaAQUZYbU28Lr2EyZZS1SsZMPmuh8d7FVvhOjMf3jcxCFVYq8QdRok1Dz76nL2ryEcc80b96Phe6rXKWhwDC00zgqNK9I6',
  knowledgeDomain: 'https://knowledge.organisations.ausmed.com.au/en/knowledge',
  ChurnZeroKey: '',
  googleMapAPIKey: 'AIzaSyA7oGeSGjjvLIn1BJqgDNnCVIc-BuCKBP4',
  amplifyFileUploadPublicSubPath: 'public/staging/org',
  amplifyFileUploadPrivateSubPath: 'private/staging/org'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
