import { HttpParams } from "@angular/common/http";
import { isArray, mapObject } from "underscore";
import { removeEmptyFields } from "../remove-empty-fields";

export function objectToParams(par): HttpParams {
    removeEmptyFields(par);
    return new HttpParams({fromObject: mapObject(par, (val) => isArray(val) ? val.join(',') : val)});

}

