import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap, share } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  domainsStore = {};
  schedules = new BehaviorSubject(null);
  private _pendingSchedules: boolean;
  domainsWithResources = new BehaviorSubject(null);

  pendingSchedules = false;


  constructor(private http: HttpClient, private customErrorHandler: ErrorHandlingService) {
  }

  getAllScormScheduleByOrg(): Observable<any> {
    return this.schedules.asObservable();
  }

  fetchCachedAllScormScheduleByOrg(orgID: string, refresh?: boolean): Observable<any> {
    if (refresh || (!this.schedules.value && !this._pendingSchedules)) {
      this._pendingSchedules = true;
      const fetch$ = this.fetchAllScormScheduleByOrg(orgID).pipe(
        tap(() => this._pendingSchedules = false),
        catchError((e) => {
          this._pendingSchedules = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.schedules.pipe(filter(x => !!x));
  }

  fetchAllScormScheduleByOrg(orgID: string, refresh?: boolean): Observable<any> {
    return this.http.get(`${environment.resourceReadApiHost}/orgs/${orgID}/scormSchedules/schedules`)
      .pipe(
        switchMap((res: any) => {
          if (res.length === 0) {
            const newSchedule = {
              orgID: orgID,
              name: 'Default Schedule',
            };
            return this.addScormSchedule(orgID, newSchedule).pipe(tap(schedule => {
              this.schedules.next([schedule]);
              // return of([schedule]);
              return of(null);
            }));
          } else {
            this.schedules.next(res);
            // return of(res);
            return of(null);

          }
        }),
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  getScormScheduleDomainsByScheduleID(orgID: string, scheduleID: string): Observable<any> {
    if (!Object.keys(this.domainsStore).includes(scheduleID)) {
      this.domainsStore[scheduleID] = new BehaviorSubject([]);
      return this.fetchScormScheduleDomainsByScheduleID(orgID, scheduleID).pipe(
        switchMap(() => this.domainsStore[scheduleID].asObservable())
      );
    }
    return this.domainsStore[scheduleID].asObservable();
  }

  fetchScormScheduleDomainsByScheduleID(orgID: string, scheduleID: string): Observable<any> {
    return this.http.get(`${environment.resourceReadApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}/domains`)
      .pipe(
        tap((domains) => {
          this.domainsStore[scheduleID].next(domains);
        }),
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  addScormSchedule(orgID: string, schedule: any): Observable<any> {
    return this.http.post(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules`, schedule)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  addScormScheduleDomain(orgID: string, scheduleID: string, domain: any): Observable<any> {
    return this.http.post(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}/domains`, domain)
      .pipe(
        tap(() => combineLatest([this.fetchScormScheduleDomainsByScheduleID(orgID, scheduleID), this.fetchScormScheduleWithResources(orgID, scheduleID)]).subscribe()),
        catchError(this.customErrorHandler.handleHttpError));
  }

  addResourceToScormDomain(orgID: string, scheduleID: string, ScormDomainResourceRelation: any): Observable<any> {
    return this.http.post(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}/resources`, ScormDomainResourceRelation)
      .pipe(
        tap(() => this.fetchScormScheduleWithResources(orgID, scheduleID).subscribe()),
        catchError(this.customErrorHandler.handleHttpError),
      );
  }

  updateScormSchedule(orgID: string, schedule: any): Observable<any> {
    return this.http.put(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules/${schedule.scheduleID}`, schedule)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  updateScheduleDomain(orgID: string, scheduleID: string, domain: any): Observable<any> {
    return this.http.put(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}/domains/${domain.domainID}`, domain)
      .pipe(
        tap(() => combineLatest([this.fetchScormScheduleDomainsByScheduleID(orgID, scheduleID), this.fetchScormScheduleWithResources(orgID, scheduleID)]).subscribe()),
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  deleteScormSchedule(orgID: string, scheduleID: any): Observable<any> {
    return this.http.delete(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  deleteScormScheduleDomain(orgID: string, scheduleID: any, domainID: any): Observable<any> {
    return this.http.delete(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}/domains/${domainID}`)
      .pipe(
        tap(() => combineLatest([this.fetchScormScheduleDomainsByScheduleID(orgID, scheduleID), this.fetchScormScheduleWithResources(orgID, scheduleID)]).subscribe()),
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  removeScormDomainResourceRelation(orgID: string, relationID: any, scheduleID?: string): Observable<any> {
    return this.http.delete(`${environment.resourceApiHost}/orgs/${orgID}/scormSchedules/resources/${relationID}`)
      .pipe(
        // tap(() => this.fetchScormScheduleWithResources(orgID, scheduleID).subscribe()),
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  fetchScormScheduleWithResources(orgID: string, scheduleID: string): Observable<any> {
    return this.http.get(`${environment.resourceReadApiHost}/orgs/${orgID}/scormSchedules/schedules/${scheduleID}/full`)
      .pipe(
        tap(res => {
          this.domainsWithResources.next(res);
        }),
        catchError(this.customErrorHandler.handleHttpError)
      );
  }

  getScormScheduleWithResources(orgID: string, scheduleID: string, refresh?: boolean): Observable<any> {
    if (refresh || (!this.domainsWithResources.value && !this.pendingSchedules)) {
      this.pendingSchedules = true;
      const fetch$ = this.fetchScormScheduleWithResources(orgID, scheduleID).pipe(
        tap(() => this.pendingSchedules = false),
        catchError(this.customErrorHandler.handleHttpError),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.domainsWithResources.pipe(
      filter(x => !!x),
    );
  }
}
