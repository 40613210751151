import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, filter, map, share, tap } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { FeatureFlagService } from './feature-flag.service';
import {
  IAssessmentObject,
  ICourseAttempt,
  ILearningObject,
  IOrgEvaluation,
  IOrgEvaluationQuestion,
  IOrgEvaluationSection,
  IOrgResource,
  IQuizQuestion,
  IUnit,
  IUnitForm,
  PRACTICAL_DISPLAY_DICT,
  PRACTICAL_TYPE_ENUM,
  RESOURCE_TYPE_ENUM
} from '../shared/byo-shared/byo-resource-types';
import { BroadcastService } from './broadcast.service';
import { some } from 'underscore';
import { PRODUCT_TYPES } from '../core/guards/mode.constants';
import { OrganisationService } from './organisation.service';
import { FEATURES } from '../core/features.config';
import { cacheResponse2 } from '../shared/utils/rx/cache-response-2';

// cons${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources = ``;
export const PA_ACTIVITY_TYPES = [
  { name: PRACTICAL_DISPLAY_DICT.Competency, value: PRACTICAL_TYPE_ENUM.competency },
  { name: PRACTICAL_DISPLAY_DICT['In-Service'], value: PRACTICAL_TYPE_ENUM.inService },
  // { name: 'Meeting', value: 'Meeting' },
  // { name: 'Event', value: 'Conference' },
  { name: PRACTICAL_DISPLAY_DICT.Other, value: PRACTICAL_TYPE_ENUM.other },
];

export interface PartnerResourceKeyword {
  alias: string;
  groupID?: string;
  keywordID: string;
  name: string;
  isGroup?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class OrgResourceService {
  orgResources = new BehaviorSubject(null);
  orgActiveResources = new BehaviorSubject(null);
  private pendingOrgCourse: boolean;
  orgCourse = new BehaviorSubject(null);
  private pendingOrgResources = false;
  private pendingOrgActiveResources = false;
  orgResource: BehaviorSubject<IOrgResource> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private featureFlagService: FeatureFlagService,
    private broadcastService: BroadcastService,
    private organisationService: OrganisationService,
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.orgResource.next(null);
      this.orgResources.next(null);
      this.orgActiveResources.next(null);
    });
  }

  public getResourceDetailLink(resource: any): string[] {
    let link;
    if (resource.orgID) {
      if (this.organisationService.isContractType(PRODUCT_TYPES.CONTENT_PARTNER)) {
        link = ['/pages/staff-portal/byo/item', resource.resourceID];
      } else {
        link = resource.resourceType === RESOURCE_TYPE_ENUM.onlineCourse ? ['/pages/staff-portal/byo/course-analytics', resource.resourceID] :
          ['/pages/staff-portal/byo/item', resource.resourceID];
      }
    } else {
      link = (resource.providerName && resource.providerName !== 'Ausmed' && resource.providerName !== 'Ausmed Education') ?
        ['/pages/library/provider/', resource.providerName, 'resource', resource.resourceID]
        : ['/pages/library/resource', resource.resourceID];
    }
    return link;
  }

  addUnit(unit: IUnitForm): Observable<IUnit> {
    const resourceID = unit.resourceID;
    return this.http.post<IUnit>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit`, unit).pipe(
      tap(() => this.fetchCourseByID(resourceID).subscribe()),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  editUnit(unit: IUnit): Observable<IUnit> {
    const resourceID = unit.resourceID;
    const unitID = unit.unitID;
    return this.http.put<IUnit>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}`, unit).pipe(
      tap(() => this.fetchCourseByID(resourceID).subscribe()),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  deleteUnit(resourceID, unitID): Observable<any> {
    return this.http.delete<IUnit>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}`).pipe(
      tap(() => this.fetchOrgResourceByID(resourceID)),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  fetchUnit(resourceID, unitID): Observable<IUnit> {
    return this.http.get<IUnit>(`${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  addLearningObject(resourceID, unitID, learningObject): Observable<ILearningObject> {
    return this.http.post<ILearningObject>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}/learningObject`, learningObject).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  fetchLearningObject(resourceID, unitID, objectID): Observable<ILearningObject> {
    return this.http.get<ILearningObject>(`${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}/learningObject/${objectID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  updateLearningObject(resourceID, unitID, objectID, learningObject: ILearningObject): Observable<ILearningObject> {
    return this.http.put<ILearningObject>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}/learningObject/${objectID}`, learningObject).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  reorderLearningObjects(resourceID, unitID, learningObjects: ILearningObject[]): Observable<ILearningObject[]> {
    return this.http.put<ILearningObject[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}/bulkLearningObject`, { learningObjects }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  deleteLearningObject(resourceID, unitID, objectID) {
    return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/unit/${unitID}/learningObject/${objectID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  // assessment
  addAssessmentQuestion(courseID: string, unitID: string, objectID: string, question: IQuizQuestion): Observable<IQuizQuestion> {
    return this.http.post(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/unit/${unitID}/learningObject/${objectID}/question`, question)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }


  bulkAddAssessmentQuestion(courseID: string, unitID: string, objectID: string, question: IQuizQuestion): Observable<IQuizQuestion> {
    return this.http.post(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/unit/${unitID}/learningObject/${objectID}/question`, question)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  updateAssessmentQuestion(courseID: string, unitID: string, objectID: string, questionID: string, question: IQuizQuestion): Observable<IQuizQuestion> {
    return this.http.put(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/unit/${unitID}/learningObject/${objectID}/question/${questionID}`, question)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  deleteAssessmentQuestion(courseID: string, unitID: string, objectID: string, questionID: string) {
    return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/unit/${unitID}/learningObject/${objectID}/question/${questionID}`)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  reorderAssessmentQuestions(courseID: string, unitID: string, objectID: string, questions: IQuizQuestion[]) {
    return this.http.put(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/unit/${unitID}/learningObject/${objectID}/bulkQuestion`, { questions })
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  // same as reorder
  bulkAddAssessmentQuestions(courseID: string, unitID: string, objectID: string, questions: IQuizQuestion[]) {
    return this.http.put(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/unit/${unitID}/learningObject/${objectID}/bulkQuestion`, { questions })
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  // Evaluation
  getOrgCourseEvaluationSectionByID(resourceID: string, evaluationID: string, sectionID: string) {
    return this.http.get<IOrgEvaluationSection>(`${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section/${sectionID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  addOrgEvaluation(resourceID: string) {
    return this.http.post<IOrgEvaluation>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation`, { resourceID }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  addOrgEvaluationSection(resourceID: string, evaluationID: string, section: IOrgEvaluationSection) {
    return this.http.post<IOrgEvaluationSection>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section`, section).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  reorderEvaluationSections(courseID: string, evaluationID: string, sections: IOrgEvaluationSection[]) {
    return this.http.put(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/evaluation/${evaluationID}/bulkSection`, { sections })
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  addOrgEvaluationQuestion(resourceID: string, evaluationID: string, sectionID: string, question: IOrgEvaluationQuestion) {
    return this.http.post<IOrgEvaluationQuestion>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section/${sectionID}/question`, question).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  updateOrgEvaluationSection(resourceID: string, evaluationID: string, section: IOrgEvaluationSection) {
    return this.http.put<IOrgEvaluationSection>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section/${section.sectionID}`, section).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  updateOrgEvaluationQuestion(resourceID: string, evaluationID: string, sectionID: string, question: IOrgEvaluationQuestion) {
    return this.http.put<IOrgEvaluationQuestion>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section/${sectionID}/question/${question.questionID}`, question).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  reorderEvaluationQuestions(courseID: string, evaluationID: string, sectionID: string, questions: IOrgEvaluationQuestion[]) {
    return this.http.put(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseID}/evaluation/${evaluationID}/section/${sectionID}/bulkQuestion`, { questions })
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  deleteOrgEvaluationSection(resourceID: string, evaluationID: string, sectionID: string) {
    return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section/${sectionID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  deleteOrgEvaluationQuestion(resourceID: string, evaluationID: string, sectionID: string, questionID) {
    return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}/section/${sectionID}/question/${questionID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  deleteOrgEvaluation(resourceID: string, evaluationID) {
    return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/${evaluationID}`).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  // TODO swith to account proxy
  fetchOrgEvaluationAnswers(resourceID: string, questionID: string, date?: any) {
    let params = new HttpParams().set('questionID', questionID);
    if (date && date.startDate && date.endDate) {
      params = params.set('startDate', date.startDate).set('endDate', date.endDate);
    }
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/answers/admin`, {
      params: params
    }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  fetchAllOrgEvaluationAnswers(resourceID: string, date?: any): Observable<any> {
    let params = new HttpParams();
    if (date && date.startDate && date.endDate) {
      params = params.set('startDate', date.startDate).set('endDate', date.endDate);
    }
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/evaluation/answers/all/admin`, {
      params: params
    }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getOrgResourceProgress(resourceID: string, date?: any): Observable<any> {
    // Note: After NLP, we don;t use org orogress anymore, here switch to get resource leraning progress instead, and map learning progress status finished to completed, pristine to in-progress to consistent with internal progress
    if (date) {
      const params = new HttpParams().set('startDate', date.startDate).set('endDate', date.endDate);
      return this.http.get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/learningProgresses/admin`, { params: params }).pipe(
        map((res: any) => {
          return res.map(p => {
            if (p.status === 'finished') p.status = 'completed';
            if (p.status === 'pristine') p.status = 'in-progress'
            return p
          })
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
    } else {
      return this.http.get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/learningProgresses/admin`).pipe(
        map((res: any) => {
          return res.map(p => {
            if (p.status === 'finished') p.status = 'completed';
            if (p.status === 'pristine') p.status = 'in-progress'
            return p
          })
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
    }
  }

  getCourseAttemptsById(courseId: string, date?: { startDate: string, endDate: string }): Observable<ICourseAttempt[]> {
    let params = new HttpParams();
    if (date.startDate && date.endDate) {
      params = params.set('startDate', date.startDate).set('endDate', date.endDate);
    }
    return this.http.get<ICourseAttempt[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseId}/attempts/admin`, { params: params }).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
    // else {
    //   return this.http.get<ICourseAttempt[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${courseId}/attempts/admin`)
    //       .pipe(catchError(this.errorHandlingService.handleHttpError));
    // }
  }

  // Org manager or team manager download staff course certificate
  // Not in use now
  getCertificate(resourceID: string, userID: string, progressID: string) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/${resourceID}/users/${userID}/progresses/${progressID}/genCert`)
      .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  /********************* BYO resource start *********************/

  getOrgResources(): Observable<any[]> {
    return this.orgResources.asObservable();
  }

  public fetchCachedOrgResources(refresh?: boolean): Observable<any> {
    if (refresh || (!this.orgResources.value && !this.pendingOrgResources)) {
      this.pendingOrgResources = true;
      const fetch$ = this.fetchOrgResources(localStorage.getItem('orgID')).pipe(
        tap(() => this.pendingOrgResources = false),
        catchError((e) => {
          this.pendingOrgResources = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.orgResources.pipe(filter(x => !!x));
  }

  public fetchCachedOrgActiveResources(refresh?: boolean): Observable<any> {
    if (refresh || (!this.orgActiveResources.value && !this.pendingOrgActiveResources)) {
      this.pendingOrgActiveResources = true;
      const fetch$ = this.fetchOrgActiveResources(localStorage.getItem('orgID')).pipe(
        tap(() => this.pendingOrgActiveResources = false),
        catchError((e) => {
          this.pendingOrgActiveResources = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.orgActiveResources.pipe(filter(x => !!x));
  }


  fetchOrgResources(orgID: string) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/orgResources')
      .pipe(
        tap((orgResources: any) => {
          this.orgResources.next(orgResources);
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchOrgActiveResources(orgID: string) {
    return this.http
      .get(environment.accountServiceEndpoint + '/orgs/' + orgID + '/orgResources/active')
      .pipe(
        tap((orgResources: any) => {
          this.orgActiveResources.next(orgResources);
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  addOrgResource(orgResource: any, userID: string) {
    if (!this.featureFlagService.featureOn(FEATURES.displayInContentLibraryToggle)) {
      orgResource.isPublic = true;
    }
    return this.http
      .post(environment.accountServiceEndpoint + '/orgs/' + orgResource.orgID + '/managers/' + userID + '/orgResources', orgResource)
      .pipe(
        tap(() => {
          this.broadcastService.broadcast('org_resource_updated');
          // this.fetchOrgResources(orgResource.orgID).subscribe();
          this.fetchOrgActiveResources(orgResource.orgID).subscribe();
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  updateOrgResource(orgResource: any, userID: string) {
    if (!this.featureFlagService.featureOn(FEATURES.displayInContentLibraryToggle)) {
      orgResource.isPublic = true;
    }

    // Remove units to avoid "too large file" error.
    if (orgResource?.orgResourceDetail?.units) {
      delete orgResource.orgResourceDetail.units
    }
    return this.http
      .put(environment.accountServiceEndpoint + '/orgs/' + orgResource.orgID + '/managers/' + userID + '/orgResources/' + orgResource.resourceID, orgResource)
      .pipe(
        tap(() => {
          this.broadcastService.broadcast('org_resource_updated');
          // this.fetchOrgResources(orgResource.orgID).subscribe();
          this.fetchOrgActiveResources(orgResource.orgID).subscribe();
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  deleteOrgResource(orgResource: any) {
    return this.http
      .delete(environment.accountServiceEndpoint + '/orgs/' + orgResource.orgID + '/orgResources/' + orgResource.resourceID)
      .pipe(
        tap(() => {
          this.broadcastService.broadcast('org_resource_updated');
          if (this.orgResources.value) {
            this.orgResources.next(this.orgResources.value.filter((r => r.resourceID !== orgResource.resourceID)));
          }
          // this.fetchOrgResources(orgResource.orgID).subscribe();
          this.fetchOrgActiveResources(orgResource.orgID).subscribe();
        }),
        catchError(this.errorHandlingService.handleHttpError),
      )
      .toPromise();
  }

  fetchOrgResourceByID(resourceID): Observable<IOrgResource> {
    return this.http
      .get<IOrgResource>(environment.resourceReadApiHost + '/orgs/' + localStorage.getItem('orgID') + '/orgResources/' + resourceID + '/full')
      .pipe(
        tap(res => this.orgResource.next(res)),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchOrgResourceKeywords = cacheResponse2(() => {
    return this.http
      .get(`${environment.resourceReadApiHost}/orgResourceKeywords`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;
  });

  fetchOrgResourceKeywordGroups = cacheResponse2(() => {
    return this.http
      .get(`${environment.resourceReadApiHost}/orgResourceKeywords/groups`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;
  });

  fetchOrgResourceKeywordByAlias = cacheResponse2((alias) => {
    return this.http
      .get(`${environment.resourceReadApiHost}/orgResourceKeywords/alias/${alias}`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;
  });

  fetchOrgResourceKeywordsWithPartnerResCount = cacheResponse2((keywordID, orgID) => {
    return this.http
      .get(`${environment.resourceReadApiHost}/orgResourceKeywords/${keywordID}/orgID/${orgID}/partner`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;
  });

  fetchGroupedResourceKeywords = cacheResponse2(() => {
    return this.http
      .get(`${environment.resourceReadApiHost}/orgResourceKeywords/groups/resCount/${localStorage.getItem('orgID')}/partner`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;

  })

  fetchCourseByID(resourceID): Observable<IOrgResource> {
    return this.http
      .get<IOrgResource>(environment.resourceReadApiHost + '/orgs/' + localStorage.getItem('orgID') + '/orgResources/' + resourceID + '/full')
      .pipe(
        tap(res => this.orgCourse.next(res)),
        catchError(error => {
          const parsedError = this.errorHandlingService.handleHttpError(error);
          this.orgCourse.error(error);
          return parsedError;
        }),
      );
  }

  searchOrgResources(keywords, topics = ''): Observable<any> {
    return this.http
      .get<any>(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/orgResources/search?title=' + keywords + '&topics=' + topics)
      .pipe(
        catchError(error => {
          return this.errorHandlingService.handleHttpError(error);
        }),
      );
  }

  searchPartnerResource(keywords, topics = ''): Observable<any[]> {
    return this.http
      .get<any>(environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/orgResources/searchOrgPartnerResources?title=' + keywords + '&topics=' + topics)
      .pipe(
        catchError(error => {
          const parsedError = this.errorHandlingService.handleHttpError(error);
          return parsedError;
        }),
      );
  }

  searchOrgResourcesSSR(limit = 20, page = 1, publishedOnly = true, condition = 'and', payload = {}, params = ''): Observable<any> {
    return this.http
      .post<any>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/orgResources/search?_limit=${limit}&_page=${page}&publishedOnly=${publishedOnly}&condition=${condition}${params}`, payload)
      .pipe(
        catchError(error => {
          return this.errorHandlingService.handleHttpError(error);
        }),
      );
  }

  searchPartnerOrgResourcesSSR(limit = 20, page = 1, title_like = '', condition = 'and', payload = {}): Observable<any> {
    return this.http
      .post<any>(`${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/orgResources/search/partner?_limit=${limit}&_page=${page}&title_like=${title_like}&condition=${condition}`, payload)
      .pipe(
        catchError(error => {
          return this.errorHandlingService.handleHttpError(error);
        }),
      );
  }

  // For course builder side bar
  fetchCachedCourseByID(resourceID: string): Observable<IOrgResource> {
    if (!this.pendingOrgCourse && !this.orgCourse.value) {
      this.pendingOrgCourse = true;
      const fetch$ = this.fetchCourseByID(resourceID).pipe(
        tap(() => this.pendingOrgCourse = false),
        catchError((e) => {
          this.pendingOrgCourse = false;
          return e;
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this.orgCourse.pipe(
      filter(r => !!r),
      catchError(error => this.errorHandlingService.handleHttpError(error))
    );
  }

  clearCourse() {
    this.orgCourse.next(null);
  }

  /********************* BYO resource end *********************/


}

export function checkCourseContent(fullCourse: IOrgResource): { valid: boolean, errorMsg?: string } {
  if (fullCourse.resourceType === 'onlineCourse') {
    if (!fullCourse.orgResourceDetail.units || !fullCourse.orgResourceDetail.units.length) {
      return ({
        valid: false,
        errorMsg: 'The course should contain at least one unit.'
      });
    }
    for (const unit of fullCourse.orgResourceDetail.units) {
      if (!unit.learningObjects || !unit.learningObjects.length) {
        return ({
          valid: false,
          errorMsg: `${unit.name} should contain at least one learning object.`,
        });
      }
      const assessmentWithoutQ = unit.learningObjects.filter(o => o.learningType === 'assessment')
        .find(assessment => !assessment.questions || !assessment.questions.length);
      if (assessmentWithoutQ) {
        return ({
          valid: false,
          errorMsg: `${assessmentWithoutQ.name} in ${unit.name} should contain at least one question.`
        });
      }
    }
    return ({ valid: true });
  }
}

export function checkCourseContentMultipleErrors(fullCourse: IOrgResource): { valid: boolean, errorMsg?: string[] } {
  const errorMsg: string[] = [];
  if (!fullCourse.orgResourceDetail.units || !fullCourse.orgResourceDetail.units.length) {
    errorMsg.push('Add at least 1 unit.');
  }
  if (some(fullCourse.orgResourceDetail.units, unit => !unit.learningObjects || !unit.learningObjects.length)) {
    errorMsg.push('Add at least 1 Item or Assessment into each Unit.');
  }
  if (some(fullCourse.orgResourceDetail.units, unit => {
    const assessmentWithoutQ = unit.learningObjects
      .filter(o => o.learningType === 'assessment')
      .find(assessment => !assessment.questions || !assessment.questions.length);
    return !!assessmentWithoutQ;
  })) {
    errorMsg.push('Add at least 1 Question into each Assessment.');
  }
  return {
    valid: !errorMsg.length,
    errorMsg
  };

}

export function checkDependentContent(fullCourse: IOrgResource, targetName: string, targetObj?: IUnit | IAssessmentObject): { valid: boolean, errorMsg?: string } {
  console.log(fullCourse);
  if (fullCourse.resourceType === 'onlineCourse') {
    const detail = fullCourse.orgResourceDetail;
    let result;
    switch (targetName) {
      case 'resource':
        if (detail.units && detail.units.length) {
          result = ({
            valid: false,
            errorMsg: 'Please remove all units within this course first.',
          });
        }
        break;
      case 'unit':
        if ((targetObj as IUnit).learningObjects && (targetObj as IUnit).learningObjects.length) {
          result = ({
            valid: false,
            errorMsg: 'Please remove all resources within this unit first.',
          });
        }
        break;
      case 'assessment':
        if ((targetObj as IAssessmentObject).questions && (targetObj as IAssessmentObject).questions.length) {
          result = ({
            valid: false,
            errorMsg: 'Please remove all questions within this assessment first.',
          });
        }
        break;
    }
    return result || ({ valid: true });
  }
}
