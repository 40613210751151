import { Component, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { SharedTableActionsComponent } from '@components/shared-table-actions/shared-table-actions.component';
import { NgbModal, NgbNavChangeEvent, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ITablePerformanceReviewAssign, PerformanceReviewService } from 'src/app/services/performance-review.service';
import { AddPluralSPipe } from 'src/app/shared/pipes/add-plural-s.pipe';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { PR_ASSIGN_STATUS_BRACKET_DICT, PR_ASSIGN_STATUS_ENUM } from 'src/app/shared/utils/pr-assign-status-transform';
import { TaskTableComponent } from '../task-activity-tables/task-table/task-table.component';

@Component({
  selector: 'app-progress-activity-modal',
  standalone: true,
  imports: [
    ModalSingleComponent,
    TaskTableComponent,
    SharedTableActionsComponent,
    NgbNavModule,
  ],
  templateUrl: './progress-activity-modal.component.html',
  styleUrl: './progress-activity-modal.component.scss'
})
export class ProgressActivityModalComponent extends ConfirmationModal implements OnInit {
  @Input() activityStatus = 'All';
  @Input() assigns: ITablePerformanceReviewAssign[];
  @Input() modalHeader;
  @Input() context = 'my tasks';
  @Input() multiSelect = false;
  @Input() showCategories = false;

  tableHeader: string;
  currentSettings;
  addPlural = inject(AddPluralSPipe);
  prService = inject(PerformanceReviewService);
  modalService = inject(NgbModal);

  activeTabId: WritableSignal<string> = signal('all');

  allAssigns: ITablePerformanceReviewAssign[];
  completedAssigns: ITablePerformanceReviewAssign[];
  openToStaffAssigns: ITablePerformanceReviewAssign[];
  openToManagerAssigns: ITablePerformanceReviewAssign[];
  overdueToStaffAssigns: ITablePerformanceReviewAssign[];
  overdueToManagerAssigns: ITablePerformanceReviewAssign[];
  scheduledAssigns: ITablePerformanceReviewAssign[];
  skippedAssigns: ITablePerformanceReviewAssign[];

  ngOnInit(): void {
    // console.log(this.assigns)
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.tableHeader = `Showing ` + this.addPlural.transform((this.activityStatus === 'All' || !this.activityStatus) ? `${this.assigns?.length} Task` : `${this.assigns?.length} ${this.activityStatus} Task`);
    // this.source.load(this.prService.sortTasks(this.assigns));

    this.allAssigns = this.assigns;
    this.completedAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.completed);
    this.openToStaffAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.openToStaff);
    this.openToManagerAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.openToManager);
    this.overdueToStaffAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.overdueToStaff);
    this.overdueToManagerAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.overdueToManager);
    this.scheduledAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.scheduled);
    this.skippedAssigns = this.allAssigns.filter(a => a.prStatus === PR_ASSIGN_STATUS_ENUM.skipped);
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.activeTabId.set(changeEvent.nextId);
  }

  get categoriesInfo(): categoryInfo[] {
    return [
      {
        navItem: 'all',
        displayNavName: 'All',
        taskNum: this.allAssigns?.length || 0,
        assigns: this.allAssigns,
      },
      {
        navItem: 'completed',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.completed],
        taskNum: this.completedAssigns?.length || 0,
        assigns: this.completedAssigns,
      },
      {
        navItem: 'openToStaff',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.openToStaff],
        taskNum: this.openToStaffAssigns?.length || 0,
        assigns: this.openToStaffAssigns,
      },
      {
        navItem: 'openToManager',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.openToManager],
        taskNum: this.openToManagerAssigns?.length || 0,
        assigns: this.openToManagerAssigns,
      },
      {
        navItem: 'overdueToStaff',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.overdueToStaff],
        taskNum: this.overdueToStaffAssigns?.length || 0,
        assigns: this.overdueToStaffAssigns,
      },
      {
        navItem: 'overdueToManager',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.overdueToManager],
        taskNum: this.overdueToManagerAssigns?.length || 0,
        assigns: this.overdueToManagerAssigns,
      },
      {
        navItem: 'scheduled',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.scheduled],
        taskNum: this.scheduledAssigns?.length || 0,
        assigns: this.scheduledAssigns,
      },
      {
        navItem: 'skipped',
        displayNavName: PR_ASSIGN_STATUS_BRACKET_DICT[PR_ASSIGN_STATUS_ENUM.skipped],
        taskNum: this.skippedAssigns?.length || 0,
        assigns: this.skippedAssigns,
      }
    ];
  }

}

interface categoryInfo {
  navItem: string;
  displayNavName: string;
  taskNum: number;
  assigns: ITablePerformanceReviewAssign[];
}