import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, merge, Observable, ReplaySubject, Subject, throwError, timer } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, delay, filter, map, share, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { clone, Dictionary, indexBy, pick, pluck, toArray } from 'underscore';
import { ILearningRecordType, LearningGroupType } from '../pages/reporting/reporting-type';
import { FRAMEWORKS_ENUM } from '../pages/settings/interfaces/IOrgLMSSetting';
import { BroadcastService } from './broadcast.service';
import { GroupService } from './group.service';
import { OrganisationService } from './organisation.service';
import { ResourceService } from './resource.service';
import { UserService, USER_STATUS } from './user.service';
import { cloneDeep } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { removeEmptyFields } from '../shared/utils/remove-empty-fields';
import * as dayjs from 'dayjs';
import { AnalyticsService, PAGE_CONTEXT_TYPES } from './analytics.service';
import { MandatoryTrainingService } from './mandatory-training.service';
import { REPORT_CARD_CONFIG } from '../pages/overview/overview-reporting/report-card-config';
import { FeatureFlagService } from './feature-flag.service';
import { FEATURES } from '../core/features.config';
import { FacilitiesService } from './facilities.service';
import { CATEGORY_ENUM } from '../pages/reporting/filter-pack/interfaces';

/**
 * to add in new report:
 * update shortcut SHORTCUT_LOCATION, SHORTCUT_LOC_DISPLAY, SHORTCUT_URL, SHORTCUT_TYPE
 * if there's a new filter, make sure the new filter value is applied at filterPack component
 */
@Injectable({
  providedIn: 'root'
})
export class ShortcutService {
  DATA: IRawShortcut[] = [
    // {
    //   shortcutID: '01',
    //   location: SHORTCUT_LOCATION.allLearning,
    //   title: 'filter 1',
    //   lastOpened: 1648181445743
    // },
    // {
    //   shortcutID: '02',
    //   location: SHORTCUT_LOCATION.compliance,
    //   title: 'workroom 1',
    //   lastOpened: 1648181445743
    // },
  ];
  globalShortcutByID: Dictionary<IRawShortcut>;

  _shortcuts$ = new BehaviorSubject<IRawShortcut[]>(null);
  _activeShortcut$ = new BehaviorSubject<IRawShortcut>(null);
  tickedShortcut$ = new Subject<IRawShortcut>(); // for only updating ticked shortcut at header without affecting filter pack

  pendingShortcuts = false;

  constructor(
    private route: Router,
    private userService: UserService,
    private groupService: GroupService,
    private resourceService: ResourceService,
    private router: ActivatedRoute,
    private broadcastService: BroadcastService,
    private organisationService: OrganisationService,
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private analytics: AnalyticsService,
    private mtService: MandatoryTrainingService,
    private featureFlagService: FeatureFlagService,
    private facilityService: FacilitiesService
  ) {
    router.queryParams.subscribe(res => {
      if (res.shortcutID === 'new') {
        return; // don't need to announce after saving a new filter
      }
      this.setActiveShortcut(res.shortcutID);
    });

    this.broadcastService.on('logout').subscribe(() => {
      // DEV
      this.DATA = [];
      this._shortcuts$.next(null);
      this._activeShortcut$.next(null);
      this.globalShortcutByID = null;
    });
  }

  /**APIs */

  addShortcut(name: string, filterForm: IFilterForm): Observable<IRawShortcut> {
    removeEmptyFields(filterForm);
    return this.http.post<IBackendShortcut>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/shortcuts`,
      {name: name, filter: filterForm}).pipe(
        map((r) => this.convertBackendFilter(r)),
        catchError(this.errorHandlingService.handleHttpError),
      );
   }

  updateShortcut(shortcutID: string, shortcutForm: IShortcutForm): Observable<IRawShortcut> {
    return this.http.put<IBackendShortcut>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/shortcuts/${shortcutID}`, shortcutForm)
      .pipe(
        map((r) => this.convertBackendFilter(r)),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchShortcuts() {
    return this.http.get<IBackendShortcut[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/shortcuts`).pipe(
      map(r => r.filter(s => s.creatorID === this.userService.plainOrgUser.userID)),
      map(r => r.map(backendFilter => this.convertBackendFilter(backendFilter))),
      catchError(this.errorHandlingService.handleHttpError),
      tap((r) => {
        this.globalShortcutByID = indexBy(r, 'shortcutID');
        this.DATA = r;
        this._shortcuts$.next(this.DATA);
      }));
    // this._shortcuts$.next([]);
  }

    deleteShortcut(shortcutID: string) {
      return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/shortcuts/${shortcutID}`)
        .pipe(
          catchError(this.errorHandlingService.handleHttpError),
        );
    }

    updateLastOpened(shortcutID: string) {
      return this.http.get<IBackendShortcut>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/shortcuts/${shortcutID}/open`)
      .pipe(
        tap(s => {
          const updatedFilter = this.convertBackendFilter(s);
          const updatedIdx = this.DATA.findIndex(d => d.shortcutID === updatedFilter.shortcutID);
          if (updatedIdx >= 0) {
            this.DATA[updatedIdx] = updatedFilter;
            this._shortcuts$.next(this.DATA);
          }
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
    }

    convertBackendFilter(r: IBackendShortcut): IRawShortcut {
      return {
        title: r.name,
        lastOpened: r.openDate || r.updateDate,
        location: r.filter.location,
        shortcutID: r.shortcutID,
        filters: r.filter,
      };

    }

  getShortcuts(refresh = false, shortcutType?: SHORTCUT_TYPE) {
    // const fetch$ = this._shortcuts$.getValue() ? of(null) : this.fetchShortcuts();
    // return fetch$.pipe(switchMap(() => this._shortcuts$.asObservable()));

    if (refresh || (!this._shortcuts$.getValue() && !this.pendingShortcuts)) {
      this.pendingShortcuts = true;
      const fetch$ = this.fetchShortcuts().pipe(
        tap(() => this.pendingShortcuts = false),
        catchError((e) => {
          this.pendingShortcuts = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe();
    }
    return this._shortcuts$.asObservable().pipe(
      filter(x => !!x),
      map(shortcuts => shortcuts.filter(s => shortcutType ? SHORTCUT_TYPE_MAP[s.location] === shortcutType : true))
      );

  }

  updateShortcutTitle(id, newTitle) {
    return this.getShorcutByID(id).pipe(
      switchMap(s => this.updateShortcut(id, {name: newTitle, filter: {...s.filters, location: s.location}}))
    );
  }

  saveFilter(newFilter: IRawShortcut) {

    // get location value based on url
    newFilter.location = this.getRawLocationOnUrl();

    let newFilter$: Observable<IRawShortcut>;
    const filterForm: IFilterForm = {...newFilter.filters, location: newFilter.location};

    if (!newFilter.shortcutID) {
      // save new
      newFilter$ = this.addShortcut(newFilter.title, filterForm);

    } else {
      // save existing
      newFilter$ = this.updateShortcut(newFilter.shortcutID, {filter: filterForm, name: newFilter.title});
    }
    // DEV
    // return of('success').pipe(delay(1000), tap(() => {
    //   this._shortcuts$.next(this.DATA);
    //   this.openShortcut(newFilter.shortcutID);
    // }));
    return newFilter$.pipe(tap(f => {
      const updatedIdx = this.DATA.findIndex(s => s.shortcutID === f.shortcutID);
      if (updatedIdx >= 0) {
        this.DATA[updatedIdx] = f;
      } else {
        this.DATA.push(f);
      }
      this._shortcuts$.next(this.DATA);
      this.globalShortcutByID = indexBy(this.DATA, 'shortcutID');

      // this.openShortcut(f.shortcutID);
      this.route.navigate([], { queryParams: { shortcutID: 'new' }, queryParamsHandling: 'merge' });
      this.tickedShortcut$.next(f);

    }));
  }

  updateShortcutOpened() {
    return timer(5000).pipe(tap(() => console.log('time updated')));
  }

  getShorcutByID(shortcutID: string) {
    return this.getShortcuts().pipe(
      take(1),
      map(shortcuts => shortcuts.find(s => s.shortcutID === shortcutID) || null));
  }

  getActiveShortcut() {
    return this._activeShortcut$.asObservable();
  }

  /**
   * * process filter.items so it's up to date to current data (eg when filter has deleted users)
   * If user status changes and new status is not in the filter, the user is removed
   * Filtering of planIDs is done on filterpack component to prevent double fetching plans
   */
  getFilteredActiveShortcut(): Observable<IRawShortcut> {
    return this.getActiveShortcut().pipe(
      switchMap((shortcut) => {
        if (!shortcut) {
          return combineLatest([of(null), of(null)]);
        }
        let existingItems$: Observable<Set<String>> | null = of(null);
        let existingResources$: Observable<Set<String>> = of(null);
        switch (shortcut.filters.filterBy) {
          case CATEGORY_ENUM.individual:
            existingItems$ = this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID')).pipe(
              // only include users with relevant statuses
              map(users => {
                const mappedStatus = shortcut.filters.userStatus.map(s => (s === USER_STATUS.inactive ? 'inActive' : s.toLowerCase()));
                return new Set(pluck(Object.values(pick(users, mappedStatus)).flat(), 'orgUserID'));
              })
            );
            break;
          case CATEGORY_ENUM.jobRoles:
            existingItems$ = this.groupService.fetchedCacheOrgJobRoles().pipe(
              map(jobRoles => new Set(pluck(jobRoles, 'jobRoleID'))));
            break;
          case CATEGORY_ENUM.teams:
            existingItems$ = this.groupService.fetchedCacheOrgTeams().pipe(
              map(teams => new Set(pluck(teams, 'teamID'))));
            break;
          case CATEGORY_ENUM.facilities:
            existingItems$ = this.facilityService.fetchCachedFacilities().pipe(
              map(teams => new Set(pluck(teams, 'teamID'))));
            break;
        }

        if (shortcut.filters?.resources) {
          existingResources$ = this.resourceService.getAllActiveResourceID()
            .pipe(map(resourceIDs => new Set(resourceIDs)));
        }

        return combineLatest([of(shortcut), existingItems$.pipe(take(1)), existingResources$]);
      }),
      map(([shortcut, existingItems, existingResources]: [IRawShortcut, Set<string>, Set<string>]) => {
        if (!shortcut) {
          return null;
        }
        if (!existingItems) {
          return shortcut;
        }
        const shortcutWithValidFilter = cloneDeep(shortcut);

        // filter out deleted items
        if (shortcut.filters?.filterBy && shortcut.filters.items) {
          shortcutWithValidFilter.filters.items =
            shortcutWithValidFilter.filters.items.filter(item => existingItems.has(item));

          // eg if previously select all users, select all users again including the new users
          if (shortcut.filters?.isCategoryAllSelected) {
            const arr = Array.from(existingItems)
            shortcutWithValidFilter.filters.items = arr
          }
        }
        // filter out deleted resources
        if (existingResources) {
          shortcutWithValidFilter.filters.resources = shortcutWithValidFilter.filters.resources.filter(r => existingResources.has(r));
        }
        // filter out inactive framework
        if (shortcut.filters?.framework) {
          if (!pluck(this.organisationService.getFullEnabledFrameworks(), 'value')
            .includes(shortcut.filters.framework)) {
            delete shortcutWithValidFilter.filters.framework;
          }
        }
        return shortcutWithValidFilter;
      }),
    );
  }

  setActiveShortcut(shortcutID) {
    if (shortcutID) {
      this.getShorcutByID(shortcutID).subscribe(s => {
        this._activeShortcut$.next(s);
      });
    } else {
      this._activeShortcut$.next(null);
      // this.route.navigate([], {
      //   queryParams: {
      //     shortcutID: null
      //   }, queryParamsHandling: 'merge'
      // });
    }
  }


  openShortcut(shortcutID: string) {
    if (!shortcutID) {
      this.route.navigate([], { queryParams: { shortcutID: null }, queryParamsHandling: 'merge' });
    }

    this.getShorcutByID(shortcutID).pipe(
      tap(shortcut => {
        if (Object.values(SHORTCUT_URL).some(possibleUrl => {
          return window.location.href.includes(possibleUrl);
        })) {
          this.route.navigate([], { queryParams: { shortcutID: shortcutID }, queryParamsHandling: 'merge' });
          return; // don't navigate if already in a page where shortcut is applicable
        } else {
          console.log(this.getLocation(shortcut.location))
          this.route.navigate([this.getLocation(shortcut.location)],
            { queryParams: { prefill: true, shortcutID: shortcutID }, queryParamsHandling: 'merge' });
        }
      }),
    ).subscribe();
  }

  getShortcutLink(shortcutID: string) {
    if (Object.values(SHORTCUT_URL).some(possibleUrl => {
      return window.location.href.includes(possibleUrl);
    })) {
      return {routerLink: [], queryParams: {shortcutID: shortcutID}}
    }

    return {routerLink: this.getLocation(this.globalShortcutByID[shortcutID].location),
      queryParams: {prefill: true, shortcutID: shortcutID}};
  }


  getLocation(location: SHORTCUT_LOCATION) {
    return location === SHORTCUT_LOCATION.workroom ? '/pages/compliance/compliance-workroom' :
            location === SHORTCUT_LOCATION.userCompliance ? '/pages/manage/user-compliance' :
    [SHORTCUT_LOCATION.acksByUsers, SHORTCUT_LOCATION.allAcks, SHORTCUT_LOCATION.acksByStandards, SHORTCUT_LOCATION.acksByPolicies,
      SHORTCUT_LOCATION.policyMng, SHORTCUT_LOCATION.policyUserCompliance].includes(location) ? '/pages/' + SHORTCUT_URL[location] :
      '/pages' + (this.featureFlagService.featureOn(FEATURES.MT) ? '' : '/library') // add extra level for Clinical resource
      + '/reporting/overview/' + SHORTCUT_URL[location];
  }

  getRawLocationOnUrl(): SHORTCUT_LOCATION {
    return Object.entries(SHORTCUT_URL).find(([k, v]) => {
      return window.location.href.includes(((k === SHORTCUT_LOCATION.workroom || k === SHORTCUT_LOCATION.userCompliance || v.includes('policies/'))
        ? ''
        : 'reporting/overview/') + v);
    })[0] as SHORTCUT_LOCATION;
  }

  // for displaying in global search, header button
  getGlobalDisplayShortcuts() {
    return combineLatest([
      this.getShortcuts(),
      merge(this.getActiveShortcut(), this.tickedShortcut$)
    ])
      .pipe(
        map(([shortcuts, activeShortut]) => {
          return shortcuts?.sort((a, b) => dayjs(b.lastOpened ).diff(dayjs(a.lastOpened)))?.map(s => {
            return {
              ...s,
              displayLastOpened: this.calculateDateDiff(s.lastOpened),
              shortcutType: s.location === SHORTCUT_LOCATION.workroom ? 'Compliance Workroom' : 'Report',
              isActive: activeShortut?.shortcutID === s.shortcutID ? true : false
            };
          }) || null;
        }));
  }

  calculateDateDiff(endDate): any {
    const currentDate = new Date();
    const duration = currentDate.getTime() - new Date(endDate).getTime();
    const mins = Math.floor(duration / 60000);
    const hrs = Math.floor(mins / 60);
    const days = Math.floor(hrs / 24);
    const displayDay = +days === 1 ? 'day' : 'days';
    const displayHour = +hrs === 1 ? 'hour' : 'hours';
    const displayMin = +mins === 1 ? 'min' : 'mins';
    return mins > 60 ? (hrs > 24 ? (`${days} ${displayDay} ago`) : `${hrs} ${displayHour} ago`) : (mins === 0 ? '1 min ago' : `${mins} ${displayMin} ago`);
  }

  calculateShortcutsLastOpen(shortcuts: IRawShortcut[]) {
    return shortcuts.map(s => {
      return {
        ...s,
        displayLastOpened: this.calculateDateDiff(s.lastOpened)
      };
    });

  }

  // event tracking
  sendApplyTrack(shortcutID: string, context = 'header') {
    const shortcut = this.globalShortcutByID[shortcutID];
    this.mtService.fetchedCacheMtPlans().pipe(take(1)).subscribe(() => {
      this.analytics.track('S-savedFilter-applied', {
        context: context,
        type: 'existing',
        ...this.getEventBody(shortcutID)
      });
    })
  }

  getEventBody(shortcutID) {
    const shortcut = this.globalShortcutByID[shortcutID];
    const locDict: {[k in SHORTCUT_LOCATION]: string} = {
      [SHORTCUT_LOCATION.allAcks]: 'allAcknowledgements',
      [SHORTCUT_LOCATION.allLearning]: 'allLearningRecords',
      [SHORTCUT_LOCATION.analytics]: 'learningAnalytics',
      [SHORTCUT_LOCATION.compliance]: 'complianceRecords',
      [SHORTCUT_LOCATION.extended]: 'extendedRecords',
      [SHORTCUT_LOCATION.feedback]: 'feedback',
      [SHORTCUT_LOCATION.standards]: 'standards',
      [SHORTCUT_LOCATION.timeSpent]: 'timeSpentLearning',
      [SHORTCUT_LOCATION.optional]: 'optional',
      [SHORTCUT_LOCATION.workroom]: 'complianceWorkroom',
      [SHORTCUT_LOCATION.acksByUsers]: 'acknowledgementByUsers',
      [SHORTCUT_LOCATION.acksByStandards]: 'acknowledgementByStandards',
      [SHORTCUT_LOCATION.acksByPolicies]: 'acknowledgementByPolicies',
      // [SHORTCUT_LOCATION.mandatoryCompliance]: 'mandatoryCompliance',
      [SHORTCUT_LOCATION.mandatoryNoncompliance]: 'mandatoryNoncompliance',
      [SHORTCUT_LOCATION.allRequirements]: 'allRequirementStatus',
      [SHORTCUT_LOCATION.userCompliance]: 'userCompliance',
      [SHORTCUT_LOCATION.allRequirementCompletions]: PAGE_CONTEXT_TYPES.allRequirementCompletions,
      [SHORTCUT_LOCATION.complianceCompletions]: PAGE_CONTEXT_TYPES.complianceCompletions,
      [SHORTCUT_LOCATION.passport]: PAGE_CONTEXT_TYPES.passport,
      [SHORTCUT_LOCATION.passportHolder]: PAGE_CONTEXT_TYPES.passportHolder,
      [SHORTCUT_LOCATION.payroll]: PAGE_CONTEXT_TYPES.payroll,
      [SHORTCUT_LOCATION.policyMng]: 'policyManagement',
      [SHORTCUT_LOCATION.policyUserCompliance]: 'policyUserCompliance',
      [SHORTCUT_LOCATION.reqState]: 'requirementState'
    };
    const [itemDict, keyword] = {
      [LearningGroupType.allUsers]: [this.userService.managedOrgUserDictionaryByOrgUserID, 'orgUserID'],
      [LearningGroupType.individual]: [this.userService.managedOrgUserDictionaryByOrgUserID, 'orgUserID'],
      [LearningGroupType.jobRole]: [this.groupService.globalJobRolesDict, 'title'],
      [LearningGroupType.team]: [this.groupService.globalTeamsDict, 'name'],
      [CATEGORY_ENUM.facilities]: [this.facilityService.globalOrgFacilities, 'name'],
      [LearningGroupType.jobRoleType]: [],
    }[shortcut.filters.filterBy];

    return {
      savedFilterName: shortcut.title,
      savedFilterLocation: locDict[shortcut.location],
      filters: {
        category: shortcut.filters.filterBy,
        accountStatus: shortcut.filters.userStatus,
        categoryType: itemDict ? shortcut.filters.items?.map(i => itemDict[i] ? itemDict[i][keyword] : '') : shortcut.filters.items,
        trainingPlans: shortcut.filters.planIDs?.map(p => this.mtService.globalPlanDict[p]?.title || p)
      }
    };

  }

}

export enum SHORTCUT_LOCATION {
  allLearning = 'allLearningRecords',
  timeSpent = 'timeSpentLearning',
  feedback = 'feedbackReport',
  standards = 'reportingByStandards',
  analytics = 'learningAnalytics',
  compliance = 'complianceReport',
  // mandatoryCompliance = 'mandatoryCompliance',
  mandatoryNoncompliance = 'mandatoryNoncompliance',
  extended = 'extendedReport',
  allRequirements = 'allRequirements',
  optional = 'optional',
  workroom = 'complianceWorkroom',
  allAcks = 'allAcks',
  acksByUsers = 'acksByUsers',
  acksByStandards = 'acksByStandards',
  acksByPolicies = 'acksByPolicies',
  userCompliance = 'userCompliance',
  allRequirementCompletions = 'allRequirementCompletions',
  complianceCompletions = 'complianceCompletions',
  passport = 'passport',
  passportHolder = 'passportHolder',
  payroll = 'payroll',
  policyMng = 'policyMng',
  policyUserCompliance = 'policyUserCompliance',
  reqState = 'reqState'
}

export const SHORTCUT_LOC_DISPLAY: Record<SHORTCUT_LOCATION, string> = {
  allLearningRecords: 'All Learning Records',
  timeSpentLearning: 'Time Spent Learning',
  feedbackReport: 'Feedback Report',
  reportingByStandards: 'Standards Report',
  learningAnalytics: 'Learning Analytics Report',
  complianceReport: REPORT_CARD_CONFIG.complianceStatus.title,
  // mandatoryCompliance: 'Mandatory, Contribute to compliance',
  mandatoryNoncompliance: 'Mandatory, does not contribute to compliance',
  allRequirements: REPORT_CARD_CONFIG.allRequirementStatus.title,
  extendedReport: 'Extended Requirements Report',
  optional: REPORT_CARD_CONFIG.allOptionalStatus.title,
  complianceWorkroom: 'Compliance Workroom',
  allAcks: 'All Acknowledgement Records',
  acksByUsers: 'Report by Users',
  acksByStandards: 'Report by Standards',
  acksByPolicies: 'Report by Policies',
  userCompliance: 'User Compliance',
  allRequirementCompletions: REPORT_CARD_CONFIG.allRequirementCompletions.title,
  complianceCompletions: REPORT_CARD_CONFIG.complianceCompletions.title,
  passport: REPORT_CARD_CONFIG.passport.title,
  passportHolder: REPORT_CARD_CONFIG.passportUser.title,
  payroll: REPORT_CARD_CONFIG.payroll.title,
  policyMng: 'Policy Management',
  policyUserCompliance: 'User Policy Compliance',
  reqState: 'All Requirement States'
};

export const SHORTCUT_URL: Record<SHORTCUT_LOCATION, string> = {
  allLearningRecords: 'learning-records/all-learning-records',
  timeSpentLearning: 'learning-records/time-spent-learning',
  reportingByStandards: 'learning-records/standards-report',
  feedbackReport: 'learning-evaluate/feedback-report',
  learningAnalytics: 'learning-analytics',
  complianceReport: 'requirement-records/mandatory-report/compliance',
  // mandatoryCompliance: 'mandatory-report/compliance',
  mandatoryNoncompliance: 'requirement-records/mandatory-report/noncompliance',
  extendedReport: 'extensions/extended-report',
  allRequirements: 'requirement-records/all-requirements',
  allRequirementCompletions: 'completion-records/all',
  complianceCompletions: 'completion-records/compliance',
  complianceWorkroom: 'compliance/compliance-workroom',
  optional: 'requirement-records/optional',
  payroll: 'payroll/payroll',
  allAcks: 'policies/reporting/all-acknowledgement-records',
  acksByUsers: 'policies/reporting/acknowledgement-report-by-users',
  acksByStandards: 'policies/reporting/acknowledgement-report-by-standards',
  acksByPolicies: 'policies/reporting/acknowledgement-report-by-policies',
  policyMng: 'policies/acknowledgement-manage/policy-management',
  policyUserCompliance: 'policies/acknowledgement-manage/policy-user-compliance',
  userCompliance: 'manage/user-compliance',
  passport: 'ausmed-passport/current-passport-holders',
  passportHolder: 'ausmed-passport/passport-holder-status',
  reqState: 'requirement-records/states/all'
};

export type SHORTCUT_TYPE = 'learningManage' | 'learningReport' | 'policyManage' | 'policyReport';

export const SHORTCUT_TYPE_MAP: Record<SHORTCUT_LOCATION, SHORTCUT_TYPE> = {
  [SHORTCUT_LOCATION.allLearning]: 'learningReport',
  [SHORTCUT_LOCATION.timeSpent]: 'learningReport',
  [SHORTCUT_LOCATION.feedback]: 'learningReport',
  [SHORTCUT_LOCATION.standards]: 'learningReport',
  [SHORTCUT_LOCATION.analytics]: 'learningReport',
  [SHORTCUT_LOCATION.compliance]: 'learningReport',
  [SHORTCUT_LOCATION.mandatoryNoncompliance]: 'learningReport',
  [SHORTCUT_LOCATION.extended]: 'learningReport',
  [SHORTCUT_LOCATION.allRequirements]: 'learningReport',
  [SHORTCUT_LOCATION.optional]: 'learningReport',
  [SHORTCUT_LOCATION.workroom]: 'learningManage',
  [SHORTCUT_LOCATION.userCompliance]: 'learningManage',
  [SHORTCUT_LOCATION.allAcks]: 'policyReport',
  [SHORTCUT_LOCATION.acksByUsers]: 'policyReport',
  [SHORTCUT_LOCATION.acksByStandards]: 'policyReport',
  [SHORTCUT_LOCATION.acksByPolicies]: 'policyReport',
  [SHORTCUT_LOCATION.allRequirementCompletions]: 'learningReport',
  [SHORTCUT_LOCATION.complianceCompletions]: 'learningReport',
  [SHORTCUT_LOCATION.passport]: 'learningReport',
  [SHORTCUT_LOCATION.passportHolder]: 'learningReport',
  [SHORTCUT_LOCATION.payroll]: 'learningReport',
  [SHORTCUT_LOCATION.policyMng]: 'policyManage',
  [SHORTCUT_LOCATION.policyUserCompliance]: 'policyManage',
  [SHORTCUT_LOCATION.reqState]: 'learningReport'
}

export interface IRawShortcut {
  shortcutID?: string;
  location?: SHORTCUT_LOCATION;
  title: string;
  lastOpened;
  filters?: IShortcutFilter;
}

export interface IShortcutFilter {
  filterBy:  CATEGORY_ENUM;
  items: string[];
  userStatus: USER_STATUS[];
  resourceTypes?: ILearningRecordType;
  resources: string[];
  framework?: FRAMEWORKS_ENUM;
  dateType?: 'assignDate' | 'dueDate';
  planIDs?: string[];
  dates?: { dateOption };
  startDate;
  endDate;
  policyIDs?: string[];
  ackStatuses?: string[];
  policyStatuses?: string[];
  mandatoryType?: string[];
  ackComplianceStatus?: string[];
  assignStatus?: string[];
  stateDate?: string;
  isCategoryAllSelected?: boolean
}

export interface IShortcutForm {
  name: string;
  filter: IFilterForm;
}

export interface IFilterForm extends IShortcutFilter {
  location: SHORTCUT_LOCATION;
}

export interface IBackendShortcut {
  shortcutID: string;
  orgID: string;
  creatorID: string;
  name: string;
  filter: any;
  createDate: string;
  updateDate: string;
  openDate: string;
}
