import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { pick } from 'underscore';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from '../../../services/error-handling.service';
import { FILTER_ITEM_ENUM } from '../../../interfaces/filters';
import { BroadcastService } from '../../../services/broadcast.service';

export interface OrgGovAckGroupByOrgUser {
    orgUserID: string;
    unacknowledgedNum: number;
    acknowledgedNum: number;
    reAssignedNum: number;
    discardedNum: number;
    skippedNum: number;
    reminderEarliestLocalDate?: string;
    reminderLatestLocalDate?: string;
    earliestUnAckAssignLocalDate?: string,
}
@Injectable({
  providedIn: 'root'
})
export class PPGoveranceService {
  private _governancesCount;
  private _totalDocCount: number = -1;
  set governancesCount(value) {
    this._governancesCount = value;
  }

  get governancesCount() {
    return this._governancesCount;
  }

  private _initPayload = { orgID: localStorage.getItem('orgID') };


  private _conditionsSubject = new BehaviorSubject<IGovernancesConditions>(undefined);
  conditionChangeAction$ = this._conditionsSubject.asObservable();

  private _resetConditionsSubject: Subject<void> = new Subject();
  resetConditionAction$ = this._resetConditionsSubject.asObservable();

  private _governancesSubject = new BehaviorSubject(null);
  governancesPage = 1;
  governancesEnd = false;

  private _totalPPCount = -1;
  private _PPCount = -1;

  private _hiddenFilterItemsSubject = new BehaviorSubject<FILTER_ITEM_ENUM[]>(undefined);
  hiddenFilterItemsChange$ = this._hiddenFilterItemsSubject.asObservable();

  private _originalConditions: IGovernancesConditions;

  private _payload;
  private _params;

  private _baseFilter: IGovernancesConditions = { params: {}, payload: this._initPayload };
  set baseFilter(conditions: IGovernancesConditions) {
    // console.log('🐻‍: ️', conditions);
    const { payload } = conditions;
    const hiddenFilterItems = [];

    // console.log('🐹', hiddenFilterItems);
    this._hiddenFilterItemsSubject.next(hiddenFilterItems);
    // console.log('base payload: ', payload);
    // console.log('hiddenFilterItems: ', hiddenFilterItems);
    this._baseFilter = conditions;
  }

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.clearConditionsAndResources();
    });

    // // this.conditionChangeAction$.subscribe(c => console.log('🦊 condition changes: ', c))
  }

  getConditions() {
    return this._conditionsSubject.value;
  }

  get originalConditions() {
    return this._originalConditions;
  }

  clearBaseFilter() {
    this._baseFilter = { params: {}, payload: this._initPayload };
  }

  selectedConditionsChanged(conditions: IGovernancesConditions, reset = true) {
    if (this._conditionsSubject.value) {
      //   console.log('condition changed, update _originalConditions to ', this._conditionsSubject.value);
      this._originalConditions = this._conditionsSubject.value;
    }
    if (reset) {
      //   console.log('😛 reset with new conditions: ', conditions);
      this._resetConditionsSubject.next();
      this._conditionsSubject.next({ params: conditions.params, payload: { orgID: localStorage.getItem('orgID') } });
    } else {
      const { params: originalParams, payload: originalPayload } = this._originalConditions || { params: {}, payload: this._initPayload };
      const { params: baseParams, payload: basePayload } = this._baseFilter;
      const params = conditions.params,
            payload = conditions.payload;

      let newPayload;
      if (basePayload.orgID && payload.orgID) {
        newPayload = pick({ ...originalPayload, ...payload, }, v => v !== undefined);
      } else {
        newPayload = pick({ ...originalPayload, ...payload, ...basePayload }, v => v !== undefined);
      }

      const newPrams = pick({ ...originalParams, ...params, ...baseParams }, v => v !== undefined);
      console.log('newPrams', newPrams)
      console.log('newPayload', newPayload)
      // console.log('original params', originalParams);
      // console.log('original payload', originalPayload);
      // console.log('params', params);
      // console.log('payload', payload);
      // console.log('base params', baseParams);
      // console.log('base payload', basePayload);
      // console.log(newPayload);
      // console.log(newPrams);
      this._params = newPrams;
      this._payload = newPayload;
      this._conditionsSubject.next({ params: newPrams, payload: newPayload });
    }
  }

  refreshAllPolicies () {
    this._conditionsSubject.next({ params: this._params || {}, payload: this._payload || this._initPayload});
  }

  fetchOrgAckInfo(): Observable<OrgGovAckGroupByOrgUser[]> {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/acksGroupByOrgUser`;
    return this.http.get<OrgGovAckGroupByOrgUser[]>(url);
  }


  clearGovernances() {
    this._governancesSubject.next(null);
    this.governancesPage = 1;
    this.governancesEnd = false;
  }

  getTotalPPCount() {
    return this._totalPPCount;
  }

  getTotalDocCount() {
    return this._totalDocCount;
  }

  setTotalPPCount(value: number, isDocs = false) {
    if (isDocs) {
      this._totalDocCount = Math.max(value, this._totalDocCount);
      return;
    }
    this._totalPPCount = Math.max(value, this._totalPPCount);
  }

  getPPCount() {
    return this._PPCount;
  }

  setPPCount(value: number) {
    this._PPCount = value;
  }

  clearConditionsAndResources() {
    this._conditionsSubject.next(undefined);
    this._originalConditions = undefined;
    this._hiddenFilterItemsSubject.next(undefined);
    this.clearGovernances();
  }

  fetchPolicyActivityFeed(startDate: string, endDate: string) {
    const orgID = localStorage.getItem('orgID');
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/feeds/acks?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }
}

export interface Payload {
  orgID?: string,
  governanceType?: string[],
  title?: string,
  standards?: string[];
  orgStandards?: string[];
  jobRoles?: string[];
  topics?: string[];
  publishedOnly?: boolean;
  docs?: string[];
  procedures?: string[];
  status?: string[];
  reviewDate?: string;
  governanceVersions?: string[];
}

export interface Params {
  _sort?: 'min' | 'availableDate' | 'title' | 'updateDate';
  _order?: 'ASC' | 'DESC';
  title_like?: string;
  condition?: 'or' | 'and';
}

export interface IGovernancesConditions {
  params: Params;
  payload: Payload;
}
