import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { globalAnimations } from '../../animations/couse-builder-menu-animations';
import { IUIStreamlineIcon, SLColor } from '../../../ui/streamline-icon/streamline-icon-interfaces';
import { COLOUR_DICT, convertFaToSl } from '@ui/streamline-icon/convert-fa-to-sl';
import { ShowAlertStatusPipe } from '../../pipes/show-alert-status.pipe';

import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

export type IAlertType = 'warning' | 'success' | 'error' | 'info';

export interface IAlterConfigByType {
  iconColor: SLColor;
  wrapperClasses: string[];
  textClasses: string[];
  defaultIconSettings?: IUIStreamlineIcon;
}

@Component({
  selector: 'ui-alert-banner',
  templateUrl: './alert-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    .sl-icon-mt-2px {
      margin-top: 2px;
    }
    .warning-text:not(:last-child) {
      display: none
    }
  `],
  animations: [globalAnimations.isAlertDisplay],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ShowAlertStatusPipe,
    UIStreamlineIconComponent
  ],
})
export class AlertBannerComponent implements OnChanges {
  @Input() type: 'Launch' | 'Feedback' | 'Warning' | 'Inactive' | 'Customised' | 'Error' | 'New' = 'Feedback';
  @Input() color = 'blue';
  @Input() icon = 'fa-users-medical';
  @Input() copy = 'This feature is brand new and we would love your feedback on how we can improve it.';
  @Input() withBorder = true;
  @Input() maxWidth = '1400px';
  @Input() iconSettings: IUIStreamlineIcon;
  @Input() alertType: IAlertType;
  @Input() includeDismiss = true;
  @Input() includeCTAButton = false;
  @Input() ctaBtnClasses = 'btn btn-outline-light btn-sm';
  @Input() ctaBtnText = '';
  @Input() slIcon: IUIStreamlineIcon;
  @Input() showClose = false;
  @Output() ctaClicked = new EventEmitter<string | void>();
  wrapperClasses: string[] = [];
  textClasses: string[] = [];
  iconColor: SLColor;
  convertFaToSl = convertFaToSl;
  @Input() hideIcon = false;

  COLOUR_DICT = COLOUR_DICT;

  private readonly _newBannerConfigMap: Record<IAlertType, IAlterConfigByType> = {
    success: { textClasses: ['text-green-800'], iconColor: 'fill-success', wrapperClasses: ['bg-green-100', 'border', 'border-green-800'] },
    error: { textClasses: ['text-red-800'], iconColor: 'fill-danger', wrapperClasses: ['bg-red-100', 'border', 'border-red-800'] },
    warning: { textClasses: ['text-yellow-800'], iconColor: 'fill-warning', wrapperClasses: ['bg-yellow-100', 'border', 'border-yellow-800'] },
    info: { textClasses: ['text-blue-800'], iconColor: 'fill-info', wrapperClasses: ['bg-blue-100', 'border', 'border-blue-800'] }
  };
  private readonly _alterTypes: IAlertType[] = ['warning', 'success', 'error', 'info'];
  showNew = true;
  remainInView = true;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnChanges({ alertType, iconSettings, ctaBtnClasses }: SimpleChanges) {
    if (alertType?.currentValue) {
      if (this._alterTypes.includes(alertType.currentValue)) {
        const { textClasses, wrapperClasses, iconColor } = this._newBannerConfigMap[alertType.currentValue] as IAlterConfigByType;
        this.wrapperClasses = wrapperClasses;
        this.textClasses = textClasses;
        this.iconColor = iconColor;
      } else {
        throw new TypeError(`Don't support current alterType`);
      }
    }
  }

  onDismiss() {
    this.showNew = false;
    setTimeout(() => {
      this.remainInView = false;
      this.cdr.markForCheck();
    }, 2000);
  }

  onCTA() {
    this.ctaClicked.emit(this.ctaBtnText);
  }
}
