import { Component, Input } from '@angular/core';
import { AvatarComponent } from '../avatar.component';

@Component({
  selector: 'app-avatar-requirements',
  standalone: true,
  imports: [AvatarComponent],
  templateUrl: './avatar-requirements.component.html',
  styleUrl: './avatar-requirements.component.scss'
})
export class AvatarRequirementsComponent {
  @Input() iconType: CellAvatarType = 'notes-paper-approve';
  @Input() size = 'sm'

  backgroundColour;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.backgroundColour = {
      'graph-stats-circle-alternate': 'green-700',
      'notes-paper-approve': 'm-sky-dark',
      'list-bullets': 'm-mandarine-dark',
      'loading-3': 'cpd-mark'
    }[this.iconType];
  }
}

export type CellAvatarType = 'notes-paper-approve' | 'plan' | 'list-bullets' | 'loading-3'

