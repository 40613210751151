<ui-unified-page-header
    [breadcrumbConfigs]="[commonBreadcrumbsConfigs.learningReports]"
    [pageTitle]="title"
></ui-unified-page-header>

<div class="p-7 children-horizontal-gap-8">
    <div *ngFor="let group of cardGroups">
        <h4 class="mb-2 fw-semibold">{{group.groupTitle}}</h4>
        <p class="mb-2">{{group.groupDescription}}</p>
        <div class="d-flex flex-wrap gap-2">
            <ng-container *ngFor="let item of group.items">
                <app-overview-card *ngIf="reportCategory !== REPORT_CATEGORY.stakeholderReports" [item]="item"
                    class="card-reporting-size" textSizeClass="f-caption">
                    <ng-container customIcon>
                        <ui-avatar [slIcon]="item.reportSlIcon" class="mb-2" [iconBackgroundColor]="item.iconBgColor" [textColor]="'text-white'"></ui-avatar>
                    </ng-container>
                </app-overview-card>
                <app-advanced-card *ngIf="reportCategory === REPORT_CATEGORY.stakeholderReports" [item]="item"
                    class="card-reporting-size"></app-advanced-card>
            </ng-container>
        </div>
    </div>

</div>

