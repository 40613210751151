import { each, map } from 'underscore';
import { Dictionary } from 'underscore';

export function toParamsDict(url: string): Dictionary<string> {
  const searchStr = url.split('?').slice(1).join('?');
  if (!searchStr) {
    return {};
  }
  const paramStrings = searchStr.split('&');
  const paramTuples = map(paramStrings, (s) => s.split('=')) as [string, string][];
  const paramDict: Dictionary<string> = {};
  each(paramTuples, (tuple) => {
    const key = decodeURIComponent(tuple[0]);
    const value = decodeURIComponent(tuple[1]);
    paramDict[key] = value;
  });
  return paramDict;
}


export function toQueryString(paramsDict: Dictionary<string>) {
  const paramStrings = map(Object.keys(paramsDict), (key) => {
    const value = paramsDict[key];
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });
  return paramStrings.length ? '?' + paramStrings.join('&') : '';
}
