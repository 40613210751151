import { Component, OnInit } from '@angular/core';
import { ObjectCardComponent } from '../../../shared/components/object-card/object-card.component';
import { FeatureFlagDirective } from '../../../shared/directives/feature-flag.directive';
import { NgFor } from '@angular/common';
import { HeaderActionComponent } from '../../../shared/components/header-action/header-action.component';
import { OverviewCardComponent } from '../../overview/shared-overview/overview-card/overview-card.component';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { SharedOverviewComponent } from '../../overview/shared-overview/shared-overview.component';

@Component({
    templateUrl: 'pp-reporting.component.html',
    standalone: true,
    imports: [HeaderActionComponent, NgFor, FeatureFlagDirective, ObjectCardComponent, OverviewCardComponent, AvatarComponent, SharedOverviewComponent]
})

export class PPReportingComponent implements OnInit {
    items = ppReportOverviewItems;
    constructor() { }

    ngOnInit() { }
}

export const ppReportOverviewItems = [
    {
        title: 'By Requirements',
        description: `View all acknowledgement records that exist for your organisation.`,
        reportSlIcon: { name: 'graph-stats-circle-alternate', theme: 'bold' },
        route: ['/pages/policies/reporting/all-acknowledgement-records'],
        displayName: 'By Requirements' // for side nav
    },
    {
        title: 'By Standards',
        description: 'View the standards related to your policies and acknowledgement records.',
        reportSlIcon: { name: 'task-list-to-do', theme: 'bold' },
        route: ['/pages/policies/reporting/acknowledgement-report-by-standards'],
    },
    {
        title: 'By User',
        description: 'View acknowledgement progress and records of each user.',
        reportSlIcon: { name: 'interface-user-single', theme: 'ui-icons-line' },
        route: ['/pages/policies/reporting/acknowledgement-report-by-users'],
    },
    {
        title: 'By Policy',
        description: `View acknowledgement progress and records of each policy. `,
        reportSlIcon: { name: 'calendar-check-1', theme: 'bold' },
        route: ['/pages/policies/reporting/acknowledgement-report-by-policies'],
    },
];


export function overviewItemsToSidenav(arr: any[]) {
    return arr.map(i => ({
        displayName: i.displayName || i.title,
        slIcon: i.reportSlIcon,
        show: true,
        route: i.route[0].replace('/pages/', '')
    }))
}