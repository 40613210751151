import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import { IConvertedOrgAssign } from 'src/app/services/org-assign.service';
import {getMaxWidth} from 'src/app/shared/utils/table-cell-functions';
import { LocalDatePipe } from '../../pipes/local-date.pipe';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { AvatarRequirementsComponent } from '@components/avatar/avatar-requirements/avatar-requirements.component';

@Component({
    selector: 'training-plan-display-link',
    template: `
    <div [style]="'width: ' + maxWidth + 'px;'">
      <div #text class="d-flex align-items-center children-gap-2" >
        <ng-container [ngSwitch]="value.isManualAssign">
          <ng-container *ngSwitchCase="true">
          <app-avatar-requirements iconType="list-bullets"></app-avatar-requirements>
            <div class="text-overflow text-truncate">
              <span
                [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                [disableTooltip]="false"
                [container]="'body'"
              >{{value.text}}</span>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <!-- <ui-avatar size="sm" [slIcon]="{name: 'list-bullets', theme: 'bold'}"
            ></ui-avatar> -->
            <app-avatar-requirements iconType="list-bullets"></app-avatar-requirements>
            <div class="text-overflow text-truncate">
              <a
                [routerLink]="['/pages/compliance/mandatory-training/overview', value.mtPlanID]"
                href="javascript:void(0);"  target="_blank"
                class="fw-medium text-neutral-900"
                [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                        [disableTooltip]="disableTooltip"
                        [container]="'body'"
              >{{value.text}}</a>
              </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <ng-template #list>
        <div class="d-flex flex-column">
          <p class="text-white f-small">{{value.tooltip}}</p>
        </div>
    </ng-template>

  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, AvatarComponent, NgbTooltip, NgSwitchDefault, RouterLink, AvatarRequirementsComponent]
})
export class TrainingPlanCellComponent implements OnInit, AfterViewInit {
  plan: { title?: string; mtPlanID?: string };
  @Input() value: IPlanCell;
  @Input() rowData: IConvertedOrgAssign;
  @Input() disableTooltip = false;
  @ViewChild('text') text;
  maxWidth = 299;
  className = 'mtPlanCell';
  isModal = false;
  showTooltip = false;
  isManualAssign = true;

  constructor(
    private _cdr: ChangeDetectorRef,
    private localDate: LocalDatePipe
  ) { }

  ngOnInit() {
    this.maxWidth = getMaxWidth(this.className, this.isModal, this.maxWidth);

  }

  ngAfterViewInit() {
    const el = this.text.nativeElement;
    if (el.scrollWidth > el.clientWidth) {
      this.showTooltip = true;
      this._cdr.detectChanges();
    }
  }

  get slIcon() {
    return this.isManualAssign ? {name: 'common-file-give-hand', theme: 'bold'}
      : {name: 'list-bullets', theme: 'bold'};
  }
}

export interface IPlanCell {
  text; tooltip; isManualAssign; mtPlanID?; assignerName?; assignDate?; planTitle?;
}
