import { Component, Input, OnInit } from '@angular/core';
import { CustomisedSingleSelectComponent } from '../../../../shared/components/customised-single-select/customised-single-select.component';

@Component({
    selector: 'app-date-type-selector',
    templateUrl: './date-type-selector.component.html',
    styleUrls: ['./date-type-selector.component.scss'],
    standalone: true,
    imports: [CustomisedSingleSelectComponent]
})
export class DateTypeSelectorComponent implements OnInit {
  @Input() controls;
  @Input() payrollDate = false;
  @Input() goalDate = false;
  options = [{
    label: 'Due Date',
    value: 'dueDate',
  },
    {
      label: 'Open Date',
      value: 'assignDate',
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
    // this.reset();
    if (this.payrollDate) {
      this.options = [{
        label: 'Learning Completion',
        value: 'completionDate',
      },
        {
          label: 'Record Creation',
          value: 'completionCreateDate',
        },
      ];
    }
    if(this.goalDate){
      this.options =
      [
        {
          label: 'Due Date',
          value: 'dueDate',
        },
        {
          label: 'Date Added',
          value: 'assignDate',
        },
        {
          label: 'Date Completed',
          value: 'completedDate',
        },
      ];
    }
  }

  reset() {
    this.controls.dateType.setValue(this.options[0].value);
  }

}
