import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SharedSidebarComponent } from '@components/shared-sidebar/shared-sidebar.component';
import { IOrgAssignDueExtension } from 'src/app/services/org-assign.service';
import { ComplianceStatusPipe } from 'src/app/shared/pipes/compliance-status.pipe';
import { ExtensionsCellTableComponent } from './extensions-cell-table/extensions-cell-table.component';
import { AssignStatusCellComponent } from '../assign-status-cell/assign-status-cell.component';
import { AvatarComponent } from '../../avatar/avatar.component';
import { SharedSidebarComponent as SharedSidebarComponent_1 } from '../../shared-sidebar/shared-sidebar.component';

@Component({
  selector: 'app-extensions-cell',
  templateUrl: './extensions-cell.component.html',
  styleUrls: ['./extensions-cell.component.scss'],
  standalone: true,
  imports: [
    SharedSidebarComponent_1,
    AvatarComponent,
    AssignStatusCellComponent,
    ExtensionsCellTableComponent,
  ],
})
export class ExtensionsCellComponent implements OnInit {
  @ViewChild('appSharedSidebar', { static: true }) appSharedSidebar: SharedSidebarComponent;

  @Input() value: string;
  @Input() rowData: { extensions: IOrgAssignDueExtension[]; user; resource; currentStatus, originDueDate };

  constructor(
    private complianceStatusPipe: ComplianceStatusPipe
  ) { }

  ngOnInit(): void {
  }

  openSidePanel() {
    this.appSharedSidebar.toggleShowSidebar();
  }

  get displayStatus() {
    return this.complianceStatusPipe.transform(this.rowData.currentStatus);
  }

}
