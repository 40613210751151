import { Component, ViewChild } from '@angular/core';
import { FilterEnables } from '../filter-set/filter-set.component';
import { GeneralReportService } from '../general-report/general-report.service';
import { CustomDateFormatter } from 'src/app/shared/date-range-selector/custom-date-formatter';
import { REPORT_CARD_CONFIG } from '../../overview/overview-reporting/report-card-config';
import { PAGE_CONTEXT_TYPES } from 'src/app/services/analytics.service';
import { ComplianceDateType, IFilterChange } from '../reporting-type';
import { GeneralReportComponent } from '../general-report/general-report.component';
import { IOrgReportQueryResource, IOrgSGReportMTAssignQueryForm, ReportLearningRecordService } from 'src/app/services/report-learning-record.service';
import { removeEmptyReturn } from 'src/app/shared/utils/remove-empty-fields';
import { HttpParams } from '@angular/common/http';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { Dictionary, groupBy, indexBy, mapObject, pairs, pluck, uniq, values } from 'underscore';
import { GroupService, IJobRole } from 'src/app/services/group.service';
import { GeneralLinkedCellComponent } from '@components/general-linked-cell/general-linked-cell.component';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { PayrollGroupItemCellComponent } from './payroll-group-item-cell/payroll-group-item-cell.component';
import * as dayjs from 'dayjs';
import { ITableDownloadSettings, SmartTableComponent } from '@components/smart-table/smart-table.component';
import { TooltipCellComponent } from '@components/table-cell/tooltip-cell/tooltip-cell.component';
import { IConvertedOrgAssign } from 'src/app/services/org-assign.service';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { LocalDateTimePipe } from 'src/app/shared/pipes/local-date-time.pipe';
import { GroupReportService } from 'src/app/services/reporting/group-report.service';
import { BreadcrumbComponent, commonBreadcrumbsConfigs, IMTBreadcrumbConfig } from '@components/header/breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { AlertBannerComponent } from '@components/alert-banner/alert-banner.component';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { SharedTableActionsComponent } from '@components/shared-table-actions/shared-table-actions.component';
import { TooltipComponent } from '@components/tooltip/tooltip.component';
import { MultiTabTablesComponent } from '../../policies-procedures/policies-procedures-shared/pnp-shared/multi-tab-tables/multi-tab-tables.component';
import { ShortcutButtonContainerComponent } from '../filter-pack/shortcut-button-new/shortcut-button-container/shortcut-button-container.component';
import { PayrollGroupItemCellModalComponent } from './payroll-group-item-cell/payroll-group-item-cell-modal/payroll-group-item-cell-modal.component';

@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.scss'],
  providers: [GeneralReportService, CustomDateFormatter],
  standalone: true,
  imports: [
      CommonModule,
      GeneralReportComponent,
      SmartTableComponent,
      MultiTabTablesComponent,
      SharedTableActionsComponent,
      TooltipComponent,
      ModalSingleComponent,
      AlertBannerComponent,
      ShortcutButtonContainerComponent,
      BreadcrumbComponent,
      PayrollGroupItemCellModalComponent
  ]

})
export class PayrollReportComponent {
  REPORT_CONFIG = REPORT_CARD_CONFIG.payroll;
  pageContext = PAGE_CONTEXT_TYPES.payroll
  filterEnables: FilterEnables = {
    framework: false,
    filterBy: true,
    selectItem: true,
    // learningSource: true,
    dateRange: true,
    trainingPlan: true,
    dateType: true,
    resource: true,
    inactiveUser: true,
    pendingAndInvitedUser: true,
    mandatoryType: true,
    payrollDate: true,

  };

  allTableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollAll,
    actions: false,
    columns: {
      ...this.columnService.getColumns(
        ['user', 'teams', 'facilityModels', 'jobRoles', 'workType', 'staffID', 'internalID', 'email', 'orgEmail', 'orgMobile', 'accountStatus', 'resource', 'code', 'resourceObj', 'resourceDuration', 'completeTimestamp', 'createTimestamp']
        , { local: true, show: ['resourceDuration'], hidden: ['staffID', 'internalID', 'email', 'orgEmail', 'orgMobile', 'accountStatus', 'code'] }
      ),
      // completeTimestampLocal: {
      //   title: 'End Time',
      // },
      mtPlanCells: {
        title: 'Sources',
        type: 'custom',
        renderComponent: TooltipCellComponent,
        sort: false,
        filter: false
      },
      firstAssigner: {
        title: 'Assigned By',
        // type: 'custom',
        // renderComponent: TooltipCellComponent,
        sort: false,
        filter: false,
        defaultToShow: false
      },
      firstAssignDate: {
        title: 'Assigned Date',
        // type: 'custom',
        // renderComponent: TooltipCellComponent,
        sort: false,
        filter: false,
        defaultToShow: false
      },
    }
  }
  groupColumns = {
    // allTitles: {title: 'Items'}, // list of titles
    allTitles: {
      title: 'Items',
      type: 'custom',
      renderComponent: PayrollGroupItemCellComponent,
      sort: false,
      filter: false
    }, // list of titles
    totalDuration: { title: 'Total Learning (Minutes)' }, // total resource duration
  }
  userTableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollUser,
    actions: false,
    columns: {
      ...this.columnService.getColumns(['user', 'teams', 'facilityModels', 'jobRoles', 'workType', 'staffID', 'internalID', 'email', 'orgMobile', 'accountStatus'],
        { local: true, hidden: ['staffID', 'internalID', 'email', 'orgEmail', 'orgMobile', 'accountStatus'] }),
      ...this.groupColumns
    }
  }
  teamTableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollTeam,
    actions: false,
    columns: 
    {
      ...this.columnService.getColumns(['teams'], {local: true}),
      ...this.groupColumns
    }
  };
  locationTableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollTeam,
    actions: false,
    columns: {
      ...this.columnService.getColumns(['facilityModels'], {local: true}),
      ...this.groupColumns
    }
  };
  managerTableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollManager,
    actions: false,
    columns: {
      ...this.columnService.getColumns(['directManager'], {local: true}),
      ...this.groupColumns
    }
  };
  jobTableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollJob,
    actions: false,
    columns: {
      ...this.columnService.getColumns(['jobRoles'], {local: true}),
      ...this.groupColumns
    }
  }
  allTableSource = new LocalDataSource();
  byUserTableSource = new LocalDataSource();
  byTeamTableSource = new LocalDataSource();
  byLocationTableSource = new LocalDataSource();
  byManagerTableSource = new LocalDataSource();
  byJobRoleTableSource = new LocalDataSource();

  tableSettingsArr = [this.allTableSettings, this.userTableSettings, this.teamTableSettings, this.locationTableSettings, this.jobTableSettings, this.managerTableSettings];
  tableSourceArr = [this.allTableSource, this.byUserTableSource, this.byTeamTableSource, this.byLocationTableSource, this.byJobRoleTableSource, this.byManagerTableSource];

  tables = [
    { name: 'By Requirement', numRow: 0 },
    { name: 'By User', numRow: 0 },
    { name: 'By Team', numRow: 0 },
    { name: 'By Location', numRow: 0 },
    { name: 'By Job Role', numRow: 0 },
    { name: 'By Manager', numRow: 0 },
  ]
  activeTab = 0;
  tableDownloadSettings: ITableDownloadSettings = { title: 'Payroll Report' };

  breadcrumbConfigs: IMTBreadcrumbConfig[] = [
    commonBreadcrumbsConfigs.learningReports,
    { label: 'Payroll', url: '/pages/reporting/overview/payroll' },
  ];

  @ViewChild(GeneralReportComponent) report: GeneralReportComponent;

  constructor(
    protected learningRecordService: ReportLearningRecordService,
    private columnService: SmartTableColumnsService,
    private groupService: GroupService,
    private userService: UserService,
    private groupReportService: GroupReportService,
  ) {

  }

  onFilterChange(filterChange: IFilterChange) {
    const queries = this.getQueries(filterChange);
    const params = this.getParams(filterChange);
    this.learningRecordService.fetchAssignsForTable(
      filterChange.startDate, filterChange.endDate,
      queries, filterChange.dateType, params)
      .pipe(map(assigns => assigns.filter(a => a.status !== 'Skipped')))
      .subscribe((assigns) => {
        const assignTableData = this.addSourcesColumn(assigns);
        const grouped = mapObject(this.groupReportService.groupIndividualRecords(assignTableData, 'assignee'), groups => {
          return groups
            .map(g => ({...g, ...this.getGroupedData(g.groupRecords)}))
            .filter(g => g.totalDuration || g.allTitles?.length)
        });
        console.log(grouped);
        // console.log(assigns);
        const tableDatas = [assignTableData, this.groupByUser(assignTableData), grouped.byTeams, grouped.byLocations, grouped.byJobRoles, grouped.byManager];
        this.tableSourceArr.forEach((source, idx) => {
          source.load(tableDatas[idx]);
          this.tables[idx].numRow = tableDatas[idx].length
        })
        this.report.showReport();
      });
  }

  getQueries(filterChange: IFilterChange) {
    const {
      startDate,
      endDate,
      resources: selectedResources = [],
      uIDs,
      staffIDs = [],
      planIDs = [],
      itemIDs = [] } = filterChange;
    const { active: activeUserIDs = [], inactive: inactiveUserIDs = [], pending = [], invited = [] } = uIDs;
    const filteredActiveAndInactiveOrgUserIDs = [...activeUserIDs, ...inactiveUserIDs, ...pending, ...invited];
    const resourceQueries = selectedResources.map<IOrgReportQueryResource>(selectResource => ({
      resourceID: selectResource.resourceID,
      resourceType: selectResource.activityType || 'e-learning',
      providerType: selectResource.providerType || '',
    }));

    const queries = {
      filteredUsers: filteredActiveAndInactiveOrgUserIDs,
      filteredPlans: planIDs.filter(p => p !== 'manual'),
      filteredResources: resourceQueries,
      filteredStaffIDs: staffIDs,
      // filteredStatuses: convertFilterStatus(filterChange.assignStatus),
    } as IOrgSGReportMTAssignQueryForm;
    return queries;
  }

  getParams(filterChange: IFilterChange) {
    // params related to assign
    const planIDs = filterChange.planIDs || [];
    const realPlanIDs = planIDs.filter(p => p !== 'manual')
    let assignType = realPlanIDs.length ? 'Plan,' : '';
    assignType += (planIDs.includes('manual')) ? 'Manual' : '';
    const assignParams = new HttpParams({
      fromObject: removeEmptyReturn({
        assignTypes: assignType,
        complianceTypes: filterChange.mandatoryType?.join(',') || '', // show optional only in manual report
      })
    });
    return assignParams;

  }

  groupByUser(assigns: any[]) {
    return values(mapObject(groupBy(assigns, 'assignee'), userAssigns => {
      return {
        ...userAssigns[0],
        ...this.getGroupedData(userAssigns),
        workType: this.userService.getOrgUserWorkType(userAssigns[0].user) || '-',
      }
    }));
  }

  getGroupedData(arr: any[]) {
    return {
      // allTitles: arr.reduce((p, c) => p + ', ' + c.resource?.title || '', '').replace(', ', ''),
      allTitles: arr.reduce((p: string[], c) => {
        p.push(c.resource?.title || '');
        return p;
      }, []),
      totalDuration: uniq(arr, a => a.activityID).reduce((p, c) => (c.resourceDuration || 0) + p, 0),
      assigns: arr,
    }
  }

  // filter out duplicate activities
  // aggregate sources for acts with multiple assigns
  addSourcesColumn(assigns: IConvertedOrgAssign[]) {
    const sourceDic = mapObject(indexBy(assigns, 'activityID'), () => []);
    const firstAssignDic: Dictionary<IConvertedOrgAssign> = {};
    assigns.forEach((a) => {
      sourceDic[a.activityID].push(a.mtPlanCell.tooltip);

      // find the earliest assign for the activity
      const currentFirstAssign = firstAssignDic[a.activityID];
      if (!currentFirstAssign || currentFirstAssign.assignDate > a.assignDate) {
        firstAssignDic[a.activityID] = a;
      }

    });
    assigns = uniq(assigns
      .map((d) => {
        const firstAssign = firstAssignDic[d.activityID]
        return {
        ...firstAssign,

        mtPlanCells: sourceDic[d.activityID],
        workType: this.userService.getOrgUserWorkType(d.user),
        firstAssignDate: dayjs(firstAssign.assignDate).format('DD-MM-YYYY'),
        firstAssigner: firstAssign.assignType === 'Manual' ? 'Manual' : 'System'
        }
      }), false, a => a.activityID
    );
    return assigns;
  }

  getParamEndDate(endDate, dateType: ComplianceDateType) {
    if (dateType === ComplianceDateType.creationDate) {
      return dayjs(endDate).add(1, 'day').format('YYYY-MM-DD');
    }
    return endDate
  }

  downloadCSV(settings) {
    // const title = 'Payroll Report'
    // downloadReportBySourceAndSettings(this.tableSourceArr[this.activeTab],
    //   settings, title, `${title}.csv`, [], ['user'], ['resource'], [{ field: 'mtPlanCell', fieldKey: 'tooltip' }]);
    // this._toaster.success(`Downloaded file`);
  }
}

export function formatTimeStamp(localDatePipe: LocalDateTimePipe) {
  return (val) => localStorage.getItem('orgcode') === 'IRTLMS' ? localDatePipe.transform(val, 'MM/DD/YYYY HH:mm') : localDatePipe.transform(val)
}
