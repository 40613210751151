import { Component, Input } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { OverviewInfoCardComponent } from '../overview-info-card/overview-info-card.component';
import { OverviewCardComponent } from './overview-card/overview-card.component';
import { FeatureFlagDirective } from 'src/app/shared/directives/feature-flag.directive';
import { OverviewShortcutsComponent } from '../overview-reporting/overview-shortcuts/overview-shortcuts.component';
import { SHORTCUT_TYPE } from 'src/app/services/shortcut.service';
import { UnifiedPageHeaderComponent } from '@components/header/unified-page-header/unified-page-header.component';

@Component({
  selector: 'app-shared-overview',
  standalone: true,
  imports: [CommonModule, OverviewInfoCardComponent, OverviewCardComponent, FeatureFlagDirective, NgIf, OverviewShortcutsComponent, UnifiedPageHeaderComponent],
  templateUrl: './shared-overview.component.html',
  styleUrls: ['./shared-overview.component.scss']
})
export class SharedOverviewComponent {
  @Input() title;
  @Input() cardBoldedText;
  @Input() cardText;
  @Input() items;
  @Input() shortcutType: SHORTCUT_TYPE
  @Input() showUnifiedPageHeader = false;
}
