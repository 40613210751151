import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { IAnalyticsEventWithArgs, IAnalyticsEventWithoutArgs } from '@ausmed/cpdo-analytics-types';
import { BehaviorSubject, timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { scriptLoader } from '../shared/utils/script-loader';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import * as dayjs from 'dayjs';
import { churnZeroOrgBlacklist } from './initialize.service';
import { UserService } from './user.service';

declare var analytics: any;

export enum PAGE_CONTEXT_TYPES {
  // Ref document: https://docs.google.com/spreadsheets/d/1r_eSFcUlGhE-ONCiwJMA7SbwfVCfCruPe8I5NMUgEP8/edit?pli=1#gid=498046536
  addFlow = 'addFlow', // for add flow
  reporting = 'reporting', // for all reporting page
  optionalRequirements = 'optionalRequirements', // not yet to add into z-page-loaded
  learningDashboard = 'dashboard',
  complianceWorkroom = 'complianceWorkroom',
  trainingPlans = 'trainingPlans',
  trainingPlanDetail = 'trainingPlanDetail',
  yourLibrary = 'yourLibrary',
  ausmedLibrary = 'allResource',
  library = 'library',
  allLearningRecords = 'allLearningRecords',
  timeSpentLearning = 'timeSpentLearning',
  feedbackReport = 'feedbackReport',
  reportByStandards = 'reportByStandards',
  learningAnalytics = 'learningAnalytics',
  complianceReport = 'complianceReport',
  optionalReport = 'optionalReport',
  extendedReport = 'extendedReport',
  allRequirementReport = 'allRequirementReport',
  mandatoryNonComplianceReport = 'mandatoryNonComplianceReport',
  manageUsers = 'manageUsers',
  teams = 'teams',
  team = 'team',
  jobRoles = 'jobRoles',
  jobRole = 'jobRole',
  structures = 'structures',
  policyDashboard = 'policyDashboard',
  policyLibrary = 'policyLibrary',
  additionalDocuments = 'additionalDocuments',
  myPolicyLibrary = 'myPolicyLibrary',
  acknowledgementRecords = 'acknowledgementRecords',
  adminDashboard = 'adminDashboard',
  complianceCompletions = 'complianceCompletions',
  allRequirementCompletions = 'allRequirementCompletions',
  userCompliance = 'userCompliance',
  passport = 'passport',
  passportHolder = 'passportHolder',
  payroll = 'payrollReport',
  yourSurveys = 'yourSurveys',
  complianceSetting = 'complianceSetting',
  allRequirementStates = 'allRequirementStates',
  // Used for overview pages under the Learning tab
  plan = 'plan',
  manage = 'manage',
  report = 'report',
  admin = 'admin',
  standards = 'standards',
  // for TP template
  TPTemplates = 'TPTemplates',
  bundles = 'bundles',
  customStandards = 'customStandards',

  // Used for SCROM Library
  scormTrainingSchedules = 'scormTrainingSchedules',
  usageReport = 'usageReport',
  scormReportByStandards = 'scormReportByStandards',
  scormFeedbackReport = 'scormFeedbackReport',
  auditLog = 'auditLog',
  complianceCalendar = 'complianceCalendar',
  yourLibraryOverview = 'yourLibrayOverview',
  itemManagement = 'itemManagement',

  // analytics
  auditReport = 'auditReport',
  boardReport = 'boardReport',
  teamReportManage = 'teamReport',
  competencyLibrary = 'competencyLibrary'
}

export const assignActionContext = {
  'workroom': 'complianceWorkroom',
  'transcript': 'transcript',
  'training': 'trainingPlan',
  'optional': 'optionalLearning',
  'calendar': 'complianceCalendar',
  'compliance-report': PAGE_CONTEXT_TYPES.complianceReport,
  'user-compliance': PAGE_CONTEXT_TYPES.userCompliance,
  'all-requirements': PAGE_CONTEXT_TYPES.allRequirementReport
};


// url: context
export const URLContextMap = {
  ...assignActionContext,
  'teams': 'teams',
  'reporting': 'reporting',
  'manage-job-roles': 'jobRoles',
  'staff-transcript': 'transcript',
  'manage-staff': 'users',
  'compliance': 'compliance',
  '/admin/dashboard': 'adminDashboard',
};



export interface PageLoadTimerType { startTime; endTime; }
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private pageLoadTimer = {
    [PAGE_CONTEXT_TYPES.learningDashboard]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.complianceWorkroom]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.trainingPlans]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.trainingPlanDetail]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.yourLibrary]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.ausmedLibrary]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.allLearningRecords]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.allRequirementCompletions]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.complianceCompletions]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.timeSpentLearning]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.feedbackReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.reportByStandards]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.learningAnalytics]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.complianceReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.allRequirementReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.extendedReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.mandatoryNonComplianceReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.optionalReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.manageUsers]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.teams]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.team]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.jobRoles]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.jobRole]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.structures]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.policyDashboard]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.policyLibrary]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.additionalDocuments]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.myPolicyLibrary]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.acknowledgementRecords]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.adminDashboard]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.userCompliance]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.passport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.passportHolder]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.yourSurveys]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.complianceSetting]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.TPTemplates]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.bundles]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.customStandards]: <PageLoadTimerType>{ startTime: null, endTime: null },

    [PAGE_CONTEXT_TYPES.scormTrainingSchedules]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.usageReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.scormReportByStandards]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.scormFeedbackReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.auditLog]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.complianceCalendar]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.payroll]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.yourLibraryOverview]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.itemManagement]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.auditReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.boardReport]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.teamReportManage]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.allRequirementStates]: <PageLoadTimerType>{ startTime: null, endTime: null },
    [PAGE_CONTEXT_TYPES.competencyLibrary]: <PageLoadTimerType>{ startTime: null, endTime: null },
  };
  loadingSegmentScript = new BehaviorSubject(false);
  segmentScriptLoaded = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private zone: NgZone,
    private userService: UserService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      (function () {
        // tslint:disable-next-line:no-shadowed-variable
        let analytics = (window as any).analytics = (window as any).analytics || []; if (!analytics.initialize) {
          if (analytics.invoked) { window.console && console.error && console.error('Segment snippet included twice.'); } else {
            analytics.invoked = !0; analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on']; analytics.factory = function (t) { return function () { let e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics; }; }; for (let t = 0; t < analytics.methods.length; t++) { let e = analytics.methods[t]; analytics[e] = analytics.factory(e); } analytics.load = function (t, e) { let n = document.createElement('script'); n.type = 'text/javascript'; n.async = !0; n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'; let a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e; }; analytics.SNIPPET_VERSION = '4.1.0';
          }
        }
      })();
    }
  }


  track(event: IAnalyticsEventWithoutArgs, callback?: () => void);
  track(event: IAnalyticsEventWithArgs, args: object, callback?: () => void);
  track(event: string, data?: object, callback?: () => void): void {
    if (isPlatformBrowser(this.platformId)) {
      this.load().then(() => {
        let postData = {
          source: 'website',
          orgID: localStorage.getItem('orgID'),
          orgCode: localStorage.getItem('orgcode'),
          adminLogin: localStorage.getItem('adminLogin') || false,
          adminUsername: localStorage.getItem('adminUsername') || undefined,
          email: this.userService?.plainOrgUser?.userDetail?.email || '',
          firstName: this.userService?.plainOrgUser?.userDetail?.firstName || '',
          lastName: this.userService?.plainOrgUser?.userDetail?.lastName || '',
          role:this.userService?.isManager?'Org Manager':'Team Manager'
        };

        let integrations = {
          'All': true,
          'Vero': true,
          'Intercom': true,
          'Google Analytics': true,
          'Mixpanel': true
        }

        if (data && typeof data !== 'function') {
          postData = _.extend(postData, data);
        }

        // add additional properties for ChurnZero

        if (environment.production && churnZeroOrgBlacklist.indexOf(localStorage.getItem('orgcode').toUpperCase()) < 0) {
          postData = _.extend(postData, {
            accountExternalId: localStorage.getItem('orgcode').toUpperCase(),
            contactExternalId: this.userService?.plainOrgUser?.userDetail?.email || ''
          });
          integrations = _.extend(integrations, { 'ChurnZero': true });
        } else {
          integrations = _.extend(integrations, { 'ChurnZero': false });
        }
        try {
          this.zone.runOutsideAngular(() => {
            analytics.track(event, postData,
              {
                integrations
              }, typeof data !== 'function' ? callback : data);
          });
        } catch (error) {
          console.log(error);
        }
      });

    }
  }

  identify(userId: any, data) {
    if (isPlatformBrowser(this.platformId)) {
      let integrations = {
        'All': true,
        'Vero': true,
        'Intercom': true,
        'Google Analytics': true,
        'Mixpanel': true
      }

      if (environment.production && churnZeroOrgBlacklist.indexOf(localStorage.getItem('orgcode').toUpperCase()) < 0) {
        data = _.extend(data, {
          accountExternalId: localStorage.getItem('orgcode').toUpperCase(),
          contactExternalId: this.userService?.plainOrgUser?.userDetail?.email || ''
        });
        integrations = _.extend(integrations, { 'ChurnZero': true });
      } else {
        integrations = _.extend(integrations, { 'ChurnZero': false });
      }
      this.load().then(() => {
        try {
          this.zone.runOutsideAngular(() => {
            analytics.identify(userId, data,
              {
                integrations
              });
          });
        } catch (error) {
          console.log(error);
        }
      });

    }
  }

  page() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('identify', data);
      this.load().then(() => {
        try {
          this.zone.runOutsideAngular(() => {
            analytics.page();
          });
        } catch (error) {
          console.log(error);
        }
      });

    }
  }

  reset() {
    analytics.reset();
  }

  // TODO: revise
  load() {
    return new Promise<void>((resolve, reject) => {
      if (this.segmentScriptLoaded) {
        resolve();
        return;
      }
      this.loadingSegmentScript.subscribe(
        (loading: boolean) => {
          if (!this.segmentScriptLoaded && !loading) {
            // script not loaded && not loading script: load script
            this.loadingSegmentScript.next(true);
            this.zone.runOutsideAngular(() => {
              timer(3000).subscribe(() => {
                try {
                  analytics.load(environment.segment);
                  this.segmentScriptLoaded = true;
                  this.loadingSegmentScript.next(false);
                  resolve();
                } catch {
                  this.loadingSegmentScript.next(false);
                  resolve();
                }
              });
            });
          } else if (this.segmentScriptLoaded && !loading) {
            resolve();
          }
        }
      );

    });

  }

  loadGoogleTag() {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.reject(null);
    }
    return scriptLoader.loadJS('https://www.googletagmanager.com/gtag/js');
  }

  loadFacebookScript() {
    if (!isPlatformBrowser(this.platformId)) {
      return Promise.reject(null);
    }
    return scriptLoader.loadJS('https://connect.facebook.net/en_US/sdk.js');
  }

  // used to calculate the page load time for Z-page-loaded event
  resetTimer(context: PAGE_CONTEXT_TYPES) {
    this.pageLoadTimer[context].startTime = dayjs();
  }

  pauseTimer(context: PAGE_CONTEXT_TYPES) {
    this.pageLoadTimer[context].endTime = dayjs();
  }

  stopTimer(context: PAGE_CONTEXT_TYPES) {
    console.log('Send', context, this.getPageTimeDiff(context));
    this.pageLoadTimer[context].startTime = null;
    this.pageLoadTimer[context].endTime = null;
  }

  getPageTimeDiff(context: PAGE_CONTEXT_TYPES) {
    return Number(this.pageLoadTimer[context].endTime?.diff(this.pageLoadTimer[context].startTime, 's', true)).toFixed(2);
  }

  getContext(url: string): string {
    for (const i in URLContextMap) {
      if (url.match(`${i}`)) {
        return URLContextMap[i];
      }
    }
  }

  createTimer(context: PAGE_CONTEXT_TYPES) {
    return new AnalyticTimer(this, context);
  }
}

export class AnalyticTimer {
  private analyticService: AnalyticsService;
  private context: PAGE_CONTEXT_TYPES;
  private isTrackSent = false;
  constructor(service: AnalyticsService, context: PAGE_CONTEXT_TYPES) {
    this.analyticService = service;
    this.context = context;
    this.analyticService.resetTimer(context);
  }

  send() {
    this.analyticService.pauseTimer(this.context);

    // ensure only send once
    if (!this.isTrackSent) {
      this.analyticService.track('Z-page-loaded', {
        context: this.context,
        loadTime: this.analyticService.getPageTimeDiff(this.context),
      });
      this.isTrackSent = true;
    }

    this.analyticService.stopTimer(this.context);

  }
}

