import { Component } from '@angular/core';

import { DefaultEditor } from './default-editor';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'textarea-editor',
    styleUrls: ['./editor.component.scss'],
    template: `
    <textarea [ngClass]="inputClass"
              class="form-control"
              [(ngModel)]="cell.newValue"
              [name]="cell.getId()"
              [disabled]="!cell.isEditable()"
              [placeholder]="cell.getTitle()"
              (click)="onClick.emit($event)"
              (keydown.enter)="onEdited.emit($event)"
              (keydown.esc)="onStopEditing.emit()">
    </textarea>
    `,
    standalone: true,
    imports: [FormsModule, NgClass],
})
export class TextareaEditorComponent extends DefaultEditor {

  constructor() {
    super();
  }
}
