export type ColourToken =
    'pink-600'
    | 'pink-300'
    | 'pink-200'
    | 'black'
    | 'white'
    | 'm-lavender-default'
    | 'm-blueberry-default'
    | 'm-sky-default'
    | 'm-sky-dark'
    | 'm-sea-default'
    | 'm-leaf-default'
    | 'm-mandarine-default'
    | 'm-blueberry-dark'
    | 'neutral-600'
    | 'neutral-900'
    | 'neutral-800'
    | 'neutral-700'
  | 'neutral-500'
    | 'neutral-400'
    | 'neutral-300'
    | 'neutral-200'
    | 'brand-peach-light'
    | 'red-600'
    | 'red-700'
    | 'red-500'
    | 'yellow-800'
    | 'yellow-700'
    | 'yellow-600'
    | 'yellow-400'
    | 'green-700'
    | 'green-600'
    | 'green-400'
    | 'green-300'
    | 'green-200'
    | 'green'
    | 'blue-800'
    | 'blue-700'
    | 'blue-200'
    | 'blue-600'
  | 'yellow-600'
  | 'yellow-800'
    | 'success'
    | 'warning'
    | 'danger';

const colorKVs: [ColourToken, string][] = [
  ['white', '#fff'],
  ['black', '#000'],
  ['neutral-200', '#EDEFF2'],
  ['neutral-300', '#DEE1E7'],
  ['neutral-400', '#CED2DA'],
  ['neutral-500', '#B1B5C2'],
  ['neutral-600', '#A0A5B1'],
  ['neutral-700', '#6F7786'],
  ['neutral-800', '#4E545F'],
  ['neutral-900', '#171D29'],
  ['pink-600', '#F44F6C'],
  ['pink-300', '#F49FAD'],
  ['pink-200', '#F8C4CC'],
  ['m-lavender-default', '#C69BC8'],
  ['m-blueberry-default', '#5377CA'],
  ['m-sky-default', '#7BC6FC'],
  ['m-sky-dark', '#3889C2'],
  ['m-sea-default', '#9AD5E0'],
  ['m-leaf-default', '#55A474'],
  ['m-mandarine-default', '#F4B455'],
  ['m-blueberry-dark', '#123890'],
  ['brand-peach-light', '#FA888E'],
  ['red-700', '#D7440E'],
  ['red-500', '#ED7043'],
  ['red-600', '#EE551D'],
  ['yellow-400', '#FFD74F'],
  ['yellow-600', '#FFC300'],
  ['yellow-700', '#FFA200'],
  ['yellow-800', '#FF7100'],
  ['green-700', '#338052'],
  ['green-600', '#4BAE72'],
  ['green-400', '#77C596'],
  ['green-300', '#96D9B1'],
  ['green-200', '#BBE9CE'],
  ['green', '#4BAE72'],
  ['blue-800', '#0E3789'],
  ['blue-700', '#2353B4'],
  ['blue-200', '#C6D7F9'],
  ['blue-600', '#3E6CCA'],
  ['success', '#4BAE72'],
  ['warning', '#FFC300'],
  ['danger', '#D7440E']];

const ColourMap = new Map<ColourToken, string>(colorKVs);

export default ColourMap;
