import { Component, Input, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CustomisedSingleSelectComponent, ISingleSelectOption } from '@components/customised-single-select/customised-single-select.component';
import { UserService } from 'src/app/services/user.service';
import { keys, values } from 'underscore';
import { BaseStepComponent } from '../test';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-select-direct-manager',
  standalone: true,
  imports: [CustomisedSingleSelectComponent],
  templateUrl: './select-direct-manager.component.html',
  styleUrl: './select-direct-manager.component.scss'
})
export class SelectDirectManagerComponent extends BaseStepComponent {
  @Input() form: AbstractControl<string>;
  @Input() replacedManager?: string;
  @Input() label = 'Select Manager';

  options: ISingleSelectOption[] = orderBy(values(inject(UserService).managedOrgUserDictionaryByOrgUserID)
    .map(u => ({value: u.orgUserID, label: u.fullName, imgUrl: u.userDetail?.profilePicURL}))
    // if replacing, don't include old manager
    .filter(i => this.replacedManager ? i.value !== this.replacedManager : true), 'label')


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

}
