import { Component, Input, inject } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { ITableSettings, SmartTableComponent } from '@components/smart-table/smart-table.component';
import { IPerformanceReviewAssign, ITablePerformanceReviewAssign, PerformanceReviewService, TASK_ASSIGN_STATUS_ENUM } from 'src/app/services/performance-review.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { getColumnProgressAssign } from '../task-activity-tables/task-table/task-table.component';
import { TableActionButtonComponent } from '@components/table-cell/action-buttons/table-action-button/table-action-button.component';
import { TaskModalComponent } from '../task-activity-tables/task-modal/task-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getTaskActionButtons } from '../task-activity-tables/task-table/task-table-utils';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';

@Component({
  selector: 'app-progress-activity-modal',
  standalone: true,
  imports: [ModalSingleComponent, SmartTableComponent],
  templateUrl: './progress-activity-modal.component.html',
  styleUrl: './progress-activity-modal.component.scss'
})
export class ProgressActivityModalComponent extends ConfirmationModal {
  @Input() activityStatus;
  @Input() assigns: ITablePerformanceReviewAssign[];
  @Input() modalHeader;
  @Input() context = 'my tasks';
  tableHeader: string;

  settings: ITableSettings = {
    actions: false,
    tableUniqKey: LocalTableUniqKey.progressModal,
    columns: {...getColumnProgressAssign(),
      actions: {
        title: 'Actions',
        exemptFromEdit: true,
        sort: false,
        filter: false,
        type: 'custom',
        renderComponent: TableActionButtonComponent,
        onComponentInitFunction: (instance: TableActionButtonComponent) => {
          instance.buttons = getTaskActionButtons()
          instance.actionClicked.subscribe(val => this.onAction(val.action, val.rowData))
        }
      }
  

    }
  }
  source = new LocalDataSource();
  prService = inject(PerformanceReviewService);
  modalService = inject(NgbModal);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.tableHeader = `Showing ${this.assigns?.length} Tasks` 
    this.source.load(this.prService.sortTasks(this.assigns));
  }

  onAction(action, task: ITablePerformanceReviewAssign) {
    if (action === 'remind') {
      this.prService.remindModal(task, this.context);
      return;
    }
    this.openReviewTask(task)
  }


  openReviewTask(task: ITablePerformanceReviewAssign) {
    const modal = this.modalService.open(TaskModalComponent, { size: 'xl' });
    modal.componentInstance.taskID = task.taskID;
    modal.componentInstance.context = this.context;
    modal.result.then((task: IPerformanceReviewAssign) => {
      if (task?.status === TASK_ASSIGN_STATUS_ENUM.completed) {
        this.assigns = this.assigns.map(a => a.taskID === task.taskID ? {...a, status: TASK_ASSIGN_STATUS_ENUM.completed} : a);
        this.source.load(this.prService.sortTasks(this.assigns));
      }
    })
  }


}
