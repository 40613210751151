import { Component, Input, OnInit } from '@angular/core';
import { ITrainingPlan, MandatoryTrainingService } from 'src/app/services/mandatory-training.service';
import { RouterLink } from '@angular/router';
import { AvatarRequirementsComponent } from '@components/avatar/avatar-requirements/avatar-requirements.component';

@Component({
  selector: 'app-training-plan-name-cell',
  standalone: true,
  imports: [AvatarRequirementsComponent, RouterLink],
  templateUrl: './training-plan-name-cell.component.html',
  styleUrl: './training-plan-name-cell.component.scss'
})
export class TrainingPlanNameCellComponent implements OnInit {
  @Input() value;
  @Input() rowData;
  trainingPlan: ITrainingPlan;
  maxWidth = 299;
  // className = 'mtPlanCell';
  // isModal = false;
  // showTooltip = false;
  // isManualAssign = true;

  constructor(

  ){}


  ngOnInit(): void {
    this.trainingPlan = this.rowData.mtPlan;
  }

}
