import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { JobRoleTitlesCellComponent } from '@components/learning-table-popovers/jobrole-titles-cell.component';
import { TeamNamesCellComponent } from '@components/learning-table-popovers/team-names-cell.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { StaffStatusDisplayComponent } from '@components/staff-status-display/staff-status-display.component';
import { TruncatedCellComponent } from '@components/truncated-cell/truncated-cell.component';
import * as dayjs from 'dayjs';
import { combineLatest, Observable, Subject } from 'rxjs';
import { GroupService } from 'src/app/services/group.service';
import { PnpService } from 'src/app/services/pnp.service';
import { TableColumnValuePrepareAndSortUtilsService } from 'src/app/services/table-column-value-prepare-and-sort-utils.service';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { AccountStatusPipe } from 'src/app/shared/pipes/account-status.pipe';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { pluck, sortBy, uniq } from 'underscore';
import { IAckBackend } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { capitalize as _capitalize } from 'lodash';
import { downloadCSVBySourceAndSettings, IDownloadCSVExtensibleConfig } from 'src/app/shared/utils/export-csv';
import { ToastrService } from 'ngx-toastr';
import { PPSharedActionsButtonComponent } from '../../../table-cells/pp-shared-actions-button/pp-shared-actions-button.component';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { CommonModule, NgIf } from '@angular/common';
import { MultiTabTablesModule, MultiTabTablesComponent } from '../../../policies-procedures-shared/pnp-shared/multi-tab-tables/multi-tab-tables.component';
import { PolicyProcedureApiService } from 'src/app/pages/policies-procedures/services/policy-procedure-api.service';
import { map as mapRx, switchMap, takeUntil } from 'rxjs/operators';
import { TitleCellComponent } from '../../../table-cells/pp-title-cell.component';
import { MultiSelectTableComponent } from '@components/multi-select-container/multi-select-table/multi-select-table.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BulkStaffModalComponent, IBulkPoliciedData } from 'src/app/shared/popups/bulk-staff-modal/bulk-staff-modal.component';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { Ng2SmartTableModule } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.module';
import { EmptyStateComponent } from '../../../../../shared/components/empty-state/empty-state.component';
import { SmartTableComponent } from '../../../../../shared/components/smart-table/smart-table.component';
import { MultiSelectTableComponent as MultiSelectTableComponent_1 } from '../../../../../shared/components/multi-select-container/multi-select-table/multi-select-table.component';
import { SharedTableActionsComponent } from '../../../../../shared/components/shared-table-actions/shared-table-actions.component';
import { ScrollShadowDirective } from '../../../../../shared/directives/scroll-shadow.directive';
import { SmartTableService } from 'src/app/services/smart-table.service';

@Component({
  selector: 'app-pp-detail-ack',
  templateUrl: './pp-detail-ack.component.html',
  styleUrls: ['./pp-detail-ack.component.scss'],
  providers: [LocalDatePipe],
  standalone: true,
  imports: [
    NgIf,
    ScrollShadowDirective,
    MultiTabTablesComponent,
    SharedTableActionsComponent,
    MultiSelectTableComponent_1,
    SmartTableComponent,
    EmptyStateComponent,
  ],
})
export class PpDetailAckComponent implements OnInit {
  @Input() policyID?: string;
  @Input() orgUserID?: string;
  @Input() isShown;
  @Input() singleTable = false; // used in user transcript page policy records tab which just display all requirements
  @Output() private policyComplianceStatus = new EventEmitter<{ allNum: number, acknowledgedNum: number, unacknowledgedNum: number }>();

  users: any[];
  teams: any[];
  teamDic;
  tables: any;
  activeTabId = 0;
  isLoaded = false;

  ackedTableSource: LocalDataSource = new LocalDataSource();
  unackedTableSource: LocalDataSource = new LocalDataSource();
  otherTableSource: LocalDataSource = new LocalDataSource();
  allTableSource: LocalDataSource = new LocalDataSource();

  allTables = [this.allTableSource, this.unackedTableSource, this.ackedTableSource, this.otherTableSource];
  currentTable = this.allTables[0];
  selectedItems = [];
  refreshFlag = true;
  @Input() uniqTableID = 'policy-acknowledgement-table';

  destroyed$ = new Subject();
  @ViewChild(MultiSelectTableComponent) multiSelectTable: MultiSelectTableComponent;

  unackTableSettings = {
    tableUniqKey: LocalTableUniqKey.ppDetailAck,
    actions: false,
    hideSubHeader: false,
    noDataMessage: 'No records to display',
    selectMode: 'multi',
    pager: {
      display: true,
      perPage: 10,
    },
    mode: 'external',
    columns: {
      fn_title: {
        title: 'Item Title',
        // isSticky: true, // exempt from edit column
        // valuePrepareFunction: (title) => {
        //     return _capitalize(title);
        // },
        type: 'custom',
        isSticky: true,
        filter: true,
        sort: true,
        renderComponent: TitleCellComponent,
      },

      fn_status: {
        title: 'Acknowledgement Status',
        // type: 'custom',
        // renderComponent: PPAcknowledgementStatusCellComponent,
        valuePrepareFunction: (status) => {
          return _capitalize(status);
        },
      },
      fn_govID: {
        title: 'Item ID',
        // valuePrepareFunction: (id) => {
        //   return id?.slice(0, 8) || '-';
        // },
      },
      fn_govVersionCode: {
        title: 'Version No.',
      },
      fn_govStatus: {
        title: 'Item Status',
      },
      displayName: {
        title: 'User',
        filter: true,
        type: 'custom',
        renderComponent: StaffLinkDisplayComponent,
        onComponentInitFunction: (instance) => {
          instance.fragment = 'Policies';
        },
        compareFunction: this._columnUtil.compareByProperty('fullName'),
        filterFunction: this._columnUtil.searchByProperty('fullName'),
        // width: '20%',
      },
      staffID: {
        title: 'User ID',
        sort: false,
        sortDirection: 'aces',
        valuePrepareFunction: this._columnUtil.replaceNoRecord(),
        type: 'custom',
        renderComponent: TruncatedCellComponent,
        onComponentInitFunction(instance) {
          instance.className = 'staffID';
        },
        // width: '20%'
      },
      fn_assignDate: {
        title: 'Date Assigned',
        type: 'string',
        valuePrepareFunction: (date) => {
          return this.localDatePipe.transform(date);
        },
        filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((date) => date ? this.localDatePipe.transform(date).toString() : ''),
        compareFunction: this._columnUtil.compareByDate(),
      },
      fn_assignDays: {
        title: 'Days Assigned',
      },
      fn_ackDate: {
        title: 'Date Acknowledged',
        type: 'string',
        valuePrepareFunction: (date) => {
          return this.localDatePipe.transform(date);
        },
        filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((date) => date ? this.localDatePipe.transform(date).toString() : ''),
        compareFunction: this._columnUtil.compareByDate(),
      },
      fn_lastRemind: {
        title: 'Last Reminded',
        type: 'string',
        valuePrepareFunction: (date) => {
          return this.localDatePipe.transform(date);
        },
        filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((date) => date ? this.localDatePipe.transform(date).toString() : ''),
        compareFunction: this._columnUtil.compareByDate(),
      },
      status: {
        title: 'Account Status',
        type: 'custom',
        valuePrepareFunction: (val) => this.accountStatusPipe.transform(val),
        filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
        compareFunction: this._columnUtil.generalCompareWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
        renderComponent: StaffStatusDisplayComponent,
        width: '10%'
      },
      email: this.smartTableService.tableSettings.email,
      orgEmail: this.smartTableService.tableSettings.orgEmail,
      teamNames: {
        title: 'Teams',
        type: 'custom',
        sort: false,
        renderComponent: TeamNamesCellComponent,
        filterFunction: this._columnUtil.searchByProperty('name'),
        valuePrepareFunction: this._columnUtil.extractByProperty('name'),
        // width: '20%'
      },
      jobRoles: {
        title: 'Job Roles',
        type: 'custom',
        renderComponent: JobRoleTitlesCellComponent,
        valuePrepareFunction: this._columnUtil.extractByProperty('title'),
        filterFunction: this._columnUtil.searchByProperty('title'),
        sort: false,
        // width: '20%'
      },
      // fn_itemType: {
      //     title: 'Item Type',
      // valuePrepareFunction: (type) => {
      //     return _capitalize(type);
      // },
      // },

      actions: {
        title: 'Actions',
        type: 'custom',
        sort: false,
        exemptFromEdit: true,
        filter: false,
        renderComponent: PPSharedActionsButtonComponent,
        onComponentInitFunction: (instance) => {
          instance.separateViewButton = true;
          instance.btnWrapperClasses = 'justify-content-end';
          instance.triggerPage = 'acknowledgementRecordsTableAction';
        },

      }
    },
  };

  ackTableSettings = {
    ...this.unackTableSettings,
    tableUniqKey: LocalTableUniqKey.ppDetailUnack,
    selectMode: '',
  };
  otherTableSettings = {
    ...this.ackTableSettings,
    tableUniqKey: LocalTableUniqKey.ppDetailOther
  };
  allAcksTableSettings = {
    ...this.ackTableSettings,
    tableUniqKey: LocalTableUniqKey.ppDetailAll
  };
  allTableSettings = [this.allAcksTableSettings, this.unackTableSettings,
  this.ackTableSettings, this.otherTableSettings];

  currentSettings = this.allTableSettings[0];


  constructor(
    private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
    private accountStatusPipe: AccountStatusPipe,
    private userService: UserService,
    private groupService: GroupService,
    private _cdr: ChangeDetectorRef,
    private pnpService: PnpService,
    private toaster: ToastrService,
    private localDatePipe: LocalDatePipe,
    private pnpApiService: PolicyProcedureApiService,
    private smartTableService: SmartTableService,
    private modalService: NgbModal

  ) { }

  ngOnInit(): void {
    if (this.hideMultiSelect) {
      this.unackTableSettings.selectMode = '';
    }

    const teams$ = this.groupService.fetchedCacheOrgTeams(),
      users$ = this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'));
    this.pnpService.ackUpdate$.pipe(takeUntil(this.destroyed$),
      switchMap(() => {
        return combineLatest([teams$, users$, this.acks$,
          // delay for testing
          //  timer(1000)
        ]);
      }),
      takeUntil(this.destroyed$))
      .subscribe(results => {
        this._processData(results);
      },
        error => this._processData([]));


  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.policyID || changes.orgUserID) {
      this.destroyed$.next(); // stop subscriptions
      this.ngOnInit();
    }
  }

  private _processData(results: any[]) {
    // const userDict = indexBy(flatten(Object.values(results[1])), 'orgUserID');
    const userDict = this.userService.managedOrgUserDictionaryByOrgUserID;
    this.teamDic = this.groupService.globalTeamsDict;
    // const _a = results[2];
    const extendedAcks: IAckTableData[] = results[2]
      ?.filter(r => r.gov)
      ?.filter((a: { gov: any; ack: IAckBackend }) => userDict[a.ack.assigneeOrgUserID])
      ?.map((a: { gov: any; ack: IAckBackend }) => {
        const u = userDict[a.ack.assigneeOrgUserID] as IOrgUser;
        return {
          ...u,
          displayName: { ...u, isNewTab: true },
          email: u.email,
          orgEmail: u.orgEmail,
          jobRoles: pluck(u.activeJobRoleTakens, 'jobRoleModel'),
          teamNames: u.orgUserDetail?.teamModels?.filter(t => !!t) || [],
          fn_status: a.ack.currentPeriod.status === 'Re-assigned' ? 'Unacknowledged' : a.ack.currentPeriod.status,
          fn_title: a.gov.title,
          fn_govID: a.gov.code,
          // _a.fn_itemType = _a.gov.governanceType;
          fn_govVersionCode: a.ack.currentPeriod.govVersionCode,
          fn_govVersionID: a.ack.currentPeriod.govVersionID,
          fn_assignDate: (a.ack.assignLocalDate),
          fn_assignDays: dayjs().diff(a.ack.assignLocalDate, 'day'),
          fn_ackDate: (a.ack.currentPeriod?.acknowledge?.localDate),
          fn_lastRemind: (a.ack.currentPeriod?.reminder?.lastReminder.sentLocalDate),
          ackID: a.ack.ackID,
          governanceID: a.gov.governanceID,
          fn_govStatus: a.gov.governanceVersion.status

        };
      }) || [];

    // const team = results[0].find(t => {
    //     return t.teamID === this.teamID;
    // });

    {
      // managers
      // User Status: In-active; Pending; Active; Rejected; Invited
      this._configTableData(extendedAcks);
    }
  }

  get acks$(): Observable<{ gov: any; ack: IAckBackend }[]> {
    return this.policyID
      ? this.pnpService.fetchAckByPolicy(this.policyID).pipe(mapRx(p => {
        return p.acks?.map(ack => {
          return {
            gov: p.gov,
            ack: { ...ack }
          };
        }) || [];
      }))
      : this.pnpApiService.getAllAcknowledgementsByUser(localStorage.getItem('orgID'), this.orgUserID);
  }

  private _configTableData(unsortedExtendedAcks: IAckTableData[]) {
    // DEV
    // extendedAcks = this.addMockData(extendedAcks);


    const { extendedAcks, acked, unacked, other } = this.getSortedAndSeparatedAcks(unsortedExtendedAcks);
    if (this.singleTable) {
      this.tables = [
        { name: 'All Policy Requirements', numRow: extendedAcks.length }
      ];
    } else {
      this.tables = [
        { name: 'All Policy Requirements', numRow: extendedAcks.length },
        { name: 'Unacknowledged', numRow: unacked.length },
        { name: 'Acknowledged', numRow: acked.length },
        { name: 'Other', numRow: other.length },
      ];
    }

    this.ackedTableSource.load(acked);
    this.unackedTableSource.load(unacked);
    this.otherTableSource.load(other);
    this.policyComplianceStatus.emit(
      {
        allNum: extendedAcks.length,
        acknowledgedNum: acked.length,
        unacknowledgedNum: unacked.length,
      }
    );
    this.allTableSource.load(extendedAcks).then(_ => {
      setTimeout(() => {
        this.isLoaded = true;
        this._cdr.markForCheck();
      }, 200);
    });
    this._cdr.markForCheck();


  }

  getSortedAndSeparatedAcks(extendedAcks: any[]) {
    extendedAcks = sortBy(extendedAcks, ack => {
      const sortAttribute = this.sortingType === 'policyDetail' ? 'fullName' : 'fn_title';
      return ack[sortAttribute]?.toLowerCase();
    });
    const acked = sortBy(extendedAcks, 'fn_ackDate').reverse().filter(a => a.fn_status === 'Acknowledged');
    const unacked = sortBy(extendedAcks, 'fn_assignDate').filter(a => a.fn_status === 'Unacknowledged' || a.fn_status === 'Re-assigned');
    const other = (extendedAcks.filter(a => !['Acknowledged', 'Unacknowledged', 'Re-assigned'].includes(a.fn_status)));
    return { extendedAcks, unacked, acked, other };
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  private addMockData(extendedAcks: any[]) {
    const unack = extendedAcks.map(ack => {
      return {
        ...ack,
        fn_status: 'Acknowledged'
      };
    });
    const other = extendedAcks.map(ack => {
      return {
        ...ack,
        fn_status: 'Discarded'
      };
    });
    return extendedAcks.concat(unack).concat(other);
  }

  sendAckReminder() {
    const selectedItems: IAckTableData[] = this.multiSelectTable?.selectedItems;
    const singleUser = selectedItems[0];
    const numPolicies = uniq(pluck(selectedItems, 'governanceID')).length;
    const policies: IBulkPoliciedData = numPolicies === 1 ? { name: selectedItems[0].fn_title } : { num: numPolicies };
    policies.ackIDs = pluck(selectedItems, 'ackID');
    let activeModal: NgbModalRef;
    activeModal = this.modalService.open(BulkStaffModalComponent, { size: 'lg' });
    activeModal.componentInstance.action = 'send ack reminders';
    activeModal.componentInstance.selectedUsers = uniq(pluck(selectedItems, 'orgUserID')).map(id => this.userService.managedOrgUserDictionaryByOrgUserID[id]);
    activeModal.componentInstance.policies = policies;
  }


  onNavChange(tab) {
    this.activeTabId = tab;
    this.currentTable = this.allTables[tab];
    this.currentSettings = this.allTableSettings[tab];
    this.selectedItems = [];

    this._cdr.detectChanges();
  }

  resetSettings(settings) {
    this.currentSettings = settings;
    this._cdr.detectChanges();
  }

  downloadCSV(settings) {
    const date = dayjs().format('YYYY-MM-DD');
    const title = date + ' Acknowledgement records';
    // switch (this.activeTabId) {
    //   case 0:
    //     title = `List of Unacknowledged users`;
    //     break;
    //   case 1:
    //     title = `List of Acknowledged users`;
    //     break;
    //   default:
    //     title = `List of Unacknowledged users`;
    //     break;
    // }
    const csvConfig: IDownloadCSVExtensibleConfig = {
      source: this.currentTable,
      settings: settings,
      csvTitle: title,
      csvFileName: `${title}.csv`,
      extractObjectFieldsByKey: [{ field: 'displayName', fieldKey: 'fullName' }]
    };
    // include invitation link into csv
    downloadCSVBySourceAndSettings(csvConfig);
    this.toaster.success('Downloaded file');
  }

  // get isEmpty() {
  //   return this.currentTable.
  // }

  get tableItemName() {
    return window.location.pathname.includes('transcript') ? 'records' : 'users';
  }

  get hideMultiSelect() {
    if (this.orgUserID) {
      return this.userService.managedOrgUserDictionaryByOrgUserID[this.orgUserID]
        .status !== 'Active';
    }
    return false;
  }

  get sortingType(): 'transcript' | 'policyDetail' {
    return this.policyID ? 'policyDetail' : 'transcript';
  }
}

export interface IAckTableData extends IOrgUser {
  fn_status: string;
  fn_title: string;
  fn_govVersionCode;
  fn_assignDate;
  fn_assignDays;
  fn_ackDate;
  ackID;
  displayName: any;

}

export function backendAckToTableData(ack: IAckBackend) {
  return {
    fn_status: ack.currentPeriod.status === 'Re-assigned' ? 'Unacknowledged' : ack.currentPeriod.status,
    fn_govVersionCode: ack.currentPeriod.govVersionCode,
    fn_govVersionID: ack.currentPeriod.govVersionID,
    fn_assignDate: (ack.assignLocalDate),
    fn_assignDays: dayjs().diff(ack.assignLocalDate, 'day'),
    fn_ackDate: (ack.currentPeriod?.acknowledge?.localDate),
    fn_lastRemind: (ack.currentPeriod?.reminder?.lastReminder.sentLocalDate),
    ackID: ack.ackID,
  };

}

@NgModule({
  imports: [CommonModule, MultiTabTablesModule, Ng2SmartTableModule, PpDetailAckComponent],
  exports: [PpDetailAckComponent],
})
export class PPDetailAckModule {
}



