import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function customEmailCheck(email: string) {
    if (!email) {
      return true;
    }

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const regexValid = !!email.match(re)?.length;

    const lastAtPos = email.lastIndexOf('@');
    const lastDotPos = email.lastIndexOf('.');

    // end with letters only
    const lastPart = email.slice(lastDotPos + 1);
    const validLastPart = /^[a-zA-Z]+$/.test(lastPart);

    return (lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2) && validLastPart;
  }

  // check if ISO localdate
  export function isValidDate(dateString, isRequired = true) {
    if (!dateString) {
      return !isRequired;
    }
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) {
      return false;  // Invalid format
    }
    let d = new Date(dateString);
    let dNum = d.getTime();
    if (!dNum && dNum !== 0) {
      return false; // NaN value, Invalid date
    }
    return d.toISOString().slice(0, 10) === dateString;
  }

// Form control validators
// checkFn should return true if there's error
export function createValidator(checkFn: (val:string) => boolean, errorName): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const disAllowed = checkFn(control.value);
    let errors = null;
    if (disAllowed) {
      errors = {};
      errors[errorName] = true;
    }
    return errors;
  };
}

