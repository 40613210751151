import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'ui-spinner',
    template: `
    <img class="ui-spinner ui-spinner-{{size}} ui-spin" [src]="color === 'dark' ? '/assets/images/ui/spinner-dark.svg' : '/assets/images/ui/spinner-light.svg'" />
  `,
    styleUrls: ['./ui-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class UISpinnerComponent {
  @Input() color: 'dark' | 'light' = 'dark';
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
}


