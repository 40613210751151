<ui-modal-single [heading]="'Unskip a Task'">
    <ng-template #contentSlot>
        <p>You are about to unskip <strong>{{task?.taskTemplate?.title}}</strong> for <strong>{{task?.user?.fullName}}</strong>.</p>
    </ng-template>

    <ng-template #rightSlot>
        <button [disabled]="isSubmitting()" type="button" class="btn btn-outline-light" (click)="closeModal()">Cancel</button>
        <button [disabled]="isSubmitting()" type="button" class="btn btn-secondary" (click)="markAsUnskipped()">
            <span *ngIf="isSubmitting()" style="color: inherit">
                    <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            Unskip Task
        </button>
    </ng-template>
</ui-modal-single>
