export const StreamlinePaths = {
  'interface-page-controller-loading': 'interface-essential/page-controller/interface-page-controller-loading',
  'interface-alert-information-circle': 'interface-essential/alerts/interface-alert-information-circle',
  'interface-delete-1': 'interface-essential/add-remove-delete/interface-delete-1',
  'interface-setting-menu-vertical': 'interface-essential/setting/interface-setting-menu-vertical',
  'interface-validation-check-circle': 'interface-essential/validation/interface-validation-check-circle',
  'loading-circle': 'interface-essential/loading-circle',
  'loading-circle-2': 'interface-essential/loading-circle-2',
  'loading-3': 'interface-essential/loading-3',
  'interface-delete': 'interface-essential/validation/interface-delete',
  'common-file-text': 'files-folders/common-files/common-file-text',
  'interface-delete-bin-1': 'interface-essential/add-remove-delete/interface-delete-bin-1',
  'interface-arrows-button-left': 'interface-essential/arrows/interface-arrows-button-left',
  'interface-arrows-button-right': 'interface-essential/arrows/interface-arrows-button-right',
  'interface-setting-menu-horizontal': 'interface-essential/setting/interface-setting-menu-horizontal',
  'interface-arrows-button-down': 'interface-essential/arrows/interface-arrows-button-down',
  'interface-arrows-button-up': 'interface-essential/arrows/interface-arrows-button-up',
  'interface-text-formatting-filter-2': 'interface-essential/text-formatting/interface-text-formatting-filter-2',
  'data-transfer-vertical': 'internet-networks-servers/data-transfer/data-transfer-vertical',
  'arrow-button-down-2': 'arrows-diagrams/arrows/arrow-button-down-2',
  'folder': 'files-folders/folders/folder',
  'arrow-button-up-2': 'arrows-diagrams/arrows/arrow-button-up-2',
  'interface-download-button-2': 'interface-essential/upload-download/interface-download-button-2',
  'mail-smiley-happy-face': 'mail/smiley/mail-smiley-happy-face',
  'mail-smiley-sad-face': 'mail/smiley/mail-smiley-sad-face',
  'interface-alert-warning-triangle': 'interface-essential/alerts/interface-alert-warning-triangle',
  'credit-card-amex': 'money-payments-finance/credit-card-payments/credit-card-amex',
  'money-atm-card-1': 'money-shopping/atm/money-atm-card-1',
  'credit-card-visa': 'money-payments-finance/credit-card-payments/credit-card-visa',
  'credit-card-mastercard': 'money-payments-finance/credit-card-payments/credit-card-mastercard',
  'interface-user-single': 'interface-essential/user/interface-user-single',
  'interface-calendar-date': 'interface-essential/calendar/interface-calendar-date',
  'interface-validation-check': 'interface-essential/validation/interface-validation-check',
  'interface-alert-warning-circle': 'interface-essential/alerts/interface-alert-warning-circle',
  'add': 'interface-essential/remove-add/add',
  'house-2-alternate': 'interface-essential/home/house-2-alternate',
  'task-checklist': 'interface-essential/form-edition/task-checklist',
  'notes-checklist-flip': 'interface-essential/notes-checklist-flip',
  'list-bullets': 'interface-essential/lists/list-bullets',
  'list-to-do': 'interface-essential/lists/list-to-do',
  'list-numbers': 'list-numbers/list-numbers',
  'book-upload': 'content/books/book-upload',
  'book-library': 'content/books/book-library',
  'maps': 'content/maps/maps',
  'data-file-bars-alternate': 'business-products/data-files/data-file-bars-alternate',
  'interface-setting-cog': 'interface-essential/setting/interface-setting-cog',
  'zip-file': 'files-folders/zipped-files/zip-file',
  'performance-increase-2': 'business-products/performances/performance-increase-2',
  'book-close-2': 'content/books/book-close-2',
  'medical-personnel': 'health-beauty/medical-personnel/medical-personnel',
  'newspaper-fold': 'content/newspapers/newspaper-fold',
  'video-player': 'video-movies-tv/video-players/video-player',
  'interface-link': 'interface-essential/link-unlink/interface-link',
  'social-music-podcast': 'logos/music/social-music-podcast',
  'layout-right': 'interface-essential/layouts/layout-right',
  'interface-arrows-diagonal-1': 'interface-essential/arrows/interface-arrows-diagonal-1',
  'layout-module-2': 'interface-essential/layouts/layout-module-2',
  'upload-button': 'internet-networks-servers/upload-download/upload-button',
  'common-file-text-add': 'files-folders/common-files/common-file-text-add',
  'business-contract-give': 'business-products/business-deals/business-contract-give',
  'interface-upload-button-2': 'interface-essential/upload-download/interface-upload-button-2',
  'interface-arrows-synchronize': 'interface-essential/arrows/interface-arrows-synchronize',
  'products-briefcase': 'shopping-ecommerce/products/products-briefcase',
  'interface-arrows-expand-5': 'interface-essential/arrows/interface-arrows-expand-5',
  'mail-send-envelope': 'mail/send/mail-send-envelope',
  'interface-bookmark': 'interface-essential/bookmark-favorite/interface-bookmark',
  'bookmark': 'interface-essential/bookmark/bookmark',
  'multiple-users-2': 'users/geometric-full-body-multiple-users/multiple-users-2',
  'interface-user-multiple': 'interface-essential/user/interface-user-multiple',
  'interface-time-clock-circle': 'interface-essential/time/interface-time-clock-circle',
  'layout-headline': 'interface-essential/layouts/layout-headline',
  'rating-star': 'social-medias-rewards-rating/rating/rating-star',
  'rating-star-alternate': 'social-medias-rewards-rating/rating/rating-star-alternate',
  'interface-arrows-vertical-expand-1': 'interface-essential/arrows/interface-arrows-vertical-expand-1',
  'multiple-users-add': 'users/natural-close-up-multiple-users-actions/multiple-users-add',
  'medical-personnel-doctor': 'health-beauty/medical-personnel/medical-personnel-doctor',
  'common-file-add': 'files-folders/common-files/common-file-add',
  'common-file-give-hand': 'files-folders/common-files/common-file-give-hand',
  'read-glasses': 'school-learning/library-reading/read-glasses',
  'study-book': 'school-learning/study-book',
  'book-open-text': 'content/books/book-open-text',
  'cog-1': 'interface-essential/settings/cog-1',
  'book-open-bookmark': 'content/books/book-open-bookmark',
  'common-file-text-alternate': 'files-folders/common-files/common-file-text-alternate',
  'folder-upload-alternate': 'files-folders/folders/folder-upload-alternate',
  'video-player-alternate': 'video-movies-tv/video-players/video-player-alternate',
  'hyperlink-3': 'interface-essential/link-unlink/hyperlink-3',
  'interface-search': 'interface-essential/search/interface-search',
  'light-bulb-shine': 'lamps-lights-fire/light-bubbles/light-bulb-shine',
  'messages-bubble-square-question': 'messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-question',
  'conversation-chat-1': 'messages-chat-smileys/conversation/conversation-chat-1',
  'gift-box': 'social-medias-rewards-rating/gifts/gift-box',
  'content-pen-write-1': 'content/content-creation/content-pen-write-1',
  'lock-1': 'interface-essential/lock-unlock/lock-1',
  'lock-unlock': 'interface-essential/lock-unlock/lock-unlock',
  'task-list-pen': 'work-office-companies/task-list-pen',
  'task-list-to-do': 'work-office-companies/task-list-to-do',
  'navigation-right': 'interface-essential/navigate/navigation-right',
  'regular-upload-button': 'upload-button/upload-button',
  'app-window-check': 'programming-apps-websites/apps-window/app-window-check',
  'app-window-search-1': 'programming-apps-websites/apps-window/app-window-search-1',
  'cloud-upload': 'internet-networks-servers/cloud/cloud-upload',
  'handshake': 'business-products/business-deals/handshake',
  'calendar3': 'interface-essential/calendar/calendar3',
  'time-clock-circle': 'time/clock/time-clock-circle',
  'circle': 'shape/circle/circle',
  'circle-outline': 'shape/circle/circle-outline',
  'question-circle': 'interface-essential/alerts/question-circle',
  'target-center': 'target/target-center',
  'ticket-add': 'interface-essential/ticket-add/ticket-add',
  'ticket-check': 'interface-essential/ticket-check/ticket-check',
  'calendar-search-1': 'interface-essential/calendar-search-1',
  'move-left-1': 'interface-essential/move-left-1',
  'move-right-1': 'interface-essential/move-right-1',
  'remove-shield': 'interface-essential/remove-shield',
  'check-shield': 'interface-essential/check-shield',
  'pencil': 'interface-essential/pencil/pencil-1',
  'alert-circle': 'interface-essential/alerts/alert-circle',
  'alarm-bell': 'interface-essential/bell/alarm-bell',
  'button-play-1': 'interface-essential/button-play/button-play-1',
  'notes-paper-approve': 'interface-essential/notes-paper-approve/notes-paper-approve',
  'messages-bubble-square-alternative': 'messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-alternate',



  // 'alert-circle': 'interface-essential/alerts/alert-circle',
  // 'check-1': 'interface-essential/form-validation/check-1',
  // 'check-double': 'interface-essential/form-validation/check-double',
  // 'check-circle': 'interface-essential/form-validation/check-circle',
  // 'bin-1': 'interface-essential/delete/bin-1',
  // 'close': 'interface-essential/form-validation/close',
  // 'check-circle-1': 'interface-essential/form-validation/check-circle-1',
  // 'check-circle-1-alternate': 'interface-essential/form-validation/check-circle-1-alternate',
  // 'share': 'interface-essential/share/share',
  // 'remove-circle-1': 'interface-essential/remove-add/remove-circle-1',
  // 'search-1': 'interface-essential/search/search-1',
  // 'information-circle': 'interface-essential/alerts/information-circle',
  // 'share-2': 'interface-essential/share/share-2',
  // 'hyperlink-3': 'interface-essential/link-unlink/hyperlink-3',
  // 'megaphone-1': 'interface-essential/share/megaphone-1',
  // 'settings-off': 'interface-essential/controls/settings-off',
  // 'cog': 'interface-essential/settings/cog',
  // 'lock-1': 'interface-essential/lock-unlock/lock-1',
  // 'multiple-actions-setting': 'users/geomertic-close-up-multiple-users-actions/multiple-actions-setting',
  //
  // 'single-neutral': 'users/geomertic-close-up-single-user-neutral/single-neutral',
  //
  // 'controls-play': 'video-movies-tv/controls/controls-play',
  //
  // 'navigation-menu': 'interface-essential/menu/navigation-menu',
  //
  // 'newspaper-read-man': 'content/newspapers/newspaper-read-man',
  //
  // 'single-neutral-actions-block': 'users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-block',
  //
  // 'cloud-download': 'internet-networks-servers/cloud/cloud-download',
  // 'download-button': 'internet-networks-servers/upload-download/download-button',
  // 'cloud-upload': 'internet-networks-servers/cloud/cloud-upload',
  // 'download-bottom': 'internet-networks-servers/upload-download/download-bottom',
  //
  // 'folder-empty': 'files-folders/folders/folder-empty',
  // 'tv-flat-screen': 'hotels-spas/tv/tv-flat-screen',
  // 'layout-module-1': 'interface-essential/layouts/layout-module-1',
  //
  // 'school-teacher': 'school-learning/school/school-teacher',
  //
  //
  //
  // 'multiple-neutral-2': 'users/geomertic-close-up-multiple-users/multiple-neutral-2',
  //
  // // 'rating-star-1': 'social-medias-rewards-rating/rating/rating-star-1',
  // 'keyboard-arrow-top-right': 'interface-essential/keyboard/keyboard-arrow-top-right',
  //
  // 'navigation-menu-horizontal': 'interface-essential/menu/navigation-menu-horizontal',
  // 'calendar-3': 'interface-essential/date-calendar/calendar-3',
  //
  // 'arrow-down-1': 'arrows-diagrams/arrows/arrow-down-1',
  // 'arrow-right-1': 'arrows-diagrams/arrows/arrow-right-1',
  // 'arrow-left': 'arrows-diagrams/arrows/arrow-left',
  // 'arrow-left-1': 'arrows-diagrams/arrows/arrow-left-1',
  // 'arrow-right': 'arrows-diagrams/arrows/arrow-right',
  //
  //
  // 'common-file-double': 'files-folders/common-files/common-file-double',
  //
  // 'email-action-unread': 'emails/email-actions/email-action-unread',
  //
  //
  // 'messages-bubble-square-add': 'messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-add',
  //
  // 'button-refresh-arrow': 'interface-essential/multimedia-controls/button-refresh-arrow',
  // 'attachment': 'interface-essential/link-unlink/attachment',
  //
  // 'floppy-disk': 'computers-devices-electronics/floppy-disks/floppy-disk',
  // 'view-1': 'interface-essential/view/view-1',
  //
  // 'check-badge-alternate': 'interface-essential/form-validation/check-badge-alternate',
  //
  // 'flag-plain-3': 'social-medias-rewards-rating/flags/flag-plain-3',
  //
  // 'credit-card-1': 'money-payments-finance/credit-card-payments/credit-card-1',
  //
  // 'professional-network-linkedin': 'logos/professional-networks/professional-network-linkedin',
  // 'social-instagram': 'logos/photos-images/social-instagram',
  //
  // 'sign-badge-circle': 'maps-navigation/sign-shapes/sign-badge-circle',
  // 'social-media-facebook': 'logos/social-medias/social-media-facebook',
  // 'social-media-twitter': 'logos/social-medias/social-media-twitter',
  // 'social-media-facebook-1': 'logos/social-medias/social-media-facebook-1',
  // 'view-off': 'interface-essential/view/view-off',
  'business-climb-top': 'interface-essential/business-climb-top',
  'filter-1': 'interface-essential/filter/filter-1',
  'paragraph-justified-align': 'interface-essential/paragraph-justified-align',
  'button-record-alternate': 'interface-essential/button-record-alternate',
  'calendar': 'interface-essential/calendar',
  'check-2-alternate': 'interface-essential/check-2-alternate',
  'dash-circle': 'interface-essential/dash-circle',
  'check-1': 'interface-essential/check-1',
  'tag': 'interface-essential/tag',
  'type-cursor-1': 'interface-essential/type-cursor-1',
  'time-clock-six': 'interface-essential/time-clock-six',
  'time-clock-midnight': 'interface-essential/time-clock-midnight',
  'shortAnswerIcon': 'interface-essential/shortAnswerIcon',
  'copy-paste': 'interface-essential/copy-paste/copy-paste',
  'calendar-add-1': 'interface-essential/calendar-add-1',
  'calendar-check-1': 'interface-essential/calendar-check-1',
  'pie-line-graph': 'interface-essential/pie-line-graph',
  'graph-stats-circle-alternate': 'interface-essential/graph-stats-circle-alternate',
  'navigation-menu': 'interface-essential/navigation-menu',
  'double-1-alternate': 'files-folders/common-files/common-file-double-1-alternate',
  'layout-three-columns': 'interface-essential/layouts/layout-three-columns',
  'pin-location-1': 'maps-navigation/pin-location-1',
  'building-1': 'building-construction/building-1',
  'file-copyright-dollar': 'files-folders/file-copyright-dollar',
  'currency-dollar-circle': 'currency-dollar-circle/currency-dollar-circle',
  'ausmed-passport': 'others/passport',
  'text': 'others/text',
  'ausmed-icon': 'ausmed/ausmed-icon',
  'ausmed-structure': 'others/structure',
  'graphic-tablet-draw': 'content/graphic-tablet-draw/graphic-tablet-draw',
  'building-house': 'real-estate/Real-Estate-Building-House',
  'task-list-checklist': 'work-office-companies/task-list-checklist',
  'human-resource-employee': 'work-office-companies/human-resource-employee',
  'plane-trip-round': 'others/plane-trip-round',
  'cog-double': 'others/cog-double',
  'team-meeting': 'interface-essential/team-meeting',
  'requirement': 'others/requirement/requirement',
  'mask-square-alternate': 'images-photography/mask-square-alternate',
  'single-neutral-action-check': 'interface-essential/single-neutral-action-check',
  'curve-notebook': 'interface-essential/curve-notebook',
  'design-tool-stamp': 'work-office-companies/design-tool-stamp',
  'stairs-ascend': 'interface-essential/stairs-ascend',
  'checklist-alternate': 'interface-essential/checklist-alternate',
};


