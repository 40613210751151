<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<ng-select #selector class="f-small my-class" *ngIf="controls" [formControl]="controls[controlName]"
    id="id || select{{controlName}}" [items]="options" [ngClass]="{'auto-grow': autoGrow, 'panel-width': fixedWidth}"
    bindLabel="label" bindValue="value" [multiple]="true" [searchable]="true" [clearable]="false"
    [closeOnSelect]="false" [loading]="isLoading" [placeholder]="placeholder" (open)="onOpen()"
    (change)="onChange($event)" [virtualScroll]="!autoGrow" [appendTo]="appendTo" (close)="onClose()"
    (search)="getFilteredItems($event)">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="d-flex justify-content-between py-2 px-3"
            [ngClass]="{'border-bottom': index === 0 && specialFirstOption}">
            <div class="custom-control custom-checkbox" [ngClass]="{'ms-6' : item.child}">
                <input id="item-{{index}}" class="custom-control-input" type="checkbox" [ngModel]="item$.selected" />
                <label class="custom-control-label f-small text-neutral-900">
                    <!-- <div appTruncateTextTooltip tooltipClass="custom-tooltip" container="body" [(fullText)]="item.label" [ngbTooltip]="item.label">{{item.label}}</div> -->
                    <div class="d-flex align-items-baseline">
                        <div [style.max-width]="'300px'"><app-truncated-text
                                [value]="item.label"><span>{{item.label}}</span></app-truncated-text></div>
                        <div *ngIf="item.rightCaption" class="f-caption text-neutral-700 ms-2">{{item.rightCaption}}
                        </div>
                    </div>
                    <div *ngIf="item.caption">
                        <p class="f-caption text-neutral-700">
                            {{item.caption}}</p>
                    </div>

                </label>
            </div>
            <span *ngIf="item.rightLabel"><staff-status-view
                    [value]="item.rightLabel | accountStatusPipe"></staff-status-view></span>
        </div>
    </ng-template>

    <ng-template ng-header-tmp>
        <div class="d-flex justify-content-between p-1">
            <button class="btn btn-link me-2" (click)="onSelectAll()">{{ filteredItems ? 'Select All Filtered Results' :
                'Select All' }}
            </button>
            <button class="btn btn-link" (click)="onClearAll()">Clear All
            </button>
        </div>

    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngIf="items?.length === 1">
            <span class="ng-value-label">{{items[0].label}}</span>
            <span class="ng-value-icon right" (click)="clear(items[0])">×</span>
        </div>
        <div class="ng-value" *ngIf="items?.length > 1 && items?.length < options?.length">
            <!-- Special first item -->
            <ng-container *ngIf="specialFirstOption">
                <span *ngIf="isFirstOptionSelected">{{options[0].label}} /
                </span>
                <!-- All selected except first item -->
                <span *ngIf="!isFirstOptionSelected && items?.length === options?.length - 1">All</span>
            </ng-container>

            <span class="ng-value-label">{{items?.length - (isFirstOptionSelected ? 1 : 0)}} {{ pluralForm }}</span>
        </div>
        <div class="ng-value" *ngIf="items?.length === options?.length">
            <span class="ng-value-label">
                <ng-container *ngIf="!allSelectedText">
                    <!-- Special first item  -->
                    <span *ngIf="specialFirstOption">{{options[0].label}} / </span>

                    <span *ngIf="includesTextAll">All </span>{{ showNumOnSelectAll ? (items?.length -
                    (specialFirstOption ? 1 : 0)) : '' }}
                    {{ pluralForm }}
                </ng-container>
                <span *ngIf="allSelectedText">{{allSelectedText}}</span>
            </span>
        </div>
    </ng-template>
</ng-select>
<div class="text-error-input"
    *ngIf="submitted && !isLoading && controls[controlName].invalid && controls[controlName].errors?.required">
    <small *ngIf="errorMsg; else defaultErrorMsg">{{ errorMsg }}</small>
    <ng-template #defaultErrorMsg>
        <small>Please select a{{'aeio'.split('').includes(displayName.toLocaleLowerCase().charAt(0)) ? 'n' : ''}}
            {{displayName}}</small>
    </ng-template>
</div>
<div class="d-none">
    <button (click)="selectSome(1)" class="btn btn-blue-100">Select 1/4</button>
    <button (click)="selectSome(2)" class="btn btn-blue-100">Select 2/4</button>
    <button (click)="selectSome(3)" class="btn btn-blue-100">Select 3/4</button>
    <button (click)="selectSome(4)" class="btn btn-blue-100">Select 4/4</button>
</div>