<!-- HAS DISPLAY PICTURE -->
<div *ngIf="url" class="avatar avatar-{{size}}" style="background-image: url({{url}});">
</div>
<!-- NO DISPLAY PICTURE -->
<div *ngIf="!url && !slIcon"
    class="avatar avatar-{{size}} d-flex justify-content-center align-items-center bg-{{bgColor}}"
    [ngClass]="{'avatar-square': isSquare}" [class.m-auto]="alignCenter">
    <span class="fw-semibold" [ngClass]="textColor">{{initials}}</span>
</div>
<!-- ICON -->

<!-- iconBackgroundColor -->

<div *ngIf="!url && slIcon && !iconBackgroundColor"
    class="avatar rounded rounded-3 avatar-{{size}} d-flex justify-content-center align-items-center bg-{{bgColor}}"
    [class.m-auto]="alignCenter">
    <span [ngClass]="textColor" style="color: inherit"><ui-streamline-icon
            [slIcon]="slIcon"></ui-streamline-icon></span>
</div>

<div *ngIf="!url && slIcon && iconBackgroundColor"
    class="avatar rounded rounded-3 avatar-{{size}} d-flex justify-content-center align-items-center bg-{{iconBackgroundColor}}"
    [class.m-auto]="alignCenter">
    <span [ngClass]="textColor" style="color: inherit"><ui-streamline-icon
            [slIcon]="slIcon"></ui-streamline-icon></span>
</div>
