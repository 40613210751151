import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomisedSingleSelectComponent, ISingleSelectOption } from '@components/customised-single-select/customised-single-select.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-row-selector',
  standalone: true,
  imports: [CommonModule, CustomisedSingleSelectComponent],
  templateUrl: './table-row-selector.component.html',
  styleUrls: ['./table-row-selector.component.scss']
})
export class TableRowSelectorComponent {
  @Input() rowNum;
  @Output() numChange = new EventEmitter();

  options: ISingleSelectOption<number>[] = 
    [5, 10, 20, 50, 100].map(n => ({value: n, label: 'Showing ' + n + ' Rows'}))
  ;
  form = new FormGroup({
    rowNum: new FormControl()
  })

  ngOnInit(): void {
    this.form.controls.rowNum.valueChanges.subscribe(val => this.numChange.emit(val))
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.rowNum) {
      this.form.controls.rowNum.setValue(this.rowNum)
    }
  }

}
