import { DATE_OPTION_ENUM } from "src/app/pages/reporting/filter-pack/interfaces";

/**
 * 
 * @param includeRetired if you need back compat eg for old saved shortcut
 * @returns 
 */
export function getAllDateOptions(includeRetired = false): DATE_OPTION_ENUM[] {
    const allOptions = [
        DATE_OPTION_ENUM.custom,
        DATE_OPTION_ENUM.lastWeek,
        DATE_OPTION_ENUM.lastMonth,
        DATE_OPTION_ENUM.lastQuarter,
        DATE_OPTION_ENUM.lastYear,
        DATE_OPTION_ENUM.last365Days,
        DATE_OPTION_ENUM.last180Days,
        DATE_OPTION_ENUM.last90Days,
        DATE_OPTION_ENUM.last30Days,
        DATE_OPTION_ENUM.last14Days,
        DATE_OPTION_ENUM.last7Days,

        DATE_OPTION_ENUM.thisWeekSoFar,
        DATE_OPTION_ENUM.thisMonthSoFar,
        DATE_OPTION_ENUM.thisQuarterSoFar,
        DATE_OPTION_ENUM.thisYearSoFar,
        DATE_OPTION_ENUM.thisWeek,
        DATE_OPTION_ENUM.thisMonth,
        DATE_OPTION_ENUM.thisQuarter,
        DATE_OPTION_ENUM.thisYear,
        DATE_OPTION_ENUM.nextWeek,
        DATE_OPTION_ENUM.nextMonth,
        DATE_OPTION_ENUM.nextQuarter,
        DATE_OPTION_ENUM.nextYear,
        DATE_OPTION_ENUM.next365Days,
        DATE_OPTION_ENUM.next180Days,
        DATE_OPTION_ENUM.next90Days,
        DATE_OPTION_ENUM.next30Days,
        DATE_OPTION_ENUM.next14Days,
        DATE_OPTION_ENUM.next7Days,
        DATE_OPTION_ENUM.allTimeReport,
    
    ]
    const retiredOptions:DATE_OPTION_ENUM[] = [DATE_OPTION_ENUM.thisWeekSoFar,
         DATE_OPTION_ENUM.thisMonthSoFar, DATE_OPTION_ENUM.thisQuarterSoFar, DATE_OPTION_ENUM.thisYearSoFar];
    return allOptions.filter(o => includeRetired || !retiredOptions.includes(o));
}

export function getDateOptionDisplay(option: DATE_OPTION_ENUM, isFullDateRange=false) {
    // if begins with This, don't put here except this year
    const displayDict = {
        [DATE_OPTION_ENUM.allTimeReport]: 'All Time',
        [DATE_OPTION_ENUM.lastYear]: 'Last Calendar Year',
        [DATE_OPTION_ENUM.thisYear]: 'This Calendar Year',
        [DATE_OPTION_ENUM.nextYear]: 'Next Year',
        [DATE_OPTION_ENUM.nextWeek]: 'Next Week',
        [DATE_OPTION_ENUM.nextMonth]: 'Next Month',
    };
    if (displayDict[option]) {
        return displayDict[option];
    }
    if (isFullDateRange) {
        return option;
    }
    return option.startsWith('This') ? option.replace(' To Date', '') + ' so Far' : option;
}
