export function arraysEqual<T>(array1?: void | T[], array2?: void | T[]) {
  if (!array2 || !array1) {
    return array2 === array1; // at least one array null
  }
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array2[i] !== array1[i]) {
      return false;
    }
  }
  return true;
}
