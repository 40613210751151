<div (mouseenter)="moveIn()" (mouseleave)="moveOut()" class="position-relative">
  <div class="d-flex align-items-start mb-4">
    <ui-avatar size="md" [fullName]="addBy.userDetail?.fullName" [url]="addBy.profilePicURL" class="me-3 cursor-pointer" (click)="jumpToNewTab()"></ui-avatar>
    <div>
      <p class="mb-2">
        <span class="text-neutral-700 fw-semibold f-small me-2 cursor-pointer" (click)="jumpToNewTab()">{{addBy.userDetail?.fullName || '-'}}</span>
        <span class="text-neutral-700 fw-normal f-small">{{addDate || '-'}}</span>
      </p>

      <div>
        <p *ngIf="evidence.note" class="text-neutral-900 fw-normal f-smal mb-2 m-overflow">{{evidence.note}}</p>
        <a *ngIf="evidence.links[0]?.title || evidence.links[0]?.link" [href]="evidence.links[0]?.link" target="_blank"
          class="text-neutral-900 d-flex align-items-center">
          <span class="fw-semibold f-smal text-nowrap text-truncate m-link-max-width d-inline-block m-overflow">{{evidence.links[0]?.title || evidence.links[0]?.link}}</span>
          <ui-streamline-icon [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'xs'}" class="sl-icon-pb-1px ps-1"></ui-streamline-icon>
        </a>
      </div>

      <!-- delete btn -->
      <div *ngIf="showDeleteBtn" class="m-delete-btn">
        <app-evidence-actions-component [rowData]="evidence" [orgActivityID]="context?.orgActivityID" [userID]="context?.user?.userID"></app-evidence-actions-component>
      </div>
    </div>
  </div>
</div>
