import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipComponent } from '@components/tooltip/tooltip.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { BreadcrumbComponent, IMTBreadcrumbConfig } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'ui-unified-page-header',
  standalone: true,
  imports: [
    UIStreamlineIconComponent,
    NgIf,
    FormsModule,
    BreadcrumbComponent,
    TooltipComponent,
  ],
  templateUrl: './unified-page-header.component.html',
  styleUrl: './unified-page-header.component.scss'
})
export class UnifiedPageHeaderComponent {
  @Input() breadcrumbConfigs?: IMTBreadcrumbConfig[];
  @Input() pageTitle: string;
  @Input() boldedText?: string;
  @Input() details?: string;
  @Input() tooltipContent?: string;
}
