import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { BaseRouteReuseStrategy } from '@angular/router';
import { ComponentRef } from '@angular/core';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  static storedRoutes = new Map<string, DetachedRouteHandle>();
  static shouldDetachToggle = true; // can switch off detach manually. eg. when menu item clicked
  // fromPath and toPath are not in usage atm
  fromPath = '';
  toPath = '';
  fromUrl = '';
  toUrl = '';

  public static clearStoredRoutes(url?: string) {
    // console.log('clear stored routes!', url);
    if (url) {
      const handle = CustomRouteReuseStrategy.storedRoutes.get(url);
      if (handle) {
        CustomRouteReuseStrategy.deactivateOutlet(handle);
        CustomRouteReuseStrategy.storedRoutes.delete(url);
      }
    } else {
      for (const [u, handle] of CustomRouteReuseStrategy.storedRoutes) {
        CustomRouteReuseStrategy.deactivateOutlet(handle);
        CustomRouteReuseStrategy.storedRoutes.delete(u);
        // console.log(CustomRouteReuseStrategy.storedRoutes);
      }
    }
  }

  public static deactivateOutlet(handle: DetachedRouteHandle): void {
    // console.log('handle 🗣', handle);
    const componentRef: ComponentRef<any> = handle['componentRef'];
    if (componentRef) {
      componentRef.destroy();
    }
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // console.log('🐰from path: ', this.fromPath);
    // console.log('from url: ', this.fromUrl);
    // console.log('toPath: ', this.toPath);
    // console.log('to url: ', this.toUrl);
    const shouldDetach = (route.data.reuseRoute === true && CustomRouteReuseStrategy.shouldDetachToggle === true);
    setTimeout(() => CustomRouteReuseStrategy.shouldDetachToggle = true, 0);
    // console.log('🧤 shouldDetach? ', shouldDetach);
    return shouldDetach;

    // route path starts from the current module while this.fromPath starts from root path (/pages/...)
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // console.log('53', this._getPath(route));
    // console.log('54', handle);
    if (this._getPath(route) && handle) {
      CustomRouteReuseStrategy.storedRoutes.set(this._getFullUrl(route), handle);
      // console.log('👛 58 route map: ', CustomRouteReuseStrategy.storedRoutes);
    }
    // const key = this.getRouteKey(route);

    // if (handle && !CustomRouteReuseStrategy.storedRoutes.has(key)) {
    //   CustomRouteReuseStrategy.storedRoutes.set(key, handle);
    // }
  }

  private getRouteKey(route: ActivatedRouteSnapshot): string {
    // console.log('67 route.routeConfig?.path',route.routeConfig?.path)
    return route.routeConfig?.path || ''; // Use a unique key
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // console.log('63', this.fromPath);
    // console.log('64', this.toPath);
    // console.log('65 route',route)
    // console.log('👟 66 route map: ', CustomRouteReuseStrategy.storedRoutes);

    // found cached url, return true
    const constructedUrl = (this._getFullUrl(route));
    if (CustomRouteReuseStrategy.storedRoutes.get(this.toUrl)
    || CustomRouteReuseStrategy.storedRoutes.get(route.data.fullUrl)
    || CustomRouteReuseStrategy.storedRoutes.get(constructedUrl)) {
      return true;
    }
    // console.log(route.data);
    // not found cached url/cross modules but landing a path using shared condition
     if (route.data.useSharedCondition) {
      // console.log('not attach and clear routes');
      CustomRouteReuseStrategy.clearStoredRoutes();
      return false;
    }
    // console.log('not attach');
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    // console.log('90',  CustomRouteReuseStrategy.storedRoutes);

    // const key = this.getRouteKey(route);
    // return CustomRouteReuseStrategy.storedRoutes.get(key) || null;


    // console.log(CustomRouteReuseStrategy.storedRoutes.get(this._getPath(route)));
    if (!this._getFullUrl(route) || !CustomRouteReuseStrategy.storedRoutes.get(this._getFullUrl(route))) {
      // console.log('not retrieve');
      return null;
    }
    // console.log('retrieve');
    return CustomRouteReuseStrategy.storedRoutes.get(this._getFullUrl(route)) as DetachedRouteHandle;
  }

  shouldReuseRoute(to: ActivatedRouteSnapshot, from: ActivatedRouteSnapshot): boolean {
    if (from.routeConfig) {
      this.fromPath = this._getPath(from);
      this.fromUrl = this._getFullUrl(from);
    }
    if (to.routeConfig) {
      this.toPath = this._getPath(to);
      this.toUrl = this._getFullUrl(to);
    }
    // console.log('👀', from.routeConfig);
    // console.log(to.routeConfig);
    return from.routeConfig === to.routeConfig;
  }

  private _getFullUrl(route: ActivatedRouteSnapshot): string {
    // console.log(route);
    return '/' + route.pathFromRoot
        .filter(v => v.routeConfig?.path)
        .map(v => {
          if (v.routeConfig.path.includes(':')) {
            const param = v.routeConfig.path.split(':').pop();
            let url = v.routeConfig.path.replace(':' + param, v.params[param]);
            if (Object.keys(v.queryParams).length) {
              url += '?';
              for (const key in v.queryParams) {
                if (v.queryParams[key]) {
                  url += `${key}=${v.queryParams[key]},`;
                }
              }
            }
            return url;
          }
          return v.routeConfig.path;
        })
        .join('/')?.replace(':groupAlias', route.params.groupAlias);
  }

  private _getPath(route: ActivatedRouteSnapshot): string {
    // console.log(route);
    return '/' + route.pathFromRoot
        .filter(v => v.routeConfig?.path)
        .map(v => v.routeConfig.path)
        .join('/');
  }


}


