import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { InitializeService, InitPayload } from './services/initialize.service';
import { AnalyticsService } from './services/analytics.service';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { combineLatest, of, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { UserService } from './services/user.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { OrgSubscriptionService } from './services/org-subscription.service';
import { PRODUCT_TYPES } from './core/guards/mode.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [NgbTooltipConfig],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  destroyed$ = new Subject<void>();
  title = 'ausmed-lms';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
    private initializeService: InitializeService,
    private router: Router,
    private titleService: Title,
    private analytics: AnalyticsService,
    tooltipConfig: NgbTooltipConfig,
    private orgSubscription: OrgSubscriptionService,
  ) {
    this.titleService.setTitle(environment.hippotlian ? 'Hippotlian LMS' : 'Ausmed for Organisations');
    this.router.events.pipe(filter((evnet) => evnet instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // remove new badge after refresh
      if (event.id === 1 && event.url === event.urlAfterRedirects) {
        localStorage.removeItem('newly_added_resource');
      }

      if (document.getElementsByTagName('mat-sidenav-content')[0]) {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTop = 0;
      }

      try {
        const features = this.featureFlagService.featureConfig$.getValue()?.features || {};
        const featureNames = Object.keys(features);
        const enabledFeatureNames = featureNames.filter(featureName => !!features[featureName]);

        (window as any).Appcues.identify(
          this.userService.user?.value?.userID, // unique, required
          {
            features: enabledFeatureNames.join(', '),
            role: this.userService.user?.value?.isManager ? 'Org Manager' : 'Team Manager', // Current user’s role or permissions
            fullName: this.userService.user?.value?.fullName, // current user's full name
          }
        );
      } catch (e) {
        console.log(e);
      }
    });

    // tooltip
    tooltipConfig.tooltipClass = 'custom-tooltip';
    // tooltipConfig.container = 'body';
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.authService.isAuthenticated() && localStorage.getItem('orgcode')) {
      this.authService.renewToken(localStorage.getItem('orgcode'))
        .pipe(switchMap((initPayload: InitPayload) => {
          // Event tracking. Check if event due to admin login hasn't been fired to prevent duplicate calls
          if (sessionStorage.getItem('adminLogin')) {
            sessionStorage.removeItem('adminLogin');
          } else {
            this.analytics.track('A-login-succeeded', {
              type: 'renew'
            });
          }
          return this.initializeService.init(initPayload);
        }),
          switchMap((initData) => {
            if (!this.userService.isManager) {
              return of([null, null, initData]);
            }
            const orgID = localStorage.getItem('orgID');
            return combineLatest([
              this.orgSubscription.getCardPayments(orgID),
              this.orgSubscription.getDebitPayments(orgID),
              of(initData)
            ]).pipe(catchError(() => of([null, null])));
          })
        )
        .subscribe(([card, debit, initData]) => {
          const contractType = initData.currentContract.contractType;
          if (contractType === PRODUCT_TYPES.LIBRARY) {
            // this.router.navigate(['/pages/scorm-schedules']);
          }
          // appcues
          if (card?.data?.length || debit?.data?.length) {
            // console.log('appcues payment');
            try {
              (window as any).Appcues.track('appcues-payment-method-found');
            } catch (e) {
              console.log(e);
            }
          }
        }, (error) => {
          console.log(error);
          this.authService.logout().subscribe();
        });
    }
    this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter((evnet) => evnet instanceof NavigationEnd)
    ).subscribe(() => {
      // Every route change should trigger segment page event.
      this.analytics.page();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
