import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DefaultFilter } from 'src/app/shared-modules/ng2-smart-table/components/filter/filter-types/default-filter';
import { NgClass } from '@angular/common';

@Component({
    template: `
    <input
      #number
      [ngClass]="inputClass"
      [formControl]="inputControl"
      class="form-control"
      [placeholder]="column.title"
      type="text">
  `,
    standalone: true,
    imports: [
        FormsModule,
        NgClass,
        ReactiveFormsModule,
    ],
})
export class CustomColumnFilterComponent extends DefaultFilter implements OnInit, OnChanges, OnDestroy {
  inputControl = new FormControl();
  updateControl = true; // used to determine if query is from typing or parent table

  constructor() {
    super();
    this.delay = 500;
  }

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(
        debounceTime(this.delay),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        this.query = value !== null ? this.inputControl.value.toString() : '';
        this.setFilter();
        this.updateControl = false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // override to prevent glitchy effect when typing
    if (changes.query && !changes.source) {
      // empty filter when changing page/running report
      if (this.updateControl) {
        this.query = changes.query.currentValue;
        this.inputControl.setValue(this.query);
      } else {
        this.updateControl = true;
      }
      // this.inputControl.setValue('');
      // this.setFilter();
    }

    // for edge case: filter, change tab, back, run filter again
    if (changes.source && !changes.query) {
      this.updateControl = true;
    }
  }
}
