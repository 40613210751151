import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { LocalDatePipe } from '../../pipes/local-date.pipe';
import { DATE_OPTION_ENUM } from '../../../pages/reporting/filter-pack/interfaces';
import { NgFor, NgIf } from '@angular/common';

import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeSelectorComponent as DateRangeSelectorComponent_1 } from '../../date-range-selector/date-range-selector.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { getAllDateOptions, getDateOptionDisplay } from './date-selector-utils';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  standalone: true,
  imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgFor, NgIf, NgbDropdownItem, DateRangeSelectorComponent_1, UIStreamlineIconComponent, NgbTooltip]
})
export class DateSelectorComponent implements OnChanges {

  constructor(
    private localDatePipe: LocalDatePipe,
  ) {
  }

  @Input() initDateRange: DATE_OPTION_ENUM;
  @Input() isFullDateRange; // true for most reports
  @Input() maxDate;
  @Input() includesAllTime = false; // all time at the top, used in resource detail pages. Consider remove??
  @Input() allTimeStartDate = '2017-01-01';
  @Input() customRange: object;
  @Input() placement: 'right' | 'top' | 'bottom' | 'left' = 'left';
  @Input() dateFilterOptions: DateFilterOptions;
  @Output() dateRangeChange: EventEmitter<string[]> = new EventEmitter();
  @Output() dateOptionChange: EventEmitter<string> = new EventEmitter();
  DATE_OPTION_ENUM = DATE_OPTION_ENUM;

  oneMonthRange = false;

  DATE_OPTIONS: DATE_OPTION_ENUM[] = getAllDateOptions()
  selectedDate;
  DATE_OPTION_DICT = {};
  getDateOptionDisplay = getDateOptionDisplay

  private static _getDateDisplay(date: Dayjs) {
    return date?.format('DD MMM YYYY');
  }

  private static _getDateValue(date: Dayjs) {
    return date?.format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.oneMonthRange = this.dateFilterOptions?.oneMonthRange;
    if (this.dateFilterOptions?.options) {
      this.DATE_OPTIONS = this.dateFilterOptions.options;
      this.getOptionDict()
    }

    if (this.initDateRange === DATE_OPTION_ENUM.custom) {
      this.datePickerChanged(this.customRange);
    }
    if (this.oneMonthRange) {
      this.DATE_OPTIONS = this.DATE_OPTIONS.filter(o => {
        return [DATE_OPTION_ENUM.thisWeek, DATE_OPTION_ENUM.thisMonth,
           DATE_OPTION_ENUM.lastWeek, DATE_OPTION_ENUM.lastMonth]
          .includes(o);
      })
    }

    if (this.dateFilterOptions?.hideAllTime) {
      this.DATE_OPTIONS = this.DATE_OPTIONS.filter(o => o !== DATE_OPTION_ENUM.allTime)
    }
  }

  ngOnChanges(changes): void {
    if (this.includesAllTime && !this.DATE_OPTIONS.includes(DATE_OPTION_ENUM.allTime)) {
      this.DATE_OPTIONS.unshift(DATE_OPTION_ENUM.allTime);
      this.DATE_OPTIONS = this.DATE_OPTIONS.filter(o => o !== DATE_OPTION_ENUM.allTimeReport)
    } 
    if (!this.isFullDateRange) {
      // remove duplicate 'so far' filters
      this.DATE_OPTIONS = this.DATE_OPTIONS.filter(o => ![DATE_OPTION_ENUM.thisWeekSoFar,
         DATE_OPTION_ENUM.thisMonthSoFar, DATE_OPTION_ENUM.thisQuarterSoFar, DATE_OPTION_ENUM.thisYearSoFar].includes(o))
    }
    this.getOptionDict();
    this.reset();
  }

  reset(useDefault = false) {
    let selDate;
    const defaultSel = this.DATE_OPTIONS.includes(DATE_OPTION_ENUM.thisMonth)
      ? DATE_OPTION_ENUM.thisMonth : this.DATE_OPTIONS[0];
    if (useDefault) {
      selDate = defaultSel;
    } else {
      selDate = this.initDateRange || defaultSel;
    }
    this.chooseDate(selDate);
  }


  datePickerChanged($event) {
    if (!$event) {
      this.selectedDate = 'Select date';
      this.dateRangeChange.emit(undefined);
    } else {
      this.selectedDate = this.localDatePipe.transform($event.startDate) + ' - ' + this.localDatePipe.transform($event.endDate);
      this.dateRangeChange.emit([dayjs($event.startDate).format('YYYY-MM-DD'), dayjs($event.endDate).format('YYYY-MM-DD')]);
    }
    this.dateOptionChange.emit('custom');
  }

  getOptionDict() {
    for (const date of getAllDateOptions(true).concat(DATE_OPTION_ENUM.allTime)) {
      let startDate, endDate, startDayJs, endDayJs;
      switch (date) {
        case DATE_OPTION_ENUM.allTimeReport:
          startDayJs = null;
          endDayJs = null;
          break;
          case DATE_OPTION_ENUM.allTime:
            // if (!this.allTimeStartDate) {
            //   this.DATE_OPTIONS = this.DATE_OPTIONS.filter(dateOption => dateOption !== DATE_OPTION_ENUM.allTime);
            //   throw Error('missing start date of all time');
            // }
            startDayJs = dayjs(this.allTimeStartDate);
            endDayJs = dayjs();
            break;

        case DATE_OPTION_ENUM.thisWeek: // this week
          startDayJs = dayjs().startOf('week');
          if (this.isFullDateRange) {
            // start of this week (Sun) to end of this week (Sat)
            endDayJs = dayjs().endOf('week');
          } else {
            // start of this week to today
            endDayJs = dayjs();
          }
          break;
        case DATE_OPTION_ENUM.lastWeek: // last week
          startDayJs = dayjs().startOf('week').subtract(1, 'week');
          // start of last week to end of last week
          endDayJs = dayjs().endOf('week').subtract(1, 'week');
          break;
        case DATE_OPTION_ENUM.thisMonth: // this month
          startDayJs = dayjs().startOf('month');
          if (this.isFullDateRange) {
            endDayJs = dayjs().endOf('month');
          } else {
            // start of this month to today
            endDayJs = dayjs();
          }
          break;
        case DATE_OPTION_ENUM.lastMonth: // last month
          startDayJs = dayjs().startOf('month').subtract(1, 'month');
          endDayJs = dayjs().subtract(1, 'month').endOf('month');
          break;
        case DATE_OPTION_ENUM.thisYear: // this year
          startDayJs = dayjs().startOf('year');
          // if (this.isFullDateRange) {
          //   endDayJs = dayjs().endOf('year');
          // } else {
            endDayJs = dayjs();
          // }
          break;
        case DATE_OPTION_ENUM.lastYear: // last year
          startDayJs = dayjs().startOf('year').subtract(1, 'year');
          endDayJs = dayjs().endOf('year').subtract(1, 'year');
          break;
        case DATE_OPTION_ENUM.thisQuarter:
          startDayJs = dayjs().startOf('quarter');
          endDayJs = dayjs().endOf('quarter');
          if (!this.isFullDateRange) {
            endDayJs = dayjs();
          }
          break;
        case DATE_OPTION_ENUM.lastQuarter:
          startDayJs = dayjs().subtract(3, 'month').startOf('quarter');
          endDayJs = startDayJs.endOf('quarter');
          break;
        default:
          if (date.endsWith('Days')) {
            const num = +date.split(' ').at(-2) - 1;
            if (date.startsWith('Next')) {
              startDayJs = dayjs();
              endDayJs = dayjs().add(num, 'days');
            } else {
              startDayJs = dayjs().subtract(num, 'days');
              endDayJs = dayjs();
            }
            break;
          }
          if (date.endsWith('so Far')) {
            const unitType = date.split(' ').at(-3).toLocaleLowerCase() as dayjs.OpUnitType // week/quarter,etc
            startDayJs = dayjs().startOf(unitType);
            endDayJs = dayjs();
            break;
          }
          if (date.startsWith('Next')) {
            const unitType = date.split(' ').at(-1).toLocaleLowerCase() as dayjs.ManipulateType // week/quarter,etc
            const ref = dayjs().add(1, unitType);
            startDayJs = dayjs(ref).startOf(unitType);
            endDayJs = dayjs(ref).endOf(unitType)

          }


      }
      startDate = {
        value: DateSelectorComponent._getDateValue(startDayJs),
        display: DateSelectorComponent._getDateDisplay(startDayJs)
      };
      endDate = {
        value: DateSelectorComponent._getDateValue(endDayJs),
        display: DateSelectorComponent._getDateDisplay(endDayJs)
      };
      this.DATE_OPTION_DICT[date] = { startDate, endDate };
    }
  }

  chooseDate(date, isCustom = false) {
    if (date === DATE_OPTION_ENUM.custom) {
      return;
    }
    this.selectedDate = date;
    const { startDate, endDate } = this.DATE_OPTION_DICT[date];
    this.dateRangeChange.emit([startDate.value, endDate.value]);
    this.dateOptionChange.emit(date);
  }

  updateCustomDisplay(startDate, endDate) {
    this.selectedDate = this.localDatePipe.transform(startDate) + ' - ' + this.localDatePipe.transform(endDate);
  }

  // getDateOptionDisplay(option: DATE_OPTION_ENUM) {
  //   // if (option === DATE_OPTION_ENUM.thisYear) {
  //   //   return 'Year to Date';
  //   // }
  //   if (option === DATE_OPTION_ENUM.allTimeReport) {
  //     return 'All Time';
  //   }
  //   if (option === DATE_OPTION_ENUM.lastYear) {
  //     return 'Last Calendar Year';
  //   }
  //   if (option === DATE_OPTION_ENUM.thisYear) {
  //     return 'This Calendar Year';
  //   }
  //   if (this.isFullDateRange) {
  //     return option;
  //   }
  //   return option.startsWith('This') ? option.replace(' To Date', '') + ' so Far' : option;
  // }

  // get oneMonthRange() {
  //   return this.dateFilterOptions?.oneMonthRange;
  // }

  // for IT support
  enableAllTime() {
    this.includesAllTime = true;
    this.ngOnChanges(null);
  }

}



export interface DateFilterOptions {
  hideAllTime?: boolean;
  oneMonthRange?: boolean;
  options?: DATE_OPTION_ENUM[];
}