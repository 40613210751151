<app-general-side-panel-cell
    [buttonText]="buttonText"
    [preventSideBar]="rowData.assigns?.length > 5"
    (cellClick)="openModal()"
>
    <!-- <div class="p-4">
        <h3 class="fw-semibold mb-2">All Items</h3> -->
        <!-- <ol>
            <li *ngFor="let item of value">{{item}}</li>
        </ol> -->
        <app-side-panel-compliance-record-section [data]="rowData.assigns" [topMargin]="0"></app-side-panel-compliance-record-section>

    <!-- </div> -->
</app-general-side-panel-cell>
