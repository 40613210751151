<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<ng-container *ngIf="spinnerStatus$ | async as spinnerStatus">
    <button  [disabled]="spinnerStatus === SPINNER_BUTTON_STATUS_ENUM.processing" class="btn btn-save-filter border-0" (click)="openSaveModal()">
        <ui-streamline-icon class="text-{{btnColour}}-700" [slIcon]="slIcon"></ui-streamline-icon>
        <span class="f-small fw-semibold">
            Save Filter
        </span>
    </button>

</ng-container>


