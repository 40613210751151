
@if (settings) {
    <app-smart-table 
    [source]="source"
    [settings]="settings"
    tableDiv="task-shared"
    [withWrapper]="true"
    [downloadSettings]="downloadSettings"
    [stickyRight]="true"
    ></app-smart-table>

}
