import { Injectable } from '@angular/core';
import { USER_STATUS } from './user.service';

export interface IAddRecordFilters {
  orgUserIDs: string[];
  resourceIDs: string[];
  dates: string[];
  userStatuses?: USER_STATUS[];
  planIDs?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AddflowLinkService {
  addRecordFilters: IAddRecordFilters;
  _newUserIdList: string[];


  constructor() { }

  useRecord(addFlow: string) {
    const ans = {...this.addRecordFilters};
    this.addRecordFilters = null;
    return ans;
  }

  addNewBadge(users:{orgUserID?, hasNewBadge?}[]) {
    this.getNewUsers();
    const _newUserIDList = this._newUserIdList;
    if (!_newUserIDList?.length) {
      return users;
    }
    const ans = users.map(user => {
      if (_newUserIDList.includes(user.orgUserID)) {
        user = {...user, hasNewBadge: true};
      }
      return user;
    });
    return ans;
  }

  // store to local storage
  addNewUsers(newUserIDs: string[]) {
    // Type:  singleUser: string, mutipuleUsers: string[]  =>  string[]
    if (newUserIDs) {
      this._newUserIdList = newUserIDs;
      localStorage.setItem('newOrgUserIDs', JSON.stringify(this._newUserIdList));
    }
  }

  // re-save data into localStorage if needed
  reAddNewUsers() {
    if (this._newUserIdList?.length) {
      localStorage.setItem('newOrgUserIDs', JSON.stringify(this._newUserIdList));
    }
  }

  getNewUsers() {
    const fromLocal = JSON.parse(localStorage.getItem('newOrgUserIDs'));
    if (fromLocal) {
      this._newUserIdList = fromLocal;
      localStorage.removeItem('newOrgUserIDs');
    }
  }

  setRecordFilters(filters: IAddRecordFilters) {
    localStorage.setItem('recordFilters', JSON.stringify(filters));
  }

  getRecordFilters(): IAddRecordFilters {
    const ans = JSON.parse(localStorage.getItem('recordFilters'));
    localStorage.removeItem('recordFilters');
    return ans;
  }

}
