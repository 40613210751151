import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';
import { DisableAndShowTextDirective } from 'src/app/shared/directives/disable-and-show-text.directive';

/** Disables and shows spinner when loading */
@Component({
  selector: 'app-processing-button',
  standalone: true,
  imports: [CommonModule,
    UISpinnerComponent,
    DisableAndShowTextDirective
  ],
  templateUrl: './processing-button.component.html',
  styleUrls: ['./processing-button.component.scss']
})
export class ProcessingButtonComponent {
  @Input() text: string
  @Input() isLoading = false;
  @Input() btnClass = 'btn-secondary';
  @Input() disabled = false;
  @Output() confirmClick = new EventEmitter();

}
