@if(valueMode==='ngModel'){
<ng-select #selector [name]="name" [id]="id" [items]="items" [bindLabel]="bindLabel" [bindValue]="bindValue"
    appInputError [showError]="showError" (search)="getFilteredItems($event)" [multiple]="true" [searchable]="true"
    [closeOnSelect]="false" [(ngModel)]="ngModelValue" (ngModelChange)="changeNgModelValue($event)"
    [virtualScroll]="true" [searchFn]="searchFn" [groupBy]="groupByValue" (add)="addAnItem($event)"
    (remove)="removeAnItem($event)">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="custom-control custom-checkbox" [class.ms-6]="item.parentGuidelineID">
            <input id="types-{{index}}" class="custom-control-input" type="checkbox" [ngModel]="item$.selected" />
            <label class="custom-control-label f-small text-neutral-800">{{ item[bindLabel] }}</label>
        </div>
    </ng-template>
    <ng-template ng-header-tmp>
        <div class="d-flex justify-content-between">
            <button class="btn btn-link text-decoration-underline fw-semibold me-2" (click)="onSelectAll()">{{
                filteredItems ? 'Select All Filtered Results' : 'Select All' }}
            </button>
            <button class="btn btn-link text-decoration-underline fw-semibold" (click)="onClearAll()">Clear All
            </button>
        </div>

    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items | slice:0:2">
            <span class="ng-value-label">
                @if(item.parentGuidelineID && item.parentGuideline){
                {{item.parentGuideline.name}} / {{item.name}}
                }@else{
                {{item.name}}
                }
            </span>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">+ {{items.length - 2}} more</span>
        </div>
    </ng-template>
</ng-select>
} @else if(valueMode==='formControl'){
<ng-select [formControl]="control" name="guidelines" id="guidelines" [items]="items" bindLabel="name"
    bindValue="guidelineID" [multiple]="true" [selectableGroup]="true" [searchable]="true"
    [selectableGroupAsModel]="false" [closeOnSelect]="false" [clearSearchOnAdd]="true" [groupBy]="groupByValue"
    [virtualScroll]="true" (add)="addAnItem($event)" (remove)="removeAnItem($event)">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        @for(item of items; track item.guidelineID){
        <div class="ng-value">
            <span class="ng-value-label">
                @if(item.parentGuidelineID && item.parentGuideline){
                {{item.parentGuideline.name}} / {{item.name}}
                }@else{
                {{item.name}}
                }
            </span>
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
        </div>
        }
    </ng-template>
    <ng-template ng-header-tmp>
        <div class="d-flex justify-content-between">
            <button class="btn btn-link" (click)="onClearAllGuidelines()">Clear All
            </button>
        </div>
    </ng-template>
    <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        <div class="ng-value" *ngFor="let item of getEnabledGuidelines(items)">
            <span class="ng-value-icon right" (click)="clear(item)">×</span>
            <span class="ng-value-label">{{ item.name }}</span>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="custom-control custom-checkbox" [ngClass]="{'ms-6' : item.parentGuidelineID}">
            <input id="guideline-item-{{index}}" class="custom-control-input" type="checkbox" [ngModel]="item$.selected"
                [ngModelOptions]="{standalone: true}" />
            <label class="custom-control-label f-small text-neutral-800">{{item.name}}</label>
        </div>
    </ng-template>
</ng-select>
}
