import { CommonModule, NgFor, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { NgbNavModule, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink } from '@ng-bootstrap/ng-bootstrap';
import { DownloadCsvButtonComponent } from '@components/table-utilities/download-csv-button/download-csv-button.component';
import { Ng2SmartTableModule } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.module';

@Component({
    selector: 'app-multi-tab-tables',
    templateUrl: './multi-tab-tables.component.html',
    styleUrls: ['./multi-tab-tables.component.scss'],
    standalone: true,
    imports: [NgbNav, NgFor, NgbNavItem, NgbNavItemRole, NgbNavLink, NgClass, DownloadCsvButtonComponent, CommonModule]
})
export class MultiTabTablesComponent implements OnInit {
  @Input() tables: {
    name, numRow,
  }[];
  @Input() activeTabId;
  @Input() showDownload = false;
  @Output() navChange = new EventEmitter();
  @Output() downloadCsv = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  onNavChange(tab) {
    this.activeTabId = tab;
    this.navChange.emit(tab);
  }

}

@NgModule({
    imports: [
        CommonModule,
        NgbNavModule,
        Ng2SmartTableModule,
        MultiTabTablesComponent
    ],
    exports: [MultiTabTablesComponent],
})
export class MultiTabTablesModule {
}

