<div class="modal-body" style="min-height: 350px;">
    <ng-container *ngIf="!loading else load">
        <div class="mb-5 text-center">
            <h3 class="text-center">Select users to assign this policy to:</h3>
            <p>Please note you can only assign policies to staff who have already been assigned the policy in the past</p>
        </div>

        <select-users-table [users]="availableUsers" [selectedUsers]="selectedUsers"
            (userRowSelect)="onUserRowSelect($event)">
        </select-users-table>
    </ng-container>
    <ng-template #load>
        <div class="d-flex justify-content-center align-items-center" style="min-height:250px;">
            <ui-spinner></ui-spinner>
        </div>
    </ng-template>

</div>

<!-- FOOTER -->
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-light" (click)="stepsControl.previousStep();">
        Back
    </button>
    <button class="btn btn-secondary" (click)="next()" [disabled]="selectedUsers.length<1">Continue</button>
</div>