import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cpdTime',
    standalone: true
})
export class CpdTimePipe implements PipeTransform {

  transform(value: any, format: 'hour' | 'minute' | 'credit' | 'minuteWithM'  = 'minute'): any {
    if (!value) {
      return 'None';
    }
    if (value === Infinity) {
      return 'None';
    }
    if (format === 'credit') {
      return value === 0 ? 'None' : (Math.floor(value / 60) + ' credit');
    } else if (format === 'hour') {
      return value === 0 ? 'None' : ((value < 60) ? (value % 60 + 'm') : Math.floor(value / 60) + 'h ' + ((value % 60) === 0 ? '' : (value % 60 + 'm')));
    } else if (format === 'minuteWithM') {
      return value === 0 ? 'None' : value + 'm';
    } else if (format === 'minute') {
      return value === 0 ? 'None' : value + '';
    }

  }


}
