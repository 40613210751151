import { Injectable } from '@angular/core';
import { IFeatureConfig } from '../interfaces/feature-config';
import { BroadcastService } from './broadcast.service';
import { BehaviorSubject } from 'rxjs';
import { coerceArray } from '@angular/cdk/coercion';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private _init = false;
  featureConfig$: BehaviorSubject<IFeatureConfig> = new BehaviorSubject<IFeatureConfig>(null);

  constructor(
      private broadcastService: BroadcastService,
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this._init = false;
    });
  }

  get featureFlags() {
    return this.featureConfig$.getValue().features;
  }

  featureOn(featureNames: string | string[]): boolean {
    return coerceArray(featureNames).every(feature => this.featureFlags[feature] || featureNames === undefined);
  }

  setConfig(config: IFeatureConfig) {
    this.featureConfig$.next(config);
  }

}
