import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-overview-info-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './overview-info-card.component.html',
  styleUrls: ['./overview-info-card.component.scss']
})
export class OverviewInfoCardComponent {

  @Input() text;
  @Input() boldedText;
}
