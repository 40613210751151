import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-common-title-cell',
  templateUrl: './common-title-cell.component.html',
  styleUrls: ['./common-title-cell.component.scss'],
  standalone: true,
  imports: [ NgIf, RouterLink ]
})
export class CommonTitleCellComponent implements OnInit {
  @Input() baseUrl: string;
  @Input() idAttributeName: string;
  @Input() value: any;
  @Input() rowData: any;
  @Input() onClickFn?: () => void;
  url: string;

  ngOnInit(): void {
    const id = this.rowData[this.idAttributeName];
    this.url = this.baseUrl + id;
  }

  onClick() {
    if (this.onClickFn) {
      this.onClickFn();
    }
  }
}
