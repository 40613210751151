import { InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { KeyValue } from '@angular/common';

interface PPOperator {
  userID: string;
  fullName: string;
}

export interface PolicyLinkedForms {
  docID: string;
  orgID: string;
  name: string;
  docCode: string;
  url: string;
  description: string;
  createDate: string;
  updateDate: string;
  createBy: string;
  updatedBy: string;
  status: GOVERNANCE_STATUS;
}

export interface PolicyLinkedProcedure {
  governanceID: string;
  orgID: string;
  title: string;
  governanceType: string;
  code: string;
  publishedVersionID: string;
}

/* should be seperated into two interfaces add & edit */
export interface SharedPPData {
  doc: string;
  originalFileName: string;
  docTitle: string;
  itemID: string;
  description: string;
  relevantJobRoles: string[];
  relevantStandards: string[];
  relevantOrgStandards?: string[];
  relevantOrgCapabilities?: string[];
  relevantTopics: string[];
  relevantVisibility?: PolicyVisType;
  needAcknowledge: boolean;
  ackJobRoleIDs: string[];
  ackTeamIDs: string[];
  ackOrgUserIDs: string[];
  reviewDate: string;
  status: GOVERNANCE_STATUS;
  internalNotes: string;
  newVersion?: EditOnlyNewVersion;
  notification?: string;
  versionID?: string;
  policyID?: string;
  governanceDocs: string[];
  linkedFormsEntities?: PolicyLinkedForms[];
  linkedGovernances: string[];
  linkedProcedureEntities?: PolicyLinkedProcedure[];
  docType?: 'link' | 'pdf';
  owners?: string[] // orgUserIDs
}

export type PPEventBusEventName = 'disable-next' | 'switch-back' | 'switch-back-modal' | 'deactivate' | 'next-step-clicked';

export interface PPEventBusEvent<P> {
  eventName: PPEventBusEventName;
  payload?: P;
}

export interface INavigateRouteGuardModal {
  openNavigateGuardModal(): boolean | Observable<boolean>;
}


/* the reqs are not stable */
export interface GovernanceVersionBackend {
  versionID: string;
  governanceID: string;
  versionCode: string,
  versionNum: number,
  status: GOVERNANCE_STATUS;
  doc: string;
  docName: string;
  relevancy: RelevancyBeforeJSON;
  ackRelevancy: AckRelevancyBeforeJSON;
  requireAcknowledgement: boolean;
  notification: string;
  governanceDocs?: PolicyLinkedForms[];
  linkedGovernances?: PolicyLinkedProcedure[];
  comment: string;
  reviewDate: string;
  createDate: string;
  updateDate: string;
  publisher?: PPOperator;
  code?: string;
  title: string;
  description?: string;
  docType?: 'pdf' | 'link'
}

/* [Technical Debt] governanceType should be `policy` and 'procedure' but the reqs are not stable atm. So, leave it as string */
export interface PolicyFromBackend {
  orgID: string;
  title: string;
  governanceType: string;
  code?: string;
  description?: string;
  reviewDate: string;
  governanceVersion: GovernanceVersionBackend;
  governanceID: string;
  createDate: string;
  updateDate: string;
  publishedVersionID: string;
  ackRelevancy?: GovernanceAckRelevancyBackend;
  relevancy?: RelevancyBeforeJSON;
  latestVersionID?: string;
  owners?: string[];
}

export interface GovernanceAckRelevancyBackend {
  jobRoles?: string[];
  teams?: string[];
  orgUserIDs?: string[];
}

export interface GovernanceForm {
  orgID: string;
  governanceType: string;
  governanceVersion: GovernanceVersionForm;
}

export interface RelevancyBeforeJSON {
  jobRoles?: string[];
  standards?: string[];
  orgStandards?: string[];
  topics?: string[];
  visibilities?: PolicyVisType;
}

export interface AckRelevancyBeforeJSON {
  jobRoles?: string[];
  teams?: string[];
  orgUserIDs?: string[];
}

export enum GOVERNANCE_STATUS {
  published = 'Published',
  archived = 'Archived',
  draft = 'Draft',
}

export type EditOnlyNewVersion = 'Major' | 'Minor' | 'Patch';
export type PolicyFlowModalCTAActions = 'dontSave' | 'saveAsDraft' | 'publish' | 'updatePublished' | 'discardChanges';
export interface GovernanceVersionForm {
  status: GOVERNANCE_STATUS;
  doc: string;
  title: string;
  code?: string;
  description?: string;
  relevancy: RelevancyBeforeJSON;
  ackRelevancy: AckRelevancyBeforeJSON;
  requireAcknowledgement: boolean;
  notification: string;
  comment: string;
  reviewDate: string;
  governanceDocs: string[];
  linkedGovernances: string[];
  newVersion?: EditOnlyNewVersion;
  docName: string;
}

export type PolicyFlowStep =
  'Upload'
  | 'Overview'
  | 'Job Roles'
  | 'Key Information' | 'Tags' |'Visibility'
  | 'Dates'
  | 'Review Date'
  | 'Procedures' | 'Owner'
  | 'Acknowledgment'
  | 'Additional Documents' | 'Acknowledgment Settings'
  | 'Review Edit' | 'Internal Notes';
const SharedPPFlowDataToken = new InjectionToken<BehaviorSubject<SharedPPData>>('This is the shared data for pp flow');
export const INIT_ADD_POLICY_FLOW_DATA: SharedPPData = {
  doc: '',
  docTitle: '',
  originalFileName: '',
  itemID: '',
  description: '',
  reviewDate: '',
  relevantJobRoles: [],
  relevantTopics: [],
  relevantStandards: [],
  relevantOrgCapabilities: [],
  needAcknowledge: true,
  ackJobRoleIDs: [],
  ackOrgUserIDs: [],
  ackTeamIDs: [],
  internalNotes: '',
  governanceDocs: [],
  status: GOVERNANCE_STATUS.published,
  linkedFormsEntities: [],
  linkedGovernances: []
};

export const NEW_VERSION_TO_OPTION_DISPLAY: Map<EditOnlyNewVersion, string> = new Map([
  ['Patch', 'Apply changes without notifying required users'],
  ['Minor', 'Apply changes and notify required users'],
  ['Major', 'Apply changes and require re-acknowledgement'],
]);
export const originalOrder = (a: KeyValue<EditOnlyNewVersion, string>, b: KeyValue<EditOnlyNewVersion, string>): number => 0;
export { SharedPPFlowDataToken };

export interface IAckBackend {
  ackID: string;
  orgID: string;
  assigneeOrgUserID: string;
  assigneeUserID: string;
  govID: string;
  assignLocalDate: string;
  assignerName: string;
  currentPeriod: {
    initDate: string;
    govVersionID: string;
    govVersionCode: string;
    govVersionNum: number;
    status: ACK_STATUS;
    assign: {
      userID: string;
      date: string;
    },
    acknowledge?: {
      date?: string;
      userID?: string;
      localDate: string;
    }
    reminder?: IAckReminder;
  };
}

export interface IAckReminder {
  lastReminder: { sentLocalDate: string };
}

export enum ACK_STATUS {
  unacknowledged = 'Unacknowledged',
  acknowledged = 'Acknowledged',
  reassigned = 'Re-assigned', // displayed as unacknowledged
  discarded = 'Discarded',
  skipped = 'Skipped',
}

export type PolicyVisType = 'required' | 'everyone';
