import { Component, Input, OnChanges } from '@angular/core';
import { Dictionary } from 'underscore';
import { StreamlinePaths } from './path';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IUIStreamlineIcon, SLColor, SLName, SLSize, SLTheme } from './streamline-icon-interfaces';
import { convertFaToSl } from './convert-fa-to-sl';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ui-streamline-icon',
  template: `
      <svg-icon
              src="/assets/images/streamline/{{ theme }}/{{ path }}.svg"
              [svgStyle]="svgStyle"
              [svgClass]="svgClass"
              *ngIf="theme && path"
      ></svg-icon>
  `,
  styleUrls: [
    './ui-streamline-icon-color.component.scss',
    './ui-streamline-icon-size.component.scss',
    './ui-streamline-icon.component.scss',
  ],
  standalone: true,
  imports: [AngularSvgIconModule, NgIf]
})
export class UIStreamlineIconComponent implements OnChanges {
  @Input() slIcon: IUIStreamlineIcon;

  /**
   * Optional; used only for resource type icons
   */
  @Input() faIcon: string;
  @Input() faIconSize: SLSize;

  theme: SLTheme;

  get name(): SLName | string {
    return this.slIcon?.name;
  }

  @Input() fullPath: string; // Optional; used for duplicate names

  get size(): SLSize {
    return this.slIcon?.size || 'md';
  }

  get color(): SLColor {
    return this.slIcon?.color;
  }

  /**
   * Don't put size and color in svgStyle
   */
  get svgStyle(): Dictionary<string> {
    return this.slIcon?.svgStyle;
  }

  /**
   * path to icon (without .svg)
   *
   * look into this folder to know which path to use: /node_modules/@streamlinehq/streamlinehq/img/streamline-${theme}
   */
  path: string;

  svgClass: string;

  ngOnChanges() {
    if (this.name) {
      this.path = (this.name && StreamlinePaths[this.name]) || this.fullPath;
      if (this.path) {
        const prefix = 'sl-';
        this.svgClass =
          `${prefix}${this.size}` +
          (this.color ? ` ${prefix}${this.color}` : '') + ' ' +
          (this.slIcon?.svgClassIn || '')
          ;
        this.theme = this.slIcon.theme || 'regular';
      }
    } else if (convertFaToSl(this.faIcon)) {
      const slIcon = convertFaToSl(this.faIcon);
      const { name, color, theme } = slIcon;
      const size = this.faIconSize || 'md';
      this.path = (name && StreamlinePaths[name]) || this.fullPath;
      if (this.path) {
        const prefix = 'sl-';
        this.svgClass =
          `${prefix}${size}` +
          (color ? ` ${prefix}${color}` : '')+ ' ' +
          (this.slIcon?.svgClassIn || '');
        this.theme = theme || 'regular';
      }
    }
  }
}







