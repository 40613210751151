import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PnpService } from 'src/app/services/pnp.service';
import { StepsControl } from 'src/app/shared/utils/workflows/workflow-steps-control';
import { PolicyProcedureApiService } from '../../../services/policy-procedure-api.service';
import { IRevokePolicyFlow, IRevokePolicySharedData } from '../../revoke-policy.interface';
import { UISpinnerComponent } from '../../../../../ui/ui-spinner/ui-spinner.component';
import { NgIf } from '@angular/common';
import { AddflowConfirmUsersComponent } from '../../../../../shared/popups/addflow-confirm-users/addflow-confirm-users.component';

@Component({
    templateUrl: 'revoke-policy-review.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AddflowConfirmUsersComponent, NgIf, UISpinnerComponent]
})

export class RevokePolicyReviewComponent implements OnInit {
    revokePolicyFlowSharedData: IRevokePolicySharedData;
    stepsControl: StepsControl<IRevokePolicyFlow>;
    error = '';
    revokingPolicy = false;
    constructor(
        private cdRef: ChangeDetectorRef,
        private pnpApiService: PolicyProcedureApiService,
        private pnpService: PnpService,
        private analytics: AnalyticsService
    ) { }

    ngOnInit(

    ) { }

    next() {
        this.stepsControl.updateSteps();
        this.stepsControl.nextStep();
        this.cdRef.detectChanges();
    }

    onBack() {
        this.stepsControl.previousStep();
    }

    revokePolicy() {
        const orgUserIDs = this.revokePolicyFlowSharedData.selectedUsers.map(u => u.orgUserID);
        this.revokingPolicy = true;
        this.cdRef.detectChanges();
        this.pnpApiService.revokePolicy(this.revokePolicyFlowSharedData.selectedPolicy.governanceID, { orgUserIDs: orgUserIDs }).subscribe(
            (result) => {
                this.revokingPolicy = false;
                this.pnpService.announceAckUpdate();
                this.revokePolicyFlowSharedData.revokeResult = result;
                this.cdRef.detectChanges();
                this.next();
                this.analytics.track('P-policy-reassigned', {
                    governanceID: this.revokePolicyFlowSharedData.selectedPolicy.governanceID
                });
            },
            error => {
                this.error = error;
            }
        );
        // this.next();
    }
}