<div *ngIf="!hideTableActions"
class="bg-white d-flex justify-content-end py-3 border-start border-end border-top">
    <div class="d-flex me-2">
        <app-shared-table-actions 
            [tableSettings]="tableSettings"
            (download)="downloadReport($event)"
            [loading]="downloading"
        ></app-shared-table-actions>
    </div>
</div>

<div class="table-panel" id="table-ack-records">
    <div *ngIf="!tableSettings.selectMode" appScrollShadow class="table-wrap table-overflow" id="table-ack-records">
        <app-smart-table [stickyLeft]="stickyLeft" [settings]="tableSettings" [source]="tableSource" tableDiv="table-ack-records"></app-smart-table>
    </div>
    <app-multi-select-table *ngIf="tableSettings.selectMode" [tableSettings]="tableSettings" [tableSource]="localTableSource" 
    [keyName]="'ackID'" (rowSelected)="selectedItems = $event" [stickyRight]="tableSettings.columns?.actions" [stickyLeft]="stickyLeft" divID="table-ack-records"
    itemName="requirement"
    >
        <button (click)="sendAckReminder()" class="btn btn-secondary text-white fw-semibold">Send Reminder</button>

    </app-multi-select-table>
</div>
