<div *ngIf="textToCopy" (click)="onButtonClick()" 
    [ngbTooltip]="list"
    class="cursor-pointer ms-2 copy-button d-flex justify-content-center" 
    [ngClass]="{'green-bg': isClicked}"
    (shown)="tooltipButtonText = 'Copy link'; isClicked = false" 
    [autoClose]="false"
    placement="bottom"
    >
    <ui-streamline-icon [slIcon]="{name: 'double-1-alternate', theme: 'bold'}"></ui-streamline-icon>
</div>

<ng-template #list>
    <div class="d-flex flex-column">
        <p class="text-white f-small">{{tooltipButtonText}}</p>
    </div>
</ng-template>