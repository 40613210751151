import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    private countries = [
        {
            'idCountry': '13',
            'countryCode': 'AU',
            'countryName': 'Australia',
            'isoAlpha3': 'AUS',
        },
        {
            'idCountry': '171',
            'countryCode': 'NZ',
            'countryName': 'New Zealand',
            'isoAlpha3': 'NZL',
        },
        {
            'idCountry': '77',
            'countryCode': 'GB',
            'countryName': 'United Kingdom',
            'isoAlpha3': 'GBR',
        },
        {
            'idCountry': '234',
            'countryCode': 'US',
            'countryName': 'United States',
            'isoAlpha3': 'USA',
        },
        {
            'idCountry': '0',
            'countryCode': '0',
            'countryName': '-------------------------',
            'isoAlpha3': '0',
        },
        {
            'idCountry': '3',
            'countryCode': 'AF',
            'countryName': 'Afghanistan',
            'isoAlpha3': 'AFG',
        }, {
            'idCountry': '15',
            'countryCode': 'AX',
            'countryName': '\u00c5land',
            'isoAlpha3': 'ALA',
        }, {
            'idCountry': '6',
            'countryCode': 'AL',
            'countryName': 'Albania',
            'isoAlpha3': 'ALB',
        }, {
            'idCountry': '62',
            'countryCode': 'DZ',
            'countryName': 'Algeria',
            'isoAlpha3': 'DZA',
        }, {
            'idCountry': '11',
            'countryCode': 'AS',
            'countryName': 'American Samoa',
            'isoAlpha3': 'ASM',
        }, {
            'idCountry': '1',
            'countryCode': 'AD',
            'countryName': 'Andorra',
            'isoAlpha3': 'AND',
        }, {
            'idCountry': '8',
            'countryCode': 'AO',
            'countryName': 'Angola',
            'isoAlpha3': 'AGO',
        }, {
            'idCountry': '5',
            'countryCode': 'AI',
            'countryName': 'Anguilla',
            'isoAlpha3': 'AIA',
        }, {
            'idCountry': '9',
            'countryCode': 'AQ',
            'countryName': 'Antarctica',
            'isoAlpha3': 'ATA',
        }, {
            'idCountry': '4',
            'countryCode': 'AG',
            'countryName': 'Antigua and Barbuda',
            'isoAlpha3': 'ATG',
        }, {
            'idCountry': '10',
            'countryCode': 'AR',
            'countryName': 'Argentina',
            'isoAlpha3': 'ARG',
        }, {
            'idCountry': '7',
            'countryCode': 'AM',
            'countryName': 'Armenia',
            'isoAlpha3': 'ARM',
        }, {
            'idCountry': '14',
            'countryCode': 'AW',
            'countryName': 'Aruba',
            'isoAlpha3': 'ABW',
        }, {
            'idCountry': '13',
            'countryCode': 'AU',
            'countryName': 'Australia',
            'isoAlpha3': 'AUS',
        }, {
            'idCountry': '12',
            'countryCode': 'AT',
            'countryName': 'Austria',
            'isoAlpha3': 'AUT',
        }, {
            'idCountry': '16',
            'countryCode': 'AZ',
            'countryName': 'Azerbaijan',
            'isoAlpha3': 'AZE',
        }, {
            'idCountry': '32',
            'countryCode': 'BS',
            'countryName': 'Bahamas',
            'isoAlpha3': 'BHS',
        }, {
            'idCountry': '23',
            'countryCode': 'BH',
            'countryName': 'Bahrain',
            'isoAlpha3': 'BHR',
        }, {
            'idCountry': '19',
            'countryCode': 'BD',
            'countryName': 'Bangladesh',
            'isoAlpha3': 'BGD',
        }, {
            'idCountry': '18',
            'countryCode': 'BB',
            'countryName': 'Barbados',
            'isoAlpha3': 'BRB',
        }, {
            'idCountry': '36',
            'countryCode': 'BY',
            'countryName': 'Belarus',
            'isoAlpha3': 'BLR',
        }, {
            'idCountry': '20',
            'countryCode': 'BE',
            'countryName': 'Belgium',
            'isoAlpha3': 'BEL',
        }, {
            'idCountry': '37',
            'countryCode': 'BZ',
            'countryName': 'Belize',
            'isoAlpha3': 'BLZ',
        }, {
            'idCountry': '25',
            'countryCode': 'BJ',
            'countryName': 'Benin',
            'isoAlpha3': 'BEN',
        }, {
            'idCountry': '27',
            'countryCode': 'BM',
            'countryName': 'Bermuda',
            'isoAlpha3': 'BMU',
        }, {
            'idCountry': '33',
            'countryCode': 'BT',
            'countryName': 'Bhutan',
            'isoAlpha3': 'BTN',
        }, {
            'idCountry': '29',
            'countryCode': 'BO',
            'countryName': 'Bolivia',
            'isoAlpha3': 'BOL',
        }, {
            'idCountry': '30',
            'countryCode': 'BQ',
            'countryName': 'Bonaire',
            'isoAlpha3': 'BES',
        }, {
            'idCountry': '17',
            'countryCode': 'BA',
            'countryName': 'Bosnia and Herzegovina',
            'isoAlpha3': 'BIH',
        }, {
            'idCountry': '35',
            'countryCode': 'BW',
            'countryName': 'Botswana',
            'isoAlpha3': 'BWA',
        }, {
            'idCountry': '34',
            'countryCode': 'BV',
            'countryName': 'Bouvet Island',
            'isoAlpha3': 'BVT',
        }, {
            'idCountry': '31',
            'countryCode': 'BR',
            'countryName': 'Brazil',
            'isoAlpha3': 'BRA',
        }, {
            'idCountry': '106',
            'countryCode': 'IO',
            'countryName': 'British Indian Ocean Territory',
            'isoAlpha3': 'IOT',
        }, {
            'idCountry': '240',
            'countryCode': 'VG',
            'countryName': 'British Virgin Islands',
            'isoAlpha3': 'VGB',
        }, {
            'idCountry': '28',
            'countryCode': 'BN',
            'countryName': 'Brunei',
            'isoAlpha3': 'BRN',
        }, {
            'idCountry': '22',
            'countryCode': 'BG',
            'countryName': 'Bulgaria',
            'isoAlpha3': 'BGR',
        }, {
            'idCountry': '21',
            'countryCode': 'BF',
            'countryName': 'Burkina Faso',
            'isoAlpha3': 'BFA',
        }, {
            'idCountry': '24',
            'countryCode': 'BI',
            'countryName': 'Burundi',
            'isoAlpha3': 'BDI',
        }, {
            'idCountry': '117',
            'countryCode': 'KH',
            'countryName': 'Cambodia',
            'isoAlpha3': 'KHM',
        }, {
            'idCountry': '47',
            'countryCode': 'CM',
            'countryName': 'Cameroon',
            'isoAlpha3': 'CMR',
        }, {
            'idCountry': '38',
            'countryCode': 'CA',
            'countryName': 'Canada',
            'isoAlpha3': 'CAN',
        }, {
            'idCountry': '52',
            'countryCode': 'CV',
            'countryName': 'Cape Verde',
            'isoAlpha3': 'CPV',
        }, {
            'idCountry': '124',
            'countryCode': 'KY',
            'countryName': 'Cayman Islands',
            'isoAlpha3': 'CYM',
        }, {
            'idCountry': '41',
            'countryCode': 'CF',
            'countryName': 'Central African Republic',
            'isoAlpha3': 'CAF',
        }, {
            'idCountry': '215',
            'countryCode': 'TD',
            'countryName': 'Chad',
            'isoAlpha3': 'TCD',
        }, {
            'idCountry': '46',
            'countryCode': 'CL',
            'countryName': 'Chile',
            'isoAlpha3': 'CHL',
        }, {
            'idCountry': '48',
            'countryCode': 'CN',
            'countryName': 'China',
            'isoAlpha3': 'CHN',
        }, {
            'idCountry': '54',
            'countryCode': 'CX',
            'countryName': 'Christmas Island',
            'isoAlpha3': 'CXR',
        }, {
            'idCountry': '39',
            'countryCode': 'CC',
            'countryName': 'Cocos [Keeling] Islands',
            'isoAlpha3': 'CCK',
        }, {
            'idCountry': '49',
            'countryCode': 'CO',
            'countryName': 'Colombia',
            'isoAlpha3': 'COL',
        }, {
            'idCountry': '119',
            'countryCode': 'KM',
            'countryName': 'Comoros',
            'isoAlpha3': 'COM',
        }, {
            'idCountry': '45',
            'countryCode': 'CK',
            'countryName': 'Cook Islands',
            'isoAlpha3': 'COK',
        }, {
            'idCountry': '50',
            'countryCode': 'CR',
            'countryName': 'Costa Rica',
            'isoAlpha3': 'CRI',
        }, {
            'idCountry': '98',
            'countryCode': 'HR',
            'countryName': 'Croatia',
            'isoAlpha3': 'HRV',
        }, {
            'idCountry': '51',
            'countryCode': 'CU',
            'countryName': 'Cuba',
            'isoAlpha3': 'CUB',
        }, {
            'idCountry': '53',
            'countryCode': 'CW',
            'countryName': 'Curacao',
            'isoAlpha3': 'CUW',
        }, {
            'idCountry': '55',
            'countryCode': 'CY',
            'countryName': 'Cyprus',
            'isoAlpha3': 'CYP',
        }, {
            'idCountry': '56',
            'countryCode': 'CZ',
            'countryName': 'Czechia',
            'isoAlpha3': 'CZE',
        }, {
            'idCountry': '40',
            'countryCode': 'CD',
            'countryName': 'Democratic Republic of the Congo',
            'isoAlpha3': 'COD',
        }, {
            'idCountry': '59',
            'countryCode': 'DK',
            'countryName': 'Denmark',
            'isoAlpha3': 'DNK',
        }, {
            'idCountry': '58',
            'countryCode': 'DJ',
            'countryName': 'Djibouti',
            'isoAlpha3': 'DJI',
        }, {
            'idCountry': '60',
            'countryCode': 'DM',
            'countryName': 'Dominica',
            'isoAlpha3': 'DMA',
        }, {
            'idCountry': '61',
            'countryCode': 'DO',
            'countryName': 'Dominican Republic',
            'isoAlpha3': 'DOM',
        }, {
            'idCountry': '222',
            'countryCode': 'TL',
            'countryName': 'East Timor',
            'isoAlpha3': 'TLS',
        }, {
            'idCountry': '221',
            'countryCode': 'TL',
            'countryName': 'East Timor',
            'isoAlpha3': 'TLS',
        }, {
            'idCountry': '63',
            'countryCode': 'EC',
            'countryName': 'Ecuador',
            'isoAlpha3': 'ECU',
        }, {
            'idCountry': '65',
            'countryCode': 'EG',
            'countryName': 'Egypt',
            'isoAlpha3': 'EGY',
        }, {
            'idCountry': '210',
            'countryCode': 'SV',
            'countryName': 'El Salvador',
            'isoAlpha3': 'SLV',
        }, {
            'idCountry': '88',
            'countryCode': 'GQ',
            'countryName': 'Equatorial Guinea',
            'isoAlpha3': 'GNQ',
        }, {
            'idCountry': '67',
            'countryCode': 'ER',
            'countryName': 'Eritrea',
            'isoAlpha3': 'ERI',
        }, {
            'idCountry': '64',
            'countryCode': 'EE',
            'countryName': 'Estonia',
            'isoAlpha3': 'EST',
        }, {
            'idCountry': '69',
            'countryCode': 'ET',
            'countryName': 'Ethiopia',
            'isoAlpha3': 'ETH',
        }, {
            'idCountry': '72',
            'countryCode': 'FK',
            'countryName': 'Falkland Islands',
            'isoAlpha3': 'FLK',
        }, {
            'idCountry': '74',
            'countryCode': 'FO',
            'countryName': 'Faroe Islands',
            'isoAlpha3': 'FRO',
        }, {
            'idCountry': '71',
            'countryCode': 'FJ',
            'countryName': 'Fiji',
            'isoAlpha3': 'FJI',
        }, {
            'idCountry': '70',
            'countryCode': 'FI',
            'countryName': 'Finland',
            'isoAlpha3': 'FIN',
        }, {
            'idCountry': '75',
            'countryCode': 'FR',
            'countryName': 'France',
            'isoAlpha3': 'FRA',
        }, {
            'idCountry': '80',
            'countryCode': 'GF',
            'countryName': 'French Guiana',
            'isoAlpha3': 'GUF',
        }, {
            'idCountry': '175',
            'countryCode': 'PF',
            'countryName': 'French Polynesia',
            'isoAlpha3': 'PYF',
        }, {
            'idCountry': '216',
            'countryCode': 'TF',
            'countryName': 'French Southern Territories',
            'isoAlpha3': 'ATF',
        }, {
            'idCountry': '76',
            'countryCode': 'GA',
            'countryName': 'Gabon',
            'isoAlpha3': 'GAB',
        }, {
            'idCountry': '85',
            'countryCode': 'GM',
            'countryName': 'Gambia',
            'isoAlpha3': 'GMB',
        }, {
            'idCountry': '79',
            'countryCode': 'GE',
            'countryName': 'Georgia',
            'isoAlpha3': 'GEO',
        }, {
            'idCountry': '57',
            'countryCode': 'DE',
            'countryName': 'Germany',
            'isoAlpha3': 'DEU',
        }, {
            'idCountry': '82',
            'countryCode': 'GH',
            'countryName': 'Ghana',
            'isoAlpha3': 'GHA',
        }, {
            'idCountry': '83',
            'countryCode': 'GI',
            'countryName': 'Gibraltar',
            'isoAlpha3': 'GIB',
        }, {
            'idCountry': '89',
            'countryCode': 'GR',
            'countryName': 'Greece',
            'isoAlpha3': 'GRC',
        }, {
            'idCountry': '84',
            'countryCode': 'GL',
            'countryName': 'Greenland',
            'isoAlpha3': 'GRL',
        }, {
            'idCountry': '78',
            'countryCode': 'GD',
            'countryName': 'Grenada',
            'isoAlpha3': 'GRD',
        }, {
            'idCountry': '87',
            'countryCode': 'GP',
            'countryName': 'Guadeloupe',
            'isoAlpha3': 'GLP',
        }, {
            'idCountry': '92',
            'countryCode': 'GU',
            'countryName': 'Guam',
            'isoAlpha3': 'GUM',
        }, {
            'idCountry': '91',
            'countryCode': 'GT',
            'countryName': 'Guatemala',
            'isoAlpha3': 'GTM',
        }, {
            'idCountry': '81',
            'countryCode': 'GG',
            'countryName': 'Guernsey',
            'isoAlpha3': 'GGY',
        }, {
            'idCountry': '86',
            'countryCode': 'GN',
            'countryName': 'Guinea',
            'isoAlpha3': 'GIN',
        }, {
            'idCountry': '93',
            'countryCode': 'GW',
            'countryName': 'Guinea-Bissau',
            'isoAlpha3': 'GNB',
        }, {
            'idCountry': '94',
            'countryCode': 'GY',
            'countryName': 'Guyana',
            'isoAlpha3': 'GUY',
        }, {
            'idCountry': '99',
            'countryCode': 'HT',
            'countryName': 'Haiti',
            'isoAlpha3': 'HTI',
        }, {
            'idCountry': '96',
            'countryCode': 'HM',
            'countryName': 'Heard Island and McDonald Islands',
            'isoAlpha3': 'HMD',
        }, {
            'idCountry': '97',
            'countryCode': 'HN',
            'countryName': 'Honduras',
            'isoAlpha3': 'HND',
        }, {
            'idCountry': '95',
            'countryCode': 'HK',
            'countryName': 'Hong Kong',
            'isoAlpha3': 'HKG',
        }, {
            'idCountry': '100',
            'countryCode': 'HU',
            'countryName': 'Hungary',
            'isoAlpha3': 'HUN',
        }, {
            'idCountry': '109',
            'countryCode': 'IS',
            'countryName': 'Iceland',
            'isoAlpha3': 'ISL',
        }, {
            'idCountry': '105',
            'countryCode': 'IN',
            'countryName': 'India',
            'isoAlpha3': 'IND',
        }, {
            'idCountry': '101',
            'countryCode': 'ID',
            'countryName': 'Indonesia',
            'isoAlpha3': 'IDN',
        }, {
            'idCountry': '108',
            'countryCode': 'IR',
            'countryName': 'Iran',
            'isoAlpha3': 'IRN',
        }, {
            'idCountry': '107',
            'countryCode': 'IQ',
            'countryName': 'Iraq',
            'isoAlpha3': 'IRQ',
        }, {
            'idCountry': '102',
            'countryCode': 'IE',
            'countryName': 'Ireland',
            'isoAlpha3': 'IRL',
        }, {
            'idCountry': '104',
            'countryCode': 'IM',
            'countryName': 'Isle of Man',
            'isoAlpha3': 'IMN',
        }, {
            'idCountry': '103',
            'countryCode': 'IL',
            'countryName': 'Israel',
            'isoAlpha3': 'ISR',
        }, {
            'idCountry': '110',
            'countryCode': 'IT',
            'countryName': 'Italy',
            'isoAlpha3': 'ITA',
        }, {
            'idCountry': '44',
            'countryCode': 'CI',
            'countryName': 'Ivory Coast',
            'isoAlpha3': 'CIV',
        }, {
            'idCountry': '112',
            'countryCode': 'JM',
            'countryName': 'Jamaica',
            'isoAlpha3': 'JAM',
        }, {
            'idCountry': '114',
            'countryCode': 'JP',
            'countryName': 'Japan',
            'isoAlpha3': 'JPN',
        }, {
            'idCountry': '111',
            'countryCode': 'JE',
            'countryName': 'Jersey',
            'isoAlpha3': 'JEY',
        }, {
            'idCountry': '113',
            'countryCode': 'JO',
            'countryName': 'Jordan',
            'isoAlpha3': 'JOR',
        }, {
            'idCountry': '125',
            'countryCode': 'KZ',
            'countryName': 'Kazakhstan',
            'isoAlpha3': 'KAZ',
        }, {
            'idCountry': '115',
            'countryCode': 'KE',
            'countryName': 'Kenya',
            'isoAlpha3': 'KEN',
        }, {
            'idCountry': '118',
            'countryCode': 'KI',
            'countryName': 'Kiribati',
            'isoAlpha3': 'KIR',
        }, {
            'idCountry': '246',
            'countryCode': 'XK',
            'countryName': 'Kosovo',
            'isoAlpha3': 'XKX',
        }, {
            'idCountry': '123',
            'countryCode': 'KW',
            'countryName': 'Kuwait',
            'isoAlpha3': 'KWT',
        }, {
            'idCountry': '116',
            'countryCode': 'KG',
            'countryName': 'Kyrgyzstan',
            'isoAlpha3': 'KGZ',
        }, {
            'idCountry': '126',
            'countryCode': 'LA',
            'countryName': 'Laos',
            'isoAlpha3': 'LAO',
        }, {
            'idCountry': '135',
            'countryCode': 'LV',
            'countryName': 'Latvia',
            'isoAlpha3': 'LVA',
        }, {
            'idCountry': '127',
            'countryCode': 'LB',
            'countryName': 'Lebanon',
            'isoAlpha3': 'LBN',
        }, {
            'idCountry': '132',
            'countryCode': 'LS',
            'countryName': 'Lesotho',
            'isoAlpha3': 'LSO',
        }, {
            'idCountry': '131',
            'countryCode': 'LR',
            'countryName': 'Liberia',
            'isoAlpha3': 'LBR',
        }, {
            'idCountry': '136',
            'countryCode': 'LY',
            'countryName': 'Libya',
            'isoAlpha3': 'LBY',
        }, {
            'idCountry': '129',
            'countryCode': 'LI',
            'countryName': 'Liechtenstein',
            'isoAlpha3': 'LIE',
        }, {
            'idCountry': '133',
            'countryCode': 'LT',
            'countryName': 'Lithuania',
            'isoAlpha3': 'LTU',
        }, {
            'idCountry': '134',
            'countryCode': 'LU',
            'countryName': 'Luxembourg',
            'isoAlpha3': 'LUX',
        }, {
            'idCountry': '148',
            'countryCode': 'MO',
            'countryName': 'Macao',
            'isoAlpha3': 'MAC',
        }, {
            'idCountry': '144',
            'countryCode': 'MK',
            'countryName': 'Macedonia',
            'isoAlpha3': 'MKD',
        }, {
            'idCountry': '142',
            'countryCode': 'MG',
            'countryName': 'Madagascar',
            'isoAlpha3': 'MDG',
        }, {
            'idCountry': '156',
            'countryCode': 'MW',
            'countryName': 'Malawi',
            'isoAlpha3': 'MWI',
        }, {
            'idCountry': '158',
            'countryCode': 'MY',
            'countryName': 'Malaysia',
            'isoAlpha3': 'MYS',
        }, {
            'idCountry': '155',
            'countryCode': 'MV',
            'countryName': 'Maldives',
            'isoAlpha3': 'MDV',
        }, {
            'idCountry': '145',
            'countryCode': 'ML',
            'countryName': 'Mali',
            'isoAlpha3': 'MLI',
        }, {
            'idCountry': '153',
            'countryCode': 'MT',
            'countryName': 'Malta',
            'isoAlpha3': 'MLT',
        }, {
            'idCountry': '143',
            'countryCode': 'MH',
            'countryName': 'Marshall Islands',
            'isoAlpha3': 'MHL',
        }, {
            'idCountry': '150',
            'countryCode': 'MQ',
            'countryName': 'Martinique',
            'isoAlpha3': 'MTQ',
        }, {
            'idCountry': '151',
            'countryCode': 'MR',
            'countryName': 'Mauritania',
            'isoAlpha3': 'MRT',
        }, {
            'idCountry': '154',
            'countryCode': 'MU',
            'countryName': 'Mauritius',
            'isoAlpha3': 'MUS',
        }, {
            'idCountry': '248',
            'countryCode': 'YT',
            'countryName': 'Mayotte',
            'isoAlpha3': 'MYT',
        }, {
            'idCountry': '157',
            'countryCode': 'MX',
            'countryName': 'Mexico',
            'isoAlpha3': 'MEX',
        }, {
            'idCountry': '73',
            'countryCode': 'FM',
            'countryName': 'Micronesia',
            'isoAlpha3': 'FSM',
        }, {
            'idCountry': '139',
            'countryCode': 'MD',
            'countryName': 'Moldova',
            'isoAlpha3': 'MDA',
        }, {
            'idCountry': '138',
            'countryCode': 'MC',
            'countryName': 'Monaco',
            'isoAlpha3': 'MCO',
        }, {
            'idCountry': '147',
            'countryCode': 'MN',
            'countryName': 'Mongolia',
            'isoAlpha3': 'MNG',
        }, {
            'idCountry': '140',
            'countryCode': 'ME',
            'countryName': 'Montenegro',
            'isoAlpha3': 'MNE',
        }, {
            'idCountry': '152',
            'countryCode': 'MS',
            'countryName': 'Montserrat',
            'isoAlpha3': 'MSR',
        }, {
            'idCountry': '137',
            'countryCode': 'MA',
            'countryName': 'Morocco',
            'isoAlpha3': 'MAR',
        }, {
            'idCountry': '159',
            'countryCode': 'MZ',
            'countryName': 'Mozambique',
            'isoAlpha3': 'MOZ',
        }, {
            'idCountry': '146',
            'countryCode': 'MM',
            'countryName': 'Myanmar [Burma]',
            'isoAlpha3': 'MMR',
        }, {
            'idCountry': '160',
            'countryCode': 'NA',
            'countryName': 'Namibia',
            'isoAlpha3': 'NAM',
        }, {
            'idCountry': '169',
            'countryCode': 'NR',
            'countryName': 'Nauru',
            'isoAlpha3': 'NRU',
        }, {
            'idCountry': '168',
            'countryCode': 'NP',
            'countryName': 'Nepal',
            'isoAlpha3': 'NPL',
        }, {
            'idCountry': '166',
            'countryCode': 'NL',
            'countryName': 'Netherlands',
            'isoAlpha3': 'NLD',
        }, {
            'idCountry': '161',
            'countryCode': 'NC',
            'countryName': 'New Caledonia',
            'isoAlpha3': 'NCL',
        }, {
            'idCountry': '171',
            'countryCode': 'NZ',
            'countryName': 'New Zealand',
            'isoAlpha3': 'NZL',
        }, {
            'idCountry': '165',
            'countryCode': 'NI',
            'countryName': 'Nicaragua',
            'isoAlpha3': 'NIC',
        }, {
            'idCountry': '162',
            'countryCode': 'NE',
            'countryName': 'Niger',
            'isoAlpha3': 'NER',
        }, {
            'idCountry': '164',
            'countryCode': 'NG',
            'countryName': 'Nigeria',
            'isoAlpha3': 'NGA',
        }, {
            'idCountry': '170',
            'countryCode': 'NU',
            'countryName': 'Niue',
            'isoAlpha3': 'NIU',
        }, {
            'idCountry': '163',
            'countryCode': 'NF',
            'countryName': 'Norfolk Island',
            'isoAlpha3': 'NFK',
        }, {
            'idCountry': '121',
            'countryCode': 'KP',
            'countryName': 'North Korea',
            'isoAlpha3': 'PRK',
        }, {
            'idCountry': '149',
            'countryCode': 'MP',
            'countryName': 'Northern Mariana Islands',
            'isoAlpha3': 'MNP',
        }, {
            'idCountry': '167',
            'countryCode': 'NO',
            'countryName': 'Norway',
            'isoAlpha3': 'NOR',
        }, {
            'idCountry': '172',
            'countryCode': 'OM',
            'countryName': 'Oman',
            'isoAlpha3': 'OMN',
        }, {
            'idCountry': '178',
            'countryCode': 'PK',
            'countryName': 'Pakistan',
            'isoAlpha3': 'PAK',
        }, {
            'idCountry': '185',
            'countryCode': 'PW',
            'countryName': 'Palau',
            'isoAlpha3': 'PLW',
        }, {
            'idCountry': '183',
            'countryCode': 'PS',
            'countryName': 'Palestine',
            'isoAlpha3': 'PSE',
        }, {
            'idCountry': '173',
            'countryCode': 'PA',
            'countryName': 'Panama',
            'isoAlpha3': 'PAN',
        }, {
            'idCountry': '176',
            'countryCode': 'PG',
            'countryName': 'Papua New Guinea',
            'isoAlpha3': 'PNG',
        }, {
            'idCountry': '186',
            'countryCode': 'PY',
            'countryName': 'Paraguay',
            'isoAlpha3': 'PRY',
        }, {
            'idCountry': '174',
            'countryCode': 'PE',
            'countryName': 'Peru',
            'isoAlpha3': 'PER',
        }, {
            'idCountry': '177',
            'countryCode': 'PH',
            'countryName': 'Philippines',
            'isoAlpha3': 'PHL',
        }, {
            'idCountry': '181',
            'countryCode': 'PN',
            'countryName': 'Pitcairn Islands',
            'isoAlpha3': 'PCN',
        }, {
            'idCountry': '179',
            'countryCode': 'PL',
            'countryName': 'Poland',
            'isoAlpha3': 'POL',
        }, {
            'idCountry': '184',
            'countryCode': 'PT',
            'countryName': 'Portugal',
            'isoAlpha3': 'PRT',
        }, {
            'idCountry': '182',
            'countryCode': 'PR',
            'countryName': 'Puerto Rico',
            'isoAlpha3': 'PRI',
        }, {
            'idCountry': '187',
            'countryCode': 'QA',
            'countryName': 'Qatar',
            'isoAlpha3': 'QAT',
        }, {
            'idCountry': '42',
            'countryCode': 'CG',
            'countryName': 'Republic of the Congo',
            'isoAlpha3': 'COG',
        }, {
            'idCountry': '188',
            'countryCode': 'RE',
            'countryName': 'R\u00e9union',
            'isoAlpha3': 'REU',
        }, {
            'idCountry': '189',
            'countryCode': 'RO',
            'countryName': 'Romania',
            'isoAlpha3': 'ROU',
        }, {
            'idCountry': '191',
            'countryCode': 'RU',
            'countryName': 'Russia',
            'isoAlpha3': 'RUS',
        }, {
            'idCountry': '192',
            'countryCode': 'RW',
            'countryName': 'Rwanda',
            'isoAlpha3': 'RWA',
        }, {
            'idCountry': '26',
            'countryCode': 'BL',
            'countryName': 'Saint Barth\u00e9lemy',
            'isoAlpha3': 'BLM',
        }, {
            'idCountry': '199',
            'countryCode': 'SH',
            'countryName': 'Saint Helena',
            'isoAlpha3': 'SHN',
        }, {
            'idCountry': '120',
            'countryCode': 'KN',
            'countryName': 'Saint Kitts and Nevis',
            'isoAlpha3': 'KNA',
        }, {
            'idCountry': '128',
            'countryCode': 'LC',
            'countryName': 'Saint Lucia',
            'isoAlpha3': 'LCA',
        }, {
            'idCountry': '141',
            'countryCode': 'MF',
            'countryName': 'Saint Martin',
            'isoAlpha3': 'MAF',
        }, {
            'idCountry': '180',
            'countryCode': 'PM',
            'countryName': 'Saint Pierre and Miquelon',
            'isoAlpha3': 'SPM',
        }, {
            'idCountry': '238',
            'countryCode': 'VC',
            'countryName': 'Saint Vincent and the Grenadines',
            'isoAlpha3': 'VCT',
        }, {
            'idCountry': '245',
            'countryCode': 'WS',
            'countryName': 'Samoa',
            'isoAlpha3': 'WSM',
        }, {
            'idCountry': '204',
            'countryCode': 'SM',
            'countryName': 'San Marino',
            'isoAlpha3': 'SMR',
        }, {
            'idCountry': '209',
            'countryCode': 'ST',
            'countryName': 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
            'isoAlpha3': 'STP',
        }, {
            'idCountry': '193',
            'countryCode': 'SA',
            'countryName': 'Saudi Arabia',
            'isoAlpha3': 'SAU',
        }, {
            'idCountry': '205',
            'countryCode': 'SN',
            'countryName': 'Senegal',
            'isoAlpha3': 'SEN',
        }, {
            'idCountry': '190',
            'countryCode': 'RS',
            'countryName': 'Serbia',
            'isoAlpha3': 'SRB',
        }, {
            'idCountry': '195',
            'countryCode': 'SC',
            'countryName': 'Seychelles',
            'isoAlpha3': 'SYC',
        }, {
            'idCountry': '203',
            'countryCode': 'SL',
            'countryName': 'Sierra Leone',
            'isoAlpha3': 'SLE',
        }, {
            'idCountry': '198',
            'countryCode': 'SG',
            'countryName': 'Singapore',
            'isoAlpha3': 'SGP',
        }, {
            'idCountry': '211',
            'countryCode': 'SX',
            'countryName': 'Sint Maarten',
            'isoAlpha3': 'SXM',
        }, {
            'idCountry': '202',
            'countryCode': 'SK',
            'countryName': 'Slovakia',
            'isoAlpha3': 'SVK',
        }, {
            'idCountry': '200',
            'countryCode': 'SI',
            'countryName': 'Slovenia',
            'isoAlpha3': 'SVN',
        }, {
            'idCountry': '194',
            'countryCode': 'SB',
            'countryName': 'Solomon Islands',
            'isoAlpha3': 'SLB',
        }, {
            'idCountry': '206',
            'countryCode': 'SO',
            'countryName': 'Somalia',
            'isoAlpha3': 'SOM',
        }, {
            'idCountry': '249',
            'countryCode': 'ZA',
            'countryName': 'South Africa',
            'isoAlpha3': 'ZAF',
        }, {
            'idCountry': '90',
            'countryCode': 'GS',
            'countryName': 'South Georgia and the South Sandwich Islands',
            'isoAlpha3': 'SGS',
        }, {
            'idCountry': '122',
            'countryCode': 'KR',
            'countryName': 'South Korea',
            'isoAlpha3': 'KOR',
        }, {
            'idCountry': '208',
            'countryCode': 'SS',
            'countryName': 'South Sudan',
            'isoAlpha3': 'SSD',
        }, {
            'idCountry': '68',
            'countryCode': 'ES',
            'countryName': 'Spain',
            'isoAlpha3': 'ESP',
        }, {
            'idCountry': '130',
            'countryCode': 'LK',
            'countryName': 'Sri Lanka',
            'isoAlpha3': 'LKA',
        }, {
            'idCountry': '196',
            'countryCode': 'SD',
            'countryName': 'Sudan',
            'isoAlpha3': 'SDN',
        }, {
            'idCountry': '207',
            'countryCode': 'SR',
            'countryName': 'Suriname',
            'isoAlpha3': 'SUR',
        }, {
            'idCountry': '201',
            'countryCode': 'SJ',
            'countryName': 'Svalbard and Jan Mayen',
            'isoAlpha3': 'SJM',
        }, {
            'idCountry': '213',
            'countryCode': 'SZ',
            'countryName': 'Swaziland',
            'isoAlpha3': 'SWZ',
        }, {
            'idCountry': '197',
            'countryCode': 'SE',
            'countryName': 'Sweden',
            'isoAlpha3': 'SWE',
        }, {
            'idCountry': '43',
            'countryCode': 'CH',
            'countryName': 'Switzerland',
            'isoAlpha3': 'CHE',
        }, {
            'idCountry': '212',
            'countryCode': 'SY',
            'countryName': 'Syria',
            'isoAlpha3': 'SYR',
        }, {
            'idCountry': '229',
            'countryCode': 'TW',
            'countryName': 'Taiwan',
            'isoAlpha3': 'TWN',
        }, {
            'idCountry': '219',
            'countryCode': 'TJ',
            'countryName': 'Tajikistan',
            'isoAlpha3': 'TJK',
        }, {
            'idCountry': '230',
            'countryCode': 'TZ',
            'countryName': 'Tanzania',
            'isoAlpha3': 'TZA',
        }, {
            'idCountry': '218',
            'countryCode': 'TH',
            'countryName': 'Thailand',
            'isoAlpha3': 'THA',
        }, {
            'idCountry': '217',
            'countryCode': 'TG',
            'countryName': 'Togo',
            'isoAlpha3': 'TGO',
        }, {
            'idCountry': '220',
            'countryCode': 'TK',
            'countryName': 'Tokelau',
            'isoAlpha3': 'TKL',
        }, {
            'idCountry': '225',
            'countryCode': 'TO',
            'countryName': 'Tonga',
            'isoAlpha3': 'TON',
        }, {
            'idCountry': '227',
            'countryCode': 'TT',
            'countryName': 'Trinidad and Tobago',
            'isoAlpha3': 'TTO',
        }, {
            'idCountry': '224',
            'countryCode': 'TN',
            'countryName': 'Tunisia',
            'isoAlpha3': 'TUN',
        }, {
            'idCountry': '226',
            'countryCode': 'TR',
            'countryName': 'Turkey',
            'isoAlpha3': 'TUR',
        }, {
            'idCountry': '223',
            'countryCode': 'TM',
            'countryName': 'Turkmenistan',
            'isoAlpha3': 'TKM',
        }, {
            'idCountry': '214',
            'countryCode': 'TC',
            'countryName': 'Turks and Caicos Islands',
            'isoAlpha3': 'TCA',
        }, {
            'idCountry': '228',
            'countryCode': 'TV',
            'countryName': 'Tuvalu',
            'isoAlpha3': 'TUV',
        }, {
            'idCountry': '233',
            'countryCode': 'UM',
            'countryName': 'U.S. Minor Outlying Islands',
            'isoAlpha3': 'UMI',
        }, {
            'idCountry': '241',
            'countryCode': 'VI',
            'countryName': 'U.S. Virgin Islands',
            'isoAlpha3': 'VIR',
        }, {
            'idCountry': '232',
            'countryCode': 'UG',
            'countryName': 'Uganda',
            'isoAlpha3': 'UGA',
        }, {
            'idCountry': '231',
            'countryCode': 'UA',
            'countryName': 'Ukraine',
            'isoAlpha3': 'UKR',
        }, {
            'idCountry': '2',
            'countryCode': 'AE',
            'countryName': 'United Arab Emirates',
            'isoAlpha3': 'ARE',
        }, {
            'idCountry': '77',
            'countryCode': 'GB',
            'countryName': 'United Kingdom',
            'isoAlpha3': 'GBR',
        }, {
            'idCountry': '234',
            'countryCode': 'US',
            'countryName': 'United States',
            'isoAlpha3': 'USA',
        }, {
            'idCountry': '235',
            'countryCode': 'UY',
            'countryName': 'Uruguay',
            'isoAlpha3': 'URY',
        }, {
            'idCountry': '236',
            'countryCode': 'UZ',
            'countryName': 'Uzbekistan',
            'isoAlpha3': 'UZB',
        }, {
            'idCountry': '243',
            'countryCode': 'VU',
            'countryName': 'Vanuatu',
            'isoAlpha3': 'VUT',
        }, {
            'idCountry': '237',
            'countryCode': 'VA',
            'countryName': 'Vatican City',
            'isoAlpha3': 'VAT',
        }, {
            'idCountry': '239',
            'countryCode': 'VE',
            'countryName': 'Venezuela',
            'isoAlpha3': 'VEN',
        }, {
            'idCountry': '242',
            'countryCode': 'VN',
            'countryName': 'Vietnam',
            'isoAlpha3': 'VNM',
        }, {
            'idCountry': '244',
            'countryCode': 'WF',
            'countryName': 'Wallis and Futuna',
            'isoAlpha3': 'WLF',
        }, {
            'idCountry': '66',
            'countryCode': 'EH',
            'countryName': 'Western Sahara',
            'isoAlpha3': 'ESH',
        }, {
            'idCountry': '247',
            'countryCode': 'YE',
            'countryName': 'Yemen',
            'isoAlpha3': 'YEM',
        }, {
            'idCountry': '250',
            'countryCode': 'ZM',
            'countryName': 'Zambia',
            'isoAlpha3': 'ZMB',
        }, {
            'idCountry': '251',
            'countryCode': 'ZW',
            'countryName': 'Zimbabwe',
            'isoAlpha3': 'ZWE',
        }];

    private states = [{
        'key': 'AFG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ALB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DZA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ASM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'AND',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'AGO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'AIA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ATA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ATG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ARG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ARM',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'ARM',
        'value': 'AG',
        'text': 'Aragatsotn',
    }, {
        'key': 'ARM',
        'value': 'AR',
        'text': 'Ararat',
    }, {
        'key': 'ARM',
        'value': 'AV',
        'text': 'Armavir',
    }, {
        'key': 'ARM',
        'value': 'GR',
        'text': 'Gegharkunik',
    }, {
        'key': 'ARM',
        'value': 'KT',
        'text': 'Kotayk',
    }, {
        'key': 'ARM',
        'value': 'LO',
        'text': 'Lori',
    }, {
        'key': 'ARM',
        'value': 'SH',
        'text': 'Shirak',
    }, {
        'key': 'ARM',
        'value': 'SU',
        'text': 'Syunik',
    }, {
        'key': 'ARM',
        'value': 'TV',
        'text': 'Tavush',
    }, {
        'key': 'ARM',
        'value': 'VD',
        'text': 'Vayots-Dzor',
    }, {
        'key': 'ARM',
        'value': 'ER',
        'text': 'Yerevan',
    }, {
        'key': 'ABW',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'AUS',
        'value': '',
        'text': 'Select Your State\/Territory',
    }, {
        'key': 'AUS',
        'value': 'AT',
        'text': 'Australian Capital Territory',
    }, {
        'key': 'AUS',
        'value': 'NW',
        'text': 'New South Wales',
    }, {
        'key': 'AUS',
        'value': 'NT',
        'text': 'Northern Territory',
    }, {
        'key': 'AUS',
        'value': 'QL',
        'text': 'Queensland',
    }, {
        'key': 'AUS',
        'value': 'SA',
        'text': 'South Australia',
    }, {
        'key': 'AUS',
        'value': 'TA',
        'text': 'Tasmania',
    }, {
        'key': 'AUS',
        'value': 'VI',
        'text': 'Victoria',
    }, {
        'key': 'AUS',
        'value': 'WA',
        'text': 'Western Australia',
    }, {
        'key': 'AUT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'AZE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BHS',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BHR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BGD',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BRB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BLR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BEL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BLZ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BEN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BMU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BTN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BOL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BIH',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BWA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BVT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BRA',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'BRA',
        'value': 'AC',
        'text': 'Acre',
    }, {
        'key': 'BRA',
        'value': 'AL',
        'text': 'Alagoas',
    }, {
        'key': 'BRA',
        'value': 'AP',
        'text': 'Amap\u00e2\u02c6\u0161\u00c2\u00b0',
    }, {
        'key': 'BRA',
        'value': 'AM',
        'text': 'Amazonas',
    }, {
        'key': 'BRA',
        'value': 'BA',
        'text': 'Bah\u00e2\u02c6\u0161\u00e2\u2030\u00a0a',
    }, {
        'key': 'BRA',
        'value': 'CE',
        'text': 'Cear\u00e2\u02c6\u0161\u00c2\u00b0',
    }, {
        'key': 'BRA',
        'value': 'DF',
        'text': 'Distrito Federal',
    }, {
        'key': 'BRA',
        'value': 'ES',
        'text': 'Espirito Santo',
    }, {
        'key': 'BRA',
        'value': 'GO',
        'text': 'Goi\u00e2\u02c6\u0161\u00c2\u00b0s',
    }, {
        'key': 'BRA',
        'value': 'MA',
        'text': 'Maranh\u00e2\u02c6\u0161\u00c2\u00a3o',
    }, {
        'key': 'BRA',
        'value': 'MT',
        'text': 'Mato Grosso',
    }, {
        'key': 'BRA',
        'value': 'MS',
        'text': 'Mato Grosso do Sul',
    }, {
        'key': 'BRA',
        'value': 'MG',
        'text': 'Minas Gera\u00e2\u02c6\u0161\u00e2\u2030\u00a0s',
    }, {
        'key': 'BRA',
        'value': 'PA',
        'text': 'Par\u00e2\u02c6\u0161\u00c2\u00b0',
    }, {
        'key': 'BRA',
        'value': 'PB',
        'text': 'Para\u00e2\u02c6\u0161\u00e2\u2030\u00a0ba',
    }, {
        'key': 'BRA',
        'value': 'PR',
        'text': 'Paran\u00e2\u02c6\u0161\u00c2\u00b0',
    }, {
        'key': 'BRA',
        'value': 'PE',
        'text': 'Pernambuco',
    }, {
        'key': 'BRA',
        'value': 'PI',
        'text': 'Piau\u00e2\u02c6\u0161\u00e2\u2030\u00a0',
    }, {
        'key': 'BRA',
        'value': 'RJ',
        'text': 'Rio de Janeiro',
    }, {
        'key': 'BRA',
        'value': 'RN',
        'text': 'Rio Grande do Norte',
    }, {
        'key': 'BRA',
        'value': 'RS',
        'text': 'Rio Grande do Sul',
    }, {
        'key': 'BRA',
        'value': 'RO',
        'text': 'Rond\u00e2\u02c6\u0161\u00c2\u00a5nia',
    }, {
        'key': 'BRA',
        'value': 'RR',
        'text': 'Roraima',
    }, {
        'key': 'BRA',
        'value': 'SC',
        'text': 'Santa Catarina',
    }, {
        'key': 'BRA',
        'value': 'SP',
        'text': 'S\u00e2\u02c6\u0161\u00c2\u00a3o Paulo',
    }, {
        'key': 'BRA',
        'value': 'SE',
        'text': 'Sergipe',
    }, {
        'key': 'BRA',
        'value': 'TO',
        'text': 'Tocantins',
    }, {
        'key': 'IOT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BRN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BGR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BFA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'BDI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KHM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CMR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CAN',
        'value': '',
        'text': 'Select Your Province\/Territory',
    }, {
        'key': 'CAN',
        'value': 'AB',
        'text': 'Alberta',
    }, {
        'key': 'CAN',
        'value': 'BC',
        'text': 'British Columbia',
    }, {
        'key': 'CAN',
        'value': 'MB',
        'text': 'Manitoba',
    }, {
        'key': 'CAN',
        'value': 'NB',
        'text': 'New Brunswick',
    }, {
        'key': 'CAN',
        'value': 'NL',
        'text': 'Newfoundland and Labrador',
    }, {
        'key': 'CAN',
        'value': 'NT',
        'text': 'Northwest Territories',
    }, {
        'key': 'CAN',
        'value': 'NS',
        'text': 'Nova Scotia',
    }, {
        'key': 'CAN',
        'value': 'NU',
        'text': 'Nunavut',
    }, {
        'key': 'CAN',
        'value': 'ON',
        'text': 'Ontario',
    }, {
        'key': 'CAN',
        'value': 'PE',
        'text': 'Prince Edward Island',
    }, {
        'key': 'CAN',
        'value': 'QC',
        'text': 'Quebec',
    }, {
        'key': 'CAN',
        'value': 'SK',
        'text': 'Saskatchewan',
    }, {
        'key': 'CAN',
        'value': 'YT',
        'text': 'Yukon',
    }, {
        'key': 'CPV',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CYM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CAF',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TCD',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CHL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CHN',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'CHN',
        'value': '34',
        'text': 'Anhui',
    }, {
        'key': 'CHN',
        'value': '11',
        'text': 'Beijing',
    }, {
        'key': 'CHN',
        'value': '50',
        'text': 'Chongqing',
    }, {
        'key': 'CHN',
        'value': '35',
        'text': 'Fujian',
    }, {
        'key': 'CHN',
        'value': '62',
        'text': 'Gansu',
    }, {
        'key': 'CHN',
        'value': '44',
        'text': 'Guangdong',
    }, {
        'key': 'CHN',
        'value': '45',
        'text': 'Guangxi Zhuang',
    }, {
        'key': 'CHN',
        'value': '52',
        'text': 'Guizhou',
    }, {
        'key': 'CHN',
        'value': '46',
        'text': 'Hainan',
    }, {
        'key': 'CHN',
        'value': '13',
        'text': 'Hebei',
    }, {
        'key': 'CHN',
        'value': '23',
        'text': 'Heilongjiang',
    }, {
        'key': 'CHN',
        'value': '41',
        'text': 'Henan',
    }, {
        'key': 'CHN',
        'value': '42',
        'text': 'Hubei',
    }, {
        'key': 'CHN',
        'value': '43',
        'text': 'Hunan',
    }, {
        'key': 'CHN',
        'value': '32',
        'text': 'Jiangsu',
    }, {
        'key': 'CHN',
        'value': '36',
        'text': 'Jiangxi',
    }, {
        'key': 'CHN',
        'value': '22',
        'text': 'Jilin',
    }, {
        'key': 'CHN',
        'value': '21',
        'text': 'Liaoning',
    }, {
        'key': 'CHN',
        'value': '15',
        'text': 'Nei Mongol',
    }, {
        'key': 'CHN',
        'value': '64',
        'text': 'Ningxia Hui',
    }, {
        'key': 'CHN',
        'value': '63',
        'text': 'Qinghai',
    }, {
        'key': 'CHN',
        'value': '61',
        'text': 'Shaanxi',
    }, {
        'key': 'CHN',
        'value': '37',
        'text': 'Shandong',
    }, {
        'key': 'CHN',
        'value': '31',
        'text': 'Shanghai',
    }, {
        'key': 'CHN',
        'value': '51',
        'text': 'Sichuan',
    }, {
        'key': 'CHN',
        'value': '12',
        'text': 'Tianjin',
    }, {
        'key': 'CHN',
        'value': '65',
        'text': 'Xinjiang Uygur',
    }, {
        'key': 'CHN',
        'value': '54',
        'text': 'Xizang',
    }, {
        'key': 'CHN',
        'value': '53',
        'text': 'Yunnan',
    }, {
        'key': 'CHN',
        'value': '33',
        'text': 'Zhejiang',
    }, {
        'key': 'CXR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CCK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'COL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'COM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'COG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'COK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CRI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CIV',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'HRV',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CUB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CYP',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CZE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DNK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DJI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DMA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DOM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TMP',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ECU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'EGY',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SLV',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GNQ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ERI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'EST',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ETH',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'FLK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'FRO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'FJI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'FIN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'FRA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'FXX',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GUF',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PYF',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ATF',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GAB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GMB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GEO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DEU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GHA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GIB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GRC',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GRL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GRD',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GLP',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GUM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GTM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GIN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GNB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GUY',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'HTI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'HMD',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'HND',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'HKG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'HUN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ISL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'IND',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'IND',
        'value': 'AI',
        'text': 'Andaman & Nicobar Islands',
    }, {
        'key': 'IND',
        'value': 'AN',
        'text': 'Andhra Pradesh',
    }, {
        'key': 'IND',
        'value': 'AR',
        'text': 'Arunachal Pradesh',
    }, {
        'key': 'IND',
        'value': 'AS',
        'text': 'Assam',
    }, {
        'key': 'IND',
        'value': 'BI',
        'text': 'Bihar',
    }, {
        'key': 'IND',
        'value': 'CA',
        'text': 'Chandigarh',
    }, {
        'key': 'IND',
        'value': 'CH',
        'text': 'Chhatisgarh',
    }, {
        'key': 'IND',
        'value': 'DD',
        'text': 'Dadra & Nagar Haveli',
    }, {
        'key': 'IND',
        'value': 'DA',
        'text': 'Daman & Diu',
    }, {
        'key': 'IND',
        'value': 'DE',
        'text': 'Delhi',
    }, {
        'key': 'IND',
        'value': 'GO',
        'text': 'Goa',
    }, {
        'key': 'IND',
        'value': 'GU',
        'text': 'Gujarat',
    }, {
        'key': 'IND',
        'value': 'HA',
        'text': 'Haryana',
    }, {
        'key': 'IND',
        'value': 'HI',
        'text': 'Himachal Pradesh',
    }, {
        'key': 'IND',
        'value': 'JA',
        'text': 'Jammu & Kashmir',
    }, {
        'key': 'IND',
        'value': 'JH',
        'text': 'Jharkhand',
    }, {
        'key': 'IND',
        'value': 'KA',
        'text': 'Karnataka',
    }, {
        'key': 'IND',
        'value': 'KE',
        'text': 'Kerala',
    }, {
        'key': 'IND',
        'value': 'LA',
        'text': 'Lakshadweep',
    }, {
        'key': 'IND',
        'value': 'MD',
        'text': 'Madhya Pradesh',
    }, {
        'key': 'IND',
        'value': 'MH',
        'text': 'Maharashtra',
    }, {
        'key': 'IND',
        'value': 'MN',
        'text': 'Manipur',
    }, {
        'key': 'IND',
        'value': 'ME',
        'text': 'Meghalaya',
    }, {
        'key': 'IND',
        'value': 'MI',
        'text': 'Mizoram',
    }, {
        'key': 'IND',
        'value': 'NA',
        'text': 'Nagaland',
    }, {
        'key': 'IND',
        'value': 'OR',
        'text': 'Orissa',
    }, {
        'key': 'IND',
        'value': 'PO',
        'text': 'Pondicherry',
    }, {
        'key': 'IND',
        'value': 'PU',
        'text': 'Punjab',
    }, {
        'key': 'IND',
        'value': 'RA',
        'text': 'Rajasthan',
    }, {
        'key': 'IND',
        'value': 'SI',
        'text': 'Sikkim',
    }, {
        'key': 'IND',
        'value': 'TA',
        'text': 'Tamil Nadu',
    }, {
        'key': 'IND',
        'value': 'TR',
        'text': 'Tripura',
    }, {
        'key': 'IND',
        'value': 'UT',
        'text': 'Uttar Pradesh',
    }, {
        'key': 'IND',
        'value': 'UA',
        'text': 'Uttaranchal',
    }, {
        'key': 'IND',
        'value': 'WE',
        'text': 'West Bengal',
    }, {
        'key': 'IDN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'IRN',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'IRN',
        'value': 'BO',
        'text': 'Ahmadi va Kohkiluyeh',
    }, {
        'key': 'IRN',
        'value': 'AR',
        'text': 'Ardabil',
    }, {
        'key': 'IRN',
        'value': 'AG',
        'text': 'Azarbayjan-e Gharbi',
    }, {
        'key': 'IRN',
        'value': 'AS',
        'text': 'Azarbayjan-e Sharqi',
    }, {
        'key': 'IRN',
        'value': 'BU',
        'text': 'Bushehr',
    }, {
        'key': 'IRN',
        'value': 'CM',
        'text': 'Chaharmahal va Bakhtiari',
    }, {
        'key': 'IRN',
        'value': 'ES',
        'text': 'Esfahan',
    }, {
        'key': 'IRN',
        'value': 'FA',
        'text': 'Fars',
    }, {
        'key': 'IRN',
        'value': 'GI',
        'text': 'Gilan',
    }, {
        'key': 'IRN',
        'value': 'GO',
        'text': 'Gorgan',
    }, {
        'key': 'IRN',
        'value': 'HA',
        'text': 'Hamadan',
    }, {
        'key': 'IRN',
        'value': 'HO',
        'text': 'Hormozgan',
    }, {
        'key': 'IRN',
        'value': 'IL',
        'text': 'Ilam',
    }, {
        'key': 'IRN',
        'value': 'KE',
        'text': 'Kerman',
    }, {
        'key': 'IRN',
        'value': 'BA',
        'text': 'Kermanshah',
    }, {
        'key': 'IRN',
        'value': 'KJ',
        'text': 'Khorasan-e Junoubi',
    }, {
        'key': 'IRN',
        'value': 'KR',
        'text': 'Khorasan-e Razavi',
    }, {
        'key': 'IRN',
        'value': 'KS',
        'text': 'Khorasan-e Shomali',
    }, {
        'key': 'IRN',
        'value': 'KH',
        'text': 'Khuzestan',
    }, {
        'key': 'IRN',
        'value': 'KO',
        'text': 'Kordestan',
    }, {
        'key': 'IRN',
        'value': 'LO',
        'text': 'Lorestan',
    }, {
        'key': 'IRN',
        'value': 'MR',
        'text': 'Markazi',
    }, {
        'key': 'IRN',
        'value': 'MZ',
        'text': 'Mazandaran',
    }, {
        'key': 'IRN',
        'value': 'QA',
        'text': 'Qazvin',
    }, {
        'key': 'IRN',
        'value': 'QO',
        'text': 'Qom',
    }, {
        'key': 'IRN',
        'value': 'SE',
        'text': 'Semnan',
    }, {
        'key': 'IRN',
        'value': 'SB',
        'text': 'Sistan va Baluchestan',
    }, {
        'key': 'IRN',
        'value': 'TE',
        'text': 'Tehran',
    }, {
        'key': 'IRN',
        'value': 'YA',
        'text': 'Yazd',
    }, {
        'key': 'IRN',
        'value': 'ZA',
        'text': 'Zanjan',
    }, {
        'key': 'IRQ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'IRL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ISR',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'ISR',
        'value': 'GZ',
        'text': 'Gaza Strip',
    }, {
        'key': 'ISR',
        'value': 'IL',
        'text': 'Israel',
    }, {
        'key': 'ISR',
        'value': 'WB',
        'text': 'West Bank',
    }, {
        'key': 'ITA',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'ITA',
        'value': 'AG',
        'text': 'Agrigento',
    }, {
        'key': 'ITA',
        'value': 'AL',
        'text': 'Alessandria',
    }, {
        'key': 'ITA',
        'value': 'AN',
        'text': 'Ancona',
    }, {
        'key': 'ITA',
        'value': 'AO',
        'text': 'Aosta',
    }, {
        'key': 'ITA',
        'value': 'AR',
        'text': 'Arezzo',
    }, {
        'key': 'ITA',
        'value': 'AP',
        'text': 'Ascoli Piceno',
    }, {
        'key': 'ITA',
        'value': 'AT',
        'text': 'Asti',
    }, {
        'key': 'ITA',
        'value': 'AV',
        'text': 'Avellino',
    }, {
        'key': 'ITA',
        'value': 'BA',
        'text': 'Bari',
    }, {
        'key': 'ITA',
        'value': 'BL',
        'text': 'Belluno',
    }, {
        'key': 'ITA',
        'value': 'BN',
        'text': 'Benevento',
    }, {
        'key': 'ITA',
        'value': 'BG',
        'text': 'Bergamo',
    }, {
        'key': 'ITA',
        'value': 'BI',
        'text': 'Biella',
    }, {
        'key': 'ITA',
        'value': 'BO',
        'text': 'Bologna',
    }, {
        'key': 'ITA',
        'value': 'BZ',
        'text': 'Bolzano',
    }, {
        'key': 'ITA',
        'value': 'BS',
        'text': 'Brescia',
    }, {
        'key': 'ITA',
        'value': 'BR',
        'text': 'Brindisi',
    }, {
        'key': 'ITA',
        'value': 'CA',
        'text': 'Cagliari',
    }, {
        'key': 'ITA',
        'value': 'CL',
        'text': 'Caltanissetta',
    }, {
        'key': 'ITA',
        'value': 'CB',
        'text': 'Campobasso',
    }, {
        'key': 'ITA',
        'value': 'CI',
        'text': 'Carbonia-Iglesias',
    }, {
        'key': 'ITA',
        'value': 'CE',
        'text': 'Caserta',
    }, {
        'key': 'ITA',
        'value': 'CT',
        'text': 'Catania',
    }, {
        'key': 'ITA',
        'value': 'CZ',
        'text': 'Catanzaro',
    }, {
        'key': 'ITA',
        'value': 'CH',
        'text': 'Chieti',
    }, {
        'key': 'ITA',
        'value': 'CO',
        'text': 'Como',
    }, {
        'key': 'ITA',
        'value': 'CS',
        'text': 'Cosenza',
    }, {
        'key': 'ITA',
        'value': 'CR',
        'text': 'Cremona',
    }, {
        'key': 'ITA',
        'value': 'KR',
        'text': 'Crotone',
    }, {
        'key': 'ITA',
        'value': 'CN',
        'text': 'Cuneo',
    }, {
        'key': 'ITA',
        'value': 'EN',
        'text': 'Enna',
    }, {
        'key': 'ITA',
        'value': 'FE',
        'text': 'Ferrara',
    }, {
        'key': 'ITA',
        'value': 'FI',
        'text': 'Firenze',
    }, {
        'key': 'ITA',
        'value': 'FG',
        'text': 'Foggia',
    }, {
        'key': 'ITA',
        'value': 'FC',
        'text': 'Forli-Cesena',
    }, {
        'key': 'ITA',
        'value': 'FR',
        'text': 'Frosinone',
    }, {
        'key': 'ITA',
        'value': 'GE',
        'text': 'Genova',
    }, {
        'key': 'ITA',
        'value': 'GO',
        'text': 'Gorizia',
    }, {
        'key': 'ITA',
        'value': 'GR',
        'text': 'Grosseto',
    }, {
        'key': 'ITA',
        'value': 'IM',
        'text': 'Imperia',
    }, {
        'key': 'ITA',
        'value': 'IS',
        'text': 'Isernia',
    }, {
        'key': 'ITA',
        'value': 'AQ',
        'text': 'LAquila',
    }, {
        'key': 'ITA',
        'value': 'SP',
        'text': 'La Spezia',
    }, {
        'key': 'ITA',
        'value': 'LT',
        'text': 'Latina',
    }, {
        'key': 'ITA',
        'value': 'LE',
        'text': 'Lecce',
    }, {
        'key': 'ITA',
        'value': 'LC',
        'text': 'Lecco',
    }, {
        'key': 'ITA',
        'value': 'LI',
        'text': 'Livorno',
    }, {
        'key': 'ITA',
        'value': 'LO',
        'text': 'Lodi',
    }, {
        'key': 'ITA',
        'value': 'LU',
        'text': 'Lucca',
    }, {
        'key': 'ITA',
        'value': 'MC',
        'text': 'Macerata',
    }, {
        'key': 'ITA',
        'value': 'MN',
        'text': 'Mantova',
    }, {
        'key': 'ITA',
        'value': 'MS',
        'text': 'Massa-Carrara',
    }, {
        'key': 'ITA',
        'value': 'MT',
        'text': 'Matera',
    }, {
        'key': 'ITA',
        'value': 'VS',
        'text': 'Medio Campidano',
    }, {
        'key': 'ITA',
        'value': 'ME',
        'text': 'Messina',
    }, {
        'key': 'ITA',
        'value': 'MI',
        'text': 'Milano',
    }, {
        'key': 'ITA',
        'value': 'MO',
        'text': 'Modena',
    }, {
        'key': 'ITA',
        'value': 'NA',
        'text': 'Napoli',
    }, {
        'key': 'ITA',
        'value': 'NO',
        'text': 'Novara',
    }, {
        'key': 'ITA',
        'value': 'NU',
        'text': 'Nuoro',
    }, {
        'key': 'ITA',
        'value': 'OG',
        'text': 'Ogliastra',
    }, {
        'key': 'ITA',
        'value': 'OT',
        'text': 'Olbia-Tempio',
    }, {
        'key': 'ITA',
        'value': 'OR',
        'text': 'Oristano',
    }, {
        'key': 'ITA',
        'value': 'PD',
        'text': 'Padova',
    }, {
        'key': 'ITA',
        'value': 'PA',
        'text': 'Palermo',
    }, {
        'key': 'ITA',
        'value': 'PR',
        'text': 'Parma',
    }, {
        'key': 'ITA',
        'value': 'PV',
        'text': 'Pavia',
    }, {
        'key': 'ITA',
        'value': 'PG',
        'text': 'Perugia',
    }, {
        'key': 'ITA',
        'value': 'PU',
        'text': 'Pesaro e Urbino',
    }, {
        'key': 'ITA',
        'value': 'PE',
        'text': 'Pescara',
    }, {
        'key': 'ITA',
        'value': 'PC',
        'text': 'Piacenza',
    }, {
        'key': 'ITA',
        'value': 'PI',
        'text': 'Pisa',
    }, {
        'key': 'ITA',
        'value': 'PT',
        'text': 'Pistoia',
    }, {
        'key': 'ITA',
        'value': 'PN',
        'text': 'Pordenone',
    }, {
        'key': 'ITA',
        'value': 'PZ',
        'text': 'Potenza',
    }, {
        'key': 'ITA',
        'value': 'PO',
        'text': 'Prato',
    }, {
        'key': 'ITA',
        'value': 'RG',
        'text': 'Ragusa',
    }, {
        'key': 'ITA',
        'value': 'RA',
        'text': 'Ravenna',
    }, {
        'key': 'ITA',
        'value': 'RC',
        'text': 'Reggio Calabria',
    }, {
        'key': 'ITA',
        'value': 'RE',
        'text': 'Reggio Emilia',
    }, {
        'key': 'ITA',
        'value': 'RI',
        'text': 'Rieti',
    }, {
        'key': 'ITA',
        'value': 'RN',
        'text': 'Rimini',
    }, {
        'key': 'ITA',
        'value': 'RM',
        'text': 'Roma',
    }, {
        'key': 'ITA',
        'value': 'RO',
        'text': 'Rovigo',
    }, {
        'key': 'ITA',
        'value': 'SA',
        'text': 'Salerno',
    }, {
        'key': 'ITA',
        'value': 'SS',
        'text': 'Sassari',
    }, {
        'key': 'ITA',
        'value': 'SV',
        'text': 'Savona',
    }, {
        'key': 'ITA',
        'value': 'SI',
        'text': 'Siena',
    }, {
        'key': 'ITA',
        'value': 'SR',
        'text': 'Siracusa',
    }, {
        'key': 'ITA',
        'value': 'SO',
        'text': 'Sondrio',
    }, {
        'key': 'ITA',
        'value': 'TA',
        'text': 'Taranto',
    }, {
        'key': 'ITA',
        'value': 'TE',
        'text': 'Teramo',
    }, {
        'key': 'ITA',
        'value': 'TR',
        'text': 'Terni',
    }, {
        'key': 'ITA',
        'value': 'TO',
        'text': 'Torino',
    }, {
        'key': 'ITA',
        'value': 'TP',
        'text': 'Trapani',
    }, {
        'key': 'ITA',
        'value': 'TN',
        'text': 'Trento',
    }, {
        'key': 'ITA',
        'value': 'TV',
        'text': 'Treviso',
    }, {
        'key': 'ITA',
        'value': 'TS',
        'text': 'Trieste',
    }, {
        'key': 'ITA',
        'value': 'UD',
        'text': 'Udine',
    }, {
        'key': 'ITA',
        'value': 'VA',
        'text': 'Varese',
    }, {
        'key': 'ITA',
        'value': 'VE',
        'text': 'Venezia',
    }, {
        'key': 'ITA',
        'value': 'VB',
        'text': 'Verbano Cusio Ossola',
    }, {
        'key': 'ITA',
        'value': 'VC',
        'text': 'Vercelli',
    }, {
        'key': 'ITA',
        'value': 'VR',
        'text': 'Verona',
    }, {
        'key': 'ITA',
        'value': 'VV',
        'text': 'Vibo Valenzia',
    }, {
        'key': 'ITA',
        'value': 'VI',
        'text': 'Vicenza',
    }, {
        'key': 'ITA',
        'value': 'VT',
        'text': 'Viterbo',
    }, {
        'key': 'JAM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'JPN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'JOR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KAZ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KEN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KIR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PRK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KOR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KWT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KGZ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LAO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LVA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LBN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LSO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LBR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LBY',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LIE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LTU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LUX',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MAC',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MKD',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MDG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MWI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MYS',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MDV',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MLI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MLT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MHL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MTQ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MRT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MUS',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MYT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MEX',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'MEX',
        'value': 'AG',
        'text': 'Aguascalientes',
    }, {
        'key': 'MEX',
        'value': 'BN',
        'text': 'Baja California Norte',
    }, {
        'key': 'MEX',
        'value': 'BS',
        'text': 'Baja California Sur',
    }, {
        'key': 'MEX',
        'value': 'CA',
        'text': 'Campeche',
    }, {
        'key': 'MEX',
        'value': 'CS',
        'text': 'Chiapas',
    }, {
        'key': 'MEX',
        'value': 'CH',
        'text': 'Chihuahua',
    }, {
        'key': 'MEX',
        'value': 'CO',
        'text': 'Coahuila',
    }, {
        'key': 'MEX',
        'value': 'CM',
        'text': 'Colima',
    }, {
        'key': 'MEX',
        'value': 'DF',
        'text': 'Distrito Federal',
    }, {
        'key': 'MEX',
        'value': 'DO',
        'text': 'Durango',
    }, {
        'key': 'MEX',
        'value': 'GO',
        'text': 'Guanajuato',
    }, {
        'key': 'MEX',
        'value': 'GU',
        'text': 'Guerrero',
    }, {
        'key': 'MEX',
        'value': 'HI',
        'text': 'Hidalgo',
    }, {
        'key': 'MEX',
        'value': 'JA',
        'text': 'Jalisco',
    }, {
        'key': 'MEX',
        'value': 'EM',
        'text': 'M\u00e2\u02c6\u0161\u00c2\u00a9xico (Estado de)',
    }, {
        'key': 'MEX',
        'value': 'MI',
        'text': 'Michoac\u00e2\u02c6\u0161\u00c2\u00b0n',
    }, {
        'key': 'MEX',
        'value': 'MO',
        'text': 'Morelos',
    }, {
        'key': 'MEX',
        'value': 'NY',
        'text': 'Nayarit',
    }, {
        'key': 'MEX',
        'value': 'NL',
        'text': 'Nuevo Le\u00e2\u02c6\u0161\u00e2\u2030\u00a5n',
    }, {
        'key': 'MEX',
        'value': 'OA',
        'text': 'Oaxaca',
    }, {
        'key': 'MEX',
        'value': 'PU',
        'text': 'Puebla',
    }, {
        'key': 'MEX',
        'value': 'QU',
        'text': 'Quer\u00e2\u02c6\u0161\u00c2\u00a9taro',
    }, {
        'key': 'MEX',
        'value': 'QR',
        'text': 'Quintana Roo',
    }, {
        'key': 'MEX',
        'value': 'SP',
        'text': 'San Luis Potos\u00e2\u02c6\u0161\u00e2\u2030\u00a0',
    }, {
        'key': 'MEX',
        'value': 'SI',
        'text': 'Sinaloa',
    }, {
        'key': 'MEX',
        'value': 'SO',
        'text': 'Sonora',
    }, {
        'key': 'MEX',
        'value': 'TA',
        'text': 'Tabasco',
    }, {
        'key': 'MEX',
        'value': 'TM',
        'text': 'Tamaulipas',
    }, {
        'key': 'MEX',
        'value': 'TX',
        'text': 'Tlaxcala',
    }, {
        'key': 'MEX',
        'value': 'VZ',
        'text': 'Veracruz',
    }, {
        'key': 'MEX',
        'value': 'YU',
        'text': 'Yucat\u00e2\u02c6\u0161\u00c2\u00b0n',
    }, {
        'key': 'MEX',
        'value': 'ZA',
        'text': 'Zacatecas',
    }, {
        'key': 'FSM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MDA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MCO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MNG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MSR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MAR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MOZ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MMR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NAM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NRU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NPL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NLD',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ANT',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'ANT',
        'value': 'BN',
        'text': 'Bonaire',
    }, {
        'key': 'ANT',
        'value': 'CR',
        'text': 'Curacao',
    }, {
        'key': 'ANT',
        'value': 'SM',
        'text': 'St. Maarten',
    }, {
        'key': 'NCL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NZL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NIC',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NER',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NGA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NIU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NFK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MNP',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'NOR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'OMN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PAK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PLW',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PAN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PNG',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PRY',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PER',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PHL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PCN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'POL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PRT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'PRI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'QAT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'REU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ROM',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'ROM',
        'value': 'AB',
        'text': 'Alba',
    }, {
        'key': 'ROM',
        'value': 'AR',
        'text': 'Arad',
    }, {
        'key': 'ROM',
        'value': 'AG',
        'text': 'Arges',
    }, {
        'key': 'ROM',
        'value': 'BC',
        'text': 'Bacau',
    }, {
        'key': 'ROM',
        'value': 'BH',
        'text': 'Bihor',
    }, {
        'key': 'ROM',
        'value': 'BN',
        'text': 'Bistrita-Nasaud',
    }, {
        'key': 'ROM',
        'value': 'BT',
        'text': 'Botosani',
    }, {
        'key': 'ROM',
        'value': 'BR',
        'text': 'Braila',
    }, {
        'key': 'ROM',
        'value': 'BV',
        'text': 'Brasov',
    }, {
        'key': 'ROM',
        'value': 'B',
        'text': 'Bucuresti',
    }, {
        'key': 'ROM',
        'value': 'BZ',
        'text': 'Buzau',
    }, {
        'key': 'ROM',
        'value': 'CL',
        'text': 'Calarasi',
    }, {
        'key': 'ROM',
        'value': 'CS',
        'text': 'Caras Severin',
    }, {
        'key': 'ROM',
        'value': 'CJ',
        'text': 'Cluj',
    }, {
        'key': 'ROM',
        'value': 'CT',
        'text': 'Constanta',
    }, {
        'key': 'ROM',
        'value': 'CV',
        'text': 'Covasna',
    }, {
        'key': 'ROM',
        'value': 'DB',
        'text': 'Dambovita',
    }, {
        'key': 'ROM',
        'value': 'DJ',
        'text': 'Dolj',
    }, {
        'key': 'ROM',
        'value': 'GL',
        'text': 'Galati',
    }, {
        'key': 'ROM',
        'value': 'GR',
        'text': 'Giurgiu',
    }, {
        'key': 'ROM',
        'value': 'GJ',
        'text': 'Gorj',
    }, {
        'key': 'ROM',
        'value': 'HR',
        'text': 'Hargita',
    }, {
        'key': 'ROM',
        'value': 'HD',
        'text': 'Hunedoara',
    }, {
        'key': 'ROM',
        'value': 'IL',
        'text': 'Ialomita',
    }, {
        'key': 'ROM',
        'value': 'IS',
        'text': 'Iasi',
    }, {
        'key': 'ROM',
        'value': 'IF',
        'text': 'Ilfov',
    }, {
        'key': 'ROM',
        'value': 'MM',
        'text': 'Maramures',
    }, {
        'key': 'ROM',
        'value': 'MH',
        'text': 'Mehedinti',
    }, {
        'key': 'ROM',
        'value': 'MS',
        'text': 'Mures',
    }, {
        'key': 'ROM',
        'value': 'NT',
        'text': 'Neamt',
    }, {
        'key': 'ROM',
        'value': 'OT',
        'text': 'Olt',
    }, {
        'key': 'ROM',
        'value': 'PH',
        'text': 'Prahova',
    }, {
        'key': 'ROM',
        'value': 'SJ',
        'text': 'Salaj',
    }, {
        'key': 'ROM',
        'value': 'SM',
        'text': 'Satu Mare',
    }, {
        'key': 'ROM',
        'value': 'SB',
        'text': 'Sibiu',
    }, {
        'key': 'ROM',
        'value': 'SV',
        'text': 'Suceava',
    }, {
        'key': 'ROM',
        'value': 'TR',
        'text': 'Teleorman',
    }, {
        'key': 'ROM',
        'value': 'TM',
        'text': 'Timis',
    }, {
        'key': 'ROM',
        'value': 'TL',
        'text': 'Tulcea',
    }, {
        'key': 'ROM',
        'value': 'VL',
        'text': 'Valcea',
    }, {
        'key': 'ROM',
        'value': 'VS',
        'text': 'Vaslui',
    }, {
        'key': 'ROM',
        'value': 'VN',
        'text': 'Vrancea',
    }, {
        'key': 'RUS',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'RWA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'KNA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'LCA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VCT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'WSM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SMR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'STP',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SAU',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SEN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SYC',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SLE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SGP',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SVK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SVN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SLB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SOM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ZAF',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SGS',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ESP',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'ESP',
        'value': '15',
        'text': 'A Coru\u00e2\u02c6\u0161\u00c2\u00b1a',
    }, {
        'key': 'ESP',
        'value': '01',
        'text': 'Alava',
    }, {
        'key': 'ESP',
        'value': '02',
        'text': 'Albacete',
    }, {
        'key': 'ESP',
        'value': '03',
        'text': 'Alicante',
    }, {
        'key': 'ESP',
        'value': '04',
        'text': 'Almeria',
    }, {
        'key': 'ESP',
        'value': '33',
        'text': 'Asturias',
    }, {
        'key': 'ESP',
        'value': '05',
        'text': 'Avila',
    }, {
        'key': 'ESP',
        'value': '06',
        'text': 'Badajoz',
    }, {
        'key': 'ESP',
        'value': '07',
        'text': 'Baleares',
    }, {
        'key': 'ESP',
        'value': '08',
        'text': 'Barcelona',
    }, {
        'key': 'ESP',
        'value': '09',
        'text': 'Burgos',
    }, {
        'key': 'ESP',
        'value': '10',
        'text': 'Caceres',
    }, {
        'key': 'ESP',
        'value': '11',
        'text': 'Cadiz',
    }, {
        'key': 'ESP',
        'value': '39',
        'text': 'Cantabria',
    }, {
        'key': 'ESP',
        'value': '12',
        'text': 'Castellon',
    }, {
        'key': 'ESP',
        'value': '51',
        'text': 'Ceuta',
    }, {
        'key': 'ESP',
        'value': '13',
        'text': 'Ciudad Real',
    }, {
        'key': 'ESP',
        'value': '14',
        'text': 'Cordoba',
    }, {
        'key': 'ESP',
        'value': '16',
        'text': 'Cuenca',
    }, {
        'key': 'ESP',
        'value': '17',
        'text': 'Girona',
    }, {
        'key': 'ESP',
        'value': '18',
        'text': 'Granada',
    }, {
        'key': 'ESP',
        'value': '19',
        'text': 'Guadalajara',
    }, {
        'key': 'ESP',
        'value': '20',
        'text': 'Guipuzcoa',
    }, {
        'key': 'ESP',
        'value': '21',
        'text': 'Huelva',
    }, {
        'key': 'ESP',
        'value': '22',
        'text': 'Huesca',
    }, {
        'key': 'ESP',
        'value': '23',
        'text': 'Jaen',
    }, {
        'key': 'ESP',
        'value': '26',
        'text': 'La Rioja',
    }, {
        'key': 'ESP',
        'value': '35',
        'text': 'Las Palmas',
    }, {
        'key': 'ESP',
        'value': '24',
        'text': 'Leon',
    }, {
        'key': 'ESP',
        'value': '25',
        'text': 'Lleida',
    }, {
        'key': 'ESP',
        'value': '27',
        'text': 'Lugo',
    }, {
        'key': 'ESP',
        'value': '28',
        'text': 'Madrid',
    }, {
        'key': 'ESP',
        'value': '29',
        'text': 'Malaga',
    }, {
        'key': 'ESP',
        'value': '52',
        'text': 'Melilla',
    }, {
        'key': 'ESP',
        'value': '30',
        'text': 'Murcia',
    }, {
        'key': 'ESP',
        'value': '31',
        'text': 'Navarra',
    }, {
        'key': 'ESP',
        'value': '32',
        'text': 'Ourense',
    }, {
        'key': 'ESP',
        'value': '34',
        'text': 'Palencia',
    }, {
        'key': 'ESP',
        'value': '36',
        'text': 'Pontevedra',
    }, {
        'key': 'ESP',
        'value': '37',
        'text': 'Salamanca',
    }, {
        'key': 'ESP',
        'value': '38',
        'text': 'Santa Cruz de Tenerife',
    }, {
        'key': 'ESP',
        'value': '40',
        'text': 'Segovia',
    }, {
        'key': 'ESP',
        'value': '41',
        'text': 'Sevilla',
    }, {
        'key': 'ESP',
        'value': '42',
        'text': 'Soria',
    }, {
        'key': 'ESP',
        'value': '43',
        'text': 'Tarragona',
    }, {
        'key': 'ESP',
        'value': '44',
        'text': 'Teruel',
    }, {
        'key': 'ESP',
        'value': '45',
        'text': 'Toledo',
    }, {
        'key': 'ESP',
        'value': '46',
        'text': 'Valencia',
    }, {
        'key': 'ESP',
        'value': '47',
        'text': 'Valladolid',
    }, {
        'key': 'ESP',
        'value': '48',
        'text': 'Vizcaya',
    }, {
        'key': 'ESP',
        'value': '49',
        'text': 'Zamora',
    }, {
        'key': 'ESP',
        'value': '50',
        'text': 'Zaragoza',
    }, {
        'key': 'LKA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SHN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SPM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SDN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SUR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SJM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SWZ',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SWE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'CHE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SYR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TWN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TJK',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TZA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'THA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TGO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TKL',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TON',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TTO',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TUN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TUR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TKM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TCA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'TUV',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'UGA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'UKR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ARE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'GBR',
        'value': '',
        'text': 'Select Your State\/Province\/Region\/Territory',
    }, {
        'key': 'GBR',
        'value': 'EN',
        'text': 'England',
    }, {
        'key': 'GBR',
        'value': 'NI',
        'text': 'Northern Ireland',
    }, {
        'key': 'GBR',
        'value': 'SD',
        'text': 'Scotland',
    }, {
        'key': 'GBR',
        'value': 'WS',
        'text': 'Wales',
    }, {
        'key': 'USA',
        'value': '',
        'text': 'Select Your State\/Territory',
    }, {
        'key': 'USA',
        'value': 'AL',
        'text': 'Alabama',
    }, {
        'key': 'USA',
        'value': 'AK',
        'text': 'Alaska',
    }, {
        'key': 'USA',
        'value': 'AZ',
        'text': 'Arizona',
    }, {
        'key': 'USA',
        'value': 'AR',
        'text': 'Arkansas',
    }, {
        'key': 'USA',
        'value': 'CA',
        'text': 'California',
    }, {
        'key': 'USA',
        'value': 'CO',
        'text': 'Colorado',
    }, {
        'key': 'USA',
        'value': 'CT',
        'text': 'Connecticut',
    }, {
        'key': 'USA',
        'value': 'DE',
        'text': 'Delaware',
    }, {
        'key': 'USA',
        'value': 'DC',
        'text': 'District Of Columbia',
    }, {
        'key': 'USA',
        'value': 'FL',
        'text': 'Florida',
    }, {
        'key': 'USA',
        'value': 'GA',
        'text': 'Georgia',
    }, {
        'key': 'USA',
        'value': 'HI',
        'text': 'Hawaii',
    }, {
        'key': 'USA',
        'value': 'ID',
        'text': 'Idaho',
    }, {
        'key': 'USA',
        'value': 'IL',
        'text': 'Illinois',
    }, {
        'key': 'USA',
        'value': 'IN',
        'text': 'Indiana',
    }, {
        'key': 'USA',
        'value': 'IA',
        'text': 'Iowa',
    }, {
        'key': 'USA',
        'value': 'KS',
        'text': 'Kansas',
    }, {
        'key': 'USA',
        'value': 'KY',
        'text': 'Kentucky',
    }, {
        'key': 'USA',
        'value': 'LA',
        'text': 'Louisiana',
    }, {
        'key': 'USA',
        'value': 'ME',
        'text': 'Maine',
    }, {
        'key': 'USA',
        'value': 'MD',
        'text': 'Maryland',
    }, {
        'key': 'USA',
        'value': 'MA',
        'text': 'Massachusetts',
    }, {
        'key': 'USA',
        'value': 'MI',
        'text': 'Michigan',
    }, {
        'key': 'USA',
        'value': 'MN',
        'text': 'Minnesota',
    }, {
        'key': 'USA',
        'value': 'MS',
        'text': 'Mississippi',
    }, {
        'key': 'USA',
        'value': 'MO',
        'text': 'Missouri',
    }, {
        'key': 'USA',
        'value': 'MT',
        'text': 'Montana',
    }, {
        'key': 'USA',
        'value': 'NE',
        'text': 'Nebraska',
    }, {
        'key': 'USA',
        'value': 'NV',
        'text': 'Nevada',
    }, {
        'key': 'USA',
        'value': 'NH',
        'text': 'New Hampshire',
    }, {
        'key': 'USA',
        'value': 'NJ',
        'text': 'New Jersey',
    }, {
        'key': 'USA',
        'value': 'NM',
        'text': 'New Mexico',
    }, {
        'key': 'USA',
        'value': 'NY',
        'text': 'New York',
    }, {
        'key': 'USA',
        'value': 'NC',
        'text': 'North Carolina',
    }, {
        'key': 'USA',
        'value': 'ND',
        'text': 'North Dakota',
    }, {
        'key': 'USA',
        'value': 'OH',
        'text': 'Ohio',
    }, {
        'key': 'USA',
        'value': 'OK',
        'text': 'Oklahoma',
    }, {
        'key': 'USA',
        'value': 'OR',
        'text': 'Oregon',
    }, {
        'key': 'USA',
        'value': 'PA',
        'text': 'Pennsylvania',
    }, {
        'key': 'USA',
        'value': 'RI',
        'text': 'Rhode Island',
    }, {
        'key': 'USA',
        'value': 'SC',
        'text': 'South Carolina',
    }, {
        'key': 'USA',
        'value': 'SD',
        'text': 'South Dakota',
    }, {
        'key': 'USA',
        'value': 'TN',
        'text': 'Tennessee',
    }, {
        'key': 'USA',
        'value': 'TX',
        'text': 'Texas',
    }, {
        'key': 'USA',
        'value': 'UT',
        'text': 'Utah',
    }, {
        'key': 'USA',
        'value': 'VT',
        'text': 'Vermont',
    }, {
        'key': 'USA',
        'value': 'VA',
        'text': 'Virginia',
    }, {
        'key': 'USA',
        'value': 'WA',
        'text': 'Washington',
    }, {
        'key': 'USA',
        'value': 'WV',
        'text': 'West Virginia',
    }, {
        'key': 'USA',
        'value': 'WI',
        'text': 'Wisconsin',
    }, {
        'key': 'USA',
        'value': 'WY',
        'text': 'Wyoming',
    }, {
        'key': 'UMI',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'URY',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'UZB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VUT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VAT',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VEN',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VNM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VGB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'VIR',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'WLF',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ESH',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'YEM',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'SRB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'DRC',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ZMB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'ZWE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'XET',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'XJE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'XSB',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'XSE',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'XCA',
        'value': '-',
        'text': 'State Not Applicable',
    }, {
        'key': 'MNE',
        'value': '-',
        'text': 'State Not Applicable',
    }];

    private AusStates = [{
        code: 'AT',
        name: 'ACT',
    }, {
        code: 'VI',
        name: 'Victoria',
    }, {
        code: 'NW',
        name: 'New South Wales',
    }, {
        code: 'QL',
        name: 'Queensland',
    }, {
        code: 'SA',
        name: 'South Australia',
    }, {
        code: 'NT',
        name: 'Northern Territory',
    }, {
        code: 'WA',
        name: 'Western Australia',
    }, {
        code: 'TA',
        name: 'Tasmania',
    }];

    constructor() {
    }

    getCountries() {
        return this.countries;
    }

    getStates() {
        return this.states;
    }

    getAusStates() {
        return this.AusStates;
    }
}
