import { Injectable } from '@angular/core';
import { IOrgUser, UserService } from '../user.service';
import { GroupService, IJobRole, ITeam } from '../group.service';
import { groupBy, keys, mapObject, pairs, values } from 'underscore';
import { FacilitiesService } from '../facilities.service';

@Injectable({
  providedIn: 'root'
})
export class GroupReportService {

  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private facilityService: FacilitiesService
  ) { }

  /**
   * 
   * @param byUserData array with user total data eg {assignee; totalMin;}[]
   * @param userIDAttr eg assignee
   * @param findByOrgUserID true if using orgUserID as key
   * @returns 
   */
  group<T = any>(byUserData: T[], userIDAttr: string, findByOrgUserID = false) {
    const userDict = findByOrgUserID
      ? this.userService.managedOrgUserDictionaryByOrgUserID
      : this.userService.managedOrgUserDictionaryByUserID;
    const withUserObj = byUserData.map(d => ({...d, userObj: userDict[d[userIDAttr]]}))
    return mapObject({
      byTeams: this._groupByTeam<T>(withUserObj),
      byLocations: this._groupByLocation<T>(withUserObj),
      byManager: this._groupByManager<T>(withUserObj),
      byJobRoles: this._groupByJobRoles<T>(withUserObj)
    }, v => v.filter(e => e)) // remove groups with no data
  }

  /**
   * 
   * @param records individual records eg Assign[]
   * @param userIDAttr eg assignee
   * @param findByOrgUserID 
   * @returns 
   */
  groupIndividualRecords<T = any>(records: T[], userIDAttr: string, findByOrgUserID = false) {
    const byUser = groupBy(records, userIDAttr);
    const byUserArr = keys(groupBy(records, userIDAttr)).map(k => {
      return {
        id: k,
        byUserRecords: byUser[k],
      };
    });
    return this.group(byUserArr, 'id', findByOrgUserID);
  }

  private _groupByTeam<T>(byUserData: IByUserData[]): Array<T & {teams: ITeam[]} & IGroupData> {
    return pairs(this.groupService.globalTeamsDict).map(([teamID, team]) => {
      const teamUserData = byUserData.filter(d => {
        return d.userObj.teams?.includes(teamID);
      });
      return this.createGroupedData<T, {teams: ITeam[]}>(teamUserData, {teams: [team]}, team.name);
    });
  }

  private _groupByLocation<T>(byUserData: IByUserData[]): Array<T & {facilityModels: ITeam[]} & IGroupData> {
    const orgLocations = values(this.facilityService.globalOrgFacilities);
    return orgLocations.map(l => {
      // user is in location or parent
      const locationUserData = byUserData.filter(u => u.userObj.facilities?.some(f => l.allDescendants.includes(f)));
      return this.createGroupedData<T, {facilityModels: ITeam[]}>(locationUserData, {facilityModels: [l]}, l.name)
    })
  }

  private _groupByManager<T>(byUserData: IByUserData[]): Array<T & {directManager: IOrgUser} & IGroupData> {
    const directManagers = values(this.userService.managedOrgHierarchyDict);
    return directManagers.map(d => {
      const reportOrgUserIDs = d.members.map(u => u.orgUserID);
      const reportsData = byUserData.filter(d => reportOrgUserIDs.includes(d.userObj?.orgUserID));
      return this.createGroupedData<T, {directManager: IOrgUser}>(reportsData, {directManager: d.manager}, d.manager.fullName)
    })
  }

  private _groupByJobRoles<T>(byUserData: IByUserData[]): Array<T & {jobRoles: IJobRole[]} & IGroupData> {
    return values(this.groupService.globalJobRolesDict).map(j => {
      const reportsData = byUserData.filter(d => d.userObj.activeJobRoleTakens.some(activeJob => activeJob.jobRole === j.jobRoleID));
      return this.createGroupedData<T, {jobRoles: IJobRole[]}>(reportsData, {jobRoles: [j]}, j.title)
    })
  }

  createGroupedData<T, G>(inGroupData: IByUserData[], groupData: G, groupName: string) {
    return inGroupData.length
      ? {
        ...groupData,
        col_groupName: groupName,
        groupRecords: inGroupData.map(u => u.byUserRecords || []).flat(),
        ...this.accumulateNumericAttributes(inGroupData),
        ...this._accumulateIDs(inGroupData),
      } as T & G & IUserIDs & {col_groupName: string, groupRecords: any[]}
      : null
  }

  private _accumulateIDs(byUserData: IByUserData[]) {
    return {
      userIDs: byUserData.map(u => u.userObj?.userID).filter(id => id),
      orgUserIDs: byUserData.map(u => u.userObj?.orgUserID).filter(id => id),
      staffIDs: byUserData.map(u => u.userObj?.staffID).filter(id => id),
    }
  }

  /**
   * Accumulates all numeric attributes from an array of objects
   * @param arr An array of objects with potentially numeric attributes
   * @returns An object with the sum of each numeric attribute across all objects
   */
  accumulateNumericAttributes<T extends Record<string, any>>(arr: T[]): Partial<Record<keyof T, number>> {
    return arr.reduce((accumulator, currentObject) => {
      // Iterate through each property of the current object
      Object.entries(currentObject).forEach(([key, value]) => {
        // Check if the value is a number
        if (typeof value === 'number' && !isNaN(value)) {
          // Add or update the accumulator
          const existingValue = accumulator[key as keyof T] as number | undefined;
          accumulator[key as keyof T] = (existingValue || 0) + value;
        }
        // Check one level deep for objects with numeric properties
        else if (typeof value === 'object' && value !== null) {
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            if (typeof nestedValue === 'number' && !isNaN(nestedValue)) {
              // Create a compound key or handle nested accumulation
              const compoundKey = `${key}.${nestedKey}` as keyof T;
              const existingValue = accumulator[key]?.[nestedKey] || 0 as number | undefined;
              accumulator[key as keyof T] = accumulator[key] || {} as any;
              accumulator[key][nestedKey] = (existingValue || 0) + nestedValue;
            }
          });
        }
      });
  
      return accumulator;
    }, {} as Partial<Record<keyof T, number>>);
  }
}

interface IByUserData {
  userObj: IOrgUser;
  byUserRecords?: any[];
}

interface IUserIDs {
  userIDs?: string;
  staffIDs?: string[];
  orgUserIDs?: string[];
}

interface IGroupData extends IUserIDs {
  col_groupName: string;
  groupRecords: any[];
}