import { Component, inject, OnInit } from '@angular/core';
import { LocalDatePipe } from '../../../../../shared/pipes/local-date.pipe';
import { OrganisationService } from 'src/app/services/organisation.service';

@Component({
    selector: 'app-extended-dates-cell',
    templateUrl: './extended-dates-cell.component.html',
    styleUrls: ['./extended-dates-cell.component.scss'],
    standalone: true,
    imports: [LocalDatePipe],
})
export class ExtendedDatesCellComponent implements OnInit {
  rowData: {assignDate; extendedDueDate; originDueDate; originLateRecogDate};

  isNewExtension = inject(OrganisationService).isNewExtensionSetting();
  originalDate: string;
  constructor() { }

  ngOnInit(): void {
    this.originalDate = this.isNewExtension ? this.rowData.originLateRecogDate : this.rowData.originDueDate

  }

}
