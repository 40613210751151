<ui-modal-single [heading]="modalHeading">
    <ng-template #contentSlot>
        <div [hidden]="!selectedRecords" class="scroll-area px-5">

            <!-- Skipped copy -->
            <ng-container *ngIf="action === 'Skipped'">
                <p class="mb-4 mt-5">You are about to mark <span class="fw-semibold"> {{ taskNum }} task{{ taskNum > 1 ?
                        's' : '' }}</span>
                    as <span class="fw-semibold">Skipped</span>.</p>
            </ng-container>

            <!-- Unskip copy -->
            <ng-container *ngIf="action === 'Unskip'">
                <p class="mb-4 mt-5">You are about to unskip <span class="fw-semibold"> {{ taskNum }} task{{ taskNum > 1
                        ?
                        's' : '' }}</span>.</p>
            </ng-container>

            <!-- Reminder copy -->
            <ng-container *ngIf="action === 'Remind'">
                <p class="mb-4 mt-5">
                    You are about to <span class="fw-semibold">send reminders to {{ selectedUsers.length }}
                        user{{selectedUsers.length > 1 ? 's' : ''}}</span> for each of their requirements
                </p>
            </ng-container>

            <div class="mb-4">
                <div>
                    <span *ngIf="hideTable" (click)="collapse.toggle()"
                        class="text-neutral-900 f-small fw-medium underline cursor-pointer">
                        <ui-streamline-icon
                            [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size:'xs'}"
                            class="me-1"></ui-streamline-icon>
                        View {{ viewHideText }}
                    </span>
                    <span *ngIf="!hideTable" (click)="collapse.toggle()"
                        class="text-neutral-900 f-small fw-medium underline cursor-pointer">
                        <ui-streamline-icon
                            [slIcon]="{name: 'interface-arrows-button-up', theme: 'micro-bold', size:'xs'}"
                            class="me-1"></ui-streamline-icon>
                        Hide {{ viewHideText }}
                    </span>
                </div>

                <div #collapse="ngbCollapse" [(ngbCollapse)]="hideTable" class="table-wrap">
                    <ng2-smart-table [settings]="activeTableSetting" [source]="activeTableSource"></ng2-smart-table>
                </div>
            </div>


            <div *ngIf="showRemindWarning && action==='Remind'" class="mb-4">
                <app-warning-banner
                    copy="Reminders only for self assessments with 'Staff to Complete' action status"></app-warning-banner>
            </div>

            <div *ngIf="showSkippedWarning && action==='Skipped'" class="mb-4">
                <app-warning-banner copy="You can only skipped the tasks of your report lines"></app-warning-banner>
            </div>

            <div *ngIf="showUnskipWarning && action==='Unskip'" class="mb-4">
                <app-warning-banner copy="You can only unskip the tasks of your report lines"></app-warning-banner>
            </div>

        </div>
    </ng-template>

    <ng-template #rightSlot>
        <button [disabled]="isSubmitting" type="button" class="btn btn-outline-light"
            (click)="closeModal()">Cancel</button>

        <button [disabled]="isSubmitting || selectedRecords.length<1" *ngIf="action === 'Remind'" type="button"
            class="btn btn-secondary" (click)="sendReminderRequests()">
            <span *ngIf="isSubmitting" style="color: inherit">
                <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            Send Reminders
        </button>

        <button [disabled]="isSubmitting || selectedRecords.length<1" *ngIf="action === 'Skipped'" type="button"
            class="btn btn-secondary" (click)="markAsSkipped()">
            <span *ngIf="isSubmitting" style="color: inherit">
                <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            Mark as Skipped
        </button>
        <button [disabled]="isSubmitting || selectedRecords.length<1" *ngIf="action === 'Unskip'" type="button"
            class="btn btn-secondary" (click)="UnskipTasks()">
            <span *ngIf="isSubmitting" style="color: inherit">
                <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            Unskip Tasks
        </button>
    </ng-template>



</ui-modal-single>