import { Component, computed, EventEmitter, input } from '@angular/core';
import { BadgeComplianceComponent } from '../badge-compliance/badge-compliance.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-task-modal-compliance-item',
  standalone: true,
  imports: [BadgeComplianceComponent, UIStreamlineIconComponent, CommonModule],
  templateUrl: './task-modal-compliance-item.component.html',
  styleUrl: './task-modal-compliance-item.component.scss'
})
export class TaskModalComplianceItemComponent {
  nonCompliantNum = input<number>();
  complianceEntity = input<ComplianceEntity>('learning');
  click = new EventEmitter();

  nonCompliantLabel = computed(() => this.complianceEntity() === 'Policy' ? 'Unacknowledged' : 'overdue or missed')
}

export type ComplianceEntity = 'learning' | 'Policy' | 'Perform'
