import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TruncatedTextComponent } from '../../../shared/components/customised-multi-select/truncated-text/truncated-text.component';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-pp-title-cell',
    template: `
    <!-- <div class="my-1">
        <a class="fw-medium text-neutral-900 btn-link cursor-pointer" (click)="jumpTo()"
        >{{ value }}</a>
    </div> -->
    <div [style.width]="'299px'">
      <app-truncated-text [value]="value">
        <a [class]="textClasses + ' cursor-pointer'" (click)="jumpTo()"
          >{{ value }}
        </a>
      </app-truncated-text>
      <p *ngIf="showCode" class="f-caption">{{rowData.code}}</p>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TruncatedTextComponent, CommonModule]
})
export class TitleCellComponent implements OnInit {
  @Input() value: any;
  @Input() rowData: any;
  @Input() itemType: any;
  @Input() linkFn: (rData) => {
    link: string,
    fragment?: string,
  };
  @Input() isNewTab = false;
  @Input() textClasses = 'fw-semibold text-neutral-900'
  @Input() showCode = false;


  constructor(
    private router: Router,
      ) { }

  ngOnInit(): void {}

  jumpTo () {
    if (this.isNewTab) {
      window.open(this.detailLink, '_blank');
      return;
    }
    if (this.fragment) {
      this.router.navigate([this.detailLink], {fragment: this.fragment});
    } else {
      this.router.navigate([this.detailLink]);
    }
  }

  get detailLink () {
    if (this.linkFn) {
      return this.linkFn(this.rowData).link;
    }
    return `/pages/policies/manage/policies/${this.rowData.governanceID}`;
  }

  get fragment () {
    if (this.linkFn) {
      return this.linkFn(this.rowData).fragment;
    }
    return ``;
  }

}
