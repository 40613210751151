import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IPerformanceReviewAssign } from 'src/app/services/performance-review.service';
import { getPrAssignStatus, PR_ASSIGN_COLOUR_DICT, PR_ASSIGN_STATUS_ENUM } from 'src/app/shared/utils/pr-assign-status-transform';

@Component({
  selector: 'app-pr-assign-status-cell',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pr-assign-status-cell.component.html',
  styleUrl: './pr-assign-status-cell.component.scss'
})
export class PrAssignStatusCellComponent implements OnInit {
  @Input() rowData: IPerformanceReviewAssign;

  assignStatus: PR_ASSIGN_STATUS_ENUM | '-';

  PR_ASSIGN_COLOUR_DICT = PR_ASSIGN_COLOUR_DICT;

  ngOnInit(): void {
    this.assignStatus = getPrAssignStatus(this.rowData.status, this.rowData.action) || '-';
  }
}