import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Dictionary, clone, indexBy, keys, uniq, values } from 'underscore';
import { ISingleSelectorOptions } from '../form-builder-tool/fb-side-panel/fb-section-editor/fb-question-card/custom-single-selector/custom-single-selector.component';
import { HierarchyService } from '../pages/admin/hierarchy/hierarchy.service';
import { AnalyticsPrService } from '../pages/performance-review/analytics-pr.service';
import { PerfReviewPermissionsService } from '../pages/performance-review/perf-review-permissions.service';
import { TaskRemindModalComponent } from '../pages/performance-review/reviews/progress/task-activity-tables/task-table/task-remind-modal/task-remind-modal.component';
import { IPerformanceReviewQuestionType } from '../performance-review-builder-tool/performance-review-builder-tool.service';
import { IEnrolledCycle } from '../shared/popups/add-staff/add-staff.types';
import { DataCacher } from '../shared/utils/class/DataCacher';
import { addZIfMissing } from '../shared/utils/dates/date-utils';
import { BroadcastService } from './broadcast.service';
import { ErrorHandlingService } from './error-handling.service';
import { ILearningGoal } from './goal.service';
import { getTaskStatusDisplay } from './service-utils/performance-review-service-utils';
import { ITableUser, SmartTableColumnsService } from './smart-table-columns.service';
import { TableColumnValuePrepareAndSortUtilsService } from './table-column-value-prepare-and-sort-utils.service';
import { IOrgUser, USER_STATUS, UserService } from './user.service';
import { getPrAssignStatus, PR_ASSIGN_STATUS_ENUM } from '../shared/utils/pr-assign-status-transform';

export interface IPerformanceReviewTaskTemplate extends IPerformanceReviewTaskTemplateForm {
    templateID: string;
    currentVersion?: IPerformanceReviewTaskTemplateVersion;
    pastVersions?: IPerformanceReviewTaskTemplateVersion[];
    orgID?: string,
    createDate: string, //"2024-07-29T23:17:02",
    createdBy: string,
    updateDate: string, //"2024-07-29T23:17:02",
    updatedBy: string,
}

export interface IPerformanceReviewTaskTemplateForm {
    title: string,
    taskType: TASK_TEMPLATE_TYPE,
    completionType: 'Manager' | 'Staff' | 'Both', // 'Both' is de-scoped in current version
    code?: string,
    description?: string,
    status: TASK_TEMPLATE_STATUS
}

export interface IPerformanceReviewTaskTemplateWithVersions {
    template: IPerformanceReviewTaskTemplate,
    currentVersion?: IPerformanceReviewTaskTemplateVersion;
    pastVersions?: IPerformanceReviewTaskTemplateVersion[];
}

export interface IPerformanceReviewTaskTemplateVersion {
    version: number;
    createDate: string;
    createBy: string;
    updateDate: string;
    updatedBy: string;
    availableDate?: string;
    agenda?: string;
    taskObjects?: IPerformanceReviewTaskObject[];
}

export type QuestionType = 'single-answer' | 'short-answer' | 'private-reflection' | 'multi-answers' | 'dropdown' | 'number' | 'ratings' | 'date-picker';

export interface IPerformanceReviewTaskQuestion {
    questionID: string; // generate on the frontend
    objectID?: string;
    qType?: QuestionType;
    order?: number;
    text?: string;
    rationale?: string;
    options?: string[];
    answer?: number;
    multipleAnswers?: number[];
    isSkippable?: boolean; // use for mandatory check, default value is false => questions is mandatory
}


export interface IPerformanceReviewAssign {
    taskID: string;
    orgID: string;
    cycleID: string;
    cycleConfigID?: string;
    assigneeOrgUserID: string;
    assigneeUserID?: string;
    managerUserID?: string;
    status: TASK_ASSIGN_STATUS_ENUM;
    action?: TASK_ASSIGN_ACTION_STATUS_ENUM;
    openDate?: string;
    assignDate?: string;
    dueDate?: string;
    staffDueDate?: string;
    managerDueDate?: string;
    completeDate?: string;
    templateID: string;
    taskTemplate?: IPerformanceReviewTaskTemplate;
    taskConfigID?: string;
    cycle?: IPerformanceReviewCycleConfig;
    canOpen: boolean;
    prStatus?: PR_ASSIGN_STATUS_ENUM;
  }

export interface IPerformanceReviewAssignFull extends IPerformanceReviewAssign {
    response?: IPerformanceReviewTaskResponse;
    managerComment?: IPerformanceReviewManagerComment;
    staffResponseDate?: string
}

export interface IPerformanceReviewAssignFullBackend {
    task: IPerformanceReviewAssign;
    staffResponse?: IPerformanceReviewTaskResponse;
    managerResponse?: IPerformanceReviewManagerComment
}

export interface IPerformanceReviewTaskConfig {
    taskConfigID: string;
    cycleConfigID: string;
    taskTemplateID: string;
    order?: number;
    dueDuration?: string;
    managerDueType?: PR_MANAGER_DUE_TYPE_ENUM;
    managerDueDuration?: string;
    assignDuration?: string;
    openDay?: number;
    openMonth?: number;
    dueDay?: number;
    dueMonth?: number;
    startDate?: string;
    endDate?: string;
    taskTemplate?: IPerformanceReviewTaskTemplate;
    progress?: number; // not in the API
}

export interface IPerformanceReviewTaskConfigForm {
    taskConfigID?: string;
    templateID: string;
    order?: number;
    dueDuration?: string;
    assignDuration?: string;
    openDay?: number;
    openMonth?: number;
    dueDay?: number;
    dueMonth?: number;
    startDate?: string;
    endDate?: string;
}

export interface IPerformanceReviewCycleConfig {
    cycleConfigID: string;
    orgID: string;
    title: string;
    description?: string;
    status: string; // Draft, Active, Archived
    cycleType: string;
    openDay?: number;
    openMonth?: number;
    duration?: string;
    startDate?: string;
    endDate?: string;
    activityNum?: number;
    enrolledUsers?: number;
    completionRate?: number; // not in API
    createDate?: string;
}

export interface IPerformanceReviewTaskConfigWithTemplates {
    taskConfig: IPerformanceReviewTaskConfig;
    taskTemplate?: IPerformanceReviewTaskTemplate;
}

export interface IPerformanceReviewCycleConfigWithExtras {
    config: IPerformanceReviewCycleConfig;
    taskConfigWithTemplates?: IPerformanceReviewTaskConfigWithTemplates[];
    enrolments?: IPerformanceReviewEnrolment[];
}

export interface IPerformanceReviewEnrolment {
    cycleEnrolmentID: string;
    cycleConfigID: string;
    orgUserID: string;
    enrolmentDate: string;
    unenrolmentDate?: string;
    orgUser?: IOrgUser; // not in the API
}

export interface IPerformanceReviewEnrolmentForm {
    cycleEnrolmentID?: string;
    orgUserID: string;
    enrolmentDate: string;
    unenrolmentDate?: string;
}

export enum CYCLE_TYPE_ENUM {
    once = 'Once-Off',
    annualEnrolment = 'Annual-Enrolment',
    annualDates = 'Annual-Dates',
    custom = 'Custom'
}

export const CYCLE_TYPE_Dict = {
    [CYCLE_TYPE_ENUM.once]: 'Once Only Cycle',
    [CYCLE_TYPE_ENUM.annualEnrolment]: 'Annual Cycle on Enrolment Date',
    [CYCLE_TYPE_ENUM.annualDates]: 'Annual Cycle on Selected Date',
    [CYCLE_TYPE_ENUM.custom]: 'Cycle on Custom Date',
};

export enum TASK_ASSIGN_STATUS_ENUM {
    open = 'Assigned',
    completed = 'Completed',
    overdue = 'Overdue',
    scheduled = 'Scheduled',
    skipped = "Skipped"
}

export enum TASK_ASSIGN_ACTION_STATUS_ENUM {
    managerToComplete = 'Manager to Complete',
    staffToComplete = 'Staff to Complete',
    noFurtherAction = 'No Further Action',
    managerToReview = 'Manager to Review',
    notApplicable = 'Not Applicable'
}

export type CYCLE_TYPE = CYCLE_TYPE_ENUM.once | CYCLE_TYPE_ENUM.annualEnrolment | CYCLE_TYPE_ENUM.annualDates | CYCLE_TYPE_ENUM.custom;

export enum ECycleStatus {
    completed = 'completed',
    inProgress = 'in-progress',
}


export interface IPerformanceReviewTaskObject {
    objectID: string;
    objectType: TASK_TEMPLATE_TYPE;
    name?: string;
    order?: number;
    references?: string[];
    linkedResource?: string;
    questions?: IPerformanceReviewTaskQuestion[];
}



export enum TASK_TEMPLATE_ENUM {
    assessment = 'Assessment',
    meeting = 'Meeting',
}

export enum TASK_TEMPLATE_STATUS {
    publish = 'Published',
    draft = 'Draft',
}

export enum PR_MANAGER_DUE_TYPE_ENUM {
    none = 'None',
    afterStaffDueDate = 'After Staff Due Date',
    afterStaffCompletion = 'After Staff Completion',
}

export type TASK_TEMPLATE_TYPE = TASK_TEMPLATE_ENUM.meeting | TASK_TEMPLATE_ENUM.assessment;
export const TEMPLATE_TYPE_DISPLAY: Map<TASK_TEMPLATE_TYPE, string> = new Map([[TASK_TEMPLATE_ENUM.meeting, 'Meeting'], [TASK_TEMPLATE_ENUM.assessment, 'Self Assessment']]);
export const TEMPLATE_TYPE_OPTIONS = [
    { label: 'Assessment', value: 'Assessment' },
    { label: 'Meeting', value: 'Meeting' }
];

export type TaskQuestionType = 'single-answer' | 'short-answer' | 'private-reflection' | 'multi-answers' | 'dropdown' | 'number' | 'ratings' | 'date-picker';

export interface IPerformanceReviewTaskResponse {
    version: number;
    responses: IPerformanceReviewTaskQuestionResponse[];
    responseDate?: string;
}

export interface IPerformanceReviewTaskQuestionResponse {
    questionID: string;
    answerOption?: number;
    // @ak check w b2c
    multipleAnswers?: number[];
    answerText?: string;
}

export interface IPerformanceReviewManagerComment {
    version: number;
    responses: IPerformanceReviewManagerResponse[];
    note?: string;
    responseDate?: string;
}

export interface IPerformanceReviewManagerResponse {
    questionID: string;
    answerOption?: number;
    multipleAnswers?: number[];
    answerText?: string | number;
    responseText?: string;  // comment
}


export interface ITablePerformanceReviewAssign extends IPerformanceReviewAssign, ITableUser {
    taskManager?: IOrgUser;
    taskTypeDisplay?: string;
}
export interface ITablePerformanceReviewAssignFull extends IPerformanceReviewAssignFull, ITableUser {
    taskManager?: IOrgUser;
    taskTypeDisplay?: string;
}

export interface ITaskQueryForm {
    filteredOrgUsers?: string[];
    filteredUsers?: string[];
    filteredCycles?: string[];
    filteredStatuses?: string[];
    filteredManagers?: string[];
}

export interface IPerformanceReviewCycle {
    cycleID: string,
    orgID: string,
    cycleConfigID: string,
    orgUserID: string,
    userID?: string,
    managerUserID?: string,
    status: string, // Active, Completed, Discarded
    startDate: string,
    endDate: string
}

export const TEMPLATE_QUESTION_TYPE_OPTIONS: ISingleSelectorOptions<IPerformanceReviewQuestionType>[] = [{
    value: 'short-answer', display: 'Short Answer', slIcon: { name: 'shortAnswerIcon' }
},
{ value: 'private-reflection', display: 'Long Answer', slIcon: { name: 'paragraph-justified-align' } },
{ value: 'single-answer', display: 'Multiple choice', hasBottomBorder: true, slIcon: { name: 'button-record-alternate' } },
{ value: 'multi-answers', display: 'Checkbox', slIcon: { name: 'check-2-alternate' } },
{ value: 'dropdown', display: 'Dropdown', slIcon: { name: 'arrow-button-down-2' } },
{ value: 'number', hasBottomBorder: true, display: 'Number Field', slIcon: { name: 'type-cursor-1' } },
{ value: 'ratings', display: 'Rating (1 to 10)', slIcon: { name: 'rating-star-alternate' } },
{ value: 'date-picker', display: 'Date Picker', slIcon: { name: 'calendar' } },
    // {value: 'upload', display: 'File Upload', slIcon: {name: 'cloud-upload'}},
];


export const CYCLES_DES = [
    {
        cycleType: CYCLE_TYPE_ENUM.once,
        title: 'Once Only Cycle',
        description: 'Add a review cycle that users must complete once - such as a probation review period for staff who have recently joined your organisation',
        image: 'assets/images/performance-review/cycle-once-off.svg',
        icon: 'task-list-checklist',
        bgColor: 'bg-green-100',
        color: 'text-green-800',
        available: true
    },
    {
        cycleType: CYCLE_TYPE_ENUM.annualEnrolment,
        title: `Annual Cycle on Enrolment Date`,
        description: 'Add a review cycle that reoccurs every year based on the users enrolment date - such as an annual performance review for front line staff on their work start date',
        image: 'assets/images/performance-review/cycle-annual-fixed.svg',
        icon: 'human-resource-employee',
        bgColor: 'bg-yellow-100',
        color: 'text-yellow-800',
        available: true
    },
    {
        cycleType: CYCLE_TYPE_ENUM.annualDates,
        title: 'Annual Cycle on Selected Date',
        description: `Add a review cycle that reoccurs every year based on fixed start and end dates - such as an annual performance review for all head office staff in a given month`,
        image: 'assets/images/performance-review/cycle-annual-custom.svg',
        icon: 'plane-trip-round',
        bgColor: 'bg-blue-100',
        color: 'text-blue-800',
        available: true
    }
    // {
    //     cycleType: CYCLE_TYPE_ENUM.custom,
    //     title: 'Custom',
    //     description: `Create your own custom cycle with our intuitive scheduling options`,
    //     shortDesc: 'Custom cycle',
    //     image: 'assets/images/performance-review/cycle-custom.svg',
    //     icon: 'cog-double',
    //     bgColor: 'bg-neutral-100',
    //     color: 'text-neutral-800',
    //     available: false
    // }
];

export interface IPerformanceReviewFetchTaskWithResponsePayload {
    filteredCycleConfigIDs?: string[],
    filteredTemplateIDs?: string[],
    filteredOrgUsers?: string[],
    filteredUsers?: string[],
    filteredManagers?: string[],
    filteredStatuses?: string[]
}

@Injectable({
    providedIn: 'root'
})
export class PerformanceReviewService {
    private _orgID = localStorage.getItem('orgID');
    http = inject(HttpClient);
    colService = inject(SmartTableColumnsService);
    errorHandlingService = inject(ErrorHandlingService);
    broadcastService = inject(BroadcastService);
    userService = inject(UserService);
    hierarchyService = inject(HierarchyService);
    tableUtil = inject(TableColumnValuePrepareAndSortUtilsService);
    private analyticsPrService = inject(AnalyticsPrService);
    modalService = inject(NgbModal);
    perfReviewPermissionService = inject(PerfReviewPermissionsService);

    orgActivities: ILearningGoal[];
    cycleConfigs: IPerformanceReviewCycleConfig[];
    private _cycleConfigsCacher: DataCacher<IPerformanceReviewCycleConfig[]> = new DataCacher<IPerformanceReviewCycleConfig[]>(this.fetchCycleConfigs(this._orgID), this.broadcastService);
    private _cycleConfigWithExtrasCacher: DataCacher<IPerformanceReviewCycleConfigWithExtras[]> = new DataCacher<IPerformanceReviewCycleConfigWithExtras[]>(this.fetchCycleConfigsWithExtras(this._orgID), this.broadcastService);

    _myTasksCacher = new DataCacher<ITablePerformanceReviewAssign[]>(this.fetchMyTasks(), inject(BroadcastService));
    // _myTasksCacher = new DataCacher<ITablePerformanceReviewAssign[]>(this.fetchMyTasks(), inject(BroadcastService));
    private _allTemplatesCacher = new DataCacher<IPerformanceReviewTaskTemplate[]>(this.fetchTaskTemplates(), inject(BroadcastService));

    private _cycleCacher = new DataCacher<IPerformanceReviewCycle[]>(this.fetchCycles(), this.broadcastService)

    taskTemplateDictByTaskID: Dictionary<IPerformanceReviewTaskTemplate>;
    cycleDic: Dictionary<IPerformanceReviewCycle>;
    cycleConfigDic: Dictionary<IPerformanceReviewCycleConfig>;

    fetchTaskTemplates(): Observable<IPerformanceReviewTaskTemplate[]> {
        const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/perfReviewTaskTemplates`;
        return this.http.get<IPerformanceReviewTaskTemplateWithVersions[]>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            map(templates => {
                return templates.map(t => ({ ...t.template, currentVersion: t.currentVersion, pastVersions: t.pastVersions }))
            }),
            tap(templates => this.taskTemplateDictByTaskID = indexBy(templates, 'templateID') as any),
        );
    }

    fetchCacheTaskTemplates(refresh = false): Observable<IPerformanceReviewTaskTemplate[]> {
        return this._allTemplatesCacher.fetchCachedData(refresh);
    }

    // only shows templates that are relevant
    fetchCacheTaskTemplatesFiltered() {
        return combineLatest([
            this.fetchCacheTaskTemplates(),
            this.fetchCachedMyTasks()
        ]).pipe(map(([templates, tasks]) => {
            if (this.perfReviewPermissionService.hasAdminAccess()) {
                return templates;
            }
            const usedTemplates = new Set(uniq(tasks.map(t => t.templateID)))
            return templates.filter(t => usedTemplates.has(t.templateID))
        }))
    }

    getTemplateByID(id): Observable<IPerformanceReviewTaskTemplate> {
        const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/perfReviewTaskTemplates/${id}`;
        return this.http.get<IPerformanceReviewTaskTemplateWithVersions>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            tap(t => console.log(t)),
            map(t => ({ ...t.template, currentVersion: t.currentVersion, pastVersions: t.pastVersions }))
        )
    }

    deleteTaskTemplate(templateID: string) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}`;
        return this.http.delete<IPerformanceReviewTaskTemplateWithVersions[]>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    createTaskTemplate(payload: IPerformanceReviewTaskTemplateForm): Observable<IPerformanceReviewTaskTemplateWithVersions> {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates`;
        return this.http.post<IPerformanceReviewTaskTemplateWithVersions>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            tap(template => this.analyticsPrService.sendTemplateTrack('PR-activity-added', template.template))
        );
    }

    updateTaskTemplate(templateID: string, payload: IPerformanceReviewTaskTemplateForm) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}`;
        return this.http.put<IPerformanceReviewTaskTemplateWithVersions>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    // just for draft status
    updateCurrentVersion(templateID: string, taskObject: IPerformanceReviewTaskObject, agenda?: string) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}/versions/current`;
        const payload = {
          ...(agenda ? {agenda} : { taskObjects: [taskObject] }),
        };
        return this.http.put<IPerformanceReviewTaskTemplateWithVersions>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    // just for publish status
    addNewTemplateVersion(templateID: string, taskObject: IPerformanceReviewTaskObject) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}/versions`;
        const payload = { taskObjects: [taskObject] };
        return this.http.post<any>(url, payload).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    publishDraftTemplate(templateID: string) {
        const url = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/perfReviewTaskTemplates/${templateID}/publish`;
        return this.http.get<IPerformanceReviewTaskTemplateWithVersions>(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            tap(template => this.analyticsPrService.sendTemplateTrack('PR-activity-published', template.template))
        );
    }

    fetchCycleConfigs(orgID: string): Observable<IPerformanceReviewCycleConfig[]> {
        return this.http
            .get<IPerformanceReviewCycleConfig[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                tap(cycleConfigs => this.cycleConfigDic = indexBy(cycleConfigs, 'cycleConfigID')),
                tap(() => this.refreshTasks().subscribe())
            );
    }

    fetchCacheCycleConfigs(refresh = false): Observable<IPerformanceReviewCycleConfig[]> {
        return this._cycleConfigsCacher.fetchCachedData(refresh);
    }

    fetchCacheCycleConfigsFiltered() {
        return combineLatest([
            this.fetchCacheCycleConfigs(),
            this.fetchCachedMyTasks()
        ]).pipe(
            map(([cycles, tasks]) => {
                if (this.perfReviewPermissionService.hasAdminAccess()) {
                    return cycles
                }
                const usedCycles = new Set(uniq(tasks.map(t => t.cycle?.cycleConfigID)))
                return cycles.filter(c => usedCycles.has(c.cycleConfigID))
            })
        )
    }

    fetchActiveAndDraftCycleConfigs(): Observable<IPerformanceReviewCycleConfig[]> {
        return this.fetchCacheCycleConfigs().pipe(
            map(cycles => cycles.filter(c => c.status === 'Active' || c.status === 'Draft'))
        )
    }


    fetchCycleConfigsWithExtras(orgID: string): Observable<IPerformanceReviewCycleConfigWithExtras[]> {
        return this.http
            .get<IPerformanceReviewCycleConfigWithExtras[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigWithExtras`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                map(configs => {
                    return configs.map(c => {
                        return {
                            ...c,
                            enrolments: c.enrolments?.filter(e => this.userService.isManager ? true
                                : keys(this.userService.managedOrgUserDictionaryByOrgUserID)?.find(u => u === e.orgUserID))
                                ?.filter(e => this.userService.managedOrgUserDictionaryByOrgUserID[e.orgUserID]?.status !== 'In-active')
                                || []
                        }
                    })
                })
            );
    }

    fetchCacheCycleConfigsWithExtras(refresh = false): Observable<IPerformanceReviewCycleConfigWithExtras[]> {
        return this._cycleConfigWithExtrasCacher.fetchCachedData(refresh);
    }

    fetchCacheCycleConfigsWithExtrasFiltered() {
        return combineLatest([
            this.fetchCacheCycleConfigsWithExtras(),
            this.fetchCachedMyTasks()
        ]).pipe(
            map(([cycles, tasks]) => {
                if (this.perfReviewPermissionService.hasAdminAccess()) {
                    return cycles
                }
                const usedCycles = new Set(uniq(tasks.map(t => t.cycle?.cycleConfigID)))
                return cycles.filter(c => usedCycles.has(c.config.cycleConfigID))
            })
        )
    }


    addCycleConfigs(orgID: string, cycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .post<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs`, cycleConfig)
            .pipe(
                tap((cycleConfig) => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                    this.analyticsPrService.sendTrack('PR-cycle-added', this.analyticsPrService.getCycleInfo(cycleConfig))
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    fetchCycleConfig(orgID: string, configID: string): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .get<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${configID}`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    fetchCycleConfigWithExtras(orgID: string, configID: string): Observable<IPerformanceReviewCycleConfigWithExtras> {
        return this.http
            .get<IPerformanceReviewCycleConfigWithExtras>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigWithExtras/${configID}`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                map(config => {
                    return {
                        ...config,
                        enrolments: config.enrolments?.filter(e => this.userService.isManager ? true
                            : values(this.userService.managedOrgUserDictionaryByOrgUserID)?.find(u => u.orgUserID === e.orgUserID))
                            || []
                    }
                })
            );
    }

    updateCycleConfigs(orgID: string, cycleConfigID: string, newCycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .put<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}`, newCycleConfig)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    activateCycleConfigs(orgID: string, cycleConfig: IPerformanceReviewCycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .get<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/activate`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe(configs => {
                        const updated = configs.find(c => c.config?.cycleConfigID === cycleConfig.cycleConfigID)
                        if (updated) {
                            this.analyticsPrService.sendCycleExtrasTrack('PR-cycle-activated', updated);
                        }
                    });
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    archiveCycleConfigs(orgID: string, cycleConfig: IPerformanceReviewCycleConfig): Observable<IPerformanceReviewCycleConfig> {
        return this.http
            .get<IPerformanceReviewCycleConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/archive`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    deleteCycleConfigs(orgID: string, cycleConfig: IPerformanceReviewCycleConfig) {
        return this.http
            .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    refreshCycleConfigs(orgID: string, cycleConfigID: string) {
        return this.http
            .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/refresh`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    refreshAllCycleConfigs(orgID: string) {
        return this.http
            .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/refreshAll`)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            );
    }


    bulkAddCycleEnrolments(orgID: string, cycleConfig: IPerformanceReviewCycleConfig, enrolments: IPerformanceReviewEnrolmentForm[]): Observable<IPerformanceReviewEnrolment[]> {
        return this.http
            .post<IPerformanceReviewEnrolment[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/enrolments/bulk`, enrolments)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe(configs => {
                        const updated = configs.find(c => c.config?.cycleConfigID === cycleConfig.cycleConfigID)
                        if (updated) {
                            this.analyticsPrService.sendCycleExtrasTrack('PR-cycle-users_enrolled', updated);
                        }
                    });
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    bulkUpdateCycleEnrolments(orgID: string, cycleConfig: IPerformanceReviewCycleConfig, enrolments: IPerformanceReviewEnrolmentForm[]): Observable<IPerformanceReviewEnrolment[]> {
        return this.http
            .put<IPerformanceReviewEnrolment[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfig.cycleConfigID}/enrolments/bulk`, enrolments)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    bulkDeleteCycleEnrolments(orgID: string, cycleConfigID: string, enrolments: IPerformanceReviewEnrolment[]) {
        return this.http
            .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/enrolments/bulk`, { body: enrolments.map(e => e.cycleEnrolmentID) })
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    addComment(taskID, payload: { version: number; responses: IPerformanceReviewManagerResponse[] }): Observable<IPerformanceReviewAssignFullBackend> {
        return this.http.post<IPerformanceReviewAssignFullBackend>(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/managerPerfReviewTasks/${taskID}/responses`, payload)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            )
    }

    saveDraftComment(taskID, payload: { version: number; responses: IPerformanceReviewManagerResponse[] }): Observable<IPerformanceReviewAssignFullBackend> {
        return this.http.post<IPerformanceReviewAssignFullBackend>(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/managerPerfReviewTasks/${taskID}/draftResponses`, payload)
            .pipe(
                catchError(this.errorHandlingService.handleHttpError)
            )
    }

    fetchMyTasks() {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/sgReports/perfReviewTasks/all`;
        return this.http.post<ITablePerformanceReviewAssign[]>(endPoint, {})
            .pipe(
                map(data => data || []),
                catchError(this.errorHandlingService.handleHttpError),
            )
    }

    fetchMyTaskFull(): Observable<ITablePerformanceReviewAssignFull[]> {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${this._orgID}/sgReports/perfReviewTaskWithResponses/all`;
        const tasks$: Observable<IPerformanceReviewAssignFull[]> = this.http.post<IPerformanceReviewAssignFullBackend[]>(endPoint, {}).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            map(tasks => tasks.map(t => ({ ...t.task, response: t.staffResponse, managerComment: t.managerResponse,
                staffResponseDate: addZIfMissing(t.staffResponse?.responseDate)}))),
        )
        return this.processTasks(tasks$);
    }

    fetchCachedMyTasks(refresh = false): Observable<ITablePerformanceReviewAssign[]> {
        return this.processTasks(this._myTasksCacher.fetchCachedData(refresh))
    }

    processTasks(tasks$: Observable<IPerformanceReviewAssign[]>) {
        return combineLatest([
            tasks$.pipe(map(tasks => this.filterTasks(tasks))),
            this.fetchCacheCycleConfigs(),
            this.fetchCacheTaskTemplates(),
            this.fetchCachedCycles()
        ]).pipe(map(([tasks, configs, _,]) => {
            return tasks?.map(t => ({
                ...t,
                cycle: this.cycleConfigDic[this.cycleDic[t.cycleID]?.cycleConfigID],
                taskTemplate: this.taskTemplateDictByTaskID[t.templateID]
            })).map((a, i) => {
                const recalculateDueDate = this.recalculateDueDate(a);
                const status = getTaskStatusDisplay(a, recalculateDueDate);
                const action = a.action;

                return ({
                    ...a,
                    ...this.colService.getUserTableSimple(a.assigneeOrgUserID),
                    assignDate: a.openDate,
                    status: status,
                    prStatus: getPrAssignStatus(status, action),
                    taskManager: this.getTaskManager(a),
                    taskTypeDisplay: TEMPLATE_TYPE_DISPLAY.get(a.taskTemplate.taskType),
                    dueDate: recalculateDueDate,
                    // has all access OR task manager
                    canOpen: this.perfReviewPermissionService.hasAllUserAccess() || a.managerUserID === this.userService.plainOrgUser.userID,
                })
            }) || []
        }),
            map(tasks => tasks.filter(t => t.accountStatus !== USER_STATUS.inactive)),
            map(tasks => this.sortTasks(tasks)));
    }

    filterTasks<T extends IPerformanceReviewAssign>(tasks: T[]) {

        if (this.perfReviewPermissionService.hasAllUserAccess()) {
            return tasks;
        }
        const userSet = this.perfReviewPermissionService.accessibleUsersSet()
        return tasks.filter(t => userSet.has(t.assigneeOrgUserID))
    }

    sortTasks(tasks: ITablePerformanceReviewAssign[]) {
        const order = new Map<TASK_ASSIGN_STATUS_ENUM, number>([
            [TASK_ASSIGN_STATUS_ENUM.overdue, 0],
            [TASK_ASSIGN_STATUS_ENUM.open, 1], [TASK_ASSIGN_STATUS_ENUM.completed, 2], [TASK_ASSIGN_STATUS_ENUM.scheduled, 3]
        ])
        return tasks.sort((a, b) => this.tableUtil.singleStringCompareFunction(1, a.dueDate, b.dueDate))
            .sort((a, b) => order.get(a.status) - order.get(b.status))
    }

    getTaskManager(task: IPerformanceReviewAssign) {
        if (!task.managerUserID) {
            return null;
        }
        return this.userService.isManager ?
            this.userService.managedOrgUserDictionaryByUserID[task.managerUserID] :
            this.userService.plainOrgUser.userID === task.managerUserID ? this.userService.orgUser.value :
                null;
    }

    /**
     * recalculate the task current due date based on its status
     * @param task
     * @returns
     */
    recalculateDueDate(task: IPerformanceReviewAssign): string {
        let calculatedDueDate: string = task.dueDate;

        if (!task.action) return calculatedDueDate;

        if (task.action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToComplete
            || task.action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToReview
        ) {
            calculatedDueDate = task.managerDueDate;
        }
        if (task.action === TASK_ASSIGN_ACTION_STATUS_ENUM.staffToComplete) {
            calculatedDueDate = task.staffDueDate;
        }

        return calculatedDueDate;
    }

    updateCachedMyTasks(updatedTask: IPerformanceReviewAssign) {
        return this._myTasksCacher.update((current) => {
            const updatedEl = current.find(el => el.taskID === updatedTask.taskID);
            if (updatedEl) {
                updatedEl.status = updatedTask.status;
                updatedEl.completeDate = updatedTask.completeDate;
                updatedEl.action = updatedTask.action
                // updatedEl. = updatedTask.managerComment
            }
            return clone(current);
        })
    }

    fetchFullTaskByID(taskID: string): Observable<ITablePerformanceReviewAssignFull> {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/perfReviewTasks/${taskID}`;
        // return this.getMockAssigns().pipe(map(r => r[2]))
        return this.processTasks(this.http.get<IPerformanceReviewAssignFullBackend>(endPoint).pipe(
            catchError(this.errorHandlingService.handleHttpError),
            map(t => ({ ...t.task, response: t.staffResponse, managerComment: t.managerResponse })),
            map(t => [t]), // convert to array
        )).pipe(map(arr => arr[0])) //
    }

    fetchCycles() {
        const endPoint = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/perfReviewCycles/all`;
        return this.http.post<IPerformanceReviewCycle[]>(endPoint, {})
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                tap(cycles => this.cycleDic = indexBy(cycles, 'cycleID'))
            )
    }

    fetchCachedCycles(refresh = false) {
        return this._cycleCacher.fetchCachedData(refresh);
    }

    refreshTasks() {
        return combineLatest([this.fetchCachedMyTasks(true), this.fetchCachedCycles(true)])
    }

    addCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfig): Observable<IPerformanceReviewTaskConfig> {
        return this.http
            .post<IPerformanceReviewTaskConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs`, taskConfig)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe(configs => {
                        const updated = configs.find(c => c.config?.cycleConfigID === cycleConfigID)
                        if (updated) {
                            const template = updated.taskConfigWithTemplates.find(withTemplates => withTemplates.taskTemplate?.templateID === taskConfig?.taskTemplateID)?.taskTemplate;
                            if (template) {
                                this.analyticsPrService.sendTrack('PR-cycle-activities_added', {
                                    ...this.analyticsPrService.getCycleInfoWithExtras(updated),
                                    ...this.analyticsPrService.getTemplateInfo(template)
                                })
                            }
                        }

                    });
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    updateCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfigID: string, taskConfig): Observable<IPerformanceReviewTaskConfig> {
        return this.http
            .put<IPerformanceReviewTaskConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs/${taskConfigID}`, taskConfig)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    removeCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfigID: string) {
        return this.http
            .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs/${taskConfigID}`)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    reorderCycleTaskConfig(orgID: string, cycleConfigID: string, taskConfigOrders): Observable<IPerformanceReviewTaskConfig> {
        return this.http
            .post<IPerformanceReviewTaskConfig>(`${environment.accountServiceEndpoint}/orgs/${orgID}/perfReviewCycleConfigs/${cycleConfigID}/taskConfigs/orders`, taskConfigOrders)
            .pipe(
                tap(() => {
                    this.fetchCacheCycleConfigs(true).subscribe();
                    this.fetchCacheCycleConfigsWithExtras(true).subscribe();
                }),
                catchError(this.errorHandlingService.handleHttpError)
            );
    }

    taskRemind(taskIDs: string[]) {
        return this.http.post<IPerformanceReviewAssign[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/remindTasks`, { taskIDs })
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                switchMap(res => res?.length === 0 ? throwError('Task not found') : of(res)),
            );
    }

    markAsSkipped(taskIDs: string[]): Observable<IPerformanceReviewAssign[]> {
        return this.http.post<IPerformanceReviewAssign[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/managerPerfReviewTasks/markAsSkipped/bulk`, { taskIDs })
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
                switchMap(res => res?.length === 0 ? throwError('Task not found') : of(res.map((t: any) => t.task))),
            );
    }

    remindModal(task: ITablePerformanceReviewAssign, context) {
        const modal = this.modalService.open(TaskRemindModalComponent, { size: 'xl' });
        modal.componentInstance.task.set(task);
        modal.componentInstance.context = context;
    }

    isMeetingWithTextBox(template: IPerformanceReviewTaskTemplate){
        if (template?.taskType === TASK_TEMPLATE_ENUM.meeting) {
            if (!template?.currentVersion?.taskObjects?.[0]?.questions?.length && !template?.description?.length) {
                return true;
            } else {
                return false;
            }
      } else { // self-assessment
        return false;
      }
    }

    handleCycleEnrolmentsWhenAddingNewUser(
        orgUser: IOrgUser,
        enrolledCycles: IEnrolledCycle[]
    ): Observable<IOrgUser> {
        let processedEnrolledCycles: IEnrolledCycle[];
        processedEnrolledCycles = enrolledCycles
            .filter(c => c.cycleConfig !== null && c.enrolment?.enrolmentDate !== null)
            .map(c => {
                return ({
                    cycleConfig: c.cycleConfig,
                    enrolment: {
                        orgUserID: orgUser.orgUserID,
                        enrolmentDate: c.enrolment.enrolmentDate,
                    },
                });
            });
        console.log('😋processedEnrolledCycles', processedEnrolledCycles);

        const requests = processedEnrolledCycles.map(cycle => this.bulkAddCycleEnrolments(
            cycle.cycleConfig.orgID,
            cycle.cycleConfig,
            [cycle.enrolment as IPerformanceReviewEnrolmentForm],
        ));
        console.log('requests', requests);


        return forkJoin(requests).pipe(
            switchMap(result => of(orgUser))
        );
    }
}


export function getAnswerText(task: IPerformanceReviewAssignFull, question: IPerformanceReviewTaskQuestion) {
    let response;
    if (task.taskTemplate.taskType === TASK_TEMPLATE_ENUM.assessment) {
        response = task.response?.responses?.find(r => r.questionID === question.questionID);
    }
    if (task.taskTemplate.taskType === TASK_TEMPLATE_ENUM.meeting) {
        response = task.managerComment?.responses?.find(r => r.questionID === question.questionID);
    }

    if (!response) {
        return '';
    }
    if (response.answerText) {
        return response.answerText;
    }
    if (!question.options?.length) {
        return ''
    }
    if (response.answerOption || response.answerOption === 0) {
        return question.options[+response.answerOption] || response.answerOption
    }
    if (response.multipleAnswers?.length) {
        return response.multipleAnswers.map(r => question.options[r]);
    }

}

/**
 * if any assign's status has 'Assigned' or 'Overdue' or 'Scheduled', it's an in progress cycle
 * @param assigns 
 */
export function getCycleStatus(assigns: IPerformanceReviewAssign[]): ECycleStatus {
    const isInProgress = assigns.some(a => a.status === TASK_ASSIGN_STATUS_ENUM.open || a.status === TASK_ASSIGN_STATUS_ENUM.overdue || a.status === TASK_ASSIGN_STATUS_ENUM.scheduled);
    return isInProgress ? ECycleStatus.inProgress : ECycleStatus.completed;
}
