import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { every, map, pluck, some, uniq } from 'underscore';
import { TruncatedCellComponent } from '../shared/components/truncated-cell/truncated-cell.component';
import { TableColumnValuePrepareAndSortUtilsService } from './table-column-value-prepare-and-sort-utils.service';
import { map as rxjsMap, tap} from 'rxjs/operators';
import { addUserNames, downloadReport, ICsvConfig, preprocessColumns } from '../shared/utils/export-csv';
import { TrainingPlanCellComponent } from '@components/learning-table-popovers/training-plan-cell.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { TeamNamesCellComponent } from '@components/learning-table-popovers/team-names-cell.component';
import { JobRoleTitlesCellComponent } from '@components/learning-table-popovers/jobrole-titles-cell.component';
import { Ng2SmartTableComponent } from '../shared-modules/ng2-smart-table/ng2-smart-table.component';
import { TrainingPlanNameCellComponent } from '@components/training-plan-name-cell/training-plan-name-cell.component';
import { CpdTimePipe } from '../shared/pipes/cpd-time.pipe';
import { LocalDatePipe } from '../shared/pipes/local-date.pipe';
import { CustomEditEnrolDateComponent } from '../pages/mandatory-training/assign-to-plan/steps/enrol-to-mt-review-uploaded-users/enrol-to-mt-review-uploaded-users.component';

export interface IShownItems {
  showingMinus: boolean;
  showClearSelection: boolean;
}

export interface IMultiSelectData {
  availableItems: number;
  shownItems: IShownItems;
  table: Ng2SmartTableComponent;
  keyName: string;
  tableRef: string;
}


@Injectable({
  providedIn: 'root'
})
export class SmartTableService {
  renderer: Renderer2;

  readonly tableSettings = {
    displayName: {
      title: 'User',
      type: 'custom',
      renderComponent: StaffLinkDisplayComponent,
      onComponentInitFunction(instance) {
        instance.className = 'displayName';
      },
      compareFunction: this._columnUtil.compareByProperty('fullName'),
      filterFunction: this._columnUtil.searchByProperty('fullName'),
      width: '216px'
    },
    title: {
      title: 'Learning Record Title',
      type: 'custom',
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction: (instance) => {
        instance.className = 'title';
      }
    },
    email: {
      title: 'Account Email',
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      type: 'custom',
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction: (instance) => {
        instance.className = 'email';
      },
      defaultToShow: false,
    },
    orgEmail: {
      title: 'Work Email',
      type: 'custom',
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction(instance) {
        instance.className = 'email';
      },
      compareFunction: this._columnUtil.generalCompare(),
      defaultToShow: true,
    },
    orgMobile: {
      title: 'Mobile Number',
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      type: 'string',
      sort: false,
    },
    workType: {
      title: 'Employment Status',
      defaultToShow: false,
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      type: 'string',
      compareFunction: this._columnUtil.generalCompare()
      // sort: false,
    },
    staffID: {
      title: 'User ID',
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      compareFunction: this._columnUtil.generalCompare(),
      type: 'custom',
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction(instance) {
        instance.className = 'staffID';
      },
    },
    mtPlanCell: {
      title: 'Source',
      type: 'custom',
      compareFunction: this._columnUtil.compareByProperty('text'),
      filterFunction: this._columnUtil.searchByProperty('text'),
      renderComponent: TrainingPlanCellComponent,
    },
    mtPlanID: { //Input: TP object
      title: 'Training Plans',
      type: 'custom',
      // compareFunction: this._columnUtil.compareByProperty('text'),
      // filterFunction: this._columnUtil.searchByProperty('text'),
      renderComponent: TrainingPlanNameCellComponent,
    },
    teams: {
      title: 'Teams',
      type: 'custom',
      renderComponent: TeamNamesCellComponent,
      valuePrepareFunction: this._columnUtil.extractByProperty('name'),
      sort: false,
      filter: false,
    },
    jobRoles: {
      title: 'Job Roles',
      sort: false,
      filter: false,
      type: 'custom',
      renderComponent: JobRoleTitlesCellComponent,
      valuePrepareFunction: this._columnUtil.extractByProperty('title'),
    },
    resourceDuration: {
      title: 'Resource Duration (Minutes)',
      defaultToShow: false,
      sort: false,
      filter: true,
      valuePrepareFunction: min => this._columnUtil.toCPDTime(min, this.cpdPipe),
    },
    workStartDate: {
      title: 'Work Start Date',
      filter: true,
      defaultToShow: false,
      valuePrepareFunction: date => {
        if (date) {
          return this._columnUtil.toLocalDate(date, this._localDatePipe);
        } else {
          return '-';
        }
      },
    },
    enrolDate: {
      title: 'Enrolment Date',
      sort: false,
      filter: false,
      valuePrepareFunction: (val) => this._localDatePipe.transform(val),
      editor: {
        type: 'custom',
        component: CustomEditEnrolDateComponent,
      },
    },
  };


  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
    private http: HttpClient,
    private cpdPipe: CpdTimePipe,
    private _localDatePipe: LocalDatePipe,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * MULTI-SELECTION FUNCTIONS
   * Note: these functions directly change component table properties
   */

  onUserRowSelect(event, selectedItems, tableData: IMultiSelectData) {
    const { availableItems, shownItems, table, keyName, tableRef } = tableData;
    const allSelected = selectedItems.length === availableItems;
    if (event.data === null) {
      // Select all/none
      // this.selectedItems = event.selected.length ? this.selectedItems.concat(event.selected) : [];

      // Select all/none
      if (event.selected.length && !shownItems.showingMinus) {
        // add items that's not already in
        selectedItems = uniq(selectedItems.concat(event.selected), item => item[keyName]);
      } else {
        // filter out the rows in page
        const pageRowIDs = map(table.grid.getRows(), (row => row.getData()[keyName]));
        selectedItems = selectedItems.filter(row => !pageRowIDs.includes(row[keyName]));

        if (allSelected) {
          shownItems.showClearSelection = true;
        }
      }
      this.hideMinusButton(tableData);

    } else {
      if (event.isSelected) {
        selectedItems = uniq(selectedItems.concat(event.selected));
      } else {
        // if (this.table.isAllSelected) {
        //     this.showMinusButton();
        // }
        const index = pluck(selectedItems, keyName).indexOf(event.data[keyName]);
        if (index !== -1) {
          selectedItems.splice(index, 1);
        }
        if (allSelected) {
          shownItems.showClearSelection = true;
        }
      }
    }
    if (selectedItems.length === 0) {
      shownItems.showClearSelection = false;
    }
    if (selectedItems.length === availableItems) {
      shownItems.showClearSelection = true;
    }
    // this.userRowSelect.emit(this.selectedItems);
    // this.cdRef.detectChanges();
    return selectedItems;
  }

  hideMinusButton(tableData: IMultiSelectData) {
    const { tableRef, shownItems } = tableData;
    const selectAllCheckbox = this.document.querySelector(tableRef + ' th[ng2-st-checkbox-select-all] > input');
    if (!selectAllCheckbox) {
      return;
    }
    this.renderer.setProperty(selectAllCheckbox, 'indeterminate', '');
    shownItems.showingMinus = false;
  }

  updateCheckAllBox(tableData: IMultiSelectData) {
    const allRows = tableData.table.grid.getRows();
    const selArray = pluck(allRows, 'isSelected');
    // hide minus button unless some but not all rows are checked
    this.hideMinusButton(tableData);
    if (every(selArray) && allRows.length) {
      tableData.table.isAllSelected = true;
    } else {
      tableData.table.isAllSelected = false;
      if (some(selArray)) {
        this.showMinusButton(tableData);
      }
    }
  }

  showMinusButton(tableData: IMultiSelectData) {
    const { tableRef, shownItems } = tableData;
    const selectAllCheckbox = this.document.querySelector(tableRef + ' th[ng2-st-checkbox-select-all] > input');
    if (!selectAllCheckbox) {
      return;
    }
    this.renderer.setProperty(selectAllCheckbox, 'indeterminate', 'true');
    shownItems.showingMinus = true;
  }

  syncTable(selectedItems, tableData: IMultiSelectData) {
    if (!tableData.table) {
      return;
    }
    const disabledIndexes = [];
    tableData.table.grid.getRows().forEach((row, index) => {
      // console.log(row);
      if (selectedItems.find(i => i[tableData.keyName] === row.getData()[tableData.keyName])) {
        row.isSelected = true;
      } else {
        row.isSelected = false;
      }

      if (row.getData().isDisabled) {
        disabledIndexes.push(index);
      }
    });
    this.disableCheckboxes(disabledIndexes, tableData.tableRef);
    this.updateCheckAllBox(tableData);
    // return tableData.table;
  }

  onSelectAll(tableData: IMultiSelectData) {
    this.hideMinusButton(tableData);
    tableData.shownItems.showClearSelection = true;
  }

  onDeselectAll(tableData: IMultiSelectData) {
    this.hideMinusButton(tableData);
    tableData.shownItems.showClearSelection = false;
  }

  disableCheckboxes(indexes: number[], tableRef) {
    const checkbox = this.document.querySelectorAll(
      tableRef + ' td > input[type=checkbox]'
    );
    checkbox.forEach((element, index) => {
      if (indexes.includes(index)) {
        this.renderer.setAttribute(element, 'disabled', 'true');
      }
    });
  }
   /**
   * MULTI-SELECTION FUNCTIONS ENDS
   */


  // Note: watch for nested col values like orgUser
  downloadServerTable(url, payload, fileName, columns, mapFn, options?) {
    return this.http.post<any[]>(url, payload, {params: options?.params}).pipe(
      // tap(resp => console.log(resp)),
      rxjsMap(resp => {
        return mapFn(resp);
      }),
      // tap((resp) => console.log(resp)),
      tap(extendedRecords => {
        columns = preprocessColumns(columns)
        const headers = Object.keys(columns).map(key => columns[key].title);
        const data = extendedRecords.map(d => {
          const processedRow = {};
          Object.keys(columns).forEach((key) => {
            if (columns[key].valuePrepareFunction) {
                processedRow[key] = columns[key].valuePrepareFunction(d[key]);
            } else {
              processedRow[key] = d[key];
              if (key === 'firstName' || key === 'lastName') {
                addUserNames(processedRow, d, 'orgUser');
              }
            }
          });
          return processedRow;
        });
        const args = {
          data,
          title: fileName,
          headers,
          orgUserFields: ['orgUser'],
          cpdPipeField: [],
          fileName: `${fileName}.csv`,
        } as ICsvConfig;
        downloadReport(args);
      })
    );
  }
}
