import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { copyToClipboard } from '../../utils/copy-to-clipboard';
import { COL_WIDTHS, getMaxWidth, getWidthFromClass } from '../../utils/table-cell-functions';
import { CopyButtonComponent } from './copy-button/copy-button.component';
import { TooltipUnderlineComponent } from '../tooltip-underline/tooltip-underline.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-truncated-cell',
    template: `
              <div class="d-flex justify-content-between" [style]="'width: ' + maxWidth + 'px;'">
                <div #text class="text-nowrap text-truncate cursor-pointer">
                    <span *ngIf="!showTooltip" [class]='displayTextClass'>{{ value }}</span>
                    <!-- <span [ngbTooltip]="value" [container]="'body'">{{ value }}</span> -->
                    <ui-tooltip-underline *ngIf="showTooltip"
                      [bodyText]="value"
                      [displayText]="value"
                      container="body"
                      [displayTextClass]="'cursor-pointer text-neutral-900 f-small text-truncate ' + displayTextClass"
                      [inlineBlock]="false"
                      [placement]="showCopyButton ? 'left auto' : 'top-left auto'"
                    ></ui-tooltip-underline>
                </div>

                <app-copy-button *ngIf="showCopyButton" [textToCopy]="value"></app-copy-button>
              </div>
            `,
    standalone: true,
    imports: [NgIf, TooltipUnderlineComponent, CopyButtonComponent]
})
export class TruncatedCellComponent implements OnInit, AfterViewInit {
  maxWidth;
  @Input() value: any;
  @Input() rowData: any;
  @Input() showCopyButton = false;
  @Input() linkURL;
  @Input() displayTextClass = '';
  className: string;
  isModal = false;
  @ViewChild('text') text;
  showTooltip = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private toaster: ToastrService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    // console.log(this.className);
    this.maxWidth = getWidthFromClass(this.className, this.maxWidth);
    this.maxWidth = getMaxWidth(this.className, this.isModal, this.maxWidth) || this.maxWidth;
  }

  ngAfterViewInit() {
    const el = this.text.nativeElement;
    if (el.scrollWidth > el.clientWidth) {
      this.showTooltip = true;
      this._cdr.detectChanges();
    }
  }

  calllinkURL() {
    if( this.linkURL ) {
      this.router.navigate(this.linkURL);
    } else {
      void(0);
    }
  }
}
