<div class="flex-wrap" [class.d-flex]="allign==='horizontal'">
    <div [style]="allign==='horizontal'?'width: 10rem':''" class="mb-4" [class.me-2]="allign==='horizontal'">
        <label class="f-small text-neutral-800 mb-1" for="selectCategoryId">Category</label>
        <app-customised-single-select
                [submitted]="submitted"
                [searchable]="false"
                id="selectCategoryId"
                [controls]="controls"
                controlName="filterBy"
                [options]="categories"
                displayName="Category"
        ></app-customised-single-select>
    </div>
    <div [hidden]="hideAccountStatus" [style]="allign==='horizontal'?'width: 122px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
        <label class="f-small text-neutral-800 mb-1" for="selectUserStatus">Account Status</label>
        <app-customised-multi-select
                [includesTextAll]="false"
                [autoGrow]="autoGrow"
                [submitted]="submitted"
                id="selectUserStatus"
                [controls]="controls"
                [options]="STATUSES"
                controlName="userStatus"
                displayName="Status"
                placeholder="Select Status"
                errorMsg="Status is required"
        ></app-customised-multi-select>
    </div>
    <div [ngSwitch]="controls.filterBy.value">
        <div *ngSwitchCase="CATEGORIES_ENUM.individual" [style]="allign==='horizontal'?'width: 200px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
            <label class="f-small text-neutral-800 mb-1" for="selectUserId">Category Type</label>
            <app-customised-multi-select
                    [autoGrow]="false"
                    [submitted]="submitted"
                    id="selectUserId"
                    [controls]="controls"
                    [options]="users"
                    controlName="items"
                    displayName="User"
                    [selectAll]="true"
                    [fixedWidth]="userFixedWidth"
            ></app-customised-multi-select>
        </div>
        <div *ngSwitchCase="CATEGORIES_ENUM.teams" [style]="allign==='horizontal'?'width: 200px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
            <label class="f-small text-neutral-800 mb-1" for="selectTeamId">Category Type</label>
            <app-customised-multi-select
                    [autoGrow]="autoGrow"
                    [submitted]="submitted"
                    id="selectTeamId"
                    [controls]="controls"
                    controlName="items"
                    [options]="teams"
                    displayName="Team"
                    placeholder="Select Team"
            ></app-customised-multi-select>
        </div>
        <div *ngSwitchCase="CATEGORIES_ENUM.jobRoles" [style]="allign==='horizontal'?'width: 200px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
            <label class="f-small text-neutral-800 mb-1" for="selectJobRoleId">Category Type</label>
            <app-customised-multi-select
                    [submitted]="submitted"
                    id="selectJobRoleId"
                    [controls]="controls"
                    controlName="items"
                    [options]="jobRoles"
                    displayName="Job Role"
                    placeholder="Select Job Role"
                    [autoGrow]="autoGrow"
            ></app-customised-multi-select>
        </div>
        <div *ngSwitchCase="CATEGORIES_ENUM.jobRoleTypes" [style]="allign==='horizontal'?'width: 200px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
            <label class="f-small text-neutral-800 mb-1" for="selectJobRoleTypeId">Category Type</label>
            <app-customised-multi-select
                    [submitted]="submitted"
                    id="selectJobRoleTypeId"
                    [controls]="controls"
                    controlName="items"
                    [options]="jobRoleTypes"
                    displayName="Job Role Type"
                    placeholder="Select Job Role Types"
                    [autoGrow]="autoGrow"
                    [allSelectedText]="'All Job Role Types'"
            ></app-customised-multi-select>
        </div>
        <div *ngSwitchCase="CATEGORIES_ENUM.facilities" [style]="allign==='horizontal'?'width: 200px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
            <label class="f-small text-neutral-800 mb-1" for="selectLocationId">Category Type</label>
            <app-locations-multi-select
              id="selectLocationId"
              [control]="controls['items']"
              [options]="locationOptions"
              [sliceNum]="0"
              [selectAllChildren] = "true"
              [showSelectedNumLabel]="false"
            ></app-locations-multi-select>
        </div>
        <div *ngSwitchCase="CATEGORIES_ENUM.directManagers" [style]="allign==='horizontal'?'width: 200px':''" class="mb-4" [class.me-2]="allign==='horizontal'">
            <label class="f-small text-neutral-800 mb-1" for="selectDirectManagerId">Category Type</label>
            <app-customised-multi-select
                    [submitted]="submitted"
                    id="selectDirectManagerId"
                    [controls]="controls"
                    controlName="items"
                    [options]="directManagerOptions"
                    displayName="Manager"
                    placeholder="Select a Direct Manager"
                    [autoGrow]="autoGrow"
            ></app-customised-multi-select>
        </div>
    </div>
</div>
