import { inject, signal } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { SHARED_MODAL_CONFIRM_KEY, SharedConfirmationModalComponent } from "../../popups/shared-confirmation-modal/shared-confirmation-modal.component";

export class ConfirmationModal {
    activeModal = inject(NgbActiveModal);
    toaster = inject(ToastrService);
    modalService = inject(NgbModal)

    isLoading = false;
    submitAttempted = signal(false);

    confirmSuccess(message, closePayload: any=true) {
        this.toaster.success(message);
        this.activeModal.close(closePayload);
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    error(msg: string) {
        this.toaster.error(msg);
        this.isLoading = false;
    }

    closeConfirmation() {
        const modal = this.modalService.open(SharedConfirmationModalComponent);
        (modal.componentInstance as SharedConfirmationModalComponent).configPack = {
          title: 'Discard changes?',
          content: 'Your changes will be permanently lost if discarded.',
          buttonText: 'Discard',
          buttonClass: 'danger'
        }
        modal.componentInstance.cancelButtonText = 'Close';
        modal.result.then(res => {
          if (res === SHARED_MODAL_CONFIRM_KEY) {
            this.activeModal.dismiss();
          }
        })
      }
    

}