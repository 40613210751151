import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IPerformanceReviewManagerComment, IPerformanceReviewManagerResponse, IPerformanceReviewTaskQuestion, TaskQuestionType } from 'src/app/services/performance-review.service';
import { OrgQuestionsFormComponent } from '../org-questions-form/org-questions-form.component';

/**
 * a component to prepare PR meeting notes data for the organisation questions form.
 */
@Component({
  selector: 'app-performance-review-meeting-notes-form',
  standalone: true,
  imports: [
    OrgQuestionsFormComponent,
  ],
  templateUrl: './performance-review-meeting-notes-form.component.html',
  styleUrl: './performance-review-meeting-notes-form.component.scss'
})
export class PerformanceReviewMeetingNotesFormComponent implements OnInit, OnChanges {
  @Input() questions: IPerformanceReviewTaskQuestion[];
  @Input() managerComment?: IPerformanceReviewManagerComment;
  /**
   * a property to indicate if each question has a comment except short-anser and private-reflection question types
   */
  @Input() hasComment = false;
  @Input() hasAdditionalNotes = false;
  @Input() disabled = false;
  @Input() submitted?;

  @Output() allFormsValid = new EventEmitter<boolean>();

  /**
   * each form control is an answer for a question
   * the form control name is the questionID
   */
  answersFormGroup: FormGroup;
  /**
   * each form control is a comment for a question
   * the form control name is the questionID
   */
  commentsFormGroup: FormGroup;
  /**
   * additional comment from the manager response
   */
  additionalNotesFormGroup: FormGroup;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.questions && this.questions) || (changes.managerComment && this.managerComment)) {
      this.answersFormGroup = new FormGroup(
        this.questions?.reduce((acc, question) => {
          acc[question.questionID] = this.getAnswerControl(question, this.managerComment?.responses);
          return acc;
        }, {})
      );
      // console.log('111this.answersFormGroup', this.answersFormGroup);

      if (this.hasComment) {
        this.commentsFormGroup = new FormGroup(
          this.questions?.reduce((acc, question) => {
            if (question.qType !== 'short-answer' && question.qType !== 'private-reflection') {
              acc[question.questionID] = this.getCommentControl(question, this.managerComment?.responses);
            }
            return acc;
          }, {})
        );
        // console.log('111this.commentsFormGroup', this.commentsFormGroup);
      }

      this.checkFormsValidity();
      this.answersFormGroup.statusChanges.subscribe(
        value => this.checkFormsValidity()
      );
      this.commentsFormGroup.statusChanges.subscribe(
        value => this.checkFormsValidity()
      );
    }

    if (this.hasAdditionalNotes) {
      let initialValue = this.managerComment?.note || '';

      this.additionalNotesFormGroup = new FormGroup({
        additionalNotes: new FormControl(initialValue),
      });

      // console.log('111this.additionalNotesFormGroup', this.additionalNotesFormGroup);

      this.checkFormsValidity();
      this.additionalNotesFormGroup.statusChanges.subscribe(
        value => this.checkFormsValidity()
      );
    }
  }

  private getAnswerControl(
    question: IPerformanceReviewTaskQuestion,
    responses?: IPerformanceReviewManagerResponse[]
  ): FormControl {
    let initValue: any;

    if (responses?.length > 0) {
      const responseObject: IPerformanceReviewManagerResponse = responses.find(r => r.questionID === question.questionID);
      initValue = this.getAnswerInitValue(responseObject, question.qType);
    }

    return this.generateAnswerControl(question, initValue);
  }

  private getAnswerInitValue(response: IPerformanceReviewManagerResponse, questionType: TaskQuestionType): any {
    let initialValue: number | string | number[];
    switch (questionType) {
      case 'number':
        initialValue = response?.answerText;
        break;
      case 'single-answer':
        initialValue = response?.answerOption;
        break;
      case 'short-answer':
        initialValue = response?.answerText;
        break;
      case 'private-reflection':
        initialValue = response?.answerText;
        break;
      case 'multi-answers':
        initialValue = response?.multipleAnswers;
        break;
      case 'dropdown':
        initialValue = response?.answerOption;
        break;
      case 'ratings':
        initialValue = response?.answerText;
        break;
      case 'date-picker':
        initialValue = response?.answerText;
        break;
      default:
        initialValue = response?.answerText;
    }
    return initialValue;
  }

  private generateAnswerControl(question: IPerformanceReviewTaskQuestion, initVal?: any): UntypedFormControl {
    const initialValue = initVal === undefined ? '' : initVal;
    const validators = [];

    if (!question?.isSkippable) {
      validators.push(Validators.required);
    }
    switch (question?.qType) {
      case 'short-answer':
        validators.push(Validators.maxLength(5000));
        break;
      case 'private-reflection':
        validators.push(Validators.maxLength(5000));
        break;
      case 'number':
        validators.push(Validators.max(10000000000000));
        validators.push(Validators.min(0));
        break;
    }

    return new UntypedFormControl(initialValue, validators);
  }

  private getCommentControl(
    question: IPerformanceReviewTaskQuestion,
    responses?: IPerformanceReviewManagerResponse[]
  ): FormControl {
    let initValue: any;

    if (responses?.length > 0) {
      const responseObject: IPerformanceReviewManagerResponse = responses.find(r => r.questionID === question.questionID);
      initValue = responseObject?.responseText || '';
    }

    const validators = [];
    return new UntypedFormControl(initValue, validators);
  }

  /**
   * process manager response data, including responses and note
   * @returns 
   */
  getProcessedData(): IMeetingNotesFormProcessedData {
    const processedData: IMeetingNotesFormProcessedData = {};

    processedData['responses'] = this.questions?.map(question => {
      const questionID = question.questionID;
      const responseObject: IPerformanceReviewManagerResponse = {
        questionID: questionID
      };

      // get comment
      const commentValue = this.commentsFormGroup.get(questionID)?.value;
      if (commentValue !== undefined) {
        responseObject['responseText'] = commentValue;
      }

      // get answer
      const answerValue = this.answersFormGroup.get(questionID).value;
      if (answerValue !== undefined) {
        if (question.qType === 'number'
          || question.qType === 'short-answer'
          || question.qType === 'private-reflection'
          || question.qType === 'ratings'
          || question.qType === 'date-picker'
        ) {
          responseObject['answerText'] = answerValue;
        }

        if (question.qType === 'single-answer' || question.qType === 'dropdown') {
          responseObject['answerOption'] = answerValue;
        }

        if (question.qType === 'multi-answers') {
          responseObject['multipleAnswers'] = answerValue;
        }
      }

      return Object.keys(responseObject).length === 1 ? null : responseObject;
    }).filter(response => response !== null);

    // get additional meeting notes
    const additionalNotes = this.additionalNotesFormGroup.get('additionalNotes')?.value;
    if (additionalNotes !== undefined) {
      processedData['note'] = additionalNotes;
    }

    console.log('processedData', processedData);

    return processedData;
  }

  /**
   * Calculate all forms validity.
   * Only check existed form group validity.
   */
  checkFormsValidity() {
    let allValid = true;

    if (this.answersFormGroup) {
      allValid = allValid && this.answersFormGroup.valid;
    }
    if (this.commentsFormGroup) {
      allValid = allValid && this.commentsFormGroup.valid;
    }
    if (this.additionalNotesFormGroup) {
      allValid = allValid && this.additionalNotesFormGroup.valid;
    }

    this.allFormsValid.emit(allValid);
  }

}


export interface IMeetingNotesFormProcessedData {
  note?: string;
  responses?: IPerformanceReviewManagerResponse[];
}