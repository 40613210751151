<div class="page-header__container">
  <div class="d-flex flex-column gap-4">
    <ui-breadcrumb *ngIf="breadcrumbConfigs" 
      [breadcrumbs]="breadcrumbConfigs" [showMarginBottom]="false" 
    ></ui-breadcrumb>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row align-items-center gap-2">
        <h2 class="fw-bold">{{pageTitle}}</h2>
        <app-tooltip *ngIf="tooltipContent"
          [placement]="'right'" [icon]="'info'" [text]="tooltipContent"
        ></app-tooltip>
      </div>
      <ng-content select="[rightSlot]"></ng-content>
    </div>
    <div *ngIf="details" class="f-small text-neutral-800">
      <span class="fw-semibold">{{boldedText}} </span> 
      <span>{{details}}</span>
    </div>
  </div>
</div>