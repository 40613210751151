export const EDITOR_CONFIG = {
  versionCheck: false,
  extraPlugins: ['editorplaceholder'],
  toolbarGroups: [
    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    { name: 'forms', groups: [ 'forms' ] },
    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    { name: 'links', groups: [ 'links' ] },
    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    { name: 'insert', groups: [ 'insert' ] },
    { name: 'colors', groups: [ 'colors' ] },
    { name: 'styles', groups: [ 'styles' ] },
    { name: 'tools', groups: [ 'tools' ] },
    { name: 'others', groups: [ 'others' ] },
    { name: 'about', groups: [ 'about' ] }
  ],
  removeButtons: 'ShowBlocks,About,Flash,Smiley,Iframe,BidiLtr,BidiRtl,Language,CreateDiv,CopyFormatting,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Templates,Save,NewPage,Preview'
};

/**
 * Only shows bold, underline,italic, strikethrough
 */
export const EDITOR_CONFIG_SIMPLE = {
  versionCheck: false,
  extraPlugins: ['editorplaceholder'],
  toolbarGroups: [
    { name: 'basicstyles', groups: [ 'basicstyles' ] },
  ],
  height: '10%',
  autoGrow_onStartup: true,
  removeButtons: 'ShowBlocks,About,Flash,Smiley,Iframe,BidiLtr,BidiRtl,Language,CreateDiv,CopyFormatting,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Templates,Save,NewPage,Preview,Subscript,Superscript'
};