import { Component, effect, inject, input } from '@angular/core';
import { SmartTableComponent } from '@components/smart-table/smart-table.component';
import { IConvertedOrgAssign } from 'src/app/services/org-assign.service';
import { LocalTableUniqKey } from '../../popups/shared-edit-table-column-modal/localTableUniqKey';
import { columnName, MANDATORY_ASSIGN_COLUMNS, SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';

@Component({
  selector: 'app-assign-table-basic',
  standalone: true,
  imports: [SmartTableComponent, ModalSingleComponent],
  templateUrl: './assign-table-basic.component.html',
  styleUrl: './assign-table-basic.component.scss'
})
export class AssignTableBasicComponent {
  assigns = input<IConvertedOrgAssign[]>();

  smartTableColumnsService = inject(SmartTableColumnsService);
  HIDDEN_COLUMNS: columnName[] = ['resourceCode', 'resourceObj', 'email', 'orgEmail', 'orgMobile', 'workType', 'accountStatus', 'teams', 'jobRoles', 'earlyRecogDate', 'complianceType', 'assignID'];

  settings = {
    tableUniqKey: LocalTableUniqKey.sgrComplianceRequirementsNumModalOverdue,
    actions: false,
    columns: {
      ...this.smartTableColumnsService.getColumns(MANDATORY_ASSIGN_COLUMNS,
        {
          hidden: this.HIDDEN_COLUMNS.concat(['completeDate', 'originDueDate', 'activityID']),
          local: true
        }),
    }
  };
  source = new LocalDataSource();

  constructor() {
    effect(() => this.source.load(this.assigns()))
  }
}
