<ui-modal-single [heading]="title">
  <ng-template #contentSlot>
      <ng-container *ngIf="policies && policies;">
          <div [hidden]="!isDataFetched">
              <app-pp-server-table
                  [tableChange]="payload"
                  (numResults)="isDataFetched=true; getTitle($event)"
                  [tableSettings]="tableSettings"
                  [policies]="policies">
              </app-pp-server-table>
          </div>
      </ng-container>
      <ng-container *ngIf="!isDataFetched">
          <div class="m-spinner-container d-flex justify-content-center align-items-center">
            <ui-spinner size="lg"></ui-spinner>
          </div>
      </ng-container>
      <!-- <ui-empty-state content="Loading..."></ui-empty-state> -->
  </ng-template>

  <ng-template #rightSlot>
    <button class="btn btn-outline-light me-1" (click)="closeModal()" type="button">Close</button>
  </ng-template>
</ui-modal-single>
