import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, concat, filter, ignoreElements, map, switchMap, take, tap } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { BroadcastService } from './broadcast.service';
import { IOrgForm } from '../pages/settings/interfaces/IOrgForm';
import { IOrganisation } from '../pages/settings/interfaces/IOrganisation';
import { PRODUCT_TYPES } from '../core/guards/mode.constants';
import { cacheResponse2 } from '../shared/utils/rx/cache-response-2';
import { FRAMEWORKS_ENUM } from '../pages/settings/interfaces/IOrgLMSSetting';
import FRAMEWORKS from '../pages/settings/standards-settings/configs/compliance-frameworks.config';
import { IComplianceFramework } from '../pages/settings/interfaces/IComplianceFramework';
import { Dictionary } from 'underscore';
import { ITeam } from './group.service';
import { addAppcueEvent, appcueGroupTrack, appcueTrack } from '../pages/settings/organisation-settings/organisation.component';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  organisation: BehaviorSubject<IOrganisation> = new BehaviorSubject<IOrganisation>(null);
  organisationContract = new BehaviorSubject({});
  private _enabledFrameworks: FRAMEWORKS_ENUM[] = null;
  activeContract = new BehaviorSubject<IContract>(null);
  plainActiveContract: IContract;
  private _contractType: PRODUCT_TYPES;
  logoUpdate = new BehaviorSubject('');
  orgLogoUpdate = new BehaviorSubject('');


  constructor(
    private httpClient: HttpClient,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService,
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.organisation.next(null);
      this.organisationContract.next({});
      this.activeContract.next(null);
      this.logoUpdate.next('');
      this.plainActiveContract = null;
      this._enabledFrameworks = null;
      this.orgLogoUpdate.next('');
      this._contractType = null;
    });
  }

  fetchOrgAccreditors = cacheResponse2(() => {
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/accreditors`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;
  });


  public getOrganisation(): Observable<IOrganisation> {
    return this.organisation.asObservable();
  }

  public getOrgID() {
    return this.getOrganisation().pipe(
      map(org => org.orgID)
    );
  }

  public fetchOrganisation(orgID: string): Observable<any> {
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${orgID}`)
      .pipe(
        tap((org: IOrganisation) => {
          this.organisation.next(org);
          this._setEnabledFrameworks();
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  public setOrganisation(organisation: IOrganisation): void {
    localStorage.setItem('orgID', organisation.orgID);
    this.organisation.next(organisation);
    this._setEnabledFrameworks();
  }

  updateOrganisation(organisation: any) {
    return this.httpClient
      .put(environment.accountServiceEndpoint + '/orgs/' + organisation.orgID, organisation)
      .pipe(
        tap(() => this._setEnabledFrameworks()),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  public setOrganisationContract(contracts): void {
    this.organisationContract.next(contracts);
  }

  public getOrganisationContract(): Observable<object> {
    return this.organisationContract.asObservable();
  }

  public setActiveContract(c: IContract): void {
    this.activeContract.next(c);
    this.plainActiveContract = c;
  }

  public setContractType(type: PRODUCT_TYPES): void {
    this._contractType = type;
  }

  public getContractType() {
    return this._contractType;
  }

  public isContractType(contractType: PRODUCT_TYPES) {
    return contractType === this.getContractType();
  }

  public getActiveContract(): Observable<IContract> {
    return this.activeContract.asObservable();
  }

  private _setEnabledFrameworks() {
    const org = this.organisation.getValue();
    this._enabledFrameworks = (org.lmsSetting?.complianceFrameworks) ? org.lmsSetting.complianceFrameworks
      .filter(f => !!f && f !== FRAMEWORKS_ENUM.AHPRA) || [] : [];
  }

  public getEnabledFrameworks(): FRAMEWORKS_ENUM[] {
    return this._enabledFrameworks;
  }

  public getFullEnabledFrameworks(): IComplianceFramework[] {
    return this.getEnabledFrameworks()
      .map(framework => FRAMEWORKS
        .find(cf => cf.value === framework)).filter(cf => !!cf);
  }

  public fetchOrganisationContract(orgId: string): Observable<any> {
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${orgId}/contracts`)
      .pipe(
        // map((contracts: any) => contracts.filter(contract => new Date(contract.endDate) >= new Date())),
        tap((contracts: any[]) => {
          this.organisationContract.next(contracts);
          // const activeContract = _.find(contracts, (contract: any) =>
          //   (contract.status.toLowerCase() === 'active' && new Date(contract.endDate) >= new Date()));
          // this.showMT.next(activeContract && activeContract.enableMT);
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchOrganisationContractFile(orgID: string, contractID: string) {
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/contracts/${contractID}/files`;
    return this.httpClient.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  updateOrganisationComplianceSettings(orgID: string, orgForm: IOrgForm) {
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}`;
    return this.httpClient.put(url, orgForm).pipe(
      tap(() => {
        this.fetchOrganisation(orgID).subscribe();
      }),
    );
  }

  getLogoUrl() {
    return this.getOrganisation()
    .pipe(filter(org => !!(org && org.orgID)),
      map(organisation => {
          if (organisation.lmsSetting.customConfig) {
            const { logoLink } = organisation.lmsSetting.customConfig;
            return logoLink;
          }
         else {
          const domain = this.getContractType() === PRODUCT_TYPES.HIPPOTLIAN ? 'https://hippotlian-cdn.s3.amazonaws.com' : 'https://ausmed-cdn.s3.amazonaws.com';
          return organisation.code ? `${domain}/images/${environment.production ? 'org' : 'org-staging'}/${organisation.code.toUpperCase()}.png` : '';
        }
    }))
  }

  getLogoStatus() {
    return this.logoUpdate.asObservable();
  }

  setLogoStatus(status) {
    return this.logoUpdate.next(status);
  }

  updateLogo() {
    return this.orgLogoUpdate.next('');
  }

  getLogo() {
    return this.orgLogoUpdate.asObservable();
  }

  saveAppcueEvent(eventName: string) {
    this.getOrganisation().pipe(
      take(1),
      switchMap(org => {
        const existingEvents = org.lmsSetting.appcuesEvents || [];
        if (!existingEvents.includes(eventName)) {
          addAppcueEvent(org, eventName);
          return this.updateOrganisation(org);
        }
        return of(null);
      }),
      catchError(() => of(null))
    ).subscribe(org => {
      if (org) {
        this.setOrganisation(org);
        appcueTrack(eventName);
        // appcueGroupTrack(org);
      }
    });
  }
}

export interface IContract {
  contractID: string;
  orgID: string;
  status: string;
  contractType: PRODUCT_TYPES;
  enableMT: boolean;
  startDate: string;
  endDate: string;
  quota: number;
  tolerance: number;
  priceNetCent: number;
  priceTaxCent: number;
  currency: string;
  billingIntervalMonth: number;
  note?: string;
  features?: string[];
  billing?: {
    products?: any[]
  };
  metaData?: {
    bundles?: [],
    purchasedResources?: []
  }
}



