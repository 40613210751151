import { IMenuItem } from "../menu.service";
import { SHORTCUT_TYPE } from "../shortcut.service";

export function getSavedFilterMenu(type: SHORTCUT_TYPE): IMenuItem {
    return {
        displayName: 'Saved Filters',
        route: (type.startsWith('policy') ? 'policies/' : '') + 'saved-filters/' + type,
        // queryParams: {type: type},
        show: true,
        slIcon: {name: 'interface-bookmark', theme: 'micro-bold'},
        // bottomBorder: true
      }

}