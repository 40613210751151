import { ComplianceRecordStatus } from 'src/app/shared/pipes/compliance-status.pipe';
import { ACK_STATUS } from '../policies-procedures/interfaces/policy-and-procedure.interface';
import { FRAMEWORKS_ENUM } from '../settings/interfaces/IOrgLMSSetting';

export enum LearningSource_ENUM {
  all = 'All Learning Source',
  mt = 'Mandatory Training',
  internal = 'Internal Record',
  private = 'Private Learning',
  addHoc = 'Ad Hoc Training',
}

export const ITEM_NAME_VALUE_PAIRS = [
  { name: 'All Users', value: 'allUsers' },
  { name: 'Individual User', value: 'users' },
  { name: 'Team', value: 'teams' },
  { name: 'Job Role', value: 'jobRoles' },
];

export interface ReportingDateRange {
  startDate: string;
  endDate: string;
}

// export type ALL_USERS = 'allUsers';
// export type INDIVIDUAL_USER = 'users';
// export type TEAM = 'teams';
// export type JOB_ROLE = 'jobRoles';
// // export type LearningGroupType = INDIVIDUAL_USER | TEAM | JOB_ROLE | ALL_USERS;
export enum LearningGroupType {
  allUsers = 'allUsers',
  individual = 'users',
  team = 'teams',
  jobRole = 'jobRoles',
  jobRoleType = 'jobRoleTypes',
  location = 'location'
}

export enum ComplianceDateType {
  dueDate = 'dueDate',
  assignDate = 'assignDate',
  completionDate = 'completionDate',
  creationDate = 'completionCreateDate'
}

export interface IDataGovernance {
  metricData: string | number;
  description: string;
}

export type ILearningRecordType = 'Internal Record' | 'User Documented' | 'User Completed';

export interface IFilterChange {
  // processed filtered uIDs
  uIDs: {
    active: string[],
    inactive: string[],
    pending: string[],
    invited: string[],
  };
  allUserIDs?: {
    active: string[],
    inactive: string[],
    pending: string[],
    invited: string[],
  }; // all users IDs filtered by includeInactiveUsers; used in learning analytics report
  staffIDs?: string[];
  startDate: string;
  endDate: string;
  filterBy: LearningGroupType;
  itemIDs?: string[]; // value is teamIDs/jobRoleIDs/orgUserIDs
  framework?: FRAMEWORKS_ENUM;
  resourceTypes?: ILearningRecordType[];
  planIDs?: string[];
  dateType?: ComplianceDateType;
  goalStatus?: 'all' | 'inProgress' | 'completed',
  resources?: any[];
  filteredOrgUserIDs: string[]; // used by learning analytics to get user count
  policies?: {
    policyIDs: string[];
    policyGovDisplay: string;
  };
  ackStatuses?: ACK_STATUS[];
  complianceTypes?: ('Mandatory' | 'Optional')[];
  mandatoryType?: string[];
  requirementStatuses?: string[];
  ackComplianceStatus?: string[];
  assignStatus?: ComplianceRecordStatus[];
  combinedUIDs?: string[];
  stateDate?: string;
}

export type ChartDateRangeOption = 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Yearly';

export interface IGovData {
  recordTypes?: ILearningRecordType[];
  filterBy: LearningGroupType;
  currentTypeDataGovernanceDisplay: string;
  dateOption: string;
  startDate: string;
  endDate: string;
  resources?: any[];
  includeInactiveUsers: boolean;
  inactiveIndividual: boolean;
  uIDs: {
    active: string[];
    inactive: string[];
    pending: string[];
    invited: string[];
  };
  framework?: FRAMEWORKS_ENUM;
  numPlan?: number;
  dateType?: ComplianceDateType;
  filteredOrgUserIDs: string[];
  policyDisplay?: string;
}
