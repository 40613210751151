import { IComplianceFramework } from '../../interfaces/IComplianceFramework';
import { FRAMEWORKS_ENUM } from '../../interfaces/IOrgLMSSetting';

const FRAMEWORKS: IComplianceFramework[] = [
  {
    title: 'Aged Care Quality Standards 2019',
    value: FRAMEWORKS_ENUM.agedCare,
    description: 'When active, you will be able to report on learning that’s linked to the Aged Care Quality Standards. ',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/aged-care-quality-standards.png'
  },
  {
    title: 'National Safety and Quality Health Service (NSQHS) Standards',
    value: FRAMEWORKS_ENUM.NSQHS,
    description: 'When active, you will be able to report on learning that’s linked to the National Safety and Quality Health Service (NSQHS) Standards. ',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/nsqhs-standards.png'
  },
  // {
  //   title: 'Australian Health Practitioner Regulation Agency (AHPRA)',
  //   value: FRAMEWORKS_ENUM.AHPRA,
  //   description: 'When active, you will be able to report on learning that’s documented by registered health professionals. Please note, the following Boards are not currently supported: Podiatry, Optometry, Aboriginal and Torres Strait Island Health Workers, Dentists, Medical, Medical Radiation',
  //   // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/ahpra-standards.png'
  // },
  {
    title: 'NDIS Practice Standards',
    value: FRAMEWORKS_ENUM.NDIS,
    description: 'When active, you will be able to report on learning that’s linked to the National Disability Insurance Scheme (NDIS) Practice Standards.',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/NDIS+Standards.png',
  },
  {
    title: 'Ngā Paerewa Health and Disability Services Standard',
    value: FRAMEWORKS_ENUM.NZ,
    description: 'When active, you will be able to report on learning that’s linked to the Ngā Paerewa Health and Disability Services Standard.',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/NDIS+Standards.png',
  },
  {
    title: 'Strengthened Aged Care Quality Standards',
    value: FRAMEWORKS_ENUM.agedCare2024,
    description: 'When active, you will be able to report on learning that’s linked to the Strengthened Aged Care Quality Standards. ',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/aged-care-quality-standards.png'
  },

];

export const FRAMEWORK_DICT = {
  [FRAMEWORKS_ENUM.agedCare]: {
    title: 'Aged Care Quality Standards 2019',
    value: FRAMEWORKS_ENUM.agedCare,
    description: 'When active, you will be able to report on learning that’s linked to the Aged Care Quality Standards. ',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/aged-care-quality-standards.png'
  },
  [FRAMEWORKS_ENUM.NSQHS]: {
    title: 'National Safety and Quality Health Service (NSQHS) Standards',
    value: FRAMEWORKS_ENUM.NSQHS,
    description: 'When active, you will be able to report on learning that’s linked to the National Safety and Quality Health Service (NSQHS) Standards. ',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/nsqhs-standards.png'
  },
  // [FRAMEWORKS_ENUM.AHPRA]: {
  //   title: 'Australian Health Practitioner Regulation Agency (AHPRA)',
  //   value: FRAMEWORKS_ENUM.AHPRA,
  //   description: 'When active, you will be able to report on learning that’s documented by registered health professionals. Please note, the following Boards are not currently supported: Podiatry, Optometry, Aboriginal and Torres Strait Island Health Workers, Dentists, Medical, Medical Radiation',
  //   // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/ahpra-standards.png'
  // },
  [FRAMEWORKS_ENUM.NDIS]: {
    title: 'NDIS Practice Standards',
    value: FRAMEWORKS_ENUM.NDIS,
    description: 'When active, you will be able to report on learning that’s linked to the National Disability Insurance Scheme (NDIS) Practice Standards.',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/NDIS+Standards.png',
  },
  [FRAMEWORKS_ENUM.NZ]: {
    title: 'Ngā Paerewa Health and Disability Services Standard',
    value: FRAMEWORKS_ENUM.NZ,
    description: 'When active, you will be able to report on learning that’s linked to the Ngā Paerewa Health and Disability Services Standard.',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/NDIS+Standards.png',
  },
  [FRAMEWORKS_ENUM.agedCare2024]: {
    title: 'Strengthened Aged Care Quality Standards',
    value: FRAMEWORKS_ENUM.agedCare2024,
    description: 'When active, you will be able to report on learning that’s linked to the Strengthened Aged Care Quality Standards. ',
    // imageSrc: 'https://ausmed-images.s3-ap-southeast-2.amazonaws.com/LMS/aged-care-quality-standards.png'
  },
};

export default FRAMEWORKS;
