import { Component, Input, SimpleChanges } from '@angular/core';
import { ITableSettings, SmartTableComponent } from '@components/smart-table/smart-table.component';
import { TooltipCellComponent } from '@components/table-cell/tooltip-cell/tooltip-cell.component';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { ModalSingleComponent } from "../../../../../shared/components/modal-single/modal-single.component";
import { SharedTableActionsComponent } from "../../../../../shared/components/shared-table-actions/shared-table-actions.component";

@Component({
  selector: 'app-payroll-group-item-cell-modal',
  templateUrl: './payroll-group-item-cell-modal.component.html',
  styleUrls: ['./payroll-group-item-cell-modal.component.scss'],
  standalone: true,
  imports: [
    SmartTableComponent,
    ModalSingleComponent,
    SharedTableActionsComponent
]
})
export class PayrollGroupItemCellModalComponent {
  @Input() tableData: any[];

  source = new LocalDataSource;
  settings: ITableSettings = {
    tableUniqKey: LocalTableUniqKey.payrollModal,
    actions: false,
    columns:    {  ...this.columnService.getColumns(
      ['user', 'teams', 'jobRoles', 'staffID', 'internalID', 'email', 'orgEmail', 'orgMobile', 'accountStatus', 'resource', 'resourceDuration', 'completeTimestamp', ]
      ,{local: true, show: ['resourceDuration'], hidden: ['staffID','internalID', 'email', 'orgEmail', 'orgMobile', 'accountStatus']}
      ),
      mtPlanCells: {
        title: 'Sources',
        type: 'custom',
        renderComponent: TooltipCellComponent,
        sort: false,
        filter: false
      },

    },


  }

  constructor(
    private columnService: SmartTableColumnsService,
  ) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
      this.source.load(this.tableData);
  }


  get modalTitle() {
    return `Showing ${this.tableData?.length} Records`;
  }
}
