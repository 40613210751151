<ui-modal-single heading="Add Reporting Line">
    <ng-template #contentSlot>
        <app-step-progress [steps]="STEPS_DISPLAY" [currentStep]="currentStepIdx()" (stepChange)="currentStepIdx.set($event)"></app-step-progress>
        <div class="mt-4">
            <ng-template *ngComponentOutlet="currentComp(); inputs:{form: currentControl(), allValues: form.value}" />

        </div>
    </ng-template>
    <ng-template #rightSlot>
        <div class="d-flex gap-2">
            <button (click)="dismiss()" [hidden]="currentStepIdx() !== 0" class=" btn btn-outline-light">Close</button>
            <button [disabled]="currentControl()?.invalid" [hidden]="currentStepIdx() === STEPS_DISPLAY.length - 1"  class="btn btn-secondary" (click)="onNext()">Next</button>
        </div>
        <!-- <button [hidden]="currentStepIdx() !== STEPS_DISPLAY.length - 1" class="btn btn-success" (click)="onAdd()">Add</button> -->
        <app-processing-button [isLoading]="isLoading" [hidden]="currentStepIdx() !== STEPS_DISPLAY.length - 1"
          btnClass="btn-success" (click)="onAdd()" text="Add"></app-processing-button>
    </ng-template>
    <ng-template #leftSlot>
        <button [hidden]="currentStepIdx() === 0" class="btn btn-outline-light" (click)="onBack()">Back</button>
    </ng-template>


</ui-modal-single>
