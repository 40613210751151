<app-shared-overview
    [title]="'Reports'"
    [cardBoldedText]="'Reporting tools'"
    cardText="help you extract key information for presentation to auditors and stakeholders or analysis."
    shortcutType="policyReport"
    [showUnifiedPageHeader]="true"
>

    <div class="mb-8">
        <div class="d-flex flex-wrap mt-4 gap-2">
            <ng-container *ngFor="let item of items" >
                <app-overview-card *featureFlag="item.featureFlag" [item]="item" textClass="mt-2" textSizeClass="f-caption" class="card-reporting-size">
                    <ng-container customIcon>
                        <ui-avatar  [slIcon]="item.reportSlIcon" class="mb-2" [iconBackgroundColor]="item.iconBgColor" textColor="text-white"></ui-avatar>
                    </ng-container>
                </app-overview-card>
            </ng-container>
        </div>
    </div>


</app-shared-overview>
