import { AsyncPipe } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { SelectorRegulatoryStandardsComponent } from '@components/common-selectors/selector-regulatory-standards/selector-regulatory-standards.component';
import { SelectorSingleUserComponent } from '@components/common-selectors/selector-single-user/selector-single-user.component';
import { CustomisedSingleSelectComponent } from '@components/customised-single-select/customised-single-select.component';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { ToggleButtonsComponent } from '@components/toggle-buttons/toggle-buttons.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { Feedback, FEEDBACK_SENTIMENT_OPTIONS, FEEDBACK_SOURCE_OPTIONS, FeedbackPayload, FeedbackSentiment, FeedbackService, FeedbackSourceType } from 'src/app/services/feedback.service';
import { UserService } from 'src/app/services/user.service';
import { CKEditorComponent } from 'src/app/shared-modules/ckeditor4-angular/ckeditor.component';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { removeEmptyReturn } from 'src/app/shared/utils/remove-empty-fields';
import { EDITOR_CONFIG_SIMPLE } from 'src/config/ckeditor-config';
import { ProcessingButtonComponent } from "../../../../ui/buttons/processing-button/processing-button.component";
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SelectorMultiUserComponent } from '@components/common-selectors/selector-multi-user/selector-multi-user.component';

@Component({
  selector: 'app-feedback-modal',
  standalone: true,
  imports: [ModalSingleComponent, SelectorSingleUserComponent, CKEditorComponent, ReactiveFormsModule,
    UIStreamlineIconComponent, AsyncPipe, MatDivider, CustomisedSingleSelectComponent, ToggleButtonsComponent,
    SelectorRegulatoryStandardsComponent, StaffLinkDisplayComponent, ProcessingButtonComponent, NgbTooltip,
    SelectorMultiUserComponent],
  templateUrl: './feedback-modal.component.html',
  styleUrl: './feedback-modal.component.scss'
})
export class FeedbackModalComponent extends ConfirmationModal implements OnInit {
  editedFeedback = signal<Feedback>(null); // input

  userService = inject(UserService);
  feedbackService = inject(FeedbackService);

  EDITOR_CONFIG = EDITOR_CONFIG_SIMPLE;
  sourceOptions = FEEDBACK_SOURCE_OPTIONS;
  sentimentOptions = FEEDBACK_SENTIMENT_OPTIONS

  form = new FormGroup({
    // user: new FormControl<string>(null, Validators.required),
    users: new FormControl<string[]>(null, Validators.required),
    content: new FormControl<string>(null, Validators.required),
    source: new FormControl<FeedbackSourceType>(null, Validators.required),
    sentiment: new FormControl<FeedbackSentiment>('Neutral'),
    guidelines: new FormControl<string[]>([])
  });

  // selectedUser = toSignal(this.form.controls.users.valueChanges.pipe(
  //   map(userID => this.userService.managedOrgUserDictionaryByOrgUserID[userID]))
  // );

  // userName = computed(() => this.selectedUser()?.fullName);

  heading = computed(() => {
    if (this.editedFeedback()) {
      return 'Edit Feedback';
    } else {
      return 'Add Feedback';
    }
  });

  ngOnInit(): void {
    if (this.editedFeedback()) {
      this.form.setValue({
        users: [this.editedFeedback().user?.orgUserID],
        guidelines: this.editedFeedback().standards || [],
        content: this.editedFeedback().content || '',
        sentiment: this.editedFeedback().sentiment,
        source: this.editedFeedback().source
      })
    }
  }

  submit() {

    this.submitAttempted.set(true);
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const formValue = this.form.value
    const payload: FeedbackPayload[] = formValue.users.map(u => {
      return removeEmptyReturn({
        // receiver: this.selectedUser().userID,
        source: formValue.source,
        content: formValue.content,
        sentiment: formValue.sentiment,
        standards: formValue.guidelines,
        receiverOrgUserID: u,
        receiverUserID: this.userService.userList.find(user => user.orgUserID === u)?.userID
      })

    })

    if (this.editedFeedback()) {
      // update
      this.feedbackService.updateFeedback(payload[0], this.editedFeedback().feedbackID).subscribe(f => this.confirmSuccess('Feedback updated', f), error => this.error(error))
    } else {
      this.feedbackService.addFeedback(payload).subscribe(f => this.confirmSuccess('Feedback added', f), error => this.error(error))
    }
  }


}
