export enum CATEGORY_ENUM {
  individual = 'users',
  teams = 'teams',
  jobRoles = 'jobRoles',
  jobRoleTypes = 'jobRoleTypes',
  facilities = 'Facilities',
  directManagers = 'directManagers',
  allUsers = 'allUsers',
}

// interface IUserCategory {
//   label: 'User';
//   value: CATEGORY_ENUM.individual;
// }
//
// interface ITeamCategory {
//   label: 'Team';
//   value: CATEGORY_ENUM.teams;
// }
//
// interface IJobRoleCategory {
//   label: 'Job Role';
//   value: CATEGORY_ENUM.jobRoles;
// }

export enum DATE_OPTION_ENUM {
  allTime = 'All Time',
  allTimeReport = 'All Time Report',
  thisWeek = 'This Week',
  lastWeek = 'Last Week',
  thisMonth = 'This Month',
  lastMonth = 'Last Month',
  // thisYear = 'Year To Date',
  thisYear = 'This Year',
  lastYear = 'Last Year',
  custom = 'Custom Date Range',
  nextYear = 'Next year',
  nextQuarter = 'Next Quarter',
  nextMonth = 'Next month',
  nextWeek = 'Next week',
  thisQuarter = 'This Quarter',
  lastQuarter = 'Last Quarter',
  last365Days = 'Last 365 Days',
  last180Days = 'Last 180 Days',
  last90Days = 'Last 90 Days',
  last30Days = 'Last 30 Days',
  last14Days = 'Last 14 Days',
  last7Days = 'Last 7 Days',
  next365Days = 'Next 365 Days',
  next180Days = 'Next 180 Days',
  next90Days = 'Next 90 Days',
  next30Days = 'Next 30 Days',
  next14Days = 'Next 14 Days',
  next7Days = 'Next 7 Days',
  thisWeekSoFar = 'This Week so Far',
  thisMonthSoFar = 'This Month so Far',
  thisQuarterSoFar = 'This Quarter so Far',
  thisYearSoFar = 'This Calendar Year so Far'
}

// export type Category = IUserCategory | ITeamCategory | IJobRoleCategory;

