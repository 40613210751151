<div class="card p-4 card-hover" appHoverToggle=".hover-container">
    @if(goalStatus() === 'inProgress'){
        <p class="badge ms-1 badge-light f-caption width-max-content">
          In Progress
        </p>
      }@else {
        <p class="badge ms-1 badge-success f-caption width-max-content">
          Completed
        </p>
      }
    <p class="fw-medium f-small mt-4">{{goal().title}}</p>
</div>
