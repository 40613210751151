import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as $ from 'jquery';
import { EvidencePayloadType } from 'src/app/services/evidence.service';
import { Subject } from 'rxjs';
import { UISpinnerComponent } from '../../../../ui/ui-spinner/ui-spinner.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { NgClass, NgIf } from '@angular/common';
export interface FormStatusType {
  isInvalid: boolean,
  isEmpty: boolean,
}
@Component({
    selector: 'app-add-evidence',
    templateUrl: 'add-evidence.component.html',
    styleUrls: ['add-evidence.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        TooltipComponent,
        NgIf,
        UISpinnerComponent,
    ],
})

export class AddEvidenceComponent implements OnInit {
  @Input() context: 'modal' | 'popover' | 'sidepanel' = 'modal';
  @Output() formStatusChange = new EventEmitter<FormStatusType>();
  @Output() addEvidenceChange = new EventEmitter<boolean>();
  @Output() cancelStatusChange = new EventEmitter<boolean>();
  @Output() evidenceFormChange = new EventEmitter<EvidencePayloadType>();
  destroyed$ = new Subject<void>();
  isSubmitting = false;
  evidenceForm = this.formBuilder.group({
    notes: [''],
    url: [''],
    title: [''],
  });
  constructor(
    private formBuilder: UntypedFormBuilder,
    // private evidenceService: EvidenceService,
  ) { }

  ngOnInit() {
    if (this.context === 'popover') {
      const popoverElement = $(`.popover`);
      popoverElement.css('min-width', '715px');
    }
  }

  addEvidenceOnPopover() {
    const popoverElement = $(`.popover`);
    popoverElement.close();
  }

  addEvidence() {
    this.addEvidenceChange.emit(true);
    this.isSubmitting = true;
  }

  hiddenAddSection() {
    this.cancelStatusChange.emit();
  }

  get charactersLeft() {
    return 280 - this.evidenceForm.getRawValue().notes.length;
  }

  get f() {
    return this.evidenceForm.controls;
  }

  // hide the error msg except there is a title without url and emit the form
  get isInvalid() {
    this.formStatusChange.emit(
      {
        isEmpty: this.isEmpty,
        isInvalid: this.evidenceForm.getRawValue().title && !this.evidenceForm.getRawValue().url,
      }
    )
    this.evidenceFormChange.emit(
      {
        note: this.evidenceForm.getRawValue().notes,
        links: [{
          title: this.evidenceForm.getRawValue().title,
          link: this.evidenceForm.getRawValue().url,
        }]
      }
    );
    return this.evidenceForm.getRawValue().title && !this.evidenceForm.getRawValue().url;
  }

  get isEmpty() {
    return !(this.evidenceForm.getRawValue().title + this.evidenceForm.getRawValue().url + this.evidenceForm.getRawValue().notes);
  }
}
