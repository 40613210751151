@if(value?.length > 0) {
    <div>
        @if(showPlusMore) {
            <div class="fw-medium text-neutral-900 cursor-pointer hover-underline">
                <a [routerLink]="value[0].url" class="fw-medium text-neutral-900" >{{value[0].name | slice: 0: 20}}</a>
                @if(value.length > 1) {
                    <span>, </span>
                    <span [ngbPopover]="popContent" placement="top" [popoverTitle]="popTitle"
                    [container]="noContainer ? '' : 'body'" class="fw-medium text-neutral-900">+{{value.length - 1}} More</span>
                }
            </div>
        } @else {
            <div [ngbPopover]="popContent" placement="top" [popoverTitle]="popTitle"
            [container]="noContainer ? '' : 'body'" class="fw-medium text-neutral-900 cursor-pointer hover-underline">
                <a>{{ cellContent }}</a>
            </div>
        }
    </div>
} @else {
        <div class=" text-neutral-900">
            <a> {{cellContent}} </a>
        </div>
}


<ng-template #popContent>
  <div class="m-scrollable">
      <ng-container *ngFor="let item of value.slice(showPlusMore ? 1 : 0);  last as isLast">
          <div class="text-truncate" [ngbTooltip]="item.name.length>31 ? item.name : ''"
              placement="top-left"
              container="body">
              <a
              href="javascript:void(0);"
              [routerLink]="[item.url]"
              class="fw-medium text-neutral-900"
              target="_blank">
              {{item.name}}
              </a>
          </div>
      </ng-container>
  </div>
</ng-template>
<ng-template #popTitle>
    <div class="f-small fw-medium text-neutral-900"> {{popoverTitle || cellContent}}  </div>
</ng-template>
