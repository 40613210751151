import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddPluralSPipe } from '../../pipes/add-plural-s.pipe';
import { AvatarComponent } from '../avatar/avatar.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'search-item-policy',
    templateUrl: './search-item-policy.component.html',
    standalone: true,
    imports: [NgIf, RouterLink, AvatarComponent, NgClass, AddPluralSPipe]
})

export class SearchItemPolicyComponent implements OnInit {
    @Input() policy;
    @Input() enableLink = true;
    @Input() openNewTab = false;
    @Input() removeBtn = false;
    @Input() noTruncation = false;
    @Output() removePolicyEvent = new EventEmitter<string>();
    constructor() { }

    ngOnInit() {
    }

    removePolicy() {
        this.removePolicyEvent.emit(this.policy.governanceID);
    }
}
