import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, RouteReuseStrategy } from '@angular/router';
import { AuthLoginGuard } from './core/guards/auth-login.guard';
import { InitGuard } from './core/guards/init.guard';
import { CustomRouteReuseStrategy } from './services/custom-route-reuse-strategy.service';
import { DevModeOnlyGuard } from './core/guards/dev-mode-only.guard';


const routes: Routes = [
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthLoginGuard, InitGuard] },
  { path: 'course-builder', loadChildren: () => import('./course-builder-2/course-builder-2.module').then(m => m.CourseBuilder2Module), canActivate: [AuthLoginGuard, InitGuard] },
  { path: 'auth', loadChildren: () => import('./core/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'demo-components', loadChildren: () => import('./shared/demo-components-module/demo-components-module.module').then(m => m.DemoComponentsModuleModule), canActivate: [DevModeOnlyGuard] },
  { path: 'form-builder', loadChildren: () => import('./form-builder-tool/form-builder-tool.module').then(m => m.FormBuilderToolModule), canActivate: [AuthLoginGuard, InitGuard] },
  { path: 'performance-review-builder', loadChildren: () => import('./performance-review-builder-tool/performance-review-builder-tool.module').then(m => m.PerformanceReviewBuilderToolModule), canActivate: [AuthLoginGuard, InitGuard] },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];
const routerOptions: ExtraOptions = {
  enableTracing: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ]
})
export class AppRoutingModule { }
