<ui-modal-single [heading]="modalHeading()">
    <ng-template #contentSlot>
    <ng-container *ngComponentOutlet="
    tableComponent();
    inputs: data();
    " />
    </ng-template>

    <ng-template #rightSlot>
        <button class="btn btn-outline-light" (click)="dismiss()">Close</button>
    </ng-template>
</ui-modal-single>