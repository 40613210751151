import { IUIStreamlineIcon } from './streamline-icon-interfaces';

export function convertFaToSl(fa: string, color: 'danger' | 'warning' | 'success' | 'muted' = null): IUIStreamlineIcon {
  let slIcon;
  switch (fa) {
    case 'fa-clipboard':
      slIcon = {name: 'task-checklist', theme: 'bold'};
      break;
    case 'fa-books':
      slIcon = {name: 'book-open-bookmark', theme: 'bold'};
      break;
    case 'fa-newspaper':
      slIcon = {name: 'newspaper-fold', theme: 'bold'};
      break;
    case 'fa-video':
      slIcon = {name: 'video-player-alternate', theme: 'bold'};
      break;
    case 'fa-link':
      slIcon =  {name: 'hyperlink-3', theme: 'bold'};
      break;
    case 'fa-file-alt':
      slIcon = {name: 'common-file-text'};
      break;
    case 'fa-file-archive':
      slIcon = {name: 'folder-upload-alternate', theme: 'bold'};
      break;
    case 'fa-podcast':
      slIcon = {name: 'social-music-podcast'};
      break;
    case 'fa-users-medical':
      slIcon = {name: 'medical-personnel-doctor', theme: 'bold'};
      break;
    case 'fa-info-circle':
      slIcon = {name: 'interface-alert-warning-circle', size: 'lg', theme: 'micro-bold'};
      break;
    case 'sl-task-list-pen':
      slIcon = {name: 'task-list-pen', theme: 'bold'};
      break;
  }
  if (slIcon && color) {
    const NEED_FILL = ['book-library', 'interface-link', 'interface-alert-warning-circle', 'medical-personnel-doctor'];
    if (NEED_FILL.includes(slIcon?.name)) {
      slIcon.color = 'fill-' + color;
    } else {
      slIcon.color = color;
    }
  }

  return slIcon;
}

export const COLOUR_DICT = {
  'green': 'success',
  'yellow': 'warning',
  'red': 'danger'
};
