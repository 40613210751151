import { Component, computed, inject, input, Signal } from '@angular/core';
import { CustomisedMultiSelectComponent } from "../../customised-multi-select/customised-multi-select.component";
import { FormControl } from '@angular/forms';
import { USER_STATUS, UserService } from 'src/app/services/user.service';
import { ISingleSelectOption } from '@components/customised-single-select/customised-single-select.component';

@Component({
  selector: 'app-selector-multi-user',
  standalone: true,
  imports: [CustomisedMultiSelectComponent],
  templateUrl: './selector-multi-user.component.html',
  styleUrl: './selector-multi-user.component.scss'
})
export class SelectorMultiUserComponent {
label = input.required();
control = input.required<FormControl<string>>();
submitted = input(false);
filteredStatuses = input<USER_STATUS[]>([USER_STATUS.active]);
managersOnly = input(false);

userService = inject(UserService);

options: Signal<ISingleSelectOption[]> = computed(() => {
  return  this.userService.userList
    .filter(u => this.filteredStatuses().includes(u.status))
    .filter(u => !this.managersOnly() || u.isManager)
    .map(u => ({value: u.orgUserID, label: u.fullName, rightLabel: u.status, caption: `User ID ${u.staffID || '-'}`}))

}) 


}
