import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StepsControl } from 'src/app/shared/utils/workflows/workflow-steps-control';
import { unique } from 'underscore';
import { IRevokePolicySharedData, IRevokePolicyFlow } from '../../revoke-policy.interface';
import { AddPluralSPipe } from '../../../../../shared/pipes/add-plural-s.pipe';
import { NgIf } from '@angular/common';

@Component({
    templateUrl: 'revoke-policy-success.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AddPluralSPipe]
})

export class RevokePolicySuccessComponent implements OnInit {
    revokePolicyFlowSharedData: IRevokePolicySharedData;
    stepsControl: StepsControl<IRevokePolicyFlow>;
    revokedUserNum: number;
    notifiedNum:number=0;
    reminderNum:number=0;
    constructor(
        private cdRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.notifiedNum=unique((this.revokePolicyFlowSharedData.revokeResult?.notifiedAcks || []).map(r => r?.ack?.assigneeOrgUserID)).length;
        this.reminderNum=unique((this.revokePolicyFlowSharedData.revokeResult?.reminderAcks || []).map(r => r?.ack?.assigneeOrgUserID)).length;
        this.revokedUserNum =0;// unique(this.revokePolicyFlowSharedData.revokeResult.map(r => r.assigneeUserID)).length;
    }

    next() {
        this.stepsControl.updateSteps();
        this.stepsControl.nextStep();
        this.cdRef.detectChanges();
    }

    closeAndAddAnother() {
        this.revokePolicyFlowSharedData.shouldRevokeAnother = true;;
        this.next();
    }
}