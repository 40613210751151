<a class="text-decoration-none d-flex children-gap-3" [target]="openNewTab?'_bland':'_self'"
    [routerLink]="['/pages/policies/manage/policies/', policy.governanceID]" *ngIf="enableLink">
    <ui-avatar [slIcon]="{name: 'app-window-check'}" size="md"></ui-avatar>
    <div class="overflow-hidden">
        <p class="text-truncate f-small">{{policy.title}}</p>
        <p class="f-caption text-neutral-800">{{((policy?.governanceVersion?.governanceDocs?.length || 0) + ' Additional Document' | addPluralS)}}</p>
    </div>
</a>

<div *ngIf="!enableLink">
    <div class="d-flex align-items-center" [ngClass]="{'justify-content-between': removeBtn}">
        <div class="d-flex children-gap-3">
            <ui-avatar [slIcon]="{name: 'app-window-check'}" size="md"></ui-avatar>
            <div class="overflow-hidden">
                <p class="text-truncate f-small">{{policy.title}}</p>
                <p class="f-caption text-neutral-800">{{((policy?.governanceVersion?.governanceDocs?.length || 0) + '
                    Additional Document' | addPluralS)}}</p>
            </div>
        </div>
        <div *ngIf="removeBtn">
            <button class="btn btn-outline-light btn-sm" (click)="removePolicy()">Remove</button>
        </div>
    </div>
</div>
