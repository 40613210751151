import { style } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { UISpinnerComponent } from '../../../ui/ui-spinner/ui-spinner.component';

import { NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { takeUntil } from 'rxjs/operators';
import { WithDestroy } from '../../utils/mixins/withDestroy';

@Component({
  selector: 'app-shared-sidebar',
  templateUrl: 'shared-sidebar.component.html',
  styleUrls: ['shared-sidebar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    UISpinnerComponent,
    UIStreamlineIconComponent
  ],
})

export class SharedSidebarComponent extends WithDestroy() implements OnInit {
  @ViewChild('sidebar') sidebar: ElementRef;
  // Sidebar anime
  @Input() showSidebar: boolean = false;
  @Input() showHeaderUnderline = false;
  @Input() isLoading = true;
  @Input() sidebarWidth = '400px';
  @Input() keepOpen = false;
  @Output() sidebarClose = new EventEmitter();
  sidePanelID = 'side-panel';
  // Avoid sidebar blinks
  hasSidebar = false;
  modals: NgbModalRef[];

  constructor(
    private cd: ChangeDetectorRef,
    private modalService: NgbModal
  ) { super() }

  ngOnInit() {
    // assumed that modals opened after side panel are opened by side panel
    // used to prevent side panel closing when the modal is opened
    this.modalService.activeInstances.pipe(takeUntil(this.destroy$)).subscribe(m => {
      this.modals = m;
    })
   }

  toggleShowSidebar() {
    new Promise(
      (resolve, reject) => {
        this.hasSidebar = true;
        resolve(true);
      }
    ).then(() => {
      this.showSidebar = true;
      // close sidebar when click other places in the page
      if (this.keepOpen) {
        return;
      }
      if (this.showSidebar) {
        $(document).on('mouseup.sidebar', (e) => {
          // var container = $(this.sidebar.nativeElement);
          // if the target of the click isn't the container nor a descendant of the container
          // if (!container.is(e.target) && container.has(e.target).length === 0
          //   && (typeof e.target.className !== 'string' || doesNotHaveClass(e.target, 'dropdown-item')))
          // prevent closing if panel has dropdowm buttons
          if ((e.target?.className && e?.target?.className?.includes('m-curtain')) || !e.target.closest('#side-panel')) {
            this.closeSidebar();
            this.cd.detectChanges();
          }
        });
      } else {
        $(document).off('mouseup.sidebar');
      };
      this.cd.detectChanges();
    })
  }

  closeSidebar() {
    // console.log('1', this.modals);
    // Added by Jerry
    // if (this.modalService.hasOpenModals && this.modals?.length) {
    //   return;
    // }
    this.sidebarClose.emit();
    $(document).off('mouseup.sidebar');
    new Promise(
      (resolve, reject) => {
        this.showSidebar = false;
        resolve(true);
      }
    ).then(() => {
      this.showSidebar = false;
      this.cd.detectChanges();
    })
  }
}

export function doesNotHaveClass(element, className) {
  // Check if the element itself has the class
  if (element.classList.contains(className)) {
      return false;
  }

  // Traverse the ancestors of the element
  let parent = element.parentElement;
  while (parent) {
      if (parent.classList && parent.classList.contains(className)) {
          return false;
      }
      parent = parent.parentElement;
  }

  // None of the ancestors have the class
  return true;
}
