import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MandatoryTrainingService } from 'src/app/services/mandatory-training.service';
import { StepsControl } from 'src/app/shared/utils/workflows/workflow-steps-control';
import { IEntrolToMTFlowSharedData } from '../../../mandatory-training.types';
import { cloneDeep } from 'lodash';
import { flatten, isEqual, mapObject, pluck, values } from 'underscore';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { SharedConfirmationModalComponent } from 'src/app/shared/popups/shared-confirmation-modal/shared-confirmation-modal.component';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { CustomDateParserFormatter } from 'src/app/shared/utils/custom-date-parser-formatter';
import { CustomAdapter } from 'src/app/shared/utils/custom-adapter';
import { formatDate, NgIf } from '@angular/common';
import { TableColumnValuePrepareAndSortUtilsService } from 'src/app/services/table-column-value-prepare-and-sort-utils.service';
import { TruncatedCellComponent } from 'src/app/shared/components/truncated-cell/truncated-cell.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { isValidDate } from 'src/app/shared/utils/custom-validations';
import * as dayjs from 'dayjs';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { DefaultEditor } from 'src/app/shared-modules/ng2-smart-table/components/cell/cell-editors/default-editor';
import { FormsModule } from '@angular/forms';
import { UISpinnerComponent } from '../../../../../ui/ui-spinner/ui-spinner.component';
import { Ng2SmartTableComponent } from '../../../../../shared-modules/ng2-smart-table/ng2-smart-table.component';

@Component({
    templateUrl: 'enrol-to-mt-review-uploaded-users.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [Ng2SmartTableComponent, NgIf, UISpinnerComponent]
})

export class EnrolToMTReviewUploadedUsersStepComponent implements OnInit, AfterViewInit {
    stepsControl: StepsControl<any>;
    sharedData: IEntrolToMTFlowSharedData;
    userLists: any;
    submitting = false;
    updateEnrolDate: any = false;
    planUsers: any[] = [];

    tableSource: LocalDataSource = new LocalDataSource();

    tableSettings = {
        actions: {
            add: false,
            position: 'right',
        },
        edit: {
            saveButtonContent: 'Confirm',
            confirmSave: true
        },
        delete: {
            deleteButtonContent: 'Remove',
            confirmDelete: true,
        },
        pager: {
            display: false,
        },
        noDataMessage: 'No users uploaded',
        mode: 'inline',
        columns: {
            uploadStatus: {
                title: 'Status',
                type: 'html',
                valuePrepareFunction: (status) => {
                    switch (status) {
                        case 'error':
                            return `<span class="badge badge-danger me-2">ERROR</span>`;
                            break;
                        case 'duplicate':
                            return `<span class="badge badge-warning me-2">DUPLICATE</span>`;
                            break;
                        case 'new':
                            return `<span class="badge badge-success me-2">NEW</span>`;
                            break;
                        default:
                            return `<span class="badge badge-success me-2">NEW</span>`;
                            break;
                    }
                },
                editable: false,
                sort: false,
                filter: false,
                editor: {
                    type: 'custom',
                    component: CustomEditUploadStatusComponent,
                },
            },
            fullName: {
                title: 'Full Name',
                sort: false,
                filter: false
            },
            staffID: {
                title: 'User ID',
                valuePrepareFunction: this._columnUtil.replaceNoRecord(),
                sort: false,
                filter: false,
                type: 'custom',
                renderComponent: TruncatedCellComponent,
                onComponentInitFunction(instance) {
                  instance.className = 'staffID';
                },
            },
            enrolDate: {
                title: 'Enrolment Date',
                sort: false,
                filter: false,
                valuePrepareFunction: (val) => this.datePipe.transform(val),
                editor: {
                    type: 'custom',
                    component: CustomEditEnrolDateComponent,
                },
            },
        }
    };

    constructor(
        private cdRef: ChangeDetectorRef,
        private mandatoryTrainingService: MandatoryTrainingService,
        private modalService: NgbModal,
        private datePipe: LocalDatePipe,
        private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
        private analytics: AnalyticsService,
    ) { }

    ngOnInit() {
        this.userLists = this.sharedData.uploadedUsers;
        const userTableData = flatten(values(mapObject(cloneDeep(this.userLists), (val, key) => val.map(v => { v.uploadStatus = key; return v; }))))
            .map(u => {
                // format to ISO localdate
                let enrolDate = u.enrolDate;
                if (enrolDate && !isValidDate(enrolDate)) {
                        const arr = enrolDate.split(/,| |-|\//);
                        if (arr.length > 1 && arr[0] < 32) {
                            const t = arr[1];
                            arr[1] = arr[0];
                            arr[0] = t;
                        }
                        enrolDate = arr.join('/');
                    enrolDate = dayjs(enrolDate).format('YYYY-MM-DD');
                    if (enrolDate.includes('Invalid')) {
                        enrolDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-au');
                    }
                }

                return {...u, enrolDate};
            });
        this.tableSource.load(userTableData);
        this.mandatoryTrainingService.getPlanUsers(this.sharedData.plan.orgID, this.sharedData.plan.mtPlanID).subscribe((planUsers: any) => {
            this.planUsers = planUsers;
        });
        this.cdRef.markForCheck();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.cdRef.markForCheck();
        }, 0);
    }

    enrolUser() {
        this.submitting = true;
        this.tableSource.getAll().then(
            data => {
                const postData = JSON.parse(JSON.stringify(data.filter(d => d.uploadStatus === 'new'))).map(u => {
                    if (u.enrolDate) {
                        return { orgUserID: u.orgUserID, enrolDate: u.enrolDate };
                    }
                    return { orgUserID: u.orgUserID, enrolDate: formatDate(new Date(), 'yyyy-MM-dd', 'en-au') };

                });
                if (postData.length > 0) {
                    this.mandatoryTrainingService.enrolIndividualUsersToPlan(this.sharedData.plan.orgID, this.sharedData.plan.mtPlanID, postData).subscribe(
                        (data) => {
                            this.submitting = false;
                            this.sharedData.selectedUsers = postData;
                            this.next();
                            this.analytics.track('C-users-enrolled', {
                                type: 'csv',
                                userType: 'existing',
                                enrolmentDate: pluck(postData, 'enrolDate'),
                                usersAdded: this.sharedData.selectedUsers.length,
                                numberOfTrainingPlans: 1,
                                orgUserIDs: postData.map(d => d.orgUserID),
                                planIDs: [this.sharedData.plan.mtPlanID]

                            });
                        },
                        (error) => {
                            this.submitting = false;
                            console.log(error);
                            alert(error.message.message);
                        }
                    );
                } else {
                    this.submitting = false;
                    this.sharedData.selectedUsers = postData;
                    this.next();
                }

            }
        );
    }

    onDeleteRow(event) {
        if (event.data.uploadStatus !== 'new') {
            event.confirm.resolve();
        } else {
            const activeModal = this.modalService.open(SharedConfirmationModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
            activeModal.componentInstance.title = `Are you sure you want to remove this user?`;
            activeModal.componentInstance.content = 'Removing this user means they will no longer be enrolled in the plan.';
            activeModal.componentInstance.buttonText = 'Remove';
            activeModal.componentInstance.buttonClass = 'danger';
            activeModal.result.then(
                (result) => {
                    if (result === 'confirm') {
                        event.confirm.resolve();
                        setTimeout(() => {
                            this.cdRef.detectChanges();
                        }, 0);

                    } else {
                        event.confirm.reject();
                        setTimeout(() => {
                            this.cdRef.detectChanges();
                        }, 0);
                    }

                }
            );
        }
    }

    onEditRow(event) {
        if (event.data.fullName.toLowerCase().trim() === event.newData.fullName.toLowerCase().trim() && event.data.staffID.toLowerCase().trim() === event.newData.staffID.toLowerCase().trim()) {
            event.confirm.resolve();
            return;
        }
        const foundUser = cloneDeep(this.sharedData.availableUsersForUpload).filter(u => u.staffID).find(u => u.staffID.toLowerCase().trim() === event.newData.staffID.toLowerCase().trim() && u.fullName.toLowerCase().trim() === event.newData.fullName.toLowerCase().trim());
        if (foundUser) {
            if (this.planUsers.find(u => u.orgUser === foundUser.orgUserID)) {
                const activeModal = this.modalService.open(SharedConfirmationModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
                activeModal.componentInstance.title = `Duplicate`;
                activeModal.componentInstance.content = 'This user is already enrolled in the plan.';
                activeModal.componentInstance.buttonText = 'Close';
                activeModal.componentInstance.buttonClass = 'outline-light';
                activeModal.componentInstance.hasCancelButton = false;
                activeModal.result.then(
                    () => {
                        event.confirm.reject();
                        setTimeout(() => {
                            this.cdRef.detectChanges();
                        }, 0);

                    }
                );
            } else {
                if (event.source.data.filter(d => d.uploadStatus === 'new').find(u => u.staffID.toLowerCase().trim() === foundUser.staffID.toLowerCase().trim() && u.fullName.toLowerCase().trim() === foundUser.fullName.toLowerCase().trim())) {
                    const activeModal = this.modalService.open(SharedConfirmationModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
                    activeModal.componentInstance.title = `Duplicate`;
                    activeModal.componentInstance.content = 'This user is already uploaded.';
                    activeModal.componentInstance.buttonText = 'Close';
                    activeModal.componentInstance.buttonClass = 'outline-light';
                    activeModal.componentInstance.hasCancelButton = false;
                    activeModal.result.then(
                        () => {
                            event.confirm.reject();
                            setTimeout(() => {
                                this.cdRef.detectChanges();
                            }, 0);

                        }
                    );
                } else {
                    foundUser.uploadStatus = 'new';
                    foundUser.enrolDate = event.newData.enrolDate;
                    this.tableSource.load(cloneDeep(event.source.data).map(d => {
                        if (event.data.orgUserID) {
                            return (d.orgUserID && d.orgUserID === event.data.orgUserID) ? foundUser : d;
                        } else {
                            return isEqual(d, event.data) ? foundUser : d
                        }
                    })).then(() => this.cdRef.detectChanges());
                    // event.confirm.resolve();
                    // foundUser.uploadStatus = 'new';
                    // foundUser.enrolDate = event.newData.enrolDate;
                    // setTimeout(() => {
                    //     this.tableSource.load(cloneDeep(event.source.data).map(d => {
                    //         if (event.data.orgUserID) {
                    //             return (d.orgUserID && d.orgUserID === event.data.orgUserID) ? foundUser : d;
                    //         } else {
                    //             return isEqual(d, event.data) ? foundUser : d
                    //         }
                    //     }))
                    //     this.cdRef.detectChanges();
                    // }, 0);
                }
            }
        } else {
            const activeModal = this.modalService.open(SharedConfirmationModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
            activeModal.componentInstance.title = `Error`;
            activeModal.componentInstance.content = 'We did not recognise this user. Please check their details are correct.';
            activeModal.componentInstance.buttonText = 'Close';
            activeModal.componentInstance.buttonClass = 'outline-light';
            activeModal.componentInstance.hasCancelButton = false;
            activeModal.result.then(
                () => {
                    event.confirm.reject();
                    setTimeout(() => {
                        this.cdRef.detectChanges();
                    }, 0);

                }
            );
        }
    }

    next() {
        this.stepsControl.updateSteps();
        this.stepsControl.nextStep();
        this.cdRef.detectChanges();
    }
}

@Component({
    template: `
    <!-- <input #enrolDate type="date" class="form-control" placeholder="enrolment date" (change)="updateValue()"> -->
    <input #enrolDate
        type="search"
        ngbDatepicker
        class="form-control"
        #d="ngbDatepicker"
        onkeydown="return false"
        (click)="d.toggle()"
        (dateSelect)="updateValue()"
        (change)="updateValue()"
        placeholder="Enrolment Date"
        [(ngModel)]="modelDate"
    >
    `,
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        { provide: NgbDateAdapter, useClass: CustomAdapter },
    ],
    standalone: true,
    imports: [NgbInputDatepicker, FormsModule]
})

export class CustomEditEnrolDateComponent extends DefaultEditor implements AfterViewInit {
    modelDate: string;
    @ViewChild('enrolDate') enrolDate: ElementRef;
    constructor() {
        super();
    }
    ngAfterViewInit(): void {
        if (this.cell.newValue) {
            this.enrolDate.nativeElement.value = this.cell.getRow().getData().enrolDate;
            this.modelDate = this.cell.getRow().getData().enrolDate;
        }
    }

    updateValue() {
        // const date = this.enrolDate.nativeElement.value;
        this.cell.newValue = this.modelDate;
    }
}

@Component({
    template: `
    <div [innerHtml]="status"></div>
    `,
    standalone: true
})

export class CustomEditUploadStatusComponent extends DefaultEditor implements AfterViewInit {
    @ViewChild('enrolDate') enrolDate: ElementRef;
    status = 'new';
    badgeClass = 'badge-success';
    constructor() {
        super();
    }
    ngAfterViewInit(): void {
        if (this.cell.newValue) {
            this.status = this.cell.getValue();
            // switch (this.status) {
            //     case 'error':
            //         this.badgeClass='badge-danger';
            //         break;
            //     case 'duplicate':
            //         this.badgeClass='badge-warning';
            //         break;
            //     case 'new':
            //         this.badgeClass='badge-success';
            //         break;
            //     default:
            //         this.badgeClass='badge-success';
            //         break;
            // }
        }
    }
}