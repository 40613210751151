import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InitializeService } from '../../services/initialize.service';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InitGuard  {
  constructor(private initializeService: InitializeService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.initializeService.getInitStatus().pipe(
        filter(res => !!res),
    );
    // return  this.initializeService.initStatus$.value;
  }
}
