<a class="text-decoration-none" [routerLink]="resource.routerLink" [target]="openNewTab?'_bland':'_self'"
    *ngIf="enableLink && !resource?.deleted">
    <div class="d-flex children-gap-3">
        <img style="width: 77px; height: 44px; object-fit: cover;" loading="lazy" width="75" height="42"
            [src]="(resource.coverImageURL? resource.coverImageURL : defaultImage) | toAusmedCDN"
            alt="{{resource.title}} thumbnail" class="border rounded">
        <div class="overflow-hidden">
            <p class="text-truncate f-small" #text [style]="style" [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'"
                [placement]="'top-left auto'" [disableTooltip]="!showTooltip" [container]="'body'">
                <ui-streamline-icon class="me-1" *ngIf="resourceLocked"
                    [slIcon]="{name: 'lock-1', theme: 'bold', color: 'fill-neutral-700',size:'sm'}"
                    ngbTooltip="You do not have access to this resource. If you would like to access this resource, please contact Ausmed to update your plan" [tooltipClass]="'custom-tooltip'"
                    [placement]="'top-left auto'" [container]="'body'">
                </ui-streamline-icon>
                <ui-streamline-icon class="me-1" *ngIf="resource?.expired"
                    [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger',size:'sm'}"
                    ngbTooltip="This item is expired" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                    [container]="'body'">
                </ui-streamline-icon>
                <ui-streamline-icon class="me-1" *ngIf="resource?.updating"
                    [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger',size:'sm'}"
                    ngbTooltip="This item is currently being updated. Check back soon" [tooltipClass]="'custom-tooltip'"
                    [placement]="'top-left auto'" [container]="'body'">
                </ui-streamline-icon>
                <ui-streamline-icon class="me-1" *ngIf="resource?.unavailable"
                    [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger',size:'sm'}"
                    [ngbTooltip]="'This item status is '+resource.status.toLowerCase()+' and currently not available'"
                    [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'" [container]="'body'">
                </ui-streamline-icon>{{resource.title}}
            </p>
            <div class="divider-dot d-flex">
                <p *ngIf="isPassportEnabled" class="f-caption text-neutral-700">
                    <img src="/assets/images/logos/ausmed-passport-logos/logo-15x15.svg"
                        alt="ausmed-passport-logo-15x15">
                    <span class="text-neutral-700"> {{schdulePassportName}}</span>
                </p>
                <p *ngIf="displayedResourceType" class="f-caption text-neutral-700">
                    {{displayedResourceType}}</p>
                <p *ngIf="resource.min" class="f-caption text-neutral-700">{{resource.min | cpdTime}}</p>
                <p *ngIf="resourceEnabledGuidelines" class="f-caption text-neutral-700">
                    {{resourceEnabledGuidelines.length}}
                    {{resourceEnabledGuidelines.length === 1 ? 'Standard' : 'Standards'}}</p>
            </div>
        </div>
    </div>
</a>

<div *ngIf="!enableLink || resource?.deleted">
    <div class="d-flex align-items-center" [ngClass]="{'justify-content-between': removeBtn}">
        <div class="d-flex children-gap-3">
            <img style="width: 77px; height: 44px; object-fit: cover;" width="150" height="84" loading="lazy"
                [src]="(resource.coverImageURL? resource.coverImageURL : defaultImage) | toAusmedCDN"
                alt="{{resource.title}} thumbnail" class="border rounded">
            <div class="overflow-hidden">
                <p class="text-truncate f-small" #text [style]="style" [ngbTooltip]="list"
                    [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'" [disableTooltip]="!showTooltip"
                    [container]="'body'">
                    <ui-streamline-icon class="me-1" *ngIf="resourceLocked"
                        [slIcon]="{name: 'lock-1', theme: 'bold', color: 'fill-neutral-700',size:'sm'}"
                        ngbTooltip="You do not have access to this resource. If you would like to access this resource, please contact Ausmed to update your plan" [tooltipClass]="'custom-tooltip'"
                        [placement]="'top-left auto'" [container]="'body'">
                    </ui-streamline-icon>
                    <ui-streamline-icon class="me-1" *ngIf="resource?.deleted || resource?.expired"
                        [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger'}"
                        ngbTooltip="This item has been deleted" [tooltipClass]="'custom-tooltip'"
                        [placement]="'top-left auto'" [container]="'body'">
                    </ui-streamline-icon>{{resource.title || resource.resource.title }}
                </p>
                <div class="divider-dot d-flex mt-1">
                    <p *ngIf="displayResourcePassportBadge && passportName" class="f-caption text-neutral-700">
                        <img src="/assets/images/logos/ausmed-passport-logos/logo-15x15.svg"
                            alt="ausmed-passport-logo-15x15">
                        {{passportName}}
                    </p>
                    <p *ngIf="displayedResourceType" class="f-caption text-neutral-700">
                        {{displayedResourceType}}</p>
                    <p *ngIf="resource.min" class="f-caption text-neutral-700">{{resource.min | cpdTime}}</p>
                    <p *ngIf="resourceEnabledGuidelines" class="f-caption text-neutral-700">
                        {{resourceEnabledGuidelines.length}}
                        {{resourceEnabledGuidelines.length === 1 ? 'Standard' : 'Standards'}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="removeBtn">
            <button class="btn btn-outline-light btn-sm" (click)="removeResource()">Remove</button>
        </div>
        <div *ngIf="rowData?.badgeText" class="badge badge-primary-light ms-3">{{rowData?.badgeText}}</div>
    </div>
</div>

<!-- tooltip -->
<ng-template #list>
    <div class="d-flex flex-column">
        <p class="text-white f-small">{{resource?.title}}</p>
    </div>
</ng-template>