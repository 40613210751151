import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { IGuideline, ResourceService } from 'src/app/services/resource.service';
import { getByoResourceTypeByField, getByoResourceTypeByResource } from '../../utils/get-resource-type';
import { resourceTypeDict } from '../../byo-shared/byo-resource-types';
import { OrganisationService } from '../../../services/organisation.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { indexBy } from 'underscore';
import { getMaxWidth } from '../../utils/table-cell-functions';
import { getResourceDefaultImg } from '../../utils/get-resource-default-img';
import { PassportService } from 'src/app/services/passport.service';
import { FEATURES } from 'src/app/core/features.config';
import { ITrainingSchedule } from 'src/app/services/mandatory-training.service';
import { ToAusmedCDN } from '../../pipes/to-ausmed-cdn.pipe';
import { CpdTimePipe } from '../../pipes/cpd-time.pipe';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';

@Component({
  selector: 'search-item-resource',
  templateUrl: './search-item-resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterLink, NgbTooltip, NgClass, CpdTimePipe, ToAusmedCDN, UIStreamlineIconComponent]
})

export class SearchItemResourceComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() resource;
  @Input() value: any;
  @Input() enableLink = true;
  @Input() openNewTab = false;
  @Input() removeBtn = false;
  @Input() displayResourcePassportBadge = false;
  @Input() noTruncation = false;
  @Input() rowData: { badgeText?} | any;
  @Output() removeResourceEvent = new EventEmitter<string>();

  FEATURES = FEATURES;
  destroyed$ = new Subject<void>();
  resourceEnabledGuidelines: IGuideline[];
  guidelineDict: any;
  defaultImage = getResourceDefaultImg();
  resourceDict = this.resourceService.resourceDict;
  getResourceTypeByField = getByoResourceTypeByField;
  displayedResourceType: string;
  isPassportEnabled = false;
  schdulePassportName = '';

  // tooltip logic
  @Input() isTable = false;
  className = 'resource';
  titleWidth = 299;
  @ViewChild('text') text;
  showTooltip = false;
  isModal = false;

  resourceLocked = false;

  constructor(
    private resourceService: ResourceService,
    private organisationService: OrganisationService,
    private cdr: ChangeDetectorRef,
    private passportService: PassportService,
    private featureFlagService: FeatureFlagService
  ) {
  }

  ngOnInit() {
    this.titleWidth = this.noTruncation ? null : ((getMaxWidth(this.className, this.isModal, this.titleWidth)) - 100 || this.titleWidth);
    this._processResource();
    this.isPassportEnabled = this.rowData?.isPassportEnabled || false;
    this.passportService.getCachedPassports().pipe(take(1)).subscribe((passports) => {
      this.schdulePassportName = passports[0].name;
    }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resource) {
      this._processResource();
    }
  }

  private _processResource() {
    if (this.value) {
      this.resource = this.value;
    }
    this.resourceService.fetchCachedGuidelines().pipe(take(1), takeUntil(this.destroyed$)).subscribe(guidelines => {
      this.guidelineDict = indexBy(guidelines, 'guidelineID');
      if (this.resource?.orgID || this.resource?.orgResource) {
        if (this.resource?.resourceType === 'onlineCourse') {
          this.resource.routerLink = ['/pages/staff-portal/byo/course-analytics', this.resource.resourceID]
        } else {
          this.resource.routerLink = ['/pages/staff-portal/byo/item', this.resource.resourceID];
        }
        const { title: resourceTypeDisplay } = getByoResourceTypeByResource(this.resource);
        this.displayedResourceType = resourceTypeDisplay;
      } else {
        this.resource.routerLink = this.resource?.thirdParty ? ['/pages/library/provider/' + this.resource.providerName + '/resource', this.resource.resourceID] : ['/pages/library/resource', this.resource.resourceID];
        this.displayedResourceType = resourceTypeDict[(this.resource.resourceType || this.resource.activityType)] || this.resource.activityType;
      }
      this.resource.coverImageURL = this.resource.coverImageUrl || this.resource.coverImageURL;
      const {
        title: resourceTypeDisplay
      } = getByoResourceTypeByResource(this.resource);
      this.defaultImage = getResourceDefaultImg(resourceTypeDisplay);
      const enabledFrameworks = this.organisationService.getEnabledFrameworks();
      const rawGuidelines = this.resource.guidelines || [];
      let fullGuidelines;
      if (rawGuidelines.every(g => typeof g === 'string')) {
        fullGuidelines = rawGuidelines.map(g => this.guidelineDict[g]).filter(g => !!g);
      } else {
        fullGuidelines = rawGuidelines;
      }
      this.resourceEnabledGuidelines = this.resourceService.getEnabledGuidelines(fullGuidelines, enabledFrameworks);
      setTimeout(() => this.cdr.detectChanges());
    });
    if (this.featureFlagService.featureOn(FEATURES.limitedResourceAccess) && !this.resource.orgID && this.resource.providerType !== 'Internal') {
      this.resourceLocked = true;
      this.resourceService.isResourceLocked(this.resource).subscribe(
        (res) => {
          this.resourceLocked = res;
          this.cdr.detectChanges();
        }
      );

    }

  }

  removeResource() {
    this.removeResourceEvent.emit(this.resource.resourceID);
  }

  ngAfterViewInit() {
    const el = this.text?.nativeElement;
    if (el.scrollWidth > el.clientWidth) {
      this.showTooltip = true;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  get passportName(): string {
    return this.passportService.getPassportByResourceID(this.resource.resourceID)?.name || null;
  }

  get style() {
    if (this.isTable) {
      return 'width: ' + this.titleWidth + 'px;';
    }
    return '';
  }

}
