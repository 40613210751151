<p class="text-center">
  <span (click)="openSidePanel()" class="underlined-link">{{value}}</span>
</p>

<app-shared-sidebar #appSharedSidebar
  showHeaderUnderline="true"
  [isLoading]="false"
  sidebarWidth="700px"
  >
  <!-- <ng-container sidebarHeaderSlot>
    <h2>Activity Feed</h2>
  </ng-container> -->

  <ng-container sideBarBodySlot>
    <div class="side-panel-header">
        <p class="f-caption text-neutral-700">Showing extension history for</p>
        <div class="d-flex align-items-center gap-3">
            <p class="f-caption text-neutral-700 fw-semibold">
                {{rowData.resource?.title}}
            </p>
            <div class="d-flex justify-content-between f-caption align-items-center" [style]="'max-width: 200px;'">
                <ui-avatar size="sm" [fullName]="rowData.user.fullName" [url]="rowData.user.profilePicURL"></ui-avatar>
                <div #text class="text-truncate text-nowrap ms-1 text-neutral-700">{{rowData.user.fullName}}</div>
            </div>
            <app-assign-status-cell [value]="displayStatus" textClass="f-caption text-neutral-700"></app-assign-status-cell>
        </div>
    </div>
    <div class="p-4">
      <app-extensions-cell-table [extensions]="rowData.extensions" [originDueDate]="rowData.originDueDate"></app-extensions-cell-table>
    </div>
  </ng-container>
</app-shared-sidebar>
