import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';

import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'ui-toggle-buttons',
  templateUrl: 'toggle-buttons.component.html',
  styleUrls: ['toggle-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, FormsModule, UIStreamlineIconComponent, NgClass]
})

export class ToggleButtonsComponent implements OnInit {
  @Input() hasFullWidth?: boolean = false;
  @Input() model?: string;
  @Input() hasIcon?= false;
  @Input() items: { label: string, value: string, iconConfig?: IUIStreamlineIcon }[];
  @Output() modelChange: EventEmitter<string> = new EventEmitter();
  constructor(
  ) { }

  ngOnInit() {
    if(!this.model){
      this.model = this.items[0].value;
    }

    // console.log(this.items)
  }

  emitModel(e) {
    this.modelChange.emit(e)
  }

}
