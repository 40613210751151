import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GovernanceForm, GovernanceVersionBackend, IAckBackend, PolicyFromBackend, PolicyLinkedForms } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { IPPAckQueryForm } from '../reporting/reports/pp-all-ack-records/pp-all-ack-records.component';
import { IOrgMTAssignRecordGroupByDate } from 'src/app/services/report-learning-record.service';
import { objectToParams } from 'src/app/shared/utils/http/object-to-params';

@Injectable({
  providedIn: 'root'
})
export class PolicyProcedureApiService {

  constructor(
    private httpClient: HttpClient,
    private errorHandlingService: ErrorHandlingService,

  ) { }

  postNewPolicy<P>(newPolicyForm: GovernanceForm): Observable<P> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances`;
    return this.httpClient.post<P>(url, newPolicyForm);
  }

  getPolicyByID(policyID: string, versionID: string): Observable<PolicyFromBackend> {
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/${policyID}`;
    const params = new HttpParams().set('versionID', versionID)
    return this.httpClient.get<PolicyFromBackend>(url, { params });
  }

  //for pp detail page
  getPolicyByIDWithoutVersionID(policyID: string): Observable<PolicyFromBackend> {
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/${policyID}`;
    return this.httpClient.get<PolicyFromBackend>(url);
  }

  updatePolicy(policyID: string, versionID: string, newPolicyForm: GovernanceForm): Observable<PolicyFromBackend> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/policy/${policyID}/version/${versionID}`;
    return this.httpClient.put<PolicyFromBackend>(url, newPolicyForm);
  }

  searchAdditionalDocumentsByTitle(title: string, options?: { publishedOnly: boolean }): Observable<PolicyLinkedForms[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/search`;
    let params = new HttpParams().set('title_like', title);
    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        params = params.set(key, value);
      })
    }
    return this.httpClient.get<PolicyLinkedForms[]>(url, { params });
  }

  publishAPolicy(governanceID, versionID): Observable<PolicyFromBackend> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}/versions/${versionID}/publish`;
    return this.httpClient.get<PolicyFromBackend>(url);
  }

  getAllPolicyVersions(governanceID): Observable<GovernanceVersionBackend[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}/versions`;
    return this.httpClient.get<GovernanceVersionBackend[]>(url);
  }

  getAllPolicyVersionActionLogs(governanceID, versionnID): Observable<any> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}/versions/${versionnID}/logs`;
    return this.httpClient.get<any>(url);
  }

  searchGovernance(param, searchFilter): Observable<PolicyFromBackend[]> {
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/search?${param}`;
    return this.httpClient.post<PolicyFromBackend[]>(url, searchFilter);
  }

  archivePolicy(governanceID, versionID): Observable<PolicyFromBackend> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}/versions/${versionID}/archive`;
    return this.httpClient.get<PolicyFromBackend>(url);
  }

  unarchivePolicy(governanceID, versionID): Observable<PolicyFromBackend> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}/versions/${versionID}/unarchive`;
    return this.httpClient.get<PolicyFromBackend>(url);
  }

  getAllAcknowledgementsByUser(orgID, orgUserID): Observable<{ gov: any; ack: IAckBackend }[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/orgUsers/${orgUserID}/acks`;
    return this.httpClient.get<{ gov: any; ack: IAckBackend }[]>(url);
  }

  deletePolicy(governanceID): Observable<PolicyFromBackend> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}`;
    return this.httpClient.delete<PolicyFromBackend>(url);
  }

  sendAckReminder(orgUserID) {
    const url = environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/orgUsers/${orgUserID}/remind`;
    return this.httpClient.get(url).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  sendAckReminderByOrgUserIDs(orgUserIDs: string[]) {
    const url = environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/remindOrgUsers`;
    return this.httpClient.post(url, orgUserIDs).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  sendGovsReminder(govIDs: string[]) {
    const url = environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/remindGovs`;
    return this.httpClient.post(url, govIDs).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  sendReminderWhenSelectReAck(govIDs: string[]) {
    const url = environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/notifyAssignGovs`;
    return this.httpClient.post(url, govIDs).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  sendReminderWhenSelectNotifyJobRoles(govIDs: string[]) {
    const url = environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/notifyChangeGovs`;
    return this.httpClient.post(url, govIDs).pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  sendAckReminderByAckIDs(ackIDs: string[]) {
    const url = environment.accountServiceEndpoint + `/orgs/${localStorage.getItem('orgID')}/remindAcks`;
    return this.httpClient.post(url, ackIDs).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  fetchAllUpcomingPolicyReviews(payload) {
    const url = `${environment.resourceReadApiHost}/orgs/${localStorage.getItem('orgID')}/governances/search?_sort=reviewDate&_order=ASC&_page=1&_limit=20000`;
    return this.httpClient.post(url, payload).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getReportAcksByPolicy(payload: IPPAckQueryForm): Observable<IReportAckCounts[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/byGov`;
    return this.httpClient.post<IReportAckCounts[]>(url, payload).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getReportAcksByOrgUser(payload: IPPAckQueryForm): Observable<IReportAckCounts[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/byOrgUser`;
    return this.httpClient.post<IReportAckCounts[]>(url, payload).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  // getAllAcks(payload: IPPAckQueryForm) {
  //   const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/all??_page=1&_limit=20000`
  //   return this.httpClient.post<IAckBackend[]>(url, payload).pipe(
  //     catchError(this.errorHandlingService.handleHttpError),
  //   )
  // }

  revokePolicy(governanceID, data) {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/governances/${governanceID}/reassign`;
    return this.httpClient.post(url, data).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getAllAcks(queryForm: IPPAckQueryForm, assignParams?: HttpParams) {
    let url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/all?`;
    // url += (assignParams || '');
    return this.httpClient.post<IAckBackend[]>(url, queryForm)
      .pipe(catchError(this.errorHandlingService.handleHttpError),);
  }

  getOvertimePolicyCompliance(queryForm: IPPAckQueryForm, params: IPolicyOvertimeParams): Observable<IPolicyOvertime[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/sgReports/acks/overtime?`;
    return this.httpClient.post<IPolicyOvertime[]>(url, queryForm, {params: objectToParams(params)})
    .pipe(catchError(this.errorHandlingService.handleHttpError),);
  }


}

export interface IReportAckCounts {
  ackNum: number;
  acknowledgedNum: number;
  govID?: string;
  unacknowledgedNum?: number;
  orgUserID?: string;
  reAssignedNum: number;
  skippedNum: number;
  discardedNum: number;
}

export interface IPolicyOvertimeParams {
  // assignDate_from?: string;
  // assignDate_to?: string;
  // ackDate_from?: string;
  // ackDate_to?: string;
  // reminderFirstDate_from?: string;
  // reminderFirstDate_to?: string;
  // reminderLastDate_from?: string;
  // reminderLastDate_to?: string;
  stateDate_from?: string;
  stateDate_to?: string;
  interval?: 'Month' | 'MonthEnd';
}

export interface IPolicyOvertime {
  date: string;
  totalActiveUserNum: number;
  totalAckUserNum: number;
  compliantUserNum: number;
  nonCompliantUserNum: number;
  nonCompliantUserIDs?: string[];
  notReqActiveUserNum: number;
  compliantActiveUserNum: number;
  nonCompliantActiveUserNum: number;
  nonCompliantActiveUserIDs?: number;
}