<ui-modal-single [heading]="tableHeader">
    <ng-template #contentSlot>
        <div class="d-flex justify-content-end mb-2">
            <app-shared-table-actions [tableSettings]="currentSettings"></app-shared-table-actions>
        </div>

        <app-task-table [forProgress]="true" [assigns]="assigns" [tableKey]="'performanceReviewTasksReportModal'"
            [multiSelect]="multiSelect" (currentSettings)="currentSettings = $event"
            [oneStatusOnly]="activityStatus!=='All'" (updatedAssigns)="assigns=$event;ngOnInit()"></app-task-table>
        <!-- <app-smart-table [source]="source" [settings]="settings"
         tableDiv="progress-modal" [withWrapper]="true"
         [header]="tableHeader"
         ></app-smart-table> -->
    </ng-template>

    <ng-template #rightSlot>
        <button class="btn btn-outline-light" (click)="dismiss()">Close</button>
    </ng-template>

</ui-modal-single>