import { NgIf } from '@angular/common';
import { Component, computed, inject, input, Input, signal } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { AnalyticsPrService } from 'src/app/pages/performance-review/analytics-pr.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ITablePerformanceReviewAssign, PerformanceReviewService } from 'src/app/services/performance-review.service';
import { ConfirmationModalComponent } from 'src/app/shared/popups/confirmation-modal/confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-task-skip-modal',
    standalone: true,
    templateUrl: './task-skip-modal.component.html',
    imports: [
        ModalSingleComponent,
        UISpinnerComponent,
        NgIf
    ],
})
export class TaskSkipModalComponent {
    @Input() task: ITablePerformanceReviewAssign;
    @Input() context = 'my tasks';
    isSubmitting = signal(false);


    prService = inject(PerformanceReviewService);
    analyticsPRService = inject(AnalyticsPrService);
    activeModal = inject(NgbActiveModal);
    toaster = inject(ToastrService);
    broadcastService = inject(BroadcastService);

    closeModal() {
        this.activeModal.dismiss();
    }

    markAsSkipped() {
        this.isSubmitting.set(true);
        this.prService.markAsSkipped([this.task.taskID])
            .pipe(
                tap(() => this.analyticsPRService.sendTrack('PR-task-skipped', {
                    taskIDs: [this.task.taskID]
                }))
            )
            .subscribe((newTasks) => {
                this.isSubmitting.set(false);
                this.activeModal.close(newTasks.find(t => t.taskID === this.task.taskID));
                this.toaster.success('Task skipped');
                this.prService.updateCachedMyTasks(newTasks.find(t => t.taskID === this.task.taskID));
                this.broadcastService.broadcast('performance-task-updated');
            },
                (error) => {
                    this.isSubmitting.set(false);
                    console.log(error)
                    this.toaster.error(error?.error?.message, 'Error');
                });
    }


}
