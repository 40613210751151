import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toAusmedCDN',
    standalone: true
})

export class ToAusmedCDN implements PipeTransform {
  transform(value: any, size: any = null): any {
    if (value) {
      if (size && size === 'banner' && (value.indexOf('http://lh5.ggpht.com') >= 0 || value.indexOf('lh3.googleusercontent.com') >= 0)) {
        value = value + '=s1700';
      }
      if (value.indexOf('ausmed-images.s3-ap-southeast-2.amazonaws.com/ausmed.com') >= 0) {
        return value.replace('ausmed-images.s3-ap-southeast-2.amazonaws.com/ausmed.com', 'cdn-images.ausmed.com.au').replace(/^http:\/\//i, 'https://');
      } else if (value.indexOf('s3-ap-southeast-2.amazonaws.com/ausmed-cdn') >= 0) {
        return value.replace('s3-ap-southeast-2.amazonaws.com/ausmed-cdn', 'cdn.ausmed.com').replace(/^http:\/\//i, 'https://');
      } else if (value.indexOf('ausmed-cdn.s3-ap-southeast-2.amazonaws.com') >= 0) {
        return value.replace('ausmed-cdn.s3-ap-southeast-2.amazonaws.com', 'cdn.ausmed.com').replace(/^http:\/\//i, 'https://');
      } else if (value.indexOf('s3-ap-southeast-2.amazonaws.com/ausmed-images/ausmed.com') >= 0) {
        return value.replace('s3-ap-southeast-2.amazonaws.com/ausmed-images/ausmed.com', 'cdn-images.ausmed.com').replace(/^http:\/\//i, 'https://');
      } else if(value.indexOf('ausmed-cdn.s3.amazonaws.com')){
        return value.replace('ausmed-cdn.s3.amazonaws.com', 'cdn.ausmed.com').replace(/^http:\/\//i, 'https://');
      } else {
        return value.replace(/^http:\/\//i, 'https://');
      }
    }
  }
}
