<div (click)="openSidePanel(sidePanel)" class="cursor-pointer" name="assignCard"  data-bs-toggle="sidePanel">
  <ng-container [ngTemplateOutlet]="resourceCard"></ng-container>

  <!-- Resource card -->
  <ng-template #resourceCard>
    <div class="text-decoration-none" (click)="jumpToResource()"
        *ngIf="enableLink && !resource?.deleted">
        <div class="d-flex children-gap-3">
            <img style="width: 77px; height: 44px; object-fit: cover;" loading="lazy" width="75" height="42"
                [src]="(resource.coverImageURL? resource.coverImageURL : defaultImage) | toAusmedCDN"
                alt="{{resource.title}} thumbnail" class="border rounded">
            <div class="overflow-hidden">
                <p class="text-truncate f-small" #text [style]="style" [ngbTooltip]="list" [tooltipClass]="'custom-tooltip'"
                    [placement]="'top-left auto'" [disableTooltip]="!showTooltip" [container]="'body'">
                    <ui-streamline-icon class="me-1" *ngIf="resourceLocked"
                        [slIcon]="{name: 'lock-1', theme: 'bold', color: 'fill-neutral-700',size:'sm'}"
                        ngbTooltip="You do not have access to this resource. If you would like to access this resource, please contact Ausmed to update your plan" [tooltipClass]="'custom-tooltip'"
                        [placement]="'top-left auto'" [container]="'body'">
                    </ui-streamline-icon>
                    <ui-streamline-icon class="me-1" *ngIf="resource?.expired"
                        [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger',size:'sm'}"
                        ngbTooltip="This item is expired" [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'"
                        [container]="'body'">
                    </ui-streamline-icon>
                    <ui-streamline-icon class="me-1" *ngIf="resource?.updating"
                        [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger',size:'sm'}"
                        ngbTooltip="This item is currently being updated. Check back soon" [tooltipClass]="'custom-tooltip'"
                        [placement]="'top-left auto'" [container]="'body'">
                    </ui-streamline-icon>
                    <ui-streamline-icon class="me-1" *ngIf="resource?.unavailable"
                        [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger',size:'sm'}"
                        [ngbTooltip]="'This item status is '+resource.status.toLowerCase()+' and currently not available'"
                        [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'" [container]="'body'">
                    </ui-streamline-icon>{{resource.title}}
                </p>
                <div class="divider-dot d-flex">
                    <p *ngIf="isPassportEnabled" class="f-caption text-neutral-700">
                        <img src="/assets/images/logos/ausmed-passport-logos/logo-15x15.svg"
                            alt="ausmed-passport-logo-15x15">
                        <span class="text-neutral-700"> {{schdulePassportName}}</span>
                    </p>
                    <p *ngIf="displayedResourceType" class="f-caption text-neutral-700">
                        {{displayedResourceType}}</p>
                    <p *ngIf="resource.min" class="f-caption text-neutral-700">{{resource.min | cpdTime}}</p>
                    <p *ngIf="resourceEnabledGuidelines" class="f-caption text-neutral-700">
                        {{resourceEnabledGuidelines.length}}
                        {{resourceEnabledGuidelines.length === 1 ? 'Standard' : 'Standards'}}</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!enableLink || resource?.deleted">
        <div class="d-flex align-items-center" [ngClass]="{'justify-content-between': removeBtn}">
            <div class="d-flex children-gap-3">
                <img style="width: 77px; height: 44px; object-fit: cover;" width="150" height="84" loading="lazy"
                    [src]="(resource.coverImageURL? resource.coverImageURL : defaultImage) | toAusmedCDN"
                    alt="{{resource.title}} thumbnail" class="border rounded">
                <div class="overflow-hidden">
                    <p class="text-truncate f-small" #text [style]="style" [ngbTooltip]="list"
                        [tooltipClass]="'custom-tooltip'" [placement]="'top-left auto'" [disableTooltip]="!showTooltip"
                        [container]="'body'">
                        <ui-streamline-icon class="me-1" *ngIf="resourceLocked"
                            [slIcon]="{name: 'lock-1', theme: 'bold', color: 'fill-neutral-700',size:'sm'}"
                            ngbTooltip="You do not have access to this resource. If you would like to access this resource, please contact Ausmed to update your plan" [tooltipClass]="'custom-tooltip'"
                            [placement]="'top-left auto'" [container]="'body'">
                        </ui-streamline-icon>
                        <ui-streamline-icon class="me-1" *ngIf="resource?.deleted || resource?.expired"
                            [slIcon]="{name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-danger'}"
                            ngbTooltip="This item has been deleted" [tooltipClass]="'custom-tooltip'"
                            [placement]="'top-left auto'" [container]="'body'">
                        </ui-streamline-icon>{{resource.title || resource.resource.title }}
                    </p>
                    <div class="divider-dot d-flex mt-1">
                        <p *ngIf="displayResourcePassportBadge && passportName" class="f-caption text-neutral-700">
                            <img src="/assets/images/logos/ausmed-passport-logos/logo-15x15.svg"
                                alt="ausmed-passport-logo-15x15">
                            {{passportName}}
                        </p>
                        <p *ngIf="displayedResourceType" class="f-caption text-neutral-700">
                            {{displayedResourceType}}</p>
                        <p *ngIf="resource.min" class="f-caption text-neutral-700">{{resource.min | cpdTime}}</p>
                        <p *ngIf="resourceEnabledGuidelines" class="f-caption text-neutral-700">
                            {{resourceEnabledGuidelines.length}}
                            {{resourceEnabledGuidelines.length === 1 ? 'Standard' : 'Standards'}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="removeBtn">
                <button class="btn btn-outline-light btn-sm" (click)="removeResource()">Remove</button>
            </div>
            <div *ngIf="rowData?.badgeText" class="badge badge-primary-light ms-3">{{rowData?.badgeText}}</div>
        </div>
    </div>
  </ng-template>

  <!-- tooltip -->
  <ng-template #list>
      <div class="d-flex flex-column">
          <p class="text-white f-small">{{resource?.title}}</p>
      </div>
  </ng-template>
</div>

<ng-template #sidePanel let-offcanvas>
	<div class="offcanvas-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body">
    @if(isLoading()){
      <div class="w-100 h-80 d-flex justify-content-center align-items-center">
        <ui-spinner size="sm"></ui-spinner>
      </div>
    } @else {
      <div class="p-4">
        <div class="mb-4 d-flex align-items-center">
          <ui-avatar size="xs" [slIcon]=" {name: 'graph-stats-circle-alternate', theme: 'bold', color: 'fill-white'}" class="me-2" [iconBackgroundColor]="'green-700'" [textColor]="'text-white'"></ui-avatar>
          <p class="text-neutral-700 f-small">{{isManualAssign ? 'Manual' : 'Requirement'}} Assignment</p>
        </div>
        <div class="border-bottom mb-4">
          <h3 class="mb-4">{{resource?.title}}</h3>
          <div class="row mb-4">
            <div class="col-5">
              <p class="text-neutral-700 f-small">Assigned item</p>
            </div>
            <div class="col-7">
              <ng-container [ngTemplateOutlet]="resourceCard"></ng-container>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-5">
              <p class="text-neutral-700 f-small">User</p>
            </div>
            <div class="col-7">
              <staff-link-display *ngIf="this.rowData?.user" [rowData]="this.rowData?.user"
              textStyle="font-weight: 600 !important; font-size: 14px !important; color: #171D29 !important;"></staff-link-display>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-5">
              <p class="text-neutral-700 f-small">Status</p>
            </div>
            <div class="col-7 ">
              <app-assign-status-cell [value]="getAssignStatus(rowData.currentStatus)"></app-assign-status-cell>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-5">
              <p class="text-neutral-700 f-small ">Days Extended</p>
            </div>
            <div class="col-7 fw-medium f-small">
              {{rowData?.daysExtended || 0}}
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-5">
              <p class="text-neutral-700 f-small">Number of Extensions Given</p>
            </div>
            <div class="col-7 fw-medium f-small">
              {{rowData?.extensions?.length || 0}}
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-5">
              <p class="text-neutral-700 f-small">Open Date</p>
            </div>
            <div class="col-7 fw-medium f-small">
              {{rowData?.assignDate | dateToDDMMMYYYY}}
            </div>
          </div>
          <div class="row mb-4" *ngIf="rowData?.dueDate">
            <div class="col-5">
              <p class="text-neutral-700 f-small">Due Date</p>
            </div>
            <div class="col-7 fw-medium f-small">
              {{rowData?.dueDate | dateToDDMMMYYYY}}
            </div>
          </div>
          <div class="row mb-4" *ngIf="rowData?.currentStatus === 'Done' || rowData?.currentStatus === 'Completed'">
            <div class="col-5">
              <p class="text-neutral-700 f-small">Completed Date</p>
            </div>
            <div class="col-7 fw-medium f-small">
              {{rowData?.completeDate | dateToDDMMMYYYY}}
            </div>
          </div>
        </div>
       </div>


       <div *ngIf="isCompletedInternally" class="px-4">

        <!-- <div class="p-4 border cursor-pointer d-flex justify-content-between align-items-center mb-4" (click)="openLearningRecord()" style="border-radius: 8px;">
          <p class="fw-semibold">Learning record</p>
          <ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-right', theme: 'micro-bold', size: 'sm', color: 'text-neutral-700', svgStyle: {'padding-bottom': '2px'}}"></ui-streamline-icon>
        </div> -->

        <div class="m-sidepanel-section" *ngIf="linkedLearningRecord?.status == 'Skipped'">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <h4 class="fw-semibold mb-2">Evidence</h4>
          </div>
          <div>
            <ng-container *ngIf="evidences; else emptyEvidence">
              <ng-container *ngFor="let evidence of evidences">
                <app-side-panel-evidence-section [evidence]="evidence"
                  [context]="{orgActivityID: linkedLearningRecord.orgActivityID, user: rowData?.user}"></app-side-panel-evidence-section>
              </ng-container>
            </ng-container>

            <ng-template #emptyEvidence>
              <p class="mb-2">No evidence to display</p>
            </ng-template>

            <button *ngIf="!hasAddEvidenceSection; else addEvidenceSection" class="btn btn-outline-light"
              (click)="showAddSection()" type="button">
              <span class="text-neutral-900 fw-semibold f-small">
                + Add Evidence
              </span>
            </button>

            <ng-template #addEvidenceSection>
              <div id="add-evidence-section-container">
                <app-add-evidence #addEvidenceComponent context="sidepanel" (evidenceFormChange)="evidenceFormValue = $event"
                  (addEvidenceChange)="addEvidence($event)" (cancelStatusChange)="cancel()" [isSubmitting]="isLoading()"></app-add-evidence>
              </div>
            </ng-template>
          </div>
        </div>
       </div>
    }
  </div>


</ng-template>



<!-- <app-shared-sidebar #appSharedSidebar
  showHeaderUnderline="true"
  [isLoading]="isLoading()"
  sidebarWidth="532px"
  >
  <ng-container sideBarBodySlot *ngIf="!isLoading()">
     <div class="p-4">
      <div class="mb-4 d-flex align-items-center">
        <ui-avatar size="xs" [slIcon]=" {name: 'graph-stats-circle-alternate', theme: 'bold', color: 'fill-white'}" class="me-2" [iconBackgroundColor]="'green-700'" [textColor]="'text-white'"></ui-avatar>
        <p class="text-neutral-700 f-small">{{isManualAssign ? 'Manual' : 'Requirement'}} Assignment</p>
      </div>
      <div class="border-bottom mb-4">
        <h3 class="mb-4">{{resource?.title}}</h3>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small">Assigned item</p>
          </div>
          <div class="col-7">
            <ng-container [ngTemplateOutlet]="resourceCard"></ng-container>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small">User</p>
          </div>
          <div class="col-7">
            <staff-link-display *ngIf="this.rowData?.user" [rowData]="this.rowData?.user" textStyle="font-weight: 400 !important; font-size: 12px !important; color: #6F7786 !important;"></staff-link-display>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small">Status</p>
          </div>
          <div class="col-7 ">
            <app-assign-status-cell [value]="getAssignStatus(rowData.currentStatus)"></app-assign-status-cell>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small ">Days Extended</p>
          </div>
          <div class="col-7 fw-medium f-small">
            {{rowData?.daysExtended || 0}}
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small">Number of Extensions Given</p>
          </div>
          <div class="col-7 fw-medium f-small">
            {{rowData?.extensions?.length || 0}}
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small">Open Date</p>
          </div>
          <div class="col-7 fw-medium f-small">
            {{rowData?.assignDate | dateToDDMMMYYYY}}
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-5">
            <p class="text-neutral-700 f-small">Due Date</p>
          </div>
          <div class="col-7 fw-medium f-small">
            {{rowData?.dueDate | dateToDDMMMYYYY}}
          </div>
        </div>
        <div class="row mb-4" *ngIf="rowData?.currentStatus === 'Done' || rowData?.currentStatus === 'Completed'">
          <div class="col-5">
            <p class="text-neutral-700 f-small">Completed Date</p>
          </div>
          <div class="col-7 fw-medium f-small">
            {{rowData?.completeDate | dateToDDMMMYYYY}}
          </div>
        </div>
      </div>
     </div>


     <div *ngIf="isCompletedInternally" class="px-4">
      <div class="m-sidepanel-section">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h4 class="fw-semibold mb-2">Evidence</h4>
        </div>
        <div>
          <ng-container *ngIf="evidences; else emptyEvidence">
            <ng-container *ngFor="let evidence of evidences">
              <app-side-panel-evidence-section [evidence]="evidence"
                [context]="{orgActivityID: linkedLearningRecord.orgActivityID, user: rowData?.user}"></app-side-panel-evidence-section>
            </ng-container>
          </ng-container>

          <ng-template #emptyEvidence>
            <p class="mb-2">No evidence to display</p>
          </ng-template>

          <button *ngIf="!hasAddEvidenceSection; else addEvidenceSection" class="btn btn-outline-light"
            (click)="showAddSection()" type="button">
            <span class="text-neutral-900 fw-semibold f-small">
              + Add Evidence
            </span>
          </button>

          <ng-template #addEvidenceSection>
            <div id="add-evidence-section-container">
              <app-add-evidence context="sidepanel" (evidenceFormChange)="evidenceFormValue = $event"
                (addEvidenceChange)="addEvidence($event)" (cancelStatusChange)="cancel()"></app-add-evidence>
            </div>
          </ng-template>
        </div>
      </div>
     </div>
  </ng-container>
</app-shared-sidebar> -->
