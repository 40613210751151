import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAnalyticsEventWithArgs } from '@ausmed/cpdo-analytics-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, finalize, switchMap, tap } from 'rxjs/operators';
import { TABLE_HEADERS } from 'src/app/core/table-headers.dict';
import { PolicyProcedureApiService } from 'src/app/pages/policies-procedures/services/policy-procedure-api.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { EmailService } from 'src/app/services/email.service';
import { PnpService } from 'src/app/services/pnp.service';
import { TableColumnValuePrepareAndSortUtilsService } from 'src/app/services/table-column-value-prepare-and-sort-utils.service';
import { IEditOrgUserFormBulk, IOrgUser, UserService, USER_STATUS } from 'src/app/services/user.service';
import { clone, find, pick, pluck } from 'underscore';
import { JobRoleTitlesCellComponent } from '../../components/learning-table-popovers/jobrole-titles-cell.component';
import { TeamNamesCellComponent } from '../../components/learning-table-popovers/team-names-cell.component';
import { StaffLinkDisplayComponent } from '../../components/staff-link-display/staff-link-display.component';
import { StaffStatusDisplayComponent } from '../../components/staff-status-display/staff-status-display.component';
import { AccountStatusPipe } from '../../pipes/account-status.pipe';
import { LocalDatePipe } from '../../pipes/local-date.pipe';
import { removeEmptyFields } from '../../utils/remove-empty-fields';
import { UISpinnerComponent } from '../../../ui/ui-spinner/ui-spinner.component';
import { Ng2SmartTableComponent } from '../../../shared-modules/ng2-smart-table/ng2-smart-table.component';
import { AlertBannerComponent } from '../../components/alert-banner/alert-banner.component';

import { FormsModule } from '@angular/forms';
import { NgClass, NgSwitch, NgSwitchCase, NgIf, NgFor } from '@angular/common';
import { ModalSingleComponent } from '../../components/modal-single/modal-single.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { UserUpdateService } from 'src/app/services/user-update.service';
import { maxTeamNum } from 'src/app/services/group.service';
import { SmartTableService } from 'src/app/services/smart-table.service';

export type BulkUserAction = 'deactivate' | 'send reminders' | 'delete' | 'invite' | 'reinvite' | 'addToTeam' | 'addToJobRole' | 'removeFromTeam' | 'removeFromJobRole' | 'send ack reminders';
@Component({
  selector: 'app-bulk-staff-modal',
  templateUrl: './bulk-staff-modal.component.html',
  styleUrls: ['./bulk-staff-modal.component.scss'],
  standalone: true,
  imports: [ModalSingleComponent, NgClass, NgSwitch, NgSwitchCase, NgIf, FormsModule, NgFor, AlertBannerComponent, Ng2SmartTableComponent, UISpinnerComponent, UIStreamlineIconComponent]
})
export class BulkStaffModalComponent implements OnInit {
  isSubmitting = false;
  hideTable = true;
  action: BulkUserAction;
  selectedUsers: IOrgUser[];
  @Input() groupAdded?: {
    name?: string;
    title: string;
    teamID?: string;
    jobRoleID?: string;
    isFacility?: boolean
  }; // for team/job role
  @Input() refreshOrgUsers = true;
  total: number;
  failedList = [];
  skipDay = 0; // for reminder
  contextType: string;

  policies: IBulkPoliciedData;
  isRemindByAcks = true;

  errorMessage: string;

  tableSource = new LocalDataSource([]);
  tableSettings = {
    noDataMessage: 'No Users Found',
    actions: false,
    hideSubHeader: true,
    selectedRowIndex: -1,
    columns: {
      displayName: {
        title: 'User',
        isSticky: true,
        type: 'custom',
        renderComponent: StaffLinkDisplayComponent,
        compareFunction: this._columnUtil.compareByProperty('fullName'),
        filterFunction: this._columnUtil.searchByProperty('fullName'),
      },
      staffID: {
        title: 'User ID',
        valuePrepareFunction: this._columnUtil.replaceNoRecord(),
        type: 'string',
        sort: false,
      },
      status: {
        title: 'Account Status',
        type: 'custom',
        valuePrepareFunction: (val) => this.accountStatusPipe.transform(val),
        filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
        compareFunction: this._columnUtil.generalCompareWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
        renderComponent: StaffStatusDisplayComponent,
      },
      email: this.smartTableService.tableSettings.email,
      orgEmail: this.smartTableService.tableSettings.orgEmail,
      teamNames: {
        title: TABLE_HEADERS.USER.teams,
        type: 'custom',
        sort: false,
        renderComponent: TeamNamesCellComponent,
        filterFunction: this._columnUtil.searchByProperty('name'),
        valuePrepareFunction: this._columnUtil.extractByProperty('name'),
      },
      jobRoles: {
        title: TABLE_HEADERS.USER.jobRoles,
        type: 'custom',
        renderComponent: JobRoleTitlesCellComponent,
        valuePrepareFunction: this._columnUtil.extractByProperty('title'),
        filterFunction: this._columnUtil.searchByProperty('title'),
        sort: false
      },

      fn_displayedUserMobile: {
        title: 'Mobile Number',
        type: 'string',
        compareFunction: this._columnUtil.generalCompare()
      },
    }
  };
  numNonactive: number;


  constructor(
    private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
    private userService: UserService,
    private toaster: ToastrService,
    private activeModal: NgbActiveModal,
    private emailService: EmailService,
    private analytics: AnalyticsService,
    private accountStatusPipe: AccountStatusPipe,
    private router: Router,
    private pnpApiService: PolicyProcedureApiService,
    private pnpService: PnpService,
    private localDatePipe: LocalDatePipe,
    private smartTableService: SmartTableService,
    private userUpdateService: UserUpdateService


  ) { }

  ngOnInit(): void {
    if (!this.isManagingGroup) {
      delete this.tableSettings.columns.status;
      delete this.tableSettings.columns.teamNames;
      delete this.tableSettings.columns.jobRoles;
    } else {
      delete this.tableSettings.columns.fn_displayedUserMobile;
    }

    this.selectedUsers.forEach((i) => {
      // tslint:disable-next-line: no-unused-expression
      i.orgMobile ? i.fn_displayedUserMobile = i.orgMobile : i.fn_displayedUserMobile = '-';
      i.email = i.email || i.orgEmail || '-';
    });

    // filter out non-active users
    if (this.action === 'send ack reminders') {
      const initNum = this.selectedUsers.length;
      this.selectedUsers = this.selectedUsers.filter(u => u.status === 'Active');
      this.numNonactive = initNum - this.selectedUsers.length;
    }

    this.tableSource.load(this.selectedUsers);
    this.contextType = this.analytics.getContext(this.router.url);
  }

  get UserNum() {
    return this.selectedUsers?.length;
  }

  get singleUserName() {
    return this.selectedUsers[0]?.fullName;
  }

  get modalHeading() {
    // add/remove team
    if (this.isManagingGroup) {
      if (this.action.includes('add')) {
        return 'Add '
          + this.subjectUser
          + ` to ${this.groupAdded.name || this.groupAdded.title}?`;
      }
      return 'Remove '
        + this.subjectUser
        + ` from ${this.groupAdded.name || this.groupAdded.title}?`;
    }
    let _headTitle;
    switch (this.action) {
      case 'invite':
        if (this.selectedUsers.length === 1) {
          _headTitle = `Invite ${this.singleUserName} ?`;
        } else {
          _headTitle = `Invite ${this.UserNum} ${this.UserNum > 1 ? ' users' : ' user'} ?`;
        }
        break;
      case 'send reminders':
        if (this.selectedUsers.length === 1) {
          _headTitle = `Send ${this.singleUserName} a reminder?`;
        } else {
          _headTitle = `Remind ${this.UserNum} ${this.UserNum > 1 ? ' users' : ' user'} ?`;
        }
        break;
      case 'send ack reminders':
        if (this.selectedUsers.length === 1) {
          _headTitle = `Send ${this.numNonactive ? 1 : this.singleUserName} a reminder?`;
        } else if (this.selectedUsers.length === 0) {
          _headTitle = `Reminder${this.numNonactive > 1 ? 's' : ''} can't be sent`;
        } else {
          _headTitle = `Send ${this.selectedUsers.length} users a reminder?`;
        }
        break;
      default:
        _headTitle = 'Are you sure you want to '
          + this.action
          + ' '
          + this.UserNum
          + (this.UserNum > 1 ? ' users' : ' user')
          + '?';
    }
    return _headTitle;
  }

  get bulletPoints(): string[] {
    switch (this.action) {
      case 'deactivate':
        return [
          'Lose access to your organisation’s account on Ausmed, their profile status will be set to ‘Inactive’.',
          'Be removed from your organisation’s billing during the next billing cycle.',
          'Continue to retain access to their personal documentation records.'
        ];
      case 'delete':
        return [
          'They will be removed from your organisation’s account and billing during the next billing cycle.',
          'Any learning and compliance data that exists for the user will be permanently deleted.'
        ];
      default:
        return [];
    }
  }

  get isManagingGroup() {
    return this.action === 'addToJobRole'
      || this.action === 'addToTeam'
      || this.action === 'removeFromJobRole'
      || this.action === 'removeFromTeam';
  }

  get subjectUser() {
    if (this.selectedUsers?.length > 1) {
      return this.UserNum + ' users';
    }
    return this.selectedUsers[0].fullName;
  }

  confirmAction() {
    this.isSubmitting = true;
    let successList = [];
    this.total = this.selectedUsers.length;
    const originalTotal = this.selectedUsers.length;
    const orgID = localStorage.getItem('orgID');
    // const submissionFunc = this.getSubmissionFunc();

    const shortcutActions = ['invite', 'send reminders', 'reinvite'];
    if (shortcutActions.includes(this.action)) {
      const orgUserIDs = this.selectedUsers.map(u => u.orgUserID).filter(Boolean);
      const bulkInvitationOrReminderStartTime = dayjs();
      this.emailService.bulkInvitation(this.skipDay, orgUserIDs).pipe(tap((result) => {
        if ((this.action === 'invite' || this.action === 'reinvite' || this.action === 'send reminders') && result?.updatedOrgUsers?.length) {
          // TODO: Technical Debt: lack of an effective way to update orgUsers
          if (this.refreshOrgUsers) {
            this.userService.fetchOrgUsers(orgID).subscribe();
          }
        }
        successList = result.updatedOrgUsers?.filter(u => u.status === USER_STATUS.invited) || [];
        this.total = successList.length;
        this.failedList = this.selectedUsers
          .filter(({ orgUserID }) => !pluck(successList, 'orgUserID').includes(orgUserID));
      }), finalize(() => this.isSubmitting = false)).subscribe(() => {
        if (this.action !== 'send reminders' || this.total > 0) {
          this.activeModal.close(true);
        }

        // for reminders, display the error on modal
        if (this.action === 'send reminders' && !this.total) {
          this.errorMessage = `Reminders can't be sent. All ${this.selectedUsers.length}
                user${this.pluralS} selected have already been reminded in the last ${this.skipDay} day${this.skipDay > 1 ? 's' : ''}`;
        }

        if (this.total > 0) {
          let eventName: IAnalyticsEventWithArgs = this.action === 'invite' ? 'AD-invitation-sent' : 'AD-reminder-sent';
          this.analytics.track(eventName, {
            context: this.contextType,
            numberOfUsers: this.total,
            loadTime: Number(dayjs().diff(bulkInvitationOrReminderStartTime, 's', true)).toFixed(2),
          })
          this.toaster.success(this.toasterMessage);
        }
        if (this.failedList.length > 0 && this.action !== 'send reminders') {
          this.failedList.forEach(failedUser => {
            this.toaster.error('Unable to send invitation to ' + failedUser.fullName);
          });
        }
      }, error => this.toaster.error(error));

    } else if (this.action === 'send ack reminders') {
      const orgUserIDs = pluck(this.selectedUsers, 'orgUserID');
      let request$: Observable<any>;
      // use for event P_acknowledgementReminder_sent
      const bulkSendRemindersStartTime = dayjs();
      request$ = this.isRemindByAcks
        ? this.pnpApiService.sendAckReminderByAckIDs(this.policies.ackIDs)
        : this.pnpApiService.sendAckReminderByOrgUserIDs(orgUserIDs);
      request$.subscribe(() => {
        this.isSubmitting = false;
        this.toaster.success(this.toasterMessage);
        this._trackEvent_P_acknowledgementReminder_sent(this.selectedUsers.length, bulkSendRemindersStartTime);
        this.pnpService.announceAckUpdate();
        setTimeout(() => {
          this.activeModal.close(true);
        }, 1000);
      }, error => {
        this.toaster.error('Error', error);
        this.isSubmitting = false;
      });
    } else {
      const bulkActionStartTime = dayjs();
      from(this.selectedUsers).pipe(
        concatMap(user => this.getObs(user)
          .pipe(
            tap(() => {
              successList.push(user);
              // this.userService.removeOrgUserInCachedOrganisationUsers(user.orgUserID, user.email);
            }),
            catchError(error => {
              this.failedList.push(user);
              this.total--;
              this.toaster.error('Error', `User: ${user.fullName}. ` + error);
              // console.log('Error: deleting user', error);
              return error;
            }),
          )
        ),
      ).subscribe(() => {
      }, error => {
      },
        () => {
          if (this.total > 0) {
            // this.total = this.getRealTotal(successList);
            this.toaster.success(this.toasterMessage);
            this.userService.fetchOrgUsers(localStorage.getItem('orgID')).subscribe();
            this.sendAnalytics(successList.map(u => u.orgUserID), bulkActionStartTime);
          }
          this.isSubmitting = false;

          if (this.total === originalTotal || this.action === 'send reminders') {
            setTimeout(() => {
              this.activeModal.close(true);
            }, 1000);
          }

          // this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'), true).subscribe();
        }
      );
    }
  }

  // no userID (pending/unlinked)
  confirmInvitation(user: IOrgUser) {
    const orgID = localStorage.getItem('orgID');
    return this.emailService.sendOnboardingEmail(orgID, user, this.skipDay);
  }

  confirmGroupEdit(isRemove = false) {
    const overLimit = this.checkOverGroupLimit();
    if (overLimit) {
      const errorMessage = this.action === 'addToJobRole'
        ? 'A User can have a maximum of 6 Job Roles' : `A User can have a maximum of ${maxTeamNum} Teams and Facilities`;
      this.toaster.error(errorMessage);
      return;
    }

    this.isSubmitting = true;
    let users = this.selectedUsers.map(u => {
      u = clone(this.userService.managedOrgUserDictionaryByOrgUserID[u.orgUserID]);
      switch (this.action) {
        case 'addToTeam':
        case 'removeFromTeam':
          editTeam(u, this.groupAdded.teamID, this.action === 'removeFromTeam');
          break;
        case 'addToJobRole':
          const startDate = (this.localDatePipe.transform(dayjs(), 'date') as dayjs.Dayjs).format('YYYY-MM-DD');
          editJobRole(u, this.groupAdded.jobRoleID, startDate);
          break;
        case 'removeFromJobRole':
          let yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
          yesterday = (this.localDatePipe.transform(yesterday, 'date') as dayjs.Dayjs).format('YYYY-MM-DD');
          editJobRole(u, this.groupAdded.jobRoleID, yesterday, true);
          break;
      }
      return getUpdateUsersForm(u);
    });
    const bulkActionStartTime = dayjs();
    this.userUpdateService.bulkUpdateOrgUser(users, {removedFacility: this.removedFacility}).pipe(
      tap(() => this.toaster.success(`User${this.pluralS} successfully
        ${this.action.includes('remove') ? 'removed' : 'added'}`)),
      switchMap(() => this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'), true))
    )
      .subscribe(u => {
        this._trackEvent_AD_members(users, bulkActionStartTime, this.action.includes('remove') ? 'removed' : 'added')
        this.isSubmitting = false;
        this.activeModal.close(true);
      }, err => {
        // this.toaster.error(err);
        this.isSubmitting = false;
      });
  }

  checkOverGroupLimit() {
    if (this.action === 'addToTeam') {
      return this.selectedUsers.find(u => u.teams?.length > (maxTeamNum - 1));
    }
    if (this.action === 'addToJobRole') {
      return this.selectedUsers.find(u => u.activeJobRoleTakens?.length > 5);
    }
    return null;
  }

  getObs(user: IOrgUser) {
    switch (this.action) {
      case 'delete':
        return this.deleteUser(user);
      case 'deactivate':
        return this.deactivateUser(user);

      // below lines currently unused because all invites are handled by the same POST /invite
      case 'invite':
      case 'send reminders':
        return this.confirmInvitation(user);
      case 'reinvite':
        // don't reinvite unlinked users
        return user.userID ? this.reinvite(user) : this.confirmInvitation(user);
    }
  }

  get toasterMessage() {
    const pluralS = this.total > 1 ? 's' : '';
    const toasterAction = ({
      'delete': 'User' + pluralS + ' deleted',
      'deactivate': 'User' + pluralS + ' deactivated',
      'invite': 'Invitation' + pluralS + ' sent',
      'reinvite': 'Invitation' + pluralS + ' sent',
      'send reminders': 'Reminder' + pluralS + ' sent',
      'send ack reminders': 'Reminder' + pluralS + ' successfully sent',
      'addToJobRole': 'User' + pluralS + ' successfully added',
      'addToTeam': 'User' + pluralS + ' successfully added'
    } as { [k in BulkUserAction]: string })[this.action];
    if (this.action === 'send reminders') {
      return toasterAction;
    }
    return toasterAction;
  }

  deleteUser(user: IOrgUser): Observable<any> {
    let obs$: Observable<any>;
    if (!user.invitation) {
      console.log('removing staff: ', user);
      obs$ = from(this.userService.removeStaffFromOrg(localStorage.getItem('orgID'), user.userID));
    } else {
      console.log('deleting invite: ', user);
      obs$ = this.userService.deleteOrgInvitation(localStorage.getItem('orgID'), user);
    }
    // return obs$;
    // .pipe(tap(() => this.userService.removeOrgUserInCachedOrganisationUsers(user.orgUserID, user.email)));
    return obs$.pipe(tap(() => this.userService.deletedUser = user.orgUserID));

  }

  _trackEvent_AD_members(users, bulkActionStartTime, action) {
    const url = this.router.url;
    let context = '';
    if (url.includes('teams')) {
      context = 'team';
    } else if (url.includes('job-roles')) {
      context = 'jobRole';
    } else if (url.includes('admin-locations')) {
      context = 'facility'
    } else {
      return console.error('Wrong URL!');
    }
    const properties = {
      numberOfUsers: users.length,
      loadTime: Number(dayjs().diff(bulkActionStartTime, 's', true)).toFixed(2),
      orgUserIDs: users?.map(u => u.orgUserID)
    };
    properties[context] = this.groupAdded?.teamID || this.groupAdded?.jobRoleID // include team/jobroleid for IT support log
    // 'AD-team-members-added' 'AD-team-members-removed' 'AD-jobRole-members-added' 'AD-jobRole-members-added'
    if (context !== 'facility') {
      const eventName: IAnalyticsEventWithArgs = `AD-${context}-members-${action}` as IAnalyticsEventWithArgs;
      this.analytics.track(eventName, properties);
    }
  }

  _trackEvent_P_acknowledgementReminder_sent(numberOfUsers, bulkSendRemindersStartTime) {
    const url = this.router.url;
    let context = '';
    if (url.includes('manage/policies')) {
      context = 'acknowledgementRecords';
    } else if (url.includes('staff-transcript')) {
      context = 'transcript';
    } else if (url.includes('policies/dashboard')) {
      context = 'dashboard';
    } else if (url.includes('policy-management')) {
      context = 'policyManagement';
    } else if (url.includes('policy-user-compliance')) {
      context = 'policyUserCompliance';
    } else {
      return console.error('Wrong URL!');
    }
    const properties = {
      context: context,
      numberOfUsers: numberOfUsers,
      loadTime: Number(dayjs().diff(bulkSendRemindersStartTime, 's', true)).toFixed(2),
    };
    this.analytics.track('P-acknowledgementReminder-sent', properties);
  }

  deactivateUser(user: IOrgUser): Observable<any> {
    return from(this.userService.deactivateUser(user.orgUserID));
  }

  // currently unused
  // inactive users with userID
  reinvite(user: IOrgUser) {
    const orgID = localStorage.getItem('orgID');
    return from(this.emailService.inviteOrganisationUser(orgID, user.userID)).pipe(
      switchMap(() => this.emailService.sendOnboardingEmail(orgID, user, this.skipDay))
    );
  }

  sendAnalytics(successOrgIDs, bulkActionStartTime) {
    if (this.action === 'delete') {
      this.analytics.track('AD-orgUser-removed', {
        orgUserID: successOrgIDs,
        type: 'newUser',
        context: 'addFlow',
        userTypeSelected: 'multiple',
        numberOfUsers: successOrgIDs.length,
        loadTime: Number(dayjs().diff(bulkActionStartTime, 's', true)).toFixed(2),
      });
    } else if (this.action === 'deactivate') {
      this.analytics.track('AD-orgUser-deactivated', {
        orgUserID: successOrgIDs,
        userTypeSelected: 'multiple',
        numberOfUsers: successOrgIDs.length,
        loadTime: Number(dayjs().diff(bulkActionStartTime, 's', true)).toFixed(2),
      });
    }
  }

  get initUserForm() {
    return this.UserNum > 1 ? 'users' : 'user';
  }


  get pluralS() {
    return this.UserNum > 1 ? 's' : '';
  }

  get alwaysHideTable() {
    return this.action === 'send ack reminders'
      && (this.selectedUsers?.length === 1 && !this.numNonactive) || !this.UserNum;
  }

  get policyDisplay() {
    if (!this.policies) {
      return 'their policies';
    }
    return this.policies.name
      ? `"${this.policies.name}"`
      : this.policies.num + ' policies';
  }

  get groupType() {
        return this.groupAdded?.isFacility ? 'Facility' : this.action.includes('Team') ? 'Team' : 'Job Role'
  }

  onCancel() {
    this.activeModal.close();
  }

  // handle edge case of removing facility
  get removedFacility() {
    return this.groupAdded?.isFacility && this.action === 'removeFromTeam' ? this.groupAdded.teamID : null;
  }



  // currently not used
  // Only used in confirmation modal for send reminders
  // to substract by unsent reminders when skipDay is used.
  getRealTotal(successList: any[]) {
    // console.log(dayjs().format('YYYY-MM-DD') === ('2021-08-17'));
    if (this.action !== 'send reminders') {
      return this.total;
    }
    if (this.skipDay === 0) {
      return this.total;
    }
    const unsentList = successList
      .filter(user => { // only users with invitation (new users)
        return user.invitation;
      }).filter(newUser => { // new users that already received today OR that didn't receive in the response
        const oldInvitation = find(this.selectedUsers, user => user.orgUserID === newUser.orgUserID)?.invitation;
        if (oldInvitation.sentDate === dayjs().format('YYYY-MM-DD')) {
          return true;
        }
        if (newUser.invitation.sentDate === dayjs().format('YYYY-MM-DD')) {
          return true;
        }
      });
    return this.total - unsentList.length;

  }
  trackRequestSMSEvent() {
    this.analytics.track('AD-SMSreminders-requested', () => {
      window.open('https://knowledge.organisations.ausmed.com.au/en/knowledge/learner-activation-sms-email-reminders', '_blank');
    });
  }

  get alertCopy() {
    return this.numNonactive > 1 ? `Reminders cannot be sent to ${this.numNonactive} users because they haven't activated their accounts yet.`
      : `Reminder cannot be sent to 1 user because they haven't activated their account yet.`;
  }
}

export interface IBulkPoliciedData {
  name?; num?; ackIDs?;
}

// edit the orgUser object directly
export function editTeam(user: IOrgUser, teamID, isRemoving = false) {
  let newTeams = clone(user.teams || []);
  if (isRemoving) {
    newTeams = newTeams.filter(t => t !== teamID);
  } else if (!newTeams.includes(teamID)) {
    newTeams.push(teamID);
  }
  user.teams = newTeams;
}

export function editJobRole(user: IOrgUser, jobRoleId, newDate, isRemoving = false) {
  const actives = (user.activeJobRoleTakens);
  const newTakens = clone(user.jobRoleTakens || []) as any[];
  if (isRemoving) {
    const updatedTakenID = actives.find(t => t.jobRole === jobRoleId).jobRoleTakenID;
    if (updatedTakenID) {
      const endedTaken = newTakens.find(t => t.jobRoleTakenID === updatedTakenID);
      endedTaken.endDate = newDate;
    }
  } else {
    // check already member
    if (actives.some(active => active.jobRole === jobRoleId)) {
      return;
    }
    // add job role
    newTakens.push({
      jobRole: jobRoleId,
      startDate: newDate,
    });
  }
  user.jobRoleTakens = newTakens;
}

// Picks only attributes needed for update user api
export function getUpdateUsersForm(orgUser: IOrgUser) {
  const attributes: (keyof IEditOrgUserFormBulk)[] = [
    'orgUserID', 'isManager', 'staffID', 'teams', 'managedTeams', 'jobRoleTakens',
    'bundleTakens', 'orgEmail', 'orgMobile'];
  const user = (pick(orgUser, attributes));
  removeEmptyFields(user);
  return user;

}

