import { Component, Input, OnInit } from '@angular/core';
import { CustomisedMultiSelectComponent } from '../../../../shared/components/customised-multi-select/customised-multi-select.component';

@Component({
    selector: 'app-record-type-selector',
    templateUrl: './record-type-selector.component.html',
    styleUrls: ['./record-type-selector.component.scss'],
    standalone: true,
    imports: [CustomisedMultiSelectComponent]
})
export class RecordTypeSelectorComponent implements OnInit {
  @Input() controls;
  @Input() submitted;
  @Input() options = [
    {
      label: 'User Documented',
      value: 'User Documented',
    },
    {
      label: 'User Completed',
      value: 'User Completed',
    },
    {
      label: 'Internal Record',
      value: 'Internal Record',
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
    // this.reset();
  }

  reset() {
    this.controls.resourceTypes.setValue(this.options.map(o => o.value));
  }

}
