import { Component, computed, inject, signal } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';
import { ConfirmationModalComponent } from 'src/app/shared/popups/confirmation-modal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-feedback-delete',
  standalone: true,
  imports: [ConfirmationModalComponent],
  templateUrl: './feedback-delete.component.html',
  styleUrl: './feedback-delete.component.scss'
})
export class FeedbackDeleteComponent {
  feedbackID = signal(null);

  feedbackService = inject(FeedbackService);
  request$ = computed(() => this.feedbackService.deleteFeedback(this.feedbackID()))
  
}
