<app-loading-container [isLoaded]="isLoaded()">
@if(assigns()) {
    <div class="children-horizontal-gap-4  p-1" >
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="fw-semibold">{{task().user.fullName}}</h4>
            <a target="_blank" [routerLink]="['/pages/staff-transcript', this.task().assigneeOrgUserID]">
                <button class="btn btn-outline-light">Profile 
                    <ui-streamline-icon [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'sm'}" class="ms-2 pb-1"></ui-streamline-icon>
                </button>
            </a>
        </div>
        <div [hidden]="expandedCard()" class="card p-4">
            <p class="fw-semibold mb-4">Compliance</p>
            <div class="divider-line children-horizontal-gap-2">
                <div>
                    <app-task-modal-compliance-item (click)="onItemClick('Learning')" [nonCompliantNum]="nonCompliantAssigns()?.length"></app-task-modal-compliance-item>
                </div>
                <div *featureFlag="FEATURES.policyAndProcedure">
                    <app-task-modal-compliance-item (click)="onItemClick('Policy')" [nonCompliantNum]="ackCount()?.unacknowledgedNum || 0" [complianceEntity]="'Policy'"></app-task-modal-compliance-item>
                </div>
                <div>
                    <app-task-modal-compliance-item (click)="onItemClick('Performance')" [nonCompliantNum]="nonCompliantTasks()?.length" [complianceEntity]="'Performance'"></app-task-modal-compliance-item>
                </div>
            </div>
        </div>
        <div class="children-horizontal-gap-4">
            <div [hidden]="expandedCard() && expandedCard() !== 'Learning'">
                <app-task-modal-completion-item [completionEntity]="'Learning'"
                [completionNum]="assignCompletion()" [noItems]="!planCompletions()?.length">
                    <div class="children-horizontal-gap-4">
                        @for (item of planCompletions(); track item.mtPlanID) {
                            <div>
                                <app-task-modal-completion-bar 
                                (click)="onCompletionItemClick($event, 'Learning', item.mtPlanID)"
                                [title]="item.title" [percentage]="item.completion"></app-task-modal-completion-bar>
                            </div>
                        }
                    </div>
                </app-task-modal-completion-item>
            </div>
            <div [hidden]="expandedCard()">
                <app-task-modal-completion-item (click)="onCompletionItemClick($event, 'Policy', '')" 
                  [completionEntity]="'Policy'" [completionNum]="policyCompletion()"
                  [preventExpand]="true" [noItems]="!totalPolicyNum()">
                </app-task-modal-completion-item>
            </div>
            <div [hidden]="expandedCard() && expandedCard() !== 'Performance'">
                <app-task-modal-completion-item [completionEntity]="'Performance'" [completionNum]="taskCompletion()">
                    <div class="children-horizontal-gap-4">
                        @for (item of taskCycleCompletions(); track item.cycleID) {
                            <div>
                                <app-task-modal-completion-bar (click)="onCompletionItemClick($event, 'Performance', item.cycleID)" [title]="item.title" [percentage]="item.completion"></app-task-modal-completion-bar>
                            </div>
                        }
                    </div>
                </app-task-modal-completion-item>
            </div>
            <div [hidden]="expandedCard() && expandedCard() !== 'Goals'">
                <app-task-modal-completion-item [completionEntity]="'Goals'" 
                    [completionNum]="goalsInProgress()?.length" [noItems]="!goals()?.length">
                    <div class="children-horizontal-gap-4">
                        @for (item of goals(); track item.goalID) {
                            <div>
                                <!-- <app-task-modal-goal-card [goal]="item"></app-task-modal-goal-card> -->
                                <app-performance-review-goal-list-item [showCard]="true" [goal]="item"></app-performance-review-goal-list-item>
                            </div>
                        }
                    </div>
                </app-task-modal-completion-item>
            </div>  
        </div>
    </div>
}
</app-loading-container>