import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, tap, share } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { cacheResponse2 } from '../shared/utils/rx/cache-response-2';
import { map, reduce } from 'underscore';
import { IManuallyAssignContent } from '../shared/popups/assign-resource/assign-resource.types';
import { EvidenceType } from './evidence.service';

@Injectable({
  providedIn: 'root'
})
export class LearningService {
  activityInsights = new BehaviorSubject([]);
  qualifications = new BehaviorSubject([]);
  private _pendingActivityInsights$: Observable<any>;
  staffTrackings = new BehaviorSubject([]);
  private _pendingStaffTrackings$: Observable<any[]>;
  private assignedStaff = new BehaviorSubject([]);
  currentAssignedStaff = this.assignedStaff.asObservable();
  private _recordDelete = new Subject();
  recordDelete$ = this._recordDelete.asObservable();

  statusDict = {
    'dismissed': 'Dismissed',
    'completed': 'In progress',
    'documented': 'Completed',
    'inprogress': 'In progress',
    'queued': 'In progress',
  };


  constructor(
      private http: HttpClient,
      private errorHandlingService: ErrorHandlingService
  ) {
  }

  fetchQualifications = cacheResponse2(() => {
    return this.http
        .get(`${environment.accountServiceEndpoint}/quals`)
        .pipe(
            tap((resp: any) => {
              this.qualifications.next(resp);
            }),
            catchError(this.errorHandlingService.handleHttpError),
        );
  });

  fetchLearningRecordsByUserID(id) {
    const url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/learnings/search`;
    return this.http.get(url, {
      params: {
        userIDs: id,
        _limit: '999',
      }
    }).pipe(
        catchError(this.errorHandlingService.handleHttpError),
    );
  }

  /** Assign CPD APIs below **/
  // Old one, should be depracated, the new one is manuallyAssignResourceToStaff
  assignResourceToStaff(orgID: string, resourceAssignment: IResourceAssignment) {
    return this.http
        .post(`${environment.accountServiceEndpoint}/orgs/${orgID}/assignResource`, resourceAssignment)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  // assign multiple items to multiple users
  manuallyAssignResourceToStaff(orgID: string, resourceAssignment: IManuallyAssignContent[]) {
    return this.http
        .post(`${environment.accountServiceEndpoint}/orgs/${orgID}/assigns`, resourceAssignment)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  updateAssignedStaff(staff) {
    this.assignedStaff.next(staff);
  }

  unAssignResourceToStaff(orgID: string, resourceAssignment: IResourceAssignment) {
    return this.http
        .post(`${environment.accountServiceEndpoint}/orgs/${orgID}/unassignResource`, resourceAssignment)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  sendAssignedLearningRemindersToMultipleStaff(orgID: string, resourceID: string, assigneeIDs: string[]) {
    const url = `${environment.accountServiceEndpoint}/orgs/${orgID}/remindAssignees`;
    const orgRemindAssignTrackForm = { assigneeIDs, resourceID };
    return this.http.post(url, orgRemindAssignTrackForm).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  sendAssignedLearningReminders(orgID: string, resourceID: string) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/resources/${resourceID}/assignTracks/remind`).pipe(
        catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getSuggestedResource(orgID: string): Observable<any[]> {
    return this.http
        .get<any[]>(`${environment.resourceReadApiHost}/recommendResource/resources/${orgID}`)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  dismissRecommendResource(orgID: string, resourceID: string) {
    return this.http
        .get(`${environment.resourceApiHost}/recommendResource/resource/dismiss?orgID=${orgID}&resourceID=${resourceID}`)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        )
        .toPromise();
  }

  // fetchAssignedTracksBasedOnStaff(orgID: string) {
  //   return this.http
  //       .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/userAssignTracks`)
  //       .pipe(
  //           catchError(this.errorHandlingService.handleHttpError),
  //       );
  // }

  fetchAssignedTracksBasedOnResource(orgID: string): Observable<any> {
    return this.http
        .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/ResourceAssignTracks`)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  // fetchAssignedTracks(orgID: string, resourceID: string) {
  //   return this.http
  //       .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/resources/${resourceID}/assignTracks`)
  //       .pipe(
  //           catchError(this.errorHandlingService.handleHttpError),
  //       );
  // }

  fetchStaffActivities(orgID: string, userID: string) {
    return this.http
        .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/users/${userID}/activities`)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  /** Internal Records APIs below  **/

  fetchOrgActivityByUserID(userID) {
    return this.http
        .get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/orgActivities`)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  addOrgActivity(userID, orgActivity) {
    return this.http
        .post(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/orgActivities`, orgActivity)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  batchAddOrgActivity(orgID, orgActivity) {
    return this.http
        .post(`${environment.accountServiceEndpoint}/orgs/${orgID}/orgActivities`, orgActivity)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  batchAddOrgActivityMultiple(orgActivities: IBatchOrgActivityForm[]) {
    return this.http
    .post(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/allOrgActivities`, orgActivities)
    .pipe(
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  deleteOrgActivity(userID, orgActivity) {
    return this.http
        .delete(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/orgActivities/${orgActivity.orgActivityID}`)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  deleteOrgActivityByID(userID, orgActivityID) {
    const orgID = localStorage.getItem('orgID');
    return this.http
        .delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/users/${userID}/orgActivities/${orgActivityID}`)
        .pipe(catchError(this.errorHandlingService.handleHttpError));
  }

  propagateDeletion(activityID) {
    this._recordDelete.next(activityID);
  }

  fetchOrgActivity(userID, orgActivityID, withRes = false): Observable<any> {
    let url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/orgActivities/${orgActivityID}`;
    if (withRes) {
      url += '?withRes=true';
    }
    return this.http.get(url)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  fetchActivity(userID, activityID, withRes = false): Observable<any> {
    let url = `${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/activities/${activityID}`;
    if (withRes) {
      url += '?withRes=true';
    }
    return this.http.get(url)
        .pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
  }


  fetchActivityInsights() {
    const orgId = localStorage.getItem('orgID');
    return this.http
        .get(`${environment.accountServiceEndpoint}/orgs/${orgId}/actSimpleReflections`)
        .pipe(
            tap((resp: any) => {
              this.activityInsights.next(resp);
            }),
            catchError(this.errorHandlingService.handleHttpError),
        );
  }

  public fetchCachedActivityInsights(refresh?: boolean): Observable<any> {
    const activityInsights = this.activityInsights.value;

    if (this._pendingActivityInsights$) {
      return this._pendingActivityInsights$;
    } else if (activityInsights && activityInsights.length > 0 && !refresh) {
      return this.activityInsights.asObservable();
    }
    return this._pendingActivityInsights$ = this.fetchActivityInsights().pipe(
        share(),
        tap(() => delete this._pendingActivityInsights$),
        catchError((e) => {
          delete this._pendingActivityInsights$;
          return this.errorHandlingService.handleHttpError(e);
        }),
    );
  }

  // fetchStaffTrackings() {
  //   return this.http
  //       .get(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/userTracks`)
  //       .pipe(
  //           tap((resp: any) => {
  //             this.staffTrackings.next(resp);
  //           }),
  //           catchError(this.errorHandlingService.handleHttpError),
  //       );
  // }

  // public fetchCachedStaffTrackings(refresh?: boolean): Observable<any> {
  //   const staffTrackings = this.staffTrackings.value;
  //   if (this._pendingStaffTrackings$) {
  //     return this._pendingStaffTrackings$;
  //   } else if (staffTrackings && staffTrackings.length && !refresh) {
  //     return this.staffTrackings.asObservable();
  //   }
  //   const staffTrackings$ = this.fetchStaffTrackings();
  //   return this._pendingStaffTrackings$ = staffTrackings$.pipe(
  //       share(),
  //       tap(() => delete this._pendingStaffTrackings$),
  //       catchError((e) => {
  //         delete this._pendingStaffTrackings$;
  //         return this.errorHandlingService.handleHttpError(e);
  //       }),
  //   );
  // }

  // TODO: Add guides to practice
  public getLastLearning(staffTracks = [], user): void {
    const learningActivities = [
      'lecture_playing', 'lecture_documented', 'lecture_completed',
      'blog_clicked', 'blog_documented',
      'explainer_clicked', 'explainer_documented',
      'course_reachProgress', 'course_completed', 'course_documented',
      'org_clicked', 'org_documented', 'thirdParty_clicked', 'thirdParty_documented',
    ];
    const lastTrack = staffTracks.filter(t => t.tracks && user.userID === t.userID).length ?
        staffTracks.filter(t => t.tracks && user.userID === t.userID)[0].tracks.find(t =>
            learningActivities.includes(t.eventType)) : null;
    return (lastTrack ? lastTrack.eventDate : null);
  }

  public calcLearningHours(plan): { dateDiff: number, requiredHour: number, completedHour: number } {
    const dateDiff = Math.ceil((new Date(plan.endDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
    const professionProgresses = plan.progresses.filter((profession: any) => {
      return profession.qualificationType === 'Profession';
    });
    const endorsementProgresses = plan.progresses.filter((profession: any) => {
      return profession.qualificationType !== 'Profession';
    });
    if (professionProgresses && professionProgresses.length) {
      const creditFlag = professionProgresses[0].requiredCreditMin || 0;
      const learningRelatedMins = map(professionProgresses, (professionProgress) => {
        const required = creditFlag ? professionProgress.requiredCreditMin : professionProgress.requiredMin;
        let completed = 0;
        if (professionProgress.groupProgresses) {
          completed = creditFlag ? professionProgress.validCreditMin : professionProgress.validMin;
        } else {
          completed = creditFlag ? professionProgress.completedCreditMin : professionProgress.completedMin;
          const endorsementCompleted = reduce(endorsementProgresses, function (memo, endorsementProgress) {
            return memo + (creditFlag ? endorsementProgress.completedCreditMin : endorsementProgress.completedMin);
          }, 0);
          completed += endorsementCompleted;
        }
        const returnObject = {'required': required || 0, 'completed': completed || 0};
        return returnObject;
      });
      const requiredHour = Number(reduce(learningRelatedMins, function (memo, relatedMins) {
        return memo + relatedMins.required;
      }, 0));
      const completedHour = Number(reduce(learningRelatedMins, function (memo, relatedMins) {
        return memo + relatedMins.completed;
      }, 0));
      return {
        dateDiff, requiredHour, completedHour,
      };
    }
  }

}

export interface IResourceAssignment {
  managerID: string;
  assigneeIDs: string[];
  orgID: string;
  resourceID: string;
  source: string;
  dueDate: string;
}

export interface IBatchOrgActivityForm {
  owners: string[],
  title: string,
  activityType: string,
  resourceID?: string,
  providerType?: string,
  startDateLocal?: string,
  endDateLocal?: string,
  completeDateLocal?: string,
  min?: number,
  comment?: string,
  accreditations?: any[],
  detail?: IOrgActivityDetail,
}

export interface IOrgActivityDetail {
  notes?: any;
  orgEventID?: string;
}
