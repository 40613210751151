import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


export class NgbDatepickerHelpers {

    // constructor(
    //     private dateAdapter: NgbDateAdapter<string>,
    // ) {}

    static getToday(): NgbDateStruct {
        const value = (formatDate(new Date(), 'yyyy-MM-dd', 'en-au'));
        return this.fromModel(value);
    }

    static fromModel(model: string) {
        if (model) {
            const date = model.toString().split('-');
            return {
                year: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                day: parseInt(date[2], 10)
            };
        }
        return null;
    }
}
