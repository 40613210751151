import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { of, combineLatest, Subject } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { IContract, OrganisationService } from '../../../services/organisation.service';

import { PRODUCT_TYPES } from '../../../core/guards/mode.constants';
import { NgbModal, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavContent, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import { ContractModalComponent } from './contract/contract-modal/contract-modal.component';
import { clone, sortBy, uniq } from 'underscore';
import timezones from './timezones';
import { AddressService } from 'src/app/services/address.service';
import { ToastrService } from 'ngx-toastr';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgForm, FormsModule } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { getIdentifyBody } from 'src/app/shared/utils/get-analytics-body';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { IOrganisation } from '../interfaces/IOrganisation';
import { cloneDeep } from 'lodash';
import { AlertBannerComponent } from '../../../shared/components/alert-banner/alert-banner.component';
import { NgTemplateOutlet, NgFor, NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'organisation-component',
    styleUrls: ['./organisation.component.scss'],
    templateUrl: './organisation.component.html',
    animations: [
        trigger('fadeAnimation', [
            transition('void => *', [
                style({
                    opacity: 0
                }),
                animate("200ms ease-in", style({
                    opacity: 1
                }))
            ]),
            transition('* => void', [
                style({
                    opacity: 1
                }),
                animate("200ms ease-in", style({
                    opacity: 0
                }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavContent, NgTemplateOutlet, NgFor, NgIf, NgbNavOutlet, FormsModule, AlertBannerComponent, DatePipe]
})
export class OrganisationComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  @ViewChild('f') f: NgForm;
  activeContract: IContract;

  contractFiles: any[];
  contracts = [];
  user: any;
  organisation: any = {
    lmsSetting: {
      billingContact: {}
    },
    address: {},
    postalAddress: {}
  };
  files: any[];

  PRODUCT_TYPES = PRODUCT_TYPES;

  updatingOrganisation = false;
  states: any;
  countries;
  pStates: any;

  allStates;
  hasCCEmail = false;
  showCCEmail = false;
  sameAsAccountEmail;
  sameAsOrgAddress;
  timezones = sortBy(timezones);
  error = '';

  oriOrg: {
    name, lmsSetting: {mainContact}, email, phone, postalAddress, timezone
  };

  constructor(
    private userService: UserService,
    private organisationService: OrganisationService,
    private modalService: NgbModal,
    private addressService: AddressService,
    private toast: ToastrService,
    private analytics: AnalyticsService,
    private featureFlag: FeatureFlagService,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.organisationService.fetchOrganisationContract(localStorage.getItem('orgID')),
      this.userService.getOrgUser(),
      this.organisationService.getOrganisation(),
      this.organisationService.getActiveContract()
    ]).pipe(
      takeUntil(this.destroyed$),
      switchMap(([contracts, user, org, activeContract]) => {
        this.user = user;
        this.organisation = JSON.parse(JSON.stringify(org));
        this.oriOrg = this.oriOrg || cloneDeep(this.organisation);
        this.organisation.address = this.organisation.address || {};
        this.organisation.lmsSetting.billingContact = this.organisation.lmsSetting.billingContact || {};
        this.allStates = this.addressService.getStates();
        this.countries = this.addressService.getCountries();
        this.onCountryChange();
        this.onPostalCountryChange();
        if (this.organisation.lmsSetting.billingContact.billingCCEmails && this.organisation.lmsSetting.billingContact.billingCCEmails.length > 0) {
          this.organisation.lmsSetting.billingContact.billingCCEmail = this.organisation.lmsSetting.billingContact.billingCCEmails[0];
          this.hasCCEmail = true;
          this.showCCEmail = true;
        }
        this.organisation.timezone = this.organisation.timezone ? this.organisation.timezone : 'Australia/Melbourne';
        this.activeContract = activeContract;

        if (this.user && this.user.isManager && contracts && contracts.length > 0) {
          this.contracts = sortBy(contracts, 'startDate').reverse();
          return of(null);
        } else {
          return of(null);
        }
      }),
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  removeCCEmail() {
    this.organisation.lmsSetting.billingContact.billingCCEmail = null;
    this.hasCCEmail = false;
    this.showCCEmail = false;
  }

  copyAccountEmail(event) {
    if (event.target.checked) {
      this.organisation.lmsSetting.billingContact.billingEmail = this.organisation.email;
      this.sameAsAccountEmail = true;
    } else {
      this.sameAsAccountEmail = false;
    }
  }

  copyOrgAddress(event) {
    if (event.target.checked) {
      this.organisation.address.street1 = this.organisation.postalAddress.street1;
      this.organisation.address.street2 = this.organisation.postalAddress.street2;
      this.organisation.address.suburb = this.organisation.postalAddress.suburb;
      this.organisation.address.state = this.organisation.postalAddress.state;
      this.organisation.address.postcode = this.organisation.postalAddress.postcode;
      this.organisation.address.country = this.organisation.postalAddress.country;
      this.sameAsOrgAddress = true;
    } else {
      this.sameAsOrgAddress = false;
    }
  }

  onCountryChange() {
    const countryDetail = this.countries.find(c => c.countryName === this.organisation.address.country);
    this.states = this.allStates.filter(s => s.key === countryDetail.countryCode || s.key === countryDetail.isoAlpha3);
    if (!this.states.find(state => state.value === this.organisation.address.state)) {
      this.organisation.address.state = '';
    }
  }

  onPostalCountryChange() {
    const countryDetail = this.countries.find(c => c.countryName === this.organisation.postalAddress.country);
    this.pStates = this.allStates.filter(s => s.key === countryDetail.countryCode || s.key === countryDetail.isoAlpha3);
    if (!this.pStates.find(state => state.value === this.organisation.postalAddress.state)) {
      this.organisation.postalAddress.state = '';
    }
  }

  viewContractFiles(contract) {
    if (contract.contractFiles) {
      const activeModal = this.modalService.open(ContractModalComponent, { size: 'md' });
      activeModal.componentInstance.files = contract.contractFiles;
    } else {
      this.organisationService.fetchOrganisationContractFile(this.organisation.orgID, contract.contractID).subscribe(
        files => {
          contract.contractFiles = files;
          const activeModal = this.modalService.open(ContractModalComponent, { size: 'md' });
          activeModal.componentInstance.files = files;
        }
      );
    }
  }

  updateOrganisation() {
    if (this.f.invalid) {
      Object.keys(this.f.controls).forEach(key => {
        this.f.form.controls[key].markAsDirty();
        this.f.form.controls[key].markAsTouched();
      });
      return;
    }
    if (this.organisation.lmsSetting.billingContact.billingCCEmail) {
      this.organisation.lmsSetting.billingContact.billingCCEmails = [this.organisation.lmsSetting.billingContact.billingCCEmail.trim()];
    }
    this.organisation.email = this.organisation.email.trim();
    this.updatingOrganisation = true;

    // appcues
    const appcuesEventName = 'appcues-org-details-added';
    addAppcueEvent(this.organisation, appcuesEventName);

    this.organisationService.updateOrganisation(this.organisation).pipe(
      finalize(() => this.updatingOrganisation = false)
    ).subscribe(
      (resp: any) => {
        this.organisationService.setOrganisation(resp);
        this.toast.success('Settings Updated');

        // analytics
        this.analytics.track('A-settings-updated', {
          context: 'orgDetails',
          orgDetails: {
            displayName: this.organisation.name !== this.oriOrg.name,
            contactName: this.organisation.lmsSetting?.mainContact !== this.oriOrg.lmsSetting.mainContact,
            contactEmail: this.oriOrg.email !== this.organisation.email,
            contactPhone: this.oriOrg.phone !== this.organisation.phone,
            address: JSON.stringify(this.oriOrg.postalAddress) !== JSON.stringify(this.organisation.postalAddress),
            timezone: this.organisation.timezone !== this.oriOrg.timezone,
          }
        });
        this.analytics.identify(this.user.userID, getIdentifyBody(this.user, this.organisation, this.activeContract, {
          orgService: this.organisationService,
          featureFlagService: this.featureFlag,
          userService: this.userService
        }));
        appcueTrack(appcuesEventName);
        // appcueGroupTrack(resp);

        // refresh ori org
        this.oriOrg = cloneDeep(this.organisation);

      }, error => {
        console.log(error);
        this.error = error;
      }
    );
  }
}

// add appcue event to the org object
// side effect: send the appcue event
export function addAppcueEvent(org: IOrganisation , eventName: string) {
  const newEvents = org.lmsSetting.appcuesEvents || [];
  // appcueTrack(eventName);
  newEvents.push(eventName);
  org.lmsSetting.appcuesEvents = uniq(newEvents);
}

export function appcueTrack(eventName: string) {
  try {
    (window as any).Appcues.track(eventName);
  } catch (e) {
    console.log(e);
  }
}

export function appcueGroupTrack(org: IOrganisation) {
  try {
    const existingEvents = org.lmsSetting.appcuesEvents || [];
    if (existingEvents.length) {
      (window as any).Appcues.group(
        org.orgID, // unique, required
        {
          appcuesEvents: existingEvents.join(', '),
        }
    );
    }
  } catch (e) {
    console.log(e);
  }
}
