import { NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { InputErrorDirective } from '../../directives/input-error.directive';
import { difference, intersection, uniq } from 'underscore';

@Component({
  selector: 'app-customised-multi-select-for-guidelines',
  templateUrl: './customised-multi-select-for-guidelines.component.html',
  standalone: true,
  imports: [NgIf, NgSelectModule, FormsModule, ReactiveFormsModule, SlicePipe, NgFor, UIStreamlineIconComponent, InputErrorDirective, NgClass]
})
export class CustomisedMultiSelectForGuidelineslComponent {
  @ViewChild('selector') selector: NgSelectComponent;
  @Input() items: any[];
  @Input() name: string;
  @Input() id: string;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() ngModelValue: any[];
  @Input() showError = false;
  @Input() searchFn;
  @Input() groupByValue = '';
  @Output() ngModelValueChange = new EventEmitter();
  @Input() control: FormControl;
  @Input({ required: true }) valueMode: 'ngModel' | 'formControl';
  @Input() disabled = false;
  filteredItems: any[];

  ngOnInit() {
    this.reorderGuidelines(this.items);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.items?.currentValue) {
      this.reorderGuidelines(this.items);
    }
    if(this.disabled && this.control){
      this.control.disable();
    }
  }

  changeNgModelValue(e) {
    this.ngModelValueChange.emit(e);
  }

  onSelectAll() {
    const selected = (this.filteredItems || this.items).map(item => item[this.bindValue]);
    this.ngModelValue = selected;
    this.ngModelValueChange.emit(this.ngModelValue);
    this.filteredItems = null;
    if (this.selector) { this.selector.close(); }
  }

  onClearAll() {
    this.ngModelValue = [];
    this.ngModelValueChange.emit(this.ngModelValue);
  }


  getFilteredItems(e) {
    this.filteredItems = e.items;
  }

  private reorderGuidelines(guidelines) {
    // const standards = guidelines.filter(g => g.guidelineType === 'standard') || [];
    // const outcomes = [...(guidelines.filter(g => g.guidelineType === 'outcome' && g.parentGuidelineID) || [])].map((outcome) => {
    //   outcome.parentGuideline = standards.find(s => s.guidelineID === outcome.parentGuidelineID);
    //   return outcome;
    // });
    // let sortedGuilines: any[] = [];
    // standards.forEach((s: any) => {
    //   sortedGuilines.push(s);
    //   sortedGuilines.push(outcomes.filter(g => g.parentGuidelineID === s.guidelineID));
    // });
    // this.items = flatten(sortedGuilines);
  }

  onClearAllGuidelines() {
    this.control.patchValue('');
  }

  getEnabledGuidelines(guidelines: any[]): any[] {
    return guidelines.filter(guideline => !!guideline.provider);
  }

  addAnItem(guideline) {
    // select parent standard while oucome selected
    if (guideline.parentGuidelineID && guideline.parentGuideline) {
      if (this.valueMode === 'ngModel' && !this.ngModelValue.find(g => g.guidelineID === guideline.parentGuidelineID)) {
        this.ngModelValue = uniq([...this.ngModelValue, guideline.parentGuideline.guidelineID]);
        this.ngModelValueChange.emit(this.ngModelValue);
      }
      if (this.valueMode === 'formControl' && !this.control.value.find(g => g.guidelineID === guideline.parentGuidelineID)) {
        this.control.patchValue(uniq([...this.control.value, guideline.parentGuideline.guidelineID]));
      }
    }
  }

  removeAnItem(guideline) {
    // deselect child outcomes when standard deselected
    if (guideline.guidelineType === 'standard') {
      if (this.valueMode === 'ngModel' && intersection(this.ngModelValue, this.items.filter(g => g.parentGuidelineID === guideline.guidelineID).map(g => g.guidelineID)).length > 0) {
        const outcomes = (this.items.filter(g => g.parentGuidelineID === guideline.guidelineID) || []);
        const tempFormValue = [...this.ngModelValue];
        this.ngModelValue = [...difference(tempFormValue, outcomes.map(g => g.guidelineID))];
        this.ngModelValueChange.emit(this.ngModelValue);
      }
      if (this.valueMode === 'formControl' && intersection(this.control.value, this.items.filter(g => g.parentGuidelineID === guideline.guidelineID).map(g => g.guidelineID)).length > 0) {
        const outcomes = (this.items.filter(g => g.parentGuidelineID === guideline.guidelineID) || []);
        const tempFormValue = [...this.control.value];
        this.control.patchValue([...difference(tempFormValue, outcomes.map(g => g.guidelineID))]);
      }
    }
  }

}
