import { Component, Input } from '@angular/core';
import { REPORT_CATEGORY } from '../overview-reporting.component';
import { CATEGORY_ITEMS_DICT, REPORT_CAT_DISPLAY } from '../reporting-subpage/reporting-subpage.component';
import { pluck } from 'underscore';

import { NgIf } from '@angular/common';
import { ObjectCardComponent } from '../../../../shared/components/object-card/object-card.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { PopoverInfoComponent } from '@components/components-with-popover/popover-info/popover-info.component';
import { hexToRgba } from 'src/app/shared/utils/color/hexToRgba';
import ColourMap from '@ui/colour-token-map';

@Component({
  selector: 'app-report-category-card',
  templateUrl: './report-category-card.component.html',
  styleUrls: ['./report-category-card.component.scss'],
  standalone: true,
  imports: [ObjectCardComponent, NgIf, UIStreamlineIconComponent, PopoverInfoComponent]
})
export class ReportCategoryCardComponent {
  @Input() item;
  @Input() category: REPORT_CATEGORY;
  REPORT_CAT_DISPLAY = REPORT_CAT_DISPLAY;

  get reportNames() {
    const reportNames = pluck(CATEGORY_ITEMS_DICT[this.category], 'title');
    return reportNames[0] ?
      pluck(CATEGORY_ITEMS_DICT[this.category], 'title').join(', ')
      :  pluck(CATEGORY_ITEMS_DICT[this.category]?.map(c => c?.items).flat(), 'title');
  }

  get slIcon() {
    if (this.item.slIcon) {
      return {...this.item.slIcon, size: '2x'};
    }
    return null;
  }

  getColorBg(){
    let colorBgText = '';
    const opacity = 0.8;
    if(this.item.bannerColor){
      colorBgText = `background-image: linear-gradient(to right, ${hexToRgba(this.item.bannerColor)}, ${hexToRgba(this.item.bannerColor, opacity)});`
    }else {
      colorBgText = `background-image: linear-gradient(to right, ${hexToRgba(ColourMap.get('pink-300'))}, ${hexToRgba(ColourMap.get('pink-300'), opacity)});`
    }
    return colorBgText;
  }




}
