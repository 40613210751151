import { Component, Input } from '@angular/core';
import { ReqStatusDisplay, ReqStatusType } from 'src/app/services/requirement.service';

@Component({
  selector: 'app-req-state-cell',
  standalone: true,
  imports: [],
  templateUrl: './req-state-cell.component.html',
  styleUrl: './req-state-cell.component.scss'
})
export class ReqStateCellComponent {
  @Input() value: ReqStatusType;

  iconPath: string;

  ngOnInit(): void {
    this.iconPath = REQ_STATE_ICON_DICT[this.value];
  }

}

const REQ_STATE_ICON_DICT: Record<ReqStatusDisplay, string> = {
  'Met': '/assets/images/audit-report/met-badge.svg',
  'New': '/assets/images/audit-report/new-badge.svg',
  'Unmet': '/assets/images/audit-report/unmet-badge.svg',
  'Waived': '/assets/images/audit-report/waived-badge.svg',
  'Extended': '/assets/images/audit-report/extended-badge.svg',
  'Leave': '/assets/images/audit-report/leave-badge.svg',
  'Not Required': ''
}
