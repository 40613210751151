<!-- For policies library table -->
<div>
    <div *ngIf="triggerPage==='policiesTableAction'">
        <!-- Continue for draft policy -->
        <div *ngIf="itemStatus === GOVERNANCE_STATUS.draft" class="d-flex children-gap-2" [ngClass]="btnWrapperClasses">
            <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to edit a policy'"
                placement="left">
                <button [disabled]="!canEdit" type="button"
                    class="btn m-fw-500 btn-outline-light btn-sm d-flex align-items-center" (click)="editItem()">
                    Continue Editing
                </button>
            </span>

            <app-ngb-dropdown [placement]="placement" dropdownClass="auto" [buttonSize]="buttonSize"
                [disableScroll]="disableScroll">
                <button ngbDropdownItem class="btn no-radius" (click)="viewItem()">
                    View
                </button>
                <button ngbDropdownItem class="btn no-radius" (click)="versionHistory()">
                    Version History
                </button>
                <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to delete policies'"
                    placement="left">
                    <button ngbDropdownItem [disabled]="!isOrgManager" class="btn no-radius" (click)="deleteItem()">
                        <span class="text-danger m-fw-500">Delete</span>
                    </button>
                </span>

            </app-ngb-dropdown>
        </div>

        <!-- Unarchive for archived policy -->
        <div *ngIf="itemStatus === GOVERNANCE_STATUS.archived" class="d-flex children-gap-2"
            [ngClass]="btnWrapperClasses">
            <button *ngIf="isOrgManager" type="button"
                class="btn m-fw-500 btn-outline-light btn-sm d-flex align-items-center" (click)="unarchiveItem()">
                Unarchive
            </button>
        </div>

        <!-- Dropdown for published policy -->
        <div *ngIf="itemStatus === GOVERNANCE_STATUS.published" class="d-flex children-gap-2"
            [ngClass]="btnWrapperClasses">
            <button type="button" class="btn m-fw-500 btn-outline-light btn-sm d-flex align-items-center"
                (click)="viewItem()">
                View
            </button>
            <app-ngb-dropdown [placement]="placement" dropdownClass="auto" [buttonSize]="buttonSize"
                [disableScroll]="disableScroll">
                <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to edit policies'"
                    placement="left">
                    <button ngbDropdownItem [disabled]="!canEdit" class="btn no-radius" (click)="editItem()">
                        Edit
                    </button>
                </span>
                <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to edit policies'"
                    placement="left">
                    <button ngbDropdownItem [disabled]="!isOrgManager" class="btn no-radius" (click)="reVokePolicy()">
                        Assign
                    </button>
                </span>
                <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to archive policies'"
                    placement="left">
                    <button ngbDropdownItem [disabled]="!isOrgManager" class="btn no-radius" (click)="archiveItem()">
                        Archive
                    </button>
                </span>

                <button ngbDropdownItem class="btn no-radius d-flex flex-row align-items-center"
                    (click)="previewItem()">
                    Preview
                    <ui-streamline-icon [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'sm'}"
                        class="ms-2 m-transformY-1">
                    </ui-streamline-icon>
                </button>
                <button ngbDropdownItem class="btn no-radius" (click)='copyPreviewLink()'>
                    Copy link to share
                </button>

                <button ngbDropdownItem class="btn no-radius" (click)="versionHistory()">
                    Version History
                </button>
            </app-ngb-dropdown>
        </div>
    </div>

    <!-- for acknowledgement records table (user detail page -> P&P tab) -->
    <div *ngIf="triggerPage==='acknowledgementRecordsTableAction' && rowData.fn_status==='Unacknowledged'">
            <span
                [ngbTooltip]="(rowData.orgUser?.status || rowData.status) === 'Active' ? '' :  'Reminder can\'t be sent until the user activates their account'"
                placement="left">

                <button [disabled]="(rowData.orgUser?.status || rowData.status) !== 'Active'" class="btn btn-outline-light btn-sm"
                    (click)="sendReminder()">
                    Send Reminder
                </button>
            </span>

            <!-- Hide this for v1 -->
            <!-- <button ngbDropdownItem class="btn no-radius" (click)="markAsAcknoledged()">
                Mark as acknowledged
            </button> -->

    </div>

    <!-- For pp dashboard table -->
    <!-- Continue for draft policy -->
    <div *ngIf="triggerPage==='DraftPnPTableAction'" class="d-flex justify-content-start">
        <div class="d-flex justify-content-start" [ngClass]="btnWrapperClasses">
            <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to edit a policy'"
                placement="left">
                <button [disabled]="!canEdit" type="button" class="btn btn-outline-light btn-sm m-fw-500"
                    (click)="editItem()">
                    Continue Editing
                </button>
            </span>

        </div>
    </div>
    <!-- Upcoming Policy Reviews -->
    <div *ngIf="triggerPage==='upcomingPolicyReviewsTableAction'" class="d-flex justify-content-start">
        <div class="d-flex justify-content-start me-2" [ngClass]="btnWrapperClasses">
            <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to edit a policy'"
                placement="left">
                <button [disabled]="!canEdit" type="button" class="btn btn-secondary text-white btn-sm m-fw-500"
                    (click)="editItem()">
                    Review
                </button>
            </span>
        </div>
        <app-ngb-dropdown [placement]="placement" dropdownClass="auto" [buttonSize]="buttonSize"
        [disableScroll]="disableScroll">
        <span [ngbTooltip]="isOrgManager? '' : 'You must be an organisation manager to edit policies'"
            placement="left">
            <button ngbDropdownItem [disabled]="!canEdit" class="btn no-radius" (click)="editItem(true)">
                <span class="m-fw-500">Open in a new tab</span>
                <ui-streamline-icon [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'sm'}" class="ms-2 pb-1"></ui-streamline-icon>
            </button>
        </span>

    </app-ngb-dropdown>

    </div>

</div>
