import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap } from 'rxjs/operators';
import { ShortcutService } from 'src/app/services/shortcut.service';
import { NgIf } from '@angular/common';
import { InputErrorDirective } from '../../../../../shared/directives/input-error.directive';
import { FormsModule } from '@angular/forms';
import { ModalSingleComponent } from '../../../../../shared/components/modal-single/modal-single.component';
import { AvatarComponent } from '@components/avatar/avatar.component';

@Component({
    selector: 'app-shortcut-edit-modal',
    templateUrl: './shortcut-edit-modal.component.html',
    styleUrls: ['./shortcut-edit-modal.component.scss'],
    standalone: true,
    imports: [ModalSingleComponent, FormsModule, InputErrorDirective, NgIf, AvatarComponent]
})
export class ShortcutEditModalComponent implements OnInit {
  @Input() shortcut;
  title: string;
  submitted = false;

  constructor(
    private activeModal: NgbActiveModal,
    private shorcutService: ShortcutService,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
    this.title = this.shortcut.shortcutTitle || this.shortcut.title;
  }

  close(result = null) {
    this.activeModal.close(result);
  }

  confirm() {
    this.submitted = true;
    if (this.shortcut.shortcutID) {
      this.edit();
      return;
    }
    this.saveNew();
  }

  saveNew() {
    this.shorcutService.saveFilter(
      {...this.shortcut,
      title: this.title
    }
    ).subscribe(res => this.close(res), error => this.toaster.error(error));
  }

  edit() {
    this.shorcutService.updateShortcutTitle(this.shortcut.shortcutID, this.title).pipe(
      switchMap(() => this.shorcutService.fetchShortcuts())
    ).subscribe(
      () => {
        this.toaster.success('Title updated');
        this.close();
      }, (error) => {
        this.toaster.error(error);
      }
    );

  }

}
