import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CustomisedMultiSelectComponent } from '../customised-multi-select/customised-multi-select.component';
import { finalize, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { IGuideline, ResourceService } from '../../../services/resource.service';
import { OrgResourceService } from '../../../services/org-resource.service';
import { getByoResourceTypeByResource } from '../../utils/get-resource-type';
import { resourceTypeDict, RESOURCE_TYPE_DISPLAY_ENUM, RESOURCE_TYPE_ENUM } from '../../byo-shared/byo-resource-types';
import { groupBy, indexBy, mapObject, pluck, uniq } from 'underscore';
import { FRAMEWORKS_ENUM } from '../../../pages/settings/interfaces/IOrgLMSSetting';
import { DOCUMENT, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { PRODUCT_TYPES } from '../../../core/guards/mode.constants';
import { OrganisationService } from '../../../services/organisation.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { LearningService } from 'src/app/services/learning.service';
import { ReportLearningRecordService } from 'src/app/services/report-learning-record.service';
import { UserService } from 'src/app/services/user.service';
import { keyBy } from 'lodash';
import { CpdTimePipe } from '../../pipes/cpd-time.pipe';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-multi-choose-resource-dropdown',
  templateUrl: './multi-choose-resource-dropdown.component.html',
  styleUrls: ['./multi-choose-resource-dropdown.component.scss'],
  standalone: true,
  imports: [NgIf, NgSelectModule, FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, NgbTooltip, CpdTimePipe]
})
export class MultiChooseResourceDropdownComponent extends CustomisedMultiSelectComponent implements OnInit {
  @ViewChild('selector') selector: NgSelectComponent;
  @Output() allResourcesDict = new EventEmitter();
  @Input() width: number;
  @Input() includeItemType = false;

  get labelWidth() {
    return this.width ? this.width - 68 : '';
  }
  isAusmedLMSFreeUser: boolean;
  ngSelectGroupBy = 'providerType';
  options;
  filteredItems: any[];
  isLoading = true;
  allResources;
  allResources$ = new ReplaySubject();
  guidelineDict;

  ausmedResourceTypes = [
    {
      value: RESOURCE_TYPE_ENUM.ausmedCourse,
      name: 'Ausmed ' + resourceTypeDict[RESOURCE_TYPE_ENUM.ausmedCourse],
    },
    {
      value: RESOURCE_TYPE_ENUM.blog,
      name: 'Ausmed ' + resourceTypeDict[RESOURCE_TYPE_ENUM.blog],
    },
    {
      value: RESOURCE_TYPE_ENUM.lecture,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.lecture],
    },
    {
      value: RESOURCE_TYPE_ENUM.guideToPractice,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.guideToPractice],
    },
    {
      value: RESOURCE_TYPE_ENUM.explainer,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.explainer],
    },
  ];
  thirdPartyResourceTypes = [
    { value: RESOURCE_TYPE_ENUM.thirdVideo, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdVideo },
    { value: RESOURCE_TYPE_ENUM.ausmedCourse, name: RESOURCE_TYPE_DISPLAY_ENUM.course },
    { value: RESOURCE_TYPE_ENUM.thirdPodcast, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdPodcast },
    { value: RESOURCE_TYPE_ENUM.thirdWebinar, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdWebinar },
    { value: RESOURCE_TYPE_ENUM.thirdInService, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdInService },
    { value: RESOURCE_TYPE_ENUM.thirdProcedure, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdProcedure },
    { value: RESOURCE_TYPE_ENUM.thirdMentoring, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdMentoring },
    { value: RESOURCE_TYPE_ENUM.thirdCompetency, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdCompetency },
    { value: RESOURCE_TYPE_ENUM.thirdPostgraduate, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdPostgraduate },
    { value: RESOURCE_TYPE_ENUM.thirdMeeting, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdMeeting },
    { value: RESOURCE_TYPE_ENUM.thirdTeaching, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdTeaching },
    { value: RESOURCE_TYPE_ENUM.thirdPeer, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdPeer },
    { value: RESOURCE_TYPE_ENUM.thirdOther, name: RESOURCE_TYPE_DISPLAY_ENUM.thirdOther },
  ];

  byoResourceTypes = [
    {
      value: RESOURCE_TYPE_ENUM.onlineCourse,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.onlineCourse],
    },
    {
      value: RESOURCE_TYPE_ENUM.article,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.article],
    },
    {
      value: RESOURCE_TYPE_ENUM.video,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.video],
    },
    {
      value: RESOURCE_TYPE_ENUM.weblink,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.weblink],
    },
    {
      value: RESOURCE_TYPE_ENUM.file,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.file],
    },
    {
      value: RESOURCE_TYPE_ENUM.practicalActivity,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.practicalActivity],
    },
    {
      value: RESOURCE_TYPE_ENUM.scorm,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.scorm],
    },
    {
      value: RESOURCE_TYPE_ENUM.form,
      name: resourceTypeDict[RESOURCE_TYPE_ENUM.form],
    }
  ];
  allItemTypes = this.ausmedResourceTypes.concat(this.byoResourceTypes).concat(this.thirdPartyResourceTypes);
  loadingText = 'Loading...'

  constructor(
    private resourceService: ResourceService,
    private orgResourceService: OrgResourceService,
    @Inject(DOCUMENT) private document: Document,
    private organisationService: OrganisationService,
    private reportService: ReportLearningRecordService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.isAusmedLMSFreeUser = this.organisationService.isContractType(PRODUCT_TYPES.LMS_FREE);
    let allResources;
    const ausmedResources$ = this.isAusmedLMSFreeUser ? of([]) : this.resourceService.fetchAusmedResources().pipe(
      take(1), map((resources: any[]) => resources.map((r) => {
        r.providerType = 'Ausmed';
        r.displayedResourceType = r?.resourceType ? resourceTypeDict[r.resourceType] : (resourceTypeDict[r.activityType] || r.activityType);
        return r;
      })));
    const orgResources$ = this.orgResourceService.fetchCachedOrgActiveResources().pipe(
      take(1), map((orgResources: any[]) => orgResources.map((r) => {
        r.providerType = 'Internal';
        const { title: resourceTypeDisplay } = getByoResourceTypeByResource(r);
        r.displayedResourceType = resourceTypeDisplay;
        return r;
      })));
    const thirdPartyResources$ = this.isAusmedLMSFreeUser ? of([]) : this.resourceService.fetchThirdPartyResources().pipe(
      take(1), map((thirdPartyResources: any[]) => thirdPartyResources.map((r) => {
        r.providerType = '3rd Party';
        r.displayedResourceType = r?.resourceType ? resourceTypeDict[r.resourceType] : (resourceTypeDict[r.activityType] || r.activityType);
        return r;
      })));
    const getAllResources$ = combineLatest([ausmedResources$, thirdPartyResources$, orgResources$]).pipe(
      finalize(() => this.isLoading = false),
      tap(
        ([ausmedResources, thirdPartyResources, orgResources]) => {
          allResources = orgResources.concat(ausmedResources, thirdPartyResources);
          this.allResources = orgResources.concat(ausmedResources, thirdPartyResources);
          this.allResources$.next(this.allResources);
          const allResourcesDict = indexBy(allResources, 'resourceID');
          // this.resourceService.filterPackResourceDic$.next(allResourcesDict);
          this.allResourcesDict.emit(allResourcesDict);
        }
      )
    );

    // only display resources with selected standard
    if (this.controls.framework) {
      if (this.controls.framework.value) {
        const selectedFramework = this.controls.framework.value;
        this.resourceService.fetchCachedGuidelines().pipe(
          tap((guidelines: IGuideline[]) => this.guidelineDict = indexBy(guidelines, 'guidelineID')),
          switchMap(() => getAllResources$.pipe(
            tap(() => {
              // only display resources with selected standard
              this._setOptions(selectedFramework);
              if (this.controls.resources.value?.length) {
                const originalResources = this.controls.resources.value;
                const resourceIdsWithStandard = originalResources
                  .filter(resourceID => this.options.map(o => o.value).includes(resourceID));
                this.controls.resources.setValue(resourceIdsWithStandard);
              }
            }),
          )),
          switchMap(() => this.controls.framework.valueChanges))
          .subscribe(newSelectedFramework => {
            this._setOptions(newSelectedFramework);
            // filter selected resources with selected framework
            if (this.controls.resources.value?.length) {
              const originalResources = this.controls.resources.value;
              const resourceIdsWithStandard = originalResources
                .filter(resourceID => this.options.map(o => o.value).includes(resourceID));
              this.controls.resources.setValue(resourceIdsWithStandard);
            }
          });
      } else {
        combineLatest([
          this.controls.framework.valueChanges,
          this.resourceService.fetchCachedGuidelines(),
        ]).pipe(
          switchMap(([selectedFramework, guidelines]: [FRAMEWORKS_ENUM, IGuideline[]]) => getAllResources$.pipe(
            tap(() => {
              this.guidelineDict = indexBy(guidelines, 'guidelineID');
              this._setOptions(selectedFramework);
              if (this.controls.resources.value?.length) {
                const originalResources = this.controls.resources.value;
                const resourceIdsWithStandard = originalResources
                  .filter(resourceID => this.options.map(o => o.value).includes(resourceID));
                this.controls.resources.setValue(resourceIdsWithStandard);
              }
            })
          )),
        ).subscribe();
      }
    } else {
      getAllResources$.subscribe(() => {
        this.showAllResources();
      });
    }
    if (this.controls['planIDs']) {
      this.filterOptionsOnPlans()
    }
  }

  reset() {
    this.controls.resources.setValue([]);
    this.controls.itemTypes?.setValue([]);
  }

  private _setOptions(selectedFramework) {
    const filteredResources = this.allResources.filter(r => r.guidelines?.length
      && r.guidelines.map(g => this.guidelineDict[g]?.provider).includes(selectedFramework));
    this.options = filteredResources.map(r => ({
      label: r.title,
      value: r.resourceID,
      displayedResourceType: r.displayedResourceType,
      min: r.min, providerType: r.providerType
    }));
  }

  setOptionsToResourceIDs(resourceIDs: string[]) {
    this.options = this.allResources
      .filter(({ resourceID }) => resourceIDs.includes(resourceID))
      .map(r => ({
        label: r.title,
        value: r.resourceID,
        displayedResourceType: r.displayedResourceType,
        min: r.min, providerType: r.providerType
      }));
  }

  showAllResources() {
    this.options = this.allResources.map(r => ({
      label: r.title, value: r.resourceID, displayedResourceType: r.displayedResourceType,
      min: r.min, providerType: r.providerType
    }));
  }

  isTruncated(index): boolean {
    const scrollWidth = this.document.querySelector(`#resource-label-${index}`).scrollWidth;
    const clientWidth = this.document.querySelector(`#resource-label-${index}`).clientWidth;
    return scrollWidth > clientWidth;
  }

  public ngSearchFn = (searchTerm: string, item: any) => {
    searchTerm = searchTerm.toLowerCase();
    if (this.ngSelectGroupBy) {
      let groupBy;
      if (item[this.ngSelectGroupBy].toLowerCase() === 'internal') {
        groupBy = 'your library';
      } else if (item[this.ngSelectGroupBy].toLowerCase() === 'ausmed') {
        groupBy = 'ausmed library';
      } else {
        groupBy = item[this.ngSelectGroupBy];
      }

      return groupBy.toLowerCase().indexOf(searchTerm) > -1
        || item.label.toLowerCase().indexOf(searchTerm) > -1;
    } else {
      return item.label.toLowerCase().indexOf(searchTerm) > -1;
    }
  }

  getFilteredItems(e){
    this.filteredItems = e.items.filter(i => i.value);
  }

  onSelectAllItemType() {
    const selected = this.allItemTypes.map(item => item.value);
    this.controls['itemTypes'].setValue(selected);
    this.options = this.allResources.map(r => ({
      label: r.title, value: r.resourceID, displayedResourceType: r.displayedResourceType,
      min: r.min, providerType: r.providerType
    }));
    this.controls[this.controlName].patchValue(this.options.map(o => o.value));
  }

  onClearAllItemType() {
    this.controls['itemTypes'].patchValue([]);
    this.options = this.allResources.map(r => ({
      label: r.title, value: r.resourceID, displayedResourceType: r.displayedResourceType,
      min: r.min, providerType: r.providerType
    }));
    this.controls[this.controlName].patchValue([]);
  }

  onItemTypeChanged($event) {
    if ($event.length < 1) {
      this.options = this.allResources.map(r => ({
        label: r.title, value: r.resourceID, displayedResourceType: r.displayedResourceType,
        min: r.min, providerType: r.providerType
      }));
      this.controls.resources.setValue([]);
    } else {
      this.options = this.allResources.filter(r => $event.map(e => e.value).includes(r?.resourceType ? r?.resourceType : (r?.activityType || ''))).map(r => ({
        label: r.title, value: r.resourceID, displayedResourceType: r.displayedResourceType,
        min: r.min, providerType: r.providerType
      }));
      this.controls[this.controlName].patchValue(this.options.map(o => o.value));
    }

  }

  // subscribes to planIDs value and filters options accordingly
  filterOptionsOnPlans() {
    const resourcesGroupedByPlan$ = this.reportService.fetchAssignsByUserIDsGroupByPlanResource(
      { filteredUsers: Object.keys(this.userService.managedOrgUserDictionaryByUserID).filter(u => u !== 'undefined') }).pipe(
        map(res => {
          // eg {planA: [resourceID1, resourceID2,...]}
          return mapObject(groupBy(res, (el) => el.planID || 'manual'), g => pluck(g, 'resourceID'));
        }),
        shareReplay()
      );
    this.controls['planIDs']?.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.isLoading = true;
        this.loadingText = 'Refining items based on source'
        this.options = null;
      }),
      switchMap((filteredPlans: string[]) => {
        return combineLatest([resourcesGroupedByPlan$, this.allResources$, of(filteredPlans)]);
      }),
      // tap(r => console.log(r))
    ).subscribe(([resourceGroupedByPlans, _, filteredPlans]) => {
      if (!filteredPlans?.length) {
        this.showAllResources();
      } else {
        const filteredResources = uniq(filteredPlans.map(p => resourceGroupedByPlans[p]).flat());
        // console.log(filteredResources);
        this.setOptionsToResourceIDs(filteredResources);
      }
      this.cdr.markForCheck();
      this.isLoading = false;
      this.loadingText = 'Loading...';
    });
  }
}
