<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- Feedback -->
<!-- Type feedback is not used ATM.  -->
<div style="border: 1px solid #1979c8" *ngIf="type === 'Feedback'" style="max-width: 1400px"
    class="border border-primary p-3 children-gap-2 bg-pink-100 rounded d-flex flex-wrap justify-content-between"
    role="alert">
    <div class="d-flex align-items-center">
        <i style="margin-top: 3px" class="far fa-info-circle text-pink-800 me-2 fa-lg"></i>
        <p class="f-small fw-medium text-neutral-900 me-2" [innerHtml]="copy"></p>
    </div>
    <div class="mt-3 mt-xl-0 d-flex justify-content-end">
        <a href="https://surveys.hotjar.com/8385279e-02fb-4e73-a1a5-ff205a7b6e28" target="_blank"
            class="d-inline-block btn btn-sm btn-secondary">Give Feedback</a>
    </div>
</div>

<!-- Launch -->
<div *ngIf="type === 'Launch'" style="max-width: 1400px"
    class="border border-primary p-3 children-gap-2 bg-pink-100 rounded d-flex align-items-center justify-content-between"
    role="alert">
    <p class="text-neutral-900 me-2" [innerHtml]="copy"></p>
    <div class="text-nowrap">
        <ng-content select="[buttonsSlot]"></ng-content>
    </div>
</div>

<!-- Inactive -->
<div *ngIf="type === 'Inactive'" style="max-width: 1400px"
    class="p-3 children-gap-2 border border-neutral-500 bg-neutral-200 rounded d-flex align-items-center justify-content-between"
    role="alert">
    <p class="f-small text-neutral-900 me-2" [innerHtml]="copy"></p>
</div>

<!-- Warning -->
<div *ngIf="type === 'Warning'" [style.max-width]="maxWidth"
    class="p-3 children-gap-2 bg-yellow-100 rounded d-flex align-items-center justify-content-between mb-2"
    [ngClass]="{'border border-warning': withBorder}" role="alert">
    <div class="d-flex align-items-center">
        <div class="d-flex flex-row align-items-center">
            <ui-streamline-icon *ngIf="!hideIcon"
                [slIcon]=" iconSettings || {name: 'interface-alert-warning-circle', theme: 'micro-bold', color: 'fill-warning'}"
                class="pb-1 me-2 sl-icon-mt-3px"></ui-streamline-icon>
            <p class="f-small text-neutral-900 warning-text" [innerHtml]="copy"></p>
            <ng-content></ng-content>
        </div>
    </div>
    <ng-content select="[buttonSlot]"></ng-content>
</div>

<!-- Customised -->
<div *ngIf="type === 'Customised' && remainInView" style="max-width: 1400px"
    class="p-3  border border-{{color}}-400 bg-{{color}}-100 rounded "
    role="alert">
    <div class="children-gap-2 d-flex align-items-base justify-content-between">
        <div class="d-flex align-items-center">
            <!-- <i style="margin-top: 3px" class="far {{icon}} text-{{color}}-800 me-2 fa-lg"></i> -->
            <ui-streamline-icon *ngIf="!hideIcon" class="me-2" [slIcon]="slIcon || convertFaToSl(icon, COLOUR_DICT[color])">
            </ui-streamline-icon>
            <p class="f-small fw-medium text-neutral-900 me-2" [innerHtml]="copy"></p>
        </div>
        <div *ngIf="includeDismiss" (click)="onDismiss()">
            <ui-streamline-icon  [slIcon]="{name: 'interface-delete-1', theme: 'micro-bold', size: 'xs'}"></ui-streamline-icon>
        </div>
        <ng-content select="[buttonSlotCustom]"></ng-content>

    </div>
    <ng-content></ng-content>
</div>

<!-- Error -->
<div *ngIf="type === 'Error'" style="max-width: 1400px"
    class="p-3 children-gap-2 border border-red-200 bg-red-100 rounded d-flex align-items-center justify-content-between"
    role="alert">
    <p class="f-small fw-medium text-danger">{{copy}}</p>
</div>


<!-- New Alert Banners -->
<div *ngIf="type === 'New' && remainInView" [@isAlertDisplay]="showNew | showAlertStatus"
    class="d-flex p-3 justify-content-between rounded align-items-center" [style.max-width]="maxWidth" role="alert"
    [ngClass]="wrapperClasses">

    <div class="left-hand-side d-inline-flex align-items-center">
        <ui-streamline-icon *ngIf="!hideIcon"
            [slIcon]="{name: 'interface-alert-information-circle', theme: 'micro-bold', color: iconColor}"
            class="pb-1 me-2 fa-md sl-icon-mt-2px"></ui-streamline-icon>
        <span class="f-small fw-medium text-neutral-900" [ngClass]="textClasses">{{copy}}</span>
    </div>

    <!-- select slot will not work when it is next to an ngif? -->
    <div class="right-hand-side d-inline-flex align-items-center">
        <button *ngIf="includeCTAButton" [ngClass]="ctaBtnClasses" (click)="onCTA()">{{ctaBtnText}}</button>
        <div *ngIf="includeDismiss" [ngClass]="textClasses" class="cursor-pointer ms-2" (click)="onDismiss()">
            <ui-streamline-icon [slIcon]="{name: 'interface-delete-1', theme: 'micro-bold', size: 'xs'}">
            </ui-streamline-icon>
        </div>
    </div>
</div>
