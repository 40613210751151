import { Component, effect, inject, input, OnInit, output, ViewChild } from '@angular/core';
import { ITableDownloadSettings, ITableSettings, SmartTableComponent } from '@components/smart-table/smart-table.component';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { SmartTableColumnsService, columnName } from 'src/app/services/smart-table-columns.service';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { TableActionButtonComponent } from '@components/table-cell/action-buttons/table-action-button/table-action-button.component';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { IPerformanceReviewAssign, ITablePerformanceReviewAssign, PerformanceReviewService, TASK_ASSIGN_STATUS_ENUM } from 'src/app/services/performance-review.service';
import { TaskRemindModalComponent } from './task-remind-modal/task-remind-modal.component';
import { getTaskActionButtons } from './task-table-utils';
import { MultiSelectContainerComponent } from '@components/multi-select-container/multi-select-container.component';
import { IMultiSelectData, IShownItems, SmartTableService } from 'src/app/services/smart-table.service';
import { clone, difference, pluck, uniq, without } from 'underscore';
import { PerformanceBulkActionsComponent } from 'src/app/pages/performance-review/bulk-actions/performance-bulk-actions.component';
import { ToastrService } from 'ngx-toastr';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { UserService } from 'src/app/services/user.service';
import { TaskSkipModalComponent } from './task-skip-modal/task-skip-modal.component';
import { TaskUnskipModalComponent } from './task-unskip-modal/task-unskip-modal.component';

@Component({
  selector: 'app-task-table',
  standalone: true,
  templateUrl: './task-table.component.html',
  styleUrl: './task-table.component.scss',
  imports: [
    SmartTableComponent,
    TaskRemindModalComponent,
    MultiSelectContainerComponent,
    UIStreamlineIconComponent,
    NgbDropdownModule
  ],
})
export class TaskTableComponent implements OnInit {
  @ViewChild('activeUserTable') activeUserTable: SmartTableComponent;
  assigns = input.required<ITablePerformanceReviewAssign[]>();
  onDashboard = input<boolean>(false);
  tableKey = input<LocalTableUniqKey>();
  LocalTableUniqKey = LocalTableUniqKey;
  currentSettings = output<ITableSettings>();
  showCompleteDate = input();
  forCycle = input(false);
  forProgress = input(false);
  hiddenCols = input<columnName[]>();
  multiSelect = input.required<Boolean>();

  defaultColumns = getColumnProgressAssign();
  cyclePageColumns = getColumnProgressAssign(true);
  progressPageColumns = getColumnProgressAssign(true, ['accountStatus', 'staffID', 'internalID', 'email', 'orgEmail', 'cycle', 'teams', 'facilityModels', 'jobRoles'])

  source = new LocalDataSource();
  settings: ITableSettings;
  columnService = inject(SmartTableColumnsService);
  modalService = inject(NgbModal);
  prService = inject(PerformanceReviewService)

  downloadSettings = DOWNLOAD_SETTING_TASK_ASSIGNS;

  selectedRecords: ITablePerformanceReviewAssign[] = [];

  shownItems: IShownItems = {
    showingMinus: false,
    showClearSelection: false
  };

  smartTableService = inject(SmartTableService);

  multiSelectData: IMultiSelectData;

  toaster = inject(ToastrService);

  oneStatusOnly = input.required<boolean>();

  updatedAssigns = output<ITablePerformanceReviewAssign[]>();

  userService = inject(UserService);

  constructor() {
    effect(() => {
      this.settings = {
        tableUniqKey: LocalTableUniqKey[this.tableKey()],
        actions: false,
        selectMode: this.multiSelect() ? 'multi' : 'single',
        columns: {
          ...(this.forCycle() ? this.cyclePageColumns : this.forProgress() ? this.progressPageColumns : this.defaultColumns),
          actions: {
            title: 'Actions',
            exemptFromEdit: true,
            sort: false,
            filter: false,
            type: 'custom',
            renderComponent: TableActionButtonComponent,
            onComponentInitFunction: (instance: TableActionButtonComponent) => {
              instance.showDropdown = true;
              instance.text = 'Open';
              instance.buttons = getTaskActionButtons();
              instance.actionClicked.subscribe(val => this.onAction(val.action, val.rowData))
            }
          }

        }
      };
      this.currentSettings.emit(this.settings);
      if (this.onDashboard() === true) {
        this.source.load(this.assigns().slice(0, 5));
      } else {
        this.source.load(this.prService.sortTasks(this.assigns()));
      }
    })
  }

  ngOnInit(): void {
  }

  onAction(action, task: ITablePerformanceReviewAssign) {
    if (action === 'remind') {
      this.prService.remindModal(task, 'my tasks');
      return;
    }
    if (action === 'skipped') {
      this.openSkipTask(task);
      return;
    }
    if (action === 'unskip') {
      this.openUnskipTask(task);
      return;
    }
    this.openReviewTask(task);
  }

  openReviewTask(task: ITablePerformanceReviewAssign) {
    const modal = this.modalService.open(TaskModalComponent, { size: '95', backdrop: 'static' });
    modal.componentInstance.taskID = task.taskID;
    modal.result.then((task: IPerformanceReviewAssign) => {
      if (!this.oneStatusOnly()) {
        if (task?.status === TASK_ASSIGN_STATUS_ENUM.completed) {
          this.source.getAll().then((allData) => {
            const updatedAssigns = allData.map(a => a.taskID === task.taskID ? { ...a, status: TASK_ASSIGN_STATUS_ENUM.completed } : a);
            this.source.load(this.prService.sortTasks(updatedAssigns));
          });
        }
      } else {
        this.source.getAll().then((allData) => {
          const taskToUpdate = allData.find(a => a.taskID === task.taskID);
          if (taskToUpdate?.status !== task?.status) {
            this.source.remove(taskToUpdate).then(
              () => {
                this.updatedAssigns.emit(without(allData, taskToUpdate));
              }
            );;
          }

        });
      }
    })
  }

  openSkipTask(task: ITablePerformanceReviewAssign) {
    const modal = this.modalService.open(TaskSkipModalComponent, { size: 'xl', backdrop: 'static' });
    modal.componentInstance.task = task;
    modal.result.then((task: IPerformanceReviewAssign) => {
      if (task) {
        if (!this.oneStatusOnly()) {
          this.source.getAll().then((allData) => {
            const updatedAssigns = allData.map(a => a.taskID === task.taskID ? { ...a, status: task.status, action: task.action } : a);
            this.source.load(this.prService.sortTasks(updatedAssigns));
          });
        } else {
          this.source.getAll().then((allData) => {
            const taskToUpdate = allData.find(a => a.taskID === task.taskID);
            if (taskToUpdate?.status !== task?.status) {
              this.source.remove(taskToUpdate).then(
                () => {
                  this.updatedAssigns.emit(without(allData, taskToUpdate));
                }
              );;
            }

          });
        }
      }

    });
  }

  openUnskipTask(task: ITablePerformanceReviewAssign) {
    const modal = this.modalService.open(TaskUnskipModalComponent, { size: 'xl', backdrop: 'static' });
    modal.componentInstance.task = task;
    modal.result.then((task: IPerformanceReviewAssign) => {
      if (!this.oneStatusOnly()) {
        this.source.getAll().then((allData) => {
          const updatedAssigns = allData.map(a => a.taskID === task.taskID ? { ...a, status: task.status } : a);
          this.source.load(this.prService.sortTasks(updatedAssigns));
        });
      } else {
        this.source.getAll().then((allData) => {
          const taskToUpdate = allData.find(a => a.taskID === task.taskID);
          if (taskToUpdate?.status !== task?.status) {
            this.source.remove(taskToUpdate).then(
              () => {
                this.updatedAssigns.emit(without(allData, taskToUpdate));
              }
            );;
          }
        });
      }
    })
  }

  onSelectAll() {
    this.multiSelectData = {
      availableItems: this.source.count(),
      shownItems: this.shownItems,
      table: this.activeUserTable.table,
      keyName: 'taskID',
      tableRef: '#' + this.tableKey()
    };
    this.source.getFilteredAndSorted().then(records => {
      this.selectedRecords = clone(records);
      this.smartTableService.onSelectAll(this.multiSelectData);

      this.smartTableService.syncTable(this.selectedRecords, this.multiSelectData);
    });
  }

  onDeselectAll() {
    this.multiSelectData = {
      availableItems: this.source.count(),
      shownItems: this.shownItems,
      table: this.activeUserTable.table,
      keyName: 'taskID',
      tableRef: '#' + this.tableKey()
    };
    this.selectedRecords = [];
    this.smartTableService.onDeselectAll(this.multiSelectData);
    this.smartTableService.syncTable(this.selectedRecords, this.multiSelectData);
  }

  onSelectRow(event) {
    this.selectedRecords = this.smartTableService.onUserRowSelect(event, this.selectedRecords, {
      availableItems: this.source.count(),
      shownItems: this.shownItems,
      table: this.activeUserTable.table,
      keyName: 'taskID',
      tableRef: '#' + this.tableKey()
    });

  }

  openBulkActionModal(action) {
    console.log(this.selectedRecords);
    const modal = this.modalService.open(PerformanceBulkActionsComponent, { size: 'xl', backdrop: 'static', keyboard: false });
    if (action === 'Remind') {
      modal.componentInstance.selectedRecords = this.selectedRecords.filter(r => r.taskTemplate.taskType === 'Assessment' && (r.status === TASK_ASSIGN_STATUS_ENUM.overdue || r.status === TASK_ASSIGN_STATUS_ENUM.open));
      modal.componentInstance.selectedUsers = uniq(pluck(this.selectedRecords.filter(r => r.taskTemplate.taskType === 'Assessment'), 'user'), user => user.orgUserID);
      modal.componentInstance.showRemindWarning = true;
    }
    if (action === 'Skipped') {
      modal.componentInstance.selectedRecords = this.selectedRecords.filter(r => this.userService.isManager || this.userService.managedOrgUserDictionaryByUserID[r?.managerUserID]);
      modal.componentInstance.selectedUsers = uniq(pluck(this.selectedRecords.filter(r => this.userService.isManager || this.userService.managedOrgUserDictionaryByUserID[r?.managerUserID]), 'user'), user => user.orgUserID);
      if (!this.userService.isManager) {
        modal.componentInstance.showSkippedWarning = true;
      }
    }
    if (action === 'Unskip') {
      modal.componentInstance.selectedRecords = this.selectedRecords.filter(r => this.userService.isManager || this.userService.managedOrgUserDictionaryByUserID[r?.managerUserID]);
      modal.componentInstance.selectedUsers = uniq(pluck(this.selectedRecords.filter(r => this.userService.isManager || this.userService.managedOrgUserDictionaryByUserID[r?.managerUserID]), 'user'), user => user.orgUserID);
      if (!this.userService.isManager) {
        modal.componentInstance.showUnskipWarning = true;
      }
    }
    modal.componentInstance.action = action;
    modal.result.then((result) => {
      if (result) {
        this.toaster.success(result);
        if (action === 'Skipped' || action === 'Unskip') {
          this.source.getAll().then((allData) => {
            const newSource = difference(allData, this.selectedRecords);
            this.source.load(newSource);
            this.onDeselectAll();
            this.updatedAssigns.emit(newSource);
          });
        }
      }
    });
  }

}

export const DOWNLOAD_SETTING_TASK_ASSIGNS: ITableDownloadSettings = { title: 'Perform Requirements', };

export function getColumnProgressAssign(forCycle = false, hiddenCols?: columnName[]) {
  const columnService = inject(SmartTableColumnsService);
  if (forCycle) {
    return {
      ...columnService.getColumns(['taskTemplate',], { local: true }),
      taskTypeDisplay: {
        title: 'Type',
        defaultToShow: true
      },
      ...columnService.getColumns(['status', 'action', 'user', 'assignDate', 'dueDate', 'completeDate', 'taskManager', 'teams', 'jobRoles', 'facilityModels', 'accountStatus', 'staffID', 'internalID', 'email', 'orgEmail', 'cycle',], { local: true, hidden: hiddenCols || ['staffID', 'email', 'orgEmail', 'internalID'] }),

    }
  }

  return {
    ...columnService.getColumns(['taskTemplate',], { local: true }),
    taskTypeDisplay: {
      title: 'Type',
      // valuePrepareFn: (v) => TEMPLATE_TYPE_DISPLAY.get(v)
    },
    ...columnService.getColumns(['status', 'user', 'staffID', 'internalID', 'email', 'orgEmail', 'taskManager', 'cycle',], { local: true, hidden: ['staffID', 'email', 'taskManager', 'orgEmail', 'internalID'] }),
    ...columnService.getColumns(['assignDate', 'dueDate', 'completeDate'], { local: true }),
  };
}
