export function generalCompare() {
    return (direction: any, a: any, b: any) => {
      if (!a) {
        a = '';
      }
      if (!b) {
        b = '';
      }
      a = a.toString()?.toLowerCase() || '';
      b = b.toString()?.toLowerCase() || '';
      if (a < b) {
        return -1 * direction;
      }
      if (a > b) {
        return direction;
      }
      return 0;
    };
  }
