import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FeatureFlagService } from './feature-flag.service';
import { FEATURES } from '../core/features.config';
import { MENU_TYPES, PRODUCT_TYPES } from '../core/guards/mode.constants';
import { OrgSubscriptionService } from './org-subscription.service';
import { BroadcastService } from './broadcast.service';
import { IUIStreamlineIcon } from '../ui/streamline-icon/streamline-icon-interfaces';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { OrganisationService } from './organisation.service';
import { REPORT_CAT_DISPLAY } from '../pages/overview/overview-reporting/reporting-subpage/reporting-subpage.component';
import { overviewItemsToSidenav, ppReportOverviewItems } from '../pages/policies-procedures/reporting/pp-reporting.component';
import { getSavedFilterMenu } from './service-utils/menu-service-utils';
import { UserService } from './user.service';
import { HierarchyService } from '../pages/admin/hierarchy/hierarchy.service';

export interface IMenuItem {
  displayName: string;
  shortName?: string;
  show: boolean;
  slIcon?: IUIStreamlineIcon;
  route?: string;
  children?: IMenuItem[];
  queryParams?: Params;
  badge?: IUIStreamlineIcon;
  bot?: boolean;
  isBottomItem?: boolean;
  beta?: boolean;
  isNew?: boolean;
  isOverViewPage?: boolean;
  keyword?: string; // for active the menu item based on url
  thirdLevel?: boolean;
  secondLevelParent?: boolean;
  bottomBorder?: boolean
}

export interface IRedDotMenuStatesPack {
  menuItem: IMenuItem;
  expanded: boolean;
  currentNum: number;
}

export interface INavMenuDotNumPayload {
  targetMenuItemPredicate: (menuItem: IMenuItem) => boolean;
  newMenuNumber: number;
  displayRedDotNumHandler: (menuDataPack: IRedDotMenuStatesPack) => number;
}

export const MENU_RED_DOTS_NUM_ONCE = 'menu-item-red-dot-num';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private _open = true; // menu is open
  triggerToggle$ = new Subject<boolean>();

  private _openItemSubject = new Subject<string>();
  openItemAction$ = this._openItemSubject.asObservable();
  MENU_ITEMS: IMenuItem[];

  ppMenuItems: IMenuItem[];
  learningMenuItems: IMenuItem[];
  adminMenuItems: IMenuItem[];
  libraryMenuItems: IMenuItem[];
  analyticsMenuItems: IMenuItem[];
  performanceMenuItems: IMenuItem[];
  competencyMenuItems: IMenuItem[];

  settingItems: IMenuItem[];

  menuType;
  hierarchyService = inject(HierarchyService);
  // MENU_ITEMS_DICTIONARY = {

  // }



  setMenuItems(contractType: PRODUCT_TYPES, isOrgManager = false, update: boolean = false, orgResourceKeywordGroups = []) {
    let menuItems: IMenuItem[];
    const NZACSubMenuItems = [];
    if (orgResourceKeywordGroups?.length > 0) {
      orgResourceKeywordGroups.forEach(g => {
        NZACSubMenuItems.push(
          {
            displayName: g.name,
            route: 'nzac-lib/' + g.alias,
            show: true,
          }
        );
      });
    }
    switch (contractType) {
      case PRODUCT_TYPES.LMS:
      case PRODUCT_TYPES.LMS_FREE:
        menuItems = [
          {
            displayName: 'Dashboard',
            slIcon: { name: 'house-2-alternate', theme: 'bold' },
            route: 'dashboard',
            show: true,
          },
          {
            displayName: 'Plan',
            slIcon: { name: 'maps', theme: 'bold' },
            route: 'plan/overview',
            show: true,
            children: [
              {
                displayName: 'Plan Overview',
                slIcon: { name: 'maps', theme: 'bold' },
                route: 'plan/overview',
                show: true,
                isOverViewPage: true,
              },
              // TODO: @Eddy need to check the featureflag with Jerry
              {
                displayName: 'Your Surveys',
                slIcon: { name: 'task-list-pen', theme: 'bold' },
                route: 'staff-portal/byo/surveys',
                keyword: 'surveys',
                show: true,
              },
              // {
              //   displayName: 'Your Library',
              //   slIcon: { name: 'book-close-2', theme: 'bold' },
              //   route: 'staff-portal/byo/items',
              //   keyword: 'staff-portal/byo/items',
              //   show: this.featureFlag.featureOn(FEATURES.byoLibrary) && !this.featureFlag.featureOn(FEATURES.limitedResourceAccess),
              // },
              {
                displayName: 'Your Library',
                slIcon: { name: 'book-library', theme: 'bold' },
                route: 'staff-portal/byo/your-library',
                keyword: 'staff-portal/byo/your-library',
                show: this.featureFlag.featureOn(FEATURES.byoLibrary),
                secondLevelParent: true,
                children: [
                  {
                    displayName: 'Created by You',
                    slIcon: { name: 'book-close-2', theme: 'regular' },
                    route: 'staff-portal/byo/created-by-you',
                    show: true,
                    thirdLevel: true
                  },
                  {
                    displayName: 'Your Purchases',
                    slIcon: { name: 'tag', theme: 'bold' },
                    route: 'staff-portal/byo/from-ausmed',
                    show: true,
                    thirdLevel: true
                  },
                ]
              },
              {
                displayName: 'Training Plans',
                slIcon: { name: 'list-bullets', theme: 'bold' },
                route: 'compliance/mandatory-training',
                show: this.featureFlag.featureOn(FEATURES.MT),
              },
              {
                displayName: 'Event Planning',
                slIcon: { name: 'ticket-add', theme: 'bold' },
                route: 'plan/event-planning',
                // TODO: @Eddy check with Robbie
                show: isOrgManager && this.featureFlag.featureOn(FEATURES.bookingSystem),
              },
              // {
              //   displayName: 'Assign an Item',
              //   slIcon: { name: 'common-file-give-hand', theme: 'bold' },
              //   route: 'plan/assign',
              //   show: true,
              // },
            ]
          },
          {
            displayName: 'Manage',
            slIcon: { name: 'list-to-do', theme: 'bold' },
            route: 'manage/overview',
            show: true,
            children: [
              {
                displayName: 'Manage Overview',
                slIcon: { name: 'list-to-do', theme: 'bold' },
                route: 'manage/overview',
                show: true,
                isOverViewPage: true,
              },
              {
                displayName: 'User Compliance',
                slIcon: { name: 'interface-user-single', theme: 'micro-bold' },
                route: 'manage/user-compliance',
                show: true,
              },
              {
                displayName: 'Workroom',
                route: 'compliance/compliance-workroom',
                slIcon: { name: 'layout-three-columns', theme: 'bold' },
                // queryParams: { prefill: true },
                show: this.featureFlag.featureOn(FEATURES.MT),
              },
              {
                displayName: 'Calendar',
                slIcon: { name: 'calendar', theme: 'bold' },
                route: 'compliance/calendar',
                show: true,
              },
              {
                displayName: 'Item Management',
                slIcon: { name: 'folder', theme: 'bold' },
                route: 'manage/item-management',
                show: true,
              },
              {
                displayName: 'Event Management',
                slIcon: { name: 'ticket-check', theme: 'bold' },
                route: 'manage/event-management',
                show: isOrgManager && this.featureFlag.featureOn(FEATURES.bookingSystem),
              },
              getSavedFilterMenu('learningManage'),

            ]
          },
          {
            displayName: 'Reports',
            slIcon: { name: 'data-file-bars-alternate', theme: 'bold' },
            route: 'reporting/overview/overview-page',
            show: true,
            children: [
              {
                displayName: 'Report Overview',
                slIcon: { name: 'data-file-bars-alternate', theme: 'bold' },
                route: 'reporting/overview/overview-page',
                show: true,
                isOverViewPage: true,
              },
              {
                displayName: REPORT_CAT_DISPLAY.requirementStatus,
                route: 'reporting/overview/requirement-records',
                show: this.featureFlag.featureOn(FEATURES.MT),
                slIcon: { name: 'notes-paper-approve', theme: 'bold' }
              },
              // {
              //   displayName: 'Completion Records',
              //   route: 'reporting/overview/completion-records',
              //   show: true,
              //   slIcon: { name: 'task-list-to-do', theme: 'bold' }
              // },
              {
                displayName: REPORT_CAT_DISPLAY.learningRecords,
                route: 'reporting/overview/learning-records',
                show: true,
                slIcon: { name: 'study-book', theme: 'bold' },
              },
              // show only optionl report for CR
              {
                displayName: 'Optional Requirements',
                route: 'reporting/overview/requirement-records/optional',
                show: !this.featureFlag.featureOn(FEATURES.MT),
                slIcon: { name: 'notes-paper-approve', theme: 'bold' }
              },

              {
                displayName: 'Extensions',
                route: 'reporting/overview/extensions',
                show: this.featureFlag.featureOn(FEATURES.MT),
                slIcon: { name: 'calendar-add-1', theme: 'bold' }
              },
              {
                displayName: REPORT_CAT_DISPLAY.evaluateLearning,
                route: 'reporting/overview/learning-evaluate',
                show: true,
                slIcon: { name: 'rating-star-alternate', theme: 'bold' },
              },
              // {
              //   displayName: 'Stakeholder Reports',
              //   route: 'reporting/overview/stakeholder-reports',
              //   show: true,
              //   slIcon: { name: 'pie-line-graph', theme: 'bold' }
              // },
              {
                displayName: 'Ausmed Passport',
                route: 'reporting/overview/ausmed-passport',
                show: true,
                slIcon: { name: 'ausmed-passport', theme: 'bold' }
              },
              {
                displayName: 'Payroll',
                route: 'reporting/overview/payroll',
                show: this.featureFlag.featureOn(FEATURES.MT),
                slIcon: { name: 'file-copyright-dollar', theme: 'bold' }
              },
              getSavedFilterMenu('learningReport'),


            ]
          },
          {
            displayName: 'Settings',
            slIcon: { name: 'cog-1', theme: 'bold' },
            route: 'settings',
            show: true,
            badge: this.orgSubscriptionService.unpaidInvoiceNumber() > 0 ? { name: 'interface-alert-warning-circle', color: 'fill-danger', theme: 'micro-bold' } : null,
            children: [
              {
                displayName: 'Contact Details',
                route: 'settings/organisation-settings',
                show: isOrgManager,
              },
              {
                displayName: 'Billing',
                route: 'settings/billing-settings',
                show: isOrgManager,
                badge: this.orgSubscriptionService.unpaidInvoiceNumber() > 0 ? { name: 'interface-alert-warning-circle', color: 'fill-danger', theme: 'micro-bold' } : null
              },
              {
                displayName: 'Learning',
                route: 'settings/compliance-settings',
                show: isOrgManager,
              },
              // {
              //   displayName: 'Standards Settings',
              //   route: 'settings/standards-settings',
              //   show: this.featureFlag.featureOn(FEATURES.compliance),
              // },
              {
                displayName: 'Notifications',
                route: 'settings/email-settings',
                show: this.featureFlag.featureOn(FEATURES.MT),
              },
              {
                displayName: 'Customisation',
                route: 'settings/customisation',
                show: true,
              },
            ]
          },
        ];
        break;
      case PRODUCT_TYPES.LIBRARY:
        menuItems = [{
          displayName: 'Settings',
          slIcon: { name: 'cog-1', theme: 'bold' },
          route: 'settings',
          show: true,
          children: [
            {
              displayName: 'Contact Details',
              route: 'settings/organisation-settings',
              show: true,
            },
            {
              displayName: 'Learning Enhancements',
              route: 'settings/learning-enhancement',
              show: true,
            },
            {
              displayName: 'Standards Settings',
              route: 'settings/standards-settings',
              show: true,
            },
            {
              displayName: 'Customisation',
              route: 'settings/customisation',
              show: true,
            },
          ]
        },
        ];
        break;
      case PRODUCT_TYPES.HIPPOTLIAN:
        menuItems = [
          {
            displayName: 'Dashboard',
            slIcon: { name: 'house-2-alternate', theme: 'bold' },
            route: 'dashboard',
            show: true,
          },
          {
            displayName: 'Your Library',
            slIcon: { name: 'book-close-2', theme: 'bold' },
            route: 'staff-portal/byo/items',
            show: true,
          },
          {
            displayName: 'Settings',
            slIcon: { name: 'cog-1', theme: 'bold' },
            route: 'settings/organisation-settings',
            show: isOrgManager,
          }
        ];
        break;
      case PRODUCT_TYPES.CONTENT_PARTNER:
        menuItems = [
          {
            displayName: 'Your Library',
            slIcon: { name: 'book-close-2', theme: 'bold' },
            route: 'staff-portal/byo/items',
            show: this.featureFlag.featureOn(FEATURES.byoLibrary),
          },
          {
            displayName: 'Settings',
            slIcon: { name: 'cog-1', theme: 'bold' },
            route: 'settings',
            show: true,
            children: [
              {
                displayName: 'Contact Details',
                route: 'settings/organisation-settings',
                show: isOrgManager,
              },
              {
                displayName: 'Standards Settings',
                route: 'settings/standards-settings',
                show: this.featureFlag.featureOn(FEATURES.compliance),
              },
              {
                displayName: 'Customisation',
                route: 'settings/customisation',
                show: true,
              },
            ]
          },
        ];
        break;
      default:
        break;
    }
    this.adminMenuItems = contractType === PRODUCT_TYPES.CONTENT_PARTNER
      ? [{
        displayName: 'Manage Users',
        shortName: 'Users',
        slIcon: { name: 'task-checklist', theme: 'bold' },
        route: 'admin/manage-staff',
        keyword: 'admin/staff-transcript',
        show: this.featureFlag.featureOn(FEATURES.staff),
      },]
      : [
        {
          displayName: 'Dashboard',
          shortName: 'Dashboard',
          slIcon: { name: 'house-2-alternate', theme: 'bold' },
          route: 'admin/dashboard',
          show: true,
        },
        {
          displayName: 'Users',
          shortName: 'Users',
          slIcon: { name: 'interface-user-single', theme: 'ui-icons-line' },
          route: 'admin/manage-staff',
          show: this.featureFlag.featureOn(FEATURES.staff),
        },
        {
          displayName: 'Targets',
          shortName: 'Targets',
          slIcon: { name: 'target-center', theme: 'bold' },
          route: 'admin/targets',
          keyword: 'targets',
          show: this.featureFlag.featureOn(FEATURES.analyticsReport) && this.userService.isManager, // TODO: @Eddy need to confirm with PM
        },
        {
          displayName: 'Groups',
          // shortName: 'Teams',
          slIcon: { name: 'building-house', theme: 'bold' },
          route: 'admin/structure-overview',
          show: this.featureFlag.featureOn(FEATURES.team),
          children: [
            {
              displayName: 'Job Roles',
              shortName: 'Job Roles',
              slIcon: { name: 'products-briefcase' },
              route: 'admin/manage-job-roles',
              show: this.featureFlag.featureOn(FEATURES.jobRole),
            },
            {
              displayName: 'Teams',
              shortName: 'Teams',
              slIcon: { name: 'interface-user-multiple', theme: 'ui-icons-line' },
              route: 'admin/teams',
              show: this.featureFlag.featureOn(FEATURES.team),

            },
            {
              displayName: 'Locations',
              shortName: 'Locations',
              slIcon: { name: 'pin-location-1', theme: 'bold' },
              route: 'admin/admin-locations',
              show: this.featureFlag.featureOn(FEATURES.team),
            },
            {
              displayName: 'Reporting Lines',
              shortName: 'Hierarchy',
              slIcon: { name: 'ausmed-structure', theme: 'bold' },
              route: 'admin/hierarchy',
              show: this.featureFlag.featureOn(FEATURES.performance) &&
                (this.userService.isManager || !!this.hierarchyService.getMyDirectReports()?.length)
            },
          ]
        },
        // {
        //   displayName: 'Administration',
        //   slIcon: { name: 'interface-user-single', theme: 'ui-icons-line' },
        //   route: 'admin/overview',
        //   show: this.featureFlag.featureOn(FEATURES.staff) || this.featureFlag.featureOn(FEATURES.team) || this.featureFlag.featureOn(FEATURES.jobRole),
        //   children: [
        //   ]
        // },
        {
          displayName: 'Frameworks',
          keyword: 'frameworks',
          slIcon: { name: 'checklist-alternate', theme: 'bold' },
          route: 'admin/frameworks/overview',
          show: isOrgManager, // TODO: @Eddy need to check the access with @Tim
          children: [
            {
              displayName: 'Regulatory Standards',
              route: 'admin/frameworks/regulatory-standards',
              slIcon: { name: 'design-tool-stamp', theme: 'bold' },
              show: true,
            },
            {
              displayName: 'Capability Frameworks',
              route: 'admin/frameworks/capability-frameworks',
              slIcon: { name: 'stairs-ascend', theme: 'bold' },
              show: true,
            },
          ]
        },
      ];
    this.ppMenuItems = [
      {
        displayName: 'Dashboard',
        slIcon: { name: 'house-2-alternate', theme: 'bold' },
        route: 'policies/dashboard',
        show: true,
      },
      {
        displayName: 'Policy Library',
        show: true,
        route: 'policies/manage/overview',
        slIcon: { name: 'curve-notebook', theme: 'regular' },
        children: [
          {
            displayName: 'Policy Register',
            slIcon: { name: 'app-window-check', theme: 'bold' },
            show: true,
            route: 'policies/manage/policies',
          },
          {
            displayName: 'Policy Review',
            show: true,
            slIcon: { name: 'app-window-search-1', theme: 'bold' },
            route: 'policies/acknowledgement-manage/policy-review',
          },
          {
            displayName: 'Additional Documents',
            slIcon: { name: 'common-file-text-alternate', theme: 'bold' },
            route: 'policies/manage/forms',
            show: true,
          },
        ]
      },
      {
        displayName: 'Compliance',
        slIcon: { name: 'single-neutral-action-check', theme: 'bold' },
        show: true,
        route: 'policies/acknowledgement-manage/overview',
        children: [
          {
            displayName: 'By Policy',
            show: true,
            slIcon: { name: 'folder', theme: 'bold' },
            route: 'policies/acknowledgement-manage/policy-management',
          },
          {
            displayName: 'By User',
            show: true,
            slIcon: { name: 'interface-user-single', theme: 'micro-bold' },
            route: 'policies/acknowledgement-manage/policy-user-compliance',

          },
          getSavedFilterMenu('policyManage'),
        ]
      },
      {
        displayName: 'Reports',
        slIcon: { name: 'data-file-bars-alternate', theme: 'bold' },
        route: 'policies/reporting',
        show: true,
        children: [
          ...overviewItemsToSidenav(ppReportOverviewItems),
          getSavedFilterMenu('policyReport'),
        ]
      },
      {
        displayName: 'Policy Partners',
        slIcon: { name: 'handshake' },
        route: 'policies/policy-partners',
        show: true,
        isBottomItem: true
      }
    ];
    this.libraryMenuItems = [
      {
        displayName: 'Training Schedule',
        slIcon: { name: 'folder-upload-alternate', theme: 'bold' },
        route: 'scorm-schedules',
        keyword: 'scorm-schedules',
        show: this.featureFlag.featureOn(FEATURES.scormSchedules),
      },
      {
        displayName: 'Your Purchases',
        slIcon: { name: 'tag', theme: 'bold' },
        route: 'library/your-purchase',
        show: this.featureFlag.featureOn(FEATURES.limitedResourceAccess),
      },
      {
        displayName: 'Ausmed Library',
        slIcon: { name: 'book-open-bookmark', theme: 'bold' },
        route: 'library/all-resources',
        show: this.featureFlag.featureOn(FEATURES.ausmedLibrary),
        children: [
          {
            displayName: 'All Resources',
            route: 'library/all-resources',
            show: true,
            isOverViewPage: true
          },
          {
            displayName: 'Mandatory Training',
            route: 'library/mandatory-training',
            show: true,
          },
          {
            displayName: 'Professions',
            route: 'library/professions',
            show: true,
          },
          {
            displayName: 'Learning Hubs',
            route: 'library/learning-hubs',
            show: true,
          },
          {
            displayName: 'Standards',
            route: 'library/standards',
            show: true,
          },
          {
            displayName: 'Providers',
            route: 'library/providers',
            show: !this.featureFlag.featureOn(FEATURES.scormSchedules),
          },
          {
            displayName: 'Topics',
            route: 'library/topics',
            show: true,
          },
          {
            displayName: 'Templates',
            route: 'library/training-plan-templates',
            show: this.featureFlag.featureOn(FEATURES.MT),
          },
          {
            displayName: 'Bundles',
            route: 'library/content-bundles',
            show: this.featureFlag.featureOn(FEATURES.ausmedLibrary),
          },
        ]
      },
      {
        displayName: 'NZAC Library',
        slIcon: { name: 'book-open-bookmark', theme: 'bold' },
        route: 'nzac-lib/all-resources', //TODO: @Eddy need to update
        keyword: 'nzac',
        show: this.featureFlag.featureOn(FEATURES.partnerContent),
        children: [
          {
            displayName: 'All Resources',
            route: 'nzac-lib/all-resources',
            show: true,
          },
          {
            displayName: 'Standards',
            route: 'nzac-lib/standards',
            show: true,
          },
        ].concat(NZACSubMenuItems)
      },
      {
        displayName: 'Learning Analytics',
        slIcon: { name: 'performance-increase-2', theme: 'bold' },
        show: this.featureFlag.featureOn(FEATURES.scormAnalytics),
        children: [
          {
            displayName: 'Usage Reports',
            route: 'scorm-analytics/usage-reports',
            show: true,
          },
          {
            displayName: 'Reports by Standards',
            route: 'scorm-analytics/compliance-report',
            show: true,
          },
          {
            displayName: 'Feedback',
            route: 'scorm-analytics/feedback',
            show: true,
          },
          {
            displayName: 'Audit Log',
            route: 'scorm-analytics/audit-log',
            show: true,
          },
        ]
      },
      // only use for Clinical Resource org, it will add to Library tab when it's CR org. eg: org code: cr
      ...((!this.featureFlag.featureOn(FEATURES.MT) && contractType === PRODUCT_TYPES.LMS) ? [menuItems.find(i => i.route === 'reporting/overview/overview-page')] : []),
      // ...((!this.featureFlag.featureOn(FEATURES.MT) && contractType === PRODUCT_TYPES.LMS) ? [{
      //   displayName: 'Report',
      //   slIcon: { name: 'data-file-bars-alternate', theme: 'bold' },
      //   route: 'library/reporting/overview',
      //   show: !this.featureFlag.featureOn(FEATURES.MT),
      // } as IMenuItem] : []),
    ];

    this.analyticsMenuItems = [
      {
        displayName: 'Audit Report',
        // slIcon: { name: 'folder-upload-alternate', theme: 'bold' },
        route: 'intelligence/audit-report',
        keyword: 'audit-report',
        show: true,
      },
      {
        displayName: 'Board Report',
        // slIcon: { name: 'folder-upload-alternate', theme: 'bold' },
        route: 'intelligence/board-report',
        keyword: 'board-report',
        show: true,
      },
      {
        displayName: 'Team Report',
        // slIcon: { name: 'folder-upload-alternate', theme: 'bold' },
        route: 'intelligence/team-report',
        keyword: 'team-report',
        show: true,
      },

    ];

    this.performanceMenuItems = [
      {
        displayName: 'Dashboard',
        slIcon: { name: 'house-2-alternate', theme: 'bold' },
        route: 'performance/dashboard',
        show: true,
      },
      {
        displayName: 'My Tasks',
        slIcon: { name: 'interface-validation-check-circle', theme: 'micro-bold' },
        show: true,
        route: 'performance/my-tasks'
      },
      {
        displayName: 'Reviews',
        show: true,
        route: 'performance/reviews/overview',
        slIcon: { name: 'rating-star-alternate', theme: 'bold' },
        children: [
          {
            displayName: 'Activities',
            slIcon: { name: 'task-list-pen', theme: 'bold' },
            show: true,
            route: 'performance/reviews/task',
          },
          {
            displayName: 'Cycles',
            slIcon: { name: 'loading-3', theme: 'bold' },
            route: 'performance/reviews/cycles',
            show: true,
          },
          {
            displayName: 'Progress',
            slIcon: { name: 'notes-checklist-flip', theme: 'bold' },
            route: 'performance/reviews/progress',
            show: true,
          },
        ]
      },
      {
        displayName: 'Goals',
        slIcon: { name: 'business-climb-top', theme: 'bold' },
        show: true,
        route: 'performance/goals'
      },
      {
        displayName: 'Feedback',
        slIcon: { name: 'messages-bubble-square-alternative', theme: 'bold' },
        route: 'performance/feedback',
        show: true,
      },
      {
        displayName: 'Reports',
        slIcon: { name: 'data-file-bars-alternate', theme: 'bold' },
        route: 'performance/reporting',
        show: true,
        children: [
          {
            displayName: 'Perform Task',
            slIcon: { name: 'rating-star-alternate', theme: 'bold' },
            show: true,
            route: '/pages/performance/reporting/performance-review-report',
          },
          {
            displayName: 'Feedback',
            slIcon: { name: 'messages-bubble-square-alternative', theme: 'bold' },
            show: true,
            route: '/pages/performance/reporting/feedback-report',
          },
          {
            displayName: 'Feedback by Standards',
            slIcon: { name: 'messages-bubble-square-alternative', theme: 'bold' },
            show: true,
            route: '/pages/performance/reporting/feedback-by-standards-report',
          },

          getSavedFilterMenu('performanceReport'),
        ]
      },
    ];

    this.competencyMenuItems = [
      {
        displayName: 'Assessment Templates',
        slIcon: { name: 'list-to-do-square', theme: 'bold' },
        route: 'competency/competency-library',
        keyword: 'competency-library',
        show: true,
      },

    ]


    // use setting items on header
    const parentSettings = menuItems.find(i => i.route === 'settings');
    this.settingItems = parentSettings.children || [parentSettings];

    menuItems = menuItems.filter(i => i.route !== 'settings');
    this.learningMenuItems = menuItems;
    // this.MENU_ITEMS = menuItems;
    if (update) {
      this.switchMenuItems(this.menuType);
      this.broadcastService.broadcast('menuUpdated');
    }
  }

  get open(): boolean {
    return this._open;
  }

  set open(value: boolean) {
    this._open = value;
  }


  constructor(
    private featureFlag: FeatureFlagService,
    private orgSubscriptionService: OrgSubscriptionService,
    private broadcastService: BroadcastService,
    private router: Router,
    private orgService: OrganisationService,
    private userService: UserService,
    // private productSwitchService: ProductSwitchService
    // private initService: InitializeService
  ) {
    // control menu changes based on route
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((e: NavigationEnd) => {
        const product = e.url.split('/')[2];
        if (!e.url.startsWith('/pages')) {
          return 'logout'; // ensure that next value is emitted when user logout then login back
        }
        const urlToMenuTypeDict = {
          settings: 'Settings',
          admin: MENU_TYPES.ADMIN,
          library: MENU_TYPES.LIBRARY,
          'nzac-lib': MENU_TYPES.LIBRARY,
          'saved-filters': MENU_TYPES.SHORTCUTS,
          'policies': MENU_TYPES.PP,
          'staff-transcript': MENU_TYPES.ADMIN,
          'welcome': 'welcome',
          'scorm-schedules': MENU_TYPES.LIBRARY,
          'scorm-analytics': MENU_TYPES.LIBRARY,
          'reporting': this.featureFlag.featureOn(FEATURES.MT) ? MENU_TYPES.LEARNING : MENU_TYPES.LIBRARY,
          'analytics': MENU_TYPES.ANALYTICS,
          'performance': MENU_TYPES.PERFORMANCE,
          'competency':MENU_TYPES.COMPETENCY
        };
        return urlToMenuTypeDict[product] || MENU_TYPES.LEARNING;
      }),
      distinctUntilChanged(),
      filter(p => !!p)
    ).subscribe((e) => {
      this.switchMenuItems(e);
    });
    // broadcastService.on('logout').subscribe(() => this.switchMenuItems(MENU_TYPES.LEARNING));
  }

  switchProduct(product) {
    switch (product) {
      case MENU_TYPES.ADMIN:
        this.router.navigate(['/pages/admin/manage-staff']);
        break;
      case MENU_TYPES.LEARNING:
        const contractType = this.orgService.getContractType();
        const homeLearningPage = contractType === PRODUCT_TYPES.CONTENT_PARTNER ?
          '/pages/staff-portal/byo/items' : '/pages/dashboard';
        this.router.navigate([homeLearningPage]);
        break;
      case MENU_TYPES.PP:
        this.router.navigate(['/pages/policies/dashboard']);
        break;
      case MENU_TYPES.LIBRARY:
        this.router.navigate(['/pages/scorm-schedules']);
        break;
    }
  }

  switchMenuItems(type: MENU_TYPES | 'welcome' | 'logout') {
    if (type) {
      this.menuType = type;
      const dict: Record<MENU_TYPES, any> = {
        [MENU_TYPES.ADMIN]: this.adminMenuItems,
        [MENU_TYPES.LIBRARY]: this.libraryMenuItems,
        [MENU_TYPES.LEARNING]: this.learningMenuItems,
        [MENU_TYPES.PP]: this.ppMenuItems,
        [MENU_TYPES.SETTINGS]: this.settingItems,
        [MENU_TYPES.SHORTCUTS]: this.learningMenuItems,
        [MENU_TYPES.ANALYTICS]: this.analyticsMenuItems,
        [MENU_TYPES.PERFORMANCE]: this.performanceMenuItems,
        [MENU_TYPES.COMPETENCY]: this.competencyMenuItems
      };
      if (type === 'welcome') {
        return;
      }
      this.MENU_ITEMS = dict[type] || this.learningMenuItems;
      this.broadcastService.broadcast('menuUpdated');
    }
  }

  toggleNav() {
    this._open = !this._open;
    this.triggerToggle$.next(true);
  }

  closeNav() {
    this._open = false;
    this.triggerToggle$.next();
  }

  openNav() {
    this._open = true;
    this.triggerToggle$.next();
  }

  onOpenItem(openItemDisplayName) {
    this._openItemSubject.next(openItemDisplayName);
  }
}
