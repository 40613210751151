import { Component, input } from '@angular/core';
import { AvatarRequirementsComponent } from '@components/avatar/avatar-requirements/avatar-requirements.component';
import { RouterLink } from '@angular/router';
import { AssignStatusCellComponent } from '@components/table-cell/assign-status-cell/assign-status-cell.component';
import { SideStaffDisplayComponent } from '@components/side-panel-components/side-staff-display/side-staff-display.component';
import { AsyncPipe } from '@angular/common';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { ITablePerformanceReviewAssign } from 'src/app/services/performance-review.service';
import { ComplianceStatusPipe } from 'src/app/shared/pipes/compliance-status.pipe';

@Component({
  selector: 'app-task-info-card',
  standalone: true,
  imports: [AvatarRequirementsComponent, RouterLink, AssignStatusCellComponent,
    SideStaffDisplayComponent, AsyncPipe, LocalDatePipe, ComplianceStatusPipe],
  templateUrl: './task-info-card.component.html',
  styleUrl: './task-info-card.component.scss'
})
export class TaskInfoCardComponent {
  task = input<ITablePerformanceReviewAssign>();
  labelClass = 'f-small text-neutral-700 mb-2'
  valueClass = 'f-small fw-semibold'

}
