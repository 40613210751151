import { Component } from '@angular/core';

import { DefaultEditor } from './default-editor';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'input-editor',
    styleUrls: ['./editor.component.scss'],
    template: `
    <input [ngClass]="inputClass"
           class="form-control"
           [(ngModel)]="cell.newValue"
           [name]="cell.getId()"
           [placeholder]="cell.getTitle()"
           [disabled]="!cell.isEditable()"
           (click)="onClick.emit($event)"
           (keydown.enter)="onEdited.emit($event)"
           (keydown.esc)="onStopEditing.emit()">
    `,
    standalone: true,
    imports: [FormsModule, NgClass],
})
export class InputEditorComponent extends DefaultEditor {

  constructor() {
    super();
  }
}
