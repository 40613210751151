import { NgFor, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { IFacility } from 'src/app/pages/manage-location/admin-location-types';
import { FacilitiesService } from 'src/app/services/facilities.service';
import { WithDestroy } from '../../utils/mixins/withDestroy';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { uniq } from 'lodash';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-locations-multi-select',
  templateUrl: './locations-multi-select.component.html',
  styleUrls: ['./locations-multi-select.component.scss'],
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, FormsModule, SlicePipe, NgIf, NgFor, NgStyle]
})
export class LocationsMultiSelectComponent extends WithDestroy() implements OnInit {
  @ViewChild('selector') selector: NgSelectComponent;
  @Input() control: FormControl;
  @Input() options: any[];
  @Input() sliceNum: number;
  @Input() selectAllChildren: boolean = false;
  @Input() showSelectedNumLabel: boolean = true;
  @Input() allowSelectAll = true;
  @Input() dropdownPosition = 'auto';
  filteredItems: any[];

  // @Input() valueAttribute: string;
  // @Input() nameAttribute: string;

  constructor(
    private facilitiesService: FacilitiesService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.selectAllChildren) {
      this.control.valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(this.control.value),
        pairwise())
        .subscribe(
          ([prev, next]: [string[], string[]]) => {
            if (next?.length - prev?.length === 1) {
              const addedLocation = this.facilitiesService.globalOrgFacilities[next[next?.length - 1]];
              if (addedLocation?.allDescendants) {
                this.control.setValue(uniq(next.concat(addedLocation?.allDescendants)), { emitEvent: false })
              }
            }
          }
        );
    }
  }

  getFilteredItems(e) {
    this.filteredItems = e.items;
  }

  public onSelectAll() {
    this.control.setValue((this.filteredItems || this.options).filter(i => !i.disabled).map(l => l.teamID));
    this.filteredItems = null;
    if (this.selector) { this.selector.close(); }
  }

  public onClearAll() {
    this.control.setValue([]);
    this.filteredItems = null;
  }

  displayedUpperStructure(facility: IFacility) {
    let v = facility;
    if (!v?.upperTeam || !this.facilitiesService.globalOrgFacilities[v.upperTeam]) {
      return v.name;
    }
    let ans = '';

    while (v.upperTeam) {
      const parent = this.facilitiesService.globalOrgFacilities[v.upperTeam];
      ans = parent.name + ' / ' + ans;
      v = parent
    }
    return ans + facility.name;
  }
}
