import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UISpinnerComponent } from '../../../../ui/ui-spinner/ui-spinner.component';
import { NgIf } from '@angular/common';
import { ModalSingleComponent } from '../../../components/modal-single/modal-single.component';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    standalone: true,
    imports: [ModalSingleComponent, NgIf, UISpinnerComponent]
})
export class ConfirmationModalComponent implements OnInit {


  @Input() modalHeading: string;
  @Input() buttonClass = 'btn-danger';
  @Input() buttonText = 'Confirm';
  @Input() toasterText: string;
  @Input() request$: Observable<any>;
  @Input() disableButton = false;
  @Input() content = '';

  // For if a second action button is needed
  @Input() secondaryButtonText: string;
  @Input() secondaryRequest$: Observable<any>;

  @Output() success = new EventEmitter();
  @Output() successWithLoadTime = new EventEmitter<{ res, loadTime }>();


  isSubmitting = false;

  constructor(
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  confirm(secondaryAction = false) {
    this.isSubmitting = true;
    const startTime = dayjs();
    (secondaryAction ? this.secondaryRequest$ : this.request$).subscribe(res => {
      if (this.toasterText) {
        this.toaster.success(this.toasterText);
      }
      this.isSubmitting = false;
      this.success.emit(res);
      this.successWithLoadTime.emit({
        res: res, loadTime: Number(dayjs().diff(startTime, 's', true)).toFixed(2),
      });
      this.closeModal(res);
    }, error => {
      this.isSubmitting = false;
      this.toaster.error(error);
    });
  }

  closeModal(result = null) {
    this.activeModal.close(result);
  }

}
