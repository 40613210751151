<div *ngIf="tables" appScrollShadow class="table-overflow">
    <app-multi-tab-tables [tables]="tables" [activeTabId]="activeTabId" (navChange)="onNavChange($event)">
        <app-shared-table-actions [tableSettings]="currentSettings" (download)="downloadCSV($event)">
        </app-shared-table-actions>

    </app-multi-tab-tables>
    <div class="table-wrap sticky-multi-select" [id]="uniqTableID" appScrollShadow>
        <ng-container *ngIf="tables[activeTabId].numRow">
            <app-multi-select-table *ngIf="refreshFlag && activeTabId === 1" [tableSource]="currentTable"
                [tableSettings]="currentSettings" [divID]="uniqTableID" [keyName]="'ackID'"
                [selectedItems]="selectedItems" [itemName]="tableItemName" [ignoreSelect]="hideMultiSelect"
                [stickyLeft]="true" [stickyRight]="true">

                <!-- Hide for v1 -->
                <!-- <button class="btn btn-outline me-2">Mark as Acknowledged</button> -->

                <button (click)="sendAckReminder()" class="btn btn-outline">Send Reminder</button>
            </app-multi-select-table>
            <app-smart-table [hidden]="activeTabId === 1" [settings]="currentSettings" [tableDiv]="uniqTableID"
                [stickyLeft]="true" [stickyRight]="true" [source]="currentTable"></app-smart-table>
        </ng-container>


        <ui-empty-state class="sticky-empty-state border-top" [border]="false" [inCard]="false"
            *ngIf="!tables[activeTabId].numRow" content="No records to display">
        </ui-empty-state>



    </div>
    <div></div>
</div>