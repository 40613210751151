import { color } from 'echarts';
import { TooltipComponent } from './../tooltip/tooltip.component';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ViewCell } from 'src/app/shared-modules/ng2-smart-table/components/cell/cell-view-mode/view-cell';
import { sortBy } from 'underscore';
import { COLUMN_VALUES } from 'src/app/core/table-headers.dict';
import { getMaxWidth } from '../../utils/table-cell-functions';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { TooltipComponent as TooltipComponent_1 } from '../tooltip/tooltip.component';
import { NgIf, NgFor } from '@angular/common';
import { UIStreamlineIconComponent } from "../../../ui/streamline-icon/ui-streamline-icon.component";
import { IJobRoleTaken } from 'src/app/services/group.service';
import { SHOW_FUTURE_JOB_ROLES_LIST, orgInTheFeatureOrgList } from '../../utils/orgList/org-list-by-feature';

@Component({
    selector: 'app-job-roles-display-cell',
    template: `
    <div class="text-wrap text-truncate" [style]="'width: ' + maxWidth + 'px;'">

    <ng-container *ngIf="futureJobRoles?.length">
      <ui-streamline-icon class="me-1" [slIcon]="{name: 'interface-time-clock-circle', theme: 'micro-bold', size: 'sm', svgStyle: {'padding-bottom': '2px'}, color: 'fill-muted'}"
              [ngbTooltip]="popTooltip"
              tooltipClass="custom-tooltip">
      </ui-streamline-icon>
    </ng-container>


    <ng-container *ngIf="!showAvatar">
      <ng-container *ngIf="showDangerBadge">
        <app-tooltip class="me-1" icon='danger' text="User does not have a matching job role for this plan."></app-tooltip>
      </ng-container>
      <ng-container *ngFor="let jobRole of shownJobs; last as isLast">
          <a href="javascript:void(0);" [routerLink]="['/pages/admin/manage-job-roles/single', jobRole.jobRoleID]" target="_blank"
             class="fw-medium text-neutral-900">{{jobRole.title}}</a><span *ngIf="!isLast" class="fw-medium text-neutral-900">,&nbsp;</span>
      </ng-container>
      <ng-container *ngIf="hiddenJobs?.length">
                <span *ngIf="shownJobs?.length" class="fw-medium text-neutral-900">, </span>
                <span class="fw-medium text-neutral-900 cursor-pointer hover-underline"
                  placement="top"
                  [ngbPopover]="popContent" [popoverTitle]="popTitle" [container]="'body'"
                  target="_blank"
                >+{{hiddenJobs?.length}} more
                </span>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="showAvatar">
      <ng-container *ngFor="let jobRole of jobRoles; last as isLast">
        <div class="d-flex children-gap-1 align-items-center">
            <ui-avatar [slIcon]="{name: 'products-briefcase', theme: 'bold', size: 'sm'}" [size]="'sm'"></ui-avatar>
          <a href="javascript:void(0);" [routerLink]="['/pages/admin/manage-job-roles/single', jobRole.jobRoleID]" target="_blank"
             class="fw-medium text-neutral-900">{{jobRole.title}}</a><span *ngIf="!isLast" class="fw-medium text-neutral-900">,&nbsp;</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!jobRoles?.length">
      <span>{{ noRecordSymbol }}</span>
    </ng-container>




    </div>

    <!-- popover -->
    <ng-template #popContent>
      <ng-container *ngFor="let job of hiddenJobs;  last as isLast">
        <div>
          <a href="javascript:void(0);"
            [routerLink]="['/pages/admin/manage-job-roles/single', job.jobRoleID]"
            class="fw-medium text-neutral-900"
            target="_blank">
            {{job.title}}
          </a>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #popTitle>
      <span class="fw-medium text-neutral-900 f-small">
        +{{ hiddenJobs.length }} more job role{{ hiddenJobs?.length > 1 ? 's' : '' }}
      </span>
    </ng-template>

    <ng-template #popTooltip>
      <ng-container *ngFor="let job of futureJobRoles;  last as isLast">
          <div>
            <a href="javascript:void(0);"
              [routerLink]="['/pages/admin/manage-job-roles/single', job.jobRoleID]"
              class="fw-medium text-white"
              target="_blank">
              {{job.title}} (Start on {{job.startDate}})
            </a>
          </div>
      </ng-container>
    </ng-template>



  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TooltipComponent_1, NgFor, RouterLink, NgbPopover, AvatarComponent, UIStreamlineIconComponent, NgbTooltip]
})
export class JobRoleTitlesCellComponent implements ViewCell, OnChanges {
  jobRoles: any[];
  @Input() value;
  @Input() rowData: any;
  @Input() showAvatar = false;
  @Input() showDangerBadge = false;
  @Input() showAll = false;
  futureJobRoles: any[];
  maxWidth:number;
  PIXPERCHAR = 7;
  maxNumChar: number;
  hiddenJobs: any[];
  shownJobs: any[];
  className = 'jobRoleTitles';
  isModal = false;


  noRecordSymbol = COLUMN_VALUES.NO_RECORD_SYMBOL;

  constructor() { }

  ngOnChanges(c: SimpleChanges) {
    this.process();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.process();
  }

  process() {
    this.futureJobRoles = this.rowData?.futureJobRoleTakens?.map(taken => ({...taken?.jobRoleModel, startDate: taken?.startDate}));
    this.maxWidth = getMaxWidth(this.className, this.isModal, this.maxWidth) || this.maxWidth;
    this.maxNumChar = Math.floor(this.maxWidth / this.PIXPERCHAR);
    this.jobRoles = this.rowData.jobRoleTitles || sortBy(this.rowData.jobRoles, 'title') || [];
    if (!this.jobRoles.length && this.rowData.jobRoleID) {
      this.jobRoles = [{ ...this.rowData }];
    }
    this.shownJobs = this.getShownJobs(this.jobRoles);

    if (!this.showAll) {
      this.hiddenJobs = this.jobRoles.slice(this.shownJobs.length);
    }
  }

  getShownJobs(jobs: any[]) {
    // if enough space, show all
    const allJobsChars = jobs.reduce((prev, cur) => {
      if (prev === 0) {
        return cur.title?.length; // first team
      }
      return prev + cur.title.length + 3; // add 3 for comma and spaces
    }, 0);
    if (allJobsChars <= this.maxNumChar || this.showAll) {
      return jobs;
    }
    // otherwise, take some and hide rest
    let currentTotalChar = 5; // space for ...n more
    const shownJobs: any[] = [];
    let continueLoop = true;
    jobs.forEach((job, index) => {
      // add first job regardless
      if (currentTotalChar + job.title.length + 3 < this.maxNumChar && continueLoop || index === 0) {
        shownJobs.push(job);
        currentTotalChar += (job.title.length + 3);
      } else {
        continueLoop = false;
      }
    });
    return shownJobs;
  }


}
