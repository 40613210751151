<nav aria-label="breadcrumb">
  <ul class="breadcrumb" [ngClass]="showMarginBottom ? '' : 'mb-0'">
    <ng-container *ngFor="let crumb of breadcrumbs; let last = last">
      <li class="breadcrumb-item">
        <a [routerLink]="crumb.url" [routerLinkActive]="'is-active'" class="text-neutral-700 f-small">
          {{crumb.label}}
        </a>
      </li>
      <li *ngIf="last" class="breadcrumb-item"></li>
    </ng-container>
  </ul>
</nav>
