import { Pipe, PipeTransform } from '@angular/core';

type AccountStatus = 'Invited' | 'In-active' | 'Active' | 'Pending' | 'Rejected' | 'Overdue';

const USER_STATUS = {
    Invited: 'Invited',
    'In-active': 'Inactive',
    Active: 'Active',
    Pending: 'Pending',
    Rejected: 'Rejected',
    Overdue: 'Overdue',
}

// This is just for compliance records!!
@Pipe({
    name: 'accountStatusPipe',
    standalone: true
})
export class AccountStatusPipe implements PipeTransform {
    transform(value: string = ''): string {
        if (value.includes('In-active')) {
            return value.replace('In-active', 'Inactive');
        }
        return value;
    }

}
