<div class="page-header-container">
  <div class="d-flex flex-column gap-4">
    @if(breadcrumbConfigs) {
      <ui-breadcrumb [breadcrumbs]="breadcrumbConfigs" [showMarginBottom]="false"
      ></ui-breadcrumb>
    }
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row align-items-center gap-2">
        <h2 class="fw-bold">{{pageTitle}}</h2>
        @if (tooltipContent) {
          <app-tooltip [placement]="'right'" [icon]="'info'" [text]="tooltipContent"
          ></app-tooltip>
        }
      </div>
      <ng-content select="[rightSlot]"></ng-content>
    </div>
    @if (details) {
      <div class="f-small text-neutral-800">
        <span class="fw-semibold">{{boldedText}} </span>
        <span>{{details}}</span>
      </div>
    }
  </div>
</div>
