<ui-alert-banner
icon="fa-info-circle"
type="Customised"
[color]="color"
[copy]="copy"
[includeDismiss]="false"
>
<ng-container buttonSlotCustom>
    <ng-content></ng-content>
</ng-container>

</ui-alert-banner>
