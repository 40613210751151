import { ISingleSelectOption } from "@components/customised-single-select/customised-single-select.component"
import { JobRoleType } from "src/app/services/group.service"

export const JOB_ROLE_TYPE_OPTIONS: ISingleSelectOption[] = [
  {label: 'Clinical Job Roles', value: 'clinical'},
  {label: 'Non-Clinical Job Roles', value: 'nonClinical'},
]

export const jobRoleTypeCaptionDisplay: Record<JobRoleType, string> = {
  clinical: 'Clinical',
  nonClinical: 'Non-Clinical'
}
