import { each, values } from 'underscore';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountStatusPipe } from '../../pipes/account-status.pipe';
import { StaffStatusDisplayComponent } from '../staff-status-display/staff-status-display.component';
import { TruncatedTextComponent } from './truncated-text/truncated-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgClass } from '@angular/common';

export interface ISelectorOption {
  label: string;
  value: string;
  child?: boolean;
  group?: string;
}

/**
 * used as multi-selectors in reporting
 */
@Component({
    selector: 'app-customised-multi-select',
    templateUrl: './customised-multi-select.component.html',
    styleUrls: ['./customised-multi-select.component.scss'],
    standalone: true,
    imports: [NgIf, NgSelectModule, FormsModule, ReactiveFormsModule, NgClass, TruncatedTextComponent, StaffStatusDisplayComponent, AccountStatusPipe]
})
export class CustomisedMultiSelectComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('selector') selector: NgSelectComponent;
  @Input() id: string;
  @Input() controls;
  @Input() controlName: string;
  @Input() options: ISelectorOption[];
  @Input() displayName: string;
  @Input() isLoading = false;
  @Input() placeholder;
  @Input() submitted;
  @Input() autoGrow = false;
  @Input() errorMsg: string;
  @Input() includesTextAll = true;
  @Input() selectAll = false;
  @Input() specialFirstOption = false;
  @Input() savedFilter$?: Observable<any>;
  @Input() fixedWidth = false;
  @Input() appendTo;
  @Input() showNumOnSelectAll = true;
  @Output() closeDropdown = new EventEmitter();
  @Input() allSelectedText;
  isFirstOptionSelected: boolean;
  destroy$ = new Subject();
  filteredItems: ISelectorOption[];
  constructor(
    ) { }

  ngOnInit(): void {
    if (this.selectAll && !this.controls.items?.value?.length) {
      this.onSelectAll();
    }

    // checks if first option is selected when saved filter is applied
    if (this.specialFirstOption && this.savedFilter$) {
      this.savedFilter$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isFirstOptionSelected = this.controls[this.controlName]?.value?.find(v => v === this.options[0]?.value);
      });
    }
  }

  public onSelectAll() {
    const selected = (this.filteredItems || this.options).map(item => item.value);
    this.controls[this.controlName].setValue(selected);
    if(this.specialFirstOption){
      this.isFirstOptionSelected = this.controls[this.controlName]?.value?.find(v => v === this.options[0]?.value);
    }
    this.filteredItems = null;
    if(this.selector){ this.selector.close(); }
  }

  public onClearAll() {
    this.controls[this.controlName].patchValue([]);
    this.filteredItems = null;
  }

  get pluralForm() {
    return pluralForm(this.displayName);
  }


  // Make sure the list scroll is at the top
  onOpen() {
    setTimeout(() => {
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 0);
  }

  // Toggles isFirsOptionSelected
  onChange(e: any[]) {
    if (!this.specialFirstOption) {
      return;
    }
    if (e.find(selected => selected.value === this.options[0].value)) {
      this.isFirstOptionSelected = true;
    } else {
      this.isFirstOptionSelected = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // checks if first option is selected when opening the page with a
    // saved filter applied
    if (this.specialFirstOption && changes.controls) {
      this.isFirstOptionSelected = this.controls[this.controlName]?.value?.find(v => v === this.options[0]?.value);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroy$.next();
  }

  onClose() {
    // console.log('closed')
    this.closeDropdown.emit();
  }

  selectSome(num) {
    const partSize = Math.ceil(this.options.length / 4);
    const start = (num - 1) * partSize;
    const end = partSize * num;
    this.controls[this.controlName].setValue(this.options.slice(start, end).map(el => el.value))
  }

  getFilteredItems(e){
    this.filteredItems = e.items;
  }
}

export function pluralForm(word: string) {
  if (word.endsWith('s')) {
    return word + 'es';
  }
  if (word.endsWith('y')) {
    return word.slice(0, word.length - 1) + 'ies';
  }
  return word + 's';
}


