import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAnalyticsEventWithArgs } from '@ausmed/cpdo-analytics-types';
import * as dayjs from 'dayjs';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ILearningGoal } from 'src/app/services/goal.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { IPerformanceReviewCycleConfig, IPerformanceReviewCycleConfigWithExtras, IPerformanceReviewTaskTemplate, ITablePerformanceReviewAssign } from 'src/app/services/performance-review.service';
import { UserService } from 'src/app/services/user.service';
import { removeEmptyReturn } from 'src/app/shared/utils/remove-empty-fields';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsPrService {

  analyticsService = inject(AnalyticsService);
  userService = inject(UserService);

  organisation = toSignal(inject(OrganisationService).getOrganisation().pipe(filter(org => !!org)));
  logoUrl = toSignal(inject(OrganisationService).getLogoUrl());

  sendTrack(event: IAnalyticsEventWithArgs, extraPayload = {}) {
    this.analyticsService.track(event, removeEmptyReturn({
      ...this.getBasicInfo(),
      ...extraPayload
    }))
  }

  sendTemplateTrack(event: IAnalyticsEventWithArgs, template: IPerformanceReviewTaskTemplate) {
    if (template) {
      this.sendTrack(event, this.getTemplateInfo(template))
    }
  }

  sendCycleExtrasTrack(event: IAnalyticsEventWithArgs, cycleWithExtras: IPerformanceReviewCycleConfigWithExtras) {
    if (cycleWithExtras) {
      this.sendTrack(event, this.getCycleInfoWithExtras(cycleWithExtras));
    }
  }

  getBasicInfo() {
    const manager = this.userService.orgUser.value;
    return {
      orgID: localStorage.getItem('orgID'),
      orgCode: localStorage.getItem('orgcode'),
      managerFirstName: manager.firstName,
      managerLastName: manager.lastName,
      managerUsername: manager.username,
      managerEmail: manager.email
    }
  } 

  getTemplateInfo(template: IPerformanceReviewTaskTemplate) {
    return {
      activity: {
        title: template.title,
        createDate: dayjs(template.createDate)?.format('YYYY-MM-DD'),
        id: template.code,
        type: template.taskType     
      }
    }
  }

  getCycleInfo(cycle: IPerformanceReviewCycleConfig) {
    return {cycle: {
      cycleId: cycle.cycleConfigID,
      cycleTitle: cycle.title,
      cycleCreateDate: cycle.createDate,
      cycleType: cycle.cycleType,
      cycleStartDate: cycle.startDate,
      cycleEndDate: cycle.endDate
    }}
  }

  getCycleInfoWithExtras(cycleWithExtras: IPerformanceReviewCycleConfigWithExtras) {
    return {cycle: {
      ...this.getCycleInfo(cycleWithExtras.config).cycle,
      countEnrolled: cycleWithExtras?.enrolments?.length || 0,
      countActivities: cycleWithExtras?.taskConfigWithTemplates?.length || 0,
      cycleStatus: cycleWithExtras?.config?.status
    }}
  }

  getTaskInfo(task: ITablePerformanceReviewAssign, context = 'my tasks') {
    const user = this.userService.managedOrgUserDictionaryByOrgUserID[task?.assigneeOrgUserID];
    return {
      context: context,
      task: {
        taskId: task.taskID,
        taskTitle: task.taskTemplate?.title,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        openDate: task.openDate,
        dueDate: task.dueDate,
        taskType: task.taskTemplate?.taskType 
    }
    }
  }

  getGoalInfo(goal: ILearningGoal) {
    const user = this.userService.managedOrgUserDictionaryByUserID[goal.owner];
    return {
        goalID: goal.goalID,
        title: goal.title,
        startDate: goal.startDateLocal,
        endDate: goal.endDateLocal,
        username: user?.username,
        firstName: user?.firstName,
        lastName: user?.lastName,
        orgName: this.organisation().name,
      }
  }

  getOrgInfo() {
    if (!this.organisation()) {
      return {};
    }
    return {
      orgName: this.organisation().name,
      myOrgLink: `https://www.ausmed.com.au/my-organisation/web-overview/${this.organisation().code?.toLocaleLowerCase()}`,
      orgLogoURL: this.logoUrl()
      }


    }


  }


