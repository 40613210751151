import { flatten, uniq } from 'underscore';
/* eslint-disable no-case-declarations */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BehaviorSubject, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, skipWhile, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IFilterChange, LearningGroupType } from '../../reporting-type';
import { FilterEnables } from '../../filter-set/filter-set.component';
import { IOrgUser, USER_STATUS, UserService } from '../../../../services/user.service';
import { OrganisationService } from '../../../../services/organisation.service';
import { MandatoryTrainingService } from '../../../../services/mandatory-training.service';
import { GroupService } from '../../../../services/group.service';
import { IComplianceFramework } from '../../../settings/interfaces/IComplianceFramework';
import { clone, intersection, isArray, pluck, sortBy } from 'underscore';
import {
  SPINNER_BUTTON_STATUS_ENUM,
  SpinnerButtonService
} from '../../../../shared/components/spinner-button/spinner-button.service';
import { CATEGORY_ENUM, DATE_OPTION_ENUM } from '../interfaces';
import { CategorySelectorComponent } from '../category-selector/category-selector.component';
import { TrainingPlanSelectorComponent } from '../training-plan-selector/training-plan-selector.component';
import { RecordTypeSelectorComponent } from '../record-type-selector/record-type-selector.component';
import { FrameworkSelectorComponent } from '../framework-selector/framework-selector.component';
import { DateTypeSelectorComponent } from '../date-type-selector/date-type-selector.component';
import { DateSelectorComponent } from '../../../../shared/components/date-selector/date-selector.component';
import { MultiChooseResourceDropdownComponent } from '../../../../shared/components/multi-choose-resource-dropdown/multi-choose-resource-dropdown.component';
import * as dayjs from 'dayjs';
import { AddflowLinkService } from 'src/app/services/addflow-link.service';
import { IShortcutFilter, ShortcutService } from 'src/app/services/shortcut.service';
import { ShortcutButtonComponent } from '../shortcut-button/shortcut-button.component';
import { LearningService } from 'src/app/services/learning.service';
import { ActivatedRoute } from '@angular/router';
import { PnpService } from 'src/app/services/pnp.service';
import { ACK_STATUS } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { SpinnerButtonComponent } from '@components/spinner-button/spinner-button.component';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ComplianceRecordStatus } from 'src/app/shared/pipes/compliance-status.pipe';
import { UISpinnerComponent } from '../../../../ui/ui-spinner/ui-spinner.component';
import { SpinnerButtonComponent as SpinnerButtonComponent_1 } from '../../../../shared/components/spinner-button/spinner-button.component';

import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { FacilitiesService } from 'src/app/services/facilities.service';
import { ShortcutButtonService } from '../shortcut-button.service';
import { ShortcutButtonContainerComponent } from '../shortcut-button-new/shortcut-button-container/shortcut-button-container.component';
import { NgbDateSelectorComponent } from '@components/ngb-date-selector/ngb-date-selector.component';
import { GoalStatusSelectorComponent } from "../goal-status-selector/goal-status-selector.component";
import { GOAL_STATUS } from 'src/app/services/goal.service';

@Component({
  selector: 'app-filter-pack',
  templateUrl: './filter-pack.component.html',
  styleUrls: ['./filter-pack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CategorySelectorComponent,
    TrainingPlanSelectorComponent,
    NgTemplateOutlet,
    RecordTypeSelectorComponent,
    FrameworkSelectorComponent,
    DateTypeSelectorComponent,
    DateSelectorComponent,
    SpinnerButtonComponent_1,
    ShortcutButtonComponent,
    UISpinnerComponent,
    MultiChooseResourceDropdownComponent,
    AsyncPipe,
    UIStreamlineIconComponent,
    ShortcutButtonContainerComponent,
    NgbDateSelectorComponent,
    GoalStatusSelectorComponent
  ],
})
export class FilterPackComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(CategorySelectorComponent) categorySelector;
  @ViewChild(TrainingPlanSelectorComponent) trainingPlanSelectorComponent;
  @ViewChild(RecordTypeSelectorComponent) recordTypeSelectorComponent;
  @ViewChild(FrameworkSelectorComponent) frameworkSelectorComponent;
  @ViewChild(DateTypeSelectorComponent) dateTypeSelectorComponent;
  @ViewChild(GoalStatusSelectorComponent) goalStatusSelectorComponent;
  @ViewChild(DateSelectorComponent) dateSelectorComponent;
  @ViewChild(MultiChooseResourceDropdownComponent) multiChooseResourceDropdownComponent;
  @ViewChild(ShortcutButtonComponent) shortcutButtonComponent;
  @ViewChildren(TrainingPlanSelectorComponent) trainingPlanSelectorComponents;
  @ViewChild(SpinnerButtonComponent) spinnerButton;

  @Input() filterEnables: FilterEnables;
  @Input() label;
  @Input() isFullDateRange = true;
  @Input() maxDate;
  @Input() filterParams: { teamID?: string, jobRoleID?: string, prefill?: boolean, dateRange?: DATE_OPTION_ENUM, orgUserID?: string, recordAdded?: boolean; mandatoryType?; isLocation? };
  @Input() spinnerButtonDone: boolean;
  @Input() policies?;
  @Input() hideButtons = false;
  @Input() alwaysShowResource = false;
  @Input() altResourcePosition = false;
  @Input() altResetPosition = false;
  @Input() runReportOnInit = false;
  @Input() initMandatoryTypes = pluck(MANDATORY_TYPE_OPTIONS, 'value');
  @Input() dateFilterOptions;
  @Input() filterOrgUsers;

  @Output() filterSubmit = new EventEmitter();
  @Output() dateOptionChange: EventEmitter<string> = new EventEmitter();
  @Output() filterChange = new EventEmitter<IFilterChange>()


  SPINNER_BUTTON_STATUS_ENUM = SPINNER_BUTTON_STATUS_ENUM;
  spinnerButtonStatus: SPINNER_BUTTON_STATUS_ENUM;
  spinnerButtonInitStatus = SPINNER_BUTTON_STATUS_ENUM.before;
  destroyed$ = new Subject<void>();

  // form control options
  categoryOptions = [
    {
      label: 'User',
      value: CATEGORY_ENUM.individual,
    },
    {
      label: 'Team',
      value: CATEGORY_ENUM.teams,
    },
    {
      label: 'Job Role',
      value: CATEGORY_ENUM.jobRoles,
    }
  ];
  recordTypeOptions = [
    {
      label: 'User Documented',
      value: 'User Documented',
    },
    {
      label: 'User Completed',
      value: 'User Completed',
    },
    {
      label: 'Internal Record',
      value: 'Internal Record',
    }
  ];
  frameworkOptions = this.organisationService.getFullEnabledFrameworks().map(cf => ({
    label: cf.title,
    value: cf.value,
  }));
  dateTypeOptions = [{
    label: 'Due Date is',
    value: 'dueDate',
  },
  {
    label: 'Open Date is',
    value: 'assignDate',
  },
  ];
  planOptions;
  // policyOptions;
  // initPolicyIDs;
  mandatoryTypeOptions = MANDATORY_TYPE_OPTIONS;
  assignStatusOptions: { label: string, value: ComplianceRecordStatus }[] = [
    { label: 'Open', value: 'Assigned' },
    { label: 'Missed', value: 'Incomplete' },
    { label: 'Overdue', value: 'Overdue' },
    { label: 'Completed', value: 'Completed' },
  ];

  // Optional
  // Currently only compliance-workroom pass in a special displayStatusDict
  @Input()
  displayStatusDict = DEFAULT_BUTTON_STATUS_DICT;
  initRawForm;
  submitted = false;
  hideResourceFilter = true;
  allResourcesDict;
  allResourcesDict$ = new BehaviorSubject(null);
  resetSpinnerButtonSubject: Subject<void | 'default'> = new Subject<void | 'default'>();
  enabledFrameworks: IComplianceFramework[];
  initPlanIDs;
  users = [];
  filterForm: UntypedFormGroup;
  initFilterBy;
  initItems;
  initUserStatuses = [USER_STATUS.active];

  initDateRange;
  initStartDate;
  initEndDate;
  initResources;
  initDateType;

  activeShortcut$ = this.shortcutService.getFilteredActiveShortcut();

  dateOption$ = this.shortcutButtonService.dateOption$;
  policyOptions: any;
  ACK_STATUS_OPTIONS = [
    { label: ACK_STATUS.acknowledged, value: ACK_STATUS.acknowledged },
    { label: ACK_STATUS.unacknowledged, value: ACK_STATUS.unacknowledged },
    { label: ACK_STATUS.discarded, value: ACK_STATUS.discarded }
  ];
  POLICY_STATUS_OPTIONS = [
    { label: 'Published', value: 'Published' },
    { label: 'Archived', value: 'Archived' },
    // {label: 'Draft', value: 'Draft'},
  ];
  ACK_COMPLIANCE_STATUS_OPTIONS = [
    { label: 'Compliant', value: 'compliant' },
    { label: 'Non-compliant', value: 'nonCompliant' }
  ];
  initAckStatuses = pluck(this.ACK_STATUS_OPTIONS.slice(0, 2), 'value');
  relevantPolicies$: any; // emits relevant policy options for policy filter when filtered policy status changes
  preFilters: Partial<IShortcutFilter> = {};
  isApplyingSavedDate: boolean;
  activeShortcutFilter: IShortcutFilter;

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private groupService: GroupService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private mandatoryTrainingService: MandatoryTrainingService,
    private spinnerButtonService: SpinnerButtonService,
    private addFlowLinkService: AddflowLinkService,
    private shortcutService: ShortcutService,
    private learningService: LearningService,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private facilitiesService: FacilitiesService,
    private shortcutButtonService: ShortcutButtonService
  ) {
    // this.activeShortcut$.subscribe(() => this.ngOnInit())
    learningService.recordDelete$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.submitFilter();
    });
    this.route.queryParams.pipe(
      take(1),
      filter(params => params?.prefill),
      takeUntil(this.destroyed$)).subscribe(() => {
        this.runReportOnInit = true;
      });
  }

  ngOnInit(): void {
    this.shortcutButtonService.initiate(this.filterEnables);
    if (this.filterEnables.policy) {
      this.initPolicyOptions();
    }

    if (this.filterParams?.prefill) {
      this.runReportOnInit = true;
    }
    if (this.filterParams?.dateRange && this.filterParams?.dateRange) {
      // TODO: extract date processor function
      this.initDateRange = this.filterParams?.dateRange;
      let startDate, endDate;
      switch (this.initDateRange) {
        case DATE_OPTION_ENUM.thisWeek: // this week
          startDate = dayjs().startOf('week').format('YYYY-MM-DD');
          if (this.isFullDateRange) {
            // start of this week (Sun) to end of this week (Sat)
            endDate = dayjs().endOf('week').format('YYYY-MM-DD');
          } else {
            // start of this week to today
            endDate = dayjs().format('YYYY-MM-DD');
          }
          break;
        case DATE_OPTION_ENUM.lastWeek: // last week
          startDate = dayjs().startOf('week').subtract(1, 'week').format('YYYY-MM-DD');
          // start of last week to end of last week
          endDate = dayjs().endOf('week').subtract(1, 'week').format('YYYY-MM-DD');
          break;
        case DATE_OPTION_ENUM.thisMonth: // this month
          startDate = dayjs().startOf('month').format('YYYY-MM-DD');
          if (this.isFullDateRange) {
            endDate = dayjs().endOf('month').format('YYYY-MM-DD');
          } else {
            // start of this month to today
            endDate = dayjs().format('YYYY-MM-DD');
          }
          break;
        case DATE_OPTION_ENUM.lastMonth: // last month
          startDate = dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
          endDate = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
          break;
        case DATE_OPTION_ENUM.thisYear: // this year
          startDate = dayjs().startOf('year').format('YYYY-MM-DD');
          if (this.isFullDateRange) {
            endDate = dayjs().endOf('year').format('YYYY-MM-DD');
          } else {
            // start of this year to today
            endDate = dayjs().format('YYYY-MM-DD');
          }
          break;
        case DATE_OPTION_ENUM.lastYear: // last year
          startDate = dayjs().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
          endDate = dayjs().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
          break;
        case DATE_OPTION_ENUM.allTime: // last year
          startDate = '2017-01-01';
          endDate = dayjs().format('YYYY-MM-DD');
          break;
      }
      this.initStartDate = startDate;
      this.initEndDate = endDate;
      this.runReportOnInit = true;
    }
    if (this.filterParams?.teamID) {
      this.initFilterBy = this.filterParams?.isLocation === 'true' ? CATEGORY_ENUM.facilities : LearningGroupType.team;
      this.initItems = [this.filterParams.teamID];
      this.runReportOnInit = true;
    }
    if (this.filterParams?.jobRoleID) {
      this.initFilterBy = LearningGroupType.jobRole;
      this.initItems = [this.filterParams.jobRoleID];
      this.runReportOnInit = true;
    }
    if (this.filterParams?.orgUserID) {
      this.initFilterBy = LearningGroupType.individual;
      this.initItems = Array.isArray(this.filterParams.orgUserID) ? this.filterParams.orgUserID : [this.filterParams.orgUserID];
      this.initUserStatuses = Object.values(USER_STATUS);
      this.runReportOnInit = true;
    }

    if (this.filterParams?.mandatoryType) {
      this.initMandatoryTypes = [this.filterParams.mandatoryType]
    }

    // prefill filters
    if (this.filterParams?.recordAdded) {
      const storedRecords = this.addFlowLinkService.getRecordFilters();
      if (storedRecords) {
        // const storedRecords = this.addFlowLinkService.useRecord('learning');

        this.initFilterBy = LearningGroupType.individual;

        this.initItems = storedRecords.orgUserIDs;
        this.initResources = storedRecords.resourceIDs;

        this.initDateRange = DATE_OPTION_ENUM.custom;
        this.initStartDate = storedRecords.dates[0];
        this.initEndDate = storedRecords.dates[1];

        this.hideResourceFilter = this.initResources?.length ? false : true;
        this.runReportOnInit = true;
        this.initUserStatuses = storedRecords.userStatuses || Object.values(USER_STATUS);
        if (storedRecords.planIDs) {
          this.initPlanIDs = storedRecords.planIDs;
        }
      }
    }

    // update alert based on spinner button status
    this.spinnerButtonService.statusUpdate$.pipe(
      takeUntil(this.destroyed$),
      filter(s => s !== undefined),
    ).subscribe((status) => {
      this.spinnerButtonStatus = status;
      this.shortcutButtonService.spinnerButtonStatus = status;
      this.cdr.detectChanges();
    });

    const users$ = this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'));
    users$.pipe(
      take(1),
      tap((users) => {
        this.users = [...users.active, ...users.invited, ...users.pending, ...users.inActive, ...users.rejected];
        this.users.forEach(user => {
          user['activeJobRoleTakens'] = this.groupService.getCurrentJobRoleTakens(user);
        });
      }),
      switchMap(() => {
        if (this.filterEnables.trainingPlan) {
          return this.mandatoryTrainingService.getPlans(localStorage.getItem('orgID')).pipe(
            tap(plans => {
              const activePlans = plans.filter(plan => plan.status === 'Active' && !(plan.endDate && new Date(plan.endDateUTC) < new Date()));
              this.planOptions = activePlans.map(plan => ({
                label: plan.title,
                value: plan.mtPlanID,
              }));
              this.filterEnables?.withoutManualAssign ? null : this.planOptions.unshift({ value: 'manual', label: 'All Manually Assigned' });
              this.initPlanIDs = this.initPlanIDs
                || (this.filterEnables?.withoutManualAssign ? activePlans.map(p => p.mtPlanID) : [...activePlans.map(p => p.mtPlanID), 'manual']);
            }),
            map(() => clone(this.initPlanIDs)),
          );
        } else {
          return of(null);
        }
      }),
      switchMap((activePlanIDs) => {
        return this.activeShortcut$.pipe(
          tap(s => {
            if (s?.shortcutID) {
              this.shortcutService.updateLastOpened(s.shortcutID).subscribe();
            }
          }),
          map(s => s?.filters),
          map(savedFilters => {
            // remove deleted plans from saved filter
            if (savedFilters?.planIDs && activePlanIDs) {
              return {
                ...savedFilters,
                planIDs: savedFilters.planIDs.filter(planID => activePlanIDs.includes(planID) || planID === 'manual')
              };
            } else {
              return savedFilters;
            }
          }),
          takeUntil(this.destroyed$));
      }),
      distinctUntilChanged(),
      tap((shortcutFilters) => {
        this.activeShortcutFilter = shortcutFilters;

        // if changing filter while loading the report, refresh page
        // if (shortcutFilters && this.spinnerButtonStatus === SPINNER_BUTTON_STATUS_ENUM.processing) {
        //   window.location.reload();
        //   return;
        // }

        if (this.filterForm) {
          this.useShortcutValues(shortcutFilters);
          return;
        }

        this.applyShortcutToInit(shortcutFilters);
        if (!shortcutFilters && this.filterForm) { // None shortcut button clicked
          this.resetToDefault();
          return;
        }

        this.filterForm = this.fb.group({
          resources: [this.initResources],
          userStatus: [this.initUserStatuses, Validators.required],
          // includeInactive: [false], // for Include Inactive Users checkbox
          // includeInvitation: [false], // for Include Invited and Pending User checkbox
          filterBy: [this.initFilterBy || this.categoryOptions[0]?.value, Validators.required],   // default option: all users
          // items: [this.initItems || ['allUsers'], Validators.required],
          items: [this.initItems || [], Validators.required],
          resourceTypes: [shortcutFilters?.resourceTypes || this.recordTypeOptions.map(o => o.value), Validators.required],
          framework: [shortcutFilters?.framework || this.frameworkOptions[0]?.value, Validators.required],
          planIDs: [this.initPlanIDs, Validators.required],
          mandatoryType: [this.initMandatoryTypes, Validators.required],
          dateType: [(this.initDateType || (this.filterEnables.payrollDate ? 'completionDate' : this.dateTypeOptions[0]?.value)), Validators.required],
          policyIDs: [this.initPolicyIDs || [], Validators.required],
          ackStatuses: [clone(this.initAckStatuses) || [], Validators.required],
          policyStatuses: [pluck(this.POLICY_STATUS_OPTIONS, 'value') || [], Validators.required],
          startDate: [this.initStartDate || dayjs().startOf('month').format('YYYY-MM-DD')],
          endDate: [this.initEndDate || dayjs().endOf('month').format('YYYY-MM-DD')],
          ackComplianceStatus: [this.initAckComplianceStatuses, Validators.required],
          assignStatus: [pluck(this.assignStatusOptions, 'value'), Validators.required],
          stateDate: [shortcutFilters?.stateDate || dayjs().format('YYYY-MM-DD'), Validators.required],
          goalStatus: [GOAL_STATUS.inProgress, Validators.required]
        });
        this.shortcutButtonService.filterForm = this.filterForm

        if (!this.filterEnables.goalStatus) {
          this.filterForm.removeControl('goalStatus');
        }
        if (!this.filterEnables.resourceType) {
          this.filterForm.removeControl('resourceTypes');
        }
        if (!this.filterEnables.framework) {
          this.filterForm.removeControl('framework');
        }
        if (!this.filterEnables.selectItem) {
          this.filterForm.removeControl('items');
        }
        if (!this.filterEnables.trainingPlan) {
          this.filterForm.removeControl('planIDs');
        }
        if (!this.filterEnables.policy) {
          this.filterForm.removeControl('policyIDs');
          this.filterForm.removeControl('ackStatuses');
          this.filterForm.removeControl('policyStatuses');
        }
        if (!this.filterEnables.ackComplianceStatus) {
          this.filterForm.removeControl('ackComplianceStatus')
        }
        if (!this.filterEnables.dateRange) {
          this.filterForm.removeControl('startDate');
          this.filterForm.removeControl('endDate');
        }
        if (!this.filterEnables.dateType) {
          this.filterForm.removeControl('dateType');
        }
        if (!this.filterEnables.mandatoryType) {
          this.filterForm.removeControl('mandatoryType');
        }
        if (!this.filterEnables.assignStatus) {
          this.filterForm.removeControl('assignStatus');
        }

        this.initRawForm = this.filterForm.getRawValue();

        if (this.initFilterBy) {
          this.filterForm.get('filterBy').patchValue(this.initFilterBy);
        }
        if (this.initItems) {
          this.filterForm.get('items').patchValue(this.initItems);
        }

        if (this.dateSelectorComponent?.selectedDate) { // for when using date from shortcut
          const selDate = this.dateSelectorComponent.DATE_OPTION_DICT[this.dateSelectorComponent?.selectedDate];
          this.filterForm.patchValue({
            startDate: selDate?.startDate?.value || this.initStartDate,
            endDate: selDate?.endDate?.value || this.initEndDate,
          });
        }


        this.relevantPolicies$ = this.filterForm?.get('policyStatuses')?.valueChanges.pipe(
          startWith(pluck(this.POLICY_STATUS_OPTIONS, 'value')),
          map(() => this.policyOptions?.filter(pol => this.filterForm.get('policyStatuses').value.includes(pol.status))),
          takeUntil(this.destroyed$),
        );

        this.patchWithShortcut(shortcutFilters);
        setTimeout(() => {
          if (this.runReportOnInit) {
            this.submitFilter();
            this.runReportOnInit = false;
          }
        }, 0);
        this.cdr.markForCheck();
      }),
      // reset spinner button if form value changes
      switchMap(() => this.filterForm.valueChanges.pipe(
        tap((c) => {
          if (JSON.stringify(this.filterForm.getRawValue()) === JSON.stringify(this.initRawForm)) {
            // reset to default
            this.resetSpinnerButton(true);
          } else {
            this.resetSpinnerButton();
          }
          this.submitted = false;
          this.shortcutButtonService.filterChanged = true;
          this.cdr.markForCheck();
          this.filterChange.emit(c);
        }), // side effect: make init status 'reset'
      ))
    ).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit() {
  }

  resetSpinnerButton(isDefault = false) {
    if (isDefault) {
      this.resetSpinnerButtonSubject.next('default');
      return;
    }
    this.resetSpinnerButtonSubject.next();
  }

  submitFilter() {
    this.submitted = true;
    if (this.filterForm.valid) {
      if (this.filterForm.value.resources) {
        this.allResourcesDict$.pipe(skipWhile(v => !v), take(1)).subscribe(() => this.applyFilters());
      } else {
        this.applyFilters();

      }
    }
  }

  applyFilters() {
    let userIDs = {
      active: [],
      inactive: [],
      pending: [],
      invited: [],
    };
    let staffIDs = [],
      filteredOrgUserIDs = [];
    // console.log('raw filter form: 🐷', this.filterForm);
    if (this.filterForm.valid) {
      // items: teamIDs, jobRoleIDs, orgUserIDs
      let { items, filterBy } = this.filterForm.value;
      // console.log('filtered items',items)
      const { userStatus } = this.filterForm.value;
      const resources = this.filterForm.value.resources ? this.filterForm.value.resources
        .map(resourceID => this.allResourcesDict[resourceID]) : [];

      const policyNames = this.filterForm.value.policyIDs?.map(policyID => this.policyOptions.find(p => p.value === policyID));
      let policies;
      if (policyNames?.length) {
        policies = {
          policyIDs: this.filterForm.value.policyIDs,
          policyGovDisplay: policyNames.length === 1 ?
            policyNames[0] :
            policyNames.length === this.policyOptions.length ?
              'All Policies' :
              policyNames.length + ' Policies'
        };
      }
      const allUsers = this._getFilteredUsersByStatusInForm(this.users, userStatus);
      switch (this.filterForm.value.filterBy) {
        case CATEGORY_ENUM.jobRoles:
          const jobRoleUsers = this._getFilteredUsersByStatusInForm(this.users.filter(u => u.jobRoleTakens &&
            (intersection(u.activeJobRoleTakens.map(t => t.jobRole), items).length)), userStatus);
          userIDs = this._getClassifiedUserIDs(jobRoleUsers);
          staffIDs = jobRoleUsers.filter(u => u.staffID).map(u => u.staffID);
          filteredOrgUserIDs = jobRoleUsers.map(u => u.orgUserID);
          break;
        case CATEGORY_ENUM.jobRoleTypes:
          const filteredJobRoles = pluck(this.groupService.getJobRoleByTypes(items), 'jobRoleID');
          const jobTypeUsers = this._getFilteredUsersByStatusInForm(this.users.filter(u => u.jobRoleTakens &&
            (intersection(u.activeJobRoleTakens.map(t => t.jobRole), filteredJobRoles).length)), userStatus);
          userIDs = this._getClassifiedUserIDs(jobTypeUsers);
          staffIDs = jobTypeUsers.filter(u => u.staffID).map(u => u.staffID);
          filteredOrgUserIDs = jobTypeUsers.map(u => u.orgUserID);
          break;
        case CATEGORY_ENUM.teams:
          const teamUsers = this._getFilteredUsersByStatusInForm(this.users
            .filter(u => u.teams && intersection(u.teams, items).length), userStatus);
          userIDs = this._getClassifiedUserIDs(teamUsers);
          staffIDs = teamUsers.filter(u => u.staffID).map(u => u.staffID);
          filteredOrgUserIDs = teamUsers.map(u => u.orgUserID);
          // console.log('🍣 users', teamUsers);
          // console.log('🍠 userIDs: ', userIDs);
          break;
        case CATEGORY_ENUM.directManagers:
          // console.log('this.users',this.users)
          const managersUsers = this._getFilteredUsersByStatusInForm(this.users
            .filter(u => u.hierarchyTeamModel?.teamID && intersection([u.hierarchyTeamModel?.teamID], items).length), userStatus);
          userIDs = this._getClassifiedUserIDs(managersUsers);
          staffIDs = managersUsers.filter(u => u.staffID).map(u => u.staffID);
          filteredOrgUserIDs = managersUsers.map(u => u.orgUserID);
          // console.log('🍣 users', managersUsers);
          // console.log('🍠 userIDs: ', userIDs);
          break;
        case CATEGORY_ENUM.facilities:
          const facilityOrgUsersIDs = uniq(items.map(facilityID => this.facilitiesService.globalOrgFacilities[facilityID]?.allMembers).flat());
          const facilityUsers  = this._getFilteredUsersByStatusInForm(
            facilityOrgUsersIDs.map(orgUserID => {
              const user = this.users.find(u => u.orgUserID === orgUserID);
              if (!user) {
                console.log(orgUserID)
              }
              return user;
            }).filter(u => u),
            userStatus);
          userIDs = this._getClassifiedUserIDs(facilityUsers);
          staffIDs = facilityUsers.filter(u => u.staffID).map(u => u.staffID);
          filteredOrgUserIDs = facilityUsers.map(u => u.orgUserID);
          // console.log('🍣 facilityUsers', facilityUsers);
          // console.log('🍠 userIDs: ', userIDs);
          break;
        case CATEGORY_ENUM.individual:
          // all users
          if (items?.length === allUsers.length) {
            userIDs = this._getClassifiedUserIDs(allUsers);
            items = allUsers.map(u => u.orgUserID);
            filterBy = LearningGroupType.allUsers;
            staffIDs = allUsers.filter(u => u.staffID).map(u => u.staffID);
            filteredOrgUserIDs = allUsers.map(u => u.orgUserID);
            break;
          }
          // individual user
          // const selectedUsers = this.users.find(u => u.orgUserID === items);
          const selectedUsers = (Array.isArray(items) ? items : [items]).map(i => this.users.find(u => u.orgUserID === i)).filter(u => u);
          userIDs = this._getClassifiedUserIDs(selectedUsers);
          staffIDs = pluck(selectedUsers, 'staffID').filter(u => u);
          filteredOrgUserIDs = pluck(selectedUsers, 'orgUserID').filter(u => u);
          // console.log('🍣 ', user);
          // console.log('🍠 userIDs: ', userIDs);
          break;
        default:
          break;
      }

      const allUserIDs = this._getClassifiedUserIDs(allUsers);

      const data: IFilterChange = {
        itemIDs: isArray(items) ? items : [items],
        filteredOrgUserIDs,
        filterBy,
        allUserIDs,
        uIDs: userIDs, // processed userIDs
        staffIDs,
        resources,
        resourceTypes: this.filterForm.value.resourceTypes,
        framework: this.filterForm.value.framework,
        planIDs: this.filterForm.value.planIDs,
        dateType: this.filterForm.value.dateType,
        goalStatus: this.filterForm.value.goalStatus,
        startDate: this.filterForm.value.startDate,
        endDate: this.filterForm.value.endDate,
        policies,
        ackStatuses: this.filterForm.value.ackStatuses,
        mandatoryType: this.filterForm.value.mandatoryType,
        ackComplianceStatus: this.filterForm.value.ackComplianceStatus,
        assignStatus: this.filterForm.value.assignStatus,
        combinedUIDs: Object.values(userIDs).flat(),
        stateDate: this.filterForm.value.stateDate
      };
      // removeEmptyFields(data);
      // console.log('filterForm 🐸', this.filterForm.value);
      // console.log('emitted filter values', data);
      this.spinnerButtonInitStatus = SPINNER_BUTTON_STATUS_ENUM.processing;
      this.filterSubmit.emit(data);
    }

  }

  onDateRangeChanged($event) {
    if ($event) {
      this.filterForm.patchValue({
        startDate: $event[0],
        endDate: $event[1],
      }, { emitEvent: !this.isApplyingSavedDate });
      this.isApplyingSavedDate = false;
    }
  }

  // send date option to use for in data gov
  onDateOptionChanged(date) {
    this.dateOptionChange.emit(date);
    this.dateOption$.next(date);
  }

  setAllResourceDict(event) {
    this.allResourcesDict = event;
    this.allResourcesDict$.next(event);
  }

  resetToDefault() {
    if (this.spinnerButtonStatus === SPINNER_BUTTON_STATUS_ENUM.processing) {
      return;
    }
    this.categorySelector.reset();
    // if (this.trainingPlanSelectorComponent) {
    //   this.trainingPlanSelectorComponent.reset();
    // }
    this.trainingPlanSelectorComponents?.forEach(c => c.reset());
    if (this.recordTypeSelectorComponent) {
      this.recordTypeSelectorComponent.reset();
    }
    if (this.frameworkSelectorComponent) {
      this.frameworkSelectorComponent.reset();
    }
    if (this.dateTypeSelectorComponent) {
      this.dateTypeSelectorComponent.reset();
    }
    if (this.goalStatusSelectorComponent) {
      this.goalStatusSelectorComponent.reset();
    }
    if (this.dateSelectorComponent) {
      this.dateSelectorComponent.reset(true);
    }
    if (this.multiChooseResourceDropdownComponent) {
      this.multiChooseResourceDropdownComponent.reset();
    }

    this.shortcutService.setActiveShortcut(null);

    this.broadcastService.broadcast('filter-pack-reset');
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  private _getFilteredUsersByStatusInForm(enabledUsers: IOrgUser[], statuses: USER_STATUS[]): IOrgUser[] {
    return enabledUsers.filter(u => statuses.includes(u.status));
  }

  private _getClassifiedUserIDs(filteredUsers = []): {
    active: string[];
    inactive: string[],
    pending: string[];
    invited: string[]
  } {
    return ({
      active: filteredUsers.filter(u => u.status === USER_STATUS.active).filter(u => u.userID).map(u => u.userID),
      inactive: filteredUsers.filter(u => u.status === USER_STATUS.inactive).filter(u => u.userID).map(u => u.userID),
      pending: filteredUsers.filter(u => u.status === USER_STATUS.pending).filter(u => u.userID).map(u => u.userID),
      invited: filteredUsers.filter(u => u.status === USER_STATUS.invited).filter(u => u.userID).map(u => u.userID),
    });
  }

  applyShortcutToInit(filters: IShortcutFilter) {
    if (!filters) {
      return;
    }
    this.initFilterBy = filters.filterBy;
    this.initItems = filters.items;
    this.initPlanIDs = filters.planIDs || this.initPlanIDs;
    this.initResources = filters.resources || this.initResources;
    this.initUserStatuses = filters.userStatus;
    this.initDateType = filters.dateType;
    this.initMandatoryTypes = filters.mandatoryType;


    if (filters.dates?.dateOption) {
      if (filters.dates.dateOption?.toLowerCase()?.includes('custom')) {
        this.initDateRange = DATE_OPTION_ENUM.custom;
        this.initStartDate = filters.startDate;
        this.initEndDate = filters.endDate;
        this.dateSelectorComponent?.updateCustomDisplay(this.initStartDate, this.initEndDate);
        this.dateOption$.next(DATE_OPTION_ENUM.custom);
      } else {
        this.initDateRange = filters.dates.dateOption;
        this.dateSelectorComponent?.chooseDate(this.initDateRange);
      }
    }

    if (this.initResources?.length) {
      this.hideResourceFilter = false;
    }
    setTimeout(() => {
      this.shortcutButtonService.filterChanged = false;
    }, 0);
  }

  // process policies into selector options
  // Don't show draft policies
  initPolicyOptions() {
    let options = this.policies.map(p => {
      return {
        ...p,
        status: p.governanceVersion?.status
      };
    });
    options = sortBy(sortBy(options, 'title').reverse(), 'status').reverse()
      .filter(p => p.status !== 'Draft');
    this.policyOptions = options.map(pol => {
      return {
        label: pol.title,
        value: pol.governanceID,
        status: pol.status
      };
    });
  }

  //TODO @ak: Handle if policy has changed status
  patchWithShortcut(filters: IShortcutFilter) {
    if (!filters) {
      return;
    }
    const attr = ['policyIDs', 'policyStatuses', 'ackStatuses', 'ackComplianceStatus', 'assignStatus'];
    attr.forEach(el => {
      if (filters[el]) {
        this.preFilters[el] = filters[el];
      }
    });
  }

  get initPolicyIDs() {
    return this.policies ? pluck(this.policyOptions, 'value') : [];
  }

  get initAckComplianceStatuses() {
    return pluck(this.ACK_COMPLIANCE_STATUS_OPTIONS, 'value');
  }

  useShortcutValues(filters: IShortcutFilter, runReport = true) {
    // console.log('filters: ' + JSON.stringify(filters));
    // console.log('controls: ' + JSON.stringify(this.filterForm.value));
    if (!filters) {
      // this.resetToDefault();
      return;
    }
    if (filters) {
      this.shortcutButtonService.filterChanged = false;
      console.log('patching');
      this.filterForm.patchValue(filters, { emitEvent: false });
      this.broadcastService.broadcast('saved-filter-applied');
      this.resetSpinnerButton();
    }
    if (filters.dates?.dateOption) {
      this.isApplyingSavedDate = true;
      if (filters.dates.dateOption?.toLowerCase()?.includes('custom')) {
        // this.initDateRange = DATE_OPTION_ENUM.custom;
        const [start, end] = [filters.startDate, filters.endDate];
        // this.initStartDate = filters.startDate;
        // this.initEndDate = filters.endDate;
        this.dateSelectorComponent?.updateCustomDisplay(start, end);
        this.dateSelectorComponent?.datePickerChanged([start, end]);
        this.dateOption$.next(DATE_OPTION_ENUM.custom);
      } else {
        // this.initDateRange = filters.dates.dateOption;
        this.dateSelectorComponent?.chooseDate(filters.dates.dateOption);
      }
    }
    if (runReport) {
      this.spinnerButton.startProcessing();
    }

  }

  discardShortcut() {
    this.useShortcutValues(this.activeShortcutFilter, false);
  }

}

export const MANDATORY_TYPE_OPTIONS = [{
  label: 'Mandatory (Compliance)', value: 'Compliance'
}, {
  label: 'Mandatory (Non-Compliance)', value: 'Mandatory'
}, {
  label: 'Optional (Non-Compliance)', value: 'Optional'
}]

export const DEFAULT_BUTTON_STATUS_DICT = {
  before: { text: 'Run Report' },
  processing: { text: 'Running...', icon: true },
  after: { text: 'Done', icon: true, btnClass: 'btn-success' },
  reset: { text: 'Run Report Again' },
  resetToDefault: { text: 'Run Report Again' },
};
