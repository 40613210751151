import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flag.service';

/**
 * render component if
 * featureA is true: *featureFlag="featureA"
 * featureA, B, AND C are true: *featureFlag="[featureA, featureB, featureC]"
 * featureA is true AND featureB is false: *featureFlag="[featureA] not featureB"
 * featureA is false: *featureFlag="[] not featureA"
 *
 * if render component based on contract type, use
 * eg. this.organisationService.isContractType(PRODUCT_TYPES.HIPPOTLIAN)
 *
 * check if feature is on in script:
 * eg. this.featureService.featureOn(FEATURES.requireUserID);
 *
 * check if feature is off in script:
 * eg. !this.featureService.featureOn(FEATURES.requireUserID);
 *
 */
@Directive({
    selector: '[featureFlag]',
    standalone: true
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: string | string[];
  @Input() featureFlagOr = '';
  @Input() featureFlagNot = '';

  private _hasView = false;

  constructor(
      private featureFlagService: FeatureFlagService,
      private _templateRef: TemplateRef<any>,
      private _viewContainer: ViewContainerRef,
  ) {
  }

  ngOnInit() {
    const isFeatureOn = (this.featureFlagService.featureOn(this.featureFlag) || this.featureFlagService.featureOn(this.featureFlagOr))
        && !this.featureFlagService.featureOn(this.featureFlagNot);
    if (isFeatureOn && !this._hasView) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      return;
    } else if (!isFeatureOn && this._hasView) {
      this._viewContainer.clear();
    }

  }

}
