import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { TableColumnValuePrepareAndSortUtilsService } from 'src/app/services/table-column-value-prepare-and-sort-utils.service';
import { clone } from 'underscore';
import { AccountStatusPipe } from '../../pipes/account-status.pipe';
import { JobRoleTitlesCellComponent } from '../learning-table-popovers/jobrole-titles-cell.component';
import { TeamNamesCellComponent } from '../learning-table-popovers/team-names-cell.component';
import { StaffLinkDisplayComponent } from '../staff-link-display/staff-link-display.component';
import { StaffStatusDisplayComponent } from '../staff-status-display/staff-status-display.component';
import { SmartTableService, IMultiSelectData, IShownItems } from 'src/app/services/smart-table.service';

import { TruncatedCellComponent } from '../truncated-cell/truncated-cell.component';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Ng2SmartTableComponent } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.component';
import { Ng2SmartTableComponent as Ng2SmartTableComponent_1 } from '../../../shared-modules/ng2-smart-table/ng2-smart-table.component';
import { MultiSelectContainerComponent } from '../multi-select-container/multi-select-container.component';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { IDownloadCSVExtensibleConfig, downloadCSVBySourceAndSettings } from '../../utils/export-csv';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'select-users-table',
    templateUrl: 'select-users-table.component.html',
    styleUrls: ['./select-users-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MultiSelectContainerComponent, Ng2SmartTableComponent_1],
})

export class SelectUsersTableComponent implements OnInit, AfterViewChecked, OnDestroy {


    @Input() users;
    @Input() selectedUsers = [];
    @Input() customisedMessage = '';
    noDataMessage = 'Loading...';
    @ViewChild('tableUsers') table: Ng2SmartTableComponent;
    @Output() userRowSelect = new EventEmitter();
    @Output() numFilteredElements = new EventEmitter();
    destroyed$ = new Subject<void>();
    selectedItems: any[] = [];
    shownItems: IShownItems = {
        showingMinus: false,
        showClearSelection: false
    };
    multiSelectData: IMultiSelectData;

    userTableSource: LocalDataSource = new LocalDataSource();
    userTableSettings = {
        actions: {
            add: false,
            edit: false,
            delete: false,
        },
        noDataMessage: 'No data to display',
        selectMode: 'multi',
        pager: {
            display: true,
            number: 10,
        },
        columns: {
            displayName: {
                title: 'User',
                type: 'custom',
                renderComponent: StaffLinkDisplayComponent,
                onComponentInitFunction(instance) {
                    instance.className = 'userna';
                    instance.maxWidth = 216;
                    instance.openNewTab = true;
                },
                compareFunction: this._columnUtil.compareByProperty('fullName'),
                filterFunction: this._columnUtil.searchByProperty('fullName'),
                width: '216px',
            },
            staffID: {
                title: 'User ID',
                compareFunction: this._columnUtil.generalCompare(),
                valuePrepareFunction: this._columnUtil.replaceNoRecord(),
                type: 'custom',
                renderComponent: TruncatedCellComponent,
                onComponentInitFunction(instance) {
                    instance.className = 'staffID';
                },
            },
            teamNames: {
                title: 'Teams',
                type: 'custom',
                renderComponent: TeamNamesCellComponent,
                valuePrepareFunction: this._columnUtil.extractByProperty('name'),
                filterFunction: this._columnUtil.searchByProperty('name'),
                sort: false
            },
            ...this.columnService.getColumns(['facilityModels'], {local: true}),
            jobRoles: {
                title: 'Job Roles',
                type: 'custom',
                renderComponent: JobRoleTitlesCellComponent,
                valuePrepareFunction: this._columnUtil.extractByProperty('title'),
                filterFunction: this._columnUtil.searchByProperty('title'),
                sort: false
            },
            status: {
                title: 'Account Status',
                type: 'custom',
                valuePrepareFunction: (val) => this.accountStatusPipe.transform(val),
                filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
                compareFunction: this._columnUtil.generalCompareWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
                renderComponent: StaffStatusDisplayComponent,
            },
        },
    };
    constructor(
        private cdRef: ChangeDetectorRef,
        private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
        private accountStatusPipe: AccountStatusPipe,
        private smartTableService: SmartTableService,
        private columnService: SmartTableColumnsService,
        private _toaster: ToastrService,
    ) {
    }

    ngOnInit() {
        if (this.users.length === 0) {
            this.noDataMessage = this.customisedMessage ? this.customisedMessage : 'No data to display';
            this.userTableSettings.noDataMessage = this.noDataMessage;
        } else {
            this.noDataMessage = '';
            this.userTableSource.load(this.users);
        }
        this.cdRef.detectChanges();

        this.multiSelectData = {
            availableItems: this.users.length,
            shownItems: this.shownItems,
            table: this.table,
            keyName: 'orgUserID',
            tableRef: '#select-users'
        };
        if (this.selectedUsers.length > 0) {
            this.selectedItems = this.selectedUsers;
            this.smartTableService.syncTable(this.selectedItems, this.multiSelectData);
        }

        this.userTableSource.onChanged().pipe(
            filter(change => change.action === 'filter'),
            takeUntil(this.destroyed$)
        ).subscribe(c => this.numFilteredElements.emit(c.elements.length));
    }

    ngAfterViewChecked() {
        this.smartTableService.syncTable(this.selectedItems, this.multiSelectData);
        this.cdRef.detectChanges();
    }


    onRowSelect(event) {
        this.selectedItems = this.smartTableService.onUserRowSelect(event, this.selectedItems, {
            availableItems: this.users,
            shownItems: this.shownItems,
            table: this.table,
            keyName: 'orgUserID',
            tableRef: '#select-users'
        });
        this.userRowSelect.emit(this.selectedItems);
    }

    onSelectAll() {
        this.userTableSource.getFilteredAndSorted().then(source => {
            this.selectedItems = clone(source);
            this.smartTableService.onSelectAll(this.multiSelectData);
            this.userRowSelect.emit(this.selectedItems);
        });
    }

    onDeselectAll() {
        this.selectedItems = [];
        this.smartTableService.onDeselectAll(this.multiSelectData);
        this.userRowSelect.emit(this.selectedItems);
    }

    ngOnDestroy() {
        this.destroyed$.next();
    }

    downloadUnenrolledUsers(){
      // const extractFieldKey: IDownloadExtractFieldKey[] = [{ field: 'displayName', fieldKey: 'fullName' }, { field: 'permissionType', fieldKey: 'permissionDisplayText' }];
      const csvConfig: IDownloadCSVExtensibleConfig = {
        source: this.userTableSource,
        settings: this.userTableSettings,
        csvTitle: 'Unenrolled active users',
        csvFileName: `Unenrolled active users.csv`,
      };
      downloadCSVBySourceAndSettings(csvConfig);
      this._toaster.success('Downloaded file');
    }
}
