import { RESOURCE_TYPE_DISPLAY_ENUM } from '../byo-shared/byo-resource-types';

export function getResourceDefaultImg(resourceType?: RESOURCE_TYPE_DISPLAY_ENUM | string) {
  const pathPrefix = 'assets/images/resource-default-images/';
  let imgPath = pathPrefix;
  if (resourceType) {
    switch (resourceType) {
      case RESOURCE_TYPE_DISPLAY_ENUM.article:
        imgPath += 'article.svg';
        break;
      case RESOURCE_TYPE_DISPLAY_ENUM.course:
        imgPath += 'course.svg';
        break;
      case RESOURCE_TYPE_DISPLAY_ENUM.file:
        imgPath += 'file.svg';
        break;
      case RESOURCE_TYPE_DISPLAY_ENUM.weblink:
        imgPath += 'link.svg';
        break;
      case RESOURCE_TYPE_DISPLAY_ENUM.practicalActivity:
        imgPath += 'practical.svg';
        break;
      case RESOURCE_TYPE_DISPLAY_ENUM.scorm:
        imgPath += 'scorm.svg';
        break;
      case RESOURCE_TYPE_DISPLAY_ENUM.video:
        imgPath += 'video.svg';
        break;
      default:
        imgPath += 'default-thumbnail.svg';
        break;
    }
  } else {
    imgPath += 'default-thumbnail.svg';
  }
  return imgPath;
}
