import { Component, OnInit } from '@angular/core';
import { LocalDatePipe } from '../../../../../shared/pipes/local-date.pipe';

@Component({
    selector: 'app-extended-dates-cell',
    templateUrl: './extended-dates-cell.component.html',
    styleUrls: ['./extended-dates-cell.component.scss'],
    standalone: true,
    imports: [LocalDatePipe],
})
export class ExtendedDatesCellComponent implements OnInit {
  rowData: {assignDate; extendedDueDate; originDueDate};
  constructor() { }

  ngOnInit(): void {
  }

}
