import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { clone, difference, uniq } from 'underscore';
import { TeamPermission } from '../shared/popups/add-staff/add-staff.types';
import { getUpdateUsersForm } from '../shared/popups/bulk-staff-modal/bulk-staff-modal.component';
import { GroupService, ITeam } from './group.service';
import { PERF_REVIEW_TEAM_TYPE } from './service-types/special-team-types';
import { UserUpdateService } from './user-update.service';
import { IOrgUser, UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialTeamService {
  private userService = inject(UserService);
  private groupService= inject(GroupService);
  private userUpdateService = inject(UserUpdateService);

  addManager(managerID: string, members: string[], teamType: string) {
    return this.getManagerSpecialTeam(managerID, teamType).pipe(
      switchMap(team => {
        const teamID = team.teamID
        // prepare payload for manager
        const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
        manager.managedTeams = uniq((manager.managedTeams || []).concat([teamID]));
        manager.teams = manager.allTeams;
        const managerPayload = getUpdateUsersForm(manager);
        // prepare payload for members
        const currentMembers = this.getCurrentMembers(managerID, teamType);
        // find removed, new members
        const newMembers = difference(members, currentMembers);
        const removedMembers = difference(currentMembers, members);

        const getPayload = (users: string[], removing = false) => {
          return users
          .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
          .map(user => {
            user.teams = user.allTeamModels
              .filter(t => t.teamID !== teamID)
              .map(t => t.teamID)
              .concat(removing ? teamID : null)
              .filter(teamID => teamID)
              ;
            return getUpdateUsersForm(user)
          })
        };
        const membersPayload = getPayload(newMembers).concat(getPayload(removedMembers, true))
        const payload = membersPayload.concat(managerPayload);
        return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType })
          .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
      })
    )
  }

  /**
   * for existed user
   * @param managerID 
   * @param teamID 
   * @param members 
   * @param includeManagerPayload if want to separate the manager's payload in another call
   * @returns 
   */
  editMembers(managerID: string, team: ITeam, members: string[], includeManagerPayload = true) {
    const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
    if (!team) {
      return throwError('Team not Found')
    }
    const teamType = team.teamType
    manager.managedTeams = uniq((manager?.managedTeams || []).concat([team.teamID]));
    manager.teams = manager?.allTeams;
    const managerPayload = getUpdateUsersForm(manager);
    // prepare payload for members
    const currentMembers = this.getCurrentMembers(managerID, teamType);
    // find removed, new members
    const newMembers = difference(members, currentMembers);
    const removedMembers = difference(currentMembers, members);

    const getPayload = (users: string[], removing = false) => {
      return users
      .filter(u => includeManagerPayload ? true : u !== managerID)
      .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
      .map(user => {
        user.teams = user.allTeamModels
          .filter(t => t.teamID !== team.teamID)
          .map(t => t.teamID)
          .concat(removing ? null : team.teamID)
          .filter(teamID => teamID)
          ;
        return getUpdateUsersForm(user)
      })
    };
    const membersPayload = getPayload(newMembers).concat(getPayload(removedMembers, true))
    const payload = membersPayload.concat(managerPayload);
    if (!includeManagerPayload) {
      payload.pop();
    }
    return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType })
      // .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
  }

  // for new user
  editMembersForEnrolmentUser(manager: IOrgUser, team: ITeam, members: string[], includeManagerPayload = true) {
    if (!team) {
      return throwError('Team not Found')
    }
    const teamType = team.teamType
    const managerPayload = getUpdateUsersForm(manager);
    const newMembers = members;

    const getPayload = (users: string[], removing = false) => {
      return users
        .filter(u => includeManagerPayload ? true : u !== manager.orgUserID)
        .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
        .map(user => {
          user.teams = user.allTeamModels
            .filter(t => t.teamID !== team.teamID)
            .map(t => t.teamID)
            .concat(removing ? null : team.teamID)
            .filter(teamID => teamID)
            ;
          return getUpdateUsersForm(user)
        })
    };
    const membersPayload = getPayload(newMembers)
    const payload = membersPayload.concat(managerPayload);
    if (!includeManagerPayload) {
      payload.pop();
    }
    return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType })
      // .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
  }

  // for existed user
  getManagerSpecialTeam(managerID: string, teamType: string) {
    const manager = this.userService.managedOrgUserDictionaryByOrgUserID[managerID];
    const specialTeam = manager?.managedSpecialTeams?.find(t => t.teamType === teamType)
    if (specialTeam) {
      return of(specialTeam)
    }
    // create a new hierarchy team, 
    return this.groupService.addOrganisationTeam(
      localStorage.getItem('orgID'),
      {
        name: `${manager?.fullName} ${teamType} Team`,
        teamType: teamType,
      } as any
    )
    // add in manager 
  }

  // for new user
  createSpecialTeam(fullName: string, teamType: string) {
    return this.groupService.addOrganisationTeam(
      localStorage.getItem('orgID'),
      {
        name: `${fullName} ${teamType} Team`,
        teamType: teamType,
      } as any
    )
  }

   // if not passing members, remove all
  //  if the member doesn't have a team, it returns of(null)
   removeManager(managerID: string, teamType: string, members?: string[]) {
    const team = this.getExistingSpecialTeam(managerID, teamType);
    if (!team) {
      return of(null);
    }
    if (!members) {
      members = this.getCurrentMembers(managerID, teamType) || [];
    }
    const payload = members
    .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
    .map(user => {
      user.teams = user.allTeamModels
        .filter(t => t.teamID !== team.teamID)
        .map(t => t.teamID)
      return getUpdateUsersForm(user)
    })
    if (!members) {
      const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
      manager.managedTeams = (manager.managedTeams.filter(t => t !== team.teamID));
      manager.teams = manager.allTeams;
      const managerPayload = getUpdateUsersForm(manager);  
      payload.push(managerPayload);
    }
    return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType})
    .pipe(
      switchMap(() => {
        if (!members) {
          return this.groupService.deleteOrganisationTeam(localStorage.getItem('orgID'), team.teamID, true)
        }
        return of(null)
      }),
      // switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID')))
    )
  }

  getExistingSpecialTeam(managerID: string, teamType: string) {
    const manager = this.userService.managedOrgUserDictionaryByOrgUserID[managerID];
    const specialTeam = manager?.managedSpecialTeams?.find(t => t.teamType === teamType);
    return specialTeam;
  }

  getCurrentMembers(managerID: string, teamType: string) {
    const specialTeam = this.getExistingSpecialTeam(managerID, teamType)
    if (specialTeam) {
      return this.userService.userList
        .filter(u => u.specialTeams?.map(t => t.teamID)?.includes(specialTeam.teamID))
        .map(u => u.orgUserID)
         || []
    }
    return [];
  }

  /**
   * Handle special team while adding a new user.
   * It includes calling 3 APIs: create a special team, call org invitation, bulk update other special team org users.
   */
  handleSpecialTeamWhenAddingNewUser(
    userFullName,
    selectedPermission: TeamPermission,
    selectedSpecialTeamMembers: string[],
    createInvitation$: Observable<IOrgUser>,
  ): Observable<IOrgUser> {
    let result$: Observable<any> = of(null);
    let specialTeam: ITeam;
    let orgUser: IOrgUser;

    const fnId = selectedPermission;
    const isPerfManager = fnId === 'administrator' || fnId === 'viewer' || fnId === 'teamAndLocationManager';

    return result$.pipe(
      switchMap(() => {
        // 1. create a special team
        if (isPerfManager && selectedSpecialTeamMembers?.length > 0) {
          return this.createSpecialTeam(userFullName, PERF_REVIEW_TEAM_TYPE);
        } else {
          return of(null);
        }
      }),
      switchMap(team => {
        // 2. call org invitation
        specialTeam = team;
        return createInvitation$;
      }),
      switchMap((orgUserData) => {
        // 3. bulk update special team members' teams attribute and the enrolment org user
        orgUser = orgUserData;
        orgUser['managedTeams'] = uniq(orgUser['managedTeams'] || []).concat(specialTeam.teamID);
        orgUser['teams'] = orgUser['teams'];

        return this.editMembersForEnrolmentUser(
          orgUser,
          specialTeam,
          selectedSpecialTeamMembers,
          true
        );
      }),
      switchMap(data => of(orgUser)),
    );

  }



}
