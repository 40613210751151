import { takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { GroupService } from 'src/app/services/group.service';
import { LearningService } from 'src/app/services/learning.service';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { EvidenceService } from 'src/app/services/evidence.service';
import { ConfirmationModalComponent } from '../../popups/confirmation-modal/confirmation-modal/confirmation-modal.component';
import { SharedConfirmationModalComponent } from '../../popups/shared-confirmation-modal/shared-confirmation-modal.component';
import { OrgAssignService } from 'src/app/services/org-assign.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { SidePanelLearningRecordSection } from './side-panel-learning-record-section/side-panel-learning-record-section.component';
import { StaffLinkDisplayComponent } from '../staff-link-display/staff-link-display.component';
import { SharedSidebarComponent } from '../shared-sidebar/shared-sidebar.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-linked-learning-record',
  templateUrl: 'linked-learning-record.component.html',
  styleUrls: ['linked-learning-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [NgIf, SharedSidebarComponent, StaffLinkDisplayComponent, SidePanelLearningRecordSection],
  providers: [OrgAssignService]
})
export class LinkedLearningRecordComponent implements OnInit, OnDestroy {
  @ViewChild('appSharedSidebar', { static: true }) appSharedSidebar;
  @Input() value: any;
  @Input() rowData: any;
  private _destory$ = new Subject<void>();
  activityID;
  user: IOrgUser;
  activity: any;
  isLoading = true;
  constructor(
    private modalService: NgbModal,
    private learningService: LearningService,
    private userService: UserService,
    private groupService: GroupService,
    private cdRef: ChangeDetectorRef,
    private evidenceService: EvidenceService,
    private orgAssignService: OrgAssignService,
    private analytics: AnalyticsService
  ) {
  }

  ngOnInit(): void {
    this.activityID = this.rowData.activityID;
    this.evidenceService.recordsDataRefresh.pipe(takeUntil(this._destory$)).subscribe(
      orgActivityID => {
        if (orgActivityID === this.rowData.activityID) {
          this.isLoading = true;
          this.generateSidebarData();
          this.evidenceService.recordsDataRefresh.next(false);
          this.cdRef.detectChanges();
        }
      }
    );
  }

  viewRecord() {
    this.appSharedSidebar.toggleShowSidebar();
    if (this.isLoading) {
      this.generateSidebarData();
    }

  }

  undoSkipped() {
    const modal = this.modalService.open(ConfirmationModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
    modal.componentInstance.modalHeading = 'Delete skipped learning record?';
    modal.componentInstance.content = 'You are about to delete this skipped learning record. This action cannot be reversed. Please confirm the selection.';
    modal.componentInstance.buttonText = 'Delete';
    modal.componentInstance.toasterText = 'Learning record successfully deleted';
    modal.componentInstance.request$ = this.orgAssignService.undoMark(localStorage.getItem('orgID'), this.rowData.assignID);
    modal.result.then(res => {
      if (res) {
        this.analytics.track('C-requirement-undoMarkSkipped', {
          assigns: [this.rowData]
        })
        const modal1 = this.modalService.open(SharedConfirmationModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
        modal1.componentInstance.title = 'Learning record deleted';
        modal1.componentInstance.content = 'The learning record successfully deleted. Please refresh this page to see the changes.';
        modal1.componentInstance.buttonText = 'Close';
        modal1.componentInstance.buttonClass = 'outline-light';
        modal1.componentInstance.hasCancelButton = false;

      }
    });
  }

  generateSidebarData() {
    let activity$: Observable<any>;
    const user = this.rowData.user;
    if (this.rowData.isOrgActivity) {
      activity$ = this.learningService.fetchOrgActivity(user.userID, this.activityID, true);
    } else {
      activity$ = this.learningService.fetchActivity(user.userID, this.activityID, true);
    }
    const users$ = this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'));
    const orgTeams$ = this.groupService.fetchedCacheOrgTeams();
    combineLatest([users$, activity$, orgTeams$]).pipe(takeUntil(this._destory$)).subscribe(([users, activity, teams]) => {
      this.user = this.userService.managedOrgUserDictionaryByUserID[user.userID];
      this.activity = activity;
      this.isLoading = false;
      this.cdRef.detectChanges();
    })
  }

  get complianceStatusBadgeStyle() {
    switch (this.rowData.status) {
      case 'Completed': return 'badge-success';
      case 'Done': return 'badge-success';
      default: return 'badge-light';
    }
  }

  get displayText() {

    return (this.activityID && this.rowData.status !== 'Skipped') ? `1 Learning Record` : `0 Learning Records`;
  }

  ngOnDestroy(): void {
    this._destory$.next();
    this._destory$.complete();
  }
}
