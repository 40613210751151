import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ScrollShadowDirective } from '../../directives/scroll-shadow.directive';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-multi-select-container',
    templateUrl: './multi-select-container.component.html',
    styleUrls: ['./multi-select-container.component.scss'],
    animations: [
        trigger('showBulkButtons', [
            state('open', style({
                padding: '0rem',
                opacity: 1,
                height: '*'
            })),
            state('closed', style({
                opacity: 0,
                height: '0'
            })),
            transition('open => closed', [
                animate('0.1s')
            ]),
            transition('closed => open', [
                animate('0.1s')
            ]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, ScrollShadowDirective],
})
export class MultiSelectContainerComponent implements OnInit {
  // option to hide the whole thing
  @Input() selectedItems: any[];
  @Input() availableItems: number;
  @Input() shownItems: any;
  @Input() itemName: string;
  @Input() tableID?: string;
  @Input() thresholdNum = 0; // how many selected for it to show
  @Input() inCard?: boolean = false;
  @Input() hideDefaultText;
  @Input() hideBulkAction = false;
  @Output() selectAll = new EventEmitter();
  @Output() deselectAll = new EventEmitter();
  shadowOn = false; // is table shadow on

  @ViewChild(ScrollShadowDirective) scrollShadow: ScrollShadowDirective;

  constructor() { }

  ngOnInit(): void {
  }

  onSelectAll() {
    this.selectAll.emit();
  }

  onDeselectAll() {
    this.deselectAll.emit();
  }

  get selectAllText() {
    return `Select all ${this.availableItems} ${this.itemName}`;
  }

  get tableWrapID() {
    return this.tableID || 'select-' + this.itemName.replace(' ', '-');
  }

  // hide shadow during animation to prevent UI bug
  onAnimationEvent(event: string) {
    if (!this.scrollShadow) {
      return;
    }
    if (event === 'start') {
      this.shadowOn = this.scrollShadow?.shadowX;
    }
    if (event === 'start') {
      this.scrollShadow.shadowX = false;
    } else {
      if (this.shadowOn) {
        this.scrollShadow.shadowX = true;
      }
    }
  }
}
