import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from './error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadCertificateService {

  constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
  ) {

  }

  downloadAusmedResourceCertificate(orgID: string, resourceID: string, userID: string, endDateLocal: string, activityID?: string): Observable<any> {
    return this.http
      .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/ausmedResources/${resourceID}/users/${userID}/genCert?completionDate=${endDateLocal}${activityID ? '&activityID=' + activityID : ''}`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  downloadOrgResourceCertificate(orgID: string, resourceID: string, userID: string, endDateLocal: string, activityID?: string): Observable<any> {
    return this.http
      .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/orgResources/${resourceID}/users/${userID}/genCert?completionDate=${endDateLocal}${activityID ? '&activityID=' + activityID : ''}`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

}

