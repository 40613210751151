<ng-container *ngIf="enableSelection && !multiple">
    <label class="f-small text-neutral-800 mb-1" for="selectPolicy">{{label}}</label>
    <ng-select class="f-small min-height-100" [ngClass]="{'is-invalid': showError}" id="selectPolicy"
        [items]="allPolicies" [loading]="loading" (change)="onPolicyChange($event)" name="governanceID"
        [closeOnSelect]="true" [(ngModel)]="selectedPolicy" [searchable]="true" [virtualScroll]="true"
        [clearSearchOnAdd]="true" bindLabel="title" appendTo="body" [placeholder]="placeholder"
        [searchFn]="customSearchFn" required>
        <ng-template ng-option-tmp let-item="item">
            <search-item-policy [policy]="item" [enableLink]="false"></search-item-policy>
        </ng-template>
    </ng-select>
</ng-container>

<ng-container *ngIf="selectedPolicy && !multiple">
    <div class="my-4 m-selected-policy p-2">
        <search-item-policy [policy]="selectedPolicy" [enableLink]="false" [removeBtn]="true"
            (removePolicyEvent)="selectedPolicy=null; onPolicyChange(null)"></search-item-policy>
    </div>
</ng-container>


<!-- choose multiple items -->
<ng-container *ngIf="enableSelection && multiple">
    <label class="f-small text-neutral-800 mb-1" for="selectPolicy">{{label}}</label>
    <ng-select class="f-small min-height-100" [ngClass]="{'is-invalid': showError}" id="selectPolicy"
        [items]="allPolicies" [loading]="loading" (change)="onPolicyChange($event)" name="governancceID"
        [closeOnSelect]="true" [(ngModel)]="selectedPolicies" [searchable]="true" [virtualScroll]="true"
        [clearSearchOnAdd]="true" bindLabel="title" appendTo="body" [multiple]="true" [placeholder]="placeholder"
        [searchFn]="customSearchFn" required>
        <ng-template ng-option-tmp let-item="item">
            <search-item-policy [policy]="item" [enableLink]="false"></search-item-policy>
        </ng-template>
    </ng-select>
</ng-container>

<ng-container *ngIf="multiple">
    <div class="my-4">
        <div *ngFor="let item of selectedPolicies" class="p-2 m-selected-policy mt-1">
            <search-item-policy [policy]="item" [enableLink]="false" [removeBtn]="true"
                (removePolicyEvent)="removePolicy($event)"></search-item-policy>
        </div>
    </div>
</ng-container>
