import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter as filterRx, take as takeRx, tap, switchMap } from 'rxjs/operators';
import { filter, findIndex, each } from 'underscore';
import { Dictionary } from 'underscore';

export interface IWorkflowStep {
  key: string;
  // componentSelector: string;
  // component: Function;
  condition?: (param: any) => boolean; // will need to call updateSteps to check
  // shouldShow$?: Observable<boolean>; // will update automatically // TODO:
  resolve?(param: any): Observable<any>;
}

export type IBeforeStepChangeFn<T extends IWorkflowStep> =
  (newStep: T, newIndex: number, oldStep: T | void, oldIndex: number | void) => Observable<any> | void;

export class StepsControl<T extends IWorkflowStep> {

  initialised: boolean;

  private _steps$ = new BehaviorSubject<ReadonlyArray<T>>(null);
  steps: ReadonlyArray<T>; // note: should readonly on the outside
  _param: any;

  get currentIndex(): number {
    return this._currentIndex;
  }
  private _currentIndex?: number;
  get currentStep(): T {
    return this._currentStep;
  }
  private _currentStep?: T;
  private _currentStep$ = new BehaviorSubject<T>(null);

  private _allSteps: ReadonlyArray<T>;

  private _beforeStepChange: IBeforeStepChangeFn<T>;
  private _onFlowFinish?(oldStep: T, oldIndex: number): Observable<void> | void;

  /**
   * @param _allSteps a sorted array of all steps (conditional or not) that the workflow can support. key should be unique.
   * @param beforeStepChange a function that is called before the step is changed.
   *      if an Observable is returned by the function, the step change will only proceed when the Observable emits its first value.
   *        This can be useful for running asynchronous calls before the step changes
   *      if the returned Observable terminates without emitting, the step change will be aborted. This can be useful for validation.
   *      BUG: if beforeStepChange calls this.update and hence changes this.steps, nextStep might change after beforeStepChange returns.
   *        Therefore, the nextStep parameter passed to beforeStepChange will not be accurate.
   *        You can get around this issue by disregarding the nextStep parameter and instead using this.steps[newIndex]]
   * potential improvement: use a LinkedList and observable inputs for conditions for constant time insertion/deletion of steps (although indexing becomes linear)
   */
  constructor(config: {
    allSteps: T[],
    beforeStepChange?: IBeforeStepChangeFn<T>;
    /**
     * optional parameter that is passed to condition function on `IWorkflowStep`
     */
    callbackParam?: any;
    onFlowFinish?(oldStep: T, oldIndex: number): Observable<any> | void,
  }) {
    this._allSteps = config.allSteps;

    this._beforeStepChange = config.beforeStepChange;
    this._param = config.callbackParam;
    this._onFlowFinish = config.onFlowFinish;
  }


  // SECTION: manages step list

  initAsync(args: {
    getInitialIndex?: (steps: ReadonlyArray<T>) => number
  } = {}) {
    this.updateSteps();
    const initialIndex = args.getInitialIndex ? args.getInitialIndex(this.steps) : 0;
    // console.log('initialIndex', initialIndex);
    return this.setCurrentIndexAsync(initialIndex).pipe(
      tap(() => this.initialised = true),
    );
  }

  /**
   * to be called when all data the workflow needs is loaded
   */
  init(args?: {
    getInitialIndex?: (steps: ReadonlyArray<T>) => number
  }) {
    this.initAsync(args).subscribe();
  }

  /**
   * returns an Observable of step array. The step array is immutable.
   */
  getSteps$(): Observable<ReadonlyArray<T>> {
    return this._steps$.pipe(filterRx(x => !!x));
  }

  /**
   * used for initialising or reevaluating steps
   * complexity Ө(allSteps)
   * NOTE: this should not be called before init is called
   */
  updateSteps() {
    // when steps are updated, currentStep should be reevaluated
    const alreadyPast = this._getPastSteps();
    const callbackParam = this._param;
    const steps = Object.freeze(filter(this._allSteps, (step) => {
      return alreadyPast[step.key] || (!step.condition || step.condition(callbackParam)); // check condition
    }));

    // if (this.currentIndex !== void(0)) {
    //   // console.log('🍫 need to reupdate current step');
    //   this.setCurrentIndexAsync(this.currentIndex, true).subscribe();
    // }
    // console.log('updatingSteps', steps.map(s => s.key));

    this.steps = steps;

    const currentStep = this.steps && this.steps[this.currentIndex];
    // console.log('currentStep', currentStep, this.steps, this.currentStep);
    if (currentStep !== this.currentStep) {
      // console.log('🍿 index unchanged but step changed', currentStep);
      this.setCurrentIndexAsync(this.currentIndex, true).subscribe();
    }

    // console.log('💧', {
    //   alreadyPast,
    //   steps,
    // });

    this._steps$.next(steps);
  }

  /**
   * returns a dictionary of all steps whose index <= this.currentIndex
   */
  private _getPastSteps(): Dictionary<boolean> {
    const alreadyPast: Dictionary<boolean> = {};
    for (let i = 0; i <= this.currentIndex; i++) {
      const step = this.steps[i];
      alreadyPast[step.key]  = true;
    }
    // console.log('ALREADy PAST', alreadyPast);
    return alreadyPast;
  }


  // SECTION: manages current slide

  setCurrentIndexAsync(newIndex: number, skipStepChange?: boolean): Observable<void> {
    const steps = this.steps;
    if (newIndex < 0 || newIndex >= steps.length) {
      return (this._onFlowFinish && this._onFlowFinish(this.currentStep, this.currentIndex)) || of(null);
    }

    // calculate variables
    const oldIndex = this._currentIndex;
    const oldStep = this._currentStep;
    let newStep = steps[newIndex];

    let ready$ = this._resolve(newIndex);

    // check beforeStepChange
    ready$ = ready$.pipe(
      switchMap(() => (!skipStepChange && this._beforeStepChange && this._beforeStepChange(newStep, newIndex, oldStep, oldIndex)) || of(null)),
    );

    // update currentStep when ready
    return ready$.pipe(takeRx(1), tap(() => {
      newStep = this.steps[newIndex]; // BUG: necessary to recalculate nextStep because it might have changed after observable emitted
      // if (!internal) {
      //   console.log('step changed', { newIndex, newStep });
      // }
      this._currentIndex = newIndex;
      if (oldStep !== newStep) {
        this._currentStep = newStep;
        this._currentStep$.next(newStep);
      }
    }));

  }

  nextStep(): void {
    this.nextStepAsync().subscribe();
  }

  nextStepAsync(): Observable<void> {
    // console.log(this.currentIndex + 1);
    return this.setCurrentIndexAsync(this.currentIndex + 1);
    // return this.setCurrentIndexAsync(this.currentIndex + 1);
  }

  private _resolve(index: number) {
    const nextStep = this.steps[index];
    if (nextStep && nextStep.resolve) {
      return nextStep.resolve(this._param);
      // .pipe(
        // tap(() => this.updateSteps()),
      // );
    } else {
      return of(null);
    }
  }

  previousStep(): void {
    this.previousStepAsync().subscribe();
  }

  previousStepAsync(): Observable<void> {
    return this.setCurrentIndexAsync(this.currentIndex - 1);
  }

  goToStep(key: string): void {
    this.goToStepAsync(key).subscribe();
  }

  goToStepAsync(key: string): Observable<void> {
    const index = findIndex(this.steps,(s)=>s.key===key);
    if (index >= 0) {
      return this.setCurrentIndexAsync(index);
    } else {
      throw new Error('cannot go to step not in array: ' + key);
    }
  }

  peekNext() {
    return this.steps[this.currentIndex + 1];
  }

  getCurrentStep$(): Observable<T> {
    return this._currentStep$.pipe(filterRx(x => !!x));
  }

}
