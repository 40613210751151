@if (answersFormGroup) {
  <form name="answersForm" [formGroup]="answersFormGroup">
    <div class="d-flex flex-column gap-4">
      @for(question of questions; track question.questionID; let questionIndex = $index) {
        <div class="org-question__container d-flex flex-column">
          <div class="d-flex flex-column gap-3">
            <!-- Header -->
            <div class="d-flex flex-row align-items-center org-question__title">
              <ui-streamline-icon 
                [slIcon]="{name: 'messages-bubble-double-alternate',  theme: 'regular', size: 'sm'}"
              ></ui-streamline-icon>
              <div class="org-question__title-content">
                {{question.text}}
              </div>
              @if (!question.isSkippable) {
                <div class="ms-auto f-small fw-semibold text-neutral-700">
                  Required
                </div>
              }
            </div>
  
            <!-- Content -->
            <div class="px-3">
              @switch (question.qType) {
                @case ('single-answer') {
                  <div class="org-question--single-answer">
                    @for (opt of question.options; track opt; let optIndex = $index) {
                      <div class="form-check f-small fw-medium">
                        <input
                          class="form-check-input"
                          type="radio"
                          [id]="questionIndex + '-' + optIndex"
                          [value]="optIndex"
                          [formControlName]="question.questionID"
                        />
                        <label class="form-check-label" [for]="questionIndex + '-' + optIndex">
                          {{ opt }}
                        </label>
                      </div>
                    }
                  </div>
                }
                @case ('short-answer') {
                  <textarea
                    [readOnly]="disabled"
                    class="form-control org-question--private-reflection"
                    [formControlName]="question.questionID"
                    [id]="question.questionID"
                    [placeholder]="disabled ? ' ' : 'Enter your answer here'"
                  ></textarea>
                }
                @case ('number') {
                  <input
                    type="number"
                    [min]="0"
                    [max]="10000000000000"
                    [id]="question.questionID"
                    class="form-control"
                    [formControlName]="question.questionID"
                    [placeholder]="disabled ? ' ' :'Enter a number here'"
                  />
                }
                @case ('private-reflection') {
                  <textarea
                    [readOnly]="disabled"
                    class="form-control org-question--private-reflection"
                    [formControlName]="question.questionID"
                    [id]="question.questionID"
                    [placeholder]="disabled ? ' ' : 'Enter your answer here'"
                  ></textarea>
                }
                @case ('multi-answers') {
                  <div class="org-question--multi-answers">
                    @for (opt of question.options; track opt; let optIndex = $index) {
                      <div class="form-check f-small fw-medium">
                        <input
                          class="form-check-input"
                          [id]="questionIndex + '-' + optIndex"
                          type="checkbox"
                          [checklist]="answersFormGroup.controls[question.questionID].value"
                          (checklistChange)="answersFormGroup.controls[question.questionID].setValue($event)"
                          [checklistValue]="optIndex"
                          [disabled]="disabled"
                        />
                        <label
                          class="form-check-label"
                          [for]="questionIndex + '-' + optIndex"
                        >
                          {{ opt }}
                        </label>
                      </div>
                    }
                  </div>
                }
                @case ('dropdown') {
                  <div class="org-question--dropdown">
                    <select 
                      id="dropdown"
                      class="styled-dropdown"
                      [formControlName]="question.questionID"
                      [disabled]="disabled"
                    >
                      <option value="" disabled>{{disabled ? ' ' : 'Select an option'}}</option>
                      @for (option of question.options; track $index) {
                        <option [value]="$index">
                          {{ option }}
                        </option>
                      }
                    </select>
                  </div>

                }
                @case ('ratings') {
                  <div class="org-question--ratings text-center">
                    <app-bar-square-rating
                      [formControlName]="question.questionID"
                      [id]="question.questionID"
                      [max]="10"
                      theme="square-rating"
                      [disabled]="disabled"
                    >
                    </app-bar-square-rating>
                  </div>
                }
                @case ('date-picker') {
                  <div class="d-flex org-question--date-picker">
                    <input 
                      ngbDatepicker
                      removeNgbDpDefaultValidation
                      type="text" 
                      class="form-control f-small" 
                      #d="ngbDatepicker"
                      onkeydown="return false" 
                      (click)="showCalendarAndHideYearSelect(d)"
                      [formControlName]="question.questionID"
                      [placeholder]="disabled ? ' ' :'Select a date'" 
                      [disabled]="disabled"
                    >
                </div>
                }
              }
  
              @if (handleErrorMessage(question)) {
                <div class="mt-2 f-small text-danger">
                  {{ handleErrorMessage(question) }}
                </div>
              }
            </div>
  
            <!-- Comment -->
             @if (commentsFormGroup) {
              <form name="commentsForm" [formGroup]="commentsFormGroup" class="px-3">
                @if (commentsFormGroup.controls[question.questionID]) {
                  <textarea
                    [readOnly]="disabled"
                    class="form-control org-question--private-reflection mb-4"
                    [formControlName]="question.questionID"
                    [id]="question.questionID"
                    [placeholder]="disabled ? ' ' : 'Add Comment'"
                  ></textarea>
                }
              </form>
             }
          </div>
        </div>
      }
    </div>
  </form>
}


<p class="fw-semibold mt-7 mb-4">Additional Meeting Notes</p>
@if (additionalNotesFormGroup) {
  <form name="additionalNotesFormGroup" [formGroup]="additionalNotesFormGroup">
    <textarea
      [readOnly]="disabled"
      class="form-control org-question--private-reflection mb-4"
      [formControlName]="'additionalNotes'"
      [id]="'additionalNotes'"
      [placeholder]="disabled ? ' ' : 'Add Additional Meeting Notes'"
    ></textarea>
  </form>
}
  