<div class="btn-group" [ngClass]="{'w-100': hasFullWidth}" role="group" *ngIf="items.length>0">
  <ng-container *ngFor="let item of items">
    <input type="radio" class="btn-check" name="btnradio" [(ngModel)]="model" [id]="item.value" [value]="item.value" autocomplete="off" (ngModelChange)="emitModel($event)">
    <label style="white-space: nowrap;" class="btn m-btn btn-outline-secondary d-flex justify-content-center toggle-btn-border" [ngClass]="{'w-50': items?.length === 2, 'min-width': hasIcon}" [for]="item.value">
      <ui-streamline-icon *ngIf="hasIcon && item.iconConfig"
        [slIcon]="item.iconConfig"
        class="pe-1 m-pb-1px">
      </ui-streamline-icon>
      {{item.label}}
    </label>
  </ng-container>
</div>
