import { HttpClient, HttpParams } from '@angular/common/http';
import { ServerDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/server/server.data-source';
import { ServerSourceConf } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/server/server-source.conf';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { Dictionary } from 'lodash';
import { GroupService } from 'src/app/services/group.service';
import { pick, pluck } from 'underscore';
import { IPPAckServersidePayload } from './pp-server-table.component';
import { ACK_STATUS, IAckBackend, PolicyFromBackend } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { backendAckToTableData } from 'src/app/pages/policies-procedures/policies/pp-detail/pp-detail-ack/pp-detail-ack.component';

export interface IPPServerTableData {
  userService: UserService;
  groupService: GroupService;
  policyDict: Dictionary<PolicyFromBackend>;
}

export class PPServerDataSource<P> extends ServerDataSource {
  private readonly _bodyPayload: any;
  groupService: GroupService;
  userService: UserService;
  policyDict: Dictionary<PolicyFromBackend>;

  private _governData$ = new Subject<{
    finalPayload: IPPAckServersidePayload,
    finalEndpoint: string;
    hasResult: boolean;
    numResult: number;
  }>();
  governData = this._governData$.asObservable();


  constructor(http: HttpClient, bodyPayload: P, conf: ServerSourceConf | {}, requiredData: IPPServerTableData) {
    super(http, conf);
    this._bodyPayload = bodyPayload;
    this.groupService = requiredData.groupService;
    this.userService = requiredData.userService;
    this.policyDict = requiredData.policyDict;
  }

  protected requestElements(): Observable<any> {
    const httpParams: HttpParams = this.createRequesParams();
    // console.log(this._bodyPayload);

    return this.http.post(`${this.conf.endPoint}${httpParams}`, this.filteredPayload, { observe: 'response' }).pipe(
      map((response) => {
        return {
          ...response,
          body: ackBackendToTableFn(this.userService.managedOrgUserDictionaryByOrgUserID,
              this.policyDict)(response.body as any[])
        };
    }), tap((res) => {
        // console.log(res);
        this._governData$.next({
          finalPayload: this.filteredPayload,
          finalEndpoint: res.url,
          hasResult: !!res.body?.length,
          numResult: res.headers?.get('X-Total-Count') || 0
        });
    }));
  }

  protected addSortRequestParams(httpParams: HttpParams): HttpParams {
    const dic = {   fn_assignDate : 'assignLocalDate',
                    fn_lastRemind : 'reminderLastLocalDate',
                    fn_ackDate: 'ackLocalDate'   };
    if (this.sortConf) {
      this.sortConf.forEach((fieldConf) => {
        httpParams = httpParams.set(this.conf.sortFieldKey, dic[fieldConf.field]);
        httpParams = httpParams.set(this.conf.sortDirKey, fieldConf.direction.toUpperCase());
      });
    }

    return httpParams;
  }


  // Further filter out payload according to column search
  get filteredPayload() {
    const searchTerms = getSearchTermsFromParams(this.createRequesParams())
    return {
      ...this._bodyPayload,
      filteredOrgUsers: filterUsersByAttribute(this.filteredUsers, searchTerms),
      filteredGovs: this.filteredGovs,
      filteredStatuses: this.filteredStatuses
    };
  }

  get filteredUsers(): IOrgUser[] {
    return this._bodyPayload.filteredOrgUsers?.map(orgUserID => this.userService.managedOrgUserDictionaryByOrgUserID[orgUserID]) || [];
  }

  get filteredGovs() {
    const govSearchTerms = pick(getSearchTermsFromParams(this.createRequesParams()), ['fn_title', 'fn_govStatus', 'fn_govCode']);
    return filterObjectsBySearchTerms(getPolicyWithSearchTerms(this._bodyPayload.filteredGovs.map(g => this.policyDict[g])), govSearchTerms)
      .map(pol => pol.governanceID);
  }

  get filteredStatuses() {
    addReassignedStatus(this._bodyPayload.filteredStatuses);
    const term = getSearchTermsFromParams(this.createRequesParams())['fn_status'];
    if (term) {
      return this._bodyPayload.filteredStatuses.filter(s => getDisplayAckStatus(s).toLocaleLowerCase().includes(term.toLocaleLowerCase()));
    }
    return this._bodyPayload.filteredStatuses;
  }

}

export function getSearchTermsFromParams(httpParams: HttpParams) {
  const terms = {};
  httpParams.keys().filter(k => k.includes('_like')).forEach(k => {
    terms[k.replace('_like', '')] = httpParams.get(k);
  });
  return terms;
}

// expand backend acks with user attributes needed for tables
export function ackBackendToTableFn(userDict: Dictionary<IOrgUser>, policyDict: Dictionary<PolicyFromBackend>) {
  return function(acks: IAckBackend[]) {
    return acks.map(ack => {
      const user = userDict[ack.assigneeOrgUserID];
      if (!user) {
        console.log(ack);
      }
      return {
        // ack attributes
        fn_title: policyDict[ack.govID].title,
        governanceID: ack.govID,
        fn_govStatus: policyDict[ack.govID].governanceVersion.status,
        fn_govCode: policyDict[ack.govID].code,
        ...backendAckToTableData(ack),
        // user attributes
        orgUser: {...user, openNewTab: true},
        teamNames: user.orgUserDetail?.teamModels?.filter(t => !!t) || [],
        jobRoleTitles: pluck(user.activeJobRoleTakens, 'jobRoleModel')?.filter(j => !!j) || [],
        staffID: user.staffID,
        userStatus: user.status,
        email: user.email,
        orgEmail:user.orgEmail,
        orgMobile: user.orgMobile,
        internalID: user.internalID,
      };
    });
  };
}

// for filtering users based on params from table filters
export function filterUsersByAttribute(filteredUsers: IOrgUser[], terms: {
  teamNames?: string;
  jobRoleTitles?: string;
  orgUser?: string;
  staffID?: string;
  userStatus?: string;
  email?: string
}): string[] {
  terms = pick(terms, ['teamNames', 'jobRoleTitles', 'orgUser', 'staffID', 'userStatus', 'email']);
  let usersWithTerms = getUserWithSearchTerms(filteredUsers);
  // for each term:
  Object.keys(terms).forEach(k => {
    // at least one term eg teamname includes search term
    usersWithTerms = usersWithTerms.filter(u => (u[k].join(', ') || '-').includes(terms[k])
                              );
  });
  return pluck(usersWithTerms, 'orgUserID');
}

export function filterObjectsBySearchTerms(toFilter: any[], searchTerms: any) {
  Object.keys(searchTerms).forEach(k => {
    // at least one term eg teamname includes search term
    toFilter = toFilter.filter(u => (u[k] || '-')?.toLocaleLowerCase()
      ?.includes(searchTerms[k].toLocaleLowerCase()));
  });
  return toFilter;
}

// Maps user attributes to column names
// used to as util for user column filter
export function getUserWithSearchTerms(filteredUsers: IOrgUser[]) {
  return filteredUsers.map(user => {
    return {
      userID: user.userID,
      orgUserID: user.orgUserID,
      staffID: [user.staffID] || [],
      teamNames: user.orgUserDetail.teamModels?.map(t => t.name) || [],
      jobRoleTitles: user.activeJobRoleTakens.map(j => j.jobRoleModel?.title || '') || [],
      orgUser: [user.fullName] || [],
      userStatus: [user.status] || [],
      email: [user.email ] || [],
      orgEmail: [user.orgEmail] || []
    };
  });
}

export function getPolicyWithSearchTerms(filteredPolicies: PolicyFromBackend[]) {
  return filteredPolicies.map(policy => {
    return {
      governanceID: policy.governanceID,
      fn_title: policy.title,
      fn_govStatus: policy.governanceVersion.status,
      fn_govCode: policy.code,
    };
  });
}

export const BACKEND_ACK_STATUSES = [
  'Unacknowledged', 'Acknowledged', 'Re-assigned', 'Discarded', 'Skipped'
];

export const ACK_STATUS_DISPLAY_DICT = {
  'Re-assigned': 'Unacknowledged'
}

export function getDisplayAckStatus(s) {
  return ACK_STATUS_DISPLAY_DICT[s] || s;
}

export function addReassignedStatus(displayStatuses: ACK_STATUS[]) {
  if (displayStatuses.includes(ACK_STATUS.unacknowledged)) {
    displayStatuses.push(ACK_STATUS.reassigned);
  }
}


