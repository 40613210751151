<div *ngIf="value.length>0;else emptyContent">
    <div [ngbPopover]="popContent" placement="top" [popoverTitle]="popTitle"
    [container]="noContainer ? '' : 'body'" class="fw-medium text-neutral-900 cursor-pointer hover-underline">
        <a>{{ cellContent }}</a>
    </div>
</div>
<ng-template #btnDown #emptyContent>
    <div class="fw-medium text-neutral-900">
        <a> - </a>
    </div>
</ng-template>


<ng-template #popContent>
  <div class="m-scrollable">
      <ng-container *ngFor="let item of value;  last as isLast">
          <div class="text-truncate" [ngbTooltip]="item.name.length>31 ? item.name : ''"
              placement="top-left"
              container="body">
              <a
              href="javascript:void(0);"
              [routerLink]="[item.url]"
              class="fw-medium text-neutral-900"
              target="_blank">
              {{item.name}}
              </a>
          </div>
      </ng-container>
  </div>
</ng-template>
<ng-template #popTitle>
    <div class="f-small fw-medium text-neutral-900"> {{cellContent}}  </div>
</ng-template>
