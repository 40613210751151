import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { IGuideline, ResourceService } from 'src/app/services/resource.service';
import { getByoResourceTypeByField, getByoResourceTypeByResource } from '../../utils/get-resource-type';
import { resourceTypeDict } from '../../byo-shared/byo-resource-types';
import { OrganisationService } from '../../../services/organisation.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { indexBy } from 'underscore';
import { getMaxWidth } from '../../utils/table-cell-functions';
import { getResourceDefaultImg } from '../../utils/get-resource-default-img';
import { PassportService } from 'src/app/services/passport.service';
import { FEATURES } from 'src/app/core/features.config';
import { ITrainingSchedule } from 'src/app/services/mandatory-training.service';
import { ToAusmedCDN } from '../../pipes/to-ausmed-cdn.pipe';
import { CpdTimePipe } from '../../pipes/cpd-time.pipe';

import { NgbActiveModal, NgbModal, NgbModalRef, NgbOffcanvas, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { NgIf, NgClass, NgTemplateOutlet, NgFor } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { environment } from 'src/environments/environment';
import { SharedSidebarComponent } from "../shared-sidebar/shared-sidebar.component";
import { StaffLinkDisplayComponent } from "../staff-link-display/staff-link-display.component";
import { AvatarComponent } from "../avatar/avatar.component";
import { AssignStatusCellComponent } from "../table-cell/assign-status-cell/assign-status-cell.component";
import { ComplianceStatusPipe } from '../../pipes/compliance-status.pipe';
import { CenteredCellComponent } from "../table-cell/centered-cell/centered-cell.component";
import { DateToDdMmmYyyyPipe } from '../../pipes/date-to-dd-mmm-yyyy.pipe';
import { IOrgActivityDetail, LearningService } from 'src/app/services/learning.service';
import { ILearningRecord } from 'src/app/services/report-learning-record.service';
import { DownloadCertificateButtonComponent } from "../download-certificate/download-certificate.component";
import { AddEvidenceComponent } from "../evidence-components/add-evidence-form/add-evidence.component";
import { SidePanelEvidenceSection } from "../linked-learning-record/side-panel-learning-record-section/side-panel-evidence-section/side-panel-evidence-section.component";
import { EvidencePayloadType, EvidenceService } from 'src/app/services/evidence.service';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { WithDestroy } from '../../utils/mixins/withDestroy';
import { UISpinnerComponent } from "../../../ui/ui-spinner/ui-spinner.component";

@Component({
  selector: 'search-item-resource',
  templateUrl: './search-item-resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./search-item-resource.component.scss'],
  imports: [NgIf, NgFor, RouterLink, NgbTooltip, DateToDdMmmYyyyPipe, NgClass, CpdTimePipe, ToAusmedCDN, NgTemplateOutlet, UIStreamlineIconComponent, SharedSidebarComponent, StaffLinkDisplayComponent, AvatarComponent, AssignStatusCellComponent, CenteredCellComponent, DownloadCertificateButtonComponent, AddEvidenceComponent, SidePanelEvidenceSection, UISpinnerComponent]
})

export class SearchItemResourceComponent extends WithDestroy() implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() resource;
  @Input() value: any;
  @Input() enableLink = true;
  @Input() openNewTab = false;
  @Input() removeBtn = false;
  @Input() displayResourcePassportBadge = false;
  @Input() noTruncation = false;
  @Input() rowData: { badgeText?} | any;
  @Output() removeResourceEvent = new EventEmitter<string>();
  @ViewChild('appSharedSidebar', { static: true }) appSharedSidebar;
  @ViewChild('addEvidenceComponent', { static: true }) addEvidenceComponent;
  private offcanvasService = inject(NgbOffcanvas);



  isLoading = signal(true);
  isCompletedInternally = false;
  linkedLearningRecord: any;
  evidenceFormValue: EvidencePayloadType;
  hasAddEvidenceSection = false;
  evidences: EvidencePayloadType;
  isAssignTable = false;
  FEATURES = FEATURES;
  destroyed$ = new Subject<void>();
  resourceEnabledGuidelines: IGuideline[];
  guidelineDict: any;
  defaultImage = getResourceDefaultImg();
  resourceDict = this.resourceService.resourceDict;
  getResourceTypeByField = getByoResourceTypeByField;
  displayedResourceType: string;
  isPassportEnabled = false;
  schdulePassportName = '';

  // tooltip logic
  @Input() isTable = false;
  className = 'resource';
  titleWidth = 299;
  @ViewChild('text') text;
  showTooltip = false;
  isModal = false;
  isManualAssign = false;
  resourceLocked = false;

  constructor(
    private resourceService: ResourceService,
    private organisationService: OrganisationService,
    private cdr: ChangeDetectorRef,
    private passportService: PassportService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private learningService: LearningService,
    private complianceStatusPipe: ComplianceStatusPipe,
    private evidenceService: EvidenceService,
    private toaster: ToastrService,
    private analytics: AnalyticsService,
    private modalService: NgbModal,

  ) {
    super();
  }

  ngOnInit() {
    this.isAssignTable = this.rowData?.assignID;
    this.isCompletedInternally = !!this.rowData?.completion?.orgActivityID;
    this.isManualAssign = this.rowData?.mtPlanCell?.isManualAssign;
    this.titleWidth = this.noTruncation ? null : ((getMaxWidth(this.className, this.isModal, this.titleWidth)) - 100 || this.titleWidth);
    this._processResource();
    this.isPassportEnabled = this.rowData?.isPassportEnabled || false;
    this.passportService.getCachedPassports().pipe(take(1)).subscribe((passports) => {
        this.schdulePassportName = passports[0].name;
      }
    );
    this.evidenceService.recordsDataRefresh.pipe(takeUntil(this.destroy$)).subscribe(
      orgActivityID => {
        if (orgActivityID === this.rowData.activityID) {
          this.isLoading.set(true);
          this.generateSidebarData();
          this.evidenceService.recordsDataRefresh.next(false);
          this.cdr.detectChanges();
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resource) {
      this._processResource();
    }
  }
  getAssignStatus(currentStatus: string){
    return this.complianceStatusPipe.transform(currentStatus);
  }

  jumpToResource(){
    if(!this.isAssignTable){
      window.open(`${environment.host}${this.resource.routerLink.join('/')}`, this.openNewTab?'_bland':'_self');
    }
  }

  openSidePanel(content: TemplateRef<any>){
    if (this.isLoading() && this.isAssignTable) {
      this.generateSidebarData();
      this.offcanvasService.open(content,
        { panelClass: this.modalService.hasOpenModals() ? 'm-top-assign-side-panel':'m-assign-side-panel', // set the panel upper than modal
          position: 'end',
          backdropClass: this.modalService.hasOpenModals() ? 'm-top-side-panel-backdrop' : 'm-side-panel-backdrop'});
      this.cdr.detectChanges()
    } else {
      this.isLoading.set(false);
      this.cdr.detectChanges()
    }
  }

  generateSidebarData() {
    const orgActivityID = this.rowData?.completion?.orgActivityID;
    const assigneeID = this.rowData?.assignee;

    if(orgActivityID){
      const getLearningRecord$ =this.learningService.fetchOrgActivity(assigneeID, orgActivityID, false);
      getLearningRecord$.pipe(take(1)).subscribe(
        record => {
          this.linkedLearningRecord = record;
          this.evidences = record?.detail?.notes;
          this.isLoading.set(false);
          this.cdr.detectChanges();
          console.log('res',record)
        }
      )
    } else {
      this.isLoading.set(false);
      this.cdr.detectChanges();
    }

  }

  private _processResource() {
    if (this.value) {
      this.resource = this.value;
    }
    this.resourceService.fetchCachedGuidelines().pipe(take(1), takeUntil(this.destroyed$)).subscribe(guidelines => {
      this.guidelineDict = indexBy(guidelines, 'guidelineID');
      if (this.resource?.orgID || this.resource?.orgResource) {
        if (this.resource?.resourceType === 'onlineCourse') {
          this.resource.routerLink = ['/pages/staff-portal/byo/course-analytics', this.resource.resourceID]
        } else {
          this.resource.routerLink = ['/pages/staff-portal/byo/item', this.resource.resourceID];
        }
        const { title: resourceTypeDisplay } = getByoResourceTypeByResource(this.resource);
        this.displayedResourceType = resourceTypeDisplay;
      } else {
        this.resource.routerLink = this.resource?.thirdParty ? ['/pages/library/provider/' + this.resource.providerName + '/resource', this.resource.resourceID] : ['/pages/library/resource', this.resource.resourceID];
        this.displayedResourceType = resourceTypeDict[(this.resource.resourceType || this.resource.activityType)] || this.resource.activityType;
      }
      this.resource.coverImageURL = this.resource.coverImageUrl || this.resource.coverImageURL;
      const {
        title: resourceTypeDisplay
      } = getByoResourceTypeByResource(this.resource);
      this.defaultImage = getResourceDefaultImg(resourceTypeDisplay);
      const enabledFrameworks = this.organisationService.getEnabledOrgStandardFrameworks();
      const rawGuidelines = this.resource.guidelines || [];
      let fullGuidelines;
      if (rawGuidelines.every(g => typeof g === 'string')) {
        fullGuidelines = rawGuidelines.map(g => this.guidelineDict[g]).filter(g => !!g);
      } else {
        fullGuidelines = rawGuidelines;
      }
      this.resourceEnabledGuidelines = this.resourceService.getEnabledGuidelines(fullGuidelines, enabledFrameworks);
      setTimeout(() => this.cdr.detectChanges());
    });
    if (this.featureFlagService.featureOn(FEATURES.limitedResourceAccess) && !this.resource.orgID && this.resource.providerType !== 'Internal') {
      this.resourceLocked = true;
      this.resourceService.isResourceLocked(this.resource).subscribe(
        (res) => {
          this.resourceLocked = res;
          this.cdr.detectChanges();
        }
      );

    }

  }

  showAddSection() {
    this.hasAddEvidenceSection = true;
    // scroll to the Bottom of the container
    this.cdr.detectChanges();
    this.scrollToBottom();
  }

  scrollToBottom() {
    let element = document.getElementById('add-evidence-section-container');
    element?.scrollIntoView(false);
  }

  addEvidence(e) {
    if (e) {
      this.isLoading.set(true);
      this.evidenceService.addEvidenceToOrgActivity(this.rowData.activityID, this.rowData.user?.userID, this.evidenceFormValue).subscribe(
        () => {
          this.toaster.success('Evidence successfully added');
          this.generateSidebarData();
          const context = this.analytics.getContext(this.router.url);
          this.analytics.track('L-record-evidence_added', {
            context: context,
            notes: !!this.evidenceFormValue.note,
            weblink: !!this.evidenceFormValue.links[0]?.link,
            ...(context === 'reporting' ? { reportName: this.evidenceService.getEventReportName() } : {})
          });
          this.isLoading.set(false);
          this.cdr.detectChanges();
        },
        (error) => {
          this.toaster.error('Error: ', error);
        }
      )
    }
  }

  cancel() {
    this.hasAddEvidenceSection = false;
  }

  removeResource() {
    this.removeResourceEvent.emit(this.resource.resourceID);
  }

  ngAfterViewInit() {
    const el = this.text?.nativeElement;
    if (el.scrollWidth > el.clientWidth) {
      this.showTooltip = true;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  get passportName(): string {
    return this.passportService.getPassportByResourceID(this.resource.resourceID)?.name || null;
  }

  get style() {
    if (this.isTable) {
      return 'width: ' + this.titleWidth + 'px;';
    }
    return '';
  }

}
