<table [id]="tableId" [ngClass]="tableClass">

  <thead ng2-st-thead *ngIf="!isHideHeader || !isHideSubHeader" [grid]="grid" [isAllSelected]="isAllSelected"
    [source]="source" [createConfirm]="createConfirm" (create)="create.emit($event)"
    (selectAllRows)="onSelectAllRows($event)" (sort)="sort($event)" (filter)="filter($event)">
  </thead>

  <tbody ng2-st-tbody [grid]="grid" [source]="source" [deleteConfirm]="deleteConfirm" [editConfirm]="editConfirm"
    [rowClassFunction]="rowClassFunction" (edit)="edit.emit($event)" (delete)="delete.emit($event)"
    (custom)="custom.emit($event)" (userSelectRow)="onUserSelectRow($event)" (editRowSelect)="editRowSelect($event)"
    (multipleSelectRow)="multipleSelectRow($event)" (rowHover)="onRowHover($event)">
  </tbody>

</table>

<ng2-smart-table-pager *ngIf="isPagerDisplay" [source]="source" [perPageSelect]="perPageSelect" [settings]="settings"
  (changePage)="changePage($event)">
</ng2-smart-table-pager>