import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SharedTableActionsComponent } from '@components/shared-table-actions/shared-table-actions.component';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { MakeColumnStickyService } from 'src/app/services/make-column-sticky.service';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { ServerDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/server/server.data-source';
import { Ng2SmartTableComponent } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.component';
import { omit } from 'underscore';
import { Ng2SmartTableComponent as Ng2SmartTableComponent_1 } from '../../../shared-modules/ng2-smart-table/ng2-smart-table.component';
import { ScrollShadowDirective } from '../../directives/scroll-shadow.directive';
import { CommonModule, NgIf, NgTemplateOutlet } from '@angular/common';
import { ICacheTableSettings } from '../../popups/shared-edit-table-column-modal/LocalEditedColumnsByKey';
import { LocalTableUniqKey } from '../../popups/shared-edit-table-column-modal/localTableUniqKey';
import { downloadReportBySourceAndSettings } from '../../utils/export-csv';
import { WithDestroy } from '../../utils/mixins/withDestroy';
import { CSV_DOWNLOAD_MAPS } from './smart-table-utils';

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss'],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, ScrollShadowDirective, Ng2SmartTableComponent_1, SharedTableActionsComponent, CommonModule]
})
export class SmartTableComponent extends WithDestroy() implements OnInit {
  @Input({ required: true }) settings: ITableSettings;
  @Input({ required: true }) source: LocalDataSource | ServerDataSource;
  @Input() stickyLeft = false;
  @Input() stickyRight = false;
  @Input({ required: true }) tableDiv: string;
  @Input() withWrapper = false;
  @Input() downloadSettings: ITableDownloadSettings;
  @Input() header: string;
  @Input() maxHeight: '';
  @Input() hideHeader = false;
  @Output() userRowSelect = new EventEmitter();
  @ViewChild(Ng2SmartTableComponent) table: Ng2SmartTableComponent;

  currentSettings: ITableSettings;
  refreshFlag = true;
  sourceSub: Subscription;

  constructor(
    private broadcastService: BroadcastService,
    private cdr: ChangeDetectorRef,
    private stickyService: MakeColumnStickyService,
    private toaster: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    // console.log(cachedSettings);
    this.broadcastService.on('column-settings-updated').pipe(
      takeUntil(this.destroy$),
      filter((res: any) => res.tableKey === this.tableUniqKey)
    ).subscribe(res => {
      const isFilterHidden = this.currentSettings.hideSubHeader;
      this.currentSettings = applyCacheSettings(this.settings, this.tableUniqKey);
      this.cdr.markForCheck();
      this.cdr.detectChanges()

      // if hiding/showing filter, refresh component
      if (this.currentSettings.hideSubHeader !== isFilterHidden) {
        this.refreshFlag = false;
        setTimeout(() => {
          this.refreshFlag = true;
          this.cdr.markForCheck();
          this.cdr.detectChanges()
        }, 0);
      } else {
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }

    });

    // table download listener
    this.broadcastService.on('table-download-click').pipe(
      takeUntil(this.destroy$),
      filter((res: any) => res.tableKey === this.tableUniqKey)
    ).subscribe(() => {
      if (this.downloadSettings) {
        downloadReportBySourceAndSettings(this.source,
          this.currentSettings,
          this.downloadSettings.title, `${this.downloadSettings.title}.csv`,
          this.downloadSettings.cpdPipeField || [],
          this.downloadSettings.userField || ['user'],
          this.downloadSettings.resourceField || ['resource'],
          [...(this.downloadSettings.extractObjectFieldsByKey || []), 
          ...CSV_DOWNLOAD_MAPS
          // {field: 'directManager', fieldKey: 'fullName'}
        ]);
        this.toaster.success(`Downloaded file`);
      }
    })

    if (!this.tableDiv) {
      this.tableDiv = 'table-' + (Math.random() + 1).toString(36).substring(7);
    }

    if (this.settings?.columns?.action || this.settings?.columns?.actions) {
      this.stickyRight = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings && changes.settings.currentValue?.columns
      && (!(changes.settings.currentValue?.pager?.display === false))
      && !changes.settings.currentValue.tableUniqKey) {
      console.warn('Table Key not found')
    }
    if (changes.settings) {
      this.currentSettings = applyCacheSettings(changes.settings.currentValue, this.tableUniqKey);
    }
    if (changes.source) {
      this.sourceSub?.unsubscribe();
      this.sourceSub = this.getSourceChangeSub();
    }
  }

  get tableUniqKey() {
    return this.settings.tableUniqKey;
  }

  getCachedSettings(): ICacheTableSettings {
    if (localStorage.getItem(this.tableUniqKey)) {
      return JSON.parse(localStorage.getItem(this.tableUniqKey)) as ICacheTableSettings;
    }
    return null;
  }

  onSelect(event) {
    // console.log(event);
    this.userRowSelect.emit(event);
  }

  get withSticky() {
    return this.stickyLeft || this.stickyRight;
  }
  // apply sticky on table changes
  getSourceChangeSub() {
    return this.source ? this.source.onChanged().pipe(
      takeUntil(this.destroy$)
    ).subscribe((r) => {
      try {
        if (this.stickyLeft) {
          this.stickyService.floatSingleColumn({ fromLeft: true, includingHeader: true, tableID: this.tableDiv });
        }
        if (this.stickyRight) {
          this.stickyService.floatSingleColumn({ fromLeft: false, hoverToDisplay: true, tableID: this.tableDiv });
        }

      }
      catch (e) {
        console.log(e);
      }
    }) : of(null).subscribe();
  }

}

export interface ITableSettings {
  tableUniqKey?: LocalTableUniqKey;
  hideSubHeader?: boolean | 'not set';
  actions?: boolean;
  selectMode?: string;
  columns: { [k: string]: ITableSettingColumns };
  pager?: { perPage?: number, display?: boolean };
}

export function getCachedSettings(uniqKey: LocalTableUniqKey): ICacheTableSettings {
  if (localStorage.getItem(uniqKey)) {
    return JSON.parse(localStorage.getItem(uniqKey)) as ICacheTableSettings;
  }
  return null;
}

export function applyCacheSettings(settings: ITableSettings, uniqKey: LocalTableUniqKey) {
  settings = cloneDeep(settings);
  const cachedSettings = getCachedSettings(uniqKey);
  const allCurrentColumns = Object.keys(settings.columns);
  const allHidenableColumns = allCurrentColumns.filter(col => !(settings.columns[col].exemptFromEdit || settings.columns[col].isSticky));

  // order according to cached preference, unless there's new update in table due to release
  if (cachedSettings?.orderedColumns) {
    if (cachedSettings.orderedColumns.length === allCurrentColumns.length
      && allCurrentColumns.every(c => cachedSettings.orderedColumns.includes(c))) {
      const orderedColumnSettings = {};
      cachedSettings.orderedColumns.forEach((c: string) => orderedColumnSettings[c] = settings.columns[c]);
      settings.columns = orderedColumnSettings as any;
    }
  }
  // hide cols according to cached columns
  let hiddenCols;
  if (cachedSettings?.columns) {
    hiddenCols = Object.keys(settings.columns)
      .filter(col => {
        if (settings.columns[col].exemptFromEdit || settings.columns[col].isSticky) {
          return false;
        }
        return !cachedSettings.columns.includes(col);
      });
  } else {
    hiddenCols = Object.keys(settings.columns).filter(col => {
      return settings.columns[col].defaultToShow === false;
    });
  }
  settings = { ...settings, columns: omit(settings.columns, hiddenCols) };
  if (cachedSettings && cachedSettings.hideSubHeader !== undefined) {
    settings.hideSubHeader = cachedSettings.hideSubHeader;
  }
  if (cachedSettings?.perPage) {
    settings.pager = {perPage: cachedSettings.perPage};
  }
  return settings;
}



export interface ITableSettingColumns {
  title: string;
  isSticky?: boolean;
  type?: string;
  renderComponent?;
  onComponentInitFunction?
  compareFunction?;
  filterFunction?;
  width?;
  exemptFromEdit?;
  defaultToShow?;
  valuePrepareFunction?: (string) => string;
  sort?: boolean;
  filter?: boolean;

}

export interface ITableDownloadSettings {
  title: string;
  cpdPipeField?: string[];
  resourceField?: string[];
  extractObjectFieldsByKey?: { field; fieldKey }[];
  userField?: string[];
}
