<ui-modal-single [heading]="(modalHeader || (activityStatus || '') + ' Activities')">
    <ng-template #contentSlot>
        <app-smart-table [source]="source" [settings]="settings"
         tableDiv="progress-modal" [withWrapper]="true"
         [header]="tableHeader"
         ></app-smart-table>
    </ng-template>

    <ng-template #rightSlot>
        <button class="btn btn-outline-light" (click)="dismiss()">Close</button>
    </ng-template>
    
</ui-modal-single>