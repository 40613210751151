<ui-modal-single heading="Move to Another Direct Manager">
    <ng-template #contentSlot>
        <app-select-direct-manager [form]="control" 
        [label]= "members?.length > 1 ? 'Who should these users report to?' : 'Which Direct Manager should we move this user to?'"
         [replacedManager]="replacedManager"></app-select-direct-manager>
        <div class="mt-4">
            <app-warning-banner copy="<span>Temporarily: Please contact our <a class='text-decoration-underline' href='https://www.ausmed.com.au/about/contact' target='_blank'>Customer Support Team</a> after actioning this change. This will ensure the correct manager is assigned for each user's performance review tasks</span>"></app-warning-banner>

        </div>
    </ng-template>

    <ng-template #rightSlot>
        <div class="d-flex gap-2">
            <button (click)="dismiss()" class=" btn btn-outline-light">Close</button>
            <app-processing-button [disabled]="control.invalid" [text]="'Update'"
             btnClass="btn-success" [isLoading]="isLoading" (click)="onUpdate()"></app-processing-button>
            <!-- <button [disabled]="control.invalid"  class="btn btn-success" (click)="onUpdate()">Update</button> -->
        </div>
    </ng-template>

</ui-modal-single>