import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ComplianceStatusTransformedDisplay } from 'src/app/shared/pipes/compliance-status.pipe';

@Component({
  selector: 'app-assign-status-cell',
  templateUrl: './assign-status-cell.component.html',
  styleUrls: ['./assign-status-cell.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class AssignStatusCellComponent implements OnInit {
  @Input() value: ComplianceStatusTransformedDisplay;
  @Input() textClass;
  ASSIGN_COLOUR_DICT = ASSIGN_COLOUR_DICT;
  constructor() { }

  ngOnInit(): void {
  }

}

export const ASSIGN_COLOUR_DICT: { [key in ComplianceStatusTransformedDisplay]: string } = {
  Open: 'neutral-400',
  Missed: 'danger',
  Overdue: 'warning',
  Completed: 'success',
  Scheduled: 'neutral-800',
  Skipped: 'neutral-300',
}