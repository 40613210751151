<div class="d-flex justify-content-between align-items-center mb-2">
    <h3>View Extension History</h3>
    <app-download-csv-button (buttonClick)="downloadCSV()"></app-download-csv-button>
</div>

<div class="table-wrap table-overflow">
    <app-smart-table [source]="tableSource" [settings]="tableSettings"
        tableDiv="extension-history-table"></app-smart-table>
</div>
<p class="mt-4">
    <span class="fw-semibold">Original Due date: </span>
    <span class>{{originDueDate | localDate}} </span>
</p>