import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';


@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  standalone: true,
  imports: [UIStreamlineIconComponent]
})
export class BackButtonComponent implements OnInit {
  @Input() text = 'Back';
  @Input() cssClasses = '';
  @Input() defaultBack = false;
  @Output() backClick = new EventEmitter();

  constructor(
    private _location: Location,
  ) { }

  ngOnInit(): void {
  }

  // Default on click function
  onClick() {
    // this.click.emit(null);
    this.backClick.emit();
    if (this.defaultBack) {
      this._location.back();
    }
  }

}
