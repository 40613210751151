import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { resourceTypeIconDict } from '../../byo-shared/byo-resource-types';
import { getByoResourceTypeByResource } from '../../utils/get-resource-type';
import { TooltipUnderlineComponent } from '../tooltip-underline/tooltip-underline.component';
import { NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';


@Component({
    template: `
    <div class="d-flex flex-row align-items-center">
        <ui-streamline-icon [faIcon]="icon" class="pe-1 sl-icon-pb-2px"></ui-streamline-icon>

        <span>
            <ui-tooltip-underline *ngIf="value?.startsWith('Practical Activity')"
                container="body"
                [displayText]="value"
                [bodyText]="'Only managers can record completion of practical activities by adding an internal record or marking the linked requirement as done once the activity is complete.'">
            </ui-tooltip-underline>
            <span *ngIf="!value?.startsWith('Practical Activity')">
                {{ value || '-'}}
            </span>
        </span>
    <div>
    `,
    styles: [':host ::ng-deep ui-tooltip-underline > p { display: inline; }', '.sl-icon-pb-2px { padding-bottom: 2px; }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TooltipUnderlineComponent, UIStreamlineIconComponent]
})

export class ResourceTypeDisplayComponent implements OnInit {
    @Input() rowData;
    @Input() value;
    @Input() customValues: {valueFn?: (string) => string, icon};
    resource;
    icon = '';
    constructor() { }

    ngOnInit() {
        if (!this.rowData.resource) {
            return;
        }
        if (this.customValues) {
            this.value = this.customValues.valueFn(this.value);
            this.icon = this.customValues.icon;
            return;
        }
        this.resource = cloneDeep(this.rowData.resource);
        if (this.resource?.orgID || this.resource?.orgResource) {
            const { icon: resourceTypeIcon, title: resourceTypeTitle } = getByoResourceTypeByResource(this.resource);
            this.icon = resourceTypeIcon;
            this.value = resourceTypeTitle;
        } else {
            this.icon = this.resource?.resourceType ? resourceTypeIconDict[this.resource.resourceType] : (resourceTypeIconDict[this.resource.activityType] || '');
        }

    }
}
