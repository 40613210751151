import { ToastrService } from 'ngx-toastr';

export function copyToClipboard(val: string, toaster: ToastrService, options?) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if (options?.hideToast) {
      return;
    }
    toaster.success('Link successfully copied');
  }
