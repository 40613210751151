import { pipe } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IComplianceFramework } from '../../../settings/interfaces/IComplianceFramework';
import { OrganisationService } from '../../../../services/organisation.service';
import { CustomisedSingleSelectComponent } from '../../../../shared/components/customised-single-select/customised-single-select.component';
import { take, takeUntil } from 'rxjs/operators';
import { IOrgFramework, OrgFrameworksService } from 'src/app/services/org-frameworks.service';
import { FRAMEWORKS_ENUM } from 'src/app/pages/settings/interfaces/IOrgLMSSetting';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';

@Component({
    selector: 'app-capability-selector',
    templateUrl: './capability-selector.component.html',
    standalone: true,
    imports: [CustomisedSingleSelectComponent]
})
export class CapabilitySelectorComponent extends WithDestroy() implements OnInit {
    @Input() controls;
    @Input() submitted;
    options: {
        label: string,
        value: FRAMEWORKS_ENUM | string,
        frameworkType: 'Ausmed' | 'Org';
    }[];

    constructor(
        private orgFrameworksService: OrgFrameworksService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.orgFrameworksService.fetchCachedOrgCapabilityFrameworks().pipe().subscribe(
            (orgCapabilityFrameworks: IOrgFramework[]) => {
                this.options = orgCapabilityFrameworks.filter(capability => !!capability.orgStandards).map(f => ({
                    label: f.name,
                    value: f.frameworkID,
                    frameworkType: 'Org'
                }))
                this.controls.framework.setValue(this.options[0].value);
                // set default value
                if (!this.controls.framework?.value && this.options) {
                    this.reset();
                }
            }
        )

    }

    reset() {
        this.controls.framework.setValue(this.options[0].value);
    }

}
