import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ProcessingButtonComponent } from '@ui/buttons/processing-button/processing-button.component';
import * as dayjs from 'dayjs';
import { IPerformanceReviewAssignFull, ITablePerformanceReviewAssignFull, PerformanceReviewService, TASK_ASSIGN_STATUS_ENUM, TaskQuestionType, TEMPLATE_QUESTION_TYPE_OPTIONS } from 'src/app/services/performance-review.service';
import { UserService } from 'src/app/services/user.service';
import { CKEditorComponent } from 'src/app/shared-modules/ckeditor4-angular/ckeditor.component';
import { DisableAndShowTextDirective } from 'src/app/shared/directives/disable-and-show-text.directive';
import { HtmlSanitizerPipe } from 'src/app/shared/pipes/html-sanitizer.pipe';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { EDITOR_CONFIG_SIMPLE } from 'src/config/ckeditor-config';

@Component({
  selector: 'app-task-manager-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ProcessingButtonComponent, HtmlSanitizerPipe,
    DisableAndShowTextDirective, NgbTooltip, CKEditorComponent],
  templateUrl: './task-manager-input.component.html',
  styleUrl: './task-manager-input.component.scss'
})
export class TaskManagerInputComponent extends ConfirmationModal {
  task = input<ITablePerformanceReviewAssignFull>();
  taskStatusChanged = output<IPerformanceReviewAssignFull>()
  template = computed(() => this.task().taskTemplate)
  showCloseButton = input(false);
  maxHeight = input('');
  EDITOR_CONFIG = EDITOR_CONFIG_SIMPLE;
  editorConfig = computed(() => ({...EDITOR_CONFIG_SIMPLE, height: '48vh'}))

  answeredVersion = computed(() => {
    const allVersions = (this.template().pastVersions || []).concat(this.template().currentVersion)
    const usedVersion = this.task().response?.version || this.task().managerComment?.version;
    return usedVersion ? allVersions.find(v => v.version === usedVersion) || this.template().currentVersion
    : this.template().currentVersion
  })

  questions = computed(() => this.answeredVersion().taskObjects?.map(objects => objects.questions).flat().sort((a, b) => a.order - b.order));

  isScheduled = computed(() => this.task().status === TASK_ASSIGN_STATUS_ENUM.scheduled);
  canComment = computed(() => this.task().managerUserID === this.userService.plainOrgUser.userID && !this.isScheduled());

  orderedResponses = computed(() => this.questions()
    .map(q => {
      const response = this.task().response?.responses?.find(r => r.questionID === q.questionID);
      if (!response) {
        return '';
      }
      if (response.answerText) {
        if (q.qType === 'date-picker') {
          return dayjs(response.answerText).format('DD/MM/YYYY')
        }
        return response.answerText;
      }
      if (!q.options?.length) {
        return ''
      }
      if (response.answerOption || response.answerOption === 0) {
        return q.options[+response.answerOption] || response.answerOption
      }
      if (response.multipleAnswers?.length) {
        return response.multipleAnswers.map(r => q.options[r])?.join(', ');
      }
    }));

  orderedComments = computed(() => this.questions().map(q => this.task().managerComment?.responses?.find(r => r.questionID === q.questionID)));

  commentForms = computed(() => {
    return new FormArray(this.questions().map(() => new FormControl({value: '', disabled: !this.canComment()})))
  });
  pillText = computed(() => {
    if (this.task().managerComment) {
      return 'Notes Submitted- View Only';
    }
    if (this.task().managerUserID !== this.userService.plainOrgUser.userID) {
      return 'Only this user’s manager can add meeting notes'
    }
    if (this.isScheduled()) {
      return 'Meeting not yet open. Available from ' + this.localDatePipe.transform(this.task()?.openDate)
    }
  });


  performanceReviewService = inject(PerformanceReviewService);
  userService = inject(UserService);
  localDatePipe = inject(LocalDatePipe);

  submit() {
    this.isLoading = true
    const payload = {
      version: this.answeredVersion().version,
      responses: this.commentForms().value.map((val, i) => {
        return {
          questionID: this.questions()[i].questionID,
          responseText: val
        }
      })
    }
    this.performanceReviewService.addComment(this.task().taskID, payload)
    .subscribe(task => {
      this.taskStatusChanged.emit({...task.task, managerComment: task.managerResponse});
      this.performanceReviewService.updateCachedMyTasks(task.task);
      const message = (this.task().status !== TASK_ASSIGN_STATUS_ENUM.completed && task.task?.status === TASK_ASSIGN_STATUS_ENUM.completed) ? 'Marked as complete' : 'Comments Added';
      this.confirmSuccess(message, task.task);
    }, error => this.error(error))
  }

  getQuestionTypeDisplay(type: TaskQuestionType) {
    if (type === 'ratings') {
      return 'Rating';
    }
    return TEMPLATE_QUESTION_TYPE_OPTIONS.find(o => o.value === type).display
  }

  // ask for confirmation if commenting in progress
  onCloseClicked() {
    if (!this.task().managerComment && this.commentForms()?.value?.some(el => el)) {
      this.closeConfirmation();
    } else {
      this.dismiss()
    }
  }
}
