import {
  RESOURCE_TYPE_DISPLAY_ENUM,
  RESOURCE_TYPE_ENUM,
  resourceTypeDict,
  resourceTypeIconDict,
  IOrgResource,
  SURVEY_DISPLAY_DICT,
  PRACTICAL_DISPLAY_DICT
} from '../byo-shared/byo-resource-types';

/**
 * for back compatibility
 * get resource type from old LMS resource obj
 * @param resource obj
 * @return RESOURCE_TYPE_ENUM
 */
export function getByoResourceTypeByField(resource: any): RESOURCE_TYPE_ENUM.video | RESOURCE_TYPE_ENUM.weblink {
  let type;
  if (resource.videoUrl) {
    type = RESOURCE_TYPE_ENUM.video;
  } else {
    type = RESOURCE_TYPE_ENUM.weblink;
  }
  return type;
}

export function getByoResourceTypeByResource(resource): { value: RESOURCE_TYPE_ENUM, title: RESOURCE_TYPE_DISPLAY_ENUM, icon: string } {
  let value, title, icon;
  value = resource.resourceType || getByoResourceTypeByField(resource);

  // if it's a form, need to display the form type
  title = TYPES_WITH_SUBTYPES.includes(value)
    ? getSubTypeDisplay(resource)
    : resourceTypeDict[value] ? resourceTypeDict[value] : 'Other';

  icon = resourceTypeIconDict[value] ? resourceTypeIconDict[value] : '';
  return { value, title, icon };
}

// for column display
export function getItemTypeByResource(resource) {
  if (!resource) {
    return 'None';
  }
  if (resource?.orgID || resource?.orgResource) {
    const { title: resourceTypeDisplay } = getByoResourceTypeByResource(resource);
    return resourceTypeDisplay || 'None';
  } else {
    return resource?.resourceType ? (resourceTypeDict[resource.resourceType] || 'None') : (resourceTypeDict[resource?.activityType] || (resource?.activityType || 'None'));
  }
}

export function getSubTypeDisplay(resource): string {
  const typeDisplay = resourceTypeDict[(resource.resourceType)];
  const subType = resource?.subType ? resource?.subType : resource?.orgResourceDetail?.orgResourceExtraDetail?.subType
    || resource?.extraDetails?.subType // resource in report api
    || '';
  const allSubtypeDict = { ...SURVEY_DISPLAY_DICT, ...PRACTICAL_DISPLAY_DICT };
  if (subType && allSubtypeDict[subType]) {
    return `${typeDisplay} > ` + allSubtypeDict[subType];
  }
  return typeDisplay;
}

export const TYPES_WITH_SUBTYPES = [RESOURCE_TYPE_ENUM.practicalActivity, RESOURCE_TYPE_ENUM.form];
