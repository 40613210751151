import { Component, signal } from '@angular/core';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-collapse-horizontal',
  standalone: true,
  imports: [NgbCollapse, UIStreamlineIconComponent],
  templateUrl: './collapse-horizontal.component.html',
  styleUrl: './collapse-horizontal.component.scss'
})
export class CollapseHorizontalComponent {
  isCollapsed = signal(false)
}
