<app-shared-overview
    [title]="'Report'"
    [cardBoldedText]="'Reporting tools'"
    cardText="help you extract key information for presentation to auditors and stakeholders or analysis."
    [showUnifiedPageHeader]="true"
>

    <div class="mb-8">
        <app-overview-shortcuts [shortcutType]="'learningReport'"></app-overview-shortcuts>
    </div>

    <div class="mb-8">
        <h3 class="mb-4">Recommended</h3>

        <app-general-carousel *ngIf="!hideSwiper">
            <div class="swiper-slide" style="max-width: 240px; min-width: 240px; height: 230px;"
            *ngFor="let item of items; let i = index">
            <app-overview-card *featureFlag="item.featureFlag" [item]="item" textClass="mt-2" textSizeClass="f-caption" class="card-reporting-size">
               <ng-container customIcon>
                   <ui-avatar  [slIcon]="item.reportSlIcon" class="mb-2" [iconBackgroundColor]="item.iconBgColor" textColor="text-white" ></ui-avatar>
               </ng-container>
           </app-overview-card>
       </div>
        </app-general-carousel>
        <div *ngIf="hideSwiper" class="d-flex flex-wrap mt-4 gap-2">
            <div  style="max-width: 240px; min-width: 240px; height: 230px;"
            *ngFor="let item of items; let i = index">
            <app-overview-card *featureFlag="item.featureFlag" [item]="item" textClass="mt-2" textSizeClass="f-caption" class="card-reporting-size">
               <ng-container customIcon>
                   <ui-avatar  [slIcon]="item.reportSlIcon" class="mb-2" [iconBackgroundColor]="item.iconBgColor" textColor="text-white"></ui-avatar>
               </ng-container>
           </app-overview-card>
       </div>
    </div>
    </div>

    <div class="mb-8">
        <h3 class="mb-2">Categories</h3>
        <div class="grid-halfs mt-4 gap-8">
            <ng-container *ngFor="let item of categories">
                <!-- <app-overview-card [item]="item">
                    <ng-container subheading>
                        <ng-container *ngIf="item.isAdvanced">
                            <button class="me-2 btn btn-success btn-sm btn-rounded f-caption">Upgrade</button>
                        </ng-container>
                    </ng-container>
               </app-overview-card> -->
               <app-report-category-card *featureFlag="item.featureFlag" [item]="item" [category]="item.reportCategory"></app-report-category-card>
            </ng-container>
        </div>
    </div>

    <!-- <div class="card bg-green-100 p-4">
        <h3 class="mb-2">Upgrade to Advanced Reports</h3>
        <div class="grid-thirds gap-2">
            <ng-container *ngFor="let advanced of advancedReports">
                <app-advanced-card [item]="advanced"></app-advanced-card>
            </ng-container>

        </div>
    </div> -->

</app-shared-overview>
