import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { cloneDeep, replace, unset } from 'lodash';
import { getAssignCsvTitle } from 'src/app/services/org-assign.service';
import { findKey, omit, pluck } from 'underscore';
import * as dayjs from 'dayjs';
import { replaceObjectAttr } from './replace-object-attr';
import { ServerDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/server/server.data-source';

export interface IDownloadExtractFieldKey {
  field: string;
  fieldKey: string;
}

export interface ITransparentKeyMap {
  field: string;
  csvDisplayHeader: string;
}

export function downloadReport(args: ICsvConfig): void {
  let csv = convertArrayOfObjectsToCSV(args);
  if (csv == null) {
    return;
  }
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  const result = replace(encodeURI(csv), /#/g, '%23');
  const link = document.createElement('a');
  link.setAttribute('href', result);
  const fileNameWithTimestamp = args.fileName.split('.')[0] + ` on ${dayjs().format('YYYY-MM-DD').toString()} at ${dayjs().format('HH:mm:ss a').toString()}` + '.' + args.fileName.split('.')[1];
  link.setAttribute('download', fileNameWithTimestamp);
  link.click();
}

export function downloadMultipleReportsInOneFile(argsList: ICsvConfig[], fileName?: string): void {
  let csvs = '';
  for (const args of argsList) {
    const csv = convertArrayOfObjectsToCSV(args);
    if (csv == null) {
      break;
    }
    if (csvs.length) {
      csvs += ('\n\n' + csv);
    } else {
      csvs += csv;
    }

  }
  if (!csvs.match(/^data:text\/csv/i)) {
    csvs = 'data:text/csv;charset=utf-8,' + csvs;
  }
  const result = replace(encodeURI(csvs), /#/g, '%23');
  const link = document.createElement('a');
  link.setAttribute('href', result);
  link.setAttribute('download', fileName);
  link.click();
}


export function downloadByoItems(args: ICsvConfig): void {
  let csv = convertItemsArrayToCSV(args);
  if (csv == null) {
    return;
  }
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  const result = encodeURI(csv);
  const link = document.createElement('a');
  link.setAttribute('href', result);
  link.setAttribute('download', args.fileName);
  link.click();
}

function convertItemsArrayToCSV(args: ICsvConfig) {
  let csv = args.title + '\n';
  args.headers.forEach(
    element => {
      csv += (element + ',');
    }
  )
  csv = csv.slice(0, -1) + '\n';
  args.data.forEach(
    _item => {
      for (let _data of _item) {
        if (_data === 'null' || _data === 'undefined') {
          csv += `"-",`;
          continue;
        }
        csv += `"${_data}",`;
      }
      csv = csv.slice(0, -1) + '\n';
    }
  )
  return csv;
}

/*@Reminder:  extractObjectFieldsByKey is a general method: orgUser, cpdPIpe, resourceField are specific cases to handle
 * set `exemptFromEdit` to be true in the column object when the column should not be included in the csv and edited; default to be false
 * Please use the latest version below
 * */
export function downloadReportBySourceAndSettings<T extends LocalDataSource>(source: T, settings: any, csvTitle: string, csvFileName: string, cpdPipeField: string[] = [], orgUserFields?: string[], resourceFields?: string[], extractObjectFieldsByKey?: IDownloadExtractFieldKey[], skipValuePrepareFields: string[] = [], newPares?) {
  const userCol = findUserColName(settings.columns);
  const columns = preprocessColumns(cloneDeep(settings.columns));
  let headers = Object.keys(columns).filter(key => !columns[key].exemptFromEdit).filter(key => !columns[key].exemptFromDownload).map(key => columns[key].title);
  source.getFilteredAndSorted().then((records) => {
    const data = records.map(record => {
      const processedRow = {};
      Object.keys(columns).filter(key => !columns[key].exemptFromEdit).filter(key => !columns[key].exemptFromDownload).forEach((key) => {
        const downloadFn = columns[key].downloadFunction || columns[key].valuePrepareFunction
        if (downloadFn && skipValuePrepareFields.indexOf(key) < 0) {
          processedRow[key] = downloadFn(record[key], record);
        } else {
          processedRow[key] = record[key];
        }
        if (key === 'firstName' || key === 'lastName') {
          addUserNames(processedRow, record, userCol);
        }
      });
      return processedRow;
    });
    const args = {
      data,
      title: csvTitle,
      headers,
      extractObjectFieldsByKey,
      resourceFields,
      orgUserFields,
      cpdPipeField,
      fileName: csvFileName,
    } as ICsvConfig;
    downloadReport(args);
  });
}

/**
 * split a string into two substrings by the last dot.
 * @param str 
 * @returns 
 */
function splitByLastDot(str: string) {
  const lastDotIndex = str.lastIndexOf('.');

  // if there is no dot in str
  if (lastDotIndex === -1) {
    return [str, ''];
  }

  const part1 = str.slice(0, lastDotIndex);
  const part2 = str.slice(lastDotIndex + 1);

  return [part1, part2];
}

// Download Matrix table with 'Estimated Time to Complete Plan' only
export function downloadTPMatrixTableBySourceAndSettings<T extends LocalDataSource>(estimatedTimesRow, source: T, settings: any, csvTitle: string, csvFileName: string, cpdPipeField: string[] = [], orgUserFields?: string[], resourceFields?: string[], extractObjectFieldsByKey?: IDownloadExtractFieldKey[], skipValuePrepareFields: string[] = [], newPares?) {
  const userCol = findUserColName(settings.columns);
  const columns = preprocessColumns(cloneDeep(settings.columns));
  let headers = Object.keys(columns).filter(key => !columns[key].exemptFromEdit).filter(key => !columns[key].exemptFromDownload).map(key => columns[key].title);
  source.getFilteredAndSorted().then((records) => {
    const data = records.map(record => {
      const processedRow = {};
      Object.keys(columns).filter(key => !columns[key].exemptFromEdit).filter(key => !columns[key].exemptFromDownload).forEach((key) => {
        if (columns[key].valuePrepareFunction && skipValuePrepareFields.indexOf(key) < 0) {
          processedRow[key] = columns[key].valuePrepareFunction(record[key], record);
        } else {
          processedRow[key] = record[key];
        }
        if (key === 'firstName' || key === 'lastName') {
          addUserNames(processedRow, record, userCol);
        }
      });
      return processedRow;
    });

    const args = {
      data,
      title: csvTitle,
      headers,
      extractObjectFieldsByKey,
      resourceFields,
      orgUserFields,
      cpdPipeField,
      fileName: csvFileName,
    } as ICsvConfig;
    let csv = convertArrayOfObjectsToCSV(args);
    if (csv == null) {
      return;
    }
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    csv += '"Estimated Time to Complete Plan",';
    estimatedTimesRow.forEach(v => {
      csv += ('"' + v + '",');
    });
    const result = replace(encodeURI(csv), /#/g, '%23');
    const link = document.createElement('a');
    link.setAttribute('href', result);

    const [fileName1, fileName2] = splitByLastDot(args.fileName);
    const fileNameWithTimestamp = fileName1 + ` on ${dayjs().format('YYYY-MM-DD').toString()} at ${dayjs().format('HH:mm:ss a').toString()}` + '.' + fileName2;
    link.setAttribute('download', fileNameWithTimestamp);
    link.click();
  });
}

/**
 * The upgrade of previous version ^
 * @param source: LocalDataSource or customised DataSource
 * @param settings: ng2-smart-table settings but you need to set csvTitle and csvFileName in the object!
 * @param csvTitle
 * @param csvFileName
 * @param extractObjectFieldsByKey
 * @param transparentKeyMaps: the keys of data in the source that should be included in the csv but not included in the columns settings
 */
// TODO: refactor type description
export interface IDownloadCSVExtensibleConfig {
  source: LocalDataSource | ServerDataSource;
  settings: any;
  csvTitle: string;
  csvFileName: string;
  extractObjectFieldsByKey?: IDownloadExtractFieldKey[];
  transparentKeyMaps?: ITransparentKeyMap[];
  withoutTitleRow?: boolean;
  remaps?: any // use another attr in the row data
}

export function downloadCSVBySourceAndSettings(downloadCSVConfig: IDownloadCSVExtensibleConfig) {
  const { source, settings, csvTitle, csvFileName, extractObjectFieldsByKey = [], transparentKeyMaps = [], remaps = {} } = downloadCSVConfig;
  const userCol = findUserColName(settings.columns);
  const columns = preprocessColumns(cloneDeep(settings.columns));
  const rawHeaders = Object.keys(columns).filter(key => !columns[key].exemptFromEdit).map(key => columns[key].title);
  const transparentHeaders = transparentKeyMaps.map(({ csvDisplayHeader = '' }) => csvDisplayHeader);
  const transparentKeys = transparentKeyMaps.map(({ field }) => field).filter(Boolean);
  const headers = [...rawHeaders, ...transparentHeaders];
  source.getFilteredAndSorted().then((records) => {
    const data = records.map(record => {
      const processedRow = {};
      Object.keys(columns).filter(key => !columns[key].exemptFromEdit).forEach((key) => {
        key = remaps[key] || key;

        const downloadFunction = columns[key]?.valuePrepareFunction || columns[key]?.downloadFunction
        if (downloadFunction) {
          processedRow[key] = downloadFunction(record[key], record);
        } else {
          processedRow[key] = record[key];
        }
        if (key === 'firstName' || key === 'lastName') {
          addUserNames(processedRow, record, userCol);
        }
      });
      if (transparentKeys.length) {
        transparentKeys.forEach((field) => processedRow[field] = record[field] || 'This field is undefined in source');
      }
      return processedRow;
    });
    const args = {
      data,
      title: csvTitle,
      headers,
      extractObjectFieldsByKey,
      fileName: csvFileName,
      withoutTitleRow: downloadCSVConfig.withoutTitleRow
    } as ICsvConfig;
    downloadReport(args);
  });
}
// Please use downloadMultipleReportsInOneFile() after this function
export async function getCSVArgsBySourceAndSettings(downloadCSVConfig: IDownloadCSVExtensibleConfig) {
  const { source, settings, csvTitle, csvFileName, extractObjectFieldsByKey = [], transparentKeyMaps = [] } = downloadCSVConfig;
  const columns = cloneDeep(settings.columns);
  const rawHeaders = Object.keys(columns).filter(key => !columns[key].exemptFromEdit).map(key => columns[key].title);
  const transparentHeaders = transparentKeyMaps.map(({ csvDisplayHeader = '' }) => csvDisplayHeader);
  const transparentKeys = transparentKeyMaps.map(({ field }) => field).filter(Boolean);
  const headers = [...rawHeaders, ...transparentHeaders];
  return source.getFilteredAndSorted().then((records) => {
    const data = records.map(record => {
      const processedRow = {};
      Object.keys(columns).filter(key => !columns[key].exemptFromEdit).forEach((key) => {
        if (columns[key].valuePrepareFunction) {
          processedRow[key] = columns[key].valuePrepareFunction(record[key], record);
        } else {
          processedRow[key] = record[key];
        }
      });
      if (transparentKeys.length) {
        transparentKeys.forEach((field) => processedRow[field] = record[field] || 'This field is undefined in source');
      }
      return processedRow;
    });
    const args = {
      data,
      title: csvTitle,
      headers,
      extractObjectFieldsByKey,
      fileName: csvFileName,
    } as ICsvConfig;
    return args;
  });
}


function convertArrayOfObjectsToCSV(args: ICsvConfig) {
  let result = '';
  let ctr = 0;
  const data = args.data || null;
  const keys = data[0] ? Object.keys(data[0]) : [];
  const columnDelimiter = args.columnDelimiter ? args.columnDelimiter : ',';
  const lineDelimiter = args.lineDelimiter ? args.lineDelimiter : '\n';
  const title = (args.title ? args.title : '');
  const timeStamp = '\n' + 'CSV Downloaded on ' + dayjs().format('YYYY-MM-DD').toString() + ' at ' + dayjs().format('HH:mm:ss a').toString();

  if (data == null || !data.length) {
    return null;
  }
  if (!args.withoutTitleRow) {
    result += title;
    result += timeStamp;
    result += lineDelimiter;
  }

  if (args.headers && args.headers.length === keys.length) {
    result += args.headers.map(h => '"' + h + '"').join(columnDelimiter); // anticipate if there's comma in header
  } else {
    result += keys.join(columnDelimiter);
  }
  result += lineDelimiter;

  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      let temp = item[key] == null ? '' : item[key];
      if (item[key] instanceof Array && item[key].length > 1) {
        temp = temp.join(',\n');
      }
      if (temp.replaceAll) {
        temp = temp.replaceAll('"', '""'); // escape double quotes
      }

      if (args.cpdPipeField?.includes(key)) {
        result += transformCPD(item[key]);
        ctr++;
      } else if (args.orgUserFields && args.orgUserFields.includes(key)) {
        result += (item[key] && item[key].fullName) || '';
        ctr++;
      } else if (args.resourceFields && args.resourceFields.includes(key)) {
        result += (item[key] && item[key].title && item[key].title.toString().replaceAll(columnDelimiter, '-')) || '-';
        ctr++;
      } else if (args.extractObjectFieldsByKey && args.extractObjectFieldsByKey.some((fieldAndKey) => fieldAndKey.field === key)) {
        const { fieldKey } = args.extractObjectFieldsByKey.find(({ field }) => field === key);
        const resultObject = item[key];
        if (isArray(resultObject)) {
          result += item[key].map((resultObjectItem) => resultObjectItem[fieldKey]?.toString().replaceAll(columnDelimiter, '-')).join('; ');
        } else {
          result += (resultObject && resultObject[fieldKey]?.toString().replaceAll(columnDelimiter, '-')) || '-';
        }
        ctr++;
      } else {
        // avoid treating the comma inside each item treat as columnDelimiter.
        // result += '\"' + item[key] + '\"';
        result += '"' + temp + '"';
        ctr++;
      }
    });
    result += lineDelimiter;
  });

  return result;
}

// add first and last name to col settings
export function preprocessColumns(columns) {
  const userCol = findUserColName(columns);
  if (userCol) {
    return replaceObjectAttr(columns, userCol, {
      firstName: { title: 'First name' },
      lastName: { title: 'Last name' }
    })
  }
  return columns;
}

export function findUserColName(columns) {
  return findKey(columns, (col) => col.title === 'User' || col.title === 'Username');
}

// add first and last names to row
export function addUserNames(processedRow, record, userCol) {
  if (userCol && record[userCol]) {
    const user = record[userCol];
    processedRow['firstName'] = processedRow['firstName'] || user.firstName;
    processedRow['lastName'] = processedRow['lastName'] || user.lastName;
  }

}

export interface ICsvConfig {
  data: any[];
  title: string;
  headers: string[];
  cpdPipeField: string[];
  fileName: string;
  resourceFields?: string[];
  extractObjectFieldsByKey?: IDownloadExtractFieldKey[];
  orgUserFields?: string[];
  columnDelimiter?: string;
  lineDelimiter?: string;
  btnStyle?: string;
  withoutTitleRow?: boolean;
}

function isArray(object): object is any[] {
  return Array.isArray(object);
}

export function transformCPD(value: any, format: 'hour' | 'minute' | 'credit' = 'minute'): string {
  if (typeof value === 'undefined') {
    return '--';
  }
  if (format === 'credit') {
    return value === 0 ? 'None' : (Math.floor(value / 60) + ' credit');
  } else if (format === 'hour') {
    return value === 0 ? 'None' : ((value < 60) ? (value % 60 + 'm') : Math.floor(value / 60) + 'h ' + ((value % 60) === 0 ? '' : (value % 60 + 'm')));
  } else if (format === 'minute') {
    return value === 0 ? 'None' : value + 'm';
  }
}

export function downloadAssignTable(source, settings) {
  const title = getAssignCsvTitle();
  downloadReportBySourceAndSettings(source, settings, title, `${title}.csv`, [], ['user'], ['resource'], [{ field: 'mtPlanCell', fieldKey: 'tooltip' }]);
}
