import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { capitalize as _capitalize } from 'lodash';
import { PnpService } from 'src/app/services/pnp.service';
import { PolicyFromBackend } from '../../../interfaces/policy-and-procedure.interface';
import { IPPAckQueryForm } from '../../../reporting/reports/pp-all-ack-records/pp-all-ack-records.component';
import { uniq as _uniq } from 'lodash';
import { UISpinnerComponent } from '../../../../../ui/ui-spinner/ui-spinner.component';
import { PpServerTableComponent } from '../../../reporting/reports/pp-all-ack-records/pp-server-table/pp-server-table.component';
import { NgIf, TitleCasePipe } from '@angular/common';
import { ModalSingleComponent } from '../../../../../shared/components/modal-single/modal-single.component';
import { Subject } from 'rxjs';
import { AddPluralSPipe } from 'src/app/shared/pipes/add-plural-s.pipe';
import { take } from 'rxjs/operators';


@Component({
    templateUrl: 'linked-ack-num-modal.component.html',
    styleUrls: ['linked-ack-num-modal.component.scss'],
    standalone: true,
    imports: [ModalSingleComponent, NgIf, PpServerTableComponent, UISpinnerComponent],
    providers: [TitleCasePipe]
})

export class LinkedAckNumModalComponent implements OnInit {
    @Input() modalTitle: string;
    @Input() payload: IPPAckQueryForm;
    @Input() context: 'reporting' | 'policyManagement'; // controls which columns are shown depending on report
    policies: PolicyFromBackend[];

    MODAL_TITLE = {
      total: 'All Acknowledgement Records',
      unacknowledged: 'Unacknowledged',
      other: 'Other',
      acknowledged: 'Acknowledged',
    }
    title = ' ';
    isDataFetched = false;
    numResult$ = new Subject();

    tableSettings = {
      tableUniqKey: LocalTableUniqKey.sgrAckRecords,
      selectMode: '',
      actions: false,
      hideSubHeader: false,
      noDataMessage: 'Loading',
      columns: this.pnpService.columns
    };

    constructor(
      private pnpService: PnpService,
      private modal: NgbActiveModal,
      private addPluralSPipe: AddPluralSPipe,
      private titleCasePipe: TitleCasePipe
    ) { }

    ngOnInit() {
      if (this.modalTitle === 'unacknowledged') {
        this.tableSettings.selectMode = 'multi';
      } else {
        delete this.tableSettings.columns.actions;
      }
      if (this.context === 'policyManagement') {
        this.tableSettings.tableUniqKey = LocalTableUniqKey.sgrAckRecordsMng;
        const toRemove = ['fn_status', 'fn_govStatus', 'fn_govVersionCode']
        toRemove.forEach(col => delete this.tableSettings.columns[col])
      }
      (this.pnpService.fetchCachedAllPolicies()).pipe(take(1)).subscribe((policies) => {
        this.policies = policies;
      });
    }

    getTitle(numResults) {
      if (this.payload.filteredGovs?.length === 1 ) {
        const polTitle = this.policies.find(p => p.governanceID === this.payload.filteredGovs[0])?.title || ''
        this.title = `Showing ${this.addPluralSPipe.transform(numResults + ' ' + this.titleCasePipe.transform(this.modalTitle) + ' Requirement')}  for  ${polTitle}`;
        return;
      }
      this.title = this.MODAL_TITLE[this.modalTitle] || this.modalTitle;
    }

    closeModal() {
      this.modal.close();
    }
}
