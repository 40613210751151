<ui-modal-single [heading]="tableHeader">
    <ng-template #contentSlot>
        @if (showCategories) {
            <div class="task-activity-cycle-table__container">
                <ul ngbNav [destroyOnHide]="false" #nav="ngbNav" [activeId]="activeTabId()" (navChange)="onNavChange($event)"
                    [destroyOnHide]="true" class="nav-tabs bg-white d-flex align-items-center justify-cntent-between">
                        @for (category of categoriesInfo; track $index) {
                            <li ngbNavItem [ngbNavItem]="category.navItem">
                                <a ngbNavLink class="task-activity-cycle-table__nav-item">
                                    {{category.displayNavName}}
                                    <span class="badge badge-pill badge-primary-light">
                                        {{category.taskNum}}
                                    </span>
                                </a>
                                <ng-template ngbNavContent>
                                    <app-task-table 
                                        [forProgress]="true" 
                                        [assigns]="category.assigns" 
                                        [tableKey]="'performanceReviewTasksReportModal'"
                                        [multiSelect]="multiSelect" 
                                        (currentSettings)="currentSettings = $event"
                                        [oneStatusOnly]="activityStatus!=='All'" 
                                        (updatedAssigns)="category.assigns = $event;ngOnInit()"
                                    ></app-task-table>
                                </ng-template>
                            </li>
                        }
                    <div class="task-activity-cycle-table__button">
                        <app-shared-table-actions [tableSettings]="currentSettings"></app-shared-table-actions>
                    </div>
                </ul>
            </div>
            <div [ngbNavOutlet]="nav"></div>
        } @else {
            <div class="d-flex justify-content-end mb-2">
                <app-shared-table-actions [tableSettings]="currentSettings"></app-shared-table-actions>
            </div>
    
            <app-task-table [forProgress]="true" [assigns]="assigns" [tableKey]="'performanceReviewTasksReportModal'"
                [multiSelect]="multiSelect" (currentSettings)="currentSettings = $event"
                [oneStatusOnly]="activityStatus!=='All'" (updatedAssigns)="assigns=$event;ngOnInit()"
            ></app-task-table>
        }
    </ng-template>

    <ng-template #rightSlot>
        <button class="btn btn-outline-light" (click)="dismiss()">Close</button>
    </ng-template>

</ui-modal-single>