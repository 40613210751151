<div [@showBulkButtons]="((selectedItems.length > thresholdNum) && !hideBulkAction) ? 'open' : 'closed'" (@showBulkButtons.done)="onAnimationEvent('done')" (@showBulkButtons.start)="onAnimationEvent('start')"
    id="multi-select-header">
    <div class="bg-blue-100 d-flex justify-content-between py-2 border-start border-end border-top" >
        <div class="d-flex align-items-center">
            <div class="f-small ms-4 me-4">
                <ng-container *ngIf="!hideDefaultText">
                    <span class="fw-semibold">{{ selectedItems.length }} of {{ availableItems }} {{ itemName }}</span>
                    currently selected,
                    <span *ngIf="selectedItems.length > 0 && selectedItems.length < availableItems" (click)="onSelectAll()" class="fw-medium underline has-pointer me-2 ms-1">
                        {{ selectAllText }}
                    </span>
                    <span *ngIf="selectedItems?.length > 0" (click)="onDeselectAll()" class="fw-medium underline has-pointer me-2">
                        Clear all selections
                    </span>
                </ng-container>
                
                <ng-content select="[buttons]"></ng-content>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center table-panel" [class.card]="inCard" [class.border]="!inCard">
    <div appScrollShadow class="table-overflow border-0" [class.table-wrap]="!inCard" [id]="tableWrapID">
        <ng-content></ng-content>
    </div>
</div>
