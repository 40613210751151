import {Component, OnInit, ChangeDetectionStrategy, Input, HostBinding} from '@angular/core';
import { Params, Router } from '@angular/router';
import { IUIStreamlineIcon } from '../../../ui/streamline-icon/streamline-icon-interfaces';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgIf, NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ui-object-card',
    templateUrl: './object-card.component.html',
    styleUrls: ['./object-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgbTooltip, NgIf, AvatarComponent, NgClass]
})
export class ObjectCardComponent implements OnInit {

  @Input() route: string[];
  @Input() queryParams: Params;
  @Input() title: string;
  @Input() description: string;
  @Input() slIcon: IUIStreamlineIcon;
  @Input() tag: string;
  @Input() iconFullName: string;
  @Input() url: string;
  @Input() noAccess = false;
  @Input() badgeColor = 'light';
  @Input() actionsBtn = false;
  @Input() hideIcon = false;
  @Input() titleSize = 'small';
  @Input() underlineTitleOnHover = false;
  @Input() noPadding = false;
  attrTitle = '';
  hideActionsBtn = true;
  @HostBinding('style.cursor') cursorStyle = 'pointer';
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.attrTitle = this.noAccess ? 'You do not have permission to access this user' : '';
    this.cursorStyle = this.noAccess ? 'not-allowed' : 'pointer';
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  jumpToLink () {
    if (this.route) {
      this.router.navigate(this.route, { queryParams: this.queryParams});
    }
  }

}
