import { pipe } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IComplianceFramework } from '../../../settings/interfaces/IComplianceFramework';
import { OrganisationService } from '../../../../services/organisation.service';
import { CustomisedSingleSelectComponent } from '../../../../shared/components/customised-single-select/customised-single-select.component';
import { take, takeUntil } from 'rxjs/operators';
import { IOrgFramework, OrgFrameworksService } from 'src/app/services/org-frameworks.service';
import { FRAMEWORKS_ENUM } from 'src/app/pages/settings/interfaces/IOrgLMSSetting';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';

@Component({
    selector: 'app-framework-selector',
    templateUrl: './framework-selector.component.html',
    styleUrls: ['./framework-selector.component.scss'],
    standalone: true,
    imports: [CustomisedSingleSelectComponent]
})
export class FrameworkSelectorComponent extends WithDestroy() implements OnInit {
  @Input() controls;
  @Input() submitted;
  @Input() options: {
    label: string,
    value: FRAMEWORKS_ENUM | string,
    frameworkType: 'Ausmed' | 'Org';
  }[] = this.organisationService.getFullEnabledFrameworks().map(cf => ({
    label: cf.title,
    value: cf.value,
    frameworkType: 'Ausmed',
  }));

  constructor(
      private organisationService: OrganisationService,
      private orgFrameworksService: OrgFrameworksService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.orgFrameworksService.getEnabledFrameworks().pipe().subscribe(
      (orgFrameworks: IOrgFramework[]) => {
        this.options = this.options.concat(orgFrameworks.map(f => ({
          label: f.name,
          value: f.frameworkID,
          frameworkType: 'Org'
        })));
        // set default value
        if (!this.controls.framework.value && this.options) {
          this.reset();
        }
      }
    )

  }

  reset() {
    this.controls.framework.setValue(this.options[0].value);
  }

}
