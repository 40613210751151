import { IOrgUser, UserService } from 'src/app/services/user.service';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { ModalSingleComponent } from "../../../../shared/components/modal-single/modal-single.component";
import { FormsModule, ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoalService, IGoalNew, ILearningGoal } from 'src/app/services/goal.service';
import { UISpinnerComponent } from "../../../../ui/ui-spinner/ui-spinner.component";
import { ToastrService } from 'ngx-toastr';
import { NgbDateSelectorComponent } from "../../../../shared/components/ngb-date-selector/ngb-date-selector.component";
import { NgIf } from '@angular/common';
import { CustomisedMultiSelectComponent } from "../../../../shared/components/customised-multi-select/customised-multi-select.component";
import { CustomisedSingleSelectComponent, ISingleSelectOption } from "../../../../shared/components/customised-single-select/customised-single-select.component";
import { switchMap, take, takeUntil } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import { HierarchyService } from 'src/app/pages/admin/hierarchy/hierarchy.service';
import { combineLatest } from 'rxjs';
import { PerfReviewPermissionsService } from '../../perf-review-permissions.service';

@Component({
  selector: 'app-performance-review-add-edit-goal-modal',
  standalone: true,
  imports: [ModalSingleComponent, FormsModule, ReactiveFormsModule, UISpinnerComponent, NgbDateSelectorComponent, NgIf, CustomisedMultiSelectComponent, CustomisedSingleSelectComponent],
  templateUrl: './performance-review-add-edit-goal-modal.component.html',
  styleUrl: './performance-review-add-edit-goal-modal.component.scss'
})
export class PerformanceReviewAddEditGoalModalComponent extends WithDestroy() implements OnInit {
  @Input() action!: 'Add' | 'Edit';
  @Input() currentGoal: ILearningGoal;
  goalForm: FormGroup;
  isProcessing = signal(false);
  hierarchyService = inject(HierarchyService);
  perfReviewPermissionService = inject(PerfReviewPermissionsService);
  min: string;
  activeUsersOption: ISingleSelectOption[];
  user: IOrgUser;

  constructor(
    private activeModal: NgbActiveModal,
    private goalService: GoalService,
    private toaster: ToastrService,
    private userService: UserService,
  ) { super(); }

  ngOnInit(): void {
    combineLatest([this.userService.fetchCachedOrgUsers(), this.userService.getOrgUser()]).pipe(take(1))
    .subscribe(
      ([allOrgUsers, myself]) => {
        this.activeUsersOption = Object.values(this.userService.managedOrgUserDictionaryByUserID).filter(u => u.status === 'Active')
          .filter(u => this.perfReviewPermissionService.hasAdminAccess() || this.perfReviewPermissionService.accessibleUsersSet().has(u.orgUserID))
          .filter(v => v?.value !== myself?.userID)
          .map(u => {
            return { value: u.userID, label: u.userDetail?.fullName, imgUrl: u.userDetail?.profilePicURL }})
      }
    );

    if (this.action == 'Add') {
      this.goalForm = new FormGroup({
        goal: new FormControl(null, Validators.required),
        dueDate: new FormControl(null, Validators.required),
        user: new FormControl(null, Validators.required),
      });
      this.min = new Date().toJSON().split('T')[0];
    } else { // Edit modal
      this.goalForm = new FormGroup({
        goal: new FormControl(this.currentGoal && this.currentGoal.title ? this.currentGoal.title : null, Validators.required),
        dueDate: new FormControl(this.currentGoal && this.currentGoal.endDateLocal ? this.currentGoal.endDateLocal : null, Validators.required),
        user: new FormControl(this.currentGoal && this.currentGoal.owner ? this.currentGoal.owner : null, Validators.required),
      });
      this.min = (
        this.currentGoal.startDate ?
          new Date(this.currentGoal.startDate).toJSON().split('T')[0] : ''
      )
    }

    this.goalForm.get('user').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
      userID => {
        this.user = this.userService.managedOrgUserDictionaryByUserID[userID];
      }
    )
  }

  createOrSaveGoal() {
    this.isProcessing.set(true);
    if (this.action == 'Add') {
      this._createGoal();
    } else {
      this._saveGoal();
    }
  }

  private _createGoal() {
    const newGoal = {
      title: this.goalForm.get('goal').value,
      priority: 3,
      isAchieved: false,
      startDateLocal: dayjs().format('YYYY-MM-DD'),
      endDateLocal: dayjs(this.goalForm.get('dueDate').value).format('YYYY-MM-DD'),
    } as IGoalNew;

    this.goalService.addGoal(localStorage.orgID, this.goalForm.get('user').value, newGoal).pipe(
      take(1),
      switchMap(
        (goal: ILearningGoal) => {
          console.log('goal', goal)
          this.goalService.setGoal(goal);
          return this.goalService.fetchCacheGoalsForDirectManager(true)
        }
      )
    ).subscribe(
      goals => {
        console.log('goals', goals)
        this.toaster.success('Goal successfully added');
        this.isProcessing.set(false);
        this.activeModal.close();
      }
    )
  }

  private _saveGoal() {
    this.goalService.updateGoal(localStorage.orgID, this.currentGoal.owner,
      { ...this.currentGoal, title: this.goalForm.controls['goal'].value, endDateLocal: this.goalForm.controls['dueDate'].value })
      .pipe(
        take(1),
        switchMap(
          (goal: ILearningGoal) => {
            console.log('goal', goal)
            this.goalService.setGoal({ ...goal, action: 'edit' });
            return this.goalService.fetchCacheGoalsForDirectManager(true)
          }
        )
      )
      .subscribe(
        goals => {
          console.log('goals', goals)
          this.toaster.success('Goal successfully updated');
          this.isProcessing.set(false);
          this.activeModal.close();
        }
      )
  }

  back() {
    this.activeModal.close();
  }

  get f() { return this.goalForm?.controls; }


}
