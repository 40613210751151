import { Component, computed, inject, input, signal, viewChildren } from '@angular/core';
import { TaskModalComplianceItemComponent } from './task-modal-compliance-item/task-modal-compliance-item.component';
import { ITablePerformanceReviewAssign, PerformanceReviewService, TASK_ASSIGN_STATUS_ENUM } from 'src/app/services/performance-review.service';
import { IConvertedOrgAssign, OrgAssignService } from 'src/app/services/org-assign.service';
import { UserComplianceService } from './user-compliance.service';
import { CompletionEntity, TaskModalCompletionItemComponent } from './task-modal-completion-item/task-modal-completion-item.component';
import { groupBy, pairs } from 'underscore';
import { IPlanCell } from '@components/learning-table-popovers/training-plan-cell.component';
import { getCompletedPercentFromArray, getTaskCompletedPercentFromArray } from 'src/app/shared/utils/calculations/completed-percent';
import { MandatoryTrainingService } from 'src/app/services/mandatory-training.service';
import { COMPLIANCE_TYPE } from 'src/app/pages/mandatory-training/ITrainingPlan';
import { ProgressBarBasicComponent } from '@components/progress-bars/progress-bar-basic/progress-bar-basic.component';
import { TaskModalCompletionBarComponent } from './task-modal-completion-item/task-modal-completion-bar/task-modal-completion-bar.component';
import { IReportAckCounts, PolicyProcedureApiService } from 'src/app/pages/policies-procedures/services/policy-procedure-api.service';
import { combineLatest, Observable, of } from 'rxjs';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { FEATURES } from 'src/app/core/features.config';
import { FeatureFlagDirective } from 'src/app/shared/directives/feature-flag.directive';
import { map } from 'rxjs/operators';
import { GoalService, ILearningGoal } from 'src/app/services/goal.service';
import { TaskModalGoalCardComponent } from './task-modal-completion-item/task-modal-goal-card/task-modal-goal-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableModalBasicComponent } from 'src/app/shared/common-tables/assign-table-basic/assign-table-basic-modal/assign-table-basic-modal.component';
import { AssignTableBasicComponent } from 'src/app/shared/common-tables/assign-table-basic/assign-table-basic.component';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { LinkedAckNumModalComponent } from 'src/app/pages/policies-procedures/table-cells/linked-ack-number/linked-ack-num-modal/linked-ack-num-modal.component';
import { ProgressActivityModalComponent } from '../../../progress-activity-modal/progress-activity-modal.component';
import { PerformanceReviewGoalListItemComponent } from 'src/app/pages/performance-review/goals/performance-review-goal-list-item/performance-review-goal-list-item.component';
import { LoadingContainerComponent } from '@ui/loading-container/loading-container.component';
import { LinkButtonComponent } from '@ui/buttons/link-button/link-button.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-task-modal-side',
  standalone: true,
  imports: [TaskModalComplianceItemComponent, TaskModalCompletionItemComponent, ProgressBarBasicComponent,
    TaskModalCompletionBarComponent, FeatureFlagDirective, TaskModalGoalCardComponent, PerformanceReviewGoalListItemComponent,
    LoadingContainerComponent, LinkButtonComponent, UIStreamlineIconComponent, RouterLink],
  templateUrl: './task-modal-side.component.html',
  styleUrl: './task-modal-side.component.scss'
})
export class TaskModalSideComponent {
  task = input<ITablePerformanceReviewAssign>();

  userComplianceService = inject(UserComplianceService);
  orgAssignService = inject(OrgAssignService);
  mtService = inject(MandatoryTrainingService);
  pnpApiService = inject(PolicyProcedureApiService);
  featureFlagService = inject(FeatureFlagService);
  prService = inject(PerformanceReviewService);
  goalService = inject(GoalService);
  modalService = inject(NgbModal);
  columnService = inject(SmartTableColumnsService);


  assigns = signal<IConvertedOrgAssign[]>(null);
  ackCount = signal<IReportAckCounts>(null);
  tasks = signal<ITablePerformanceReviewAssign[]>(null);
  goals = signal<ILearningGoal[]>(null);
  // planCompletions = signal<CompletionByPlanType>(null);
  nonCompliantAssigns = computed(() => this.assigns()
    ?.filter(a => a.complianceType === COMPLIANCE_TYPE.compliance && (a.status === 'Overdue' || a.status === 'Incomplete')));
  groupedAssigns = computed(() => groupBy(this.assigns(), a => (a.mtPlanCell as IPlanCell).mtPlanID || 'Manual'))
  planCompletions = computed(() => {
    if (this.groupedAssigns()) {
      const grouped = this.groupedAssigns();
      return pairs(grouped).map(([k, v]) => ({ mtPlanID: k, completion: getCompletedPercentFromArray(v), title: k === 'Manual' ? 'Manual Assign' : this.mtService.globalPlanDict[k].title }))
    }
  });
  assignCompletion = computed(() => getCompletedPercentFromArray(this.assigns()));
  policyCompletion = computed(() => {
    if (this.ackCount()?.ackNum) {
      return this.ackCount().acknowledgedNum / (this.ackCount().acknowledgedNum + this.ackCount().unacknowledgedNum) * 100;
    }
    return 100;
  });
  totalPolicyNum = computed(() => this.ackCount()?.ackNum)
  taskCompletion = computed(() => getTaskCompletedPercentFromArray(this.tasks()));
  groupTasks = computed(() => groupBy(this.tasks(), t => t.cycle?.cycleConfigID))
  taskCycleCompletions = computed(() => {
    if (this.groupTasks()) {
      return pairs(this.groupTasks()).map(([k, v]) => ({ cycleID: k, completion: getTaskCompletedPercentFromArray(v), title: v[0].cycle?.title }))
    }
  });
  nonCompliantTasks = computed(() => this.tasks()?.filter(t => t.status === TASK_ASSIGN_STATUS_ENUM.overdue));
  goalsInProgress = computed(() => this.goals()?.filter(g => !g.isAchieved));

  isLoaded = signal(false);

  FEATURES = FEATURES;

  /**
   * If one child component is expanded, all the others must be hidden
   */
  collapsibleComponents = viewChildren(TaskModalCompletionItemComponent);
  expandedCard = computed(() => {
    if (this.collapsibleComponents()?.length) {
      return this.collapsibleComponents().find(c => !c.isCollapse())?.completionEntity()
    }
  });



  ngOnInit(): void {
    const ackCount$: Observable<IReportAckCounts[]> = this.featureFlagService.featureOn(FEATURES.policyAndProcedure) ?
      this.pnpApiService.getReportAcksByOrgUser({
        filteredOrgUsers: [this.task().assigneeOrgUserID]
      })
      : of([]);
    const tasks$ = this.prService.fetchCachedMyTasks().pipe(
      map(tasks => tasks.filter(t => t.assigneeOrgUserID === this.task().assigneeOrgUserID))
    );
    const goals$: Observable<ILearningGoal[]> = this.task().assigneeUserID ?
      this.goalService.fetchStaffGoals(localStorage.getItem('orgID'), this.task().assigneeUserID, 'all').pipe(
        map((goals: ILearningGoal[]) => goals.sort((a,) => a.isAchieved ? 1 : -1))
      ) :
      of([]);

    combineLatest([
      this.orgAssignService.getConvertedUserAssigns(this.task().assigneeUserID)
        .pipe(map(assigns => assigns.map(a => ({ ...a, ...this.columnService.getUserTableSimple(this.task().assigneeOrgUserID) })))),
      ackCount$,
      tasks$,
      goals$
    ])
      .subscribe(([assigns, acks, tasks, goals]) => {
        this.assigns.set(assigns);
        this.ackCount.set(acks?.length ? acks[0] : null);
        this.tasks.set(tasks);
        this.goals.set(goals);
        this.isLoaded.set(true);
      })
  }

  onItemClick(entity: 'Learning' | 'Policy' | 'Performance') {
    let modal: TableModalBasicComponent | LinkedAckNumModalComponent | ProgressActivityModalComponent;
    switch (entity) {
      case 'Learning':
        if (this.nonCompliantAssigns()?.length) {
          modal = this.modalService.open(TableModalBasicComponent, { size: 'xl' }).componentInstance as TableModalBasicComponent;
          modal.data.set({ assigns: this.nonCompliantAssigns() });
          modal.tableComponent.set(AssignTableBasicComponent);
          modal.modalHeading.set(`${this.nonCompliantAssigns().length} Requirements overdue or missed`);
        }
        break;
      case 'Policy':
        if (this.ackCount()?.unacknowledgedNum) {
          modal = this.modalService.open(LinkedAckNumModalComponent, { size: 'xl' }).componentInstance as LinkedAckNumModalComponent;
          modal.modalTitle = `${this.ackCount().unacknowledgedNum} Policies Unacknowledged`
          modal.payload = {
            filteredOrgUsers: [this.task().assigneeOrgUserID],
            filteredStatuses: ['Unacknowledged']
          };
          modal.context = 'reporting';
        }
        break;
      case 'Performance':
        if (this.nonCompliantTasks()?.length) {
          modal = this.modalService.open(ProgressActivityModalComponent, { size: 'xl' }).componentInstance as ProgressActivityModalComponent;
          modal.activityStatus = 'Overdue';
          modal.assigns = this.nonCompliantTasks();
        }
        break;
    }
  }

  onCompletionItemClick(event, entity: ProductType, id: string) {
    event.stopPropagation();
    let modal: TableModalBasicComponent | LinkedAckNumModalComponent | ProgressActivityModalComponent;
    switch (entity) {
      case 'Learning':
        modal = this.modalService.open(TableModalBasicComponent, { size: 'xl' }).componentInstance as TableModalBasicComponent;
        modal.data.set({ assigns: this.groupedAssigns()[id] });
        modal.tableComponent.set(AssignTableBasicComponent);
        modal.modalHeading.set(`${this.groupedAssigns()[id].length} 
         ${id === 'Manual' ? 'Manual Requirements' : this.mtService.globalPlanDict[id].title}`);
        break;
      case 'Policy':
        if (this.ackCount()?.ackNum) {
          modal = this.modalService.open(LinkedAckNumModalComponent, { size: 'xl' }).componentInstance as LinkedAckNumModalComponent;
          modal.modalTitle = `Policy Requirement`
          modal.payload = {
            filteredOrgUsers: [this.task().assigneeOrgUserID],
          };
          modal.context = 'reporting';
        }
        break;
      case 'Performance':
        modal = this.modalService.open(ProgressActivityModalComponent, { size: 'xl' }).componentInstance as ProgressActivityModalComponent;
        // modal.activityStatus = 'Overdue';
        modal.assigns = this.groupTasks()[id];
        break;

    }

  }

  checkHidden(t: CompletionEntity) {
    // console.log('checking')
    return this.expandedCard() && this.expandedCard() !== t;
  }

}

export type ProductType = 'Learning' | 'Policy' | 'Performance' | 'Goal'