import { Component, OnInit, ChangeDetectionStrategy, Input, ContentChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NgTemplateOutlet, NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';
import { BackButtonComponent } from '@components/back-button/back-button.component';
import { NavigationTabsComponent } from '@components/navigation/navigation-tabs/navigation-tabs.component';


@Component({
  selector: 'ui-modal-single',
  templateUrl: './modal-single.component.html',
  styleUrls: ['./modal-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgIf, NgClass, UIStreamlineIconComponent, UISpinnerComponent, BackButtonComponent, NavigationTabsComponent]
})
export class ModalSingleComponent implements OnInit {
  @ContentChild('headingSlot') headingSlot: TemplateRef<any>;
  @ContentChild('contentSlot') contentSlot: TemplateRef<any>;
  @ContentChild('leftSlot') leftSlot: TemplateRef<any>;
  @ContentChild('rightSlot') rightSlot: TemplateRef<any>;
  @ContentChild('msgSlot') msgSlot: TemplateRef<any>;

  @Input() templateModalInstance: any;
  @Input() content;
  @Input() heading?: string;
  @Input() description?: string;
  @Input() icon?: 'danger' | 'warning';
  @Input() closeResult: any;
  @Input() scrollable = false;
  @Input() disableCloseButton = false;
  @Input() hideCloseButton = false;
  @Input() dismiss = false;
  @Input() maxHeight;
  @Input() bodyHeight;
  @Input() loading = false;
  @Input() backButton = false;
  @Input() tabs?: {name; icon};
  @Input() overrideCloseButton = false;

  @Output() backClick = new EventEmitter();
  @Output() tabClick = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    if (this.overrideCloseButton) {
      this.closeClick.emit();
      return;
    }
    if (this.dismiss) {
      this.activeModal.dismiss();
      return;
    }
    if (this.templateModalInstance) {
      this.templateModalInstance.close(this.closeResult);
    } else {
      this.activeModal?.close(this.closeResult);
    }
  }


}
