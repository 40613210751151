import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AddPluralSPipe } from 'src/app/shared/pipes/add-plural-s.pipe';

@Component({
  selector: 'app-tooltip-cell',
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, AddPluralSPipe],
  templateUrl: './tooltip-cell.component.html',
  styleUrls: ['./tooltip-cell.component.scss']
})
export class TooltipCellComponent {
  @Input() rowData;
  @Input() value: string[];



}
