import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popover-info',
  standalone: true,
  imports: [CommonModule, UIStreamlineIconComponent, NgbPopover],
  templateUrl: './popover-info.component.html',
  styleUrls: ['./popover-info.component.scss']
})
export class PopoverInfoComponent {
  @Input() popoverData: {
    displayText;
    popoverText;
    popoverTitle;
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

}
