import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

// TODO: multi-columns will be supported in the future
export interface IFloatSingleColumnSettings {
  tableID?: string;
  includingHeader?: boolean;
  fromLeft: boolean;
  hoverToDisplay?: boolean;
  alwaysDisplayFloatCells?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MakeColumnStickyService {
  private renderer: Renderer2;
  private readonly bgColor = '#FFF';

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * @param @optional tableID
   *  Pass in the table to improve the specification when there are more than one tables on the page
   */
  floatSingleColumn({
                      fromLeft = false,
                      includingHeader = false,
                      tableID = '',
                      hoverToDisplay = false,
                      alwaysDisplayFloatCells = false,
                    }: IFloatSingleColumnSettings = {fromLeft: false}) {
    const childSelector = fromLeft ? `first-child` : `last-child`;
    const rowSelector = tableID ? `#${tableID} tr.ng2-smart-row td:${childSelector}` : `tr.ng2-smart-row td:${childSelector}`;
    const headerSelector = tableID ? `#${tableID} th.ng2-smart-th:${childSelector}` : `th.ng2-smart-th:${childSelector}`;
    const filterSelector = tableID ? `#${tableID} tr.ng2-smart-filters th:${childSelector}` : `tr.ng2-smart-filters th:${childSelector}`;
    // const tablePagerSelector = tableID ? `#${tableID} ng2-smart-table-pager` : `ng2-smart-table-pager`;
    const checkboxSelectAllSelector = tableID ? `#${tableID} th[ng2-st-checkbox-select-all]` : `th[ng2-st-checkbox-select-all]`;
    setTimeout(() => {
      const tableRows = this.document.querySelectorAll(rowSelector);
      const columnHeader = this.document.querySelector(headerSelector);
      const headerFilter = this.document.querySelector(filterSelector);
      // const pager = this.document.querySelector(tablePagerSelector);
      const selectAllCheckbox = this.document.querySelector(checkboxSelectAllSelector);
      const needFloatCheckboxes = !!selectAllCheckbox && fromLeft;
      // if (pager) {
        // this.renderer.setStyle(pager, 'position', 'sticky');
        // this.renderer.setStyle(pager, 'left', '12px');
      // }
      if (tableRows?.length) {
        tableRows.forEach((element, index) => {
          const floatClass = fromLeft ? 'table-sticky-column-left' : 'table-sticky-column-right';
          this.renderer.addClass(element, floatClass);
          if (hoverToDisplay) {
            this.renderer.addClass(element, 'hover-to-display-cell');
          }
          // for checkbox column class
          if (needFloatCheckboxes) {
            this.renderer.addClass(element, 'checkbox-column');
          }
          if (alwaysDisplayFloatCells) {
            this.renderer.addClass(element, 'always-display-cell');
          }
        });
        if (needFloatCheckboxes) {
          const checkboxWidth = selectAllCheckbox.getBoundingClientRect().width;
          const querySelectorForRealFistColumn = tableID ? `#${tableID} tr.ng2-smart-row td:nth-child(2)` : `tr.ng2-smart-row td:nth-child(2)`;
          const realFirstColumn = this.document.querySelectorAll(querySelectorForRealFistColumn);
          realFirstColumn.forEach((cell) => {
            this.renderer.addClass(cell, 'table-sticky-column-left');
            this.renderer.setStyle(cell, 'left', `${checkboxWidth}px`);
          });
        }
      }
      if (includingHeader) {
        if (fromLeft) {
          this._setStylesForLeftElement(columnHeader);
          this._setStylesForLeftElement(headerFilter);
          if (needFloatCheckboxes) {
            const checkboxWidth = selectAllCheckbox.getBoundingClientRect().width;
            const selectorOfRealFirstColumnHeader = tableID ? `#${tableID} th.ng2-smart-th:nth-child(2)` : `th.ng2-smart-th:nth-child(2)`;
            const selectorOfRealFirstFilters = tableID ? `#${tableID} tr.ng2-smart-filters th:nth-child(2)` : `tr.ng2-smart-filters th:nth-child(2)`;
            const realColumnHeader = this.document.querySelector(selectorOfRealFirstColumnHeader);
            const realFilter = this.document.querySelector(selectorOfRealFirstFilters);
            this._setStylesForLeftElement(selectAllCheckbox);
            this._setStylesForLeftElement(realColumnHeader, checkboxWidth);
            this._setStylesForLeftElement(realFilter, checkboxWidth);

            // checkbox column header and select all
            this._addCheckboxClass(headerFilter);
            this._addCheckboxClass(selectAllCheckbox);
          }
        }
      }
    }, 50);
  }

  private _setStylesForLeftElement(element: Element, left = 0) {
    // const greyRowColor = '#F9FAFA';
    if (element) {
      this.renderer.setStyle(element, 'position', 'sticky');
      this.renderer.setStyle(element, 'left', `${left}px`);
      this.renderer.setStyle(element, 'z-index', 10);
      this.renderer.setStyle(element, 'background', this.bgColor);
      // this.renderer.setStyle(element, 'border-end', '1px solid #E6E7EA');
      this.renderer.addClass(element, 'table-sticky-column-left');
      // this.renderer.addClass(element, 'checkbox-column');
    }
  }

  private _addCheckboxClass(element: Element) {
    if (element) {
      this.renderer.addClass(element, 'checkbox-column');
    }
  }
}
