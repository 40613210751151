import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IUIStreamlineIcon } from '../../../ui/streamline-icon/streamline-icon-interfaces';

import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'ui-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, UIStreamlineIconComponent]
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() url?: string;
  @Input() size: 'xs' | 'sm' | 'mds' | 'md' | 'lg' | 'xl' = 'md';
  @Input() fullName?: string;
  @Input() isSquare = false;
  @Input() slIcon: IUIStreamlineIcon;

  initials = '';
  @Input() bgColor = 'pink-100';
  @Input() iconBackgroundColor;
  @Input() textColor = 'text-pink-700';
  @Input() alignCenter?: boolean = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fullName && changes.fullName.currentValue) {
      this.setInitials(changes.fullName.currentValue);
    }
  }

  ngOnInit(): void {
    if (this.fullName) {
      this.setInitials(this.fullName);
    }
  }

  setInitials(n: string) {
    // this.initials = n.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('').toUpperCase();
    const initials = n.toUpperCase().split(' ');
    const firstNameInitial = initials[0].charAt(0);
    const lastNameInitial = initials[1]?.charAt(0) || '';
    this.initials = firstNameInitial + lastNameInitial;
  }
}
