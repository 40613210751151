import { Component, input } from '@angular/core';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-badge-compliance',
  standalone: true,
  imports: [UIStreamlineIconComponent],
  templateUrl: './badge-compliance.component.html',
  styleUrl: './badge-compliance.component.scss'
})
export class BadgeComplianceComponent {
  isCompliant = input(true);
}
