import { Component, Input, OnInit } from '@angular/core';
import { IOrgAssign } from 'src/app/services/org-assign.service';
import { IRecordStatus } from 'src/app/services/report-learning-record.service';
import { TableColumnValuePrepareAndSortUtilsService } from 'src/app/services/table-column-value-prepare-and-sort-utils.service';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { TrainingPlanCellComponent } from '../../../../components/learning-table-popovers/training-plan-cell.component';
import { AvatarComponent } from '../../../../components/avatar/avatar.component';

@Component({
    selector: 'app-mandatory-record-section',
    templateUrl: 'mandatory-record-section.component.html',
    styleUrls: ['mandatory-record-section.component.scss'],
    standalone: true,
    imports: [AvatarComponent, TrainingPlanCellComponent]
})

export class mandatoryRecordSectionComponent implements OnInit {
    @Input() mandatoryRecord: IOrgAssign;
    user: IOrgUser;

    constructor(
      private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
      private _localDatePipe: LocalDatePipe,

    ) { }

    ngOnInit() {
      this.user = this.mandatoryRecord.user;
    }

    jumpToNewTab() {
      window.open('/pages/staff-transcript/' + this.user.orgUserID, '_blank')
    }

    dateFormat(date) {
       return this._columnUtil.toLocalDate(date, this._localDatePipe)
    }

    get complianceStatusBadgeStyle() {
      switch(this.mandatoryRecord.status) {
        case 'Completed': return 'badge-success';
        case 'Done': return 'badge-success';
        default: return 'badge-light';
      }
    }


}
