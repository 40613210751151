<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="d-flex justify-content-between align-items-center mb-2" >
    <ng-container *ngFor="let step of steps; let i=index">
        <div class="flex-item f-small cursor-pointer" [ngClass]="{'grey-step': i > currentStep, 'blue-step': i <= currentStep}" (click)="onStepClick(i)">{{i >= currentStep ?  i + 1 : ''}}
            <ui-streamline-icon *ngIf="i < currentStep" [slIcon]="{ name: 'interface-validation-check', theme: 'mini-bold', size: 'xs' }" class="mb-1"></ui-streamline-icon>
        </div>
        <div *ngIf="i < steps.length - 1" class="separator bg-neutral-400" [ngClass]="{'bg-neutral-400': i >= currentStep, 'bg-blue-600': i < currentStep}"></div>
    </ng-container>
</div>

<div class="d-flex justify-content-between align-items-center">
    <ng-container *ngFor="let step of steps; let i=index">
        <div class="f-small text-neutral-700">{{step}}
        </div>
    </ng-container>

</div>

