<div class="d-flex align-items-center p-7 {{ inCard ? 'card' : 'flex-direction-column'}}">
    <div class="d-flex flex-column align-items-center gap-3" [style.max-width.px]="maxWidth" style="text-align: center;">
      <ng-content select="[topSlot]"></ng-content>
      <p *ngIf="heading" class="fw-semibold text-neutral-900">{{heading}}</p>
      <p *ngIf="emptyStateHeader" class="fw-semibold text-neutral-900">{{emptyStateHeader}}</p>
      <p class="f-small text-neutral-800">{{content}}</p>
      <div class="f-small text-neutral-800">
        <ng-content select="[contentSlot]"></ng-content>
      </div>
      <ng-content select="[buttonsSlot]"></ng-content>
    </div>
</div>
