import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, FormsModule } from '@angular/forms';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { IRawShortcut, IShortcutFilter, ShortcutService, SHORTCUT_LOCATION, SHORTCUT_LOC_DISPLAY } from 'src/app/services/shortcut.service';
import { object, pluck } from 'underscore';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DATE_OPTION_ENUM } from '../interfaces';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { LearningGroupType } from '../../reporting-type';
import { UserService } from 'src/app/services/user.service';
import { GroupService } from 'src/app/services/group.service';
import { MandatoryTrainingService } from 'src/app/services/mandatory-training.service';
import { UISpinnerComponent } from '../../../../ui/ui-spinner/ui-spinner.component';
import { InputErrorDirective } from '../../../../shared/directives/input-error.directive';

import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-shortcut-button',
  templateUrl: './shortcut-button.component.html',
  styleUrls: ['./shortcut-button.component.scss'],
  standalone: true,
  imports: [NgbDropdown, NgIf, NgbDropdownToggle, NgbDropdownMenu, NgTemplateOutlet, NgbDropdownItem, FormsModule, InputErrorDirective, UISpinnerComponent, AsyncPipe, UIStreamlineIconComponent]
})
export class ShortcutButtonComponent implements OnChanges, OnDestroy {
  @Input() activeShortcut = null;
  @Input() isFilterChangedAndRan = false;
  @Input() controls: { [key: string]: AbstractControl };
  @Input() disabled = false;
  @Input() dateOption = DATE_OPTION_ENUM.thisMonth;
  @Output() isFilterChangedAndRanChange = new EventEmitter();
  @Output() discard = new EventEmitter();



  newTitle: string;
  existingTitle: string;
  isSubmitting = false;
  activeDropdown = 0; // to decide which dropdown is shown.

  // for duplicate name error
  isNameDuplicate = false;

  filterChange = false;

  destroy$ = new Subject();

  @ViewChild('myDrop') dropdown: NgbDropdown;

  shortcuts$ = this.shortcutService.getShortcuts();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.dropdown?.close();
  }

  constructor(
    private shortcutService: ShortcutService,
    private toaster: ToastrService,
    private analytics: AnalyticsService,
  ) {
    // keep updating default title
    this.suggestedTitle().subscribe(t => this.newTitle = t);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.existingTitle = this.activeShortcut?.title;
  }


  saveAs(isSavingNew) {
    const savedTitle = isSavingNew ? this.newTitle : this.existingTitle;
    this.isSubmitting = true;
    this.checkNameValid(isSavingNew).pipe(
      switchMap(isValid => {
        if (isValid) {
          return this.shortcutService.saveFilter({
            shortcutID: isSavingNew ? null : this.activeShortcut?.shortcutID,
            title: savedTitle,
            filters: this.getFilterValues(),
            lastOpened: Date.now(),
            // location: SHORTCUT_LOCATION.allLearning
          });
        } else {
          return of(null);
        }
      })
    ).subscribe((res: IRawShortcut) => {
      this.isSubmitting = false;
      if (res) {
        this.dropdown.close();
        const title = savedTitle.length > 35 ? savedTitle.substring(0, 32) + '...' : savedTitle;
        this.toaster.success(`Filter successfully saved`);
        this.activeShortcut = res;
        this.sendTracking(res, isSavingNew);
        this.isFilterChangedAndRan = false;
        this.isFilterChangedAndRanChange.emit(false);
      } else {
        this.isNameDuplicate = true;
      }
    }, err => {
      this.toaster.error(err, 'Error');
    });
  }

  checkNameValid(isSavingNew) {
    if (!isSavingNew) {
      return of(true);
    }
    return this.shortcutService.getShortcuts()
      .pipe(take(1), map(shortcuts => !pluck(shortcuts, 'title').includes(this.newTitle)));
  }

  loadFilter() {
    this.controls.userStatus.setValue(this.activeShortcut.filters.userStatus);
  }

  ngOnDestroy(): void {
    this.destroy$.next();

  }

  getFilterValues(): IShortcutFilter {
    const formValues = object(Object.entries(this.controls)
      .map(([k, v]) => {
        return [k, v.value];
      })
    ) as IShortcutFilter;
    // return {...formValues, items: formValues.items.concat('efd2f530-74c8-4481-b3fc-238a74a59641')};
    return {
      ...cloneDeep(formValues),
      dates: {
        dateOption: this.dateOption
      }
    };
  }


  get showDropdown() {
    return this.activeShortcut && this.isFilterChangedAndRan;
  }

  onDropdownChange(event) {
    if (!event && this.activeDropdown !== 0) {
      this.activeDropdown = 0;
    }
  }

  openModal(modal) {
    this.activeDropdown = modal;
    this.dropdown.open();
  }

  onType() {
    this.isNameDuplicate = false;
  }

  suggestedTitle() {
    const title = SHORTCUT_LOC_DISPLAY[this.shortcutService.getRawLocationOnUrl()];
    return this.shortcutService.getShortcuts().pipe(
      takeUntil(this.destroy$),
      map(shortcuts => pluck(shortcuts, 'title')),
      map(shortcutTitles => {
        let i = 1;
        while (shortcutTitles.includes(title + ' ' + i)) {
          i += 1;
        }
        return title + ' ' + i;
      })
    );
  }

  discardChanges() {
    // this.shortcutService.setActiveShortcut(this.activeShortcut.shortcutID);
    this.discard.emit();
    setTimeout(() => {
      this.dropdown.close();
    }, 0);
    // this.cdr.detectChanges();
  }

  sendTracking(res: IRawShortcut, isSavingNew) {

    this.analytics.track('S-savedFilter-saved', {
      type: isSavingNew ? 'new' : 'existing',
      ...this.shortcutService.getEventBody(res.shortcutID)
    });
  }
}

