import { Component, Input, inject } from '@angular/core';
import { ConfirmationModalComponent } from 'src/app/shared/popups/confirmation-modal/confirmation-modal/confirmation-modal.component';
import { HierarchyService } from '../hierarchy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-remove-manager-modal',
  standalone: true,
  imports: [ConfirmationModalComponent],
  templateUrl: './remove-manager-modal.component.html',
  styleUrl: './remove-manager-modal.component.scss'
})
export class RemoveManagerModalComponent {
  @Input() managerID;
  @Input() members: string[];

  hierarchyService = inject(HierarchyService);
  userService = inject(UserService)

  get request$() {
    return this.hierarchyService.removeManager(this.managerID, this.members);
  }

  get text() {
    return this.members 
      ? 'These users will no longer be responsible for performance review activities. If you wish to continue assigning such activities to these users, we recommend moving direct reports to another direct manager'
      : `The ${this.userService.managedOrgHierarchyDict[this.managerID].members.length} direct reports of this manager will no longer be included in performance review activities. We recommend reassigning these direct reports to another manager.`
  }

  get heading() {
    return this.members 
    ? `Are you sure you want to remove ${this.members.length} direct reports?`
    : `Are you sure you want to remove this direct manager?`

  }

}
