<div *ngIf="withWrapper; else drop" class="d-flex justify-content-end p-2 bg-white custom-border-header">
    <ng-container *ngTemplateOutlet="drop"></ng-container>
</div>

<ng-template #drop>
    <div ngbDropdown container="body" placement="bottom-end">
        <button ngbDropdownToggle id="actions" [disabled]="disable || loading"
            class="btn-sm btn btn-outline-light f-caption"><span *ngIf="loading">
                <ui-spinner size="sm" class="me-1"></ui-spinner></span>Actions</button>
        <div ngbDropdownMenu aria-labelledby="actions">
            <button *ngIf="actionsConfig.expandTable" ngbDropdownItem title="Expand table" type="button"
                class="btn no-radius btn-sm" (click)="expand()">
                <ui-streamline-icon [slIcon]="{name: 'interface-arrows-diagonal-1', theme: 'micro-bold', size: 'sm'}"
                    class='me-2'></ui-streamline-icon>
                {{displayExpandTableText}}
            </button>
            <button *ngIf="actionsConfig.editColumns" ngbDropdownItem type="button" class="btn no-radius btn-sm"
                (click)="editColumn()">
                <ui-streamline-icon [slIcon]="{name: 'layout-right', theme: 'bold'}"
                    class="me-2"></ui-streamline-icon>Edit Columns
            </button>
            <button *ngIf="actionsConfig.downloadTable" ngbDropdownItem type="button" class="btn no-radius btn-sm"
                (click)="downloadCSV()">
                <ui-streamline-icon [slIcon]="{name: 'interface-download-button-2', theme: 'micro-bold', size: 'sm'}"
                    class="me-2 pb-1"></ui-streamline-icon>Download CSV
            </button>
            <button *ngIf="actionsConfig.toggleFilters" ngbDropdownItem type="button" class="btn no-radius btn-sm"
                (click)="onToggleFiltersClick()">
                <ui-streamline-icon [slIcon]="{name: 'interface-text-formatting-filter-2', theme: 'micro-bold'}"
                    class="me-1"></ui-streamline-icon>
                {{showOrHideFilterDisplay}} Filters
            </button>
            <ng-content></ng-content>
        </div>
    </div>

</ng-template>