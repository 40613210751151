<ui-modal-single [heading]="'Your Contract Files'">
  <ng-template #contentSlot>
      <div class="d-flex align-items-center " *ngFor="let f of files; index as i">
          <span class="me-2">{{ i + 1 }}.</span>
          <span><a target="_blank" class="btn btn-link" [attr.href]="f.url">{{ f.filename }}</a></span>
      </div>
      <div *ngIf="files.length<1">
        <ui-empty-state content="No files attached" [inCard]="false"></ui-empty-state>
      </div>
   </ng-template>
  <ng-template #rightSlot>
    <button type="button" class="btn btn-outline-light" (click)="activeModal.close('Close click')">Close</button>
   </ng-template>
  </ui-modal-single>
