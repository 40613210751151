import { Injectable } from '@angular/core';
import { CustomColumnFilterComponent } from '@components/custom-column-filter/custom-column-filter.component';
import { JobRoleTitlesCellComponent } from '@components/learning-table-popovers/jobrole-titles-cell.component';
import { ResourceLinkCellComponent } from '@components/learning-table-popovers/resource-link-cell.component';
import { TeamNamesCellComponent } from '@components/learning-table-popovers/team-names-cell.component';
import { IPlanCell } from '@components/learning-table-popovers/training-plan-cell.component';
import { LinkedLearningRecordComponent } from '@components/linked-learning-record/linked-learning-record.component';
import { ResourceTypeDisplayComponent } from '@components/resource-type-display/resource-type-display.component';
import { PopoverItemStructure, SharedPopoverCellComponent } from '@components/shared-popover-cell/shared-popover-cell.component';
import { ITableSettingColumns } from '@components/smart-table/smart-table.component';
import { StaffLinkDisplayComponent } from '@components/staff-link-display/staff-link-display.component';
import { StaffStatusDisplayComponent } from '@components/staff-status-display/staff-status-display.component';
import { StandardsPopoverCellComponent } from '@components/standards-popover-cell/standards-popover-cell.component';
import { AssignStatusCellComponent } from '@components/table-cell/assign-status-cell/assign-status-cell.component';
import { CenteredCellComponent } from '@components/table-cell/centered-cell/centered-cell.component';
import { ExtensionsCellComponent } from '@components/table-cell/extensions-cell/extensions-cell.component';
import { TruncatedCellComponent } from '@components/truncated-cell/truncated-cell.component';
import { capitalize } from 'lodash';
import { clone, Dictionary } from 'underscore';
import { TABLE_HEADERS } from '../core/table-headers.dict';
import { TitleCellComponent } from '../pages/policies-procedures/table-cells/pp-title-cell.component';
import { ExtendedDatesCellComponent } from '../pages/reporting/compliance-report/compliance-report-controller/extended-dates-cell/extended-dates-cell.component';
import { AccountStatusPipe } from '../shared/pipes/account-status.pipe';
import { AddPluralSPipe } from '../shared/pipes/add-plural-s.pipe';
import { ComplianceStatusPipe } from '../shared/pipes/compliance-status.pipe';
import { CpdTimePipe } from '../shared/pipes/cpd-time.pipe';
import { LocalDatePipe } from '../shared/pipes/local-date.pipe';
import { GroupService, ITeam } from './group.service';
import { ITrainingPlan } from './mandatory-training.service';
import { IComplianceRecord } from './report-learning-record.service';
import { SmartTableService } from './smart-table.service';
import { TableColumnValuePrepareAndSortUtilsService } from './table-column-value-prepare-and-sort-utils.service';
import { IOrgUser, UserService } from './user.service';
import { COMPLIANCE_TYPE } from '../pages/mandatory-training/ITrainingPlan';
import { SearchItemResourceComponent } from '@components/search-item-resource/search-item-resource.component';
import { formatTimeStamp } from '../pages/reporting/payroll-report/payroll-report.component';
import { FacilitiesService } from './facilities.service';
import { CommonTitleCellComponent } from '@components/common-title-cell/common-title-cell.component';
import { LocalDateTimePipe } from '../shared/pipes/local-date-time.pipe';
import { ReqTitleCellTsComponent } from '../pages/policies-procedures/table-cells/requirements/req-title-cell.ts/req-title-cell.ts.component';
import { ReqStateCellComponent } from '../pages/policies-procedures/table-cells/requirements/req-state-cell/req-state-cell.component';
import { PerformanceTaskTitleComponent } from '../pages/performance-review/performance-review-task-title-template/performance-task-title.component';
import { GeneralLinkedCellComponent } from '@components/general-linked-cell/general-linked-cell.component';
import { IPerformanceReviewAssign, ITablePerformanceReviewAssign } from './performance-review.service';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskModalComponent } from '../pages/performance-review/reviews/progress/task-activity-tables/task-modal/task-modal.component';
import { ResourceService } from './resource.service';
import { OrgFrameworksService } from './org-frameworks.service';

export type columnName = 'resource' | 'status' | 'resourceObj' | 'resourceCode' | 'resourceDuration' | 'user'
  | 'staffID' | 'internalID' | 'accountStatus' | 'daysOverdue' | 'daysExtended' | 'dueDate' | 'mtPlanCell'
  | 'manualAssignBy' | 'complianceType' | 'activityID' | 'assignID' | 'teams' | 'jobRoles'
  | 'earlyRecogDate' | 'assignDate' | 'completeDate' | 'originDueDate' | 'lateRecogDate' | 'email' | 'orgMobile' | 'orgEmail'
  | 'policyTitle' | 'code' | 'policyStatus' | 'linkedDocs' | 'requiredJobRoles' | 'standards' | 'completeTimestamp'
  | 'reviewDate' | 'updateDate' | 'extendedDueDate' | 'currentStatus' | 'extendedDates' | 'extensions' | 'workType' | 'workStartDate' | 'jobRoleType'
  | 'commonTitle' | 'assignee' | 'userID' | 'createTimestamp'
  | 'reqTitle' | 'reqStatus' | 'mtPlanID'
  | 'facilityModels' | 'taskTemplate' | 'cycle' | 'taskManager';

export const OPTIONAL_ASSIGN_COLUMNS: columnName[] = ['resource', 'resourceCode', 'resourceObj', 'resourceDuration', 'status', 'user', 'staffID', 'internalID', 'email', 'orgEmail', 'orgMobile',
  'accountStatus', 'teams', 'facilityModels', 'jobRoles', 'jobRoleType', 'earlyRecogDate', 'assignDate', 'completeDate', 'mtPlanCell', 'activityID', 'assignID'];

export const MANDATORY_ASSIGN_COLUMNS: columnName[] = ['resource', 'resourceCode', 'resourceObj', 'resourceDuration', 'status', 'user', 'assignee', 'complianceType', 'staffID', 'internalID', 'workType', 'workStartDate', 'email', 'orgEmail', 'orgMobile',
  'accountStatus', 'teams', 'facilityModels', 'jobRoles', 'jobRoleType', 'earlyRecogDate', 'assignDate', 'dueDate', 'completeDate', 'originDueDate', 'lateRecogDate', 'daysOverdue',
  'daysExtended', 'mtPlanCell', 'activityID', 'assignID'];

export const EXTENDED_ASSIGN_COLUMNS: columnName[] = ['resource', 'user', 'extensions', 'daysExtended', 'extendedDates', 'assignDate', 'originDueDate', 'extendedDueDate', 'currentStatus', 'staffID', 'internalID', 'email', 'orgEmail', 'orgMobile', 'teams', 'jobRoles'];
export const EXTENDED_ASSIGN_HIDDEN: columnName[] = ['assignDate', 'originDueDate', 'extendedDueDate', 'orgMobile', 'teams', 'jobRoles'];

export const POLICY_COLUMNS: columnName[] = ['policyTitle', 'code', 'updateDate', 'reviewDate', 'requiredJobRoles', 'standards', 'linkedDocs']

/**
 * Note: this service needs to be provided in module/component that also has the pipes (eg shared module)
 */
@Injectable()
export class SmartTableColumnsService {

  constructor(
    private accountStatusPipe: AccountStatusPipe,
    private complianceStatusPipe: ComplianceStatusPipe,
    private _localDatePipe: LocalDatePipe,
    private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
    private smartTableService: SmartTableService,
    private userService: UserService,
    private cpdPipe: CpdTimePipe,
    private groupService: GroupService,
    private addPluralSPipe: AddPluralSPipe,
    private facilitiesService: FacilitiesService,
    private localDateTimePipe: LocalDateTimePipe,
    private modalService: NgbModal,
    private resourceService: ResourceService,
    private orgFrameWorkService: OrgFrameworksService

  ) {
  }

  readonly serverTableSettings: Record<columnName, any> = {
    resource: {
      title: 'Item Title',
      type: 'custom',
      sort: true,
      // isSticky: true,
      isDisabled: true,
      // renderComponent: ResourceLinkCellComponent,
      // filter: {
      //   type: 'custom',
      //   component: CustomColumnFilterComponent
      // }
      renderComponent: SearchItemResourceComponent,
      onComponentInitFunction: (instance) => {
        instance.enableLink = true;
        instance.openNewTab = true;
        instance.isTable = true;
      },
      compareFunction: this._columnUtil.compareByProperty('title'),
      filterFunction: this._columnUtil.searchByProperty('title'),
    },
    reqTitle: {
      title: 'Requirement Title',
      type: 'custom',
      sort: false,
      // isSticky: true,
      isDisabled: true,
      // renderComponent: ResourceLinkCellComponent,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      renderComponent: ReqTitleCellTsComponent,
      compareFunction: this._columnUtil.compareByProperty('title'),
    },
    commonTitle: {
      title: 'Heading',
      sort: false,
      type: 'custom',
      renderComponent: CommonTitleCellComponent,
    },
    status: {
      title: 'Status',
      sort: true,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      type: 'custom',
      renderComponent: AssignStatusCellComponent,
      valuePrepareFunction: (val) => this.complianceStatusPipe.transform(val)
    },
    reqStatus: {
      title: 'State',
      sort: false,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      type: 'custom',
      renderComponent: ReqStateCellComponent,
      filterFunction: (value, search) => {
        if(search === 'met'){
          search = 'Met'
        }
        if(search === 'unmet'){
          search = 'Unmet'
        }
        return value.includes(search)
      },
    },
    currentStatus: {
      title: 'Current Status',
      sort: false,
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      type: 'custom',
      renderComponent: AssignStatusCellComponent,
      valuePrepareFunction: (val) => this.complianceStatusPipe.transform(val)
    },
    resourceObj: {
      title: 'Item Type',
      type: 'custom',
      renderComponent: ResourceTypeDisplayComponent,
      valuePrepareFunction: this._columnUtil.resourceTypeConvert(),
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun(this._columnUtil.resourceTypeConvert()),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(this._columnUtil.resourceTypeConvert()),
      sort: true,
      filter: false,
    },
    resourceCode: {
      title: 'Item ID',
      sort: true,
      filter: false,
      type: 'custom',
      renderComponent: TruncatedCellComponent,
      onComponentInitFunction: (instance) => {
        instance.className = 'resourceCode';
      },
    },
    resourceDuration: {
      title: 'Resource Duration (Minutes)',
      defaultToShow: false,
      sort: false,
      filter: true,
      valuePrepareFunction: min => this._columnUtil.toCPDTime(min, this.cpdPipe),
    },
    user: {
      title: 'User',
      type: 'custom',
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      sort: false,
      renderComponent: StaffLinkDisplayComponent,
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(u => u?.fullName)
    },
    taskManager: {
      title: 'Manager',
      type: 'custom',
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      sort: false,
      renderComponent: StaffLinkDisplayComponent,
    },
    staffID: {
      sort: false,
      title: 'User ID',
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      filter: {
        type: 'custom',
        component: CustomColumnFilterComponent
      },
      onComponentInitFunction: (instance) => {
        instance.className = 'staffID';
      },
    },
    internalID: {
      sort: false,
      filter: false,
      title: 'Internal ID',
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      // filter: {
      //   type: 'custom',
      //   component: CustomColumnFilterComponent
      // },
      // onComponentInitFunction: (instance) => {
      //   instance.className = 'staffID';
      // },
    },
    workType: {
      title: 'Employment Status',
      defaultToShow: false,
      sort: true,
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
      type: 'string',
      compareFunction: this._columnUtil.generalCompare()
    },
    accountStatus: {
      title: 'Account Status',
      sort: true,
      filter: true,
      type: 'custom',
      valuePrepareFunction: (val) => this.accountStatusPipe.transform(val),
      renderComponent: StaffStatusDisplayComponent,
    },
    dueDate: {
      title: 'Due Date',
      filter: false,
      sort: true,
      valuePrepareFunction: (date) => this._columnUtil.toLocalDate(date, this._localDatePipe),
    },
    extendedDueDate: {
      title: 'Extended Due Date',
      filter: false,
      sort: false,
      valuePrepareFunction: (date) => this._columnUtil.toLocalDate(date, this._localDatePipe),
    },
    daysOverdue: {
      sort: true,
      filter: false,
      title: 'Days Overdue',
      valuePrepareFunction: (val) => val || 0
    },
    daysExtended: {
      sort: true,
      filter: false,
      title: 'Days Extended',
      type: 'custom',
      renderComponent: CenteredCellComponent
    },
    mtPlanCell: { ...this.smartTableService.tableSettings.mtPlanCell, sort: true, filter: true },
    mtPlanID: { ...this.smartTableService.tableSettings.mtPlanID, sort: false, filter: false },
    manualAssignBy: {
      title: 'Assigned By',
      type: 'custom',
      sort: false,
      filter: false,
      renderComponent: StaffLinkDisplayComponent,
    },
    complianceType: {
      title: 'Requirement Type',
      sort: true,
      filter: true,
      defaultToShow: true,
      valuePrepareFunction: (type: COMPLIANCE_TYPE) => {
        return COMPLIANCE_TYPE_DISPLAY[type];
      },
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((type: COMPLIANCE_TYPE) => {
        return COMPLIANCE_TYPE_DISPLAY[type];
      })
    },
    activityID: {
      title: 'Learning Records',
      type: 'custom',
      renderComponent: LinkedLearningRecordComponent,
      sort: false,
      filter: false,
      valuePrepareFunction: (val) => val ? 'has linked record' : '-',
    },
    assignID: {
      title: 'Record ID',
      valuePrepareFunction: (val) => '"' + this._columnUtil.longIDColumn(val) + '"', // so always read as text by csv opener
      sort: false,
      filter: false,
    },
    teams: {
      title: TABLE_HEADERS.USER.teams,
      type: 'custom',
      sort: false,
      filter: true,
      renderComponent: TeamNamesCellComponent,
      valuePrepareFunction: this._columnUtil.extractByProperty('name')
    },
    facilityModels: {
      title: 'Location',
      type: 'custom',
      sort: false,
      filter: true,
      renderComponent: TeamNamesCellComponent,
      valuePrepareFunction: this._columnUtil.extractByProperty('name'),
      onComponentInitFunction: (instance: TeamNamesCellComponent) => {
        instance.isFacilityCol = true;
      },
    },
    jobRoles: {
      title: TABLE_HEADERS.USER.jobRoles,
      type: 'custom',
      sort: false,
      filter: true,
      renderComponent: JobRoleTitlesCellComponent,
      valuePrepareFunction: this._columnUtil.extractByProperty('title')
    },
    jobRoleType: {
      title: 'Job Role Type',
      sort: false,
      filter: true,
      // valuePrepareFunction: (val) => jobRoleTypeCaptionDisplay[val] || '-'
    },
    earlyRecogDate: {
      title: 'Early Recognition Date',
      valuePrepareFunction: (date) => this._columnUtil.toLocalDate(date, this._localDatePipe),
      sort: true,
      filter: false
    },
    assignDate: {
      title: 'Open Date',
      filter: false,
      sort: true,
      valuePrepareFunction: (date) => this._columnUtil.toLocalDate(date, this._localDatePipe),
    },
    completeDate: {
      title: 'Date Completed',
      sort: true,
      filter: true,
      valuePrepareFunction: date => this._columnUtil.toLocalDate(date, this._localDatePipe),
    },
    originDueDate: {
      title: 'Original Due Date',
      filter: false,
      sort: true,
      valuePrepareFunction: date => this._columnUtil.toLocalDate(date, this._localDatePipe),
    },
    lateRecogDate: {
      title: 'Overdue End Date',
      filter: false,
      sort: true,
      valuePrepareFunction: date => this._columnUtil.toLocalDate(date, this._localDatePipe),
    },
    email: this.smartTableService.tableSettings.email,
    orgEmail: this.smartTableService.tableSettings.orgEmail,
    orgMobile: { ...this.smartTableService.tableSettings.orgMobile, sort: true, filter: false },
    policyTitle: {
      title: 'Item Title',
      type: 'custom',
      isSticky: true,
      filter: false,
      sort: true,
      renderComponent: TitleCellComponent,
      onComponentInitFunction: (instance) => {
        instance.item = 'policy';
        instance.isNewTab = true;
      }
    },
    code: {
      title: 'Item ID',
      type: 'string',
      filter: false,
      sort: false,
      valuePrepareFunction: (_code) => {
        return _code ? _code : '-';
      },
      compareFunction: this._columnUtil.generalCompare(),
    },
    policyStatus: {
      title: 'Status',
      type: 'string',
      filter: false,
      sort: false,
      valuePrepareFunction: (_status) => capitalize(_status),
    },
    linkedDocs: {
      title: 'Additional Documents',
      type: 'custom',
      filter: false,
      renderComponent: SharedPopoverCellComponent,
      sort: false,
      valuePrepareFunction: (_docs): PopoverItemStructure[] => {
        const docs = _docs ? _docs.map(doc => ({
          name: doc.name,
          url: '/pages/policies/manage/forms/' + doc.docID
        })) : [];
        return docs;
      },
      onComponentInitFunction: (instance) => {
        instance.column = 'Document';
        instance.noContainer = true;
      },
    },
    requiredJobRoles: {
      title: 'Required Job Roles',
      type: 'custom',
      filter: false,
      renderComponent: SharedPopoverCellComponent,
      sort: false,
      valuePrepareFunction: (_jobRoles): PopoverItemStructure[] => {
        const jobRoles = _jobRoles ? _jobRoles.map(jobRoleID => {
          return {
            name: this.groupService.globalJobRolesDict[jobRoleID]?.title || '',
            url: '/pages/admin/manage-job-roles/single/' + jobRoleID
          };
        }) : [];
        return jobRoles;
      },
      onComponentInitFunction: (instance) => {
        instance.column = 'Job Role';
        instance.noContainer = true;
      },
    },
    standards: {
      title: 'Related Standards',
      type: 'custom',
      filter: false,
      renderComponent: StandardsPopoverCellComponent,
      onComponentInitFunction: (instance: StandardsPopoverCellComponent) => {
        instance.noContainer = true;
      },
      valuePrepareFunction: (d: string[]) => {
        d = d || [];
        const arr = d.map(s => (this.resourceService.guidelineDict[s]?.name))
        .concat(d.map(s => (this.orgFrameWorkService.orgStandardsDictByStandardID[s]?.name)))
        .filter(s => s);
        return arr.length ? arr : '-'
      },
      sort: false,
    },
    reviewDate: {
      title: 'Review Due By',
      type: 'string',
      filter: false,
      sort: true,
      valuePrepareFunction: (d) => {
        if (d) {
          return this._localDatePipe.transform(d);
        } else {
          return '-';
        }
      },
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(d => d ? this._localDatePipe.transform(d).toString() : '')
    },
    updateDate: {
      title: 'Date Updated',
      type: 'string',
      filter: false,
      sort: true,
      valuePrepareFunction: (d) => {
        if (d) {
          return this._localDatePipe.transform(d);
        } else {
          return '-';
        }
      },
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(d => d ? this._localDatePipe.transform(d).toString() : '')
    },
    extendedDates: {
      title: 'Original Due Date - Extended Due Date',
      type: 'custom',
      filter: false,
      sort: false,
      renderComponent: ExtendedDatesCellComponent
    },
    extensions: {
      title: 'Times Extended',
      sort: false,
      filter: false,
      type: 'custom',
      valuePrepareFunction: (val) => this.addPluralSPipe.transform((val?.length || 0) + ' Time'),
      renderComponent: ExtensionsCellComponent,
    },
    workStartDate: {
      title: 'Work Start Date',
      filter: false,
      defaultToShow: false,
      valuePrepareFunction: date => {
        if (date) {
          return this._columnUtil.toLocalDate(date, this._localDatePipe);
        } else {
          return '-';
        }
      },
    },
    completeTimestamp: {
      title: 'Time of Completion',
      valuePrepareFunction: formatTimeStamp(this.localDateTimePipe),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(formatTimeStamp(this.localDateTimePipe))
    },
    createTimestamp: {
      title: 'Record Creation',
      valuePrepareFunction: formatTimeStamp(this.localDateTimePipe),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(formatTimeStamp(this.localDateTimePipe))
    },
    // for RESPECT to show userID as UUID on the all requirement report
    assignee: {
      title: 'User UUID',
      type: 'string',
      defaultToShow: false,
      filter: false,
      sort: false,
    },
    // for IRT to show userID as UUID on the Manage Users page
    userID: {
      title: 'User UUID',
      type: 'string',
      defaultToShow: false,
      filter: false,
      sort: false,
    },
    taskTemplate: {
      title: 'Task',
      type: 'custom',
      sort: false,
      filter: false,
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(t => t?.title),
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun(t => t?.title),
      valuePrepareFunction: (val) => val.title,
      renderComponent: GeneralLinkedCellComponent,
      onComponentInitFunction: (instance: GeneralLinkedCellComponent<IPerformanceReviewAssign>) => {
        // instance.linkFn = (rowData: ITablePerformanceReviewAssign) => ({routerLink: ['/pages/performance/reviews/task/', rowData?.templateID]});
        instance.clickFn = (rowData: ITablePerformanceReviewAssign) => {
          const modal = this.modalService.open(TaskModalComponent, { size: 'xl' });
          modal.componentInstance.taskID = rowData.taskID;
        };
        instance.width = 400;
      },
    },
    cycle: {
      title: 'Cycle',
      type: 'custom',
      renderComponent: GeneralLinkedCellComponent,
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(t => t?.title),
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun(t => t?.title),
      valuePrepareFunction: (val) => val?.title,
      onComponentInitFunction: (instance: GeneralLinkedCellComponent<IPerformanceReviewAssign>) => {
        instance.avatar = { iconType: 'loading-3' };
        instance.linkFn = (rowData) => ({ routerLink: ['/pages/performance/reviews/cycles/', rowData?.cycle?.cycleConfigID] });
      },
    },
  };

  readonly localTableSettings: Record<columnName, any> = {
    manualAssignBy: {},
    email: { sort: false },
    orgMobile: { sort: false },
    orgEmail: { sort: false },
    daysOverdue: {
      title: 'Days Overdue',
    },
    daysExtended: {
      title: 'Days Extended',
    },
    mtPlanCell: {},
    activityID: {
      valuePrepareFunction: this._columnUtil.longIDColumn,
      compareFunction: this._columnUtil.generalCompare()
    },
    assignID: {},
    commonTitle: {},
    userID: {},
    resource: {
      filterFunction: this._columnUtil.searchByProperty('title'),
      compareFunction: this._columnUtil.compareByProperty('title')
    },
    reqTitle: {},
    status: {
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((val) => this.complianceStatusPipe.transform(val)),
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun((val) => this.complianceStatusPipe.transform(val)),
    },
    reqStatus: {},
    currentStatus: {
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((val) => this.complianceStatusPipe.transform(val)),
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun((val) => this.complianceStatusPipe.transform(val)),
    },
    resourceObj: {
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun(this._columnUtil.resourceTypeConvert()),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(this._columnUtil.resourceTypeConvert())
    },
    resourceCode: {
      compareFunction: this._columnUtil.generalCompare(),
      // valuePrepareFunction: this._columnUtil.longIDColumn,
    },
    resourceDuration: {},
    user: {
      filterFunction: this._columnUtil.filterAvatar,
      compareFunction: this._columnUtil.userAvatarCompareFunction,
    },
    taskManager: {
      filterFunction: this._columnUtil.filterAvatar,
      compareFunction: this._columnUtil.userAvatarCompareFunction,
    },
    staffID: {
      compareFunction: this._columnUtil.generalCompare(),
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
    },
    internalID: {
      compareFunction: this._columnUtil.generalCompare(),
      valuePrepareFunction: this._columnUtil.replaceNoRecord(),
    },
    workType: {},
    accountStatus: {
      compareFunction: this._columnUtil.generalCompareWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun((val) => this.accountStatusPipe.transform(val)),
    },
    teams: {
      valuePrepareFunction: this._columnUtil.extractByProperty('name'),
      filterFunction: this._columnUtil.searchByProperty('name'),
      compareFunction: this._columnUtil.compareByProperty('name')
    },
    facilityModels: {
      valuePrepareFunction: this._columnUtil.extractByProperty('name'),
      filterFunction: this._columnUtil.searchByProperty('name'),
      compareFunction: this._columnUtil.compareByProperty('name')
    },
    jobRoles: {
      type: 'custom',
      valuePrepareFunction: this._columnUtil.extractByProperty('title'),
      filterFunction: this._columnUtil.searchByProperty('title'),
      compareFunction: this._columnUtil.compareByProperty('title')
    },
    jobRoleType: {
      sort: false
    },
    earlyRecogDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    assignDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    completeDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    dueDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    extendedDueDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    originDueDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    lateRecogDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    extendedDates: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    complianceType: {
      title: 'Requirement Type',
      defaultToShow: true,
    },
    policyTitle: {
      compareFunction: this._columnUtil.generalCompare()
    },
    code: {},
    policyStatus: { sort: false },
    linkedDocs: { sort: false },
    requiredJobRoles: { sort: false },
    standards: { sort: false },
    reviewDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    updateDate: {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
    },
    extensions: {
      filterFunction: (extensions: any[], search = '') => {
        return this.serverTableSettings.extensions.valuePrepareFunction(extensions).includes(search);
      }
    },
    workStartDate: {},
    completeTimestamp: {},
    createTimestamp: {},
    assignee: {},
    mtPlanID: {},
    taskTemplate: {},
    cycle: {},
  }

  dateCol = {
    valuePrepareFunction: (date) => this._columnUtil.toLocalDate(date, this._localDatePipe),
    compareFunction: this._columnUtil.compareByDate(),
    filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe))
  }

  getColumns(columnNames: columnName[],
    options?: { hidden?: columnName[], local?: boolean, show?: columnName[], titleMap?: {[key in columnName]?: string}}) {
    const columns = {};
    columnNames.forEach(name => {
      const col = clone(this.serverTableSettings[name]);
      if (options?.hidden?.includes(name)) {
        col.defaultToShow = false;
      }
      if (options?.local) {
        col.filter = true;
        col.sort = true;
        const localCol = this.localTableSettings[name];
        Object.keys(localCol).forEach(p => col[p] = localCol[p]);
      }
      if (options?.show?.includes(name)) {
        col.defaultToShow = true;
      }
      if (options?.titleMap && options.titleMap[name]) {
        col.title = options.titleMap[name];
      }

      columns[name] = col;
    });
    return columns as { [key: string]: ITableSettingColumns };
  }

  getPlanCell(plan: ITrainingPlan, isManualAssign = false, assignRecord?: IComplianceRecord): IPlanCell {
    let assignerName = '';
    if (isManualAssign) {
      assignerName = this.userService.managedOrgUserDictionaryByUserID[assignRecord.assigner]?.fullName || 'System';
    }
    return {
      text: isManualAssign ? `By ${assignerName}` : (plan?.title || '-'),
      tooltip: isManualAssign ? `Manually assigned by ${assignerName} on ${this._localDatePipe.transform(assignRecord.assignDate)}`
        : 'Automatically assigned through training plan: ' + plan?.title,
      isManualAssign: isManualAssign,
      mtPlanID: plan?.mtPlanID,
      planTitle: plan?.title,
      assignerName,
      assignDate: '2022'
    };
  }

  get dateFunctions() {
    return {
      compareFunction: this._columnUtil.compareByDate(),
      filterFunction: this._columnUtil.generalSearchWithValuePrepareFun(date => this._columnUtil.toLocalDate(date, this._localDatePipe)),
      valuePrepareFunction: (date) => this._columnUtil.toLocalDate(date, this._localDatePipe),
    }
  }

  getUserTableDetails(orgUserID: string) {
    const user = this.userService.managedOrgUserDictionaryByOrgUserID[orgUserID];
    if (!user) {
      return null;
    }
    return this.getUserTableDetailsGeneral(user);
  }

  getUserTableDetailsGeneral(user: IOrgUser) {
    return {
      ...getUserTableDetails(user, this.groupService.globalTeamsDict),
      managedLocations: user.managedTeams
        .filter(teamID => this.facilitiesService.globalOrgFacilities[teamID])
        .map(teamID => {
          const facility = this.facilitiesService.globalOrgFacilities[teamID];
          return {
            text: facility.upperStructure,
            link: ['/pages/admin/admin-locations', facility.teamID],
            tooltip: this.userService.isManager ? ''
              : this.userService.managedTeams.includes(teamID) ? '' : 'You do not have permission to access this location'
          }
        }),
      facility: user.facilities?.map(t => this.facilitiesService.globalOrgFacilities[t])?.filter(t => !!t) || [],
      facilityModels: user.facilities?.map(t => this.facilitiesService.globalOrgFacilities[t])?.filter(t => !!t) || [],
    }
  }

  getUserTableSimple(orgUserID: string) {
    const user = this.userService.managedOrgUserDictionaryByOrgUserID[orgUserID];
    if (!user) {
      return null;
    }
    return {
      staffID: user.staffID || '',
      internalID: user.internalID,
      teams: user.orgUserDetail?.teamModels || [],
      jobRoles: user.activeJobRoleTakens?.map(j => j.jobRoleModel) || [],
      email: user.email,
      orgEmail: user.orgEmail,
      accountStatus: user.status,
      user: user,
      orgUserID: user.orgUserID,
      facilityModels: user.facilityModels?.filter(t => !!t) || [],
    }

  }

}

export function getUserTableDetails(user: IOrgUser, teamDict: Dictionary<ITeam>) {
  return {
    staffID: user.staffID || '',
    teams: user.teams?.map(t => teamDict[t])?.filter(t => !!t) || [],
    jobRoles: user.activeJobRoleTakens?.map(j => j.jobRoleModel) || [],
    email: user.email,
    accountStatus: user.status,
    user: user,
    orgUserID: user.orgUserID,
    orgMobile: user.orgMobile,
    orgEmail: user.orgEmail,
    internalID: user.internalID,
  }
}

export const COMPLIANCE_TYPE_DISPLAY: Record<COMPLIANCE_TYPE, string> = {
  [COMPLIANCE_TYPE.compliance]: 'Mandatory (Compliance)',
  [COMPLIANCE_TYPE.mandatory]: 'Mandatory (Non-Compliance)',
  [COMPLIANCE_TYPE.optional]: 'Optional (Non-Compliance)'
}


export type ITableUser = ReturnType<SmartTableColumnsService['getUserTableSimple']> ;
