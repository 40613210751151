import { IClassifiedOrgUsers } from "src/app/services/user.service";
import { IHostedStep } from "src/app/shared/utils/workflows/component-host-step-control";
import { StepsControl } from "src/app/shared/utils/workflows/workflow-steps-control";
import { IOrganisation } from "../../settings/interfaces/IOrganisation";
import { PolicyFromBackend } from "../interfaces/policy-and-procedure.interface";

export interface IRevokePolicySharedData {
    selectedPolicy?: PolicyFromBackend;
    organisation: IOrganisation;
    orgJobRoles: any[];
    orgUsers: IClassifiedOrgUsers;
    selectedUsers?: any[];
    analyticsContext?: string;
    manager: any;
    allPolicies: PolicyFromBackend[];
    shouldRevokeAnother: boolean;
    allowRevokeAnother: boolean;
    preselectedUser?: any;
    revokeResult?: any;
}

export interface IRevokePolicyComponent {
    revokePolicySharedData?: IRevokePolicySharedData;
    stepsControl: StepsControl<IRevokePolicyFlow>;
}

export interface IRevokePolicyFlow extends IHostedStep<IRevokePolicyComponent> {
}

export const REVOKE_POLICY_FLOW_STEP_KEYS = {
    choosePolicy: 'choose-policy',
    chooseUsers: 'choose-users',
    review: 'review',
    success: 'success',
};