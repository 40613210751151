import { inject, Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { clone,  difference,  uniq } from 'underscore';
import { of, throwError } from 'rxjs';
import { GroupService, ITeam } from './group.service';
import { getUpdateUsersForm } from '../shared/popups/bulk-staff-modal/bulk-staff-modal.component';
import { UserUpdateService } from './user-update.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialTeamService {
  private userService = inject(UserService);
  private groupService= inject(GroupService);
  private userUpdateService = inject(UserUpdateService);

  addManager(managerID: string, members: string[], teamType: string) {
    return this.getManagerSpecialTeam(managerID, teamType).pipe(
      switchMap(team => {
        const teamID = team.teamID
        // prepare payload for manager
        const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
        manager.managedTeams = uniq((manager.managedTeams || []).concat([teamID]));
        manager.teams = manager.allTeams;
        const managerPayload = getUpdateUsersForm(manager);
        // prepare payload for members
        const currentMembers = this.getCurrentMembers(managerID, teamType);
        // find removed, new members
        const newMembers = difference(members, currentMembers);
        const removedMembers = difference(currentMembers, members);

        const getPayload = (users: string[], removing = false) => {
          return users
          .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
          .map(user => {
            user.teams = user.allTeamModels
              .filter(t => t.teamID !== teamID)
              .map(t => t.teamID)
              .concat(removing ? teamID : null)
              .filter(teamID => teamID)
              ;
            return getUpdateUsersForm(user)
          })
        };
        const membersPayload = getPayload(newMembers).concat(getPayload(removedMembers, true))
        const payload = membersPayload.concat(managerPayload);
        return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType })
          .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
      })
    )
  }

  /**
   * 
   * @param managerID 
   * @param teamID 
   * @param members 
   * @param includeManagerPayload if want to separate the manager's payload in another call
   * @returns 
   */
  editMembers(managerID: string, team: ITeam, members: string[], includeManagerPayload = true) {
    const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
    if (!team) {
      return throwError('Team not Found')
    }
    const teamType = team.teamType
    manager.managedTeams = uniq((manager.managedTeams || []).concat([team.teamID]));
    manager.teams = manager.allTeams;
    const managerPayload = getUpdateUsersForm(manager);
    // prepare payload for members
    const currentMembers = this.getCurrentMembers(managerID, teamType);
    // find removed, new members
    const newMembers = difference(members, currentMembers);
    const removedMembers = difference(currentMembers, members);

    const getPayload = (users: string[], removing = false) => {
      return users
      .filter(u => includeManagerPayload ? true : u !== managerID)
      .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
      .map(user => {
        user.teams = user.allTeamModels
          .filter(t => t.teamID !== team.teamID)
          .map(t => t.teamID)
          .concat(removing ? null : team.teamID)
          .filter(teamID => teamID)
          ;
        return getUpdateUsersForm(user)
      })
    };
    const membersPayload = getPayload(newMembers).concat(getPayload(removedMembers, true))
    const payload = membersPayload.concat(managerPayload);
    if (!includeManagerPayload) {
      payload.pop();
    }
    return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType })
      // .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
  }

  getManagerSpecialTeam(managerID: string, teamType: string) {
    const manager = this.userService.managedOrgUserDictionaryByOrgUserID[managerID];
    const specialTeam = manager.managedSpecialTeams?.find(t => t.teamType === teamType)
    if (specialTeam) {
      return of(specialTeam)
    }
    // create a new hierarchy team, 
    return this.groupService.addOrganisationTeam(
      localStorage.getItem('orgID'),
      {
        name: `${manager.fullName} ${teamType} Team`,
        teamType: teamType,
      } as any
    )
    // add in manager 
  }

   // if not passing members, remove all
   removeManager(managerID: string, teamType: string, members?: string[]) {
    const team = this.getExistingSpecialTeam(managerID, teamType);
    if (!team) {
      return of(null);
    }
    if (!members) {
      members = this.getCurrentMembers(managerID, teamType) || [];
    }
    const payload = members
    .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
    .map(user => {
      user.teams = user.allTeamModels
        .filter(t => t.teamID !== team.teamID)
        .map(t => t.teamID)
      return getUpdateUsersForm(user)
    })
    if (!members) {
      const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
      manager.managedTeams = (manager.managedTeams.filter(t => t !== team.teamID));
      manager.teams = manager.allTeams;
      const managerPayload = getUpdateUsersForm(manager);  
      payload.push(managerPayload);
    }
    return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreSpecialTeam: teamType})
    .pipe(
      switchMap(() => {
        if (!members) {
          return this.groupService.deleteOrganisationTeam(localStorage.getItem('orgID'), team.teamID, true)
        }
        return of(null)
      }),
      // switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID')))
    )
  }

  getExistingSpecialTeam(managerID: string, teamType: string) {
    const manager = this.userService.managedOrgUserDictionaryByOrgUserID[managerID];
    const specialTeam = manager.managedSpecialTeams?.find(t => t.teamType === teamType);
    return specialTeam;
  }

  getCurrentMembers(managerID: string, teamType: string) {
    const specialTeam = this.getExistingSpecialTeam(managerID, teamType)
    if (specialTeam) {
      return this.userService.userList
        .filter(u => u.specialTeams?.map(t => t.teamID)?.includes(specialTeam.teamID))
        .map(u => u.orgUserID)
         || []
    }
    return [];
  }



}
