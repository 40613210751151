import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { copyToClipboard } from 'src/app/shared/utils/copy-to-clipboard';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
    selector: 'app-copy-button',
    templateUrl: './copy-button.component.html',
    styleUrls: ['./copy-button.component.scss'],
    standalone: true,
    imports: [NgIf, NgbTooltip, NgClass, UIStreamlineIconComponent]
})
export class CopyButtonComponent implements OnInit {
  @Input() textToCopy;

  tooltipButtonText = 'Copy link';
  isClicked = false;

  constructor(
    private toaster: ToastrService
  ) { }


  ngOnInit(): void {
  }

  onButtonClick() {
    copyToClipboard(this.textToCopy, this.toaster, {hideToast: true});
    this.tooltipButtonText = 'Link copied!';
    this.isClicked = true;
  }




}
