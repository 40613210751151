<div [class]="dropdownClass" ngbDropdown [placement]="placement"
    [container]="container" (openChange)="onDropdownChange($event)"
>
    <button [hidden]="ignoreClick" [disabled]="disableDropdown" ngbDropdownToggle id="actions" class="btn-{{buttonSize}} btn btn-outline-light btn-square remove-dropdown-arrow m-actions-btn">
        <ui-streamline-icon [slIcon]="{name: 'interface-setting-menu-horizontal', theme: 'micro-bold', size: 'md', svgStyle: {'padding-bottom': '2px'}}"></ui-streamline-icon></button>
    <div ngbDropdownMenu aria-labelledby="actions">
        <ng-content>
        </ng-content>
    </div>
</div>
<button [hidden]="!ignoreClick" (click)="onFakeButtonClicked()" class="btn-{{buttonSize}} btn btn-outline-light btn-square remove-dropdown-arrow m-actions-btn">
    <ui-streamline-icon [slIcon]="{name: 'interface-setting-menu-horizontal', theme: 'micro-bold', size: 'md', svgStyle: {'padding-bottom': '2px'}}"></ui-streamline-icon>
</button>
