<div style="width: 180px">
    <label class="f-small text-neutral-800 mb-1" for="selectDateTypeId">Date Type</label>
    <app-customised-single-select
            id="selectDateTypeId"
            controlName="dateType"
            displayName="Date Type"
            [options]="options"
            [controls]="controls"
    ></app-customised-single-select>
</div>
