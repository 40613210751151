import { Component, Input, inject } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { SelectDirectManagerComponent } from '../direct-manager-add-modal/select-direct-manager/select-direct-manager.component';
import { FormControl, Validators } from '@angular/forms';
import { HierarchyService } from '../hierarchy.service';
import { UserService } from 'src/app/services/user.service';
import { ProcessingButtonComponent } from '@ui/buttons/processing-button/processing-button.component';
import { WarningBannerComponent } from 'src/app/pages/compliance/compliance-workroom/mark-complete-bulk/warning-banner/warning-banner.component';

@Component({
  selector: 'app-change-manager-modal',
  standalone: true,
  imports: [ModalSingleComponent, SelectDirectManagerComponent, ProcessingButtonComponent, WarningBannerComponent],
  templateUrl: './change-manager-modal.component.html',
  styleUrl: './change-manager-modal.component.scss'
})
export class ChangeManagerModalComponent extends ConfirmationModal {
  @Input() replacedManager: string;
  @Input() members?: string[]; // if members not given, replace all
  control = new FormControl('', Validators.required);

  hierarchyService = inject(HierarchyService);
  dict = inject(UserService).managedOrgHierarchyDict

  onUpdate() {
    if (this.control.invalid) {
      return;
    }
    this.isLoading = true;
    this.hierarchyService.addManager(
      this.control.value,
      (this.members || (this.dict[this.replacedManager]?.members || []).map(m => m.orgUserID))
    ).subscribe(() => {
      this.confirmSuccess('Manager updated');
    }, 
    // error => this.error(error)
  )
  }
}
