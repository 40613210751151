<div style="width: 450px">
    <label class="f-small text-neutral-800 mb-1" for="selectFrameworkId">Regulatory Standards Framework</label>
    <app-customised-single-select
            id="selectFrameworkId"
            controlName="framework"
            [autoGrow]="false"
            [submitted]="submitted"
            [controls]="controls"
            [options]="options"
            displayName="Framework"
    ></app-customised-single-select>
</div>
