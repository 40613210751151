import { ToastrService } from "ngx-toastr";
import { BaseInjectorConstructor } from "./clearRoute";

export const HasBasicServices = <Tbase extends BaseInjectorConstructor>(superClass: Tbase) => {

    // @Injectable()
    abstract class ReturnedClass extends superClass {
        toastrService = this.injector.get(ToastrService);
        constructor(...args) {
            super(...args);
        }
    }
    return ReturnedClass;
  };