<ul ngbNav [destroyOnHide]="true" #nav="ngbNav" [activeId]="activeTabId" (activeIdChange)="onNavChange($event)"
class="nav-tabs bg-white custom-border-header align-items-center px-4">
<li *ngFor="let table of tables; index as idx" [ngbNavItem]="idx">
    <a ngbNavLink>{{table.name}} <span class="badge badge-pill" [ngClass]="{'badge-primary-light': activeTabId === idx, 'badge-light': activeTabId !== idx}">{{table.numRow}}</span></a>
</li>

<!-- table actions -->
<li class="ms-auto">
    <ng-content></ng-content>
</li>

<li *ngIf="showDownload" class="ms-auto">
    <app-download-csv-button (buttonClick)="downloadCsv.emit()"></app-download-csv-button>
</li>

</ul>
