import { DecimalPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { ProgressBarBasicComponent } from '@components/progress-bars/progress-bar-basic/progress-bar-basic.component';
import { HoverToggleDirective } from 'src/app/shared/directives/hover-toggle.directive';

@Component({
  selector: 'app-task-modal-completion-bar',
  standalone: true,
  imports: [ProgressBarBasicComponent, HoverToggleDirective, DecimalPipe],
  templateUrl: './task-modal-completion-bar.component.html',
  styleUrl: './task-modal-completion-bar.component.scss'
})
export class TaskModalCompletionBarComponent {
  title = input('');
  percentage = input(null);
}
