import {Directive, Input, ElementRef} from '@angular/core';
/*
* I know how to bypass the guard and create a team but PMs require us to do it. Developers can only display the elements like this.
* */
@Directive({
    selector: '[appDisableAndShowText]',
    standalone: true
})
export class DisableAndShowTextDirective {
  @Input() hoverText = '';
  @Input() set appDisableAndShowText(disable) {
    if (disable) {
      switch (this.el.nativeElement.tagName) {
        case 'BUTTON':
          this.el.nativeElement.setAttribute('disabled', '');
          this.el.nativeElement.setAttribute('title', this.hoverText);
          this.el.nativeElement.style.cursor = 'not-allowed';
          break;
        case 'DIV':
          this.el.nativeElement.setAttribute('title', this.hoverText);
          this.el.nativeElement.style.cursor = 'not-allowed';
          break;
        default:
          this.el.nativeElement.setAttribute('title', this.hoverText);
          this.el.nativeElement.style.cursor = 'not-allowed';
          break;
      }
    }
  }
  constructor(private el: ElementRef) {
  }

}
