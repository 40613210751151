import { ITableActionButton } from "@components/table-cell/action-buttons/table-action-button/table-action-button.component";
import { ITablePerformanceReviewAssign, TASK_ASSIGN_STATUS_ENUM, TASK_TEMPLATE_ENUM } from "src/app/services/performance-review.service";

export function getTaskActionButtons(): ITableActionButton[] {
    return [{text: 'Send Reminder', action: 'remind', 
        disabledFn: (task: ITablePerformanceReviewAssign) => task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.meeting,
        tooltipFn: (task: ITablePerformanceReviewAssign) => task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.meeting ? 'You cannot send reminders for a meeting.' : null,
        hideFn: (task: ITablePerformanceReviewAssign) => task?.status === TASK_ASSIGN_STATUS_ENUM.completed || task.status === TASK_ASSIGN_STATUS_ENUM.scheduled,
        icon: {name: 'alarm-bell', theme: 'bold', svgClassIn: 'text-warning'}
      }, {text: 'Open', action: 'open'}]
}