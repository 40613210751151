import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { ModalSingleComponent } from '../../components/modal-single/modal-single.component';
export const SHARED_MODAL_CONFIRM_KEY = 'confirm';
export const SHARED_MODAL_DISMISS_KEY = 'dismissed';
export interface IShareModalConfig {
  title: string;
  content: string;
  buttonText?: string;
  buttonClass?: string;
  hasCancelButton?: boolean;
}
@Component({
    template: `
      <ui-modal-single [heading]="title">
          <ng-template #contentSlot>
              <div class="d-flex flex-wrap justify-content-start">
                  <div [innerHTML]="content"></div>
              </div>
          </ng-template>
          <ng-template #rightSlot>
              <button type="button" class="btn btn-{{cancelButtonClass}}" (click)="close()" *ngIf="hasCancelButton">{{cancelButtonText}}</button>
              <button type="button" class="btn btn-{{buttonClass}}" (click)="close(confirmKey)">{{buttonText}}</button>
          </ng-template>
      </ui-modal-single>`,
    standalone: true,
    imports: [ModalSingleComponent, NgIf]
})

export class SharedConfirmationModalComponent implements OnInit {
  @Input() title;
  @Input() content;
  @Input() buttonText = 'Confirm';
  @Input() buttonClass = 'secondary';
  @Input() hasCancelButton = true;
  @Input() cancelButtonClass = 'outline-light';
  @Input() cancelButtonText = 'Cancel';
  /* The pack has the priority. The introduction of this is to shorten your code. Feel free to NOT use it
  *  DO NOT DElETE above inputs due to the compatability concerns unless you are about to replace ALL shared modals
  * */
  @Input() configPack: IShareModalConfig;
  readonly confirmKey = SHARED_MODAL_CONFIRM_KEY;



  constructor(
    protected activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    if (this.configPack) {
      const { title, buttonClass = 'primary', hasCancelButton = true, buttonText = 'Confirm', content } = this.configPack;
      this.title = title;
      this.buttonClass = buttonClass;
      this.hasCancelButton = hasCancelButton;
      this.buttonText = buttonText;
      this.content = content;
    }
  }

  close(result = SHARED_MODAL_DISMISS_KEY) {
    this.activeModal.close(result);
  }
}
