import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';

@Component({
  selector: 'app-loading-container',
  standalone: true,
  imports: [CommonModule, UISpinnerComponent],
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss']
})
export class LoadingContainerComponent {
  @Input() isLoaded = false;

}
