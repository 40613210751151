<ng-select *ngIf="controls"
           [ngClass]="{'auto-grow': autoGrow, 'is-invalid': submitted && controls[controlName].invalid && controls[controlName].errors?.required}"
           (open)="onOpenDropdown()"
           class="f-small auto-grow" [formControl]="controls[controlName]"
           [id]="id || 'select'+controlName" [items]="options"
           bindLabel="label" bindValue="value" [searchable]="searchable"
           [clearable]="false"
           [placeholder]="placeholder"
           [closeOnSelect]="true"
           [virtualScroll]="true"
           appendTo="body"
           (blur)="selectorBlur.emit()">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="d-flex justify-content-between" [class.parent-border]="item.borderBottom" >
            <div class="d-flex flex-column">
                <div class="align-items-center d-flex gap-1">
                    <ui-avatar *ngIf="showAvatar" size="sm" [fullName]="item.label" [url]="item.imgUrl"></ui-avatar>
                    <span class="f-small text-neutral-900 {{'ms-' + (item.level * 2)}}" [ngClass]="{'m-first-letter-add-L': item.addL}">
                            {{item.label}}
                            <span *ngIf="controls[controlName].value===item.value">
                                 <ui-streamline-icon class="ps-1" [slIcon]="{name: 'interface-validation-check', size: 'sm',
                                 theme: 'mini-bold', svgStyle: {'padding-bottom': '3px'}}"></ui-streamline-icon> 
                            </span>
                    </span>
                </div>
                <span *ngIf="item.caption" class="f-small text-neutral-700">{{item.caption}}</span>
            </div>
            <span *ngIf="item.rightLabel"><staff-status-view [value]="item.rightLabel | accountStatusPipe"></staff-status-view></span>
        </div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <ui-streamline-icon *ngIf="slIcon" [slIcon]="slIcon" class="me-2"></ui-streamline-icon>
        <span class="f-small text-neutral-900" [innerHTML]="item.label"></span>
    </ng-template>

</ng-select>
<small class="text-error-input" *ngIf="submitted && controls[controlName].invalid && controls[controlName].errors?.required">
    Please select a {{displayName}}
</small>
