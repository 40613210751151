@if(isCollapsed()) {
    <button 
    (click)="isCollapsed.set(false)" class="btn btn-outline-light p-1">
    <ui-streamline-icon [slIcon]="{name: 'move-left-1', theme: 'bold'}" class="me-1"></ui-streamline-icon>
    </button>
} @else {
    <button
    (click)="isCollapsed.set(true)" class="btn btn-outline-light p-1">
    <ui-streamline-icon [slIcon]="{name: 'move-right-1', theme: 'bold', }" class="me-1"></ui-streamline-icon>
    </button>
    <span class="fw-semibold f-caption text-neutral-700 ms-2">Overview</span>
}

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" [horizontal]="true" class="mt-4">
    <ng-content></ng-content>
</div>
