<ui-modal-single
[heading]="modalTitle">
    <ng-template #contentSlot>
        <div class="pb-2 float-end">
            <app-shared-table-actions 
            [tableSettings]="settings"
            [actionsConfig]="{
                expandTable: true,
                toggleFilters: true,
                editColumns: true,
                downloadTable: true,
              }"
            ></app-shared-table-actions>
        </div>
        <app-smart-table
        [source]="source"
        [settings]="settings"
        tableDiv="all-table-modal"
        [withWrapper]="true"
        [stickyLeft]="true"
        [downloadSettings]="{title: 'Payroll Report'}"
    ></app-smart-table>
    </ng-template>

</ui-modal-single>