import { Injectable, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectManagerAddModalComponent } from './direct-manager-add-modal/direct-manager-add-modal.component';
import { IEditOrgUserFormBulk, IOrgUser, UserService } from 'src/app/services/user.service';
import { UserUpdateService } from 'src/app/services/user-update.service';
import { of } from 'rxjs';
import { GroupService } from 'src/app/services/group.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { clone, uniq, values } from 'underscore';
import { getUpdateUsersForm } from 'src/app/shared/popups/bulk-staff-modal/bulk-staff-modal.component';
import { keyBy } from 'lodash';
import { ChangeManagerModalComponent } from './change-manager-modal/change-manager-modal.component';
import { RemoveManagerModalComponent } from './remove-manager-modal/remove-manager-modal.component';
import { FEATURES } from 'src/app/core/features.config';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  private modalService = inject(NgbModal);
  private userService = inject(UserService);
  private userUpdateService = inject(UserUpdateService);
  private groupService= inject(GroupService);
  private featureFlagService = inject(FeatureFlagService);

  allReporterInOrg: IOrgUser[];


  fetchCachedHierarchy() {
    return this.userService.fetchCachedOrgUsers().pipe(
      map(() => values(this.userService.managedOrgHierarchyDict)),
      tap(m => {
        this.allReporterInOrg = uniq(m.map(i => i.members).flat(),'orgUserID');
      }),
      tap(m => console.log(m))
    )
  }

  // if user has existing direct mngr, it will be replaced
  addManager(managerID: string, members: string[]) {
    return this.getManagerHierarchyTeam(managerID).pipe(
      switchMap(teamID => {
        // prepare payload for manager
        const manager = clone(this.userService.managedOrgUserDictionaryByOrgUserID[managerID]);
        manager.managedTeams = uniq((manager.managedTeams || []).concat([teamID]));
        manager.teams = manager.allTeams;
        const managerPayload = getUpdateUsersForm(manager);
        // prepare payload for members
        // for each user, remove directTeam
        // add in new direct team
        const membersPayload = members
          .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
          .map(user => {
            user.teams = user.allTeamModels
              .filter(t => t.teamType !== HIERARCHY_TEAM_TYPE)
              .map(t => t.teamID)
              .concat(teamID);
            return getUpdateUsersForm(user)
          })

        const payload = membersPayload.concat(managerPayload);
        return this.userUpdateService.bulkUpdateOrgUser(payload, { ignoreDirectManager: true })
          .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
      })
    )

  }

  // if not passing members, remove all
  removeManager(managerID: string, members?: string[]) {
    const hierarchy = this.userService.managedOrgHierarchyDict[managerID];
    if (!members) {
      members = hierarchy?.members.map(m => m.orgUserID) || [];
    }
    const membersPayload = members
    .map(id => clone(this.userService.managedOrgUserDictionaryByOrgUserID[id]))
    .map(user => {
      user.teams = user.allTeamModels
        .filter(t => t.teamID !== hierarchy.teamID)
        .map(t => t.teamID)
      return getUpdateUsersForm(user)
    })
    return this.userUpdateService.bulkUpdateOrgUser(membersPayload, { ignoreDirectManager: true})
    .pipe(switchMap(() => this.userService.fetchOrgUsers(localStorage.getItem('orgID'))))
  }

  // get/create manager's teamID
  getManagerHierarchyTeam(managerID: string) {
    const manager = this.userService.managedOrgUserDictionaryByOrgUserID[managerID]
    if (manager.managedHierarchy) {
      return of(manager.managedHierarchy)
    }
    // create a new hierarchy team,
    return this.groupService.addOrganisationTeam(
      localStorage.getItem('orgID'),
      {
        name: manager.orgUserID,
        teamType: HIERARCHY_TEAM_TYPE,
      } as any
    ).pipe(map(team => team.teamID))
    // add in manager
  }

  openAddManager(managerID?: string) {
    const modal = this.modalService.open(DirectManagerAddModalComponent, {size: 'lg'});
    modal.componentInstance.selectedManager = managerID;
    return modal.result;
  }

  openChangeManager(managerID: string, members?: string[]) {
    const modal = this.modalService.open(ChangeManagerModalComponent, {size: 'lg'});
    modal.componentInstance.replacedManager = managerID;
    modal.componentInstance.members = members;
  }

  openRemoveManager(managerID: string, members?: string[]) {
    const modal = this.modalService.open(RemoveManagerModalComponent, {size: 'lg'});
    modal.componentInstance.managerID = managerID;
    modal.componentInstance.members = members;
  }

  // lms user's direct manager
  getMyManager() {
    const directTeam = this.userService.orgUser.value.orgUserDetail.teamModels?.find(t => t.teamType === HIERARCHY_TEAM_TYPE)
    if (directTeam) {
      const directManager = this.userService.allRawOrgUsers.find(u => u.managedTeams?.includes(directTeam.teamID));
      return {...directManager, fullName: directManager.userDetail?.fullName
          || (directManager.invitation?.firstName + ' ' + directManager.invitation?.lastName)};
    }
    return undefined;
  }

  // lms user's direct reports
  getMyDirectReports(): IOrgUser[] {
    const directTeam = this.userService.orgUser.value.orgUserDetail.managedTeamModels?.find(t => t.teamType === HIERARCHY_TEAM_TYPE)
    if (directTeam) {
      // console.log(this.userService.userList?.filter(u => u.hierarchyTeamModel?.teamID === directTeam.teamID && u.status==='Active'))
      return this.userService.userList?.filter(u => u.hierarchyTeamModel?.teamID === directTeam.teamID && u.status==='Active');
    }
  }

  getMyDirectReportIDs(): string[] {
    return this.getMyDirectReports()?.map(u => u.orgUserID) || [];
  }

  getManagerGlobally(teamID: string) {
    const rawUser = this.userService.allRawOrgUsers.find(u => u?.managedTeams?.includes(teamID));
    if (rawUser) {
      return this.userService._spreadOrgUserBasedOnInvitationAndDetails(rawUser);
    }
    return null;

  }

  isShowUserCycle(orgUserID) {
    if (!this.featureFlagService.featureOn(FEATURES.performance)) {
      return false;
    }
    return this.userService.isManager ||
    this.getMyDirectReports()?.find(d => orgUserID === d.orgUserID);
  }

}

export const HIERARCHY_TEAM_TYPE = 'org_hierarchy';

export interface IHierarchy {
  manager: IOrgUser;
  members: IOrgUser[];
  teamID: string;
}

export interface IHierarchyOption extends IHierarchy {
  value: string;
  display: string;
  label: string;
}

export function getHierarchyDict(userList: IOrgUser[]) {
  const managers = userList.filter(u => u.managedHierarchy);
  return keyBy(managers.map(m => ({
    manager: m,
    members: userList.filter(u => u.hierarchyTeamModel?.teamID === m.managedHierarchy),
    teamID: m.managedHierarchy
  })), (hierarchy: IHierarchy) => hierarchy.manager.orgUserID);
}

export function getFilterOrgUsers() {
  return inject(UserService).isManager ? null : inject(HierarchyService).getMyDirectReportIDs()
}
