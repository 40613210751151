  // convert hex color to RGBA color
  export function hexToRgba(hex, opacity = 1) {
    // Remove the leading '#' if present
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Construct the RGBA color
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
