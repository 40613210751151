<div *ngIf="includeItemType" class="mb-4">
    <label class="f-small text-neutral-800 mb-1" for="itemTypes">Item Type</label>
    <ng-select #selector class="f-small" *ngIf="controls && controls['itemTypes']" [formControl]="controls['itemTypes']"
        id="itemTypes" [loading]="isLoading" [virtualScroll]="true" [items]="allItemTypes" bindLabel="name"
        bindValue="value" [multiple]="true" [searchable]="true" [clearable]="true" [closeOnSelect]="false"
        (search)="getFilteredItems($event)"
        placeholder="Select Item Type" (change)="onItemTypeChanged($event)">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <div class="d-flex">
                <div class="custom-control custom-checkbox ">
                    <input id="item-{{index}}" class="custom-control-input" type="checkbox"
                        [ngModel]="item$.selected" />
                    <label class="custom-control-label f-small text-neutral-800">{{item.name}}</label>
                </div>
            </div>
        </ng-template>

        <ng-template ng-header-tmp>
            <div class="d-flex justify-content-between p-1">
                <button class="btn btn-link me-2" (click)="onSelectAllItemType()">Select All
                </button>
                <button class="btn btn-link" (click)="onClearAllItemType()">Clear All
                </button>
            </div>

        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length === 1">
                <span class="ng-value-label">{{items[0].name}}</span>
                <span class="ng-value-icon right" (click)="clear(items[0])">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1 && items.length < options?.length">
                <span class="ng-value-label">{{items.length}} item types</span>
            </div>
            <div class="ng-value" *ngIf="items.length === options?.length">
                <span class="ng-value-label">All {{ items.length }} item types</span>
            </div>
        </ng-template>
    </ng-select>
    <small class="text-error-input" *ngIf="controls[controlName].invalid && controls[controlName].errors?.required">
        Please select a item type
    </small>

</div>


<label class="f-small text-neutral-800 mb-1" for="id || select{{controlName}}">Item</label>
<ng-select #selector class="f-small" *ngIf="controls" [formControl]="controls[controlName]" id="id || select{{controlName}}"
    [loading]="isLoading" [virtualScroll]="true" [items]="options" bindLabel="label" bindValue="value" [multiple]="true"
    [searchable]="true" [clearable]="true" [closeOnSelect]="false" [selectableGroup]="true" [searchFn]="ngSearchFn" [loadingText]="loadingText"
    (search)="getFilteredItems($event)"
    [groupBy]="ngSelectGroupBy" [selectableGroupAsModel]="false" placeholder="Select {{displayName}}">
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="py-1 custom-control custom-checkbox" [ngSwitch]="item.providerType">
            <ng-container *ngSwitchCase="'Internal'">
                <div>
                    <input id="item-your-library" class="custom-control-input" type="checkbox"
                        [ngModel]="item$.selected" />
                    <label class="custom-control-label">Your Library</label>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'Ausmed'"><input id="item-ausmed-library" class="custom-control-input"
                    type="checkbox" [ngModel]="item$.selected" /> <label class="custom-control-label">Ausmed
                    Library</label>
            </ng-container>
            <ng-container *ngSwitchDefault><input id="item-third-party-{{item.providerType}}"
                    class="custom-control-input" type="checkbox" [ngModel]="item$.selected" /> <label
                    class="custom-control-label">{{item.providerType}}</label></ng-container>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="d-flex">
            <div class="custom-control custom-checkbox ">
                <input id="item-{{index}}" class="custom-control-input" type="checkbox" [ngModel]="item$.selected" />
                <label class="custom-control-label f-small text-neutral-800">
                    <span [style.max-width.px]="labelWidth" [ngbTooltip]="isTruncated(index) ? item.label : ''"
                        container="body" id="resource-label-{{index}}" class="text-truncate f-small">{{item.label}}
                    </span>
                    <div class="divider-dot d-flex">
                        <p class="f-caption text-neutral-700">
                            {{item.displayedResourceType}}</p>
                        <p *ngIf="item.min" class="f-caption text-neutral-700">{{item.min | cpdTime}}</p>
                    </div>
                </label>
            </div>
        </div>
    </ng-template>

    <ng-template ng-header-tmp>
        <div class="d-flex justify-content-between p-1">
            <button class="btn btn-link me-2" (click)="onSelectAll()">{{ filteredItems ? 'Select All Filtered Results' : 'Select All' }}
            </button>
            <button class="btn btn-link" (click)="onClearAll()">Clear All
            </button>
        </div>

    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngIf="items.length === 1">
            <span class="ng-value-label">{{items[0].label}}</span>
            <span class="ng-value-icon right" (click)="clear(items[0])">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 1 && items.length < options?.length">
            <span class="ng-value-label">{{items.length}} {{ displayName }}s</span>
        </div>
        <div class="ng-value" *ngIf="items.length === options?.length">
            <span class="ng-value-label">All {{ items.length }} {{ displayName }}s</span>
        </div>
    </ng-template>
</ng-select>
<small class="text-error-input" *ngIf="controls[controlName].invalid && controls[controlName].errors?.required">
    Please select a {{displayName}}
</small>
