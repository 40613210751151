<div [ngClass]="{'float-end': floatEnd}">
    <ng-container *ngIf="text">
        <button (click)="onClick()" class="btn btn-sm btn-outline-light fw-semibold">{{text}}</button>
    </ng-container>
    <ng-container *ngIf="buttons">
        @if (showDropdown && !dropdownButtonsAllHidden()) {
        <div class="d-inline-flex ms-2">
            <app-ngb-dropdown>
                @for(b of buttons; track b.text) {
                <button [disabled]="b.disabled" ngbDropdownItem (click)="$event.stopPropagation(); onClick(b.action)" [hidden]="b.hide"
                    [class]="'btn no-radius ' + b.textClass" [attr.disabled]="b.disabled || null" [ngbTooltip]="b.tooltip">{{b.text}}</button>
                }
            </app-ngb-dropdown>
        </div>
        } @else {
        @for (b of buttons; track $index) {
        @if(!b.disabled){
        <button (click)="$event.stopPropagation(); onClick(b.action)" [disabled]="b.disabled" [hidden]="b.hide"
            [ngbTooltip]="b.tooltip" tooltipClass="custom-tooltip"
            [class]="'btn btn-sm btn-outline-light fw-semibold ms-2 btn-opaque ' + b.textClass">
            @if (b.icon) {
            <ui-streamline-icon class="me-1" [slIcon]="b.icon"></ui-streamline-icon>
            }
            {{b.text}}</button>
        }

        }

        }
    </ng-container>

    <ng-container *ngIf="textRight">
        <button (click)="onClick()" class="btn btn-{{rightBtnClass}} fw-semibold ms-2">{{textRight}}</button>
    </ng-container>

</div>