import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ITableDownloadSettings, SmartTableComponent } from '@components/smart-table/smart-table.component';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter as filterRx, tap } from 'rxjs/operators';
import { IMultiSelectData, IShownItems, SmartTableService } from 'src/app/services/smart-table.service';
import { clone, unique } from 'underscore';
import { NgClass } from '@angular/common';
import { SmartTableComponent as SmartTableComponent_1 } from '../../smart-table/smart-table.component';
import { MultiSelectContainerComponent } from '../multi-select-container.component';

@Component({
    selector: 'app-multi-select-table',
    templateUrl: './multi-select-table.component.html',
    styleUrls: ['./multi-select-table.component.scss'],
    standalone: true,
    imports: [MultiSelectContainerComponent, SmartTableComponent_1, NgClass]
})
export class MultiSelectTableComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  availableItems: number;
  shownItems: IShownItems = {
    showingMinus: false,
    showClearSelection: false
  };
  multiSelectData: IMultiSelectData;

  @ViewChild(SmartTableComponent) tableWrap: SmartTableComponent;



  @Input() tableSettings: any;
  @Input() tableSource: LocalDataSource;
  @Input() itemName = 'users';
  @Input() divID; // id of the containing div
  @Input() keyName; // key of the items eg orgUserID

  @Input() activeTab$: Observable<any>;
  @Input() selectedItems = [];
  @Input() smartTableClasses = [];
  @Input() ignoreSelect = false;
  @Input() selectAllOnInit = false;
  @Input() inCard?: boolean = false;
  @Input() stickyLeft = false;
  @Input() stickyRight = false;
  @Input() hideDefaultText = false;
  @Input() hideBulkAction = false;
  @Input() downloadSettings: ITableDownloadSettings
  @Input() preselected?: any[];

  @Output() rowSelected = new EventEmitter();


  destroyed$ = new Subject<void>();

  constructor(
    private smartTableService: SmartTableService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.activeTab$?.pipe(takeUntil(this.destroyed$)).subscribe(tab => {
      this.selectedItems = [];
    });

    // empty selected array when table is reloaded eg due to data re-fetch
    this.tableSource.onChanged().pipe(
      tap(({action}) => console.log(action)),
      filterRx(({ action }) => action === 'load'),
      takeUntil(this.destroyed$)
    ).subscribe(update => {
      // console.log(update);
      this.selectedItems = this.preselected || [];
    });
    if (this.selectAllOnInit) {
      this.onSelectAll();
    }
  }

  onSelectAll() {
    this.tableSource.getFilteredAndSorted().then(source => {
      this.selectedItems = clone(source);
      this.smartTableService.onSelectAll(this.multiSelectData);
      this.smartTableService.syncTable(this.selectedItems, this.multiSelectData);
      this.emitSelection();
    });
  }

  onDeselectAll() {
    this.selectedItems = [];
    this.smartTableService.onDeselectAll(this.multiSelectData);
    this.smartTableService.syncTable(this.selectedItems, this.multiSelectData);
    this.emitSelection();
  }

  onRowSelect(event) {
    if (this.ignoreSelect) {
      return;
    }
    this.selectedItems = this.smartTableService.onUserRowSelect(event, this.selectedItems, {
      availableItems: this.availableItems,
      shownItems: this.shownItems,
      table: this.tableWrap.table,
      keyName: this.keyName,
      tableRef: '#' + this.divID
    });
    
    // handle weird duplicate issue when there is preselected items
    if (this.preselected) {
      this.selectedItems = unique(this.selectedItems, (i) => i[this.keyName])
    }
    this.emitSelection();
  }

  ngAfterViewInit(): void {
    this.multiSelectData = {
      availableItems: this.tableSource.count(),
      shownItems: this.shownItems,
      table: this.tableWrap.table,
      keyName: this.keyName,
      tableRef: '#' + this.divID
    };

  }

  ngAfterViewChecked() {
    this.smartTableService.syncTable(this.selectedItems, this.multiSelectData);
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  emitSelection() {
    this.rowSelected.emit(this.selectedItems);
  }





}
