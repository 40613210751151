import { Directive, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

@Directive({
    selector: '[component-host]',
    standalone: true,
})
export class ComponentHostDirective {
  constructor(private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { }

  loadComponent(componentCtor) {
    this.viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentCtor);
    return this.viewContainerRef.createComponent(componentFactory);
  }
}
