import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { catchError, concatAll, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { scriptLoader } from '../shared/utils/script-loader';
import { find, union } from 'underscore';
import { BroadcastService } from './broadcast.service';
import { FeatureFlagService } from './feature-flag.service';
import { FEATURES } from '../core/features.config';

declare var Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class OrgSubscriptionService {
    unpaidInvoices: BehaviorSubject<[]> = new BehaviorSubject([]);
    processingUnpaidInvoices: BehaviorSubject<boolean> = new BehaviorSubject(false);
    paymentMethods: BehaviorSubject<any> = new BehaviorSubject(null);
    private _pendingPaymentMethods$: Observable<any>;
    constructor(
        protected http: HttpClient,
        private errorHandlingService: ErrorHandlingService,
        private broadcastService: BroadcastService,
        private featureFlagService: FeatureFlagService
    ) {
        this.broadcastService.on('logout').subscribe(() => {
            this.unpaidInvoices.next([]);
            this.processingUnpaidInvoices.next(false);
            this.paymentMethods.next(null);
        });
    }

    getOrgStripeUser(orgID): Observable<any> {
        const url = environment.accountServiceEndpoint + `/orgs/${orgID}/stripe/customer`;
        return this.http.get(url).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );

    }

    createOrgStripeUser(orgID): Observable<any> {
        const url = environment.accountServiceEndpoint + `/orgs/${orgID}/stripe/customer`;
        return this.http.post(url, {}).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    deleteCardFromOrgStripe(orgID, sourceID): Observable<any> {
        return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/sources/${sourceID}`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    saveCardToOrgStripe(orgID, sourceID): Observable<any> {
        return this.http.post(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/sources`, { 'source': sourceID }).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getClientSecret(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/payments/setup`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }
    getDebitClientSecret(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/debitPayments/setup`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    setDefaultPayment(orgID, default_payment_method): Observable<any> {
        return this.http.post(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/defaultPayment`, { 'default_payment_method': default_payment_method }).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    deletePayments(orgID, paymentID): Observable<any> {
        return this.http.delete(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/payments/${paymentID}`).pipe(
            tap(() => this.paymentMethods.next(null)),
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getCardPayments(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/cardPayments`).pipe(
            tap((payments: any) => {
                this.paymentMethods.next(union((this.paymentMethods.value || []), (payments.data || [])))
            }),
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getDebitPayments(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/debitPayments`).pipe(
            tap((payments: any) => {
                this.paymentMethods.next(union((this.paymentMethods.value || []), (payments.data || [])))
            }),
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    fetchPaymentMethods(orgID: string, refresh?: boolean): Observable<any> {
        const paymentMethods = this.paymentMethods.value;

        if (this._pendingPaymentMethods$) {
            return this._pendingPaymentMethods$;
        } else if (paymentMethods && !refresh) {
            return this.paymentMethods.asObservable();
        }
        return this._pendingPaymentMethods$ = this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/cardPayments`).pipe(
            switchMap((cardPayments: any) => {
                this.paymentMethods.next(cardPayments.data || []);
                return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/debitPayments`).pipe(
                    switchMap((debitPayments: any) => {
                        this.paymentMethods.next(union(this.paymentMethods.value, (debitPayments.data || [])));
                        delete this._pendingPaymentMethods$;
                        return this.paymentMethods.asObservable()
                    }),
                    catchError((e) => {
                        delete this._pendingPaymentMethods$;
                        return this.errorHandlingService.handleHttpError(e);
                    }),
                )
            }),
            catchError((e) => {
                delete this._pendingPaymentMethods$;
                return this.errorHandlingService.handleHttpError(e);
            }),
        );
    }

    checkRequirePayment(action, orgID): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if ((action === 'invite' || action === 'reinvite' || action === 'send reminders') && this.featureFlagService.featureOn(FEATURES.requireSetupBilling)) {
                this.fetchPaymentMethods(orgID).subscribe(
                    (payments) => {
                        if (payments.length < 1) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                )

            } else {
                resolve(false);
            }
        });

    }

    createStripeFormToken(form) {
        return scriptLoader.loadJS('https://js.stripe.com/v2/').then((initial) => {
            if (initial) {
                Stripe.setPublishableKey(environment.stripeKey);
            }
            return new Promise((resolve, reject) => {
                Stripe.card.createToken(form, (status, response) => {
                    if (response.error) {
                        reject(response.error);
                        // console.log(response);
                    } else {
                        resolve(response);
                    }
                }, (resp) => {
                    reject(resp);
                });
            });
        });
    }

    async setupCardPaymentIntent(stripe, clientSecret, cardElement) {
        return await stripe.confirmCardSetup(
            clientSecret,
            {
                payment_method: {
                    card: cardElement
                },
            },
        )
    }

    async setupDebitPaymentIntent(stripe, clientSecret, debitElement, orgName, orgEmail) {
        return await stripe.confirmAuBecsDebitSetup(
            clientSecret,
            {
                payment_method: {
                    au_becs_debit: debitElement,
                    billing_details: {
                        name: orgName,
                        email: orgEmail
                    }
                },
            },
        )
    }

    updateCardToOrgStripe(orgID, card): Observable<any> {
        return this.http.put(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/customer/sources/${card.id}`, card).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    updateStripeCustomerSource(orgID, default_source) {
        return this.http
            .put(environment.accountServiceEndpoint + `/orgs/${orgID}/stripe/customer`, { default_source: default_source })
            .pipe(
                catchError(this.errorHandlingService.handleHttpError),
            );
    }

    getOrgSubscriptions(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/subscriptions`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    cancelOrgSubscriptions(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/cancelledSubscriptions`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getOrgSubscriptionInvoices(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/invoices`).pipe(
            map((resp: any) => {
                this.processingUnpaidInvoices.next(true);
                return resp.map(i => {
                    if (i.status === 'open' && i.charge && i.charge.status === 'pending') {
                        i.status = 'pending';
                    }
                    return i;
                })
            }),
            tap((invoices) => {
                this.unpaidInvoices.next(invoices.filter(i => i.status === 'open') || []);
                this.processingUnpaidInvoices.next(false);
            }),
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    payOrgSubscriptionInvoice(orgID, invoiceId): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/invoices/${invoiceId}/pay`).pipe(
            tap(() => this.unpaidInvoices.next([])),
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getOrgSubscriptionUpcommingInvoices(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/invoices/upcoming`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getOrgSubscriptionInvoiceCharge(orgID, chargeId): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/stripe/charges/${chargeId}`).pipe(
            catchError(this.errorHandlingService.handleHttpError),
        );
    }

    getOrgSubscriptionUsage(orgID): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/monthUsages`).pipe(
            catchError(this.errorHandlingService.handleHttpError));
    }

    getOrgSubscriptionUsageForDate(orgID, date): Observable<any> {
        return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/monthUsages?userListUsageDate=${date}`).pipe(
            catchError(this.errorHandlingService.handleHttpError));
    }

    unpaidInvoiceNumber(): number {
        return this.unpaidInvoices.getValue().length;
    }

}

