import { Component, effect, inject, input, output } from '@angular/core';
import { ITableDownloadSettings, ITableSettings, SmartTableComponent } from '@components/smart-table/smart-table.component';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { SmartTableColumnsService, columnName } from 'src/app/services/smart-table-columns.service';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { TableActionButtonComponent } from '@components/table-cell/action-buttons/table-action-button/table-action-button.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { ITablePerformanceReviewAssign, PerformanceReviewService } from 'src/app/services/performance-review.service';
import { TaskRemindModalComponent } from './task-remind-modal/task-remind-modal.component';
import { getTaskActionButtons } from './task-table-utils';

@Component({
  selector: 'app-task-table',
  standalone: true,
  imports: [SmartTableComponent, TaskRemindModalComponent],
  templateUrl: './task-table.component.html',
  styleUrl: './task-table.component.scss'
})
export class TaskTableComponent {
  assigns = input.required<ITablePerformanceReviewAssign[]>();
  onDashboard = input<boolean>(false);
  tableKey = input<LocalTableUniqKey>();
  currentSettings = output<ITableSettings>();
  showCompleteDate = input();
  forCycle = input(false);
  forProgress = input(false);
  hiddenCols = input<columnName[]>();

  defaultColumns = getColumnProgressAssign();
  cyclePageColumns = getColumnProgressAssign(true);
  progressPageColumns = getColumnProgressAssign(true, ['accountStatus', 'staffID', 'internalID', 'email', 'orgEmail', 'cycle', 'teams', 'facilityModels', 'jobRoles'])

  source = new LocalDataSource();
  settings: ITableSettings;
  columnService = inject(SmartTableColumnsService);
  modalService = inject(NgbModal);
  prService = inject(PerformanceReviewService)

  downloadSettings = DOWNLOAD_SETTING_TASK_ASSIGNS;


  constructor() {
    effect(() => {
      this.settings = {
        tableUniqKey: LocalTableUniqKey[this.tableKey()],
        actions: false,
        columns: {
          ...(this.forCycle() ? this.cyclePageColumns : this.forProgress() ? this.progressPageColumns : this.defaultColumns),
          actions: {
            title: 'Actions',
            exemptFromEdit: true,
            sort: false,
            filter: false,
            type: 'custom',
            renderComponent: TableActionButtonComponent,
            onComponentInitFunction: (instance: TableActionButtonComponent) => {
              instance.buttons = getTaskActionButtons();
              instance.actionClicked.subscribe(val => this.onAction(val.action, val.rowData))
            }
          }

        }
      };
      this.currentSettings.emit(this.settings);
      if (this.onDashboard() === true) {
        this.source.load(this.assigns().slice(0, 5));
      } else {
        this.source.load(this.assigns());
      }

    })
  }

  onAction(action, task: ITablePerformanceReviewAssign) {
    if (action === 'remind') {
      this.prService.remindModal(task, 'my tasks');
      return;
    }
    this.openReviewTask(task)
  }

  openReviewTask(task: ITablePerformanceReviewAssign) {
    const modal = this.modalService.open(TaskModalComponent, { size: '95', backdrop: 'static' });
    modal.componentInstance.taskID = task.taskID;
  }

}

export const DOWNLOAD_SETTING_TASK_ASSIGNS: ITableDownloadSettings = { title: 'My Activities', };

export function getColumnProgressAssign(forCycle = false, hiddenCols?: columnName[]) {
  const columnService = inject(SmartTableColumnsService);
  if (forCycle) {
    return {
      ...columnService.getColumns(['taskTemplate',], { local: true }),
      taskTypeDisplay: {
        title: 'Type',
        defaultToShow: true
      },
      ...columnService.getColumns(['status', 'user', 'assignDate', 'dueDate', 'completeDate', 'taskManager', 'teams', 'jobRoles', 'facilityModels', 'accountStatus', 'staffID', 'internalID', 'email', 'orgEmail', 'cycle',], { local: true, hidden: hiddenCols || ['staffID', 'email', 'orgEmail', 'internalID'] }),

    }
  }

  return {
    ...columnService.getColumns(['taskTemplate',], { local: true }),
    taskTypeDisplay: {
      title: 'Type',
      // valuePrepareFn: (v) => TEMPLATE_TYPE_DISPLAY.get(v)
    },
    ...columnService.getColumns(['status', 'user', 'staffID', 'internalID', 'email', 'orgEmail','taskManager', 'cycle',], { local: true, hidden: ['staffID', 'email', 'taskManager', 'orgEmail','internalID'] }),
    ...columnService.getColumns(['assignDate', 'dueDate', 'completeDate'], { local: true }),
  };
}
