import { Component, Input } from '@angular/core';
import { AvatarRequirementsComponent } from '@components/avatar/avatar-requirements/avatar-requirements.component';
import { TruncatedTextComponent } from '@components/customised-multi-select/truncated-text/truncated-text.component';
import { IRequirementTable } from 'src/app/services/requirement.service';

@Component({
  selector: 'app-req-title-cell.ts',
  standalone: true,
  imports: [AvatarRequirementsComponent, TruncatedTextComponent],
  templateUrl: './req-title-cell.ts.component.html',
  styleUrl: './req-title-cell.ts.component.scss'
})
export class ReqTitleCellTsComponent {
  @Input() value;
  @Input() rowData: IRequirementTable;

}
