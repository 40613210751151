<app-loading-container [isLoaded]="isLoaded">
    <div *ngIf="shortcuts$ | async as shortcuts">
        <div class="d-flex justify-content-between mb-2">
            <h3>Recently Saved {{this.shortcutType?.includes('Report') ? 'Reports' : 'Filters'}}</h3>
            <button class="btn btn-link" [routerLink]="[link]">
                <span class="fw-semibold">View All</span>
            </button>
        </div>

        <ng-container *ngIf="shortcuts?.length; else noData">
            <div class="row g-2">
                @for (shortcut of shortcuts; track $index) {
                <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                    <div class="card p-4 h-100 justify-content-center">
                        <a [routerLink]="shortcut.routerLink" [queryParams]="shortcut.queryParams">
                            <div class="d-flex align-items-center gap-2 justify-content-between">
                                <div>
                                    <p class="fw-semibold">{{(shortcut.title | slice:0:20) + (shortcut.title?.length
                                        >
                                        20 ?
                                        '...' : '')}}</p>
                                    <p class="f-caption">{{shortcut.displayLocation}}</p>
                                </div>
                                <ui-streamline-icon class="text-pink-700"
                                    [slIcon]="{name: 'interface-bookmark', theme: 'micro-bold'}"></ui-streamline-icon>
                            </div>
                        </a>
                    </div>

                </div>
                }
            </div>

        </ng-container>
    </div>
</app-loading-container>

<ng-template #noData>

    <p class="f-small text-neutral-700 fw-medium">
        <ui-streamline-icon [slIcon]="{ name: 'bookmark', theme: 'core-line' }"></ui-streamline-icon>
        There are no Saved Filters in this space. Save a filter in your favourite search to streamline your future
        searches
    </p>
</ng-template>