import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-step-progress',
  standalone: true,
  imports: [CommonModule, UIStreamlineIconComponent],
  templateUrl: './step-progress.component.html',
  styleUrl: './step-progress.component.scss'
})
export class StepProgressComponent {
  @Input() steps: string[];
  @Input() currentStep: number;
  @Output() stepChange = new EventEmitter();

  onStepClick(i) {
    if (i < this.currentStep) {
      this.stepChange.emit(i);
    }
  }

}
