import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toMonth',
    standalone: true
})
export class ToMonthPipe implements PipeTransform {

    transform(value: number, format = 'MMM'): any {
        const monthNames = {
            'MMM': ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            'M':["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ]
        };
        if (typeof value === 'undefined') {
            return '--';
        }
        return monthNames[format][value-1]
    }


}
