import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { LocaliserService } from '../../services/localiser.service';


@Injectable()
export class CustomDateFormatter extends NgbDateParserFormatter {

  constructor(
      private localiser: LocaliserService,
  ) {
    super();
  }


  format(date: NgbDateStruct | null): string {
    return date ? (dayjs().set({ year: date.year, month: date.month - 1, date: date.day})).format(this.localiser.dateFormat) : '';
  }

  parse(value: string): NgbDateStruct | null {
    return undefined;
  }
}
