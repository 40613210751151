import { IOrgUser, UserService } from '../../services/user.service';
import { IContract, OrganisationService } from '../../services/organisation.service';
import { PRODUCT_TYPES } from '../../core/guards/mode.constants';
import { camelCase, curry, isEqual, omit } from 'lodash';
import { LearningGroupType } from 'src/app/pages/reporting/reporting-type';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { FEATURES } from 'src/app/core/features.config';
import { getByoResourceTypeByResource, getItemTypeByResource } from './get-resource-type';
import { ILearningObject, IOrgResource, IQuizQuestion, IUnit, RESOURCE_TYPE_ENUM } from '../byo-shared/byo-resource-types';
import { flatten, indexBy, pick, pluck, some } from 'underscore';
import { AnalyticsService, PAGE_CONTEXT_TYPES, assignActionContext } from 'src/app/services/analytics.service';
import { ResourceProviderType } from 'src/app/services/mandatory-training.service';

export interface IAssessmentUpdateEventBody {
  context: string;
  titleUpdated: boolean;
  durationUpdated: boolean;
  gradeUpdated: boolean;
  questionsUpdated: boolean;
  assessment: {
    title;
    duration;
    graded;
    passmark;
    numberOfAttempts;
    totalQuestions;
  };

}

export function getIdentifyBody(orgUser: IOrgUser, org: any, contract: IContract, services: {
  orgService: OrganisationService;
  featureFlagService: FeatureFlagService;
  userService: UserService;
}) {

    // values are analytics terms
    const CONTRACT_DICT: {[value in PRODUCT_TYPES]: string} = {
      'Ausmed LMS Free': 'Ausmed LMS',
      'Ausmed LMS Premium': 'Ausmed Premium',
      'Ausmed Library': 'Library',
      'Hippotlian LMS': 'Hippotlian',
      [PRODUCT_TYPES.CONTENT_PARTNER]: PRODUCT_TYPES.CONTENT_PARTNER,
    };

    // keys are analytics terms
    const FEATURE_DICT: {[key: string]: FEATURES} = {
      'Mandatory Training': FEATURES.MT,
      'Course Builder': FEATURES.courseBuilder,
      'Manual Entry Record': FEATURES.manualEntryRecord,
      'Practical Activity': FEATURES.practicalActivity,
      'Require User ID': FEATURES.requireUserID,
      'Stripe Billing': FEATURES.stripeBilling,
    };
    const { featureFlagService } = services;
    const allFeatures = featureFlagService.featureFlags;
    // console.log(featureFlagService.featureFlags);

    return {
        email: orgUser.userDetail?.email,
        firstName: orgUser.userDetail?.firstName,
        lastName: orgUser.userDetail?.lastName,
        username: orgUser.userDetail?.username,
        country: org.address ? org.address.country : '',
        currentPosition: {
          startDate: orgUser.work?.startDate || '',
          title: orgUser.work?.position || ''
        },
        organisation: org.name,
        orgCode: org.code,
        orgID: localStorage.getItem('orgID'),
        location: org.address ? org.address.state : '',
        product: contract.contractType === PRODUCT_TYPES.LMS && contract.billing?.products?.every(product => product.productName !== 'LMS') ? 'Clinical Resource'
                  : contract.contractType === PRODUCT_TYPES.LMS ? 'LMS'
                  : contract.contractType === PRODUCT_TYPES.LIBRARY ? 'Library'
                  : '',
        contractType: CONTRACT_DICT[contract?.contractType],
        settings: {
          vertical: org.lmsSetting?.industry,
          reportingMeasure: org.lmsSetting?.reportingMeasure,
          reportingWindow: org.lmsSetting?.reportingWindow,
        },
        featureFlags: Object.keys(FEATURE_DICT)
          .filter(k => allFeatures[FEATURE_DICT[k]]),
        adminLogin: localStorage.getItem('adminLogin') ? true : false,
        permissionType: services.userService.isManager ? 'Org' : 'Team'
    };
}

export function getContextFromUrl(url: string, resource?: any) {
  const urlDict = {
    '/reporting/overview/learning-evaluate/suggested-by-user': 'userSuggestion',
    '/library/resource': 'ausmedLibrary',
    '/byo': 'orgLibrary',
    '/staff-portal/byo/items': 'orgLibrary',
    '/library/all-resources': 'ausmedLibrary',
    '/library/provider': 'thirdPartyLibrary',
    '/library/professions': 'professionLibrary',
    '/library/learning-hubs': 'hubLibrary',
    '/library/standards': 'standardsLibrary',
    '/library/topics': 'topicsLibrary',
    '/library/mandatory-training': 'mandatoryTrainingLibrary',
    '/reporting/overview/learning-evaluate/feedback-report': 'feedbackReport'
  };
  let context = Object.entries(urlDict).reduce((acc, curr) => {
    if (url.includes(curr[0])) {
      return curr[1];
    } else {
      return acc;
    }
  }, '');
  if (!context && resource) {
    const contextDict = {
      'partnerLibrary': 'partnerLibrary',
      'yourLibrary': 'orgLibrary',
      'ausmedLibrary': 'ausmedLibrary'
    };
    context = contextDict[getResourceContext(resource)];
  }
  // distinguish orglib and partnerlib
  if (context === 'orgLibrary' && resource && getResourceContext(resource) === 'partnerLibrary') {
    context = 'partnerLibrary';
  }
  return context;

}

export function getResourceViewedContext(url: string, resource?: any) {
  const urlDict = {
    '/reporting/overview/learning-evaluate/suggested-by-user': 'userSuggestion',
    '/byo': 'orgLibrary',
    '/staff-portal/byo/items': 'orgLibrary',
    '/library': 'ausmedLibrary',
    '/library/provider': 'thirdPartyLibrary',
    // for resource viewed
    '/staff-transcript': 'staffTranscript',
    '/compliance/mandatory-training': 'mandatoryTraining',
    '/dashboard': 'dashboard'
  };

  let context = Object.entries(urlDict).reduce((acc, curr) => {
    if (url.includes(curr[0])) {
      return curr[1];
    } else {
      return acc;
    }
  }, '');
  if (!context && resource) {
    context = getContextFromResource(resource);
  }
  return context;

}

function getContextFromResource(resource) {
    const contextDict = {
      'partnerLibrary': 'partnerLibrary',
      'yourLibrary': 'orgLibrary',
      'ausmedLibrary': 'ausmedLibrary'
    };
    return contextDict[getResourceContext(resource)];

}

export function getRecordAddedContext(url: string, resource?: any) {
  const urlDict = {
    '/reporting/overview/learning-evaluate/suggested-by-user': 'userSuggestion',
    '/library/resource': 'resourceDetails',
    '/library/provider/': 'resourceDetails',
    '/byo/item/': 'resourceDetails',
    '/byo': 'orgLibrary',
    '/staff-portal/byo/items': 'orgLibrary',
    '/library/all-resources': 'allResources',
    '/library/providers': 'thirdPartyLibrary',
    '/library/professions': 'professionLibrary',
    '/library/learning-hubs': 'hubLibrary',
    '/library/standards': 'standardsLibrary',
    '/library/topics': 'topicsLibrary',
    '/library/mandatory-training': 'mandatoryTrainingLibrary',
    '/reporting/overview/learning-evaluate/feedback-report': 'feedbackReport'
  };

  let context = Object.entries(urlDict).reduceRight((acc, curr) => {
    if (url.includes(curr[0])) {
      return curr[1];
    } else {
      return acc;
    }
  }, '');
  if (!context && resource) {
    context = getContextFromResource(resource);
  }
  return context;

}

export function getResourceViewedBody(url, resource) {
  const context = getResourceViewedContext(url, resource);
  return {
    resource: getAnalyticsResource(resource),
    context,
    type: getProviderType(resource)
  };

}

export function subBrowseNeeded(currentUrl: string) {
  const contexts = ['professions', 'learning-hubs', 'standards', 'providers', 'topics'];
  return contexts.some(c => currentUrl.includes('/library/' + c + '/'));
}

// get subbrowse context from url for tables other than learning hubs or topics
export function getSubBrowse(currentUrl: string) {
  const pathName = currentUrl.split('/').pop();
  return pathName.split('%20').join(' ');
}

export function getReportType(currentUrl: string = window.location.pathname) {
  const arr = currentUrl.split('/');
  const idx = arr.findIndex(w => w === 'reporting') + 1;
  if (idx > 0 && arr.length > idx) {
    return camelCase(arr[idx].replace('/', ' '));
  }
}

export function calculateDateRange(start: string, end: string) {
  return ((Date.parse(end) - Date.parse(start)) / 24 / 60 / 60 / 1000);
}

// preprocess resource detail before sent as tracker property
export function getAnalyticsResource(resource) {
  const toOmit = ['transcript', 'explainerDetail.transcript', 'resource', 'actions',
  'orgResourceDetail.content', 'actions.orgResourceDetail.content', 'content', 'courseDetail.outcomes', 'outcomes', 'orgResourceDetail.outcomes', 'orgResourceDetail.units'];
  return omit(resource, ...toOmit);
}

// preprocess resource detail before sent as tracker property (Multiple resources will upload 3 objects. Ref: L-assign-added in Events Dict Document)
export function getAnalyticsResourcesLibraryAndTitleAndType(resources: IOrgResource[]) {
  function resourceLibraryMap (providerType: ResourceProviderType) {
    switch (providerType) {
      case 'Ausmed': return 'ausmedLibrary';
      case '3rd Party': return '3rd partyLibrary';
      case 'Internal': return 'yourLibrary';
    }
  }
  const resourceLibrary: string[] = [];
  const resourceTitle: string[] = [];
  const resourceType: string[] = [];
  resources.forEach(r => {
    resourceLibrary.push(resourceLibraryMap(r.providerType as ResourceProviderType));
    resourceTitle.push(r.title);
    resourceType.push(r.resourceType || r.activityType);
  });
  return {resourceLibrary: resourceLibrary, resourceTitle: resourceTitle, resourceType: resourceType};
}

export function getProviderType(resource: any) {
  return resource.thirdParty || resource.providerType === '3rd Party' || resource.providerName && !resource.providerName.includes('Ausmed')
      ? 'thirdParty'
      : resource.orgResourceDetail || resource.orgResource ? 'org'
          : 'ausmed';
}

export function translateLearningGroupType(type: LearningGroupType) {
  if (type === LearningGroupType.individual) {
    return 'individualUser';
  }
  return type;
}

export function translateReqStatus(display: string) {
  const anStatusDict = {
    'assigned': 'open',
    'incomplete': 'missed'
  };
  return anStatusDict[display.toLowerCase()] || display.toLowerCase();
}

export function translateResType(resource: any) {
  // return resource?.resourceType || resource?.activityType;
  // return getItemTypeByResource(resource).toLowerCase();

  if (resource?.orgID || resource?.orgResource) {
    return getByoResourceTypeByResource(resource).value;
  } else {
    return resource?.resourceType || resource?.activityType || 'unidentified';
  }
}

export function getResourceContext(resource: any) {
  if (resource.orgResourceDetail || resource.orgResource) {
    if (resource.orgResourceDetail) {
      if (resource.orgResourceDetail.isShareable && resource.orgResourceDetail.provider?.orgID !== localStorage.getItem('orgID')) {
        return 'partnerLibrary';
      }
    }
    return 'yourLibrary';
  }
  return 'ausmedLibrary';
}

export function workroomActionContext(): 'complianceWorkroom' | 'transcript' | 'trainingPlan' | 'complianceCalendar' {
   return Object.keys(assignActionContext).reduce((p, c) => {
     if (window.location.pathname.includes(c)) {
       return assignActionContext[c];
     }
     return p;
   }, 'complianceWorkroom');
}

export function getGeneralContext(rowData?) {
  const contextDict = {
    'workroom': 'complianceWorkroom',
    'transcript': 'transcript',
    'training': 'trainingPlan',
    'dashboard': 'dashboard',
    'manage-staff': 'users',
    'admin/teams': 'teams',
    'admin/manage-job-roles': 'jobRoles',
    '/reporting': 'reporting',
    '/byo/': 'orgLibrary',
    'library/resource': 'ausmedLibrary'
   };
   let context =  Object.keys(contextDict).reduce((p, c) => {
    if (window.location.pathname.includes(c)) {
      return contextDict[c];
    }
    return p;
  }, 'dashboard');

  if (context === 'orgLibrary' && rowData) {
    // find resource object in rowData since they can be under different names
    const resource = Object.values(pick(rowData, ['resource', 'resourceObj'])).reduce((p, c) => {
      return c.orgResourceDetail ? c : p;
    }, null);
    if (resource && getResourceContext(resource) === 'partnerLibrary') {
      context = 'partnerLibrary';
    }
  }
  return context;
}


export function addAssignContext(): 'itemDetails' | 'trainingPlans' | 'ausmedLibrary' | 'partnerLibrary' | 'orgLibrary' {
  const contextDict = {
    'training': 'trainingPlan',
    '/byo/': 'orgLibrary',
    'pages/library/': 'ausmedLibrary',
    'nzac-lib': 'partnerLibrary',
    '/item/': 'itemDetails',
    '/course-analytics/': 'itemDetails',
    '/resource/': 'itemDetails'
   };
   return Object.keys(contextDict).reduce((p, c) => {
    if (window.location.pathname.includes(c)) {
      return contextDict[c];
    }
    return p;
  }, 'itemDetails');
}

export function generalGetFromUrl(contextDict: any) {
  return Object.keys(contextDict).reduceRight((p, c) => {
    if (window.location.pathname.includes(c)) {
      return contextDict[c];
    }
    return p;
  }, contextDict[Object.keys(contextDict)[0]]);
}

export function getDeleteContext() {
  const contextDict = {
    'transcript': 'transcript',
    'training': 'trainingPlan',
    'reporting': 'reporting'
  };
  return generalGetFromUrl(contextDict);
}
/**
 * Course Builder events
*/
export function getAnalyticsUnit(unit: IUnit) {
  return {
    title: unit.name,
    totalItems: unit.learningObjects?.filter(learningObject => learningObject.learningType !== RESOURCE_TYPE_ENUM.assessment).length,
    totalAssessments: getUnitAssessments(unit).length,
    totalQuestions: getUnitTotalQuestions(unit)
  };
}

function getUnitAssessments(unit: IUnit) {
  return unit.learningObjects?.filter(learningObj => learningObj.learningType === RESOURCE_TYPE_ENUM.assessment);
}

function getUnitTotalQuestions(unit: IUnit) {
  return getUnitAssessments(unit)
    .reduce((acc, cur) => {
      return acc + cur.questions.length;
    }, 0);
}

function getUnitItems(unit: IUnit) {
  return unit.learningObjects?.filter(learningObject => learningObject.learningType !== RESOURCE_TYPE_ENUM.assessment);
}

export function checkLearnObjContentUpdated(oldObject: ILearningObject, newObject: ILearningObject) {
  const allContents = ['content', 'fileUrl', 'webUrl', 'videoUrl'];
  return some(allContents, contentType => {
    return oldObject[contentType] !== newObject[contentType];
  });
}

/** Learning object add/remove/updates */

export function sendLearnObjAddTrack(
  analytics: AnalyticsService,
  updatedLearningObj: ILearningObject,
  updatedCourse: any) {
    const isAssessment = updatedLearningObj.learningType === RESOURCE_TYPE_ENUM.assessment;
    // adding unit
    if (!isAssessment) {
      analytics.track('L-unit-item_added', {
        context: 'courseBuilder',
        item: {
          title: updatedLearningObj.name,
          duration: updatedLearningObj.min,
          resourceType: updatedLearningObj.learningType,
        },
        resource: getAnalyticsResource(updatedCourse)
      });
    } else { // adding assessment
      analytics.track('L-unit-assessment_added', {
        context: 'courseBuilder',
        assessment: {
          title: updatedLearningObj.name,
          duration: updatedLearningObj.min,
          graded: updatedLearningObj.isGraded,
          passMark: updatedLearningObj.passMark,
          numberOfAttempts: updatedLearningObj.attemptTime,
          recoveryPointSet: updatedLearningObj.isGraded ? !!updatedLearningObj.recoverProgressPoint : undefined
        },
        resource: getAnalyticsResource(updatedCourse)
      });
    }
    // Send update unit event
    const unit = getUnitFromLearning(updatedLearningObj, updatedCourse);
    analytics.track('L-unit-updated', {
      context: 'courseBuilder',
      itemUpdated: !isAssessment,
      assessmentUpdated: isAssessment,
      unit: getAnalyticsUnit(unit),
      resource: getAnalyticsResource(updatedCourse)
    });
}

// currently unused
export function sendLearnObjUpdateTrack(
  analytics: AnalyticsService,
  oldLearningObj: ILearningObject,
  updatedLearningObj: ILearningObject,
  updatedCourse: any) {
    if (updatedLearningObj.learningType !== RESOURCE_TYPE_ENUM.assessment) {
      analytics.track('L-unit-item_updated', {
        context: 'courseBuilder',
        titleUpdated: oldLearningObj.name !== updatedLearningObj.name,
        durationUpdated: oldLearningObj.min !== updatedLearningObj.min,
        contentUpdated: checkLearnObjContentUpdated(oldLearningObj, updatedLearningObj),
        item: {
          title: updatedLearningObj.name,
          duration: updatedLearningObj.min,
          resourceType: updatedLearningObj.learningType,
        },
        resource: getAnalyticsResource(updatedCourse)
      });
    }

}

export function sendLearnObjDeleteTrack(
  analytics: AnalyticsService,
  deletedLearningObj: ILearningObject,
  updatedCourse: any) {
    const isAssessment = deletedLearningObj.learningType === RESOURCE_TYPE_ENUM.assessment;
    // delete item
    if (deletedLearningObj.learningType !== RESOURCE_TYPE_ENUM.assessment) {
      analytics.track('L-unit-item_deleted', {
        context: 'courseBuilder',
        item: {
          title: deletedLearningObj.name,
          duration: deletedLearningObj.min,
          resourceType: deletedLearningObj.learningType
        },
        resource: getAnalyticsResource(updatedCourse)
      });
    } else { // delete assessment
      analytics.track('L-unit-assessment_deleted', {
        context: 'courseBuilder',
        assessment: {
          title: deletedLearningObj.name,
          duration: deletedLearningObj.min,
          graded: deletedLearningObj.isGraded,
          passMark: deletedLearningObj.passMark,
          numberOfAttempts: deletedLearningObj.attemptTime,
          recoveryPointSet: deletedLearningObj.isGraded ? !!deletedLearningObj.recoverProgressPoint : undefined,
          totalQuestions: deletedLearningObj.questions?.length || 0
        },
        resource: getAnalyticsResource(updatedCourse)
      });
    }
        // Send update unit event
        const unit = getUnitFromLearning(deletedLearningObj, updatedCourse);
        analytics.track('L-unit-updated', {
          context: 'courseBuilder',
          itemUpdated: !isAssessment,
          assessmentUpdated: isAssessment,
          unit: getAnalyticsUnit(unit),
          resource: getAnalyticsResource(updatedCourse)
        });
}

// currently unused
export function sendUpdateAssessmentTrack(
  analytics: AnalyticsService,
  changeList: any,
  assessment: ILearningObject,
  updatedCourse
) {
  analytics.track('L-unit-assessment_updated', {
    context: 'courseBuilder',
    titleUpdated: false,
    durationUpdated: false,
    gradeUpdated: false,
    questionsUpdated: false,
    ...changeList,
    assessment: {
      title: assessment.name,
      duration: assessment.min,
      graded: assessment.isGraded,
      passmark: assessment.passMark,
      numberOfAttempts: assessment.attemptTime,
      totalQuestions: assessment.questions?.length || 0
    },
    resource: getAnalyticsResource(updatedCourse)
  });
}

/** Course/survey change */

export function sendUpdateCourseTrack(analytics: AnalyticsService, initCourse: IOrgResource, newCourse: IOrgResource) {
  // only fires when editing already-published resources
  if (initCourse.status !== 'Published') {
    return;
  }
  const body = {
    context: 'courseBuilder',
    detailsUpdated: compareObjects(omitAtt(initCourse, newCourse, ['min', 'orgResourceDetail.units', 'updateDate'])),
    unitsUpdated: compareObjects([initCourse.orgResourceDetail?.units, newCourse.orgResourceDetail?.units]),
    itemsUpdated: isLearningObjectsChanged(initCourse?.orgResourceDetail?.units, newCourse.orgResourceDetail?.units) || false,
    assessmentsUpdated: isLearningObjectsChanged(initCourse?.orgResourceDetail?.units, newCourse.orgResourceDetail?.units, true) || false,
    questionsUpdated: isQuestionsChanged(initCourse?.orgResourceDetail?.units, newCourse.orgResourceDetail?.units) || false,
    resource: {
      totalUnits: newCourse.orgResourceDetail?.units?.length || 0,
      totalItems: getCourseTotal(newCourse.orgResourceDetail?.units, 'items'),
      totalAssessments: getCourseTotal(newCourse.orgResourceDetail?.units, 'assessments'),
      totalQuestions: getCourseTotal(newCourse.orgResourceDetail?.units, 'questions'),
      educators: newCourse.orgResourceDetail.educators
    }
  };
  if (Object.values(pick(body, ['detailsUpdated', 'unitsUpdated', 'itemsUpdated', 'assessmentsUpdated', 'questionsUpdated'])).some(p => p)) {
    analytics.track('L-resource-edited', body);
  }
}

export function sendUpdateFormTrack(analytics: AnalyticsService, initCourse: IOrgResource, newCourse: IOrgResource) {
  // if (initCourse.status !== 'Published') {
  //   return;
  // }
  const body = {
    detailsUpdated: compareObjects(omitAtt(initCourse, newCourse, ['min', 'orgResourceDetail.units', 'updateDate', 'orgResourceDetail.orgResourceExtraDetail.subType'])),
    sectionsUpdated: isLearningObjectsChanged(initCourse?.orgResourceDetail?.units, newCourse.orgResourceDetail?.units, true) || false,
    questionsUpdated: isQuestionsChanged(initCourse?.orgResourceDetail?.units, newCourse.orgResourceDetail?.units) || false,
    surveyTypeUpdated: initCourse?.orgResourceDetail?.orgResourceExtraDetail?.subType !== newCourse?.orgResourceDetail?.orgResourceExtraDetail?.subType,

    survey: {
      status: newCourse.status,
      surveyType: newCourse.orgResourceDetail.orgResourceExtraDetail?.subType,
      totalSections: getCourseTotal(newCourse.orgResourceDetail?.units, 'assessments'),
      totalQuestions: getCourseTotal(newCourse.orgResourceDetail?.units, 'questions'),
    }

  };
  if (Object.values(pick(body, ['detailsUpdated', 'sectionsUpdated', 'questionsUpdated', 'surveyTypeUpdated'])).some(p => p)) {
    analytics.track('S-survey-edited', body);
  }
}

/** Helpers */

function compareObjects([obj1, obj2]): boolean {
  // return !isEqual(obj1, obj2);

  try {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  } catch {
    return false;
  }
}

function omitAtt(obj1, obj2, toOmit: string[]): [any, any] {
  return [omit(obj1, ...toOmit), omit(obj2, ...toOmit)];
}

export function getAnalyticsQType(question: IQuizQuestion) {
  return ({
    'single-answer': 'multiple choice - single answer',
    'multi-answers': 'multiple choice - multiple answer',
    'true/false': 'true or false',
    'short-answer': 'shortAnswer',
    'private-reflection': 'shortAnswer'
  }[question.qType]) || question.qType;
}

export function getCourseTotal(units: IUnit[], counted: string) {
  if (!units) {
    return 0;
  }
  const functionDic = {
    'items': (u) => getUnitItems(u).length,
    'assessments': (u) => getUnitAssessments(u).length,
    'questions': (u) => getUnitTotalQuestions(u)
  };
  return units
    .map(unit => functionDic[counted](unit))
    .reduce((acc, cur) => acc + cur, 0);
}

export function findAssessmentDifferences(oldAssessment: ILearningObject, newAssessment: ILearningObject) {
  const analDict = {
    name: 'titleUpdated',
    min: 'durationUpdated',
    isGraded: 'gradeUpdated'
  };
  const ans = {};
  const changedAttributes = findObjectDifferences(oldAssessment, newAssessment, Object.keys(analDict));
  changedAttributes.forEach(att => ans[analDict[att]] = true);
  return ans;
}

export function findObjectDifferences(obj1, obj2, attributes: string[]) {
  return attributes.filter(att => obj1[att] !== obj2[att]);
}

export function getUnitFromLearning(learning: ILearningObject, course: IOrgResource) {
  if (!course.orgResourceDetail.units) {
    return null;
  }
  return course.orgResourceDetail.units.find(unit => unit.unitID === learning.unitID);
}

export function isLearningObjectsChanged(initUnits: IUnit[], finalUnits: IUnit[], assessment = false): boolean {
  const getItemsFromUnit = (units: IUnit[]) => {
    return indexBy(flatten(pluck(units, 'learningObjects')).filter((learningObject: ILearningObject) => {
      const isAssessment = learningObject.learningType === RESOURCE_TYPE_ENUM.assessment;
      return assessment ? isAssessment : !isAssessment;
    }), 'objectID');
  };
  return !isEqual(getItemsFromUnit(initUnits), getItemsFromUnit(finalUnits));


}

export function isQuestionsChanged(initUnits: IUnit[], finalUnits: IUnit[]) {

  // get flatten array of questions in whole course
  const getQuestionsFromUnits = (units: IUnit[]) => {
    const questions = flatten(pluck(flatten(pluck(units, 'learningObjects')).filter((learningObject: ILearningObject) => {
      return learningObject.learningType === RESOURCE_TYPE_ENUM.assessment;
    }), 'questions'));
    return indexBy(questions, 'questionID');
  };
  return !isEqual(getQuestionsFromUnits(initUnits), getQuestionsFromUnits(finalUnits));
}

export function getSurveyAnalytics(survey: IOrgResource) {
  return {
    status: survey.status,
    surveyType: survey.orgResourceDetail.orgResourceExtraDetail?.subType,
    totalSections: getCourseTotal(survey.orgResourceDetail?.units, 'assessments'),
    totalQuestions: getCourseTotal(survey.orgResourceDetail?.units, 'questions'),
  };
}



/**
 * Course builder events ends
 */


 export function setContext(eventName: string, context: string) {
  localStorage.setItem('context_' + eventName, context);
}

export function useContext(eventName: string) {
  const context = localStorage.getItem('context_' + eventName);
  localStorage.removeItem('context_' + eventName);
  return context;
}

export function getContext(eventName: string) {
  const context = localStorage.getItem('context_' + eventName);
  return context;
}
/**
 * training plan event helpers
 */
// oneOff, recurring property
export function getScheduleProperties(schedule): {oneOff; recurring} {
  let recurring, oneOff;
  if (schedule.scheduleType === 'Once') {
    oneOff = schedule.scheduleDuration;
  } else {
    if (schedule.scheduleDuration) {
      recurring = 'interval';
    }
    if (schedule.dueDay) {
      recurring = 'date';
    }
  }
  return {oneOff, recurring};
}
