<div class="modal-content">
    <div class="modal-header p-5">
        <div class="progress w-100 me-5">
            <div class="progress-bar" role="progressbar" [style.width]="progress+'%'" aria-valuemax="100">
            </div>

        </div>
        <button (click)="closeModal()" type="button" class="btn btn-icon btn-square" data-dismiss="modal"
            aria-label="Close" tabindex="-1">
            <ui-streamline-icon [slIcon]="{name: 'interface-delete-1', theme: 'micro-bold', size: 'xs'}"
               ></ui-streamline-icon>
        </button>
    </div>

    <div>
        <ng-container *ngIf="loading">
            <div class="d-flex justify-content-center align-items-center" style="min-height:250px;">
                <ui-spinner></ui-spinner>
            </div>
        </ng-container>
        <ng-template component-host>
            
        </ng-template>
    </div>
</div>
