import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { TableColumnValuePrepareAndSortUtilsService } from 'src/app/services/table-column-value-prepare-and-sort-utils.service';
import { StaffLinkDisplayComponent } from 'src/app/shared/components/staff-link-display/staff-link-display.component';
import { map as _map, uniq as _uniq, compact as _compact, flattenDeep as _flattenDeep } from 'lodash';
import { Router } from '@angular/router';
import { assignActionContext } from 'src/app/services/analytics.service';
import { EvidencePayloadType } from 'src/app/services/evidence.service';
import { FormStatusType } from '@components/evidence-components/add-evidence-form/add-evidence.component';
import { NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { OrganisationService } from 'src/app/services/organisation.service';
import { SmartTableService } from 'src/app/services/smart-table.service';
import { IInterval } from '../../compliance/compliance-workroom/mark-complete-bulk/extend-date-selector/extend-date-selector.component';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';
import { Ng2SmartTableComponent } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.component';
import { WarningBannerComponent } from '../../compliance/compliance-workroom/mark-complete-bulk/warning-banner/warning-banner.component';
import { ITablePerformanceReviewAssign, PerformanceReviewService } from 'src/app/services/performance-review.service';
import { tap } from 'rxjs/operators';
import { AnalyticsPrService } from '../analytics-pr.service';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { BroadcastService } from 'src/app/services/broadcast.service';

/**
 *Modal for bulk mark as completed and as skipped.
 */
@Component({
    selector: 'app-performance-bulk-actions',
    templateUrl: './performance-bulk-actions.component.html',
    styleUrls: ['./performance-bulk-actions.component.scss'],
    standalone: true,
    imports: [ModalSingleComponent, NgIf,
        NgbCollapse, Ng2SmartTableComponent, UISpinnerComponent,
        UIStreamlineIconComponent, WarningBannerComponent]
})
export class PerformanceBulkActionsComponent implements OnInit, OnDestroy {
    broadcastService = inject(BroadcastService);
    modalHeading: string;
    selectedRecords: ITablePerformanceReviewAssign[]; // needs user and resource
    evidenceFormValue: EvidencePayloadType;
    @Input() showRemindWarning: boolean = false;
    @Input() showSkippedWarning: boolean = false;
    @Input() showUnskipWarning: boolean = false;
    hideTable = true;
    isSubmitting = false;
    evidenceFormStatus: FormStatusType;
    action: AssignActionType = 'Remind';
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    selectedUsers = [];
    contextTypes = {
        ...assignActionContext,
        'teams': 'teams',
        'manage-job-roles': 'jobRoles',
        'staff-transcript': 'transcript',
        'manage-staff': 'users',
        'compliance-workroom': 'complianceWorkroom',
        'mandatory-training': 'trainingPlan',
        'calendar': 'complianceCalendar'
    }

    // for extension
    extendInterval: IInterval;
    sendEmail = false;
    outputUpdatedRecords = false; // for compliance workroom
    confirmClicked = false;
    extensionType = 'period';
    newDueDate;

    columnService = inject(SmartTableColumnsService);
    tasksTableSource = new LocalDataSource([]);
    userTableSource = new LocalDataSource([]);
    tasksTableSettings = {
        actions: false,
        subHeader: true,
        columns: {
            ...this.columnService.getColumns(['taskTemplate',], { local: true }),
            taskTypeDisplay: {
                title: 'Type',
                defaultToShow: true
            },
            ...this.columnService.getColumns(['status', 'user', 'assignDate', 'dueDate', 'completeDate', 'taskManager', 'staffID', 'cycle',], { local: true }),
        }
    };

    userTableSettings = {
        noDataMessage: 'No Users Found',
        actions: false,
        hideSubHeader: true,
        selectedRowIndex: -1,
        columns: {
            displayName: {
                title: 'User',
                isSticky: true,
                type: 'custom',
                renderComponent: StaffLinkDisplayComponent,
                compareFunction: this._columnUtil.compareByProperty('fullName'),
                filterFunction: this._columnUtil.searchByProperty('fullName'),
            },
            staffID: {
                title: 'User ID',
                valuePrepareFunction: this._columnUtil.replaceNoRecord(),
                type: 'string',
                sort: false,
            },
            email: this.smartTableService.tableSettings.email,
            orgEmail: this.smartTableService.tableSettings.orgEmail,
        }
    };

    prService = inject(PerformanceReviewService);

    analyticsPrService = inject(AnalyticsPrService);

    constructor(
        private _columnUtil: TableColumnValuePrepareAndSortUtilsService,
        private activeModal: NgbActiveModal,
        private toaster: ToastrService,
        private router: Router,
        private organisationService: OrganisationService,
        private smartTableService: SmartTableService,
    ) { }

    ngOnInit(): void {
        // console.assert(this.selectedRecords[0].assignID);
        console.log(this.organisationService.organisation.getValue());

        if (this.action === 'Remind') {
            this.modalHeading = `Are you sure you want to send reminder${this.taskNum > 1 ? 's' : ''}
         to ${this.selectedUsers.length} user${this.selectedUsers.length > 1 ? 's' : ''}?`;
        }


        if (this.action === 'Skipped') {
            this.modalHeading = `Mark ${this.taskNum} 
       Task${this.taskNum > 1 ? 's' : ''} as ${this.action}`;
        }

        if (this.action === 'Unskip') {
            this.modalHeading = `Unskip ${this.taskNum} 
       Task${this.taskNum > 1 ? 's' : ''}`;
        }

        this.tasksTableSource.load(this.selectedRecords);
        if (this.selectedUsers.length) {
            this.userTableSource.load(this.selectedUsers);
        }

    }

    get taskNum() {
        return this.selectedRecords?.length;
    }

    get activeTableSetting() {
        return this.action === 'Remind' ? this.userTableSettings : this.tasksTableSettings;
    }

    get activeTableSource() {
        return this.action === 'Remind' ? this.userTableSource : this.tasksTableSource;
    }

    get viewHideText() {
        return this.action !== 'Remind'
            ? `${this.taskNum} task${this.taskNum > 1 ? 's' : ''}`
            : `${this.selectedUsers.length} user${this.selectedUsers.length > 1 ? 's' : ''}`;
    }

    closeModal(result = null) {
        this.activeModal.close(result);
    }

    confirmSkipped() {
    }

    sendReminderRequests() {
        this.isSubmitting = true;

        this.prService.taskRemind(this.selectedRecords.map(r => r.taskID))
            .pipe(
                tap(() => this.analyticsPrService.sendTrack('PR-task-reminder-sent', {
                    reminderType: 'bulk',
                    context: 'my tasks'
                }))
            )
            .subscribe(
                () => {
                    this.isSubmitting = false;
                    const record = this.selectedRecords.length > 1 ? 'Reminders' : 'Reminder';
                    const message = `${record} sent`;
                    this.closeModal(message);
                },
                error => {
                    this.isSubmitting = false;
                    this.toaster.error(error, 'Error');
                }
            );
    }

    markAsSkipped() {
        this.isSubmitting = true;
        this.prService.markAsSkipped(this.selectedRecords.map(r => r.taskID))
            .pipe(
            tap(() => this.analyticsPrService.sendTrack('PR-task-skipped', {
                taskIDs:this.selectedRecords.map(r => r.taskID)
            }))
        )
            .subscribe(
                () => {
                    this.isSubmitting = false;
                    const record = this.selectedRecords.length > 1 ? 'Tasks' : 'Tasks';
                    const message = `${record} skipped`;
                    this.closeModal(message);
                    this.prService.fetchCachedMyTasks(true).subscribe(() => this.broadcastService.broadcast('performance-task-updated'));
                },
                error => {
                    this.isSubmitting = false;
                    this.toaster.error(error, 'Error');
                }
            );
    }

    UnskipTasks() {
        this.isSubmitting = true;
        this.isSubmitting = false;
        const record = this.selectedRecords.length > 1 ? 'Tasks' : 'Tasks';
        const message = `${record} unskipped`;
        this.closeModal(message);
        this.prService.fetchCachedMyTasks(true).subscribe(() => this.broadcastService.broadcast('performance-task-updated'));
        return;
        this.prService.markAsSkipped(this.selectedRecords.map(r => r.taskID))
            .pipe(
            // tap(() => this.analyticsPrService.sendTrack('PR-task-reminder-sent', {
            //     reminderType: 'bulk',
            //     context: 'my tasks'
            // }))
        )
            .subscribe(
                () => {
                    this.isSubmitting = false;
                    const record = this.selectedRecords.length > 1 ? 'Tasks' : 'Tasks';
                    const message = `${record} skipped`;
                    this.closeModal(message);
                    this.prService.fetchCachedMyTasks(true).subscribe(() => this.broadcastService.broadcast('performance-task-updated'));
                },
                error => {
                    this.isSubmitting = false;
                    this.toaster.error(error, 'Error');
                }
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }




    // get event tracking context
    get eventContext() {
        const urlParamsList = this.router.url.split('/');
        for (let i in this.contextTypes) {
            if (urlParamsList.includes(i)) {
                const contextType = this.contextTypes[i];
                return contextType;
            }
        }
    }
}


export type AssignActionType = 'Skipped' | 'Remind' | 'Unskip'
