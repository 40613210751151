import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-advanced-card',
    templateUrl: './advanced-card.component.html',
    styleUrls: ['./advanced-card.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class AdvancedCardComponent {
  @Input() item;
}
