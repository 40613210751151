import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { removeEmptyFields } from '../shared/utils/remove-empty-fields';
import { GroupService, IJobRole, IJobRoleTaken } from './group.service';
import { ComplianceDateType, ILearningRecordType } from '../pages/reporting/reporting-type';
import { ResourceService } from './resource.service';
import { chain, Dictionary, sortBy, uniq } from 'underscore';
import { IOrgUser, UserService } from './user.service';
import { IOrgAssign, IOrgAssignStatsByDay, OrgAssignService, getDaysExtended } from './org-assign.service';
import * as dayjs from 'dayjs';
import { cacheResponse2 } from '../shared/utils/rx/cache-response-2';
import { COMPLIANCE_TYPE } from '../pages/mandatory-training/ITrainingPlan';
import { MandatoryTrainingService } from './mandatory-training.service';
import { SmartTableColumnsService, getUserTableDetails } from './smart-table-columns.service';
import { generalCompare } from '../shared/utils/array/general-compare';

export type IRecordStatus = 'Scheduled' | 'Assigned' | 'Overdue' | 'Completed' | 'Done' | 'Skipped' | 'Incomplete' | 'Discarded' | 'Removed';

export interface IOrgMTComplianceStat {
  totalNum: number;
  scheduledNum: number;
  assignedNum: number;
  unevaluatedNum: number;
  overdueNum: number;
  completedNum: number;
  doneNum: number;
  skippedNum: number;
  incompleteNum: number;
  compliantRate: number; // compliant rate in percentage int. (Done + Completed) / (Done + Completed + Overdue + Incomplete) * 100
}

export interface IListedComplianceStat extends IOrgMTComplianceStat {
  assignType: ASSIGN_TYPE;
  complianceType: COMPLIANCE_TYPE;
  practicalType: 'all' | 'practical';
}

export enum ASSIGN_TYPE {
  plan = 'Plan',
  manual = 'Manual'
}

export interface ISystemGeneratedReportsTableUserMetaData {
  extendedJobRoleAndTeamInfoUsers: any[];
  allJobRoleTakens: IJobRoleTaken[];
  allTeamIDs: string[];
}


export interface IOrgMTUserStat {
  orgUser: string;
  userID?: string;
  complianceStatus: 'Compliant' | 'Non-Compliant';
  last12mComplianceStat?: IOrgMTComplianceStat;
  last12mPracticalComplianceStat?: IOrgMTComplianceStat;
  jobRoles?: IJobRole[];
  stats: IListedComplianceStat[];
}

export interface IComplianceRecord {
  activityID?: string;
  assignDate: string;
  assignee: string;
  dueDate: string;
  earlyRecogDate: string;
  isOrgActivity?: boolean;
  lateRecogDate: string;
  mtAssignID: string;
  mtPlan: string;
  mtSchedule: string;
  orgID: string;
  originDueDate?: string;
  originLateRecogDate: string;
  providerType: string;
  resourceID?: string;
  resourceTitle?: string;
  resourceType?: string;
  status: IRecordStatus;
  resource?: any;
  resourceMin?: number;
  resourceCode?: string;

  // from new orgAssign
  assignID: string;
  assignType?;
  complianceType?;
  assigner?;
  plan?;
  resourceBrief?;
}

export interface IExtendedComplianceRecord extends IComplianceRecord {
  [key: string]: unknown;
}

export interface IComplianceImpact {
  mtAssignID: string;
  orgID: string;
  mtPlan: string;
  mtSchedule: string;
  resourceTitle: string;
  resourceMin: number;
  resourceType: string;
  resourceID: string;
  providerType: string;
  activityID: string;
  isOrgActivity: boolean;
  assignee: string;
  assignDate: string;
  dueDate: string;
  earlyRecogDate: string;
  lateRecogDate: string;
  originDueDate?: string;
  status: string;
  completeDate: string;
  utc: {
    assignDateUTC: string;
    dueDateUTC: string;
    earlyRecogDateUTC: string;
    lateRecogDateUTC: string;
    completeDateUTC: string;
  };
}

export interface ILearningRecord {
  activityID: string;
  activityType?: string;
  createDate?: string;
  createDateLocal?: string;
  createdBy?: string;
  endDateLocal?: string;
  min?: number;
  orgUserID?: string;
  staffID?: string;
  recordType?: string;
  status?: string;
  title?: string;
  userID?: string;
  resource?: any;
  assigns?: IComplianceImpact[];
  linkedAssigns?: IOrgAssign[];
}

// userID could be userID or orgUserID; Evan has no way to differentiate ids from historical records
export interface ActivityRecordGroupByUser {
  userID?: string;
  actNum: number;
  actTotalMin: number;
}

export interface ActivityRecordGroupByResource {
  resourceID: string;
  providerType: string;
  actNum: number;
  actTotalMin: number;
  resource?: any;
}

export interface ActivityRecordGroupByEvent {
  orgEventID: string;
  actNum: number;
  actTotalMin: number;
}

export interface IOrgReportQueryResource {
  resourceID: string;
  resourceType: string;
  providerType: string;
}

export interface IOrgSGReportMTAssignQueryForm {
  filteredUsers?: string[];
  filteredPlans?: string[];
  filteredStatuses?: IRecordStatus[];
  filteredResources?: IOrgReportQueryResource[];
  filteredStaffIDs?: string[];
  filteredRecordTypes?: ILearningRecordType[];
  filteredOrgEventIDs?: string[];
  isExtendedOnly?: boolean;
}

export interface IOrgSGReportFeedbackQueryForm {
  filteredUsers?: string[];
  filteredStaffIDs?: string[];
  filteredResources?: string[];
  isForAllUsers: boolean; // determine if empty filteredUsers mean all users or no users; only used in top comments section of feedback report
}

export interface IOrgMTAssignRecordGrouped {
  recordTotalNum: number;
  scheduleNum: number;
  assignedNum: number;
  overdueNum: number;
  completedNum: number;
  doneNum: number;
  skippedNum: number;
  incompleteNum: number;
  removedNum: number;
  assignDateMin?: string;
  assignDateMax?: string;
  dueDateMin?: string;
  dueDateMax?: string;
}

export interface IOrgMTAssignRecordGroupByAssignee extends IOrgMTAssignRecordGrouped {
  assigneeID: string;
  planNum: number;
}

export interface IOrgMTAssignRecordGroupByResource extends IOrgMTAssignRecordGrouped {
  resourceID: string;
  providerType?: string;
  resource?: any;
}

export interface IOrgMTAssignRecordGroupByPlan extends IOrgMTAssignRecordGrouped {
  planID: string;
  assigneeNum: number;
}

export interface IOrgMTAssignRecordGroupByJobResource extends IOrgMTAssignRecordGrouped {
  resourceID: string;
  jobRoleID: string;
}

export interface IOrgMTAssignRecordGroupByPlanResource extends IOrgMTAssignRecordGroupByPlan {
  resourceID?: string;
}

export interface IActivityRecordGroupByEndDate {
  endDate: string;
  actNum: number;
  actTotalMin: number;
}

export interface IOrgMTAssignRecordGroupByDate {
  date: string;
  totalUserNum: number;
  compliantUserNum: number;
  nonCompliantUserNum: number;
  nonCompliantUserIDs?: string[];
}


export type OvertimeLearningRecordInterval = 'Day' | 'Week' | 'Month' | 'Quarter' | 'Year';

@Injectable({
  providedIn: 'root',
  deps: [ResourceService, UserService, GroupService, OrgAssignService],
})
export class ReportLearningRecordService implements OnDestroy {
  orgID;
  private _totalRecordNum: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalRecordNum$ = this._totalRecordNum.asObservable();
  private _currentFilteredRecordNum: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentFilteredRecordNum$ = this._currentFilteredRecordNum.asObservable();
  private _totalReportedMin: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalReportedMin$ = this._totalReportedMin.asObservable();
  private _destory$ = new Subject<void>();
  standards = [];
  topics = [];
  teams = [];
  teamDict = {};
  // jobRoles = [];
  jobRoleDict = {};
  userDict = {};
  _allActiveAndInactiveOrgUsers: IOrgUser[] = [];
  private _orgUserIDDict: Dictionary<IOrgUser>;

  constructor(private http: HttpClient, private resourceService: ResourceService,
    private userService: UserService, private groupService: GroupService, private mtService: MandatoryTrainingService,
    private orgAssignService: OrgAssignService,
    private columnService: SmartTableColumnsService
  ) {
    this.orgID = localStorage.getItem('orgID');
    const guidelines$ = this.resourceService.fetchCachedGuidelines();
    const topics$ = this.resourceService.fetchAllTopics();
    const classifiedOrgUsers$ = this.userService.fetchCachedOrgUsers(this.orgID);
    const orgTeams$ = this.groupService.fetchedCacheOrgTeams();
    const orgJobRoles$ = this.groupService.fetchedCacheOrgJobRoles();
    combineLatest([guidelines$, topics$, classifiedOrgUsers$, orgTeams$, orgJobRoles$]).pipe(takeUntil(this._destory$))
      .subscribe(([guidelines, topics, classifiedOrgUsers, teams, jobRoles]) => {
        this.standards = guidelines;
        this.topics = topics;
        this._allActiveAndInactiveOrgUsers = [...classifiedOrgUsers.active, ...classifiedOrgUsers.inActive];
        this.userDict = this.userService.managedOrgUserDictionaryByUserID;
        this._orgUserIDDict = this.userService.managedOrgUserDictionaryByOrgUserID;
        this.teams = teams;
        this.teamDict = this.groupService.globalTeamsDict;
        this.jobRoleDict = this.groupService.globalJobRolesDict;
      });
  }

  ngOnDestroy(): void {
    this._destory$.next();
    this._destory$.complete();
    this._totalReportedMin.complete();
    this._totalRecordNum.complete();
    this._currentFilteredRecordNum.complete();
  }

  propagateTotalRecordNum(val = 0) {
    this._totalRecordNum.next(val);
  }

  propagateCurrentRecordNum(val = 0) {
    this._currentFilteredRecordNum.next(val);
  }

  fetchUsersInfoByIDs(startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, extraParams: HttpParams = null): Observable<ActivityRecordGroupByUser[]> {
    const baseUrl = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/learnings/byUser?`;
    let url = (startDate && endDate) ? `${baseUrl}endDate_from=${startDate}&endDate_to=${endDate}&` : baseUrl;
    url += extraParams || '';
    removeEmptyFields(queries);
    return this.http.post<ActivityRecordGroupByUser[]>(url, queries).pipe(tap((res) => {
      if (startDate && endDate) {
        const recordNum = res.reduce((acc, actByUser) => acc + actByUser.actNum, 0);
        const recordMin = res.reduce((acc, actByUser) => acc + actByUser.actTotalMin, 0);
        this._currentFilteredRecordNum.next(recordNum);
        this._totalReportedMin.next(recordMin);
      }
    }));
  }

  fetchResourceByUserIDs(startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, params: HttpParams = null): Observable<ActivityRecordGroupByResource[]> {
    if (startDate && endDate) {
      params = params || new HttpParams();
      params = params.append('endDate_from', startDate);
      params = params.append('endDate_to', endDate);
    }
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/learnings/byResource?`
      + (params || '');
    removeEmptyFields(queries);
    return this.http.post<ActivityRecordGroupByResource[]>(url, queries).pipe(tap((res) => {
      // console.log('🕊 Resource by UserIDs', res);
    }));
  }

  fetchLearningRecordsByUserIDs(startDate = null, endDate = null, queries: IOrgSGReportMTAssignQueryForm, assignParams?: HttpParams): Observable<ILearningRecord[]> {
    const baseURL = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/learnings/all`;
    const url = (startDate && endDate) ? baseURL + `?endDate_from=${startDate}&endDate_to=${endDate}&${(assignParams || '')}` : baseURL;
    return this.http.post<ILearningRecord[]>(url, { ...queries });
  }

  fetchFeedbacksByUserIDs(createDate_from, createDate_to, queries: IOrgSGReportFeedbackQueryForm): Observable<any[]> {
    const baseURL = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/feedbacks/byUser`;
    const url = (createDate_from && createDate_to) ? baseURL + `?createDate_from=${createDate_from}&createDate_to=${createDate_to}&` : baseURL;
    return this.http.post<any[]>(url, { ...queries });
  }

  fetchFeedbacksByResources(createDate_from, createDate_to, queries: IOrgSGReportFeedbackQueryForm): Observable<any[]> {
    const baseURL = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/feedbacks/byResource`;
    const url = (createDate_from && createDate_to) ? baseURL + `?createDate_from=${createDate_from}&createDate_to=${createDate_to}&` : baseURL;
    return this.http.post<any[]>(url, { ...queries });
  }

  fetchTop10ResourcesByFeedbackNum(): Observable<any[]> {
    const payload: IOrgSGReportFeedbackQueryForm = { isForAllUsers: true };
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/feedbacks/byResource?_sortBy=feedbackNum&_order=DESC&_page=1&_limit=10`;
    return this.http.post<any[]>(url, payload);
  }

  fetchComplianceRecordNumsGroupedByStatus(queryForm: IOrgSGReportMTAssignQueryForm, assignParams?: HttpParams): Observable<number> {
    let url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/count?`;
    url += (assignParams || '');
    return this.http.post<{ total: number }>(url, queryForm)
      .pipe(map(({ total = 0 }) => total));
  }

  fetchComplianceRecordNumsGroupedByStatusWithDateRange(queryForm: IOrgSGReportMTAssignQueryForm, startDate, endDate, dateType: ComplianceDateType, assignParams?: HttpParams): Observable<number> {
    let url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/count?`;
    if (startDate && endDate) {
      url += `${dateType}_from=${startDate}&${dateType}_to=${endDate}&`
    }
    url += (assignParams || '');
    return this.http.post<{ total: number }>(url, queryForm)
      .pipe(map(({ total = 0 }) => total));
  }

  fetchComplianceRecordsByUserIDs(startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams?: HttpParams): Observable<any[]> {
    removeEmptyFields(queries);
    const baseURL = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/all`;
    let url = (startDate && endDate) ? baseURL + `?${dateType}_from=${startDate}&${dateType}_to=${endDate}&` : baseURL + '?';
    url += (assignParams || '');
    return this.http.post<IOrgAssign[]>(url, { ...queries });
  }

  // get assigns with all the data needed for tables
  fetchAssignsForTable(startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams?: HttpParams) {
    return combineLatest([
      this.fetchComplianceRecordsByUserIDs(startDate, endDate, queries, dateType, assignParams),
      this.mtService.fetchedCacheMtPlans()
    ]).pipe(
      take(1),
      map(([assigns, plans]) => { return assigns.map(a => {
        const foundPlan = plans.find(({ plan }) => plan.mtPlanID === a?.plan?.planID)?.plan;

        const convertedAssigns = this.orgAssignService.convertAssign({...a, plan: foundPlan});
        const user = this.userService.managedOrgUserDictionaryByUserID[a.assignee];
        const tableUser = this.columnService.getUserTableDetails(user.orgUserID);
        return {
          ...convertedAssigns, ...tableUser, resourceDuration: convertedAssigns.resource?.min
        }
      }).sort((a, b) => generalCompare()(1, a.user?.fullName, b.user?.fullName))
      })
    )
  }

  fetchComplianceRecordsByUserIDsWithCache = cacheResponse2((startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams?: HttpParams): Observable<any[]> => {
    removeEmptyFields(queries);
    const baseURL = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/all`;
    let url = (startDate && endDate) ? baseURL + `?${dateType}_from=${startDate}&${dateType}_to=${endDate}&` : baseURL + '?';
    url += (assignParams || '');
    return this.http.post<IOrgAssign[]>(url, { ...queries });
  });

  fetchComplianceRequirementsStatsGroupByDay(startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams?: HttpParams): Observable<any[]> {
    removeEmptyFields(queries);
    const baseURL = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byDueDate`;
    let url = (startDate && endDate) ? baseURL + `?${dateType}_from=${startDate}&${dateType}_to=${endDate}&` : baseURL + '?';
    url += (assignParams || '');
    return this.http.post<IOrgAssignStatsByDay[]>(url, { ...queries });
  }

  getIndividualComplianceStatus(userID: string): Observable<'Compliant' | 'Non-Compliant'> {
    return this.http.get<IOrgMTUserStat>(`${environment.accountServiceEndpoint}/orgs/${this.orgID}/users/${userID}/mtUserStat`).pipe(map<IOrgMTUserStat, 'Compliant' | 'Non-Compliant'>(
      ({ complianceStatus = 'Compliant' }) => complianceStatus));
  }

  fetchResourcesByGuidelines(guidelineIDs: string[]): Observable<any[]> {
    const url = `${environment.resourceReadApiHost}/resources/search/all/lms/${this.orgID}?_limit=500`;
    return this.http.post<any[]>(url, { guidelines: guidelineIDs });
  }

  fetchResourcesByOrgStandards(orgStandardIDs: string[]): Observable<any[]> {
    const url = `${environment.resourceReadApiHost}/resources/search/all/lms/${this.orgID}?_limit=500`;
    return this.http.post<any[]>(url, { orgStandards: orgStandardIDs });
  }

  fetchAssignsByUserIDs(startDate, endDate, mtAssignQueries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams: HttpParams): Observable<IOrgMTAssignRecordGroupByAssignee[]> {
    removeEmptyFields(mtAssignQueries);
    if (startDate && endDate) {
      assignParams = assignParams.append(dateType + '_from', startDate);
      assignParams = assignParams.append(dateType + '_to', endDate);
    }
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byAssignee?orgID=${this.orgID}&${assignParams}`;
    return this.http.post<IOrgMTAssignRecordGroupByAssignee[]>(url, mtAssignQueries).pipe(tap((res) => {
      // console.log('🐙 mtAssignByAssignee', res);
    }));
  }

  fetchAssignsByUserIDsWithoutDateRange(mtAssignQueries: IOrgSGReportMTAssignQueryForm, assignParams: HttpParams): Observable<IOrgMTAssignRecordGroupByAssignee[]> {
    removeEmptyFields(mtAssignQueries);
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byAssignee?orgID=${this.orgID}&${assignParams}`;
    return this.http.post<IOrgMTAssignRecordGroupByAssignee[]>(url, mtAssignQueries).pipe(tap((res) => {
      // console.log('🐙 mtAssignByAssignee', res);
    }));
  }

  fetchAssignsByUserIDsGroupByResource(startDate, endDate, mtAssignQueries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams: HttpParams): Observable<IOrgMTAssignRecordGroupByResource[]> {
    removeEmptyFields(mtAssignQueries);
    if (startDate && endDate) {
      assignParams = assignParams.append(dateType + '_from', startDate);
      assignParams = assignParams.append(dateType + '_to', endDate);
    }
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byResource?orgID=${this.orgID}&${assignParams}`;
    return this.http.post<IOrgMTAssignRecordGroupByResource[]>(url, mtAssignQueries).pipe(tap((res) => {
      // console.log('👊 mtAssignByResource', res);
    }));
  }

  fetchAssignsByUserIDsGroupByResourceWithoutDate(mtAssignQueries: IOrgSGReportMTAssignQueryForm, assignParams: HttpParams): Observable<IOrgMTAssignRecordGroupByResource[]> {
    removeEmptyFields(mtAssignQueries);
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byResource?orgID=${this.orgID}&${assignParams}`;
    return this.http.post<IOrgMTAssignRecordGroupByResource[]>(url, mtAssignQueries).pipe(map((data => data.filter(d => d.resource))));
  }

  fetchAssignsByUserIDsGroupByPlan(startDate, endDate, mtAssignQueries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams: HttpParams): Observable<IOrgMTAssignRecordGroupByPlan[]> {
    removeEmptyFields(mtAssignQueries);
    if (startDate && endDate) {
      assignParams = assignParams.append(dateType + '_from', startDate);
      assignParams = assignParams.append(dateType + '_to', endDate);
    }
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byPlan?orgID=${this.orgID}&${assignParams}`;
    return this.http.post<IOrgMTAssignRecordGroupByPlan[]>(url, mtAssignQueries).pipe(
      map((res) => res.filter(r => r.planID)), // remove manaul assings which doesn't make sense by plan
      tap((res) => {
        // console.log('💋 mtAssignByPlan', res);
      }));
  }

  fetchAssignsByUserIDsGroupByPlanResource(mtAssignQueries: IOrgSGReportMTAssignQueryForm,): Observable<IOrgMTAssignRecordGroupByPlan[]> {
    removeEmptyFields(mtAssignQueries);
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byPlanResource?orgID=${this.orgID}`;
    return this.http.post<IOrgMTAssignRecordGroupByPlanResource[]>(url, mtAssignQueries).pipe(
      tap((res) => {
        // console.log('💋 mtAssignByPlan', res);
      }));
  }

  fetchAssignsByJobRoleResource(startDate, endDate, mtAssignQueries: IOrgSGReportMTAssignQueryForm, dateType: ComplianceDateType, assignParams: HttpParams): Observable<IOrgMTAssignRecordGroupByJobResource[]> {
    removeEmptyFields(mtAssignQueries);
    if (startDate && endDate) {
      assignParams = assignParams.append(dateType + '_from', startDate);
      assignParams = assignParams.append(dateType + '_to', endDate);
    }
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/assigns/byJobRoleResource?orgID=${this.orgID}&${assignParams}`;
    return this.http.post<IOrgMTAssignRecordGroupByJobResource[]>(url, mtAssignQueries).pipe(tap((res) => {
      // console.log('👊 mtAssignByResource', res);
    }));
  }

  fetchLearningRecordsOvertime(startDate, endDate, queries: IOrgSGReportMTAssignQueryForm, interval: OvertimeLearningRecordInterval): Observable<IActivityRecordGroupByEndDate[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/learnings/overtime?endDate_from=${startDate}&endDate_to=${endDate}&interval=${interval}`;
    removeEmptyFields(queries);
    return this.http.post<IActivityRecordGroupByEndDate[]>(url, queries).pipe(tap((res) => {
      // console.log('👁overtime', res);
    }));
  }

  // unused
  fetchComplianceRecordOvertime(startDate, endDate, queries: { filteredUsers: string[]; filteredPlans?: string[] }, interval: OvertimeLearningRecordInterval, dateType: ComplianceDateType): Observable<IOrgMTAssignRecordGroupByDate[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/sgReports/mtAssigns/overtime?${dateType}_from=${startDate}&${dateType}_to=${endDate}&interval=${interval}`;
    return this.http.post<IOrgMTAssignRecordGroupByDate[]>(url, queries).pipe(tap(res => {
      // console.log('👁compliance overtime', res);
    }));
  }

  fetchAllNonCompliantAssignees(): Observable<IOrgMTUserStat[]> {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/mtUserStats`;
    return this.http.get<IOrgMTUserStat[]>(url);
  }


  extendResourceInfo(recordByResource: ActivityRecordGroupByResource[]): any[] {
    return recordByResource.map((rawRecord) => {
      const resource = rawRecord.resource;

      if (resource) {
        const {
          title = '',
          min = 0,
          activityType,
          expiryDate,
          availableDate,
          topics: resourceTopics = [],
          guidelines = [],
          code = ''
        } = resource;
        const resourceCode = code ? code : (rawRecord?.resourceID?.substring(0, 8) || '');
        const resourceType = this.resourceService.resourceDict[activityType] || '';
        const linkedStandards = sortBy(guidelines.map((standardID) => {
          const guide = this.standards.find((standard) => standardID === standard.guidelineID);
          return (guide && guide.name) || '';
        }));
        const topics = resourceTopics.map((topicID) => {
          const topic = this.topics.find((fetchedTopic) => topicID === fetchedTopic.topicID);
          return (topic && topic.name) || '';
        });
        const avgReportedTime = rawRecord.actNum ? Math.ceil(rawRecord.actTotalMin / rawRecord.actNum) : 0;
        if (resource?.status === 'Updating' || resource?.status === 'Commence Review') {
          resource.updating = true;
        } else if (resource?.status === 'Published' && (resource?.expiryDate && new Date(resource?.expiryDate) < new Date())) {
          resource.expired = true;
        } else if (resource?.status !== 'Published') {
          resource.unavailable = true;
        }
        return {
          ...rawRecord,
          resourceCode,
          avgReportedTime,
          min,
          resourceType,
          expiryDate,
          availableDate,
          linkedStandards,
          title,
          topics,
          resourceID: rawRecord.resourceID,
          resource
        };
      }
      return { ...rawRecord };
    });
  }

  // Add teams, jobroles, workStartDate,Activate On
  addTeamAndJobRoleAndLocationsInfoToUsersHavingRecords(activityRecordsByUser: ActivityRecordGroupByUser[]): ISystemGeneratedReportsTableUserMetaData {
    const extendedJobRoleAndTeamInfoUsers = [];
    const result = activityRecordsByUser.reduce((acc, current) => {
      const validUser = this.userDict[current.userID] || this._orgUserIDDict[current.userID];
      const foundOrgUser = { ...validUser, openNewTab: true };
      const { teams = [], activeJobRoleTakens = [], status, email, workStartDate, staffID } = foundOrgUser;
      const teamNames = teams.map(teamId => this.teamDict[teamId]).filter(Boolean);
      const activeJobRoleIDs = activeJobRoleTakens.map(({ jobRole }) => jobRole);
      const jobRoleTitles = activeJobRoleIDs.map((id) => this.jobRoleDict[id]);
      extendedJobRoleAndTeamInfoUsers.push({
        ...current,
        teamNames,
        status,
        orgUser: foundOrgUser,
        jobRoleTitles,
        email,
        workStartDate: foundOrgUser.profile?.startDate || foundOrgUser.workStartDate || '',
        activatedDate: foundOrgUser.startDate ?? '',
        teamIDs: teams,
        facilityModels: foundOrgUser.facilityModels,
        activeJobRoleIDs,
        activeJobRoleTakens,
        staffID
      });
      const { allTeamIDs, allJobRoleTakens } = acc;
      return { allTeamIDs: [...allTeamIDs, ...teams], allJobRoleTakens: [...allJobRoleTakens, ...activeJobRoleTakens] };
    }, { allTeamIDs: [], allJobRoleTakens: [] });
    return { ...result, extendedJobRoleAndTeamInfoUsers };
  }

  generateJobRoleTableData(selectedJobRoles: string[], appendRankInfoUsers: any[], filteredOrgUsers: IOrgUser[] = []) {
    return uniq(selectedJobRoles).map((selectedJobRoleID) => {
      const jobRole = this.jobRoleDict[selectedJobRoleID];
      const totalUserNum = filteredOrgUsers.filter(({ activeJobRoleTakens = [] }) => {
        const userActiveJobRoleIDs = activeJobRoleTakens.map(jt => jt.jobRole);
        return userActiveJobRoleIDs.includes(selectedJobRoleID);
      }).length;
      const [reportedTimeSpent, recordNum] = appendRankInfoUsers.reduce((acc, user) => {
        const { activeJobRoleIDs } = user;
        if (activeJobRoleIDs.includes(selectedJobRoleID)) {
          const [prevReportedTimeSpent, prevRecordNum] = acc;
          return [prevReportedTimeSpent + user.actTotalMin, prevRecordNum + user.actNum];
        }
        return acc;
      }, [0, 0]);
      const averageTimeSpent = recordNum ? Math.ceil(reportedTimeSpent / recordNum) : 0;
      return { ...jobRole, totalUserNum, averageTimeSpent, reportedTimeSpent, recordNum };
    });
  }

  aggregateTeamTableData(allTeamIDs: string[], appendRankInfoUsers: any[], filteredOrgUsers: IOrgUser[]) {
    return chain(allTeamIDs).uniq().map((uniqTeamID) => {
      const uniqTeam = this.teamDict[uniqTeamID];
      const [reportedTimeSpent, recordNum] = appendRankInfoUsers.reduce((acc, userWithRankInfo) => {
        const { teamIDs: userTeamIDs } = userWithRankInfo;
        if (userTeamIDs.includes(uniqTeamID)) {
          const [prevReportedTimeSpent, prevRecordNum] = acc;
          return [prevReportedTimeSpent + userWithRankInfo.actTotalMin, prevRecordNum + userWithRankInfo.actNum];
        }
        return acc;
      }, [0, 0]);
      const totalUserNum = filteredOrgUsers.filter(({ teams = [] }) => teams.includes(uniqTeamID)).length;
      const averageTimeSpent = recordNum ? Math.ceil(reportedTimeSpent / recordNum) : 0;
      const teamManagers = this.userService.classifiedOrgUsers.getValue().all.reduce((acc, { managedTeams = [], fullName }) => {
        if (managedTeams.includes(uniqTeamID)) {
          acc.push(fullName);
        }
        return acc;
      }, []);
      return { ...uniqTeam, totalUserNum, averageTimeSpent, reportedTimeSpent, recordNum, teamManagers };
    }).value();
  }

  // do not use anymore; it will be used in the future if PMs change their mind
  appendNoLearningRecordResources(selectedResources: any[] = [], recordByResource: ActivityRecordGroupByResource[] = []): ActivityRecordGroupByResource[] {
    const hasSelectedResources = selectedResources.length;
    if (hasSelectedResources) {
      const havingRecordsResourceIDs = recordByResource.map(({
        resourceID,
        resource
      }) => resourceID || resource.resourceID || '');
      const noRecordResourceData = selectedResources.filter(({ resourceID = '' }) => !havingRecordsResourceIDs.includes(resourceID))
        .map((noRecordResource) => ({
          resourceID: noRecordResource.resourceID,
          providerType: noRecordResource.providerType || 'Ausmed',
          actNum: 0,
          actTotalMin: 0,
          resource: noRecordResource
        }));
      return [...noRecordResourceData, ...recordByResource];
    } else {
      return [...recordByResource];
    }
  }

  propagateDeletion(deletedMin = 0, isDecrementRecords = false) {
    // this._deleted.next(deletedRecord);
    this._totalReportedMin.next(this._totalReportedMin.getValue() - (deletedMin || 0));
    if (isDecrementRecords) {
      this._currentFilteredRecordNum.next(this._currentFilteredRecordNum.getValue() - 1);
      this._totalRecordNum.next(this._totalRecordNum.getValue() - 1);
    }
    // this._totalReportedMin.
  }

  // for mapping results of this.fetchComplianceREcords
  extendComplianceRecords(complianceRecords: IComplianceRecord[], mtPlans): IExtendedComplianceRecord[] {
    return complianceRecords.map<IExtendedComplianceRecord>((record) => {
      const foundPlan = mtPlans.find(({ plan }) => plan.mtPlanID === record?.plan?.planID || null)?.plan || {};
      const user = this.userDict[record.assignee];
      const { email = '', teams: teamIDs = [], activeJobRoleTakens = [], staffID = '', status: accountStatus = '' } = user;
      const { topics: resourceTopics = [], guidelines = [] } = record.resource || {};
      const linkedStandards = sortBy(guidelines.map((standardID) => {
        const guide = this.standards.find((standard) => standardID === standard.guidelineID);
        return (guide && guide.name) || '';
      }));
      const topics = resourceTopics.map((topicID) => {
        const topic = this.topics.find((fetchedTopic) => topicID === fetchedTopic.topicID);
        return (topic && topic.name) || '';
      });
      const teams = teamIDs.map(teamID => {
        const noAccess = !this.groupService.managedTeamsMapByTeamID[teamID];
        return { ...this.teamDict[teamID], noAccess };
      });
      const jobRoles = activeJobRoleTakens.filter(jt => jt.jobRole && jt.jobRoleModel).map(jt => this.jobRoleDict[jt.jobRole]);
      const mappedResourceType = this.resourceService.resourceDict[record?.resource?.resourceType || record?.resource?.activityType || record?.resourceBrief?.resourceType];
      const resource = record?.resource || {
        resourceID: record?.resourceBrief?.resourceID,
        resourceType: mappedResourceType || record?.resourceBrief?.resourceType,
        title: record?.resourceBrief?.resourceTitle,
        min: record?.resourceBrief?.resourceMin,
        deleted: true
      };
      if (resource?.status === 'Updating' || resource?.status === 'Commence Review') {
        resource.updating = true;
      } else if (resource?.status === 'Published' && (resource?.expiryDate && new Date(resource?.expiryDate) < new Date())) {
        resource.expired = true;
      } else if (resource?.status !== 'Published') {
        resource.unavailable = true;
      }
      const { originDueDate: recordOriginDueDate, dueDate } = record;
      const originDueDate = recordOriginDueDate || dueDate;
      const daysExtended = getDaysExtended(record);
      // resource type column
      const resourceObj = { ...record.resource };
      const extendRecord = {
        ...record,
        staffID,
        accountStatus,
        // mtPlanCell: this.smartTableColumnsService.getPlanCell(foundPlan, isEmpty(foundPlan) ? true : false, record),
        user,
        teams,
        resource,
        originDueDate,
        daysExtended,
        jobRoles,
        topics,
        linkedStandards,
        email,
        orgMobile: user?.orgMobile,
        resourceObj,
        resourceType: resource.resourceType,
        resourceCode: record?.resource?.code ? record.resource.code : ((record?.resource?.resourceID || record?.resourceBrief?.resourceID)?.substring(0, 8) || ''),
        resourceDuration: record?.resource?.min || record?.resourceBrief?.min || 0
      };
      const key = record.status === 'Overdue' || record.status === 'Incomplete' ? 'hasOverdueDays' : 'default';

      const addDaysOverdueByStatus: Record<'hasOverdueDays' | 'default', (r: IExtendedComplianceRecord) => IExtendedComplianceRecord | IExtendedComplianceRecord & { daysOverdue: number }> = {
        'hasOverdueDays': r => {
          const now = dayjs();
          const due = dayjs(r.dueDate);
          const daysOverdue = now.diff(due, 'day');
          return { ...r, daysOverdue };
        },
        'default': r => r,
      };

      return addDaysOverdueByStatus[key](extendRecord);
    });
  }


}

// gets user's missed/overdue nums, whether compliance only or mandatory + compliance
export function getUserAssignNum(userStat: IOrgMTUserStat, attr: 'incompleteNum' | 'overdueNum', complianceOnly = false) {
  if (!userStat.stats) {
    return 0;
  }
  return userStat.stats.filter(s => s.practicalType === 'all')
    .filter(s => complianceOnly ? s.complianceType === COMPLIANCE_TYPE.compliance : true)
    .reduce((p, c) => p + (c[attr] as number) || 0, 0)
}

export function getResourceQueries(selectedResources: any[]) {
  return selectedResources.map<IOrgReportQueryResource>(selectResource => ({
    resourceID: selectResource.resourceID,
    resourceType: selectResource.activityType || 'e-learning',
    providerType: selectResource.providerType || '',
  }));
}
