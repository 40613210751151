import { Component } from '@angular/core';

import { EditCellDefault } from './edit-cell-default';
import { Cell } from '../../../lib/data-set/cell';
import { InputEditorComponent } from '../cell-editors/input-editor.component';
import { CompleterEditorComponent } from '../cell-editors/completer-editor.component';
import { CheckboxEditorComponent } from '../cell-editors/checkbox-editor.component';
import { TextareaEditorComponent } from '../cell-editors/textarea-editor.component';
import { SelectEditorComponent } from '../cell-editors/select-editor.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'table-cell-default-editor',
    templateUrl: './default-edit.component.html',
    standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        SelectEditorComponent,
        TextareaEditorComponent,
        CheckboxEditorComponent,
        CompleterEditorComponent,
        NgSwitchDefault,
        InputEditorComponent,
    ],
})
export class DefaultEditComponent extends EditCellDefault {

  constructor() {
    super();
  }

  getEditorType(): string {
    return this.cell.getColumn().editor && this.cell.getColumn().editor.type;
  }
}
