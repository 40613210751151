import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, of, throwError } from 'rxjs';
import { catchError, filter, share, switchMap, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from '../services/error-handling.service';
import { BroadcastService } from './broadcast.service';
import { Dictionary, groupBy as _groupBy, keyBy as _keyBy } from 'lodash';
import * as dayjs from 'dayjs';


@Injectable({
  providedIn: 'root'
})
/**
 * service for passport feature
 */
export class PassportService {
  PASSPORT_DEFAULT_EARLY_RECOGDURATION = 365; // Passport's Early RecogDuration = 365 - Assign duration
  passports: PassportType[];
  passportDictByID: Dictionary<PassportType>;
  passportModulesDictByID: Dictionary<Dictionary<any>>;
  orgID = localStorage.getItem('orgID');
  private pendingOrgPassport: boolean;

  constructor(
    private http: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private broadcastService: BroadcastService
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      // this.docs.next(null);
      // this.publishedDocs.next(null);
      // this.policies.next(null);
    });
  }

  fetchPassports(): Observable<PassportType[]> {
    const url = `${environment.resourceReadApiHost}/passports`;
    return this.http.get(url).pipe(
      tap((passports: PassportType[]) => {
        this.passports = passports;
        this.preprocess(passports);
      }),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getCachedPassports(): Observable<PassportType[]> {
    if (!this.pendingOrgPassport && !this.passports) {
      this.pendingOrgPassport = true;
      const fetch$: Observable<any> = this.fetchPassports().pipe(
        tap(() => this.pendingOrgPassport = false),
        catchError((e) => {
          this.pendingOrgPassport = false;
          return e;
        }),
        share(),
      );
      return fetch$;
    }
    return of(this.passports).pipe(
      filter(p => !!p),
      catchError(error => this.errorHandlingService.handleHttpError(error))
    );
  }

  preprocess(passports: PassportType[]) {
    this.passportDictByID = _keyBy(passports, (p) => (p.passportID));
    const passportModules: any = _keyBy(passports, (p) => (p.passportID));
    Object.keys(passportModules).forEach(resourceID => {
      passportModules[resourceID] = _keyBy(passportModules[resourceID].passportModules, (r) => (r.resourceID));
    });
    this.passportModulesDictByID = passportModules;
  }

  getPassportDictByID() {
    if (this.passportDictByID) {
      return this.passportDictByID;
    } else {
      console.error('Please fetch the passport data first!');
    }
  }

  getPassportModulesDictByID() {
    if (this.passports) {
      return this.passportModulesDictByID;
    } else {
      console.error('Please fetch the passport data first!');
    }
  }

  getPassportByResourceID(resourceID: string): PassportType | null {
    for (const passport of this.passports) {
      for (const module of passport.passportModules) {
        if (module.resourceID === resourceID) {
          return passport;
        }
      }
    }
    return null;
  }

  isPassportResource(resourceID: string): boolean {
    for (const passportID in this.passportModulesDictByID) {
      if (this.passportModulesDictByID[passportID][resourceID]) {
        return true;
      }
    }
    return false;
  }

  //  simplified B2B get user passport enrolment API, just used in the Passports card of Compliance tab of user transcript page
  getPassportStatusAndB2CCertificateUrlCode(userID: string) {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/users/${userID}/passportEnrolments`;
    return this.http.get(url).pipe(
      tap((res: PassportEnrolmentsType[]) => { }),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  getEnrolmentWithCompetencies(userID: string) {
    const url = `${environment.accountServiceEndpoint}/orgs/${this.orgID}/users/${userID}/passportEnrolmentWithCompetencies`;
    return this.http.get<{ enrolment, competencies }[]>(url).pipe(
      // tap((res: any[]) => {console.log(res)}),
      catchError(this.errorHandlingService.handleHttpError),
    );
  }

  calculatePassportRecogDuration(assignDuration: string) {
    const assignDurationNum = +assignDuration.split(' ')[0];
    const assignDurationUnit = assignDuration.split(' ')[1][0]; // D, W, Y
    let assignDurationByDay;
    switch (assignDurationUnit) {
      case 'D': { assignDurationByDay = assignDurationNum; break; };
      case 'W': { assignDurationByDay = assignDurationNum * 7; break; };
      case 'Y': { assignDurationByDay = assignDurationNum * 30; break; };
      default: console.error('Wrong time unit!')
    }
    assignDurationByDay = this.PASSPORT_DEFAULT_EARLY_RECOGDURATION - assignDurationByDay;
    return (assignDurationByDay > 0 ? assignDurationByDay : 0) + ' Days';
  }

   generateB2BPassportStatus(passportEnrolment: PassportEnrolmentsType): B2B_PASSPORTS_STATUS {
    if (!passportEnrolment.expiryLocalDate) {
      if (passportEnrolment.competencies?.length) {
        return B2B_PASSPORTS_STATUS.inProgress;
      }
      return B2B_PASSPORTS_STATUS.notStarted;
    } else {
      if (dayjs(passportEnrolment.expiryLocalDate) >= dayjs()) {
        return B2B_PASSPORTS_STATUS.valid;
      } else {
        return B2B_PASSPORTS_STATUS.expired;
      }
    }
  }
}

export interface PassportType {
  passportID: string;
  name: string;
  description?: string;
  passportModules: PassportModulesType[];
  headline: string;
  detail: PassportDetailType;
  alias: string;
}

export interface PassportModulesType {
  moduleID: string;
  passportID: string;
  resourceID: string;
  providerType?: string;
  order: number;
  competencyDuration: string;
  resource: any; // need to give it a specific type later
}

export interface PassportDetailType {
  video?: string;
  faq?: PassportDetailFaqType[];
}

export interface PassportDetailFaqType {
  answer: string;
  question: string;
}
export interface PassportEnrolmentsType {
  enrolmentID?: string;
  passportID?: string;
  userID?: string;
  status?: B2C_PASSPORTS_STATUS;
  alias?: string;
  enrolmentLocalDate?: string;
  apsirationLocalDate?: string;
  unenrolmentLocalDate?: string;
  declinedLocalDate?: string;
  completionLocalDate?: string;
  expiryLocalDate: string;
  competencies: any[];
}

export enum B2C_PASSPORTS_STATUS {
  enrolled = 'Enrolled',
  unenrolled = 'Unenrolled',
  declined = 'Declined',
  suspended = 'Suspended',
}

export enum B2B_PASSPORTS_STATUS {
  valid = 'Valid',
  inProgress = 'In Progress',
  expired = 'Expired',
  notStarted = 'Not Started'
}

export enum PASSPORT_COMPETENCY_STATUS_API {
  developing = 'Developing',
  obtained = 'Obtained',
}

export enum PASSPORT_COMPETENCY_STATUS {
  Developing = 'In Progress',
  Obtained = 'Completed',
}






