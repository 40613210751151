import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorHandlingService } from './error-handling.service';

const URLReportNameMap = {
  'time-spent-learning': 'timeSpentLearning',
  'all-learning-records': 'allLearningRecords',
  'optional': 'optionalRequirements',
  'compliance-report': 'complianceReport',
  'standards-report': 'reportByStandards',
  'learning-analytics': 'learningAnalytics'
};
@Injectable({
  providedIn: 'root'
})
export class EvidenceService {
  orgID = localStorage.getItem('orgID');
  recordsDataRefresh = new Subject<string | false>();

  constructor(
    private httpClient: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private router: Router,
  ) {
  }

  addEvidenceToOrgActivity(orgActivityID: string, userID: string, payload: EvidencePayloadType): Observable<any> {
    return this.httpClient
      .post(`${environment.accountServiceEndpoint}/orgs/${this.orgID}/users/${userID}/orgActivities/${orgActivityID}/notes`, payload)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  fetchEvidence(orgActivityID: string, userID: string): Observable<any> {
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${this.orgID}/users/${userID}/orgActivities/${orgActivityID}`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  deleteEvidence(orgActivityID: string, userID: string, noteID: string): Observable<any> {
    return this.httpClient
      .delete(`${environment.accountServiceEndpoint}/orgs/${this.orgID}/users/${userID}/orgActivities/${orgActivityID}/notes/${noteID}`)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  getEventReportName() {
    const url = this.router.url;
    for (const i in URLReportNameMap) {
      if (url.match(`${i}`)) {
        return URLReportNameMap[i];
      }
    }
  }
}

export interface EvidenceType {
  addedBy: string,
  addedDate: string,
  noteID: string,
  note?: string,
  links?: EvidenceWeblinkType,
}

export interface EvidenceWeblinkType {
  title?: string,
  link?: string,
}

export interface EvidencePayloadType {
  note?: string,
  links?: EvidenceWeblinkType[],
}
