import { Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CustomisedMultiSelectForGuidelineslComponent } from '@components/customised-multi-select-for-guidelines/customised-multi-select-for-guidelines.component';
import { map } from 'rxjs/operators';
import { ResourceService } from 'src/app/services/resource.service';

@Component({
  selector: 'app-selector-regulatory-standards',
  standalone: true,
  imports: [CustomisedMultiSelectForGuidelineslComponent, ReactiveFormsModule],
  templateUrl: './selector-regulatory-standards.component.html',
  styleUrl: './selector-regulatory-standards.component.scss'
})
export class SelectorRegulatoryStandardsComponent {
  control = input.required<FormControl>();

  resourceService = inject(ResourceService);

  guidelines = toSignal(this.resourceService.fetchCachedGuidelines().pipe(
    map((allGuidelines) => this.resourceService.getEnabledGuidelines(allGuidelines))
  ))

}
