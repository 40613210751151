import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerButtonService {
  private _statusSubject = new BehaviorSubject<SPINNER_BUTTON_STATUS_ENUM>(undefined);
  statusUpdate$ = this._statusSubject.asObservable();

  constructor() {
  }

  setStatus(newStatus: SPINNER_BUTTON_STATUS_ENUM) {
    this._statusSubject.next(newStatus);
  }

  resetStatus() {
    this._statusSubject.next(undefined);
  }


}

export enum SPINNER_BUTTON_STATUS_ENUM {
  before = 'before',
  processing = 'processing',
  after = 'after',
  reset = 'reset',
  resetToDefault = 'resetToDefault',
}
