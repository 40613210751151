import { IFeatureConfig } from '../interfaces/feature-config';
import { PRODUCT_TYPES } from './guards/mode.constants';

export enum FEATURES {
  hippotlianAnalytics = 'hippotlianAnalytics',
  thirdPartyProviders = 'thirdPartyProviders',
  learningPortal = 'learningPortal',
  dashboard = 'dashboard',
  courseBuilder = 'courseBuilder',
  formBuilder = 'formBuilder',
  practicalActivity = 'practicalActivity',
  requireUserID = 'requireUserID',
  manualEntryRecord = 'manualEntryRecord',
  MT = 'MT',
  compliance = 'compliance',
  addButton = 'addButton',
  addToSchedule = 'addToSchedule',
  hippotlianMetrics = 'hippotlianMetrics',
  assign = 'assign',
  addRecords = 'addRecords',
  addTeam = 'addTeam',
  addJobRole = 'addJobRole',
  ausmedLibrary = 'ausmedLibrary',
  byoLibrary = 'byoLibrary',
  settings = 'settings',
  staff = 'staff',
  jobRole = 'jobRole',
  team = 'team',
  suggestedByStaff = 'suggestedByStaff',
  resourceAccreditations = 'Accreditations',
  globalSearch = 'globalSearch',
  displayInContentLibraryToggle = 'displayInContentLibraryToggle',
  hasOrgResourceKeywords = 'hasOrgResourceKeywords', // use keywords for org resources for content partner resources
  // scorm features
  scormSchedules = 'scormSchedules',
  scormAnalytics = ' scormAnalytics',
  staffWork = 'staffWork', // show workType and work startDate in add staff flow.
  scormCloud = 'scormCloud',
  stripeBilling = 'stripeBilling',
  partnerContent = 'partnerContent', // has resources from other orgs
  orgUserProfileIntegration = 'orgUserProfileIntegration',
  policyAndProcedure = 'policyAndProcedure',
  disallowTeamLeaderAddUser = 'disallowTeamLeaderAddUser', // disallow TM to add user (just for MEDIBANK org)
  allowAddPassport = 'allowAddPassport', // allow OM to add passport
  requireSetupBilling = 'requireSetupBilling', // require setup payment method before invite users
  limitedResourceAccess = 'limitedResourceAccess', // org has bundles or purchased resources in the contract
  bookingSystem = 'bookingSystem',
  disallowTMDeactiveUser = 'disallowTMDeactiveUser',
  performance = 'performance',
  analyticsReport = 'analyticsReport',
  competencyLibrary = 'competencyLibrary',
  disallowChangeTPName = 'disallowChangeTPName', // for auto-enrolment orgs who use the name to match the enrolment

}

export const featureConfigs: { [type: string]: IFeatureConfig } = {
  [PRODUCT_TYPES.HIPPOTLIAN]: {
    name: PRODUCT_TYPES.HIPPOTLIAN,
    features: {
      [FEATURES.dashboard]: true,
      [FEATURES.addButton]: true,
      [FEATURES.MT]: false,
      [FEATURES.compliance]: false,
      [FEATURES.hippotlianMetrics]: true,
      [FEATURES.hippotlianAnalytics]: true,
      [FEATURES.assign]: false,
      [FEATURES.addRecords]: false,
      [FEATURES.ausmedLibrary]: false,
      [FEATURES.byoLibrary]: true,
      [FEATURES.settings]: true,
      [FEATURES.addTeam]: true,
      [FEATURES.staff]: true,
      [FEATURES.jobRole]: true,
      [FEATURES.team]: true,
      [FEATURES.suggestedByStaff]: false,
      [FEATURES.staffWork]: false,
      [FEATURES.scormCloud]: false,
      [FEATURES.resourceAccreditations]: true,
      [FEATURES.hasOrgResourceKeywords]: false,
      [FEATURES.policyAndProcedure]: false, // will check and update in initialize.service,
      [FEATURES.performance]: false,
      [FEATURES.analyticsReport]: false,
      [FEATURES.competencyLibrary]: false
    },
  },
  [PRODUCT_TYPES.LMS]: {
    name: PRODUCT_TYPES.LMS,
    features: {
      [FEATURES.learningPortal]: true,
      [FEATURES.thirdPartyProviders]: true,
      [FEATURES.manualEntryRecord]: true,  // default: true; will check and update in initialize.service
      [FEATURES.practicalActivity]: false,  // default: false; will check and update in initialize.service
      [FEATURES.courseBuilder]: false, // will check and update in initialize.service
      [FEATURES.requireUserID]: false, // will check and update in initialize.service
      [FEATURES.dashboard]: true,
      [FEATURES.displayInContentLibraryToggle]: true,
      [FEATURES.addButton]: true,
      [FEATURES.MT]: false, // will check enableMT and update by contract in initialize.service
      [FEATURES.compliance]: true,
      [FEATURES.assign]: true,
      [FEATURES.addRecords]: true,
      [FEATURES.ausmedLibrary]: true,
      [FEATURES.byoLibrary]: true,
      [FEATURES.settings]: true,
      [FEATURES.addTeam]: true,
      [FEATURES.addJobRole]: true,
      [FEATURES.staff]: true,
      [FEATURES.jobRole]: true,
      [FEATURES.team]: true,
      [FEATURES.suggestedByStaff]: true,
      [FEATURES.staffWork]: true,
      [FEATURES.scormCloud]: true,
      [FEATURES.globalSearch]: true,
      [FEATURES.partnerContent]: false,
      [FEATURES.orgUserProfileIntegration]: false,
      [FEATURES.hasOrgResourceKeywords]: false,
      [FEATURES.policyAndProcedure]: false, // will check and update in initialize.service
      [FEATURES.disallowTeamLeaderAddUser]: false,
      [FEATURES.requireSetupBilling]: false,
      [FEATURES.bookingSystem]: false,
      [FEATURES.disallowTMDeactiveUser]: false,
      [FEATURES.performance]: false,
      [FEATURES.analyticsReport]: false,
      [FEATURES.competencyLibrary]: false
    },
  },
  [PRODUCT_TYPES.LMS_FREE]: {
    name: PRODUCT_TYPES.LMS_FREE,
    features: {
      [FEATURES.learningPortal]: true,
      [FEATURES.thirdPartyProviders]: false,
      [FEATURES.manualEntryRecord]: true,  // default: true; will check and update in initialize.service
      [FEATURES.practicalActivity]: false,  // default: false; will check and update in initialize.service
      [FEATURES.courseBuilder]: false, // will check and update in initialize.service
      [FEATURES.requireUserID]: false, // will check and update in initialize.service
      [FEATURES.ausmedLibrary]: true,
      [FEATURES.dashboard]: true,
      [FEATURES.addButton]: true,
      [FEATURES.displayInContentLibraryToggle]: true,
      [FEATURES.MT]: false, // will check enableMT and update by contract in initialize.service
      [FEATURES.compliance]: true,
      [FEATURES.assign]: true,
      [FEATURES.addRecords]: true,
      [FEATURES.byoLibrary]: true,
      [FEATURES.settings]: true,
      [FEATURES.addTeam]: true,
      [FEATURES.addJobRole]: true,
      [FEATURES.staff]: true,
      [FEATURES.jobRole]: true,
      [FEATURES.team]: true,
      [FEATURES.suggestedByStaff]: true,
      [FEATURES.staffWork]: true,
      [FEATURES.scormCloud]: true,
      [FEATURES.globalSearch]: true,
      [FEATURES.partnerContent]: false,
      [FEATURES.orgUserProfileIntegration]: false,
      [FEATURES.hasOrgResourceKeywords]: false,
      [FEATURES.requireSetupBilling]: false,
      [FEATURES.limitedResourceAccess]: false,
      [FEATURES.policyAndProcedure]: false, // will check and update in initialize.service
      [FEATURES.bookingSystem]: false,
      [FEATURES.performance]: false,
      [FEATURES.analyticsReport]: false,
      [FEATURES.competencyLibrary]: false

    },
  },
  [PRODUCT_TYPES.LIBRARY]: {
    name: PRODUCT_TYPES.LIBRARY,
    features: {
      [FEATURES.learningPortal]: true,
      [FEATURES.scormAnalytics]: true,
      [FEATURES.scormSchedules]: true,
      [FEATURES.ausmedLibrary]: true,
      [FEATURES.settings]: true,
      [FEATURES.compliance]: true,
      [FEATURES.addToSchedule]: true,
      [FEATURES.dashboard]: true,
      [FEATURES.MT]: false,
      [FEATURES.policyAndProcedure]: false, // will check and update in initialize.service,
      [FEATURES.performance]: false,
      [FEATURES.analyticsReport]: false,
      [FEATURES.competencyLibrary]: false
    },
  },
  [PRODUCT_TYPES.CONTENT_PARTNER]: {
    name: PRODUCT_TYPES.CONTENT_PARTNER,
    features: {
      [FEATURES.courseBuilder]: true,
      [FEATURES.settings]: true,
      [FEATURES.addTeam]: true,
      [FEATURES.addJobRole]: true,
      [FEATURES.staff]: true,
      [FEATURES.globalSearch]: true,
      [FEATURES.byoLibrary]: true,
      [FEATURES.compliance]: true,
      [FEATURES.learningPortal]: true,
      [FEATURES.jobRole]: false,
      [FEATURES.team]: false,
      [FEATURES.hasOrgResourceKeywords]: true,
      [FEATURES.partnerContent]: true,
      [FEATURES.MT]: false,
      [FEATURES.policyAndProcedure]: false, // will check and update in initialize.service
      [FEATURES.performance]: false,
      [FEATURES.analyticsReport]: false,
      [FEATURES.competencyLibrary]: false
    },
  }
};

