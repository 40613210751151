<div class="card p-4 children-horizontal-gap-4">
    <div class="details">
        <div class="detail-item">
            <p [class]="labelClass">Status</p>
            <app-assign-status-cell [value]="task().status | complianceStatusPipe"></app-assign-status-cell>
        </div>
        <div class="detail-item">
            <p [class]="labelClass">User</p>
            <!-- <app-side-staff-display [user]="task().user"></app-side-staff-display> -->
             <p [class]="valueClass">{{task().user?.fullName}}</p>
        </div>
        @if(task().taskManager) {
            <div class="detail-item">
                <p [class]="labelClass">Manager</p>
                <!-- <app-side-staff-display [user]="task().taskManager"></app-side-staff-display> -->
                <p [class]="valueClass">{{task().taskManager?.fullName}}</p>
            </div>
        }

        <div class="detail-item">
            <p [class]="labelClass">Cycle</p>
            <!-- <div class="d-flex justify-content-between"> -->
                <!-- <app-avatar-requirements [iconType]="'loading-3'" class="me-1 d-inline-flex"></app-avatar-requirements> -->
                <a
                [routerLink]="['/pages/performance/reviews/cycles/', task().cycle.cycleConfigID]"
                [class]="valueClass">
                  {{task().cycle.title}}
                </a>          
    
            <!-- </div> -->
    
        </div>
        <div class="detail-item">
            <p [class]="labelClass">Open Date</p>
            <p class="fw-semibold f-small">{{task().openDate | localDate}}</p>
        </div>
        <div class="detail-item">
            <p [class]="labelClass">Due Date</p>
            <p class="fw-semibold f-small">{{task().dueDate | localDate}}</p>
        </div>
        @if(task().completeDate) {
            <div class="detail-item">
                <p [class]="labelClass">Completed Date</p>
                <p class="fw-semibold f-small">{{task().completeDate | localDate}}</p>
            </div>
        }
    </div>
</div>