import { inject, Injectable } from '@angular/core';
import { HierarchyService } from '../admin/hierarchy/hierarchy.service';
import { UserService } from 'src/app/services/user.service';
import { SpecialTeamService } from 'src/app/services/special-team.service';
import { PERF_REVIEW_TEAM_TYPE } from 'src/app/services/service-types/special-team-types';
import { keys } from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class PerfReviewPermissionsService {
  hierarchyService = inject(HierarchyService);
  userService = inject(UserService);
  specialTeamService = inject(SpecialTeamService)

  constructor() { }

  hasAllUserAccess() {
    return this.userService.plainOrgUser.isManager && this.getRole() !== 'Administrator' && this.getRole() !== 'Viewer';
  }

  hasAdminAccess() {
    return this.userService.plainOrgUser.isManager && this.getRole() !== 'Viewer';
  }

  accessibleUsersSet() {
    return new Set(this.accessibleUsers())
  }

  getFilterOrgUsers() {
    return this.hasAllUserAccess() ? null : this.accessibleUsers()

  }

  accessibleUsers() {
    // if (this.hasAdminAccess()) {
    //   return keys(this.userService.managedOrgUserDictionaryByOrgUserID);
    // }
    return this.hierarchyService.getMyDirectReportIDs()
    .concat(this.getMyExtraMembers());
  }

  getMyExtraMembers() {
    const specialTeam = this.userService.orgUser.value.orgUserDetail.managedTeamModels?.find(t => t.teamType === PERF_REVIEW_TEAM_TYPE)
    if (specialTeam) {
      // console.log(this.userService.userList?.filter(u => u.hierarchyTeamModel?.teamID === directTeam.teamID && u.status==='Active'))
      return this.userService.userList?.filter(u => u.specialTeams?.some(t => t.teamID === specialTeam.teamID))?.map(u => u.orgUserID);
    }
    return [];

  }

  getRole() {
    return this.userService.plainOrgUser.profile?.privilege?.perfReviewRole
  }
}

export function isPerfAdmin() {
  return inject(PerfReviewPermissionsService).hasAdminAccess()
}