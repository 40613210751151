<ui-object-card [title]="item.title"
[description]="getDescriptionHtml(item.description)"
[route]="item.route" [queryParams]="item.queryParams"
[titleSize]="'paragraph'" [underlineTitleOnHover]="true"
[slIcon]="item.slIcon" [hideIcon]="true">
    <ng-container cardTop>
        <ui-streamline-icon *ngIf="item.slIcon" class="text-pink-700 mb-2" [slIcon]="item.slIcon"></ui-streamline-icon>
        <ng-content select="[customIcon]"></ng-content>
    </ng-container>
    <ng-container subheading>
        <ng-content select="[subheading]"></ng-content>

    </ng-container>


</ui-object-card>
