import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { ShortcutButtonContainerComponent } from 'src/app/pages/reporting/filter-pack/shortcut-button-new/shortcut-button-container/shortcut-button-container.component';
import { LocalTableUniqKey } from 'src/app/shared/popups/shared-edit-table-column-modal/localTableUniqKey';
import { BroadcastService } from 'src/app/services/broadcast.service';
/**
 * pass uniq key to handle the download function. Otherwise it only emits the click and do nothing
 */
@Component({
  selector: 'app-download-csv-button',
  standalone: true,
  imports: [CommonModule, UIStreamlineIconComponent, ShortcutButtonContainerComponent],
  templateUrl: './download-csv-button.component.html',
  styleUrls: ['./download-csv-button.component.scss']
})
export class DownloadCsvButtonComponent {
  @Output() buttonClick = new EventEmitter()
  @Input() withSaveFilter = false;
  @Input() text = 'Download CSV';
  @Input() small = false;
  @Input() tableUniqKey?: LocalTableUniqKey

  constructor(
    private broadcastService: BroadcastService
  ) { }


  onClick() {
    this.buttonClick.emit();
    if (this.tableUniqKey) {
      this.broadcastService.broadcast('table-download-click', {tableKey: this.tableUniqKey})
    }
  }
}
