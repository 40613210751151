<div class="swiper-container position-relative" [swiper]="config" [performance]="true" #swiper="ngxSwiper"
     (indexChange)="indexChanged($event)">
    <div class="swiper-wrapper pb-2">
        <!-- <div class="swiper-slide" style="min-width: 240px;"
             *ngFor="let resource of resources; trackBy: trackResource; let i = index">
             <div class="resource-card" resource-template [resource]="resource"></div>
        </div> -->
        <ng-content></ng-content>
        
        
    </div>
    <button class="btn btn-outline-light btn-sm shadow btn-carousel-prev" (click)="prevSlide()" [hidden]="disablePrev">
        <span class="text-neutral-900">
            <ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-left', theme: 'micro-bold', size: 'sm', svgStyle: {'padding-bottom': '2px'}}"></ui-streamline-icon>
        </span>
    </button>

    <button class="btn btn-outline-light btn-sm shadow btn-carousel-next" (click)="nextSlide()" [hidden]="disableNext">
        <span class="text-neutral-900">
            <ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-right', theme: 'micro-bold', size: 'sm', svgStyle: {'padding-bottom': '2px'}}"></ui-streamline-icon>
        </span>
    </button>



</div>