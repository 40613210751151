<div [style]="'width: ' + width + 'px'">
    <label *ngIf="hasTitle" class="f-small text-neutral-800 mb-1" [for]="'select-' + controlName">
        {{title}}
    </label>
    <app-customised-multi-select
            [id]="'select-' + controlName"
            [controlName]="controlName"
            [controls]="controls"
            [submitted]="submitted"
            [options]="options"
            [autoGrow]="autoGrow"
            [displayName]="displayName"
            [placeholder]="placeholder"
            [specialFirstOption]="borderFirstItem"
            [savedFilter$]="savedFilter$"
            [showNumOnSelectAll]="showNumOnSelectAll"
    ></app-customised-multi-select>
</div>
