<ui-object-card [title]="item.title"
[route]="item.route" [titleSize]="'paragraph'" [underlineTitleOnHover]="true"
[slIcon]="item.slIcon" [hideIcon]="true" [noPadding]="true">
    <ng-container *ngIf="item.popoverData" subheading>
        <app-popover-info [popoverData]="item.popoverData"></app-popover-info>
    </ng-container>
    <ng-container cardTop>
        <div class="d-flex justify-content-center color-grad" [style]="getColorBg()">
          @if(item.title === REPORT_CAT_DISPLAY.ausmedPassport){
            <ui-streamline-icon *ngIf="item.slIcon" class="overview-card-hover my-4 text-white" [slIcon]="slIcon"></ui-streamline-icon>
          } @else {
            <ui-streamline-icon *ngIf="item.slIcon" class="overview-card-hover my-4 text-white" [slIcon]="slIcon"></ui-streamline-icon>
          }

        </div>
        <ng-content select="[customIcon]"></ng-content>
    </ng-container>

    <ng-container description>
        <!-- <p class="f-caption text-neutral-900 mt-4">{{item.description}}
        </p> -->
        <p class="f-caption text-neutral-800 mt-2">Includes:
            <span class="fw-semibold">{{reportNames}}</span>
        </p>
    </ng-container>


</ui-object-card>
