import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { UserService } from 'src/app/services/user.service';
import { ComponentHostDirective } from 'src/app/shared/directives/component-host/component-host.directive';
import { ComponentHostStepControl } from 'src/app/shared/utils/workflows/component-host-step-control';
import { StepsControl } from 'src/app/shared/utils/workflows/workflow-steps-control';
import { PolicyFromBackend } from '../interfaces/policy-and-procedure.interface';
import { PolicyProcedureApiService } from '../services/policy-procedure-api.service';
import { IRevokePolicyComponent, IRevokePolicyFlow, IRevokePolicySharedData, REVOKE_POLICY_FLOW_STEP_KEYS } from './revoke-policy.interface';
import { RevokePolicyChoosePolicyComponent } from './steps/choose-policy/revoke-policy-choose-policy.component';
import { RevokePolicyChooseUserComponent } from './steps/choose-users/revoke-policy-choose-user.component';
import { RevokePolicyReviewComponent } from './steps/review/revoke-policy-review.component';
import { RevokePolicySuccessComponent } from './steps/success/revoke-policy-success.component';
import { ComponentHostDirective as ComponentHostDirective_1 } from '../../../shared/directives/component-host/component-host.directive';
import { UISpinnerComponent } from '../../../ui/ui-spinner/ui-spinner.component';
import { NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';


@Component({
    templateUrl: 'revoke-policy.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, UISpinnerComponent, ComponentHostDirective_1, UIStreamlineIconComponent]
})

export class RevokePolicyComponent implements OnInit, OnDestroy {
    @Input() preSelectedPolicy: PolicyFromBackend;
    @Input() preSelectedUser: any;
    @Input() allowRevokeAnother: boolean = true;;
    @ViewChild(ComponentHostDirective, { static: true }) host: ComponentHostDirective;
    revokePolicyFlowSharedData: IRevokePolicySharedData;
    addResourceToPlanFlow = true;
    stepsControl = new StepsControl<IRevokePolicyFlow>({
        allSteps: [{
            key: REVOKE_POLICY_FLOW_STEP_KEYS.choosePolicy,
            componentClass: RevokePolicyChoosePolicyComponent,
            input: ['revokePolicyFlowSharedData', 'stepsControl'],
        },
        {
            key: REVOKE_POLICY_FLOW_STEP_KEYS.chooseUsers,
            componentClass: RevokePolicyChooseUserComponent,
            input: ['revokePolicyFlowSharedData', 'stepsControl'],
            condition: () => !this.preSelectedUser
        },
        {
            key: REVOKE_POLICY_FLOW_STEP_KEYS.review,
            componentClass: RevokePolicyReviewComponent,
            input: ['revokePolicyFlowSharedData', 'stepsControl']
        },
        {
            key: REVOKE_POLICY_FLOW_STEP_KEYS.success,
            componentClass: RevokePolicySuccessComponent,
            input: ['revokePolicyFlowSharedData', 'stepsControl']
        }],
        onFlowFinish: () => {
            this.closeModal();
        }
    });
    private _hostControl: ComponentHostStepControl<IRevokePolicyFlow, IRevokePolicyComponent>;

    progress = 0;
    errorMessage = '';
    currentStepIndex = 0;
    destroyed$ = new Subject<void>();

    user: any;
    allPolices: PolicyFromBackend[] = [];

    currentComponent: IRevokePolicyComponent;
    currentStep: any;
    loading = false;

    constructor(
        private activeModal: NgbActiveModal,
        private _cdr: ChangeDetectorRef,
        private organisationsService: OrganisationService,
        private userService: UserService,
        private groupService: GroupService,
        private pnpApiService: PolicyProcedureApiService,
        private modalService: NgbModal
    ) {
        this._hostControl = new ComponentHostStepControl<IRevokePolicyFlow, IRevokePolicyComponent>(this.stepsControl, this);
    }

    ngOnInit(): void {
        this.loading = true;
        combineLatest([
            this.organisationsService.getOrganisation(),
            this.groupService.fetchedCacheOrgJobRoles(),
            this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID')),
            this.userService.getOrgUser(),
            this.pnpApiService.searchGovernance('_page=1&_limit=500', {
                orgID: localStorage.getItem('orgID'),
                status: ["Published"],
                governanceType: ["policy"]
            })
        ]).pipe(
            take(1),
            switchMap(([organisation, orgJobRoles, orgUsers, user, policies]) => {
                this.user = user;
                this.revokePolicyFlowSharedData = {
                    organisation: organisation,
                    orgJobRoles: orgJobRoles,
                    orgUsers: orgUsers,
                    manager: user,
                    allPolicies: policies.sort((a, b) => {
                        const titleA = a.title.toLowerCase();
                        const titleB = b.title.toLowerCase();
                        if (titleA < titleB) {
                            return -1;
                        }
                        if (titleA > titleB) {
                            return 1;
                        }
                        return 0;
                    }),
                    shouldRevokeAnother: false,
                    allowRevokeAnother: this.allowRevokeAnother
                };
                if (this.preSelectedPolicy) {
                    this.revokePolicyFlowSharedData.selectedPolicy = this.preSelectedPolicy;
                }

                if (this.preSelectedUser) {
                    this.revokePolicyFlowSharedData.selectedUsers = [this.preSelectedUser].map((u: any) => {
                        const teamDict = this.groupService.globalTeamsDict;
                        u.displayName = {
                            fullName: u.fullName,
                            userID: u.userID,
                            profilePicURL: u.userDetail ? u.userDetail.profilePicURL : '',
                            status: u.status,
                        };

                        u.teamNames = u.teams ? u.teams.map(teamID => teamDict[teamID] ? teamDict[teamID] : '') : [];
                        u.jobRoles = u.activeJobRoleTakens ? u.activeJobRoleTakens.map(j => j.jobRoleModel) : [];

                        return u;
                    });
                    this.revokePolicyFlowSharedData.preselectedUser = this.preSelectedUser;
                }
                this.loading = false;
                this.stepsControl.init();
                return this._hostControl.init$(this.host);
            }),
        ).subscribe(c => {
            this.currentComponent = c;
            this._cdr.markForCheck();
        });
        this.stepsControl.getCurrentStep$().pipe(
            tap((c) => {
                this.currentStep = c;
                this.currentStepIndex = this.stepsControl.currentIndex;
                this.progress = (this.stepsControl.steps.indexOf(c) + 1) * 100 / this.stepsControl.steps.length;
            }),
            takeUntil(this.destroyed$),
        ).subscribe();

    }



    closeModal() {
        this.activeModal.close();
        if (this.revokePolicyFlowSharedData.shouldRevokeAnother) {
            const modal = this.modalService.open(RevokePolicyComponent, { size: 'xl', backdrop: 'static' });

            if (this.preSelectedUser) {
                modal.componentInstance.preSelectedUser = this.preSelectedUser;
            }
            this.revokePolicyFlowSharedData.shouldRevokeAnother = false;
        }
    }

    nextStep() {
        this.stepsControl.nextStep();
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }



}

