import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ViewCell } from 'src/app/shared-modules/ng2-smart-table/components/cell/cell-view-mode/view-cell';
import { sortBy } from 'underscore';
import { GroupService, ITeam } from '../../../services/group.service';
import { COLUMN_VALUES } from 'src/app/core/table-headers.dict';
import { getMaxWidth } from '../../utils/table-cell-functions';
import { AvatarComponent } from '../avatar/avatar.component';
import { RouterLink } from '@angular/router';
import { NgbTooltip, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor } from '@angular/common';
import { take } from 'rxjs/operators';

type ExtendedPlainTextTeam = ITeam & { justPlainText: boolean };

@Component({
    selector: 'app-teams-display-cell',
    template: `
      <div class="text-nowrap text-truncate" [style]="'width: ' + maxWidth + 'px;'">
          <ng-container *ngIf="!showAvatar">
              <ng-container *ngFor="let team of shownTeams;  last as isLast">
                  <span placement="top-left" [ngbTooltip]="!team.justPlainText?'':noPermissionText"
                  container="body">
                      <a *ngIf="team?.teamID && !team.justPlainText"
                         [routerLink]="[routePrefix, team.teamID]"
                         class="fw-medium text-neutral-900"
                         target="_blank">{{team.name}}</a>
                    <span *ngIf="team?.teamID && !!team.justPlainText"
                          class="no-access-link text-neutral-600">
                        {{team.name}}</span>
                    <span *ngIf="!isLast"
                          class="fw-medium text-neutral-900">,&nbsp;</span>
                  </span>
              </ng-container>
              <ng-container *ngIf="hiddenTeams?.length">
                <span *ngIf="shownTeams?.length" class="fw-medium text-neutral-900">, </span>
                <span class="fw-medium text-neutral-900 cursor-pointer hover-underline"
                  placement="top"
                  [ngbPopover]="popContent" [popoverTitle]="popTitle" [container]="'body'"
                  target="_blank"
                >+{{hiddenTeams?.length}} more
                </span>
              </ng-container>
          </ng-container>

          <ng-container *ngIf="showAvatar">
              <ng-container *ngFor="let team of teams;  last as isLast">
                  <span [ngbTooltip]="!team.justPlainText?'':noPermissionText">
                      <div *ngIf="team?.teamID && !team.justPlainText" class="d-flex children-gap-1 align-items-center">
                          <ui-avatar [size]="'sm'" [slIcon]="{name: 'interface-user-multiple', theme: 'micro-bold', size: 'sm'}"></ui-avatar>
                          <a href="javascript:void(0);"
                             [routerLink]="[routePrefix, team.teamID]"
                             class="fw-medium text-neutral-900"
                             target="_blank">
                              {{team.name}}</a><span *ngIf="!isLast"
                                                     class="fw-medium text-neutral-900">,&nbsp;</span>
                      </div>
                      <div *ngIf="team?.teamID && !!team.justPlainText"
                           class="d-flex children-gap-1 align-items-center">
                          <ui-avatar [size]="'sm'" [slIcon]="{name: 'interface-user-multiple', theme: 'micro-bold', size: 'sm'}"></ui-avatar>
                          <span class="no-access-link text-neutral-600">
                              {{team.name}}
                          </span>
                      </div>
                      <span *ngIf="!isLast" class="fw-medium text-neutral-900">,&nbsp;</span>
                   </span>
              </ng-container>
          </ng-container>

          <ng-container *ngIf="!teams?.length">
            <span>{{ noRecordSymbol }}</span>
          </ng-container>

      </div>

      <!-- popover -->
      <ng-template #popContent>
        <ng-container *ngFor="let team of hiddenTeams;  last as isLast">
          <div>
            <span placement="top-left" [ngbTooltip]="!team.justPlainText?'':noPermissionText"
                    container="body">
              <a *ngIf="team?.teamID && !team.justPlainText"
                href="javascript:void(0);"
                [routerLink]="['/pages/admin/teams/single', team.teamID]"
                class="fw-medium text-neutral-900"
                target="_blank">
                {{team.name}}
              </a>
              <span *ngIf="team?.teamID && !!team.justPlainText"
                class="no-access-link text-neutral-600">
                {{team.name}}
              </span>
            </span>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #popTitle>
        <span class="fw-medium text-neutral-900 f-small">
          {{popTitleText}}
        </span>
      </ng-template>

  `,
    styles: [`.no-access-link {
      pointer-events: none !important;
  }`],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, NgbTooltip, RouterLink, NgbPopover, AvatarComponent]
})
export class TeamNamesCellComponent implements ViewCell, OnInit {
  teams: ExtendedPlainTextTeam[];
  noRecordSymbol = COLUMN_VALUES.NO_RECORD_SYMBOL;
  @Input() value;
  @Input() rowData: any;
  @Input() showAvatar = false;
  @Input() showAll = false;
  @Input() isFacilityCol = false;
  maxWidth: number;
  PIXPERCHAR = 7;
  maxNumChar: number;
  hiddenTeams: ExtendedPlainTextTeam[];
  shownTeams: ExtendedPlainTextTeam[];
  className = 'teamNames';
  isModal = false;
  routePrefix = '/pages/admin/teams/single'

  constructor(private groupService: GroupService) {
  }

  ngOnInit() {
    this.groupService.fetchedCacheOrgTeams().pipe(take(1))
      .subscribe(() => {
        this.maxWidth = getMaxWidth(this.className, this.isModal, this.maxWidth) || this.maxWidth;
        this.maxNumChar = Math.floor(this.maxWidth / this.PIXPERCHAR);
    
    
        let teams = (this.rowData.teamNames || this.rowData.teams || [])
    
        if (this.isFacilityCol) {
          this.routePrefix = '/pages/admin/admin-locations';
          teams = this.rowData.facility || this.rowData.facilityModels || [];
        }
      
        if (!teams.length && this.rowData.teamID) {
          teams = [{ ...this.rowData }];
        }
        this.teams = sortBy(sortBy([...teams].map(team => ({ ...team, justPlainText: !this.groupService.managedTeamsMapByTeamID[team.teamID] })), 'name'), 'justPlainText')
          .filter(t => t?.name);
        this.shownTeams = this.getShownTeams(this.teams);
        if (!this.showAll) {
          this.hiddenTeams = this.teams.slice(this.shownTeams.length);
        }
      })


  }

  getShownTeams(teams: ExtendedPlainTextTeam[]) {
    // if enough space, show all
    const allTeamsChars = teams.reduce((prev, cur) => {
      if (prev === 0) {
        return cur.name.length; // first team
      }
      return prev + cur.name.length + 3; // add 3 for comma and spaces
    }, 0);
    if (allTeamsChars <= this.maxNumChar || this.showAll) {
      return teams;
    }
    // otherwise, take some and hide rest
    let currentTotalChar = 5; // space for ...n more
    const shownTeams: ExtendedPlainTextTeam[] = [];
    let continueLoop = true;
    teams.forEach((team, index) => {
      // add first team regardless
      if (currentTotalChar + team.name.length + 3 < this.maxNumChar && continueLoop || index === 0) {
        shownTeams.push(team);
        currentTotalChar += (team.name.length + 3);
      } else {
        continueLoop = false;
      }
    });
    return shownTeams;
  }

  get noPermissionText() {
    return `You do not have permission to access this ${this.isFacilityCol ? 'facility' : 'team'}`
  }

  get popTitleText() {
    return `+${this.hiddenTeams.length} more ${this.isFacilityCol ? 'facility' : 'team'}${this.hiddenTeams?.length > 1 ? 's' : '' }`.replace('facilitys', 'facilities');

  }
}
