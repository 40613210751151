import { NgComponentOutlet } from '@angular/common';
import { Component, Input, Signal, computed, inject, signal } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { StepProgressComponent } from '@ui/flow/step-progress/step-progress.component';
import { clone, keys, pluck, values } from 'underscore';
import { SelectDirectManagerComponent } from './select-direct-manager/select-direct-manager.component';
import { SelectDirectReportsComponent } from './select-direct-reports/select-direct-reports.component';
import { ReviewDirectReportsComponent } from './review-direct-reports/review-direct-reports.component';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HierarchyService } from '../hierarchy.service';
import { ProcessingButtonComponent } from '@ui/buttons/processing-button/processing-button.component';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-direct-manager-add-modal',
  standalone: true,
  imports: [ModalSingleComponent, StepProgressComponent, NgComponentOutlet, ProcessingButtonComponent, ProcessingButtonComponent],
  templateUrl: './direct-manager-add-modal.component.html',
  styleUrl: './direct-manager-add-modal.component.scss'
})
export class DirectManagerAddModalComponent extends ConfirmationModal {
  @Input() selectedManager: string;

  hierarchyService = inject(HierarchyService);



  form = new FormGroup({
    manager: new FormControl<string>(null, [Validators.required]),
    members: new FormControl<string[]>([], [Validators.required])
  })
  STEPS_DISPLAY: string[];
  currentStepIdx = signal(0);
  currentComp: Signal<any>;
  currentControl: Signal<string>;

  ngOnInit(): void {
    let steps = clone(STEP_CONFIG);
    if (this.selectedManager) {
      // remove first step
      steps = STEP_CONFIG.filter(s => {
        if (!this.selectedManager) {
          return true;
        }
        return s.key !== 'manager';
      });
      
      // set manager
      this.form.controls.manager.setValue(this.selectedManager);

      
    }
    this.STEPS_DISPLAY = pluck(steps, 'title');
    this.currentComp = computed(() => steps[this.currentStepIdx()].component);
    this.currentControl = computed(() => this.form.controls[steps[this.currentStepIdx()].key]);

  }

  onAdd() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const formValue = this.form.value;
    this.hierarchyService.addManager(formValue.manager, formValue.members)
      .subscribe(() => {
      this.confirmSuccess(this.selectedManager ? 'Reports added' : 'Reporting line added');
    }, error => this.error(error));
  }

  onNext() {
    this.currentStepIdx.update(v => v + 1);
  }


  onBack() {
    this.currentStepIdx.update(v => v - 1)
  }

}

export const STEP_CONFIG = [
  {key: 'manager', title: 'Select Manager', component: SelectDirectManagerComponent},
  {key: 'members', title: 'Select Reports', component: SelectDirectReportsComponent},
  {key: 'confirm', title: 'Confirm', component: ReviewDirectReportsComponent},
];

