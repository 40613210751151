import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SHORTCUT_LOC_DISPLAY, SHORTCUT_TYPE, ShortcutService } from 'src/app/services/shortcut.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { RouterLink } from '@angular/router';
import { LoadingContainerComponent } from '@ui/loading-container/loading-container.component';
import { Observable } from 'rxjs';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import { getSavedFilterMenu } from 'src/app/services/service-utils/menu-service-utils';
import { sortBy } from 'underscore';

@Component({
  selector: 'app-overview-shortcuts',
  standalone: true,
  imports: [CommonModule, UIStreamlineIconComponent, RouterLink, LoadingContainerComponent],
  templateUrl: './overview-shortcuts.component.html',
  styleUrls: ['./overview-shortcuts.component.scss']
})
export class OverviewShortcutsComponent extends WithDestroy() {
  @Input() shortcutType: SHORTCUT_TYPE
  
  isLoaded = false;
  shortcuts$: Observable<any[]>;
  
  constructor(
    private shortcutService: ShortcutService,
    private cdr: ChangeDetectorRef

  ) {super()}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.shortcuts$ = this.shortcutService.getShortcuts(false, this.shortcutType).pipe(
      takeUntil(this.destroy$),
      // tap(shortcuts => console.log(shortcuts)),
      map(shortcuts => sortBy(shortcuts, s => s.lastOpened).reverse()
                        // .sort((a, b) => a.title - b.title)
                        .slice(0, 4)
                        .map(s => ({...s,
                           displayLocation: SHORTCUT_LOC_DISPLAY[s.location],
                           routerLink: [this.shortcutService.getLocation(s.location)],
                           queryParams: { prefill: true, shortcutID: s.shortcutID }
                          }))
      ), 
      // map(s => []),
      tap(s => {
        this.isLoaded = true;
        this.cdr.detectChanges();
      }));
  
  }

  get link() {
    return '/pages/' + getSavedFilterMenu(this.shortcutType).route
  }
}
