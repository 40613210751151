<ui-modal-single [heading]="title">
    <ng-template #contentSlot>
        <div cdkDropList class="draggable-list" (cdkDropListDropped)="drop($event)">
            <div *ngFor="let column of titlesEnableStatus; let i = index" cdkDrag>
                <div cdkDragHandle [cdkDragHandleDisabled]="column.disabled || hideSortColumn" class="d-flex mb-2 hover-color" [ngClass]="{'ms-1': hideSortColumn}">
                    <ui-input-group 
                                    [showDrag]="!hideSortColumn"
                                    [(ngModel)]="column.enabled"
                                    [label]="column.title"
                                    [value]="column.enabled"
                                    [disabled]="column.disabled"
                                    id="column-input-id-{{i}}">
                    </ui-input-group>
                </div>
            </div>

        </div>
        <small class="text-danger" *ngIf="noEnabledColumns">Please enable at least one column.</small>
    </ng-template>
    <ng-template #rightSlot>
        <button type="button" class="btn btn-outline-light" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-secondary" [disabled]="noEnabledColumns" (click)="save()">{{confirmText}}</button>
    </ng-template>
</ui-modal-single>
