<ui-modal-single [heading]="modalHeading">
    <ng-template #contentSlot>
        <ng-content></ng-content>
        {{content}}
    </ng-template>

    <ng-template #rightSlot>
        <button *ngIf="!secondaryButtonText" [disabled]="isSubmitting" type="button" class="btn btn-outline-light" (click)="closeModal()">Cancel</button>
        <button *ngIf="secondaryButtonText" [disabled]="isSubmitting" type="button" class="btn btn-outline-light" (click)="confirm(true)">{{secondaryButtonText}}</button>
        <button [disabled]="isSubmitting || disableButton" type="button" class="btn {{buttonClass}}" (click)="confirm()">
            <span *ngIf="isSubmitting" style="color: inherit">
                    <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
            </span>
            {{buttonText}}
        </button>
    </ng-template>

    <!-- <ng-template #leftSlot>
        <button *ngIf="secondaryButtonText" [disabled]="isSubmitting" type="button" class="btn btn-outline-light" (click)="closeModal()">Cancel</button>
    </ng-template> -->



</ui-modal-single>
