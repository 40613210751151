import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HierarchyService } from 'src/app/pages/admin/hierarchy/hierarchy.service';
import { FacilitiesService } from 'src/app/services/facilities.service';
import { GroupService } from 'src/app/services/group.service';
import { IClassifiedOrgUsers, UserService } from 'src/app/services/user.service';
import { LearningGroupType } from '../../reporting-type';
import { CATEGORY_ENUM } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CategorySelectorService {
  facilities = inject(FacilitiesService).fetchManagedFacilities();
  users = inject(UserService).fetchCachedOrgUsers();
  teams = inject(GroupService).fetchedCacheOrgTeams();
  jobRoles = inject(GroupService).fetchedCacheOrgJobRoles();
  directManagers = inject(HierarchyService).fetchCachedHierarchy();

  constructor() { }

  getSelectorOptions(): Observable<Partial<Record<CATEGORY_ENUM, any[] | IClassifiedOrgUsers>>> {
    return combineLatest([
      this.users, this.teams, this.jobRoles, this.facilities, this.directManagers
    ]).pipe(
      map(arrays => ({
        users: arrays[0],
        teams: arrays[1],
        jobRoles: arrays[2],
        'Facilities': arrays[3],
        directManagers: arrays[4],
      }))
    )
  }
}
