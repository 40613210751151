import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvokeFunctionExpr } from '@angular/compiler';
import { ViewCell } from 'src/app/shared-modules/ng2-smart-table/components/cell/cell-view-mode/view-cell';
import { EmptyStateComponent } from '../../../../../shared/components/empty-state/empty-state.component';
import { NgFor, NgIf } from '@angular/common';
import { ModalSingleComponent } from '../../../../../shared/components/modal-single/modal-single.component';

@Component({
    selector: 'ngx-app-contract-modal',
    templateUrl: './contract-modal.component.html',
    standalone: true,
    imports: [
        ModalSingleComponent,
        NgFor,
        NgIf,
        EmptyStateComponent,
    ],
})
export class ContractModalComponent implements ViewCell {
  @Input() value: string | number;
  @Input() rowData: any;

  @Input() files: any[];

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

}
