import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateToDDMMMYYYY',
  standalone: true
})
export class DateToDdMmmYyyyPipe implements PipeTransform {

  transform(value: Date | string): string {
    // Parse the input date using dayjs
    const date = dayjs(value);
    // Format the date to "MMM YYYY"
    return date.format('DD MMM YYYY');
  }

}
