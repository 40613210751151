import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbTooltip, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { GOVERNANCE_STATUS, PolicyFromBackend } from 'src/app/pages/policies-procedures/interfaces/policy-and-procedure.interface';
import { SharedConfirmationModalComponent } from 'src/app/shared/popups/shared-confirmation-modal/shared-confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { PolicyProcedureApiService } from '../../services/policy-procedure-api.service';
import { combineLatest } from 'rxjs';
import { PPGoveranceService } from '../../services/pp-goverance.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../../../environments/environment';
import { IAckTableData } from '../../policies/pp-detail/pp-detail-ack/pp-detail-ack.component';
import { PnpService } from 'src/app/services/pnp.service';
import { BulkStaffModalComponent } from 'src/app/shared/popups/bulk-staff-modal/bulk-staff-modal.component';
import * as dayjs from 'dayjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ResourceService } from 'src/app/services/resource.service';
import { GroupService } from 'src/app/services/group.service';
import generatePolicyEventTrackField from '../../policies/utils/generate-policy-event-track-obj';
import { copyToClipboard } from 'src/app/shared/utils/copy-to-clipboard';
import { RevokePolicyComponent } from '../../revoke-policy/revoke-policy.component';

import { NgbDropdownComponent } from '../../../../shared/components/ngb-dropdown/ngb-dropdown.component';
import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';


@Component({
    selector: 'pp-shared-actions-button',
    templateUrl: './pp-shared-actions-button.component.html',
    styles: [`
        .m-transformY-1 {
            transform: translateY(-1px);
        }
        .m-fw-500 {
            font-weight: 500 !important;
        }
    `
    ],
    standalone: true,
    imports: [NgIf, NgClass, NgbTooltip, NgbDropdownComponent, NgbDropdownItem, UIStreamlineIconComponent]
})

export class PPSharedActionsButtonComponent implements OnInit {
    /* ngClass supports `btn btn-sm`; string is enough! */
    @Input() btnWrapperClasses = '';
    @Input() openInNewTab = false;
    @Input() value: any;
    @Input() rowData: any;
    @Input() buttonSize = 'sm';
    @Input() disableScroll = true; // disable scrolling when dropdown is open
    @Input() triggerPage: 'detailPageAction' | 'policiesTableAction' | 'acknowledgementRecordsTableAction' | 'DraftPnPTableAction' | 'upcomingPolicyReviewsTableAction';
    @Input() placement = 'left-bottom auto';
    GOVERNANCE_STATUS = GOVERNANCE_STATUS;
    isOrgManager: Boolean = false;

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private toaster: ToastrService,
        private pnpService: PolicyProcedureApiService,
        private ppService: PPGoveranceService,
        private _router: Router,
        private userService: UserService,
        private otherPpService: PnpService,
        private analytics: AnalyticsService,
        private _resourceService: ResourceService,
        private _groupService: GroupService,
    ) { }

    ngOnInit() {
        this.isOrgManager = this.userService.isManager;
    }

    get viewLink() {
        return `/pages/policies/manage/policies/${this.rowData.governanceID}`;
    }

    get itemStatus() {
        return this.rowData.fn_status;
    }

    editItem(newTab = false) {
        if (this.canEdit) {
            const url = `/pages/policies/manage/policies/${this.rowData.governanceID}/edit?versionID=${this.rowData.governanceVersion.versionID}`
            if (newTab) {
                window.open(url, '_blank');
                return;
            }
            const context = this.triggerPage === 'policiesTableAction' ? 'policyLibrary' : 'dashboard';
            this.router.navigateByUrl(url, { state: { context } });
        }
    }

    reVokePolicy() {
        const modal = this.modalService.open(RevokePolicyComponent, { size: 'xl', backdrop: 'static', keyboard: false });
        modal.componentInstance.preSelectedPolicy = this.rowData;
        modal.componentInstance.allowRevokeAnother = false;
    }

    archiveItem() {
        const activeModal = this.modalService.open(SharedConfirmationModalComponent, { backdrop: 'static', size: 'lg' });
        activeModal.componentInstance.title = 'Archive policy?';
        activeModal.componentInstance.content = `<p>Once archived, this policy will no longer be visible to users in the policy library of My Organisation. This action cannot be reversed. </p>`;
        activeModal.componentInstance.buttonText = 'Archive';
        activeModal.result.then((result) => {
            if (result === 'confirm') {
                this._cdr.markForCheck();
                const archivePolicy$ = this.pnpService.archivePolicy(this.rowData.governanceID, this.rowData.governanceVersion.versionID);
                archivePolicy$.subscribe(
                    () => {
                        this.toaster.success('Policy successfully archived');
                        if (this._resourceService.guidelineDict && this._groupService.globalJobRolesDict) {
                            this._trackEvent_P_policy_archived();
                        } else {
                            combineLatest([this._resourceService.fetchCachedGuidelines(), this._groupService.fetchOrgJobRoles(localStorage.getItem('orgID'))]).subscribe(
                                () => {
                                    this._trackEvent_P_policy_archived();
                                }
                            );
                        }
                        this.rowData.governanceVersion.status = GOVERNANCE_STATUS.archived;
                        setTimeout(() => {
                            this.ppService.refreshAllPolicies();
                            this._router.navigate(['/pages/policies/manage/policies']);
                        }, 1000);
                        this._cdr.detectChanges();
                    },
                    (error) => {
                        this.toaster.error(error, 'Error');
                        this._cdr.detectChanges();
                    }
                );
            }
        });
    }

    unarchiveItem() {
        const activeModal = this.modalService.open(SharedConfirmationModalComponent, { backdrop: 'static', size: 'lg' });
        activeModal.componentInstance.title = 'Unarchive';
        activeModal.componentInstance.content = `<p>Are you sure you want to unarchive this policy?</p>`;
        activeModal.componentInstance.buttonText = 'Confirm';
        activeModal.result.then((result) => {
            if (result === 'confirm') {
                this._cdr.markForCheck();
                this.pnpService.unarchivePolicy(this.rowData.governanceID, this.rowData.governanceVersion.versionID).subscribe(
                    () => {
                        this.toaster.success('Policy unarchived');
                        this.rowData.governanceVersion.status = GOVERNANCE_STATUS.published;
                        setTimeout(() => {
                            this.ppService.refreshAllPolicies();
                            this._router.navigate(['/pages/policies/manage/policies']);
                        }, 1000);
                        this._cdr.detectChanges();
                    },
                    (error) => {
                        this.toaster.error(error, 'Error');
                        this._cdr.detectChanges();
                    }
                );
            }
        });
    }

    deleteItem() {
        const activeModal = this.modalService.open(SharedConfirmationModalComponent, { backdrop: 'static', size: 'lg' });
        activeModal.componentInstance.title = 'Delete draft?';
        activeModal.componentInstance.content = `<p>Deleting this policy will permanently remove it from your library. This action cannot be reversed. </p>`;
        activeModal.componentInstance.buttonText = 'Delete';
        activeModal.componentInstance.buttonClass = 'danger';
        activeModal.result.then((result) => {
            if (result === 'confirm') {
                this._cdr.markForCheck();
                this.pnpService.deletePolicy(this.rowData.governanceID).subscribe(
                    () => {
                        this.toaster.success('Policy successfully deleted');
                        setTimeout(() => {
                            this.ppService.refreshAllPolicies();
                            this._router.navigate(['/pages/policies/manage/policies']);
                        }, 1000);
                        this._cdr.detectChanges();
                    },
                    (error) => {
                        this.toaster.error(error, 'Error');
                        this._cdr.detectChanges();
                    }
                );
            }
        });
    }

    previewItem() {
        window.open(`${environment.frontendDomain}/my-organisation/policy/${this.rowData.governanceID}/${localStorage.getItem('orgcode')}`, '_blank');
    }

    versionHistory() {
        this.router.navigate([`/pages/policies/manage/policies/${this.rowData.governanceID}`], { fragment: 'versionHistory' });
    }

    sendReminder() {
        const rowData = this.rowData as IAckTableData;
        const activeModal = this.modalService.open(BulkStaffModalComponent, { backdrop: 'static', size: 'lg' });

        activeModal.componentInstance.action = 'send ack reminders';
        activeModal.componentInstance.selectedUsers = [this.userService.managedOrgUserDictionaryByOrgUserID[rowData.orgUserID || rowData.orgUser?.orgUserID]];
        activeModal.componentInstance.policies = { name: rowData.fn_title, ackIDs: [rowData.ackID] };
    }

    markAsAcknoledged() {
        const activeModal = this.modalService.open(SharedConfirmationModalComponent, { backdrop: 'static', size: 'lg' });
        activeModal.componentInstance.title = 'Mark as Acknowledged';
        activeModal.componentInstance.content = ``;
        activeModal.componentInstance.buttonText = 'Send';
        activeModal.result.then((result) => {
            if (result === 'confirm') {
                this.toaster.success('Marked as complete');
            }
        });
    }

    viewItem() {
        this._router.navigateByUrl(`/pages/policies/manage/policies/${this.rowData.governanceID}`);
    }

    previewPolicy() {
        window.open(environment.frontendDomain + `/my-organisation/policy/${this.rowData.governanceID}/${localStorage.getItem('orgcode')}`);
    }

    copyPreviewLink() {
        copyToClipboard(environment.frontendDomain + `/my-organisation/policy/${this.rowData.governanceID}/${localStorage.getItem('orgcode')}`, this.toaster);
    }

    _trackEvent_P_policy_archived() {
        this.rowData.governanceVersion['relevancy'] = this.rowData.relevancy;
        const _baseProperties = { policy: generatePolicyEventTrackField(this.rowData, this._groupService, this._resourceService) };
        this.analytics.track('P-policy-archived', _baseProperties);
    }

    get canEdit() {
        return this.isOrgManager
    }
}
