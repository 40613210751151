import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusType } from '@components/evidence-components/add-evidence-form/add-evidence.component';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService, PAGE_CONTEXT_TYPES } from 'src/app/services/analytics.service';
import { EvidencePayloadType, EvidenceService, EvidenceType } from 'src/app/services/evidence.service';
import { IOrgUser, UserService } from 'src/app/services/user.service';
import { CpdTimePipe } from 'src/app/shared/pipes/cpd-time.pipe';
import { LocalDatePipe } from 'src/app/shared/pipes/local-date.pipe';
import { AddEvidenceComponent } from '../../evidence-components/add-evidence-form/add-evidence.component';
import { SidePanelEvidenceSection } from './side-panel-evidence-section/side-panel-evidence-section.component';
import { NgIf, NgFor } from '@angular/common';
import { AvatarComponent } from '../../avatar/avatar.component';
import { DownloadCertificateButtonComponent } from '@components/download-certificate/download-certificate.component';

@Component({
  selector: 'app-side-panel-learning-record-section',
  templateUrl: 'side-panel-learning-record-section.component.html',
  styleUrls: ['side-panel-learning-record-section.component.scss'],
  standalone: true,
  imports: [AvatarComponent, NgIf, NgFor, SidePanelEvidenceSection, AddEvidenceComponent, DownloadCertificateButtonComponent]
})

export class SidePanelLearningRecordSection implements OnInit {
  @Input() user: IOrgUser;
  @Input() activity: any;
  @Input() isOrgActivity = false;
  hasAddEvidenceSection = false;
  evidenceFormValue: EvidencePayloadType;
  evidences: EvidenceType[];
  isInternalRecord = false;
  learningRecordMap;

  constructor(
    private cpdTimePipe: CpdTimePipe,
    private localDatePipe: LocalDatePipe,
    private evidenceService: EvidenceService,
    private toaster: ToastrService,
    private _cdr: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.learningRecordMap = { ...this.activity, resource: this.activity?.resourceObj, recordType: this.recordType, userID: this.user.userID, endDateLocal: dayjs(this.activity.completeDate).format('YYYY-MM-DD') };
    this.isInternalRecord = this.recordType === 'Internal Record' ? true : false;
    this.evidences = this.activity.detail.notes ? this.activity.detail.notes.sort((a, b) => {
      return dayjs(a.addedDate).diff(dayjs(b.addedDate))
    }) : [];
  }

  jumpToNewTab() {
    window.open('/pages/staff-transcript/' + this.user.orgUserID, '_blank')
  }

  showAddSection() {
    this.hasAddEvidenceSection = true;
    // scroll to the Bottom of the container
    this._cdr.detectChanges();
    this.scrollToBottom();
  }

  scrollToBottom() {
    let element = document.getElementById('add-evidence-section-container');
    element.scrollIntoView(false);
  }

  addEvidence(e) {
    if (e) {
      this.evidenceService.addEvidenceToOrgActivity(this.activity.orgActivityID, this.user.userID, this.evidenceFormValue).subscribe(
        () => {
          this.evidenceService.recordsDataRefresh.next(this.activity.orgActivityID);
          const context = this.analytics.getContext(this.router.url);
          this.analytics.track('L-record-evidence_added', {
            context: context,
            notes: !!this.evidenceFormValue.note,
            weblink: !!this.evidenceFormValue.links[0]?.link,
            ...(context === 'reporting' ? { reportName: this.evidenceService.getEventReportName() } : {})
          })
          this.toaster.success('Evidence successfully added');
        },
        (error) => {
          this.toaster.error('Error: ', error);
        }
      )
    }
  }

  cancel() {
    this.hasAddEvidenceSection = false;
  }

  get reportedTimeSpentLearning() {
    return this.cpdTimePipe.transform(this.activity.resourceObj?.min, 'minuteWithM') || '-';
  }

  get recordType() {
    if (this.isOrgActivity) {
      return 'Internal Record';
    } else {
      if (this.activity.status === 'Done') {
        return 'User Completed';
      }
      if (this.activity.status === 'Completed') {
        return 'User Documented';
      }
    }
  }

  get createdDate() {
    return this.localDatePipe.transform(this.activity.createDate) || '-';
  }

  get managerName() {
    if (this.activity.managerID) {
      return this.userService.managedOrgUserDictionaryByUserID[this.activity.managerID]?.fullName || 'System'
    }
    return ''
  }
}
