import { ITeam } from "src/app/services/group.service";
import { IOrgUser } from "src/app/services/user.service";
import { keys } from "underscore";

export type locationType = 'org_location_group' | 'org_location_region' | 'org_location_city' | 'org_location_facility';


export const LOCATION_TYPE_DISPLAY: Record<locationType, string> = {
    org_location_group: 'Group',
    org_location_region: 'Region',
    org_location_city: 'Sub-Region',
    org_location_facility: 'Facility',
}

export interface IFacility extends ITeam {
    teamType: locationType;
    descendantFacilities: string[];
    allMembers: string[];
    managerIDs: string[];
    allManagers: IOrgUser[]; // includes all descendant managers
    allDescendants: string[];
    upperStructure: string;
    upperManagedTeam: string;
    locationTypeDisplay: string;
    level?: number;
}

export interface IFacilityMultiSelect extends ITeam {
    teamType: locationType;
    descendantFacilities: string[];
    allMembers: string[];
    managerIDs: string[];
    allManagers: IOrgUser[]; // includes all descendant managers
    allDescendants: string[];
    upperStructure: string;
    upperManagedTeam: string;
    locationTypeDisplay: string;
    level?: number;
    disabled?: boolean;
}

export function getLocationParentType(type: locationType) {
    const locLevels = keys(LOCATION_TYPE_DISPLAY); // list of location types from highest
    return locLevels[locLevels.indexOf(type) - 1]
}

export function getLocationChildType(type: locationType) {
    const locLevels = keys(LOCATION_TYPE_DISPLAY); // list of location types from highest
    return locLevels[locLevels.indexOf(type) + 1]
}
