import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedSidebarComponent } from '@components/shared-sidebar/shared-sidebar.component';

@Component({
  selector: 'app-general-side-panel-cell',
  standalone: true,
  imports: [CommonModule, SharedSidebarComponent],
  templateUrl: './general-side-panel-cell.component.html',
  styleUrls: ['./general-side-panel-cell.component.scss']
})
export class GeneralSidePanelCellComponent {
  @Input() buttonText;
  @Input() preventSideBar = false;
  @Output() cellClick = new EventEmitter();

  @ViewChild('appSharedSidebar', { static: true }) appSharedSidebar;


  openSidePanel() {
    if (this.preventSideBar) {
      this.cellClick.emit();
      return;
    }
    this.appSharedSidebar.toggleShowSidebar();
  }
}
