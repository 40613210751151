import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  OnInit,
  ViewChild,
  SimpleChanges,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  NgZone
} from '@angular/core';
import { indexBy } from 'underscore';
import { CommonModule, isPlatformBrowser, NgFor } from '@angular/common';
import { Subject } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { SwiperDirective } from 'src/app/shared-modules/ngx-swiper-wrapper/swiper.directive';

import { ResourceTemplateComponent } from '../../components/resource-template/resource-template.component';
import { SwiperDirective as SwiperDirective_1 } from '../../../shared-modules/ngx-swiper-wrapper/swiper.directive';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { OverviewCardComponent } from 'src/app/pages/overview/shared-overview/overview-card/overview-card.component';
import { REPORT_CARD_CONFIG } from 'src/app/pages/overview/overview-reporting/report-card-config';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { FeatureFlagDirective } from '../../directives/feature-flag.directive';


@Component({
  selector: 'app-general-carousel',
  standalone: true,
  imports: [
    SwiperDirective_1,
    NgFor,
    ResourceTemplateComponent,
    UIStreamlineIconComponent,
    OverviewCardComponent,
    AvatarComponent,
    FeatureFlagDirective,
    CommonModule
  ],
  templateUrl: './general-carousel.component.html',
  styleUrl: './general-carousel.component.scss'
})
export class GeneralCarouselComponent {
  @Input() resources: any[];
  @Input() perSlide = 3.7;
  disablePrev = true;
  disableNext = false;
  breakpoints = [
    {
      maxWidth: 0,
      slidesPerView: this.perSlide,
      slidesPerGroup: this.perSlide,
    },
    // {
    //   maxWidth: 576,
    //   slidesPerView: 2.2,
    //   slidesPerGroup: 2.2,
    // },
    // {
    //   maxWidth: 768,
    //   slidesPerView: 3.2,
    //   slidesPerGroup: 3.2,
    // },
    // {
    //   maxWidth: 992,
    //   slidesPerView: 3.5,
    //   slidesPerGroup: 3.5,
    // },
    // {
    //   maxWidth: 1200,
    //   slidesPerView: 4,
    //   slidesPerGroup: 4,
    // },
    // {
    //   maxWidth: 1400,
    //   slidesPerView: 4.2,
    //   slidesPerGroup: 4.2,
    // },
    // {
    //   maxWidth: 1800,
    //   slidesPerView: 5,
    //   slidesPerGroup: 5,
    // },
    {
      maxWidth: 2400,
      slidesPerView: 5.2,
      slidesPerGroup: 5.2,
    },
    {
      maxWidth: Infinity,
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
  ];
  currentBreak: SwiperOptions;

  config: SwiperOptions = {

    // disable 'accidental' swipes
    shortSwipes: false,

    // make long swipes easier to do to compensate
    longSwipesRatio: 0.15,
    longSwipesMs: 75,

    preloadImages: false,
    spaceBetween: 8,
    speed: 500,

    // Loop with blanks
    loop: false,
    loopFillGroupWithBlank: true,

    breakpoints: indexBy(this.breakpoints, 'maxWidth'),
    allowTouchMove: false,
  };

  index = 0;

  @ViewChild('swiper', { static: true })
  set swiper(s: SwiperDirective) {
    if (!s) { return; }
    this._s = s;
    s.update();
  }
  get swiper() { return this._s; }
  private _s: SwiperDirective;

  isLast: boolean;

  private _destroyed = new Subject<void>();
  readonly isBrowser: boolean;

  private _init: boolean;

  constructor(private _cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private _platformID, private _zone: NgZone) {
    this.isBrowser = isPlatformBrowser(this._platformID);
  }

  ngOnInit() {
    if (!this.isBrowser) { return; }
    this._zone.runOutsideAngular(() => {
      // NOTE: should we share a subscription in a service so that each carousel uses the same listener (hence less listeners are registered?)
      // fromDebouncedEvent(window, 'resize').pipe( // can use window.matchMedia also if available
      //   startWith(null),
      //   takeUntil(this._destroyed),
      //   mapRx(() => {
      //     const width = window.innerWidth;
      //     // return find(this.breakpoints, b => width <= b.maxWidth);
      //     const i = sortedIndex<{ maxWidth: number }, string>(this.breakpoints, { maxWidth: width }, 'maxWidth');
      //     return this.breakpoints[i];
      //   }),
      //   distinctUntilChanged(),
      // )
    });
    this._init = true;
  }

  // called outside angular
  indexChanged(index: number) {
    this.index = index;
    this._zone.run(() => this._cd.detectChanges());
  }

  ngOnChanges(c: SimpleChanges) {
    if (c.resources) {
      if (this.swiper) {
        this.swiper.update();
      }
      // if (this.swiper.swiper()) {
      //   setTimeout(() => this.updateButtonStatus(), 0);
      // }
    }
  }

  updateButtonStatus() {
    this.disablePrev = this.swiper.swiper().isBeginning;
    this.disableNext = this.swiper.swiper().isEnd;
    this._cd.markForCheck();
  }

  nextSlide() {
    this.swiper.nextSlide();
    this.updateButtonStatus();
  }


  prevSlide() {
    this.swiper.prevSlide();
    this.updateButtonStatus();
  }

  trackResource(resource) {
    return resource.resourceID;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }


}
