/* eslint-disable no-case-declarations */
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { CATEGORY_ENUM } from '../interfaces';
import { GroupService, JobRoleType } from '../../../../services/group.service';
import { IOrgUser, UserService } from '../../../../services/user.service';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { FilterEnables } from '../../filter-set/filter-set.component';
import { AccountStatusPipe } from '../../../../shared/pipes/account-status.pipe';
import { sortBy } from 'underscore';
import { ISingleSelectOption, CustomisedSingleSelectComponent } from '../../../../shared/components/customised-single-select/customised-single-select.component';
import { USER_STATUS } from '../../../../services/user.service';
import { LearningGroupType } from '../../reporting-type';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { FEATURES } from 'src/app/core/features.config';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { CustomisedMultiSelectComponent } from '../../../../shared/components/customised-multi-select/customised-multi-select.component';
import { LocationsMultiSelectComponent } from "../../../../shared/components/locations-multi-select/locations-multi-select.component";
import { FacilitiesService } from 'src/app/services/facilities.service';
import { IFacility } from 'src/app/pages/manage-location/admin-location-types';
import { HierarchyService, IHierarchy } from 'src/app/pages/admin/hierarchy/hierarchy.service';
import { JOB_ROLE_TYPE_OPTIONS, jobRoleTypeCaptionDisplay } from './category-selector-export-functions';
import { Router } from '@angular/router';
import { CategorySelectorService } from './category-selector.service';

@Component({
    selector: 'app-category-selector',
    templateUrl: './category-selector.component.html',
    styleUrls: ['./category-selector.component.scss'],
    standalone: true,
    imports: [CustomisedSingleSelectComponent, CustomisedMultiSelectComponent, NgSwitch, NgSwitchCase, LocationsMultiSelectComponent]
})
export class CategorySelectorComponent implements OnDestroy, OnChanges {

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    private accountStatusPipe: AccountStatusPipe,
    private facilitiesService: FacilitiesService,
    private featureFlagService: FeatureFlagService,
    private _hierarchyService: HierarchyService,
    private router: Router,
    private categorySelectorService: CategorySelectorService
  ) {
  }

  @Input() controls;
  @Input() submitted;
  @Input() filterEnables: FilterEnables;
  @Input() allign = 'horizontal';
  @Input() autoGrow = true;
  @Input() hideAccountStatus = false;
  @Input() userFixedWidth = false;
  @Input() filterOrgUsers: string[];


  STATUSES = [
    { value: USER_STATUS.active, label: this.accountStatusPipe.transform(USER_STATUS.active) },
    { value: USER_STATUS.invited, label: this.accountStatusPipe.transform(USER_STATUS.invited) },
    { value: USER_STATUS.pending, label: this.accountStatusPipe.transform(USER_STATUS.pending) },
    { value: USER_STATUS.inactive, label: this.accountStatusPipe.transform(USER_STATUS.inactive) },
    { value: USER_STATUS.rejected, label: this.accountStatusPipe.transform(USER_STATUS.rejected) },
  ].filter(s => {
    // hide pending filter when feature flag on
    if (this.featureFlagService.featureOn(FEATURES.orgUserProfileIntegration)) {
      return s.value !== USER_STATUS.pending;
    } else {
      return true;
    }
  });
  destroyed$ = new Subject();
  teams: { label: string, value: string, child?: boolean }[];
  locationOptions: IFacility[];
  users: ISingleSelectOption[];
  jobRoles: { label: string, value: string }[];
  jobRoleTypes = JOB_ROLE_TYPE_OPTIONS;
  directManagerOptions: { label: string, value: string }[]
  allUsers: IOrgUser[];
  CATEGORIES_ENUM = CATEGORY_ENUM;
  categories: ISingleSelectOption[] = [
    {
      label: 'User',
      value: CATEGORY_ENUM.individual,
    },
    {
      label: 'Team',
      value: CATEGORY_ENUM.teams,
    },
    {
      label: 'Job Role',
      value: CATEGORY_ENUM.jobRoles,
    },
    {
      label: 'Job Role Types',
      value: CATEGORY_ENUM.jobRoleTypes,
      addL: true
    },
    {
      label: 'Location',
      value: CATEGORY_ENUM.facilities,
    },
    {
      label: 'Manager',
      value: CATEGORY_ENUM.directManagers,
    }
  ];

  subscription: Subscription;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (!this.groupService.showJobRoleTypes) {
    // if (!false) {
     this.categories =  this.categories.filter(c => c.value !== CATEGORY_ENUM.jobRoleTypes);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // hide team selector
    if (changes.filterEnables?.currentValue?.hideTeamSelect) {
      this.categories = this.categories.filter(c => c.value !== CATEGORY_ENUM.teams);
    }
    // hide manager selector
    if(!this.filterEnables?.directManager){
      this.categories = this.categories.filter(c => c.value !== CATEGORY_ENUM.directManagers);
    }
    if (!changes.controls) {
      return;
    }
    this.destroyed$.next(); // unsubscribe when the control changes

    this._setOptions();
    this.subscription = this.controls.filterBy.valueChanges.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(() => {
      switch (this.controls.filterBy.value) {
        case CATEGORY_ENUM.individual:
          // this.controls.items.setValue(this.users[0].value);
          const selectedUsers = this.users.map(item => item.value);
          this.controls.items.setValue(selectedUsers);
          break;
        case CATEGORY_ENUM.teams:
          const selectedTeams = this.teams.map(item => item.value);
          this.controls.items.setValue(selectedTeams);
          break;
        case CATEGORY_ENUM.jobRoles:
          const selectedJobRoles = this.jobRoles.map(item => item.value);
          this.controls.items.setValue(selectedJobRoles);
          break;
        case CATEGORY_ENUM.jobRoleTypes:
          this.controls.items.setValue(this.jobRoleTypes.map(t => t.value));
          break;
        case CATEGORY_ENUM.facilities:
          const selectedLocations = this.locationOptions.map(item => item.teamID);
          this.controls.items.setValue(selectedLocations);
          break;
        case CATEGORY_ENUM.directManagers:
          const selectedManager = this.directManagerOptions.map(item => item.value);
          this.controls.items.setValue(selectedManager);
          break;
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setDefaultCategory() {
    this.controls.filterBy.setValue(this.categories[0].value);
  }

  setDefaultUserStatus() {
    this.controls.userStatus.setValue([this.STATUSES[0].value]);
  }

  setDefaultItems() {
    this.controls.items.setValue(this.users.map(u => u.value));
  }

  reset() {
    try {
      setTimeout(() => {
        this.setDefaultCategory();
        this.setDefaultUserStatus();
      }, 0);
      // this.setDefaultItems();
    } catch (e) {
      console.log(e);
    }
  }

  private _getUserOption(users: IOrgUser[]) {
    const userOptions = users.map(u => ({
      label: u.fullName,
      value: u.orgUserID,
      rightLabel: u.status,
      caption: `User ID ${u.staffID || '-'}`
    }));
    // for Goal list page filter
    // if(this.router.url.includes('/goals') && !this.userService.isManager){
    //   return sortBy(userOptions, (u) => u.label?.toLowerCase()).filter(orgUser => this._hierarchyService.getMyDirectReports().map(u => u.orgUserID).includes(orgUser.value));
    // }
    return sortBy(userOptions, (u) => u.label?.toLowerCase());
  }

  private _setOptions() {
    const jobRoles$ = this.categorySelectorService.jobRoles.pipe(
      take(1),
      map(jobRoles => {
        return jobRoles.map(j => ({
          label: j.title,
          value: j.jobRoleID,
          rightCaption: jobRoleTypeCaptionDisplay[j.jobRoleType]
        }));
      }
      ),
    );
    jobRoles$.subscribe(j => this.jobRoles = j);

    this.categorySelectorService.directManagers.subscribe(
      (hierarchy: IHierarchy[]) => {
        this.directManagerOptions = hierarchy.map(h => ({label: h.manager.fullName, value: h.teamID}))
      }
    )

    const teams$ = this.categorySelectorService.teams;
    const managedFacilities$ = this.categorySelectorService.facilities;

    managedFacilities$.pipe(
      take(1),
      switchMap((facilities) => {
        this.locationOptions = facilities.map(f => this.facilitiesService.convertFacilityToFlatArray(f)).filter(fs => fs.length).flat();
        return teams$
      }),
      map((teams) => {
        let orderedFlattenTeams = [];
        const parentTeams = teams.filter(({ upperTeam = '' }) => !upperTeam);
        const parentTeamsWithChildTeams = parentTeams.map((rawParentTeam) => {
          const childTeams = teams.filter((rawTeam) => rawTeam.upperTeam && (rawTeam.upperTeam === rawParentTeam.teamID));
          return { ...rawParentTeam, childTeams };
        });
        let leftTeams = [];
        Object.assign(leftTeams, teams);

        for (const t of parentTeamsWithChildTeams) {
          orderedFlattenTeams.push({
            label: t.name,
            value: t.teamID,
          });
          leftTeams = leftTeams.filter(team => team.teamID !== t.teamID);
          const childTeams = t.childTeams;
          orderedFlattenTeams.push(...childTeams.map(childTeam => {
            leftTeams = leftTeams.filter(team => team.teamID !== childTeam.teamID);
            return ({
              label: childTeam.name,
              value: childTeam.teamID,
              child: true,
            });
          }));

        }
        // merge the left teams from all managed teams
        // eg. a team manager may manage a child team but do not manage/have no access its parent team
        leftTeams = leftTeams.map(childTeam => ({
          label: childTeam.name,
          value: childTeam.teamID,
        }));
        orderedFlattenTeams = orderedFlattenTeams.concat(leftTeams);
        return orderedFlattenTeams;
      }
      )).subscribe(t => this.teams = t);

    const users$ = this.categorySelectorService.users.pipe(
      take(1),
      map(users => {
        this.allUsers = [...users.active, ...users.invited, ...users.pending, ...users.inActive, ...users.rejected].filter(u => this.filterOrgUsers ? this.filterOrgUsers.includes(u.orgUserID) : true);
        let userOption;
        if (this.controls.userStatus) {
          const statuses = this.controls.userStatus.value;
          const filteredUsers = this.allUsers.filter(user => statuses.includes(user.status));
          userOption = filteredUsers;
        } else {
          userOption = users.active;
        }
        return this._getUserOption(userOption)
      }
      ),
    );

    users$.pipe(
      take(1),
      tap(users => {
        this.users = [
          // {
          //   label: `All ${users.length} Users`,
          //   value: 'allUsers',
          //   borderBottom: true,
          // },
          ...users,
        ];
      }),
      switchMap(() => this.controls.userStatus.valueChanges.pipe(takeUntil(this.destroyed$))),
      tap((statuses: USER_STATUS[]) => {
        const filteredUsers = this.allUsers.filter(user => statuses.includes(user.status));
        this.users = [
          // {
          //   label: `All ${filteredUsers.length} Users`,
          //   value: 'allUsers',
          //   borderBottom: true,
          // },
          ...this._getUserOption(filteredUsers),
        ];
        if (this.controls.filterBy.value === LearningGroupType.individual &&
          // this.allUsers.find(user => user.orgUserID === this.controls.items.value) &&
          // !statuses.includes(this.allUsers.find(user => user.orgUserID === this.controls.items.value).status)) {
          true) {
          this.setDefaultItems();
        }
        // if (LearningGroupType.individual) {
        //   this.setDefaultItems();
        // }
      }),
    ).subscribe();

  }


}


