<ui-modal-single >
    <ng-template #headingSlot>
        <div class="d-flex align-items-center">
            <ui-avatar  [slIcon]="{name: 'interface-bookmark', theme: 'micro-bold'}" class="me-2"></ui-avatar>
            <h3 class="text-neutral-900" [innerHtml]="shortcut.shortcutID ? 'Edit Saved Filter' : 'Save current filters for this Report'"></h3>
        </div>
    </ng-template>
    
    <ng-template #contentSlot>
        <p *ngIf="!shortcut?.shortcutID" class="f-small mb-4">Save the current filters in this Report to quickly access them in your Saved Filters</p>
        <div class="form-group pb-10">
            <label class="f-small text-neutral-800 mb-1" for="title">Bookmark title
            </label>
            <input name="title" id="title" [(ngModel)]="title" appInputError [showError]="submitted && !title"
                class="form-control">
            <p class="text-error-input mt-1"
            *ngIf="submitted && !title">
                Please enter title to save
            </p>
        </div>
    </ng-template>

    <ng-template #rightSlot>
        <button class="btn btn-secondary" (click)="confirm()">
            Save
        </button>
    </ng-template>
    <ng-template #leftSlot>
        <button class="btn btn-outline-light" (click)="close()">
            Cancel
        </button>
    </ng-template>
</ui-modal-single>
