import { Component, Input, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NgModel, NG_VALUE_ACCESSOR, UntypedFormControl, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgIf, NgClass } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
    selector: 'ui-input-group',
    templateUrl: './input-group.component.html',
    styleUrls: ['./input-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputGroupComponent),
            multi: true,
        }
    ],
    standalone: true,
    imports: [
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    UIStreamlineIconComponent
],
})
export class InputGroupComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() name: string;
  @Input() answerControl: UntypedFormControl | AbstractControl;
  @Input() label: string;
  @Input() value: string | number;
  @Input() id;
  @Input() type: 'radio' | 'checkbox' = 'checkbox';
  @Input() showDrag = false; // shows drag icon
  state: boolean;
  color:string;

  @Input() indented = false;
  constructor() { }

  onChange(value: any): void {
  }

  changeColor(value) {
    this.color = value ? 'text-neutral-700' : 'text-neutral-300';
  }

  ngOnInit(): void {
    this.color = this.value ? 'text-neutral-700' : 'text-neutral-300'
  }

  onTouch(value: any): void {
  }

  writeValue(state: any): void {
    this.state = state;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    // this.onChange = function(state: boolean) {
    //   this.writeValue(state);
    //   this.model.viewToModelUpdate(state);
    // };
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
