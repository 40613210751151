import { Injectable } from '@angular/core';
import { FacilitiesService } from './facilities.service';
import { Observable, of, throwError } from 'rxjs';
import { IEditOrgUserForm, IEditOrgUserFormBulk, IOrgInvitation, IOrgInvitationForm, IRawBackendOrgUser, OrgUserProfile, UserService } from './user.service';
import { removeEmptyFields } from '../shared/utils/remove-empty-fields';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from './error-handling.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { uniq, values } from 'underscore';
import { maxTeamNum } from './group.service';

@Injectable({
  providedIn: 'root'
})
export class UserUpdateService {

  constructor(
    private facilityService: FacilitiesService,
    private httpClient: HttpClient,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService
  ) { }

  // ensure that user facilities are still included when updating teams
  updateOrgUser(orgID: string, user: any, updatingUserFacilities = false, options?: {removedTeam?: string}) {
    user = this.reAddDirectManager(user);
    user.teams = uniq((user.teams || []).concat(this.getFilteredSpecialTeams(user.orgUserID)));
    if (!updatingUserFacilities) {
      return this.facilityService.fetchCachedFacilities().pipe(
        take(1),
        switchMap(() => {
          return this.update(orgID, this.reAddUserFacilities(user));
        })
      )
    }
    if (options?.removedTeam) {
      user.teams = user.teams.filter(t => t !== options.removedTeam)
    }
    return this.update(orgID, user);
  }

  bulkUpdateOrgUser(
    orgUsersForm: IEditOrgUserFormBulk[],
    options?: { removedFacility?: string, ignoreFacilities?: boolean, ignoreDirectManager?: boolean
      ignoreSpecialTeam?: string
     }) {
    return this.facilityService.fetchCachedFacilities().pipe(
      take(1),
      switchMap(() => {
        orgUsersForm = orgUsersForm.map(u => ({
          ...u,
          teams: uniq((u.teams || []).concat(this.getFilteredSpecialTeams(u.orgUserID, options?.ignoreSpecialTeam)))
        }))
        if (!options?.ignoreFacilities) {
          orgUsersForm = orgUsersForm.map(u => this.reAddUserFacilities(u))
        }
        if (options?.removedFacility) {
          orgUsersForm = orgUsersForm.map(u => ({
            ...u,
            teams: u.teams?.filter(t => t !== options.removedFacility)
          }))
        }
        if (!options?.ignoreDirectManager) {
          orgUsersForm = orgUsersForm.map(u => this.reAddDirectManager(u))
        }
        return this.bulkUpdate(orgUsersForm);
      })
    )
  }

  private bulkUpdate(orgUsersForm: IEditOrgUserFormBulk[]): Observable<IRawBackendOrgUser[]> {
    if (orgUsersForm.some(u => u.teams?.length > maxTeamNum)) {
      return throwError(`A User can have a maximum of ${maxTeamNum} Teams and Facilities`);
    }
    removeEmptyFields(orgUsersForm);
    const url = environment.accountServiceEndpoint + '/orgs/' + localStorage.getItem('orgID') + '/orgUsers';
    return this.httpClient.put<IRawBackendOrgUser[]>(url, orgUsersForm)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }



  getFilteredSpecialTeams(orgUserID: string, ignoredType?: string) {
    const user = this.userService.managedOrgUserDictionaryByOrgUserID[orgUserID];
    if (user) {
      const specialTeams = (user.specialTeams || [])
        .filter(t => ignoredType ? t.teamType !== ignoredType : true);
      return specialTeams.map(t => t.teamID);
    }
    return [];
  }


  reAddUserFacilities(user: { orgUserID?, teams?}) {
    const orgUserID = user.orgUserID;
    const userFacilities = values(this.facilityService.globalOrgFacilities)
      .filter(f => f.teamType === 'org_location_facility')
      .filter(f => f.allMembers.includes(orgUserID))
      .map(f => f.teamID);
    user.teams = uniq((user.teams || []).concat(userFacilities));
    return user;
  }

  reAddDirectManager(user: {orgUserID?, teams?, managedTeams?}) {
    if (user.orgUserID) {
      const orgUser =  this.userService.managedOrgUserDictionaryByOrgUserID[user.orgUserID];
      if (orgUser) {
        const directManagerTeam = orgUser.hierarchyTeamModel?.teamID;
        const directManagedTeam = orgUser.managedHierarchy
        return {
          ...user,
          teams: uniq((user.teams || []).concat(directManagerTeam)).filter(t => t),
          managedTeams: uniq((user.managedTeams || []).concat(directManagedTeam)).filter(t => t)
        }
      }
    }
    return user;
  }


  private update(orgId: string, user: any): Observable<any> {
    if (user.teams?.length > maxTeamNum) {
      return throwError(`A User can have a maximum of ${maxTeamNum} Teams and Facilities`);
    }

    if (user.userID) {
      /* This is for edit existing users*/
      const profile: OrgUserProfile = user.profile;
      const editExistingOrgUserForm: IEditOrgUserForm = {
        userID: user.userID,
        isManager: user.isManager,
        staffID: user.staffID,
        teams: user.teams,
        managedTeams: user.managedTeams,
        jobRoleTakens: user.jobRoleTakens,
        orgMobile: user.orgMobile,
        orgEmail: user.orgEmail,
        profile,
      };
      removeEmptyFields(editExistingOrgUserForm);
      const url = environment.accountServiceEndpoint + '/orgs/' + orgId + '/users/' + user.userID;
      return this.httpClient
        .put(url, editExistingOrgUserForm)
        .pipe(
          catchError(this.errorHandlingService.handleHttpError),
        );
    }

    else {
      /* This is for updating invitation */
      /* I found that this logics is highly dependent on user-profile component data structure...
      * department and position come from somewhere unknown
        creates new invitation when there's none
      * */
      const invitation: IOrgInvitation = {
        ...user.invitation,
        firstName: user.firstName,
        lastName: user.lastName,
        position: user.work?.position,
        department: user.work?.department,
        workType: user.profile?.workType,
        startDate: user.profile?.startDate,
      };
      removeEmptyFields(invitation);
      // console.log('invitation 😁', invitation);

      /* have no idea what the user (any) here is but it will work when refactoring it */

      const invitationForm: IOrgInvitationForm = {
        isManager: user.isManager,
        staffID: user.staffID,
        teams: user.teams,
        managedTeams: user.managedTeams,
        jobRoleTakens: user.jobRoleTakens,
        bundleTakens: user.bundleTakens,
        orgEmail: user.orgEmail,
        orgMobile: user.orgMobile,
        profile: user.profile,
        invitation,
      };
      // newUser = {
      //   // isManager: user.isManager,
      //   // staffID: user.staffID,
      //   // teams: user.teams,
      //   // managedTeams: user.managedTeams,
      //   // jobRoleTakens: user.jobRoleTakens,
      //   // orgEmail: user.orgEmail,
      //   // orgMobile: user.orgMobile,
      //   ...user,
      //   invitation,
      // };

      removeEmptyFields(invitationForm);
      // console.log('final new user 🐣: ', newUser);
      const url = `${environment.accountServiceEndpoint}/orgs/${orgId}/invitations/${user.orgUserID}`;
      return this.httpClient
        .put(url, invitationForm)
        .pipe(
          catchError(this.errorHandlingService.handleHttpError),
        );
    }
  }



}
