<div class="d-flex align-items-center gap-2 p-2" [ngClass]="{'card-hover': nonCompliantNum()}">
    <app-badge-compliance [isCompliant]="!nonCompliantNum()"></app-badge-compliance>
    <div>
        @if(nonCompliantNum()) {
            <p class="text-red-800 f-small fw-semibold">Non-Compliant with {{complianceEntity()}}</p>
            <p class="f-caption text-neutral-700">{{nonCompliantNum()}} {{nonCompliantLabel()}}
                <ui-streamline-icon [slIcon]="{ name: 'interface-arrows-button-right', theme: 'micro-bold', size: 'xs' }"></ui-streamline-icon>
            </p>
        } @else {
            <p class="text-green-800 f-small fw-semibold">Compliant with {{complianceEntity()}}</p>
        }
    </div>
</div>