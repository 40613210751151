<div class="modal-body mw-75 mw-sm-100 m-auto" style="min-height: 350px;">
    <div class="text-center border-bottom pb-7 mb-7">
        <h2>What policy would you like to assign?</h2>
        <p>Please note you can only assign policies to staff who have already been assigned the policy in the past</p>
    </div>

    <div class="mb-7">
        <div class="mb-5">
            <app-choose-policies-dropdown [allPolicies]="availablePolicies"
                (policyChange)="onPolicyChanged($event)" [selectedPolicy]="revokePolicyFlowSharedData.selectedPolicy">
            </app-choose-policies-dropdown>
        </div>
        <div class="d-flex" *ngIf="error">
            <p class="text-danger f-small">{{error}}</p>
        </div>
    </div>
    
</div>
<!-- FOOTER -->
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-light" (click)="onBack();">
        Back
    </button>
    <button class="btn btn-secondary" [disabled]="!revokePolicyFlowSharedData.selectedPolicy || error" (click)="next()">Continue
    </button>
</div>
