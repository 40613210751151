import { NgIf } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { UISpinnerComponent } from '@ui/ui-spinner/ui-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { toasterConfig } from 'src/app/course-builder-2/helper/toaster-config';
import { DownloadCertificateService } from 'src/app/services/download-certificate.service';

@Component({
    selector: 'app-download-certificate',
    templateUrl: './download-certificate.component.html',
    styleUrls: ['./download-certificate.component.scss'],
    standalone: true,
    imports: [NgIf, UIStreamlineIconComponent, NgbTooltip, UISpinnerComponent]
})
export class DownloadCertificateButtonComponent implements OnInit {
    @Input() rowData;
    disableDownloadButton = signal(true);
    downloading = signal(false);

    constructor(
        private downloadCertificateService: DownloadCertificateService,
        private toaster: ToastrService
    ) { }

    ngOnInit(): void {
        if (this.rowData.resource && this.rowData.recordType!=='Internal Record' && !this.rowData.resource?.thirdParty && (this.rowData.resource.activityType === 'OnlineCourse' || (this.rowData.resource.resourceType === 'onlineCourse'))) {
            this.disableDownloadButton.set(false);
        }
    }

    downloadCertificate() {
        this.downloading.set(true);
        if (this.rowData.resource.orgResource) {
            this.downloadCertificateService.downloadOrgResourceCertificate(localStorage.getItem('orgID'), this.rowData?.resource?.resourceID, this.rowData?.userID, this.rowData.endDateLocal, this.rowData?.activityID).subscribe(
                (data) => {
                    this.downloading.set(false);
                    window.open(data.url, '_blank');
                },
                (error) => {
                    this.downloading.set(false);
                    this.toaster.error(error, 'Error', toasterConfig)
                }
            );
        } else {
            this.downloadCertificateService.downloadAusmedResourceCertificate(localStorage.getItem('orgID'), this.rowData?.resource?.resourceID, this.rowData?.userID, this.rowData.endDateLocal, this.rowData?.activityID).subscribe(
                (data) => {
                    this.downloading.set(false);
                    window.open(data.url, '_blank');
                },
                (error) => {
                    this.downloading.set(false);
                    this.toaster.error(error, 'Error', toasterConfig)
                }
            );
        }
    }
}
