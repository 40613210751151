import { Component, computed, inject, input } from '@angular/core';
import { GoalService, ILearningGoal } from 'src/app/services/goal.service';
import { HoverToggleDirective } from 'src/app/shared/directives/hover-toggle.directive';

@Component({
  selector: 'app-task-modal-goal-card',
  standalone: true,
  imports: [HoverToggleDirective],
  templateUrl: './task-modal-goal-card.component.html',
  styleUrl: './task-modal-goal-card.component.scss'
})
export class TaskModalGoalCardComponent {
  goal = input<ILearningGoal>();

  goalService = inject(GoalService);

  goalStatus = computed(() => this.goalService.getGoalStatus(this.goal()))
}
