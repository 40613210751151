import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
export const ANIMATION_TIME = 225;
export const globalAnimations: {
  readonly bodyExpansion: AnimationTriggerMetadata;
  readonly openCloseQuestionCard: AnimationTriggerMetadata;
  readonly openCloseQuestionForm: AnimationTriggerMetadata;
  readonly toggleSidePanel: AnimationTriggerMetadata;
  readonly isAlertDisplay: AnimationTriggerMetadata;
} = {
  bodyExpansion: trigger('bodyExpansion', [
    state('collapsed, void', style({height: '0px', visibility: 'hidden'})),
    state('expanded', style({height: '*', visibility: 'visible'})),
    transition('expanded <=> collapsed, void => collapsed',
        animate(`${ANIMATION_TIME}ms cubic-bezier(0.4,0.0,0.2,1)`)),
  ]),
  openCloseQuestionCard: trigger('openCloseQuestionCard', [
    transition(':enter', [
      style({height: '2.15rem', visibility: 'hidden'}),
      animate(`${ANIMATION_TIME}ms cubic-bezier(0.4,0.0,0.2,1)`, style({height: '*', visibility: 'visible'})),
    ]),
  ]),
  openCloseQuestionForm: trigger('openCloseQuestionForm', [
    transition(':enter', [
      style({ height: '2.15rem', visibility: 'hidden'}),
      animate(`${ANIMATION_TIME}ms cubic-bezier(0.4,0.0,0.2,1)`, style({ visibility: 'visible', height: '*'})),
    ]),
  ]),
  toggleSidePanel: trigger('toggleSidePanel', [
    transition(':enter', [
      style({transform: 'translateX(100%)'}),
      animate('0.3s', style({transform: 'translateX(0%)'}))
    ]),
    transition(':leave', [
      animate('0.3s', style({transform: 'translateX(100%)'}))
    ]),
  ]),
  isAlertDisplay: trigger('isAlertDisplay', [
    state('open', style({
      opacity: 1,
      display: 'block',
    })),
    state('closed', style({
      opacity: 0,
      display: 'none',
    })),
    transition('open => closed', [
      animate('2s')
    ]),
    transition('closed => open', [
      animate('1s')
    ]),
  ]),
};

export type ExpansionPanelState = 'expanded' | 'collapsed';
export type AlertDisplayState = 'open' | 'closed';
