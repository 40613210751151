import { Pipe, PipeTransform } from '@angular/core';

export type ComplianceRecordStatus = 'Assigned' | 'Overdue' | 'Incomplete' | 'Done' | 'Completed' | 'Scheduled' | 'Skipped';
export type ComplianceStatusTransformedDisplay = 'Open' | 'Missed' | 'Completed' | 'Scheduled' | 'Overdue' | 'Skipped';

// This is just for compliance records!!
@Pipe({
    name: 'complianceStatusPipe',
    standalone: true
})
export class ComplianceStatusPipe implements PipeTransform {
  transform(value: string = ''): string {
    if (value.includes('Assigned')) {
      return value.replace('Assigned', 'Open');
    }
    if (value.includes('Incomplete')) {
      return value.replace('Incomplete', 'Missed');
    }
    if (value.includes('Done')) {
      return value.replace('Done', 'Completed');
    }
    if (value.includes('Completed')) {
      return value.replace('Completed', 'Completed');
    }
    return value;
  }

}
