<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<form name="filterForm" *ngIf="filterForm" [formGroup]="filterForm">
        <div class="d-flex flex-wrap">
                <div class="me-2" *ngIf="filterEnables.stateDate">
                        <label class="f-small text-neutral-800 mb-1">
                                State on Selected Date
                        </label>
                        <app-ngb-date-selector [control]="filterForm.controls.stateDate" [showIcon]="true"
                                [required]="true" [min]="'2000-01-01'"></app-ngb-date-selector>
                </div>

                <app-category-selector [submitted]="submitted" [controls]="filterForm.controls"
                        [filterOrgUsers]="filterOrgUsers" [filterEnables]="filterEnables"
                        [userFixedWidth]="true"></app-category-selector>
                <!-- Assign statuses -->
                <app-training-plan-selector class="me-2" *ngIf="filterEnables.assignStatus" [submitted]="submitted"
                        [controls]="filterForm.controls" [options]="assignStatusOptions" width="200"
                        [title]="'Current Status'" displayName="Status" controlName="assignStatus"
                        [prefilter]="preFilters.assignStatus"></app-training-plan-selector>
                <app-training-plan-selector class="me-2" *ngIf="filterEnables.trainingPlan" [submitted]="submitted"
                        [controls]="filterForm.controls" [options]="planOptions" [borderFirstItem]="true" width="300"
                        [title]="'Source'" displayName="Plan"></app-training-plan-selector>

                <ng-container *ngIf="altResourcePosition">
                        <div class="me-2">
                                <ng-container *ngTemplateOutlet="resources"></ng-container>
                        </div>
                </ng-container>

                <!-- Assignment Type -->
                <app-training-plan-selector class="me-2" *ngIf="filterEnables.mandatoryType" [submitted]="submitted"
                        [controls]="filterForm.controls" [options]="mandatoryTypeOptions"
                        [controlName]="'mandatoryType'" [title]="'Requirement Type'" [displayName]="'Requirement'"
                        width="250" placeholder="Select Requirement Types"
                        [showNumOnSelectAll]="false"></app-training-plan-selector>



                <ng-container *ngIf="filterEnables.policy">
                        <!-- policy status selector -->
                        <app-training-plan-selector class="me-2" [submitted]="submitted"
                                [controls]="filterForm.controls" [options]="POLICY_STATUS_OPTIONS"
                                [title]="'Policy Status'" controlName="policyStatuses" placeholder="Select Statuses"
                                displayName="Status" width="200" [prefilter]="preFilters.policyStatuses">
                        </app-training-plan-selector>
                        <!-- policy selector -->
                        <app-training-plan-selector class="me-2" [submitted]="submitted"
                                [controls]="filterForm.controls" [options]="relevantPolicies$ | async"
                                [title]="'Policies'" controlName="policyIDs" placeholder="Select Policies"
                                displayName="Policy" width="200" [prefilter]="preFilters?.policyIDs">
                        </app-training-plan-selector>
                        <!-- ack status selector -->
                        <app-training-plan-selector class="me-2" [submitted]="submitted"
                                [controls]="filterForm.controls" [options]="ACK_STATUS_OPTIONS"
                                [title]="'Acknowledgement Status'" controlName="ackStatuses"
                                placeholder="Select Statuses" displayName="Status" width="200"
                                [initValues]="initAckStatuses" [prefilter]="preFilters?.ackStatuses">
                        </app-training-plan-selector>

                </ng-container>

                <app-training-plan-selector *ngIf="filterEnables.ackComplianceStatus" [submitted]="submitted"
                        class="me-2" [controls]="filterForm.controls" [options]="ACK_COMPLIANCE_STATUS_OPTIONS"
                        [title]="'Compliance Status'" controlName="ackComplianceStatus"
                        placeholder="Select Compliance Statuses" displayName="Compliance Status" width="225"
                        [prefilter]="preFilters?.ackComplianceStatus"></app-training-plan-selector>

                <app-record-type-selector class="me-2 mb-4" *ngIf="filterEnables.resourceType" [submitted]="submitted"
                        [controls]="filterForm.controls" [options]="recordTypeOptions"></app-record-type-selector>
                <app-framework-selector class="mb-4 me-2" *ngIf="filterEnables.framework" [submitted]="submitted"
                        [controls]="filterForm.controls"></app-framework-selector>
                <app-capability-selector class="mb-4 me-2" *ngIf="filterEnables.capability" [submitted]="submitted"
                        [controls]="filterForm.controls"></app-capability-selector>
                <!-- Goals status -->
                <app-goal-status-selector class="me-2 mb-4" *ngIf="filterEnables.goalStatus"
                        [controls]="filterForm.controls"></app-goal-status-selector>

                <div>
                        <ng-content select="[extraFilterPack]"></ng-content>
                </div>


                <!-- Date -->
                <app-date-type-selector class="mb-4 me-2" *ngIf="filterEnables.dateType"
                        [payrollDate]="filterEnables.payrollDate" [controls]="filterForm.controls"
                        [goalDate]="filterEnables.goalDate"></app-date-type-selector>
                <div style="width: 220px;" class="mb-4 me-4" *ngIf="filterEnables.dateRange">
                        <label class="f-small text-neutral-800 mb-1" for="selectDate">{{ label?.dateRange || 'Learning
                                Completed'}}</label>
                        <!-- updated all reports to full date range (requested by Grace)-->
                        <!-- removed maxDate of date picker for all reports (requested by Grace)-->
                        <app-date-selector [isFullDateRange]="isFullDateRange" [initDateRange]="initDateRange"
                                [customRange]="{startDate:initStartDate, endDate:initEndDate}"
                                (dateRangeChange)="onDateRangeChanged($event)" [dateFilterOptions]="dateFilterOptions"
                                (dateOptionChange)="onDateOptionChanged($event)" id="selectDate"></app-date-selector>
                </div>



                <div #parentButton><ng-content></ng-content></div>
                <div>
                        <ng-content select="[extraFilter]"></ng-content>
                </div>
                <div *ngIf="!parentButton.children.length && !altResetPosition" class="mt-6 mb-4">
                        <ng-container *ngTemplateOutlet="reset"></ng-container>
                </div>



        </div>

        <div *ngIf="filterEnables.resource">
                <div *ngIf="!alwaysShowResource">
                        <span *ngIf="hideResourceFilter && !filterForm.value.resources?.length"
                                (click)="hideResourceFilter = !hideResourceFilter"
                                class="f-small fw-medium cursor-pointer">
                                <ui-streamline-icon
                                        [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size:'xs'}"
                                        class="me-1"></ui-streamline-icon>
                                Show more options
                        </span>
                        <span *ngIf="hideResourceFilter && filterForm.value.resources?.length"
                                (click)="hideResourceFilter = !hideResourceFilter"
                                class="f-small fw-medium cursor-pointer">
                                <ui-streamline-icon
                                        [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size:'xs'}"
                                        class="me-1"></ui-streamline-icon>
                                Show (1) more options
                        </span>
                        <span *ngIf="!hideResourceFilter" (click)="hideResourceFilter = !hideResourceFilter"
                                class="f-small fw-medium cursor-pointer">
                                <ui-streamline-icon
                                        [slIcon]="{name: 'interface-arrows-button-up', theme: 'micro-bold', size:'xs'}"
                                        class="me-1"></ui-streamline-icon>
                                Hide options
                        </span>
                </div>
                <!-- Resources -->
                <div class="d-flex align-items-end">
                        <ng-container *ngIf="!altResourcePosition">
                                <div class="mt-2">
                                        <ng-container *ngTemplateOutlet="resources"></ng-container>
                                </div>
                        </ng-container>
                        <ng-container *ngIf="altResetPosition">
                                <div class="mt-2 ms-2">
                                        <ng-container *ngTemplateOutlet="reset"></ng-container>
                                </div>
                        </ng-container>
                </div>
        </div>


        <div class="mt-3 d-flex" *ngIf="!hideButtons">
                <app-spinner-button class="me-2" btnWidth="160px" [isValid]="filterForm.valid && !extraFilterForm?.invalid"
                        (clickEvent)="submitFilter()" [done]="spinnerButtonDone" [status]="spinnerButtonInitStatus"
                        [displayStatusDict]="displayStatusDict" [reset]="resetSpinnerButtonSubject.asObservable()">
                </app-spinner-button>
                <!-- <app-shortcut-button *ngIf="filterEnables.hideShortcut !== true" [activeShortcut]="activeShortcut$ | async"
                        [(isFilterChangedAndRan)]="filterChanged" [controls]="filterForm.controls"
                        [disabled]="spinnerButtonStatus===SPINNER_BUTTON_STATUS_ENUM.processing || !filterForm.valid"
                        [dateOption]="dateOption$ | async" (discard)="discardShortcut()"></app-shortcut-button> -->
                <app-shortcut-button-container
                        *ngIf="filterEnables.hideShortcut !== true"></app-shortcut-button-container>
        </div>


</form>
<ui-spinner *ngIf="!filterForm"></ui-spinner>

<ng-template #resources>
        <div [hidden]="hideResourceFilter && !alwaysShowResource" style="width: 388px;">
                <app-multi-choose-resource-dropdown id="selectResourceId" [controls]="filterForm.controls" [width]="388"
                        controlName="resources" displayName="Item"
                        (allResourcesDict)="setAllResourceDict($event)"></app-multi-choose-resource-dropdown>
        </div>
</ng-template>

<ng-template #reset>
        <button [disabled]="spinnerButtonStatus===SPINNER_BUTTON_STATUS_ENUM.processing"
                class="btn btn-outline-light {{spinnerButtonStatus===SPINNER_BUTTON_STATUS_ENUM.processing ? 'has-not-allowed-cursor' : ''}}"
                (click)="resetToDefault();">Reset to Default</button>
</ng-template>