import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { clone } from 'lodash';
import { StepsControl } from 'src/app/shared/utils/workflows/workflow-steps-control';
import { intersection } from 'underscore';
import { IRevokePolicyFlow, IRevokePolicySharedData } from '../../revoke-policy.interface';
import { NgIf } from '@angular/common';
import { ChoosePoliciesDropdownComponent } from '../../../../../shared/components/choose-policies-dropdown/choose-policies-dropdown.component';

@Component({
    templateUrl: 'revoke-policy-choose-policy.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ChoosePoliciesDropdownComponent, NgIf]
})

export class RevokePolicyChoosePolicyComponent implements OnInit {
    revokePolicyFlowSharedData: IRevokePolicySharedData;
    stepsControl: StepsControl<IRevokePolicyFlow>;
    error = '';
    availablePolicies = [];
    constructor(
        private cdRef: ChangeDetectorRef,
        private activeModal: NgbActiveModal,
    ) { }

    ngOnInit() {
        this.availablePolicies = this.revokePolicyFlowSharedData.allPolicies;
        if (this.revokePolicyFlowSharedData.selectedPolicy && !this.revokePolicyFlowSharedData.selectedPolicy?.ackRelevancy?.jobRoles) {
            this.error = 'This policy is not assigned to any users yet';
        }
        if (this.revokePolicyFlowSharedData.preselectedUser) {
            this.availablePolicies = this.availablePolicies.filter(p => p.ackRelevancy?.jobRoles && intersection(p.ackRelevancy.jobRoles, this.revokePolicyFlowSharedData.preselectedUser.activeJobRoleTakens.map(j => j.jobRole)).length > 0);
            if(this.availablePolicies.length<1){
                this.error = 'This user is not assigned with any polcies yet';
            }
        }
        this.cdRef.detectChanges();
    }

    onPolicyChanged(policy) {
        this.error = '';
        if (policy) {
            if (!policy.ackRelevancy || !policy?.ackRelevancy?.jobRoles) {
                this.error = 'This policy hs not been assigned to any users yet';
                return;
            } else {
                if (this.revokePolicyFlowSharedData.selectedUsers) {
                    const validateUsers = this.revokePolicyFlowSharedData.selectedUsers.filter(u => u.activeJobRoleTakens && intersection(policy.ackRelevancy.jobRoles, u.activeJobRoleTakens.map(j => j.jobRole)).length > 0);
                    if (validateUsers.length < 1) {
                        this.error = 'This policy is not assigned to the user yet';
                        return;
                    }
                }
                this.revokePolicyFlowSharedData.selectedPolicy = policy;
            }

        } else {
            this.revokePolicyFlowSharedData.selectedPolicy = undefined;
        }
        this.cdRef.detectChanges();
    }

    onBack() {
        this.activeModal.close('reopen');
    }

    next() {
        this.stepsControl.updateSteps();
        this.stepsControl.nextStep();
        this.cdRef.detectChanges();
    }
}