import { cloneDeep, keyBy } from "lodash";
import { IOrgUser } from "../user.service";
import { pluck } from "underscore";
import { LOCATION_TYPE_DISPLAY } from "src/app/pages/manage-location/admin-location-types";
import { HIERARCHY_TEAM_TYPE } from "src/app/pages/admin/hierarchy/hierarchy.service";
import { ALL_SPECIAL_TEAM_TYPES } from "../service-types/special-team-types";

export function spreadUserTeams(user: IOrgUser) {
    const u = user;
    u.facilities = [];
    u.allTeams = u.teams;
    u.teams = []
    u.allTeamModels = u.orgUserDetail?.teamModels || [];
    if (u.orgUserDetail?.teamModels) {
        u.facilityModels = u.orgUserDetail.teamModels.filter(t => LOCATION_TYPE_DISPLAY[t.teamType]);
        u.facilities = u.facilityModels.map(t => t.teamID);
        u.specialTeams = u.orgUserDetail.teamModels.filter(t => ALL_SPECIAL_TEAM_TYPES.has(t.teamType))
        u.hierarchyTeamModel = u.orgUserDetail.teamModels.find(t => t.teamType === HIERARCHY_TEAM_TYPE);
        u.orgUserDetail.teamModels = u.orgUserDetail.teamModels.filter(t => !u.facilities.includes(t.teamID) 
          && t.teamType !== HIERARCHY_TEAM_TYPE && !ALL_SPECIAL_TEAM_TYPES.has(t.teamType));
        u.teams = pluck(u.orgUserDetail.teamModels, 'teamID');
    }

    u.managedHierarchy = u.orgUserDetail.managedTeamModels?.find(t => t.teamType === HIERARCHY_TEAM_TYPE)?.teamID || null;
    u.allManagedTeamModels = u.orgUserDetail?.managedTeamModels;
    u.managedSpecialTeams = u.orgUserDetail.managedTeamModels?.filter(t => ALL_SPECIAL_TEAM_TYPES.has(t.teamType)) || null;
    return u;

}

/**
 * 
 * @param users 
 * @returns add direct managers attribute to user
 */
export function addDirectManagers(users: IOrgUser[]) {
  const managerDict = keyBy(users.filter(u => u.managedHierarchy), 'managedHierarchy');
  return users.map(u => ({
    ...u,
    directManager: u.hierarchyTeamModel ? managerDict[u.hierarchyTeamModel.teamID] : null
  }))
}

/**
 * 
 * @param user 
 * spreads to get basic information
 */
export function spreadUserBasicInfo(user: IOrgUser) {
  user['fullName'] = `${user?.invitation?.firstName} ${user?.invitation?.lastName}`;
  if (user.hasOwnProperty('userDetail')) {
    user = { ...user, ...user.userDetail };
  }
  return user;


}