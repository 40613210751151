import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor() {
  }

  handleHttpError(error: any) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = 'An error occurred:' + error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
      if (error.status === 400) {
        if (error.error.message === 'You are not the manager of this organisation.') {
          console.error('An error occurred:', error.error.message);
          errorMessage = 'You are unauthorised to do so, please contact your organisation manager';
        } else if (error.error.message === 'There is already a user with this username.') {
          errorMessage = 'There is already a user with this username.';
        } else if (error.error.message === 'Unable to send invitation email. Error Msg: Sent Failed! Error Msg: 400 Failed to send to Vero.') {
          errorMessage = 'Unable to send invitation email. Email address is invalid.';
        } else {
          errorMessage = error.error.message ? error.error.message : 'Server error, please notify Ausmed.';
        }
      } else if (error.status === 401) {
        errorMessage = error.error.message ? error.error.message : 'You are unauthorised to do so, please contact your organisation manager.';
      } else if (error.status === 500) {
        errorMessage = 'Internal service error, please try again later.';
      } else if (error.status === 404) {
        errorMessage = error.error.message ? error.error.message : '404 Not Found';
      } else if (error.status === 413) {
        errorMessage = 'File size too large';
      } else if (error.name === 'TimeoutError') {
        errorMessage = 'Upload timeout, please check your internet connection and try again later';
      } else {
        const {url = 'unknown url', status, message = '', error: errorInError = 'unknown'} = error as HttpErrorResponse;
        errorMessage =
            `Please screenshot this to Ausmed Service Team:
            error code: ${status};
            url: ${url};
            error message: ${message || errorInError};`;
      }
    }
    // todo segement events tracking error
    // let postData = {
    //   source: 'website',
    //   org: true,
    // };
    // if (error) {
    //   postData = _.extend(postData, {error: error});
    // }
    // if (localStorage.getItem('orgID')) {
    //   postData = _.extend(postData, {orgID: localStorage.getItem('orgID')});
    // }
    // analytics.track(errorMessage, postData,
    //     {
    //       integrations: {
    //         'All': true,
    //         'Vero': true,
    //         'Intercom': true,
    //         'Google Analytics': true,
    //         'Mixpanel': true,
    //       },
    //     });
    // // return an observable with a user-facing error message
    return throwError(errorMessage);
  }
}
