import { USER_STATUS } from '../../services/user.service';

export function getBadgeFromUserStatus(status, isLight = false) {
  let badgeClass;
  let color;
  switch (status) {
    case USER_STATUS.active:
      color = 'success';
      break;
    case USER_STATUS.invited:
      color = 'warning';
      break;
    case USER_STATUS.inactive:
      color = 'inactive';
      break;
    case USER_STATUS.rejected:
      color = 'inactive';
      break;
    case USER_STATUS.pending:
      color = 'danger';
      break;
    default:
      color = 'inactive';
      break;
  }
    badgeClass = `badge badge-${color}`;
  if (isLight && color !== 'inactive') {
    badgeClass += `-light`;
  }
  return badgeClass;
}
