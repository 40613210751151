import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { IOrgUser } from 'src/app/services/user.service';
import { Ng2SmartTableComponent } from '../../../shared-modules/ng2-smart-table/ng2-smart-table.component';

import { NgClass, NgIf } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { omit } from 'underscore';

@Component({
  selector: 'app-addflow-confirm-users',
  templateUrl: './addflow-confirm-users.component.html',
  styleUrls: ['./addflow-confirm-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    Ng2SmartTableComponent,
    UIStreamlineIconComponent
  ],
})
export class AddflowConfirmUsersComponent implements OnInit {
  @Input() selectedUsers: IOrgUser[];
  @Input() heading = '';
  @Input() contClasses = 'p-5 scroll-area'
  hideTable = true;
  tableSource = new LocalDataSource();
  tableSettings = {
    noDataMessage: 'No Users Found',
    actions: false,
    hideSubHeader: true,
    selectedRowIndex: -1,
    columns: {
      ...omit(this.columnService.getColumns(['user', 'staffID', 'jobRoles', 'teams', 'facilityModels', 'accountStatus'])),
    }
  };


  constructor(
    private cdRef: ChangeDetectorRef,
    private columnService: SmartTableColumnsService
  ) { }

  ngOnInit(): void {
    this.tableSource.load(this.selectedUsers.map(u => { return { ...u, accountStatus: u.status } }));
    // force table to load
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 0);

  }

  onViewTable() {
    this.hideTable = !this.hideTable;
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 0);
  }
}
