import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { cloneDeep as _cloneDeep, find as _find, orderBy as _orderBy } from 'lodash';
import { AddPluralSPipe } from 'src/app/shared/pipes/add-plural-s.pipe';
import { RouterLink } from '@angular/router';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor, CommonModule } from '@angular/common';


export type PopoverItemStructure = {
  name: string;
  url: string;
}
@Component({
    templateUrl: './shared-popover-cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['shared-popover-cell.component.scss'],
    providers: [AddPluralSPipe],
    standalone: true,
    imports: [NgIf, NgbPopover, NgFor, NgbTooltip, RouterLink, CommonModule]
})
export class SharedPopoverCellComponent implements OnInit {
    @Input() value: PopoverItemStructure[];
    @Input() rowData: any;
    @Input() column: string;
    @Input() plural?: string;
    @Input() noContainer = false;
    @Input() showPlusMore // eg IT Team, +1 more
    @Input() popoverTitle;
    @Input() emptyArrayText = '-';
    @Input() nullText = '-';
    @Input() specialContent;
    cellContent: string;

    constructor(
        private addSPipe: AddPluralSPipe,
        ) { }

    ngOnInit(): void {
      if (!this.value?.length) {
        this.cellContent =  this.value?.length === 0 ? this.emptyArrayText : this.nullText;
        return;
      }
      if (this.specialContent) {
        this.cellContent = this.specialContent;
        return;
      }

      this.value = _orderBy(this.value, 'name');
      if(!this.plural) {
        this.cellContent = this.addSPipe.transform(this.value.length + ' ' + this.column);
      } else {
        this.cellContent = (this.value.length || 0) !== 1 ? `${this.value.length} ${this.plural}` : `${this.value.length} ${this.column}`;
      }
    }

}
