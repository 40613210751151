import { clone, pluck } from 'underscore';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { MandatoryTrainingService } from '../../../../services/mandatory-training.service';
import { ISelectorOption } from '@components/customised-multi-select/customised-multi-select.component';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CustomisedMultiSelectComponent } from '../../../../shared/components/customised-multi-select/customised-multi-select.component';
import { NgIf } from '@angular/common';
import { IShortcutFilter } from 'src/app/services/shortcut.service';
/**
 * Can be used as general multi-selector
 */
@Component({
    selector: 'app-training-plan-selector',
    templateUrl: './training-plan-selector.component.html',
    styleUrls: ['./training-plan-selector.component.scss'],
    standalone: true,
    imports: [CustomisedMultiSelectComponent, NgIf]
})
export class TrainingPlanSelectorComponent implements OnInit {
  @Input() controls;
  @Input() submitted;
  @Input() options: ISelectorOption[];

  @Input() hasTitle = true;
  @Input() title = 'Mandatory Training Plan';
  @Input() controlName = 'planIDs';
  @Input() placeholder = 'Select Training Plan';
  @Input() displayName = 'plan';
  @Input() width = 200;
  @Input() initValues?;
  @Input() borderFirstItem = false;
  @Input() prefilter: string[];
  @Input() autoGrow = true;
  @Input() showNumOnSelectAll = true;
  @Input() defaultAllSelected = false;
  @Input() allPreFilters: Partial<IShortcutFilter> // if passing all shortcut values

  // isLoading = true;
  destroy$ = new Subject();
  savedFilter$ = this.broadcastService.on('saved-filter-applied');

  constructor(
    private broadcastService: BroadcastService,
  ) {
  }

  ngOnInit(): void {
    if (this.defaultAllSelected) {
      // this.reset()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // reset when options change
    if (changes.options && !changes.options.firstChange) {
      // console.log(changes.options);
      this.reset();
    }
    if (changes.prefilter && this.prefilter) {
      this.prefilter = this.prefilter.filter(id => pluck(this.options, 'value').includes(id));
      this.controls[this.controlName].patchValue(this.prefilter);
    }
    if (changes.allPreFilters && this.allPreFilters && this.allPreFilters[this.controlName]) {
      this.prefilter = this.allPreFilters[this.controlName].filter(id => pluck(this.options, 'value').includes(id));
      this.controls[this.controlName].setValue(this.prefilter);
    }
  }


  reset() {
    if (this.initValues) {
      this.controls[this.controlName].setValue(clone(this.initValues));
      return;
    }
    this.controls[this.controlName].setValue(this.options.map(p => p.value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
