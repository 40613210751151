import { Dictionary } from 'underscore';
import { timer } from 'rxjs';
import { take, delayWhen } from 'rxjs/operators';

export class ScriptLoader {

  // record of all loaded scripts
  private readonly _loaded: Dictionary<boolean> = {};
  // promises of all pending scripts
  private readonly _pending: Dictionary<Promise<boolean>> = {};

  constructor() { }

  /**
   * load JS file dynamically with caching
   * @param url
   * @param refresh
   * @return Promise that resolves to true if the script has just loaded, false if it was already cached
   */
  // can also use this in the future: this.$q.resolve(import(url)) - currently only supported by Chrome
  loadJS(url: string, refresh?: boolean, delay?: boolean): Promise<boolean> {
    if (!refresh) {
      if (this._pending[url]) {
        // console.log('script pending');
        return this._pending[url];
      } else if (this._loaded[url]) {
        // console.log('script already loaded');
        return Promise.resolve(false);
      }
    }

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', url);
    script.setAttribute('async', '');
    // document.head.appendChild(script);
    let d = true;
    if (delay === true || delay === undefined) {
      d = true;
    } else {
      d = false;
    }
    return this._pending[url] = this._getLoadPromise(script, d)
        .then(() => {
          delete this._pending[url];
          this._loaded[url] = true;
          return true;
        })
        .catch((e) => {
          delete this._pending[url];
          return Promise.reject(e);
        });
  }

  /**
   * load CSS file dynamically with caching
   * @param url
   * @param refresh
   * @return Promise that resolves to true if the CSS has just loaded, false if it was already cached
   */
  loadCSS(url: string, refresh?: boolean, delay?: boolean): Promise<boolean> {
    if (!refresh) {
      if (this._pending[url]) {
        // console.log('script pending');
        return this._pending[url];
      } else if (this._loaded[url]) {
        // console.log('script already loaded');
        return Promise.resolve(false);
      }
    }

    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('async', '');
    link.setAttribute('href', url);
    // document.head.appendChild(link);

    let d = true;
    if (delay === true || delay === undefined) {
      d = true;
    } else {
      d = false;
    }

    return this._pending[url] = this._getLoadPromise(link, d)
        .then(() => {
          delete this._pending[url];
          this._loaded[url] = true;
          return true;
        })
        .catch((e) => {
          delete this._pending[url];
          return Promise.reject(e);
        });
  }
  /**
   * wraps onload and onerror functions of element into a Promise
   * @param ele
   */
  private _getLoadPromise(ele: HTMLElement, delay: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      timer(delay ? 3000 : 0).pipe(take(1)).subscribe(() => {
        document.head.appendChild(ele);
        ele.onload = (_) => resolve();
        ele.onerror = (evt) => reject(evt);
      });
    });
  }
}

export const scriptLoader = new ScriptLoader();
