import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import { take, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { IGuideline, ResourceService } from 'src/app/services/resource.service';
import { groupBy as _groupBy, orderBy as _orderBy, sortBy as _sortBy, keyBy as _keyBy } from 'lodash';
import { FRAMEWORKS_ENUM } from 'src/app/pages/settings/interfaces/IOrgLMSSetting';
import { FRAMEWORK_DICT } from 'src/app/pages/settings/standards-settings/configs/compliance-frameworks.config';
import { UISpinnerComponent } from '../../../ui/ui-spinner/ui-spinner.component';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgFor } from '@angular/common';
import { AddPluralSPipe } from '../../pipes/add-plural-s.pipe';
import { IOrgStandard, OrgFrameworksService } from 'src/app/services/org-frameworks.service';
import { combineLatest } from 'rxjs';
import { flatten } from 'underscore';

type DisplayedStandard = {
  provider: FRAMEWORKS_ENUM | string,
  standardName: string,
  url?: string,
  parentGuidelineID?: string,
  guidelineID: string,
  guidelineType?: string
}

@Component({
  templateUrl: 'standards-popover-cell.component.html',
  styleUrls: [`standards-popover-cell.component.scss`],
  standalone: true,
  imports: [
    NgIf,
    NgbPopover,
    NgFor,
    NgbTooltip,
    UISpinnerComponent,
    AddPluralSPipe
  ],
})

export class StandardsPopoverCellComponent implements OnInit {
  @Input() value: [];
  @Input() rowData: any;
  @Input() noContainer = false;
  isLoading = true;
  displayedNum: number;

  FRAMEWORK_DICT = FRAMEWORK_DICT;
  relatedOrgStandards: IOrgStandard[];
  relatedStandards: IGuideline[];
  displayedStandards: DisplayedStandard[] = [];
  displayedOrgStandards: DisplayedStandard[] = [];


  constructor(
    private resourceService: ResourceService,
    private orgFrameworksService: OrgFrameworksService,
  ) {
  }

  ngOnInit() {
    const standardIDs = this.rowData.fn_relatedStandards || [];
    const orgStandardIDs = this.rowData.fn_relatedOrgStandards || [];
    // calculate the available standards num
    combineLatest([this.resourceService.fetchCachedGuidelines(), this.orgFrameworksService.fetchCachedOrgStandardFrameworks()])
      .pipe(
        take(1)
      ).subscribe(() => {
        this.relatedStandards = standardIDs.map(id => this.resourceService.guidelineDict[id]).filter(guideline => guideline && this.resourceService.isEnabledGuideline(guideline));
        this.relatedOrgStandards = orgStandardIDs.map(id => this.orgFrameworksService.orgStandardsDictByStandardID[id]).filter(s => !!s) || [];
        this.displayedNum = (this.relatedStandards.length || 0) + (this.relatedOrgStandards.length || 0);
        this._preprocessData();
      });
  }

  _preprocessData() {
    if (this.relatedStandards.length) {
      this.relatedStandards.forEach(standard => {
        this.displayedStandards.push({
          standardName: standard.name,
          url: standard.url,
          provider: standard.provider,
          parentGuidelineID: standard?.parentGuidelineID,
          guidelineID: standard.guidelineID,
          guidelineType: standard.guidelineType
        });
      });
      this.displayedStandards = this._groupAndOrder(this.displayedStandards);
    }
    if (this.relatedOrgStandards.length) {
      this.relatedOrgStandards.forEach(orgStandard => {
        this.displayedOrgStandards.push({
          standardName: orgStandard.name,
          provider: orgStandard.frameworkName,
          guidelineID: orgStandard.standardID
        });
      });
      this.displayedOrgStandards = this._groupAndOrder(this.displayedOrgStandards, 'org');
    }
    this.isLoading = false;
  }

  private _groupAndOrder(items: DisplayedStandard[], standardSource = 'ausmed') {
    let sortedItems = [];
    const groupedObj = _groupBy(items, (i) => (i.provider));

    for (const key of Object.keys(groupedObj)) {
      if (standardSource === 'ausmed') {
        const standards = _sortBy((groupedObj[key].filter(s => s.guidelineType === 'standard') || []), 'standardName');
        const outcomes = _sortBy((groupedObj[key].filter(s => s.guidelineType === 'outcome') || []), 'standardName');
        const result = [];
        standards.forEach(s => {
          result.push(s);
          result.push(outcomes.filter(o => o.parentGuidelineID === s.guidelineID));
        })
        sortedItems.push(flatten(result));
      } else {
        sortedItems.push(_sortBy(groupedObj[key], [function (o) { return o.standardName; }]));
      }

    }
    sortedItems = _sortBy(sortedItems, [function (o) { return o[0].provider; }]);
    return sortedItems;
  }
}
