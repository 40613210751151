<ui-modal-single [tabs]="isMeetingWithTextBox() ? TEXTBOX_MEETING_TAB_ITEMS : TAB_ITEMS" (tabClick)="currentTab.set($event)" (closeClick)="onCloseClicked()"
    [overrideCloseButton]="true" [scrollable]="true">
    <ng-template #headingSlot>
        <div class="d-flex gap-2 align-items-center">
            <!-- <app-task-title-avatar [taskType]="task()?.taskTemplate?.taskType"></app-task-title-avatar> -->
            <h3>{{task()?.taskTemplate?.title}}</h3>
        </div>
    </ng-template>

    <ng-template #contentSlot>
        <app-loading-container [isLoaded]="task()">
            <div>
                @if(task()) {
                <!-- <div class="border-bottom">
                        <app-navigation-tabs [items]="TAB_ITEMS" (tabClick)="currentTab.set($event)"></app-navigation-tabs>

                    </div> -->
                <div class="d-flex">


                    <!-- @if(task().status === 'Completed') {
                            <div>
                                <app-success-banner [copy]="'Comments are successfully saved and this task is complete'"></app-success-banner>
                            </div>
                        } -->

                    <!-- <div>
                            <p class="f-small fw-medium text-neutral-700 mb-1">Reviewee</p>
                            <staff-link-display [value]="task().user" avatarSize="md"></staff-link-display>
                        </div> -->

                    <!-- @if(task().taskTemplate?.completionType === 'Both') {
                            <div class="card children-horizontal-gap-4 p-4">
                                <p class="text-neutral-700 fw-semibold f-small">{{completionNum}}/2 Complete</p>
                                <div class="d-flex gap-4 align-items-center">
                                    <ui-streamline-icon
                                    [slIcon]="circle1Icon"
                                    ></ui-streamline-icon>
                                    <p class="fw-semibold">{{item1Text}}</p>
                                </div>
                                <div class="d-flex gap-4 align-items-center">
                                    <ui-streamline-icon
                                    [slIcon]="circle2Icon"
                                    ></ui-streamline-icon>
                                    @if (task().response) {
                                        <p class="fw-semibold">{{item2Text}}</p>
                                    } @else {
                                        <div>
                                            <p class="fw-semibold text-neutral-700 mb-2">Manager is required to comment on self-reflection response</p>
                                            <p class="f-small text-neutral-700">Reviewee must send their response for manager to comment</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        } -->
                    <div [hidden]="currentTab() !== 0" class="flex-grow-1 task-modal-height overflow-y-auto">
                        @if(task().taskTemplate?.completionType === 'Both' && !task().response) {
                        @if(task().status==='Skipped'){
                          <div class="p-8">
                            <p class="f-small text-neutral-700 fst-italic">No responses are available as the self-assessment was skipped.</p>
                          </div>
                        }@else {
                        <!-- <p class="fw-semibold">{{task().user.fullName}} has not completed this self assessment</p> -->
                        <div class="d-flex flex-column justify-content-between align-items-end"
                            [style.min-height]="'50vh'">
                            <div class="w-100">
                                <app-warning-banner
                                    [copy]="task().user?.fullName + ' has not completed this self assessment'"
                                    color="blue">
                                    <button (click)="remind()" class="btn btn-outline-light">
                                        <ui-streamline-icon
                                            [slIcon]="{name: 'alarm-bell', theme: 'bold', svgClassIn: 'text-warning'}"
                                            class="me-2"></ui-streamline-icon>
                                        Send Reminder</button>
                                </app-warning-banner>

                            </div>

                        </div>
                        }

                        } @else {
                        <div class="">
                            <app-task-manager-input [task]="task()" (taskStatusChanged)="commentSuccess($event)" (validForms)="validForms($event)"
                                [showCloseButton]="true"></app-task-manager-input>
                        </div>
                        }
                    </div>

                    <div [hidden]="currentTab() !== 1" class="flex-grow-1 m-7">
                        <app-task-info-card [task]="task()"></app-task-info-card>
                    </div>

                    <div [hidden]="currentTab() !== 2" class="flex-grow-1 m-7">
                      <div class="card p-4 bg-neutral-100 meeting-card-height"
                        [innerHTML]="templateDescription() || '-' | htmlSanitizer: 'html'">
                      </div>
                    </div>



                    <div class="border-start task-modal-side-container ">
                        <div class="p-4 task-modal-height overflow-y-auto ">
                            <app-collapse-horizontal>
                                <div class="task-modal-side ">
                                    <app-task-modal-side [task]="task()"></app-task-modal-side>
                                </div>
                            </app-collapse-horizontal>
                        </div>
                    </div>
                </div>

                }

            </div>
        </app-loading-container>
    </ng-template>

    <ng-template #rightSlot>
        @if(task()) {
        <div class="d-flex gap-2">
            <button (click)="onCloseClicked()" class="btn btn-outline-light">Close</button>
            <button *ngIf="canPublish()" (click)="onSaveAsDraft()" class="btn btn-secondary btn-outline-light"
                [disabled]="!canComment()"
                [ngbTooltip]="canComment() ? '' : 'Only this user’s Manager can save this task as draft'"
                tooltipClass="custom-tooltip"
            >Save as Draft</button>
            @if (canPublish()) {
            <app-processing-button (confirmClick)="submit()" [isLoading]="isLoading" [disabled]="!canComment()"
                [text]="task().taskTemplate.completionType === 'Both' ? 'Save & Add Comments' : 'Save & Mark as Complete'"
                btnClass="btn-success"
                [ngbTooltip]="canComment() ? '' : 'Only this user’s Manager can mark this task as complete'"
                tooltipClass="custom-tooltip">
            </app-processing-button>
            }
        </div>

        }

    </ng-template>
</ui-modal-single>
