<!-- <ui-modal-single>
  <ng-template #contentSlot> -->
    <div [ngClass]="contClasses" style="min-height: 100px">
      <!-- paragraph -->
      <ng-content select="[paragraph]"></ng-content>

      <!-- users table -->
      <ng-container>
        <div class="my-4">
          <span
            *ngIf="hideTable"
            (click)="onViewTable()"
            class="
              text-neutral-900
              f-paragraph
              fw-medium
              underline
              cursor-pointer
            "
            ><ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size:'xs'}" class="f-paragraph me-2"></ui-streamline-icon>View
            {{ selectedUsers.length > 1 ? selectedUsers.length + ' users' : 'user' }}
          </span>
          <span
            *ngIf="!hideTable"
            (click)="hideTable = !hideTable"
            class="
              text-neutral-900
              f-paragraph
              fw-medium
              underline
              cursor-pointer
            "
          >
            <ui-streamline-icon [slIcon]="{name: 'interface-arrows-button-up', theme: 'micro-bold', size:'xs'}" class="me-2 f-paragraph"></ui-streamline-icon>
            Hide {{ selectedUsers.length > 1 ? selectedUsers.length + ' users' : 'user' }}
          </span>
        </div>
        <ng-container *ngIf="!hideTable">
          <div class="table-wrap my-4">
            <ng2-smart-table
              [settings]="tableSettings"
              [source]="tableSource"
            ></ng2-smart-table>
          </div>
        </ng-container>
      </ng-container>

      <!-- Add evidence block -->
      <ng-content select="[addEvidence]"></ng-content>
    </div>
    <ng-content> </ng-content>
  <!-- </ng-template>
</ui-modal-single> -->
