<div class="modal-body" style="min-height: 350px;">
    <div class="text-center mb-7">
        <h2 class="mb-2">Review Imported Users</h2>
    </div>
    <div class="mb-4">
        <p class="mb-2">Please check the users you have imported below against the key provided:</p>
        <div class="mb-1">
            <span class="badge badge-success me-2">NEW</span>
            <span class="f-small text-neutral-800">This user will be enrolled in the plan.</span>
        </div>
        <div class="mb-1">
            <span class="badge badge-warning me-2">DUPLICATE</span>
            <span class="f-small text-neutral-800">This user is already enrolled in the plan. As such, they will not be enrolled.</span>
        </div>
        <div class="mb-1">
            <span class="badge badge-danger me-2">ERROR</span>
            <span class="f-small text-neutral-800">We did not recognise this user. Please check their details are correct.</span>
        </div>
    </div>
    <div class="table-wrap table-overflow" >
        <ng2-smart-table [settings]="tableSettings" [source]="tableSource" (deleteConfirm)="onDeleteRow($event)" (editConfirm)="onEditRow($event)"></ng2-smart-table>
    </div>
</div>

<!-- FOOTER -->
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-outline-light" (click)="stepsControl.previousStep();"
        *ngIf="stepsControl.steps[stepsControl.currentIndex]?.allowBack">
        Back
    </button>
    <button class="btn btn-secondary" [disabled]="submitting" (click)="enrolUser()"><span *ngIf="submitting" style="color: inherit">
            <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
     </span>Enrol Users</button>
</div>
