<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div (click)="stopPropagation($event)" class="m-popover-displayed-text-container px-1 ms-2 bg-white" [ngbPopover]="popContent" placement="top">
    <ui-streamline-icon [slIcon]="{name: 'question-circle', theme: 'regular', size: 'sm'}"
        class="me-1 m-question-cricle"></ui-streamline-icon>
    <span class="f-caption fw-semibold">{{popoverData.displayText}}</span>
</div>

<ng-template #popContent>
    <div>
        <h4 class="pb-2">{{popoverData.popoverTitle}}</h4>
        <small class="d-block">{{popoverData.popoverText}}
        </small>
        <!-- <a href="javascript.void(0)" target="_blank" class="d-flex align-items-center">
            <small class="fw-semibold pe-1">Learn more about Passport Settings</small>
            <ui-streamline-icon
                [slIcon]="{name: 'interface-arrows-expand-5', theme: 'micro-bold', size: 'xs'}"></ui-streamline-icon>
        </a> -->
    </div>
</ng-template>