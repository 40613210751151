import { groupBy, indexBy } from 'underscore';
import { deepClone } from 'src/app/shared/utils/deepclone';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BroadcastService } from './broadcast.service';
import { ErrorHandlingService } from './error-handling.service';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DataCacher } from '../shared/utils/class/DataCacher';
import { UserService } from './user.service';
import { OrganisationService } from './organisation.service';
import { Dictionary } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OrgFrameworksService {
  private _orgID = localStorage.getItem('orgID');
  private _frameworksCacher: DataCacher<IOrgFramework[]> = new DataCacher<IOrgFramework[]>(this.fetchOrgFrameworks(), this.broadcastService);
  private _userID: string;
  enabledOrgFrameworkIDs: BehaviorSubject<string[]> = new BehaviorSubject([]);
  orgFrameworksDictByFrameworkID: Dictionary<IOrgFramework>;
  orgStandardsDictByFrameworkID: Dictionary<IOrgStandard[]>;
  orgStandardsDictByStandardID: Dictionary<IOrgStandard>;

  constructor(
    private httpClient: HttpClient,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService,
    private organisationService: OrganisationService,
  ) {
    const organisation$ = this.organisationService.organisation;
    organisation$.subscribe(organisation => {
      this.enabledOrgFrameworkIDs.next(organisation.metaData?.enabledOrgFrameworks || []);
    })
  }

  fetchOrgFrameworks(): Observable<IOrgFramework[]>{
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks`)
      .pipe(
        tap(orgFrameworks => {this.orgFrameworksDictByFrameworkID = indexBy(orgFrameworks, 'frameworkID')}),
        tap((orgFrameworks: IOrgFramework[]) => {
          const orgStandards = orgFrameworks.filter(f => f.orgStandards).map(f => f.orgStandards).flat();
          this.orgStandardsDictByFrameworkID = groupBy(orgStandards, 'frameworkID');
          this.orgStandardsDictByStandardID = indexBy(orgStandards, 'standardID');
        }),
        catchError(this.errorHandlingService.handleHttpError),
      ) as Observable<any>;
  }

  fetchCachedOrgFrameworks(refresh = false): Observable<IOrgFramework[]> {
    return this._frameworksCacher.fetchCachedData(refresh)
  }

  enableOrDisableOrgFrameworks(){
    const organisation = deepClone(this.organisationService.organisation.value);
    const enabledOrgFrameworkIDs = deepClone(this.enabledOrgFrameworkIDs.value);
    if(!organisation['metaData']){
      organisation['metaData'] = {};
    }
    organisation.metaData['enabledOrgFrameworks'] = enabledOrgFrameworkIDs;
    return this.organisationService.updateOrganisation(organisation).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    )
  }

  getEnabledFrameworks(): Observable<IOrgFramework[]>{
    const enabledOrgFrameworkIDs = deepClone(this.enabledOrgFrameworkIDs.value);
    return this.fetchCachedOrgFrameworks().pipe(
      map((orgFrameworks: IOrgFramework[]) => {
        return orgFrameworks.filter(f => enabledOrgFrameworkIDs.includes(f.frameworkID));
      })
    )
  }

  getEnabledOrgStandards(): Observable<IOrgStandard[]>{
    const enabledOrgFrameworkIDs = deepClone(this.enabledOrgFrameworkIDs.value);
    return this.fetchCachedOrgFrameworks().pipe(
      map((orgFrameworks: IOrgFramework[]) => {
        const enabledOrgStandards = orgFrameworks.filter(f => enabledOrgFrameworkIDs.includes(f.frameworkID)).filter(f => f.orgStandards).map(f => f.orgStandards).flat();
        return enabledOrgStandards;
      })
    )
  }

  getOrgStandardsByResourceID(resourceID: string): Observable<IOrgStandard[]>{
    return combineLatest([
      this.getEnabledOrgStandards(),
      this.httpClient.get(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgStandardRelations/resources/${resourceID}`)
    ])
    .pipe(
      map(([orgStandards, relation]: [IOrgStandard[], IOrgStandardResourceRelation[]]) => {
        const enabledOrgStandardIDs = orgStandards.map(s => s.standardID);
        const enabledResourceOrgStandards = relation.filter(r => enabledOrgStandardIDs.includes(r.standardID)).map(r => this.orgStandardsDictByStandardID[r.standardID]);
        return enabledResourceOrgStandards;
      })
    )
  }

  createOrgStandardsResourceRelation(resourceID: string, orgStandards: string[]): Observable<IOrgStandardResourceRelation[]>{
    const payload = orgStandards.map(standardID => ({
      standardID: standardID,
      resourceID: resourceID,
      orgID: this._orgID,
      providerName: 'Internal',
    }))
    return this.httpClient.post(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgStandardRelations/resources/${resourceID}`, payload).pipe(
      catchError(this.errorHandlingService.handleHttpError),
    ) as Observable<any>;
  }

  createOrgFramework(frameworkPayload: IOrgFrameworkForm): Observable<IOrgFramework> {
    return this.userService.getUser().pipe(
      take(1),
      switchMap(u => {
        this._userID = u.userID;
        return this.httpClient
          .post(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks?userID=${this._userID}`, frameworkPayload)
          .pipe(
            catchError(this.errorHandlingService.handleHttpError),
          ) as Observable<any>;
      })
    )
  }

  updateOrgFramework(frameworkPayload: IOrgFrameworkForm, frameworkID: string): Observable<IOrgFramework> {
    return this.userService.getUser().pipe(
      take(1),
      switchMap(u => {
        this._userID = u.userID;
        return this.httpClient
          .put(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks/${frameworkID}?userID=${this._userID}`, frameworkPayload)
          .pipe(
            catchError(this.errorHandlingService.handleHttpError),
          ) as Observable<any>;
      })
    )
  }

  deleteOrgFramework(orgFrameworkID: string): Observable<any>{
    // disable the framework and then, delete it
    this.enabledOrgFrameworkIDs.next(this.enabledOrgFrameworkIDs.value.filter(id => id !== orgFrameworkID));
    return this.enableOrDisableOrgFrameworks().pipe(
      take(1),
      switchMap(() => (
        this.httpClient
        .delete(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks/${orgFrameworkID}`)
        .pipe(
          catchError(this.errorHandlingService.handleHttpError),
        ) as Observable<any>
      ))
    );
  }

  createOrgStandards(orgStandardsPayload: IOrgStandardForm[], orgFrameworkID: string): Observable<IOrgStandard[]> {
    return this.userService.getUser().pipe(
      take(1),
      switchMap(u => {
        this._userID = u.userID;
        return this.httpClient
          .post(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks/${orgFrameworkID}/orgStandards?userID=${this._userID}`, orgStandardsPayload)
          .pipe(
            catchError(this.errorHandlingService.handleHttpError),
          ) as Observable<any>;
      })
    )
  }

  updateOrgStandard(orgStandardsPayload: IOrgStandardForm, orgFrameworkID: string, orgStandardID: string): Observable<IOrgStandard> {
    return this.userService.getUser().pipe(
      take(1),
      switchMap(u => {
        this._userID = u.userID;
        return this.httpClient
          .put(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks/${orgFrameworkID}/orgStandards/${orgStandardID}?userID=${this._userID}`, orgStandardsPayload)
          .pipe(
            catchError(this.errorHandlingService.handleHttpError),
          ) as Observable<any>;
      })
    )
  }

  deleteOrgStandard(orgFrameworkID: string, orgStandardID: string): Observable<IOrgStandard> {
    return this.userService.getUser().pipe(
      take(1),
      switchMap(u => {
        this._userID = u.userID;
        return this.httpClient
          .delete(`${environment.accountServiceEndpoint}/orgs/${this._orgID}/orgFrameworks/${orgFrameworkID}/orgStandards/${orgStandardID}?userID=${this._userID}`, {body: []})
          .pipe(
            catchError(this.errorHandlingService.handleHttpError),
          ) as Observable<any>;
      })
    )
  }
}

export interface IOrgFrameworkForm {
  orgID: string,
  name: string,
  description?: string,
}
export interface IOrgFramework extends IOrgFrameworkForm {
  frameworkID: string,
  createdBy?: string,
  orgStandards?: IOrgStandard[];
}

export interface IOrgStandardForm {
  orgID: string,
  frameworkID: string,
  name: string,
}
export interface IOrgStandard extends IOrgStandardForm {
  standardID: string,
  createdBy?: string,
  frameworkName?: string,
  parentStandardID?: string,
  parentStandard?: IOrgStandard // for development only, not in API
  standardType: string, // standard, skill

}

export interface IOrgStandardResourceRelationForm {
  standardID: string,
  resourceID: string,
  orgID: string,
  providerName: string,
}
export interface IOrgStandardResourceRelation extends IOrgStandardResourceRelationForm{
  relationID: string,
}
