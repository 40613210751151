import { Component, Injector, Input, OnInit } from '@angular/core';
import { ITableSettings } from '@components/smart-table/smart-table.component';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';
import { IOrgAssignDueExtension } from 'src/app/services/org-assign.service';
import { SmartTableColumnsService } from 'src/app/services/smart-table-columns.service';
import { UserService } from 'src/app/services/user.service';
import { downloadCSVBySourceAndSettings, IDownloadCSVExtensibleConfig } from 'src/app/shared/utils/export-csv';
import { Base } from 'src/app/shared/utils/mixins/clearRoute';
import { HasBasicServices } from 'src/app/shared/utils/mixins/hasBasicServices';
import { LocalDatePipe } from '../../../../pipes/local-date.pipe';
import { SmartTableComponent } from '../../../smart-table/smart-table.component';
import { DownloadCsvButtonComponent } from '../../../table-utilities/download-csv-button/download-csv-button.component';

@Component({
    selector: 'app-extensions-cell-table',
    templateUrl: './extensions-cell-table.component.html',
    styleUrls: ['./extensions-cell-table.component.scss'],
    standalone: true,
    imports: [DownloadCsvButtonComponent, SmartTableComponent, LocalDatePipe]
})
export class ExtensionsCellTableComponent extends HasBasicServices(Base) implements OnInit {
  @Input() extensions: IOrgAssignDueExtension[];
  @Input() originDueDate: string;

  tableSettings: ITableSettings = {
    actions: false,
    hideSubHeader: true,
    columns: {
      number: {
        title: 'Number',
      },
      user: {
        ...this.smartTableColumnService.getColumns(['user'], {local: true}).user,
        title: 'Extended by Manager'
      },
      extensionCreateDate: {
        title: 'Was Extended On',
        ...this.smartTableColumnService.dateFunctions
      },
      ...this.smartTableColumnService.getColumns(['extendedDueDate'], {local: true})
    }
  }
  tableSource = new LocalDataSource();

  constructor(
    private smartTableColumnService: SmartTableColumnsService,
    private userService: UserService,
    injector: Injector
  ) {
    super(injector)
   }

  ngOnInit(): void {
    const l = this.extensions.length
    const data = this.extensions.map((e, idx) => ({
      number: l - idx,
      user: this.userService.managedOrgUserDictionaryByUserID[(e.manager)],
      extensionCreateDate: e.createDate,
      extendedDueDate: e.extensionDueDate
    }));
    this.tableSource.load(data);

  }

  downloadCSV() {
    const config: IDownloadCSVExtensibleConfig = {
      source: this.tableSource,
      settings: this.tableSettings,
      csvTitle: 'Extension History',
      csvFileName: 'Extension History.csv',
      extractObjectFieldsByKey: [{field: 'user', fieldKey: 'fullName'}]
    };
    downloadCSVBySourceAndSettings(config);
    this.toastrService.success('File Downloaded');
  }

}
