<app-loading-container [isLoaded]="isLoaded">
    <div *ngIf="shortcuts$ | async as shortcuts">
        <div class="d-flex justify-content-between mb-2">
            <h3>Recently Saved {{this.shortcutType?.includes('Report') ? 'Reports' : 'Filters'}}</h3>
            <button class="btn btn-link" [routerLink]="[link]">
               <span class="fw-semibold">View All</span>
            </button>
        </div>
        
        <ng-container *ngIf="shortcuts?.length; else noData">
            <div class="d-flex flex-wrap column-gap-10 row-gap-4">
                <div *ngFor="let shortcut of shortcuts" >
                    <a [routerLink]="shortcut.routerLink" [queryParams]="shortcut.queryParams">
                    <div class="d-flex align-items-center gap-2">
                        <ui-streamline-icon class="text-pink-700" [slIcon]="{name: 'interface-bookmark', theme: 'micro-bold'}"></ui-streamline-icon>
                        <div>
                            <p class="fw-semibold">{{(shortcut.title | slice:0:30) + (shortcut.title?.length > 30 ? '...' : '')}}</p>
                            <p class="f-caption">{{shortcut.displayLocation}}</p>
                        </div>
                    </div>
                    </a>
                </div>
            </div>        
        </ng-container>
    </div>
</app-loading-container>

<ng-template #noData>
    
    <p class="f-small text-neutral-700 fw-medium">
        <ui-streamline-icon [slIcon]="{ name: 'bookmark', theme: 'core-line' }"></ui-streamline-icon>
        There are no Saved Filters in this space. Save a filter in your favourite search to streamline your future searches</p>
</ng-template>
