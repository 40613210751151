<div class="d-flex gap-1 align-items-center">
    <app-avatar-requirements [size]="'mds'"></app-avatar-requirements>
    <div style="width: 450px;">
        <app-truncated-text [value]="value">
          <span class="fw-semibold f-small">{{value}}</span>
        </app-truncated-text>
        <!-- <p class="text-neutral-700 f-caption">Required by {{rowData.mtPlanCell?.assignerName || rowData.mtPlanCell?.planTitle}}</p> -->
    </div>

</div>

