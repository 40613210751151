import { Directive, HostBinding, Input, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[appInputError]',
    standalone: true
})
export class InputErrorDirective {
  @Input() @HostBinding('class.is-invalid') showError: boolean;
  @Input() inputModel: NgModel;


  constructor() { }


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.inputModel?.statusChanges.subscribe(() => {
      this.showError = this.inputModel.errors && !this.inputModel.pristine;
    });
  }

}
