import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralSidePanelCellComponent } from '@components/table-cell/general-side-panel-cell/general-side-panel-cell.component';
import { IOrgAssign } from 'src/app/services/org-assign.service';
import { SidePanelComplianceRecordSectionComponent } from 'src/app/shared/popups/shared-compliance-impact-cell/side-panel-compliance-record-section/side-panel-compliance-record-section.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PayrollGroupItemCellModalComponent } from './payroll-group-item-cell-modal/payroll-group-item-cell-modal.component';

@Component({
  selector: 'app-payroll-group-item-cell',
  standalone: true,
  imports: [CommonModule, GeneralSidePanelCellComponent, SidePanelComplianceRecordSectionComponent],
  templateUrl: './payroll-group-item-cell.component.html',
  styleUrls: ['./payroll-group-item-cell.component.scss']
})
export class PayrollGroupItemCellComponent {
  @Input() value;
  @Input() rowData: {assigns: IOrgAssign[]};

  constructor(
    private modalService: NgbModal
  ) {}

  openModal() {
    const modal: PayrollGroupItemCellModalComponent = this.modalService.open(PayrollGroupItemCellModalComponent, {size: 'xl'}).componentInstance;
    modal.tableData = this.rowData.assigns;
  }

  get buttonText() {
    return `See all items (${this.rowData?.assigns?.length || 0})`
  }

}
