var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
export function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);

//   var x=window.scrollX;
//   var y=window.scrollY;
//   window.onscroll=function(){window.scrollTo(x, y);};
}

// call this to Enable
export function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, );
  window.removeEventListener('touchmove', preventDefault, );
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);

//   window.onscroll=function(){};
}

export function toggleScroll(event) {
    if (event === true) {
        setTimeout(() => {
            // console.log(dropdown.isOpen());
            // console.log(event);
            disableScroll();
        });
        // setInterval(() => {
        //     console.log(dropdown);
        //     if (!dropdown.isOpen()) {
        //         console.log('none foune');
        //         enableScroll();
        //         // this.clearInterval();
        //     }
        // }, 1000);
    } else {
        enableScroll();
    }
}
