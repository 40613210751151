export const BOOKING_SYSTEM_ORG_LIST = [
  'e76170da-5562-4cf7-9065-6afbfce77f9a', // (staging for testing) shc
  '7d25a6d7-c66a-4493-a43a-4d2d730915a8', // irtlms
  // '0ab9787b-5f1b-407d-881d-37df52842e4b' // demo
]

export const PERFORMANCE_REVIEW_SURVEY_ORG_LIST = [
  'e76170da-5562-4cf7-9065-6afbfce77f9a', // (staging for testing) shc
  'bc0fca93-a59c-4bf2-896d-087ca14d7caa', // autumn aged care - AUTM
  '0ab9787b-5f1b-407d-881d-37df52842e4b' // demo
]

export const ANALYTICS_REPORT_ORG_LIST = [
  'e76170da-5562-4cf7-9065-6afbfce77f9a', // (staging for testing) shc
  '7c913dad-b945-4d50-b3f2-56a2f83f078e', //AUSMED
  '0ab9787b-5f1b-407d-881d-37df52842e4b', // demo
  // Form real org demo
  'f222d2e6-8f99-4f2a-87e1-e84f568d7d57',
  'df9d6b71-2bbb-415c-b650-3a9b8cf734d3',
  '398fb160-b2df-45c1-a154-73024c5a5275',
  '5a0e24c5-87fe-42d2-a9fc-ae6a2567dade',
  '19342e66-dcda-436e-9dc0-5564ec7ef1df',
  'bc0fca93-a59c-4bf2-896d-087ca14d7caa',
  '5eecf3b7-dcca-4923-a976-801345171f00',
  'fd55bc00-edd1-4fce-ab67-e9c64c6aaff2',
  '3b3898b5-dfc2-4315-930b-de8d5a214eea' // INFCARE
]

export const SHOW_FUTURE_JOB_ROLES_LIST = [
  'e76170da-5562-4cf7-9065-6afbfce77f9a', // (staging for testing) shc
  '7d25a6d7-c66a-4493-a43a-4d2d730915a8', // irtlms
]

export function orgInTheFeatureOrgList(list: string[]): boolean {
  const orgID = localStorage.getItem('orgID');
  return list.includes(orgID);
}

export const PERFORMANCE_REVIEW_ORG_LIST = [
  'e76170da-5562-4cf7-9065-6afbfce77f9a', // (staging for testing) shc
  '0ab9787b-5f1b-407d-881d-37df52842e4b', // demo
  'bc0fca93-a59c-4bf2-896d-087ca14d7caa', // AUTM
  '398fb160-b2df-45c1-a154-73024c5a5275', // INTLIV
  '7c913dad-b945-4d50-b3f2-56a2f83f078e', // AUSMED
  '26be8d70-44f7-469a-a9c8-27945f5a4a65' // KNH
]

export function showPerformance() {
  return orgInTheFeatureOrgList(PERFORMANCE_REVIEW_ORG_LIST);
}


