import { Observable, of } from 'rxjs';
import { tap, finalize, share } from 'rxjs/operators';
import { arraysEqual } from '../array/arrays-equal';

/**
 * Higher order function that takes an async `originalFn` and returns a new function.
 * This new function memoises the emitted value of `originalFn`.
 * Cache size is only one, so any call with different arguments will overwrite the cache.
 *
 * @param checkArgs number of arguments used for caching
 */
export function cacheResponse2<
    A extends any[], // argument array
    V, // emitted value
    >(
    originalFn: (...args: A) => Observable<V>,
    checkArgs?: number,
    uncache$?: Observable<any>,
): CachedFunction2<A, V> {

  const wrapperFn = function(refresh?: boolean, ...args: A) {

    const cachedArgs = checkArgs >= 0 ? args.slice(0, checkArgs) : args;

    // console.log('💙', { cachedArgs, args, prevCachedArgs: wrapperFn.args });

    // find out if we should return a cached value or pending observable
    if (!refresh && arraysEqual(cachedArgs, wrapperFn.args)) {
      if (wrapperFn.cached) {
        return of(wrapperFn.value);
      } else if (wrapperFn.pending$) {
        return wrapperFn.pending$;
      }
    }
    // invalidate previous cache, if any
    wrapperFn.cached = false;
    delete wrapperFn.value;
    // save args
    wrapperFn.args = cachedArgs;
    return wrapperFn.pending$ = originalFn(...args).pipe(
        tap((res) => {
          wrapperFn.value = res;
          wrapperFn.cached = true;
        }),
        finalize(() => delete wrapperFn.pending$),
        share(),
    );
  } as CachedFunction2<A, V>;
  wrapperFn.clearCache = () => {
    wrapperFn.cached = false;
    wrapperFn.value = null;
    wrapperFn.args = null;
    wrapperFn.pending$ = null;
    // console.log('cleared', wrapperFn);
  };
  if (uncache$) {
    uncache$.subscribe(wrapperFn.clearCache);
  }
  return wrapperFn;
}

/**
 * Async Function with cached value
 */
export type CachedFunction2<
    A extends Array<any>,
    V,
    > = ((refresh?: boolean, ...args: A) => Observable<V>) & {
  cached?: boolean;
  args?: any[];
  value?: V;
  pending$?: Observable<V>;
  clearCache(): void;
};
