import {Injectable, OnDestroy} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

export interface PreserveOnceBroadcastEvent <P> {
  key: string;
  payload: P;
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService implements OnDestroy {

  private _eventBus: Subject<BroadcastEvent>;
  /* [Tech Debt] only temporary solution; the same as above but replyValueOnce with correct props */
  private _preserveOnceEventBus = new BehaviorSubject<PreserveOnceBroadcastEvent<unknown>>(null);

  constructor() {
    this._eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    this._eventBus.next({key, data});
  }

  broadcastPreserveOnce<P>(event: PreserveOnceBroadcastEvent<P>) {
    this._preserveOnceEventBus.next(event);
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus.asObservable()
        .pipe(
            filter(event => event.key === key),
            map(event => <T> event.data)
        );
  }

  onPreserveOnce<P>(key: string): Observable<P> {
    return this._preserveOnceEventBus.asObservable()
        .pipe(filter(event => event && (event.key === key)),
            map<PreserveOnceBroadcastEvent<P>, P>(event => event.payload));
  }

  ngOnDestroy(): void {
    this._eventBus.complete();
  }

  getPreserveOnceEventBusValue() {
    return this._preserveOnceEventBus.value;
  }
}

interface BroadcastEvent {
  key: any;
  data?: any;
}


