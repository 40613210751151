import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-truncated-text',
    templateUrl: './truncated-text.component.html',
    styleUrls: ['./truncated-text.component.scss'],
    standalone: true,
    imports: [NgbTooltip]
})
export class TruncatedTextComponent implements OnInit {
  showTooltip = false;
  @Input() value;
  @Input() doRecheck = false
  @ViewChild('text') text;


  constructor(
    private _cdr: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const el = this.text.nativeElement;
    if (el.scrollWidth > el.clientWidth) {
      this.showTooltip = true;
      this._cdr.detectChanges();
    }
  }

  ngAfterViewChecked(): void {
    if (this.doRecheck) {
      const el = this.text.nativeElement;
      if (el.scrollWidth > el.clientWidth) {
        this.showTooltip = true;
        this._cdr.detectChanges();
      }
    }
  }


}
