@if(showCard()) {
  <app-task-modal-goal-card [goal]="goal" (click)="onCardClicked($event)"></app-task-modal-goal-card>
} @else {

  <!-- xlarge view -->
  <div class="m-goal-item-xl btn" (click)="openGoalSidePanel()">
    <div class="d-flex align-items-center" style="width: 30%;">
      <ui-avatar class="me-2" [slIcon]="{name: 'business-climb-top', color: 'fill-white', theme: 'bold'}"
        [iconBackgroundColor]="'m-sea-dark'" [textColor]="'text-white'"></ui-avatar>
      <p class="fw-semibold f-small m-goal-title">{{goal?.title || '-'}}</p>
    </div>
  
    <div class="d-flex align-items-center justify-content-between" style="width: 615px;">
      <!-- <ui-avatar [fullName]="user?.userDetail?.fullName" size="sm" [url]="user?.userDetail?.profilePicURL" class="me-8"
        [textColor]="'text-white'" [bgColor]="'pink-600'">
      </ui-avatar> -->
  
      <staff-link-display [rowData]="user" [noLink]="true"></staff-link-display>
  
      <div class="d-flex justify-content-center align-items-center">
        @if(getGoalStatus() === 'inProgress'){
          <p class="f-caption">Due {{ displayedDate(goal?.endDateLocal) }} ·
          </p>
          <p class="badge ms-1 badge-light">
            In Progress
          </p>
        }@else {
          <p class="f-caption">Completed on {{ displayedDate(goal?.achieveDate) }} ·
          </p>
          <p class="badge ms-1 badge-success">
            Completed
          </p>
        }
        <button type="button" class="ms-8 btn btn-outline-light btn-sm" (click)="openGoalSidePanel()">Open</button>
      </div>
    </div>
  </div>
  
  <!-- large view -->
  <div class="m-goal-item-lg btn" (click)="openGoalSidePanel()">
    <div class="d-flex align-items-center" style="width: 40%;">
      <ui-avatar class="me-2" [slIcon]="{name: 'business-climb-top', color: 'fill-white', theme: 'bold'}"
        [iconBackgroundColor]="'m-sea-dark'" [textColor]="'text-white'"></ui-avatar>
      <p class="fw-semibold f-small m-goal-title">{{goal?.title || '-'}}</p>
    </div>
  
    <div class="d-flex align-items-center" style="width: 615px;">
      <!-- <ui-avatar [fullName]="user?.userDetail?.fullName" size="sm" [url]="user?.userDetail?.profilePicURL" class="me-8"
        [textColor]="'text-white'" [bgColor]="'pink-600'">
      </ui-avatar> -->
  
      <staff-link-display [rowData]="user" [noLink]="true"></staff-link-display>
  
      <div class="d-flex justify-content-center align-items-center">
        @if(getGoalStatus() === 'inProgress'){
          <p class="f-caption">Due {{ displayedDate(goal?.endDateLocal) }} ·
          </p>
          <p class="badge ms-1 badge-light">
            In Progress
          </p>
        }@else {
          <p class="f-caption">Completed on {{ displayedDate(goal?.achieveDate) }} ·
          </p>
          <p class="badge ms-1 badge-success">
            Completed
          </p>
        }
      </div>
    </div>
    <button type="button" class="ms-8 btn btn-outline-light btn-sm m-open-btn" (click)="openGoalSidePanel()">Open</button>
  </div>
}


<app-shared-sidebar (click)="stopPropagation($event)" #appSharedSidebar showHeaderUnderline="true" sidebarWidth="650px" [isLoading]="false">
  <ng-container sideBarBodySlot>
    <div *ngIf="open" class="p-4">
      <div class="d-flex mb-5 justigy-content-center align-items-center">
        <ui-avatar class="me-2" size="sm" [slIcon]="{name: 'business-climb-top', color: 'fill-white', theme: 'bold'}"
          [iconBackgroundColor]="'m-sea-dark'" [textColor]="'text-white'"></ui-avatar>
        <span class="text-neutral-700 f-small">Goal</span>
      </div>

      <h3 class="mb-2">{{goal.title}}</h3>
      <p class="mb-2 badge ms-1" [ngClass]="{'badge-light': getGoalStatus() === GOAL_STATUS.inProgress, 'badge-success': getGoalStatus() === GOAL_STATUS.completed}">
        {{getGoalStatus() === GOAL_STATUS.completed ? 'Completed' : 'In Progress'}}
      </p>
      <p class="text-neutral-700 f-small mb-1">Owned by <span class="text-neutral-900">{{user.userDetail?.fullName}}</span></p>
      <!-- <p class="text-neutral-700 f-small mb-1">Added by <span class="text-neutral-900">{{user.userDetail?.fullName}}</span></p> -->
      <p class="text-neutral-700 f-small mb-1">Added <span class="text-neutral-900">{{displayedDate(goal.startDateLocal)}}</span></p>
      <p class="text-neutral-700 f-small mb-1">Due <span class="text-neutral-900">{{displayedDate(goal.endDateLocal)}}</span></p>
      @if(getGoalStatus() === GOAL_STATUS.completed){
        <p class="text-neutral-700 f-small mb-1">Completed <span class="text-neutral-900">{{displayedDate(goal?.org?.orgAchieveDateLocal || goal?.achieveDate)}}</span></p>
      }

      <div class="d-flex w-100 justify-content-between">
        @if (goal.isAchieved) {
        <!-- <button class="btn btn-outline-light" style="width: 545px;" (click)="unmarkGoal()" [disabled]="!goal.isAchieved || isMarking()">
              <ui-spinner [hidden]="!isMarking()" size="sm"></ui-spinner>
              Mark as In Progress
            </button> -->
        } @else {
        <button class="btn btn-success" style="width: 545px;" (click)="markGoal()"
          [disabled]="goal.isAchieved || isMarking()">
          <ui-spinner [hidden]="!isMarking()" size="sm" color="light"></ui-spinner>
          Mark as Complete
        </button>
        }

        @if(!goal.isAchieved){
        <button class="btn btn-outline-light" (click)="editGoal()">Edit</button>
        }

      </div>






      <!-- <app-sidepanel-ack-table *ngIf="appSharedSidebar.showSidebar" [payload]="payload" [payloadName]="payloadName"></app-sidepanel-ack-table> -->
    </div>
  </ng-container>

</app-shared-sidebar>
