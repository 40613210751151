import { Component, signal } from '@angular/core';
import { ModalSingleComponent } from '@components/modal-single/modal-single.component';
import { CommonModule } from '@angular/common';
import { ConfirmationModal } from 'src/app/shared/utils/base-class/confirmation-modal';

@Component({
  selector: 'app-assign-table-basic-modal',
  standalone: true,
  imports: [ModalSingleComponent, CommonModule],
  templateUrl: './assign-table-basic-modal.component.html',
  styleUrl: './assign-table-basic-modal.component.scss'
})
export class TableModalBasicComponent extends ConfirmationModal {
  data = signal(null);
  tableComponent = signal(null);
  modalHeading = signal(null);

}
