import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-task-modal-completion-item',
  standalone: true,
  imports: [UIStreamlineIconComponent, NgbCollapse, DecimalPipe, CommonModule],
  templateUrl: './task-modal-completion-item.component.html',
  styleUrl: './task-modal-completion-item.component.scss'
})
export class TaskModalCompletionItemComponent {
  completionEntity = input.required<CompletionEntity>();
  completionNum = input.required<number>();
  preventExpand = input(false);
  noItems = input(false);

  cardTitle = computed(() => {
    const dict: Record<CompletionEntity, string> = {
      Learning: 'Learning Requirements',
      Policy: 'Policy Requirements',
      Performance: 'Performance Reviews',
      Feedback: 'Feedback',
      Goals: 'Goals'
    }
    return dict[this.completionEntity()] || this.completionEntity()
  });

  icon = computed<IUIStreamlineIcon>(() => {
    const dict: Record<CompletionEntity, IUIStreamlineIcon> = {
      Learning: {name: 'book-close-2', theme: 'bold'},
      Policy: { name: 'app-window-check', theme: 'bold' },
      Performance: {name: 'loading-3', theme: 'bold',},
      Feedback: {name: 'messages-bubble-square-alternative', theme: 'bold',},
      Goals: {"name": "business-climb-top", "theme": "bold",}
    };
    return {...dict[this.completionEntity()], size: 'sm'};
  })

  isCollapse = signal(true);

  onCollapseClick() {
    if (this.preventExpand() || this.noItems()) {
      return;
    }
    this.isCollapse.set(!this.isCollapse());
  }

}

export type CompletionEntity = 'Learning' | 'Policy' | 'Performance' | 'Feedback' | 'Goals'
