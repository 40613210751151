import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addPluralS',
    standalone: true
})
export class AddPluralSPipe implements PipeTransform {

  transform(value: string): string {
    const num = +value.split(' ')[0];
    if (num > 1 || num === 0) {
      if (value.toLocaleLowerCase().endsWith('policy')) {
        return value.replace('cy', 'cies');
      }
      if (value.toLocaleLowerCase().endsWith('ity')) {
        return value.replace('ity', 'ities');
      }
      // if (value.toLocaleLowerCase().endsWith('y')) {
      //   return value.replace('y', 'ies');
      // }
      return value + 's';
    }
    if (num === 1) {
      return value;
    }
    // for 0/null
    return '0 ' + value.split(' ').slice(1).join(' ');
  }

}
