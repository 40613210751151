<div [style]="'width: ' + maxWidth + 'px;'">
  <div #text class="d-flex align-items-center children-gap-2" >
        <!-- <ui-avatar size="sm" [slIcon]="{name: 'list-bullets', theme: 'bold'}"
        ></ui-avatar> -->
        <app-avatar-requirements iconType="list-bullets"></app-avatar-requirements>
        <div class="text-overflow text-truncate">
          <a
              [routerLink]="['/pages/compliance/mandatory-training/overview', trainingPlan.mtPlanID]"
              href="javascript:void(0);"  target="_blank"
              class="fw-medium text-neutral-900"
            >{{trainingPlan.title}}</a>
          </div>
  </div>
</div>
