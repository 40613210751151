import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WithDestroy } from 'src/app/shared/utils/mixins/withDestroy';
import { NgbDropdownComponent } from '@components/ngb-dropdown/ngb-dropdown.component';
import { NgbDropdownItem, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { IUIStreamlineIcon } from '@ui/streamline-icon/streamline-icon-interfaces';

@Component({
  selector: 'app-table-action-button',
  standalone: true,
  imports: [CommonModule, NgbDropdownComponent, NgbDropdownItem, NgbTooltipModule, UIStreamlineIconComponent],
  templateUrl: './table-action-button.component.html',
  styleUrls: ['./table-action-button.component.scss']
})
export class TableActionButtonComponent {
  @Input() text: string;
  @Input() rightBtnClass: string = 'outline-light';
  @Input() rowData;
  @Input() buttons: ITableActionButton[];
  @Input() showDropdown = false;
  @Input() floatEnd = true // puts the button at end of table
  @Input() textRight: string;
  @Input() disableMainActionFn?: (rowData) => boolean;

  @Output() actionClicked = new EventEmitter<{ rowData; action }>();

  dropdownButtonsAllHidden = signal(true);
  isMainActionDisabled = signal(false);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.disableMainActionFn) {
      this.isMainActionDisabled.set(this.disableMainActionFn(this.rowData));
    }

    this.buttons?.filter(b => b.disabledFn).forEach(b => {
      b.disabled = b.disabledFn(this.rowData);
    });
    this.buttons?.filter(b => b.tooltipFn).forEach(b => {
      b.tooltip = b.tooltipFn(this.rowData);
    });
    this.buttons?.filter(b => b.hideFn).forEach(b => {
      b.hide = b.hideFn(this.rowData);
    });

    (this.buttons || []).forEach(b => {
      if (!b.hide) {
        this.dropdownButtonsAllHidden.set(false);
      }
    })
  }

  onClick(action = '') {
    this.actionClicked.emit({ rowData: this.rowData, action });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.actionClicked.complete();
  }

}

export interface ITableActionButton {
  text: string; action: string; textClass?: string;
  disabledFn?: (rowData) => boolean; disabled?: boolean, tooltipFn?: (rowData) => string; tooltip?: string;
  hide?: boolean; hideFn?: (rowData) => boolean;
  icon?: IUIStreamlineIcon
}