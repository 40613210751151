import ColourMap from "@ui/colour-token-map";
import { color } from "echarts";
import { FEATURES } from "src/app/core/features.config";

export const REPORT_CARD_CONFIG = {
    complianceStatus: {
        title: 'All Mandatory (Compliance) Requirements',
        description: `Use this report to see if compliance requirements have been met or have not been met`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'graph-stats-circle-alternate', theme: 'bold'},
        iconBgColor: 'green-700',
        route: ['/pages/reporting/overview/requirement-records/mandatory-report/compliance']
    },
    mandatoryStatus: {
        title: 'All Mandatory (Non-Compliance) Requirements',
        description: `Use this report to see if mandatory (non-compliance) requirements have been met or have not been met`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'graph-stats-circle-alternate', theme: 'bold'},
        iconBgColor: 'green-700',
        route: ['/pages/reporting/overview/requirement-records/mandatory-report/noncompliance']
    },
    allOptionalStatus: {
        title: 'All Optional (Non-Compliance) Requirements',
        description: `Use this report to see when optional requirements are due and if they have been completed`,
        // featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'graph-stats-circle-alternate', theme: 'bold'},
        iconBgColor: 'green-700',
        route: ['pages/reporting/overview/requirement-records/optional']
    },
    standards: {
        title: 'Standards Report',
        description: `Use this report to see all learning completed in your organisation against the standards`,
        iconBgColor: 'yellow-700',
        route: ['/pages/reporting/overview/learning-records/standards-report'],
        reportSlIcon: {name: 'study-book', theme: 'bold'},
    },
    allLearning: {
        title: 'All Learning Records',
        description: `Use this report to see all learning records and when they were complete`,
        route: ['/pages/reporting/overview/learning-records/all-learning-records'],
        iconBgColor: 'yellow-700',
        reportSlIcon: {name: 'task-list-to-do', theme: 'bold'}
    },
    allRequirementStatus: {
        title: 'All Requirements',
        description: `Use this report to see when all requirements were completed in a given period`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'graph-stats-circle-alternate', theme: 'bold', color: 'fill-white'},
        iconBgColor: 'green-700',
        route: ['/pages/reporting/overview/requirement-records/all-requirements']
    },
    newRequirementStatus: {
        title: 'All Requirement States',
        description: `Use this report to see the Requirement States of your staff in your Organisation`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'notes-paper-approve', theme: 'bold'},
        iconBgColor: 'm-sky-dark',
        route: ['pages/reporting/overview/requirement-records/states/all']
    },
    complianceCompletions: {
        title: 'Compliance Requirement Completion Records',
        description: `Use this report to see when compliance requirements are due and if they have been completed`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'task-list-to-do', theme: 'bold'},
        iconBgColor: 'pink-700',
        route: ['pages/reporting/overview/completion-records/compliance']
    },
    allRequirementCompletions: {
        title: 'All Requirement Completion Records',
        description: `Use this report to see when requirements are due and if they have been completed`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'task-list-to-do', theme: 'bold'},
        iconBgColor: 'pink-700',
        route: ['pages/reporting/overview/completion-records/all']
    },
    extended: {
        title: 'Extended Requirements Report',
        description: `Use this report to track extended requirements`,
        featureFlag: FEATURES.MT,
        reportSlIcon: {name: 'calendar-add-1', theme: 'bold'},
        iconBgColor: 'neutral-700',
        route: ['pages/reporting/overview/extensions/extended-report']
    },
    optional: {
        title: 'All Optional Completions Records',
        description: `Use this report to see when optional requirements were completed`,
        // featureFlag: FEATURES.MT,
    },
    suggested: {
        title: 'Suggested By Users Report',
        description: `Use this report to see resources your users have suggested to the organisation`,
        featureFlag: FEATURES.suggestedByStaff,
        iconBgColor: 'lavender',
        route: ['/pages/reporting/overview/learning-evaluate/suggested-by-user'],
        reportSlIcon: {name: 'rating-star-alternate', theme: 'bold'}
    },
    feedback: {
        title: 'Feedback Report',
        description: `Use this report to analyse feedback provided by your staff`,
        route: ['/pages/reporting/overview/learning-evaluate/feedback-report'],
        iconBgColor: 'lavender',
        reportSlIcon: {name: 'rating-star-alternate', theme: 'bold'}
    },
    time: {
        title: 'Time Spent Learning',
        description: `Use this report to see the time spent learning by your organisation`,
        iconBgColor: 'yellow-700',
        route: ['/pages/reporting/overview/learning-records/time-spent-learning'],
        reportSlIcon: {name: 'study-book', theme: 'bold'}
    },
    learningAnalytics: {
        title: 'Learning Analytics Report',
        description: `Use this report to see visual representation of learning completed by your organisation`,
        iconBgColor: 'yellow-700',
        route: ['/pages/reporting/overview/learning-records/learning-analytics'],
        reportSlIcon: {name: 'data-file-bars-alternate', theme: 'bold'}
    },
    audit: {
        title: 'Audit Report',
        description: 'Use this report to generate a report for auditors.',
        route: ['/pages/reporting/overview/learning-evaluate/feedback-report'],
        reportSlIcon: {name: 'pie-line-graph', theme: 'bold'}

    },
    team: {
        title: 'Team Report',
        description: 'Use this report to understand and monitor a team’s performance.',
        route: ['/pages/reporting/overview/learning-evaluate/feedback-report'],
        reportSlIcon: {name: 'pie-line-graph', theme: 'bold'}
    },
    board: {
        title: 'Board Report',
        description: 'Use this report to communicate specific items to your board.',
        route: ['/pages/reporting/overview/learning-evaluate/feedback-report'],
        reportSlIcon: {name: 'pie-line-graph', theme: 'bold'}
    },
    passport: {
        title: 'Current Passport Holders',
        description: 'Use this report to see how many people hold Ausmed Passport in your organisation',
        route: ['/pages/reporting/overview/ausmed-passport/current-passport-holders'],
        iconBgColor: 'blue-700',
        reportSlIcon: {name: 'ausmed-passport', theme: 'bold'}
    },
    passportUser: {
        title: 'Find Users Who Joined with Passport',
        description: 'Use this report to see who joined with Ausmed Passport, and how much time was saved',
        route: ['/pages/reporting/overview/ausmed-passport/passport-user'],
        iconBgColor: 'blue-700',
        reportSlIcon: {name: 'ausmed-passport', theme: 'bold'}
    },
    payroll: {
        title: 'Payroll Report',
        description: `Use this report to see when learning was completed as well as learning duration to assist with Payroll purposes.`,
        featureFlag: FEATURES.MT,
        iconBgColor: 'pink-300',
        reportSlIcon: {name: 'file-copyright-dollar', theme: 'bold'},
        route: ['pages/reporting/overview/payroll/payroll']
    },

}
