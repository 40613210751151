// eg {..., user} => {..., firstName, lastName}
export function replaceObjectAttr(obj: Object, name: string, newAttrs: Object) {
    let ans = {};
    Object.keys(obj).forEach(k => {
        if (name === k) {
            ans = {
                ...ans,
                ...newAttrs
            }
        } else {
            ans[k] = obj[k];
        }
    });
    return ans;
}