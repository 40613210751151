<ui-modal-single [heading]="heading()">
    <ng-template #contentSlot>
        <div class="children-horizontal-gap-4">

            <div>
                @if(editedFeedback()) {
                <label class="mb-1" for="manager">Feedback given to</label>
                <staff-link-display [rowData]="editedFeedback().user"></staff-link-display>
                } @else {
                <!-- <app-selector-single-user label="Who do you want to give this feedback to?" [control]="form.controls.user"
                    [submitted]="submitAttempted()"></app-selector-single-user> -->
                <app-selector-multi-user label="Who do you want to give this feedback to?"
                    [control]="form.controls.users" [submitted]="submitAttempted()"></app-selector-multi-user>
                }
            </div>
            <div>
                <label class=" mb-1" for="content">Feedback</label>
                <ckeditor class="f-small" id="content" [formControl]="form.get('content')" [config]="EDITOR_CONFIG">
                </ckeditor>
                @if (submitAttempted() && form.get('content').invalid) {
                <small class="text-error-input">
                    Feedback is required
                </small>
                }
                <!-- @if(userName()) {
                    <p class="f-caption ">
                        <span class="text-m-blueberry-dark"><ui-streamline-icon [slIcon]="{ name: 'lock-1', theme: 'bold', size: 'sm' }"></ui-streamline-icon> Private</span>
                        Only {{userName()}} and their manager(s) will be able to see this feedback
                    </p>
                } -->
            </div>
            <div>
                <label class="mb-1" for="source">What was the source of this feedback?</label>
                <app-customised-single-select [options]="sourceOptions" [controls]="form.controls" controlName="source"
                    id="source"></app-customised-single-select>
                @if (submitAttempted() && form.get('source').invalid) {
                <small class="text-error-input">
                    Source is required
                </small>
                }
            </div>
            <mat-divider></mat-divider>
            <div>
                <div class="fw-semibold">Tags</div>
                <div class="text-neutral-700">These tags are not visible to the user receiving feedback</div>
            </div>
            <div>
                <label class="mb-1" for="sentiment">What is the sentiment of this feedback?</label>
                <div>
                    <ui-toggle-buttons [items]="sentimentOptions" [model]="form.controls.sentiment.value"
                        (modelChange)="form.controls.sentiment.setValue($event)"
                        [hasFullWidth]="true"></ui-toggle-buttons>
                </div>
            </div>
            <div>
                <label class="mb-1" for="guideline">What Regulatory Standards does this feedback align with?</label>
                <app-selector-regulatory-standards
                    [control]="form.controls.guidelines"></app-selector-regulatory-standards>
            </div>

        </div>
    </ng-template>

    <ng-template #rightSlot>
        <button class="btn btn-outline-light me-2" (click)="dismiss()">Close</button>
        <!-- <button (click)="submit()" class="btn btn-secondary">Add Feedback</button> -->
        <app-processing-button
            [ngbTooltip]="!editedFeedback() ? 'An email notification will be automatically sent to the user, informing them that new feedback is available.' : ''"
            tooltipClass="custom-tooltip" container="body" (click)="submit()"
            [text]="editedFeedback() ? 'Update' : 'Add Feedback'" [isLoading]="isLoading"
            [disabled]="isLoading"></app-processing-button>
    </ng-template>
</ui-modal-single>