import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { clone } from 'lodash';
import { map } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group.service';
import { PnpService } from 'src/app/services/pnp.service';
import { StepsControl } from 'src/app/shared/utils/workflows/workflow-steps-control';
import { intersection } from 'underscore';
import { IRevokePolicyFlow, IRevokePolicySharedData } from '../../revoke-policy.interface';
import { UISpinnerComponent } from '../../../../../ui/ui-spinner/ui-spinner.component';
import { SelectUsersTableComponent } from '../../../../../shared/components/select-users-table/select-users-table.component';
import { NgIf } from '@angular/common';

@Component({
    templateUrl: 'revoke-policy-choose-user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, SelectUsersTableComponent, UISpinnerComponent]
})

export class RevokePolicyChooseUserComponent implements OnInit {
    revokePolicyFlowSharedData: IRevokePolicySharedData;
    stepsControl: StepsControl<IRevokePolicyFlow>;
    availableUsers = [];
    selectedUsers = [];
    loading = false;
    constructor(
        private cdRef: ChangeDetectorRef,
        private groupService: GroupService,
        private pnpService: PnpService
    ) { }

    ngOnInit() {
        this.loading = true;

        this.pnpService.fetchAckByPolicy(this.revokePolicyFlowSharedData.selectedPolicy.governanceID).pipe(map(p => {
            return p.acks || [];
        })).subscribe(
            (acks) => {
                if (this.revokePolicyFlowSharedData.selectedUsers) {
                    this.selectedUsers = clone(this.revokePolicyFlowSharedData.selectedUsers);
                }

                this.availableUsers = this.revokePolicyFlowSharedData.orgUsers.active.filter(u => u.activeJobRoleTakens && intersection(this.revokePolicyFlowSharedData.selectedPolicy.ackRelevancy.jobRoles, u.activeJobRoleTakens.map(j => j.jobRole)).length > 0).filter(u => acks.filter((ack: any) => ack.currentPeriod.status !== 'Discarded').map((ack: any) => ack.assigneeUserID).indexOf(u.userID) >= 0).map((u: any) => {
                    const teamDict = this.groupService.globalTeamsDict;
                    u.displayName = {
                        fullName: u.fullName,
                        userID: u.userID,
                        profilePicURL: u.userDetail ? u.userDetail.profilePicURL : '',
                        status: u.status,
                    };

                    u.teamNames = u.teams ? u.teams.map(teamID => teamDict[teamID] ? teamDict[teamID] : '') : [];
                    u.jobRoles = u.activeJobRoleTakens ? u.activeJobRoleTakens.map(j => j.jobRoleModel) : [];

                    return u;
                });
                this.loading = false;
                this.cdRef.detectChanges();
            },
            () => {
                this.loading = false;
                this.cdRef.detectChanges();
            }
        )

    }

    onUserRowSelect(event) {
        this.selectedUsers = event;
        this.cdRef.detectChanges();
    }

    next() {
        this.revokePolicyFlowSharedData.selectedUsers = this.selectedUsers;
        this.stepsControl.updateSteps();
        this.stepsControl.nextStep();
        this.cdRef.detectChanges();
    }

    onBack() {
        this.stepsControl.previousStep();
    }
}