<div *ngIf="!answerControl">
  <div class="d-flex" [ngClass]="{'draggable': showDrag && !disabled}">
    <ui-streamline-icon *ngIf="showDrag"
    [slIcon]="{name: 'interface-setting-menu-vertical', theme: 'micro-bold'}" 
    [ngClass]="color"
    ></ui-streamline-icon>
  
    <div  class="custom-control custom-{{type}}" [ngClass]="{'ms-6' : indented}">
      <input class="custom-control-input"
             [name]="name" [type]="type" [id]="id"
             [checked]="state" (change)="onChange($event.target.checked); changeColor($event.target.checked);"
             [disabled]="disabled"
             [value]="value">
      <label class="custom-control-label f-small text-neutral-900"  [for]="id">{{label}}</label>
    </div>
  </div>

</div>

<!--The following excerpt will handle reactive form case; pass in FormControl to activate the code -->
<ng-container *ngIf="answerControl">
  <div *ngIf="type === 'radio'" class="custom-control custom-radio">
    <input class="custom-control-input custom-radio"
           [formControl]="answerControl"
           [name]="name" type="radio" [id]="id"
           [value]="value">
    <label class="custom-control-label f-small text-neutral-900" [for]="id">{{label}}</label>
  </div>
  <div *ngIf="type === 'checkbox'" class="custom-control custom-checkbox">
    <input class="custom-control-input custom-checkbox"
           [formControl]="answerControl"
           [name]="name" type="checkbox" [id]="id"
           [value]="value">
    <label class="custom-control-label f-small text-neutral-900" [for]="id">{{label}}</label>
  </div>
</ng-container>



