import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';
import { ObjectCardComponent } from '@components/object-card/object-card.component';

@Component({
  selector: 'app-overview-card',
  standalone: true,
  imports: [CommonModule, UIStreamlineIconComponent, ObjectCardComponent],
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent {
  @Input() item;
  @Input() textClass = 'mt-4'
  @Input() textSizeClass = 'f-paragraph'

  getDescriptionHtml(description) {
    return `<div class="${this.textClass} ${this.textSizeClass} text-neutral-700">${description}</div>`
  }
}
