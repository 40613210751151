import { CommonModule, NgFor, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NgbNavModule, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink } from '@ng-bootstrap/ng-bootstrap';
import { DownloadCsvButtonComponent } from '@components/table-utilities/download-csv-button/download-csv-button.component';
import { Ng2SmartTableModule } from 'src/app/shared-modules/ng2-smart-table/ng2-smart-table.module';
import { keys, mapObject, values } from 'underscore';
import { ReportTabs } from 'src/app/pages/reporting/compliance-report/compliance-report-controller/compliance-report-controller.component';
import { LocalDataSource } from 'src/app/shared-modules/ng2-smart-table/lib/data-source/local/local.data-source';

/**
 * if you pass the source in the tableDic, the row numbers will automatically update
 */
@Component({
    selector: 'app-multi-tab-tables',
    templateUrl: './multi-tab-tables.component.html',
    styleUrls: ['./multi-tab-tables.component.scss'],
    standalone: true,
    imports: [NgbNav, NgFor, NgbNavItem, NgbNavItemRole, NgbNavLink, NgClass, DownloadCsvButtonComponent, CommonModule]
})

export class MultiTabTablesComponent implements OnChanges {
  @Input() tables: {
    name, numRow?, key?
  }[];
  @Input() tableDic: {[k in ReportTabType]: {name; numRow?; source?: LocalDataSource}};
  @Input() tabOrder: string[];
  @Input() activeTabId;
  @Input() showDownload = false;
  @Output() navChange = new EventEmitter();
  @Output() navKeyChange = new EventEmitter<ReportTabType>()
  @Output() downloadCsv = new EventEmitter();



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableDic?.currentValue) {
      if (this.tabOrder) {
        this.tables = this.tabOrder.map(el => this.tableDic[el]);
      } else {
        this.tables = values(this.tableDic);
      }
    }
  }

  onNavChange(tab) {
    this.activeTabId = tab;
    this.navChange.emit(tab);
    if (this.tableDic) {
      if (this.tabOrder) {
        this.navKeyChange.emit(this.tabOrder[tab] as ReportTabType);
      } else {
        const activeKey = keys(this.tableDic)[tab] as ReportTabType;
        this.navKeyChange.emit(activeKey)
      }
    }
  }
}

export type ReportTabType = 'all' | 'team';

export function getDefaultTableDic(): Record<ReportTabs, {name; numRow; source: LocalDataSource}> {
  return mapObject({
    "by-user": { name: 'By User', numRow: 0 },
    "by-team": { name: 'By Team', numRow: 0 },
    "by-resource": { name: 'By Item', numRow: 0 },
    "by-location": { name: 'By Location', numRow: 0 },
    "by-jobRole": { name: 'By Job Role', numRow: 0 },
    "by-manager": { name: 'By Manager', numRow: 0 },
  }, v => ({...v, source: new LocalDataSource()}));
}


@NgModule({
    imports: [
        CommonModule,
        NgbNavModule,
        Ng2SmartTableModule,
        MultiTabTablesComponent
    ],
    exports: [MultiTabTablesComponent],
})
export class MultiTabTablesModule {
}

