<div ngbDropdown [autoClose]="false" #myDrop="ngbDropdown" (openChange)="onDropdownChange($event)" (click)="$event.stopPropagation()">
        <button *ngIf="shortcuts$ | async" [disabled]="disabled" ngbDropdownToggle class="btn btn-outline-light px-7">
                <span>
                        <ui-streamline-icon class="pb-1"
                                [slIcon]="{name: 'navigation-right', theme: (activeShortcut ? 'bold' : 'regular'), svgStyle: {'transform': 'rotate(-45deg)'}}">
                        </ui-streamline-icon>

                </span>
                Save{{!showDropdown ? ' as' : ''}}
                <ui-streamline-icon *ngIf="showDropdown" class="ps-2" 
                        [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size: 'xs'}"
                ></ui-streamline-icon>
        </button>
        <div *ngIf="!showDropdown || activeDropdown === 1" ngbDropdownMenu>
                <ng-container *ngTemplateOutlet="saveAsModal; context:{isSaveAs: true}"></ng-container>
        </div>
        <div *ngIf="activeDropdown === 2" ngbDropdownMenu>
                <ng-container *ngTemplateOutlet="saveAsModal; context:{isSaveAs: false}"></ng-container>
        </div>
        <div *ngIf="showDropdown && activeDropdown === 0" ngbDropdownMenu>
        <button ngbDropdownItem type="button" class="btn no-radius fw-medium"
                (click)="openModal(1)">Save as
        </button>
        <button ngbDropdownItem type="button" class="btn no-radius fw-medium"
                (click)="openModal(2)">Save
        </button>
        <button ngbDropdownItem type="button" class="btn no-radius fw-medium"
                (click)="discardChanges()">Discard Changes
        </button>
    
        </div>
</div>

<ng-template #saveAsModal let-isSaveAs="isSaveAs">
        <div [style.min-width]="'360px'" class="py-4 px-4" (click)="$event.stopPropagation()">
                <!-- modal header -->
                <div class="d-flex justify-content-between">
                        <p class="text-neutral-800 fw-semibold">
                                {{isSaveAs ? 'Save as new filter' : 'Save changes?'}}
                        </p>
                        <button (click)="myDrop.close()" type="button"
                                class="btn btn-icon btn-square flex-shrink-0 align-self-start" data-dismiss="modal"
                                aria-label="Close" tabindex="-1">
                                <ui-streamline-icon
                                        [slIcon]="{name: 'interface-delete-1', theme: 'micro-bold', size: 'xs'}"
                                       ></ui-streamline-icon>
                        </button>
                </div>

                <div *ngIf="isSaveAs" class="form-group">
                        <label class="f-small text-neutral-800 mb-1" for="title">Title *
                        </label>
                        <input name="title" id="title" [(ngModel)]="newTitle" appInputError (input)="onType()"
                                [showError]="!newTitle || (isNameDuplicate)" class="form-control" [ngModelOptions]="{standalone: true}">
                        <p class="text-error-input mt-1" *ngIf="!newTitle">
                                Title is required
                        </p>
                        <p class="text-error-input mt-1" *ngIf="isNameDuplicate">
                                This title already exists. Choose a different title
                        </p>
                </div>
                <p class="f-small my-4" *ngIf="!isSaveAs">Save changes for filter “{{existingTitle}}”?</p>

                <div class="d-flex justify-content-between" >
                        <button type="button" class="btn btn-outline-light" (click)="myDrop.close()">Cancel</button>
                        <button (click)="saveAs(isSaveAs)" type="button" class="btn btn-secondary" [disabled]="isSubmitting">
                                <span *ngIf="isSubmitting" style="color: inherit">
                                        <ui-spinner size="sm" class="me-1" color="light"></ui-spinner>
                                </span>Save
                        </button>
                </div>
        </div>
</ng-template>