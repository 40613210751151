import { inject } from "@angular/core";
import { ITableActionButton } from "@components/table-cell/action-buttons/table-action-button/table-action-button.component";
import { ITablePerformanceReviewAssign, TASK_ASSIGN_ACTION_STATUS_ENUM, TASK_ASSIGN_STATUS_ENUM, TASK_TEMPLATE_ENUM } from "src/app/services/performance-review.service";
import { UserService } from "src/app/services/user.service";

export function getTaskActionButtons(): ITableActionButton[] {
  return [
    {
      text: 'Send Reminder', action: 'remind',
      disabledFn: (task: ITablePerformanceReviewAssign) => task?.status === TASK_ASSIGN_STATUS_ENUM.completed || task?.status === TASK_ASSIGN_STATUS_ENUM.scheduled || task?.status === TASK_ASSIGN_STATUS_ENUM.skipped || task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.meeting || (task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.assessment && task?.action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToReview),
      tooltipFn: (task: ITablePerformanceReviewAssign) => task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.meeting ? 'You cannot send reminders for a meeting.' : null,
      hideFn: (task: ITablePerformanceReviewAssign) => task?.status === TASK_ASSIGN_STATUS_ENUM.completed || task?.status === TASK_ASSIGN_STATUS_ENUM.scheduled || task?.status === TASK_ASSIGN_STATUS_ENUM.skipped || task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.meeting || (task?.taskTemplate?.taskType === TASK_TEMPLATE_ENUM.assessment && task?.action === TASK_ASSIGN_ACTION_STATUS_ENUM.managerToReview),
      icon: { name: 'alarm-bell', theme: 'bold', svgClassIn: 'text-warning' }
    },
    {
      text: 'Mark as Skipped', action: 'skipped',
      // disabledFn: (task: ITablePerformanceReviewAssign) =>!inject(UserService).isManager && !inject(UserService).managedOrgUserDictionaryByUserID[task?.managerUserID],
      // tooltipFn: (task: ITablePerformanceReviewAssign) => (!inject(UserService).isManager && !inject(UserService).managedOrgUserDictionaryByUserID[task?.managerUserID]) ? 'You cannot mark this task as skipped' : null,
      hideFn: (task: ITablePerformanceReviewAssign) => task?.status === TASK_ASSIGN_STATUS_ENUM.completed || task.status === TASK_ASSIGN_STATUS_ENUM.scheduled || task.status === TASK_ASSIGN_STATUS_ENUM.skipped,
      icon: { name: 'alarm-bell', theme: 'bold', svgClassIn: 'text-warning' }
    },
    {
      text: 'Unskip task', action: 'unskip',
      disabledFn: (task: ITablePerformanceReviewAssign) => task?.status !== TASK_ASSIGN_STATUS_ENUM.skipped,
      // tooltipFn: (task: ITablePerformanceReviewAssign) => (!inject(UserService).isManager && !inject(UserService).managedOrgUserDictionaryByUserID[task?.managerUserID]) ? 'You cannot unskip this task' : null,
      // hideFn: (task: ITablePerformanceReviewAssign) => task?.status !== TASK_ASSIGN_STATUS_ENUM.skipped,
      hideFn: (task: ITablePerformanceReviewAssign) => true,
      icon: { name: 'alarm-bell', theme: 'bold', svgClassIn: 'text-warning' }
    }
  ]
}