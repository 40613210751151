<div>
  <ng-select #selector [formControl]="control" name="locations" id="locations" [items]="options" bindLabel="name"
    bindValue="teamID" (search)="getFilteredItems($event)" [multiple]="true" [searchable]="true" [closeOnSelect]="false"
    [virtualScroll]="true" [dropdownPosition]="dropdownPosition">
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-level="level">
      <div class="custom-control custom-checkbox ms-{{item.level * 2}}">
        <input [disabled]="item.disabled" id="types-{{index}}" class="custom-control-input" type="checkbox"
          [ngModel]="item$.selected" name="types-{{index}}" />
        <label class="custom-control-label f-small text-neutral-800">{{item.name}}</label>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="allowSelectAll">
      <div class="d-flex justify-content-between">
        <button class="btn btn-link text-decoration-underline fw-semibold me-2" (click)="onSelectAll()">{{ filteredItems
          ? 'Select All Filtered Results' : 'Select All' }}
        </button>
        <button class="btn btn-link text-decoration-underline fw-semibold" (click)="onClearAll()">Clear All
        </button>
      </div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <ng-container *ngIf="sliceNum || sliceNum === 0; else noSlice">
        <div class="ng-value" *ngFor="let item of items | slice:0:sliceNum">
          <span class="ng-value-label">{{item.name}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" *ngIf="!item.disabled">×</span>
        </div>
        <ng-container *ngIf="sliceNum === 0;else showMore">
          <div class="ng-value" *ngIf="items.length === 1">
            <span class="ng-value-label">{{items.length}} Location</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{items.length}} Locations</span>
          </div>
        </ng-container>
        <ng-template #showMore>
          <div class="ng-value" *ngIf="items.length > sliceNum">
            <span class="ng-value-label">+ {{items.length - sliceNum}} more</span>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #noSlice>
        <div class="ng-value" *ngFor="let item of items">
          <span class="ng-value-label">{{displayedUpperStructure(item)}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" *ngIf="!item.disabled">×</span>
        </div>
      </ng-template>
    </ng-template>
  </ng-select>
  <p class="mt-1 f-caption text-neutral-800" *ngIf="showSelectedNumLabel">
    {{control?.value.length}} of
    {{options?.length}} Locations selected</p>
</div>