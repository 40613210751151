import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPlanCell } from '@components/learning-table-popovers/training-plan-cell.component';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalDatePipe } from '../shared/pipes/local-date.pipe';
import { ErrorHandlingService } from './error-handling.service';
import { IOrgMTAssignReminderForm } from './mandatory-training.service';
import { IComplianceRecord, IRecordStatus } from './report-learning-record.service';
import { IOrgUser, UserService } from './user.service';
import { COMPLIANCE_TYPE } from '../pages/mandatory-training/ITrainingPlan';
import { OrganisationService } from './organisation.service';


// assign after manual assign upgrade

export interface IReportBackendAssign {
  assign: IOrgAssign;
  mtPlan?: any;
  resource?: any;
}
export interface IOrgAssign {
  assignDate: string;
  assignee: string;
  dueDate?: string;
  earlyRecogDate: string;
  isOrgActivity?: boolean;
  lateRecogDate: string;
  assignID: string;
  orgID: string;
  originDueDate?: string;
  originLateRecogDate?: string;
  providerType: string;
  status: IRecordStatus;
  user?: IOrgUser;
  extensions?: IOrgAssignDueExtension[],
  mtPlanCell?: IPlanCell;
  daysExtended?: string;
  daysOverdue?: string;
  assignType: 'Plan' | 'Manual';
  complianceType: COMPLIANCE_TYPE;
  plan?: { planID; scheduleID; };
  resource?: any;
  resourceBrief: IOrgAssignResource;
  assigner?: string;
  completion: IOrgAssignCompletion;
  utc: {
    assignDateUTC: string;
    dueDateUTC: string;
    earlyRecogDateUTC: string;
    lateRecogDateUTC: string;
    completeDateUTC: string;
    progCompleteDateUTC: string;
    userCompleteDateUTC: string;
    orgCompleteDateUTC: string;
  };
  reminder: IOrgMTAssignReminderForm;

}

export interface IOrgAssignResource {
  resourceID: string;
  resourceTitle: string;
  resourceMin: number;
  resourceType: string;
  providerType: string;
  code?: string;
}

export interface IOrgAssignCompletion {
  progressID?: string;
  userActivityID?: string;
  orgActivityID?: string;
  progCompleteDate?: string;
  progCompleteLocalDate?: string;
  userCompleteDate?: string;
  userCompleteLocalDate?: string;
  orgCompleteDate?: string;
  orgCompleteLocalDate?: string;
  completeDate?: string;
  completeLocalDate?: string;
  userCompleteCreateDate?: string;
  orgCompleteCreateDate?: string;
  completeCreateDate?: string;
}

export interface IOrgAssignReminder {
  assignReminder?: IOrgAssignReminderDetail;
  firstReminder?: IOrgAssignReminderDetail;
  lastReminder?: IOrgAssignReminderDetail;
  allReminders?: IOrgAssignReminderDetail[];
  lastErrorDate?: string;
  lastErrorLocalDate?: string;
  lastErrorMsg?: string;
}

export interface IOrgAssignReminderDetail {
  sentDate?: string;
  sentLocalDate?: string;
  sentBy?: string;
}

export interface IOrgAssignDueExtension {
  createDate?: string;
  extensionDueDate?: string;
  manager?: string;
  originDueDate?: string;
}

export interface IOrgAssignStatsByDay {
  dueDate: string;
  recordTotalNum: number;
  scheduleNum: number;
  assignedNum: number;
  overdueNum: number;
  completedNum: number;
  doneNum: number;
  skippedNum: number;
  incompleteNum: number;
  planNum: number;
  assignDateMin?: string,
  assignDateMax?: string,
  dueDateMin?: string,

}

@Injectable({
  providedIn: 'root'
})
export class OrgAssignService {

  constructor(
    private http: HttpClient,
    private customErrorHandler: ErrorHandlingService,
    private userService: UserService,
    private localDate: LocalDatePipe,
    private organisationService: OrganisationService
  ) { }

  getUserOrgAssigns(userID) {
    if (!userID) {
      return of([]);
    }
    return this.http.get<IReportBackendAssign[]>(`${environment.accountServiceEndpoint}/orgs/${localStorage.getItem('orgID')}/users/${userID}/assigns`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }

  getConvertedUserAssigns(userID) {
    return this.getUserOrgAssigns(userID).pipe(
      map(assigns => assigns.map(a => {
        return {
          ...a.assign,
          plan: a.mtPlan ? { ...a.assign.plan, ...a.mtPlan } : null,
          resource: a.resource || null
        };
      })),
      map(assigns => assigns.map(a => this.convertAssign(a)))
    );
  }

  convertAssign(assign: IOrgAssign) {
    const dueDateDayjs = dayjs(assign.dueDate)
    const oldAssign = {
      ...assign,
      resource: assign.resource || {
        resourceID: assign?.resourceBrief?.resourceID,
        resourceType: assign?.resourceBrief?.resourceType,
        title: assign?.resourceBrief?.resourceTitle,
        min: assign?.resourceBrief?.resourceMin,
        deleted: true
      },
      // mtAssignID: assign.assignID,
      resourceTitle: assign?.resource?.title || assign.resourceBrief?.resourceTitle,
      resourceMin: assign?.resource?.min || assign.resourceBrief.resourceMin,
      mtPlan: assign.plan?.planID || null,
      mtSchedule: assign.plan?.scheduleID || null,
      dueDate: assign.dueDate || null,
      resourceID: assign?.resource?.resourceID || assign.resourceBrief.resourceID,
      resourceType: assign?.resource?.resourceType || assign.resourceBrief.resourceType,
      mtPlanCell: null,
      activityID: assign.completion?.orgActivityID || assign.completion?.userActivityID,
      isOrgActivity: !!assign.completion?.orgActivityID,
      completeDate: assign.completion?.completeLocalDate,
      extendedDueDate: this.organisationService.isNewExtensionSetting() ? assign.lateRecogDate : assign.dueDate, // if new comp setting, use lateRecogDate
      currentStatus: assign.status,
      completeTimestampLocal: dayjs(assign.completion.completeDate + 'Z').format('YYYY-MM-DDTHH:mm:ss '),
      completeTimestamp: assign.completion.completeDate + 'Z',
      createTimestamp: assign.completion.completeCreateDate,
      daysExtended: getDaysExtended(assign),
      daysOverdue: dayjs().isAfter(dueDateDayjs) ? dayjs().diff(dueDateDayjs, 'day') : 0,
      code: assign?.resource?.code ? assign.resource.code : (assign?.resource?.resourceID.substring(0, 8) || ''),
      resourceObj: assign.resource || assign.resourceBrief
    };
    if (oldAssign.resource?.status === 'Updating' || oldAssign.resource?.status === 'Commence Review') {
      oldAssign.resource.updating = true;
    } else if (oldAssign.resource?.status === 'Published' && (oldAssign.resource?.expiryDate && new Date(oldAssign.resource?.expiryDate) < new Date())) {
      oldAssign.resource.expired = true;
    } else if (oldAssign.resource?.status !== 'Published') {
      oldAssign.resource.unavailable = true;
    }
    oldAssign.mtPlanCell = this.getPlanCell(oldAssign);
    return oldAssign;
  }

  // data for source cell column
  getPlanCell(assign: { plan?; assigner?; assignDate }): IPlanCell {
    const { plan, assigner, assignDate } = assign;
    const isManualAssign = !plan;
    const assignerName = this.userService.managedOrgUserDictionaryByUserID[assigner]?.fullName || 'System';
    return {
      text: isManualAssign ? ('By ' + assignerName)
        : (plan?.title || '-'),
      tooltip: isManualAssign ? `Manually assigned by ${assignerName} on ${(this.localDate.transform(assignDate))}`
        : 'Automatically assigned through training plan: ' + plan.title,
      // tooltip: '',
      isManualAssign: isManualAssign,
      mtPlanID: plan?.mtPlanID,
      assignerName,
      assignDate: assignDate,
      planTitle: plan?.title,
    };
  }

  // for undo skipped learning records only
  undoMark(orgID, assignID) {
    return this.http.get(`${environment.accountServiceEndpoint}/orgs/${orgID}/assigns/${assignID}/undoMark`)
      .pipe(catchError(this.customErrorHandler.handleHttpError));
  }



}
// export interface IConvertedOrgAssign extends IComplianceRecord {
//   plan?;
//   assigner?: string;
//   mtPlanCell: IPlanCell; // data used in table cells,
//   completion?: IOrgAssignCompletion
// }

export function getAssignCsvTitle(optional = false) {
  const date = dayjs().format('YYYY-MM-DD');
  if (optional) {
    return date + ' Optional Requirements';
  }
  return date + ' Mandatory Requirements';
}

export type IConvertedOrgAssign = ReturnType<OrgAssignService['convertAssign']>

export function   // depends on compliance settings, either dueDate or lateRecogDate is extended
getDaysExtended(assign: {dueDate?; lateRecogDate; originDueDate?; originLateRecogDate?}) {
  const dueDateJs =  dayjs(assign.dueDate);
  const lateRecogDateJs = dayjs(assign.lateRecogDate);
  const dueDateDiff = dueDateJs.diff(dayjs((assign.originDueDate || assign.dueDate)), 'day');
  const lateRecogDateDiff = lateRecogDateJs.diff(dayjs((assign.originLateRecogDate || assign.lateRecogDate)), 'day');
  return dueDateDiff || lateRecogDateDiff || 0;
}