<input *ngIf="control"
    type="search"
    ngbDatepicker
    class="form-control f-small"
    #d="ngbDatepicker"
    onkeydown="return false"
    (click)="d.toggle()"
    [formControl]="control"
    [required]="required"
    [maxDate]="today"
    [id]="id"
    [name]="name"
    [minDate]="minDate"
    [style]="style"
    [ngClass]="{'is-invalid': showError}"
>
<input *ngIf="!control"
    type="search"
    ngbDatepicker
    class="form-control f-small"
    #d="ngbDatepicker"
    onkeydown="return false"
    (click)="d.toggle()"
    [(ngModel)]="model"
    [required]="required"
    [maxDate]="today"
    [id]="id"
    [name]="name"
    (dateSelect)="onDateSelect($event)"
    (change)=onChange($event)
    [disabled]="disabled"
    [placeholder]="placeholder"
    [minDate]="minDate"
    [style]="style"
    [ngClass]="{'is-invalid': showError}"
>
<!-- <span *ngIf="isShowIcon && !disabled" class="far fa-calendar errspan" (click)="onIconClick()"></span> -->
<span *ngIf="isShowIcon && !disabled" class="errspan" (click)="onIconClick()">
    <ui-streamline-icon [slIcon]="{name: 'interface-calendar-date', theme: 'micro-bold', size: 'sm'}"></ui-streamline-icon>
</span>
