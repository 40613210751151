import { IAccrediationConfig } from '../../../shared/byo-shared/byo-resource-types';

export interface IOrgLMSSetting {
  industry?: string;
  reportingMeasure?: ReportingMeasure;
  reportingWindow?: ReportingWindow;
  complianceFrameworks?: FRAMEWORKS_ENUM[];
  accreditationConfigs?: IAccrediationConfig[];
  mtConfig?: IMtConfig;
  [key: string]: any;
  appcuesEvents?: string[];
}

export type ReportingWindow = 'Calendar Year' | 'Financial Year';
export type ReportingMeasure = 'CPD Requirement' | 'Completion of Mandatory Training';

// export enum FRAMEWORKS_ENUM {
//   agedCare =  'Aged Care Quality and Safety Commission',
//   NSQHS = 'Australian Commission on Safety and Quality in Health Care',
//   AHPRA = 'Australian Health Practitioner Regulation Agency (AHPRA)',
//   NDIS = 'NDIS Quality and Safeguards Commission',
//   NZ = 'Ngā paerewa Health and disability services standard'
// }

export enum FRAMEWORKS_ENUM {
  agedCare =  'Aged Care Quality Standards',
  NSQHS = 'National Safety and Quality Health Service Standards',
  AHPRA = 'Australian Health Practitioner Regulation Agency (AHPRA)',
  NDIS = 'NDIS Practice Standards',
  NZ = 'Ngā paerewa Health and disability services standard',
  agedCare2024 = 'Strengthened Aged Care Quality Standards',
  NSQPCHS='National Safety and Quality Primary and Community Healthcare Standards'
}

export interface ComplianceReportColumn {
  topic: string;
  activityNum: number;
  min: number;
}

export interface ComplianceActivityDetail {
  activityName: string;
  complianceNum: number;
}

export interface IMtConfig {
  managerRefreshReport?: boolean;
  staffNewAssignReminder?: boolean;
  staffNewOverdueReminder?: boolean;
  staffDueTodayReminder?: boolean;
  staffDueTomorrowReminder?: boolean;
  staffDueIn2DaysReminder?: boolean;
  staffDueIn3DaysReminder?: boolean;
  staffDueIn4DaysReminder?: boolean;
  staffDueIn5DaysReminder?: boolean;
  staffDueIn6DaysReminder?: boolean;
  staffDueIn7DaysReminder?: boolean;
  extensionNoncompliantCutoffDate?: string;

  staffNewAssignPushReminder?: boolean;
  staffNewOverduePushReminder?: boolean;
  staffDueTomorrowPushReminder?: boolean;
  staffDueIn7DaysPushReminder?: boolean;

  staffNewTaskReminder?: boolean;
  staffNewTaskOverdueReminder?: boolean;
  staffTaskDueTomorrowReminder?: boolean;
  staffTaskDueIn7DaysReminder?: boolean;
  
  staffNewTaskPushReminder?: boolean;
  staffNewTaskOverduePushReminder?: boolean;
  staffTaskDueTomorrowPushReminder?: boolean;
  staffTaskDueIn7DaysPushReminder?: boolean;
  appAlerts?: boolean;
}
