import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { enableScroll, toggleScroll } from '../../utils/disable-scrolling';

import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-ngb-dropdown',
  templateUrl: './ngb-dropdown.component.html',
  styleUrls: ['./ngb-dropdown.component.scss'],
  standalone: true,
  imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, UIStreamlineIconComponent]
})
export class NgbDropdownComponent implements OnInit, OnDestroy {
  @Input() dropdownClass = 'd-flex justify-content-end';
  @Input() placement = 'left left-top auto';
  @Input() container = 'body';
  @Input() buttonSize = 'sm';
  @Input() disableScroll = true;
  @Input() disableDropdown = false;
  @Input() ignoreClick = false;
  @Output() fakeButtonClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onDropdownChange(event) {
    if (this.disableScroll) {
      toggleScroll(event);
    }
  }

  ngOnDestroy() {
    enableScroll();
  }

  onFakeButtonClicked() {
    this.fakeButtonClicked.emit(null);
  }

}
