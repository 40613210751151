<div class="modal-body mw-75 mw-sm-100 m-auto" style="min-height: 350px;">
    <div class="text-center border-bottom pb-7 mb-7">
        <h2>Policy successfully assigned!</h2>
    </div>
    <div class="text-center">
        <div *ngIf="revokePolicyFlowSharedData.selectedUsers.length === 1"><span
                class="fw-semibold">{{revokePolicyFlowSharedData.selectedUsers[0].fullName}}</span> was notified to
            acknowledge this policy. Click "Close"
            to finish
        </div>
        <div *ngIf="revokePolicyFlowSharedData.selectedUsers.length !== 1">
            <div>This policy has been assigned.</div>
            <div>{{revokePolicyFlowSharedData.selectedUsers.length + ' user' |
                addPluralS}} were notified
                to acknowlege the policy.
            </div>

        </div>
    </div>
    <div class="mt-2 d-flex justify-content-center">
        <img src="/assets/images/hi-5.svg" alt="Hi-5 image">
    </div>
</div>

<!-- FOOTER -->
<div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-outline-light me-2" *ngIf="revokePolicyFlowSharedData.allowRevokeAnother"
        (click)="closeAndAddAnother()">Assign Another Policy</button>
    <button class="btn btn-secondary" (click)="next()">Close</button>
</div>