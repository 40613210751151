@if (settings) {
@if(multiSelect()){
<div class="table-panel">
    <app-multi-select-container [selectedItems]="selectedRecords" [availableItems]="source.count()"
        [shownItems]="shownItems" (selectAll)="onSelectAll()" (deselectAll)="onDeselectAll()" itemName="requirements">
        <app-smart-table class="sticky-multi-select" #activeUserTable [withWrapper]="true" [stickyRight]="true"
            [tableDiv]="tableKey()" (userRowSelect)="onSelectRow($event)" [settings]="settings" [source]="source"
            [downloadSettings]="downloadSettings"></app-smart-table>
        <ng-container buttons>
            <button class="btn btn-secondary ms-2" (click)="openBulkActionModal('Remind')">Send
                Reminders</button>
            <div ngbDropdown placement="bottom" class="d-inline-block ms-2" container="body">
                <button class="btn btn-outline-light w-100" id="mark-as-done-or-skipped" ngbDropdownToggle>
                    Actions
                    <ui-streamline-icon
                        [slIcon]="{name: 'interface-arrows-button-down', theme: 'micro-bold', size:'xs'}"
                        class="ms-2"></ui-streamline-icon>
                </button>
                <div ngbDropdownMenu class="f-small">
                    <button ngbDropdownItem class="btn no-radius" type="button"
                        (click)="openBulkActionModal('Skipped')">
                        Mark as Skipped
                    </button>
                    @if(tableKey()===LocalTableUniqKey.progressSkipped){
                    <button ngbDropdownItem class="btn no-radius" type="button" (click)="openBulkActionModal('Unskip')">
                        Unskip Tasks
                    </button>
                    }

                </div>
            </div>

        </ng-container>
    </app-multi-select-container>
</div>
}@else {
<app-smart-table [source]="source" [settings]="settings" tableDiv="task-shared" [withWrapper]="true"
    [downloadSettings]="downloadSettings" [stickyRight]="true" #activeUserTable></app-smart-table>
}


}