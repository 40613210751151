<!-- <div class="p-7 d-none">
  <h1> Organisation Details</h1>
  <div *ngIf="user">
    <p class="mb-5 pt-2 f-small text-neutral-800">Only users with Organisation Manager level permissions can access and
      update
      this information.</p>

    <div [ngClass]="{'organisation-grid': user.isManager && activeContract?.contractType !== PRODUCT_TYPES.LIBRARY}">
      <div *ngIf="user.isManager">
        <organisationCardComponent [user]="user" [activeContract]="activeContract" [organisation]="organisation">
        </organisationCardComponent>
      </div>

      <div *ngIf="user.isManager && activeContract?.contractType !== PRODUCT_TYPES.LIBRARY">
        <div class="mb-7">
          <pie-chart></pie-chart>
        </div>
        <ngx-contract *ngIf="files" [user]="user" [contractFiles]="contractFiles" [contracts]="contracts">
        </ngx-contract>
      </div>
    </div>
  </div>
</div> -->
<div class="pt-7 px-7">
  <h3>Contact Details</h3>
  <div>
    <div class="py-2 f-small text-neutral-800">Only users with Organisation Manager access can view and update these
      settings.</div>
  </div>
  <ul ngbNav #nav="ngbNav" class="nav-tabs align-items-center"
    [hidden]="activeContract?.contractType !== 'Ausmed Library'">
    <li ngbNavItem [ngbNavItem]="'detail'">
      <a ngbNavLink class="tab-header">Organisation & Billing Details</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="orgDetail"></ng-container>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="'contract'">
      <a ngbNavLink class="tab-header">Contracts</a>

      <ng-template ngbNavContent>
        <div class="grid-thirds">
          <div class="card mb-2" *ngFor="let contract of contracts">
            <div class="p-4 d-flex justify-content-between border-bottom pb-4">
              <div><span class="fw-semibold f-small">{{contract.contractType}}</span>
              </div>
              <div><span
                  class="badge {{contract.status==='Active'?'badge-success-light':'badge-inactive'}}">{{contract.status}}</span>
              </div>
            </div>
            <div class="p-4 d-flex justify-content-between align-items-center f-small">
              <div>
                <div class="pb-2">Contract Period</div>
                <div>{{contract.startDate | date:'dd MMM yyyy'}} - {{contract.endDate?(contract.endDate |
                  date:'dd MMM yyyy'):'-'}}</div>
              </div>
              <div><button class="btn btn-outline-light btn-sm" (click)="viewContractFiles(contract)">View</button>
              </div>
            </div>

          </div>
        </div>

      </ng-template>
    </li>
  </ul>
</div>
<div class="p-7">
  <ng-container *ngIf="activeContract?.contractType !== 'Ausmed Library'">
    <ng-container *ngTemplateOutlet="orgDetail"></ng-container>
  </ng-container>
  <ng-container *ngIf="activeContract?.contractType === 'Ausmed Library'">
    <div [ngbNavOutlet]="nav"></div>
  </ng-container>
</div>

<ng-template #orgDetail>
  <form #f="ngForm" novalidate>
    <div class="grid-halfs">
      <div class="card p-5">
        <div class="fw-semibold pb-4">Organisation Details</div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="orgName">Organisation Display Name*</label>
          <input type="text" class="form-control" name="orgName" id="orgName" #orgName="ngModel"
            [(ngModel)]="organisation.name" placeholder="Name" required disabled>
          <div *ngIf="orgName.errors && !orgName.pristine">
            <div class="text-error-input mt-1 f-small" *ngIf="orgName.errors.required">
              Name is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="orgContact">Organisation Contact Name</label>
          <input type="text" class="form-control" id="orgContact" name="orgContact" #orgContact="ngModel"
            [(ngModel)]="organisation.lmsSetting.mainContact" placeholder="Name">
        </div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="orgEail">Main Contact Email*</label>
          <input type="email" class="form-control" id="orgEail" name="orgEail" #orgEail="ngModel"
            [(ngModel)]="organisation.email" placeholder="Email" (change)="sameAsAccountEmail=false" required email>
          <div *ngIf="orgEail.errors && !orgEail.pristine">
            <div class="text-error-input mt-1 f-small" *ngIf="orgEail.errors.required">
              Email is required
            </div>
            <div class="text-error-input mt-1 f-small" *ngIf="orgEail.errors.email">
              Email is not a valid email address
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="phone">Main Contact Phone</label>
          <input type="text" class="form-control" id="phone" name="phone" #phone="ngModel"
            [(ngModel)]="organisation.phone" placeholder="Phone">
        </div>

        <div class="fw-semibold pb-4">Organisation Address</div>
        <fieldset (change)="sameAsOrgAddress=false">
          <div class="form-group">
            <label class="f-small text-neutral-800" for="postalStreet1">Address*</label>
            <input type="text" class="form-control" id="postalStreet1" name="postalStreet1" #postalStreet1="ngModel"
              [(ngModel)]="organisation.postalAddress.street1" placeholder="Address" required>
            <input type="text" class="form-control mt-2" id="postalStreet2" name="postalStreet2"
              #postalStreet2="ngModel" [(ngModel)]="organisation.postalAddress.street2">
            <div *ngIf="postalStreet1.errors && !postalStreet1.pristine">
              <div class="text-error-input mt-1 f-small" *ngIf="postalStreet1.errors.required">
                Address is required
              </div>
            </div>
          </div>
          <div class="grid-halfs">
            <div class="form-group">
              <label class="f-small text-neutral-800" for="postalSuburb">Suburb</label>
              <input type="text" class="form-control" id="postalSuburb" name="postalSuburb" #postalSuburb="ngModel"
                [(ngModel)]="organisation.postalAddress.suburb" placeholder="Suburb">
            </div>
            <div class="form-group">
              <label class="f-small text-neutral-800" for="postalPostCode">Post Code</label>
              <input type="text" class="form-control" id="postalPostCode" name="postalPostCode"
                #postalPostCode="ngModel" [(ngModel)]="organisation.postalAddress.postcode" placeholder="Post Code">
            </div>
            <div class="form-group">
              <label class="f-small text-neutral-800" for="postalCountry">Country</label>
              <select type="text" class="form-control custom-select" name="postalCountry" id="postalCountry"
                #postalCountry="ngModel" [(ngModel)]="organisation.postalAddress.country"
                (change)="onPostalCountryChange()">
                <option *ngFor="let country of countries" [value]="country.countryName">{{country.countryName}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="f-small text-neutral-800" for="postalState">State</label>
              <select type="text" class="form-control custom-select" name="postalState" id="postalState"
                #postalState="ngModel" [(ngModel)]="organisation.postalAddress.state">
                <option *ngFor="let state of pStates" [value]="state.value">{{state.text}}</option>
              </select>
            </div>
          </div>
        </fieldset>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="timezoneId">Timezone</label>
          <select class="form-control custom-select" name="timezoneId" id="timezoneId" #timezoneId="ngModel"
            [(ngModel)]="organisation.timezone" placeholder="Select timezone">
            <option *ngFor="let timezone of timezones" [value]="timezone">
              {{ timezone }}
            </option>
          </select>
        </div>
      </div>
      <div class="card p-5" [hidden]="activeContract?.contractType !== 'Ausmed Library'">
        <div class="fw-semibold pb-4">Billing Details</div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="billingName">Billing Organisation Name*</label>
          <input type="text" class="form-control" id="billingName" name="billingName" #billingName="ngModel"
            [(ngModel)]="organisation.lmsSetting.orgBillingName" placeholder="Name" [required]="activeContract?.contractType === 'Ausmed Library'">
          <div *ngIf="billingName.errors && !billingName.pristine">
            <div class="text-error-input mt-1" *ngIf="billingName.errors.required">
              Name is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="abn">ABN*</label>
          <input type="text" class="form-control" id="abn" name="abn" #abn="ngModel" [(ngModel)]="organisation.abn"
            placeholder="ABN" [required]="activeContract?.contractType === 'Ausmed Library'">
          <div *ngIf="abn.errors && !abn.pristine">
            <div class="text-error-input mt-1" *ngIf="abn.errors.required">
              ABN is required
            </div>
          </div>
        </div>

        <div class="fw-semibold pb-4">Billing Address</div>
        <div class="form-group" [hidden]="!organisation.postalAddress.street1">
          <div class="custom-control custom-checkbox">
            <input id="sameAsOrgAddress" name="sameAsOrgAddress" class="custom-control-input"
              (change)="copyOrgAddress($event)" type="checkbox" [checked]="sameAsOrgAddress" />
            <label for="sameAsOrgAddress" class="custom-control-label f-small text-neutral-800">Same as organisation
              address<br />({{organisation.postalAddress.street1}}{{organisation?.postalAddress?.street2?', '+organisation.postalAddress.street2:''}}{{organisation?.postalAddress?.suburb?', '+organisation.postalAddress.suburb:''}} {{organisation.postalAddress.state}} {{organisation.postalAddress.country}}{{organisation?.postalAddress?.postcode?', '+organisation.postalAddress.postcode:''}})</label>
          </div>
        </div>
        <fieldset [disabled]="sameAsOrgAddress">
          <div class="form-group">
            <label class="f-small text-neutral-800" for="addressStreet1">Address*</label>
            <input type="text" class="form-control" id="addressStreet1" name="addressStreet1" #addressStreet1="ngModel"
              [(ngModel)]="organisation.address.street1" placeholder="Address" required>
            <input type="text" class="form-control mt-2" id="addressStreet2" name="addressStreet2"
              #addressStreet2="ngModel" [(ngModel)]="organisation.address.street2">
            <div *ngIf="addressStreet1.errors && !addressStreet1.pristine">
              <div class="text-error-input mt-1 f-small" *ngIf="addressStreet1.errors.required">
                Address is required
              </div>
            </div>
          </div>
          <div class="grid-halfs">
            <div class="form-group">
              <label class="f-small text-neutral-800" for="addressSuburb">Suburb</label>
              <input type="text" class="form-control" id="addressSuburb" name="addressSuburb" #addressSuburb="ngModel"
                [(ngModel)]="organisation.address.suburb" placeholder="Suburb">
            </div>

            <div class="form-group">
              <label class="f-small text-neutral-800" for="addressPostCode">Post Code</label>
              <input type="text" class="form-control" id="addressPostCode" name="addressPostCode"
                #addressPostCode="ngModel" [(ngModel)]="organisation.address.postcode" placeholder="Post Code">
            </div>
            <div class="form-group">
              <label class="f-small text-neutral-800" for="addressCountry">Country</label>
              <select type="text" class="form-control custom-select" name="addressCountry" id="addressCountry"
                #addressCountry="ngModel" [(ngModel)]="organisation.address.country" (change)="onCountryChange()">
                <option *ngFor="let country of countries" [value]="country.countryName">{{country.countryName}}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="f-small text-neutral-800" for="addressState">State</label>
              <select type="text" class="form-control custom-select" name="addressState" id="addressState"
                #addressState="ngModel" [(ngModel)]="organisation.address.state">
                <option *ngFor="let state of states" [value]="state.value">{{state.text}}</option>
              </select>
            </div>
          </div>
        </fieldset>
        <div class="fw-semibold pb-4">Billing Contact Info</div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="billingContact">Billing Contact Name</label>
          <input type="text" class="form-control" id="billingContact" name="billingContact" #billingContact="ngModel"
            [(ngModel)]="organisation.lmsSetting.billingContact.billingContactName" placeholder="Name">
        </div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="billingEmail">Billing Contact Email*</label>
          <input type="email" class="form-control" id="billingEmail" name="billingEmail" #billingEmail="ngModel"
            [(ngModel)]="organisation.lmsSetting.billingContact.billingEmail" placeholder="Email" [required]="activeContract?.contractType === 'Ausmed Library'" email
            [disabled]="sameAsAccountEmail">
          <div *ngIf="billingEmail.errors && !billingEmail.pristine">
            <div class="text-error-input mt-1" *ngIf="billingEmail.errors.required">
              Email is required
            </div>
            <div class="text-error-input mt-1" *ngIf="billingEmail.errors.email">
              Email is not a valid email address
            </div>
          </div>
        </div>
        <div class="form-group" [hidden]="!organisation.email">
          <div class="custom-control custom-checkbox">
            <input id="sameAsAccountEmail" name="sameAsAccountEmail" class="custom-control-input"
              (change)="copyAccountEmail($event)" type="checkbox" [checked]="sameAsAccountEmail" />
            <label for="sameAsAccountEmail" class="custom-control-label f-small text-neutral-800">Use main contact
              email<br />
              ({{organisation.email}})</label>
          </div>
        </div>
        <div class="form-group">
          <label class="f-small text-neutral-800" for="billingPhone">Billing Contact Phone</label>
          <input type="text" class="form-control" id="billingPhone" name="billingPhone" #billingPhone="ngModel"
            [(ngModel)]="organisation.lmsSetting.billingContact.billingPhone" placeholder="Phone">
        </div>
        <div class="my-2" *ngIf="!hasCCEmail" (click)="showCCEmail=!showCCEmail"><span
            class="fw-semibold btn btn-link">+ Add CC email</span></div>
        <div class="form-group animated fadeIn" *ngIf="showCCEmail" [@fadeAnimation]>
          <label class="f-small text-neutral-800" for="ccEmail">CC Email</label>
          <input type="email" class="form-control" id="ccEmail" name="ccEmail" #ccEmail="ngModel"
            [(ngModel)]="organisation.lmsSetting.billingContact.billingCCEmail" placeholder="CC Email" email>
          <div class="mt-1 d-flex justify-content-between align-items-center">
            <div>
              <div *ngIf="ccEmail.errors && !ccEmail.pristine">
                <div class="text-error-input" *ngIf="ccEmail.errors.email">
                  CC email is not valid
                </div>
              </div>
            </div>

            <button class="btn btn-link text-error-input" (click)="removeCCEmail()">
              Remove
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="pt-5 d-flex justify-content-start">
      <button class="btn btn-secondary" (click)="updateOrganisation()" [disabled]="updatingOrganisation"><span
          *ngIf="updatingOrganisation"><i class="fas fa-spinner fa-spin"></i></span> Save</button>
    </div>
    <div *ngIf="error" class="mt-2">
      <ui-alert-banner [copy]="error" alertType="error" type="New" [includeDismiss]="false">
      </ui-alert-banner>
    </div>

  </form>
</ng-template>
