import { clone } from "underscore";
import { DataCacher } from "./DataCacher";

export class ListCacher<T> extends DataCacher<T[]> {
    pushNew(newData: T | T[]) {
        this.update((current => current.concat(newData)))
    }

    /**
     * 
     * @param updatedData 
     * @param keyAttr attribute name used as key to find which el to update
     */
    updateEl(updatedData: T, keyAttr: keyof T) {
        this.update(current => {
            const updatedEl = current.findIndex(el => el[keyAttr] === updatedData[keyAttr]);
            current[updatedEl] = updatedData;
            return clone(current);
        })
    }

    deleteEl(id: string, keyAttr: keyof T) {
        this.update(current => current.filter(el => el[keyAttr] !== id))
    }
}