import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertBannerComponent } from '@components/alert-banner/alert-banner.component';
import { UIStreamlineIconComponent } from '@ui/streamline-icon/ui-streamline-icon.component';

@Component({
  selector: 'app-warning-banner',
  standalone: true,
  imports: [CommonModule, AlertBannerComponent, UIStreamlineIconComponent],
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss']
})
/** yellow/blue banner */
export class WarningBannerComponent {
  @Input() copy;
  @Input() color = 'yellow'

}
