import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, share, tap, map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from './error-handling.service';
import { BroadcastService } from './broadcast.service';
import { isBetweenDate } from '../shared/utils/is-between-date';
import { IOrgUser, UserService } from './user.service';
import { removeEmptyFields } from '../shared/utils/remove-empty-fields';
import { deepClone } from '../shared/utils/deepclone';
import { Dictionary, groupBy, indexBy, intersection, omit, pluck, values } from 'underscore';
import { sortBy } from 'underscore';
import { ISelectorOption2 } from '@components/customised-multi-select2/customised-multi-select2.component';
import { orderBy as _orderBy } from 'lodash';
import { LOCATION_TYPE_DISPLAY } from '../pages/manage-location/admin-location-types';
import { IAddress } from '../shared/google-locations/google-address-types';
import { HIERARCHY_TEAM_TYPE } from '../pages/admin/hierarchy/hierarchy.service';
import { jobRoleTypeCaptionDisplay } from '../pages/reporting/filter-pack/category-selector/category-selector-export-functions';
import { ALL_SPECIAL_TEAM_TYPES } from './service-types/special-team-types';
import { toSignal } from '@angular/core/rxjs-interop';

// TODO: refactor the whole group service to cache the result and call api only once.
@Injectable({
  providedIn: 'root'
})
export class GroupService {
  orgTeams = new BehaviorSubject(null);
  private _pendingTeams: Observable<any>;
  private pendingTeams: boolean;
  teamsDict = new Map();
  globalTeamsDict: Dictionary<ITeam>;
  globalTeamsLocationDict: Dictionary<ITeam>;
  globalLocationsDict: Dictionary<ITeam>;
  globalJobRolesDict: Dictionary<IJobRole>;
  globalExistingTeamNames: Set<string>;
  managedTeamsMapByTeamID: Dictionary<ITeam>; // includes special teams
  orgTeamsWithUsers = new BehaviorSubject(null);
  private pendingOrgTeamsWithUsers: boolean;

  orgJobRoles = new BehaviorSubject(null);
  private _pendingOrgJobRoles: Observable<any>;
  private pendingJobRoles: boolean;

  showJobRoleTypes = false;
  jobTypeDic: Dictionary<{ jobRoleType?: JobRoleType; jobRoleID: string; orgID: string; isActive: boolean; title: string; description?: string; createDate: string; updateDate: string; }[]>;

  globalTeamsDic$ = toSignal(this.fetchedCacheOrgTeams().pipe(map(() => this.globalTeamsDict)), {});
  globalLocationsDic$ = toSignal(this.fetchedCacheOrgTeams().pipe(map(() => this.globalLocationsDict)), {});
  globalJobRolesDic$ = toSignal(this.fetchedCacheOrgJobRoles().pipe(map(() => this.globalJobRolesDict)), {});

  constructor(
    private httpClient: HttpClient,
    private broadcastService: BroadcastService,
    private errorHandlingService: ErrorHandlingService,
    private userService: UserService,
  ) {
    this.broadcastService.on('logout').subscribe(() => {
      this.orgTeams.next(null);
      this.orgJobRoles.next(null);
      this.teamsDict.clear();
    });
  }

  // excludes facility teams unless stated in argument
  public fetchedCacheOrgTeams(refresh?: boolean): Observable<any[]> {
    if (refresh || (!this.orgTeams.value && !this.pendingTeams)) {
      this.pendingTeams = true;
      const fetch$ = this.fetchOrgTeams(localStorage.getItem('orgID')).pipe(
        tap(() => this.pendingTeams = false),
        catchError((e) => {
          this.pendingTeams = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.orgTeams.pipe(
      filter(x => !!x),
      // map((teams: ITeam[]) => teams.filter(t => includeFacilities ? true : !(t.teamType in LOCATION_TYPE_DISPLAY)))
    ); // emit pending / cached value (wihout null and only once)
  }

  public fetchedCacheOrgJobRoles(refresh?: boolean): Observable<IJobRole[]> {
    // const orgJobRoles = this.orgJobRoles.value;
    //
    // if (this._pendingOrgJobRoles) {
    //   return this._pendingOrgJobRoles;
    // } else if (orgJobRoles && orgJobRoles.length > 0 && !refresh) {
    //   return this.orgJobRoles.asObservable();
    // }
    // return this._pendingOrgJobRoles = this.fetchOrgJobRoles(localStorage.getItem('orgID')).pipe(
    //     share(),
    //     tap(() => delete this._pendingOrgJobRoles),
    //     catchError((e) => {
    //       delete this._pendingOrgJobRoles;s
    //       return this.errorHandlingService.handleHttpError(e);
    //     }),
    // );

    if (refresh || (!this.orgJobRoles.value && !this.pendingJobRoles)) {
      this.pendingJobRoles = true;
      const fetch$ = this.fetchOrgJobRoles(localStorage.getItem('orgID')).pipe(
        tap(() => this.pendingJobRoles = false),
        catchError((e) => {
          this.pendingJobRoles = false;
          return throwError(e);
        }),
        share(),
      );
      fetch$.subscribe(); // makes observable hot (important so as not to cause pending bugs)
    }
    return this.orgJobRoles.pipe(filter(x => !!x));
  }

  public fetchCachedJobRoleWithMembers(): Observable<Dictionary<IJobRoleWithMembers>> {
    return combineLatest([this.fetchedCacheOrgJobRoles(), this.userService.fetchCachedOrgUsers(localStorage.getItem('orgID'))]).pipe(
      map(([jobRoles, users]) => {
        const allUsers = users.all;
        return indexBy(jobRoles.map(j => {
          return {
            ...j,
            members: allUsers.filter(u => u.activeJobRoleTakens.some(taken => taken.jobRole === j.jobRoleID))
          };
        }), 'jobRoleID');
      })
    );
  }

  fetchOrgTeams(orgID: string): Observable<ITeam[]> {
    return this.httpClient
      .get<ITeam[]>(`${environment.accountServiceEndpoint}/orgs/${orgID}/teams`)
      .pipe(
        // @ak for test
        // map(() => []),
        tap((teams) => {
          const normalTeams = teams.filter(t => !(t.teamType in LOCATION_TYPE_DISPLAY || t.teamType === HIERARCHY_TEAM_TYPE || ALL_SPECIAL_TEAM_TYPES.has(t.teamType)));
          const locationTeams = teams.filter(t => (t.teamType in LOCATION_TYPE_DISPLAY));
          this.globalTeamsLocationDict = indexBy(normalTeams.concat(locationTeams), 'teamID'); // Object
          this.globalLocationsDict = indexBy(locationTeams, 'teamID');
          this.globalTeamsDict = indexBy(normalTeams, 'teamID'); // Object
          this.globalExistingTeamNames = new Set(teams.map(t => t.name?.toLocaleLowerCase()));
          normalTeams.forEach((team: any) => this.teamsDict.set(team.teamID, team)); // MAP
        }),
        map((teams) => this.userService.isManager ? sortBy(teams, 'name') : sortBy(teams.filter(({ teamID }) => this.userService.managedTeams.includes(teamID)), 'name')),
        tap((teams: any) => {
          this.managedTeamsMapByTeamID = indexBy(teams, 'teamID');
          this.orgTeams.next(teams.filter(t => !(t.teamType in LOCATION_TYPE_DISPLAY || t.teamType === HIERARCHY_TEAM_TYPE || ALL_SPECIAL_TEAM_TYPES.has(t.teamType))));
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  getOrgTeams(): Observable<any[]> {
    return this.orgTeams.asObservable();
  }


  fetchOrgJobRoles(orgID: string) {
    return this.httpClient
      .get(`${environment.accountServiceEndpoint}/orgs/${orgID}/jobRoles`)
      .pipe(
        tap((jobRoles: IJobRole[]) => {
          this.showJobRoleTypes = false;
          if (jobRoles.some(j => j.jobRoleType)) {
            this.showJobRoleTypes = true;
          }
          this.globalJobRolesDict = indexBy(jobRoles, 'jobRoleID');
          if (this.showJobRoleTypes) {
            this.setJobTypeDict();
          }
        }),
        map((jobRoles: any) => sortBy(jobRoles, 'title')),
        tap((jobRoles: any) => {
          this.orgJobRoles.next(jobRoles);
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  getOrgJobRoles(): Observable<any[]> {
    return this.orgJobRoles.asObservable();
  }

  addOrgJobRole(orgID: string, jobRole: any) {
    return this.httpClient
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/jobRoles', jobRole)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  // todo optimize
  addOrganisationJobRole(orgId: string, jobRole: any) {
    const jobRoleCopy = deepClone(jobRole);
    removeEmptyFields(jobRoleCopy);
    return this.httpClient
      .post(environment.accountServiceEndpoint + '/orgs/' + orgId + '/jobRoles', jobRoleCopy)
      .pipe(
        tap(() => {
          this.userService.fetchCachedOrgUsers(orgId, true).subscribe(
            () => {
              this.fetchedCacheOrgJobRoles(true).subscribe();
            },
          );
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  updateOrganisationJobRole(orgId: string, jobRoleId, jobRole: any) {
    const jobRoleCopy = deepClone(jobRole);
    removeEmptyFields(jobRoleCopy);
    return this.httpClient
      .put(environment.accountServiceEndpoint + '/orgs/' + orgId + '/jobRoles/' + jobRoleId, jobRoleCopy)
      .pipe(
        tap(() => {
          this.userService.fetchCachedOrgUsers(orgId, true).subscribe(
            () => {
              this.fetchedCacheOrgJobRoles(true).subscribe();
            },
          );
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  bulkUpdateJobRole(payload: IJobRoleForm[]) {
    const orgID = localStorage.getItem('orgID');
    return this.httpClient
      .put(environment.accountServiceEndpoint + '/orgs/' + orgID + '/jobRoles', payload)
      .pipe(
        tap(() => {
          this.userService.fetchCachedOrgUsers(orgID, true).subscribe(
            () => {
              this.fetchedCacheOrgJobRoles(true).subscribe();
            },
          );
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  updateOrgJobRole(orgID: string, jobRoleId, jobRole: any) {
    return this.httpClient
      .put(environment.accountServiceEndpoint + '/orgs/' + orgID + '/jobRoles/' + jobRoleId, jobRole)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }


  deleteOrgJobRole(orgID: string, jobRoleId: string) {
    return this.httpClient
      .delete(environment.accountServiceEndpoint + '/orgs/' + orgID + '/jobRoles/' + jobRoleId)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  deleteOrganisationJobRole(orgId: string, jobRoleId: string) {
    return this.httpClient
      .delete(environment.accountServiceEndpoint + '/orgs/' + orgId + '/jobRoles/' + jobRoleId)
      .pipe(
        tap(() => {
          this.fetchedCacheOrgJobRoles(true).subscribe();
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  pureAddOrganisationJobRole(orgID: string, jobRole: any) {
    return this.httpClient
      .post(environment.accountServiceEndpoint + '/orgs/' + orgID + '/jobRoles', jobRole)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  /*
    return all current jobRoleTakens of a user
  */
  getCurrentJobRoleTakens(user: any): any[] {
    if (user.jobRoleTakens) {
      return user.jobRoleTakens.filter(taken => !!taken.jobRoleModel).
        filter(taken => {
          if (isBetweenDate(new Date().getTime(), new Date(taken.startDateUTC).getTime(), new Date(taken.endDateUTC).getTime())) {
            return true;
          }
        });
    }
    return [];
  }

  addOrganisationTeam(orgId: string, team: IGroupForm) {
    return this.httpClient
      .post<ITeam>(environment.accountServiceEndpoint + '/orgs/' + orgId + '/teams', team)
      .pipe(
        tap(() => {
          this.fetchedCacheOrgTeams(true).subscribe(() => {
          });
        }),
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  addCurrentOrganisationTeam(orgId: string, team: any) {
    return this.httpClient
      .post(environment.accountServiceEndpoint + '/orgs/' + orgId + '/teams', team)
      .pipe(
        catchError(this.errorHandlingService.handleHttpError),
      );
  }

  updateOrganisationTeam(orgId: string, teamId: string, team: ISubmitTeamForm, refresh = true) {
    return this.httpClient
      .put(environment.accountServiceEndpoint + '/orgs/' + orgId + '/teams/' + teamId, team)
      .pipe(
        tap(() => {
          this.fetchedCacheOrgTeams(refresh).subscribe();
        }),
        catchError(this.errorHandlingService.handleHttpError)
      );
  }

  deleteOrganisationTeam(orgId: string, teamId: string, refetchTeams = true) {
    return this.httpClient
      .delete(environment.accountServiceEndpoint + '/orgs/' + orgId + '/teams/' + teamId)
      .pipe(
        tap(() => {
          if (refetchTeams) {
            this.fetchedCacheOrgTeams(true).subscribe();
          }
        }),
        catchError(this.errorHandlingService.handleHttpError),
      )
      .toPromise();
  }

  updateTeamAndOrgUserMangers(orgID: string, team: ITeamForm, deletedManagers: IOrgUser[], addedManagers: IOrgUser[]) {

  }

  getTeamMultiSelectOptions(refresh = true): Observable<ISelectorOption2[]> {
    const teamOptions$ = this.fetchedCacheOrgTeams(refresh).pipe(
      map(teams => {
        const globalTeamsDict = this.globalTeamsDict,
          managedTeamsMapByTeamID = this.managedTeamsMapByTeamID;
        const parentTeams = [...values(globalTeamsDict)].filter(({ upperTeam = '' }) => !upperTeam);
        const parentTeamsWithChildTeams = parentTeams.map((rawParentTeam) => {
          const childTeams = rawParentTeam.lowerTeams ?
            rawParentTeam.lowerTeams.map(childTeamID => (
              {
                value: childTeamID,
                label: `<span class="text-neutral-900">${rawParentTeam.name}</span> > <span class="text-neutral-800">${globalTeamsDict[childTeamID].name}</span>`,
                disabled: !managedTeamsMapByTeamID[globalTeamsDict[childTeamID].teamID],
              }
            )) : [];
          return {
            value: rawParentTeam.teamID,
            label: `<span class="text-neutral-900">${rawParentTeam.name}</span>`,
            disabled: !managedTeamsMapByTeamID[rawParentTeam.teamID],
            childTeams,
          };
        });
        let flattenTeams = [];
        parentTeamsWithChildTeams.forEach(parentTeamWithChild => {
          flattenTeams.push(
            omit(parentTeamWithChild, 'childTeams')
          );
          // Add all child teams into the parentTeamsWithChildTeams
          flattenTeams = flattenTeams.concat(parentTeamWithChild.childTeams || []);
        });
        return flattenTeams;
      })
    );
    return teamOptions$;
  }

  getTeamStructureData(): Observable<
    { fn_team; fn_teamName; fn_teamID; fn_isParent; fn_isChild }[]> {
    return this.fetchedCacheOrgTeams().pipe(
      map(teams => {
        let teamData = teams.map(team => {
          return {
            fn_team: team,
            fn_teamName: team.name,
            fn_teamID: team.teamID,
            fn_isParent: team.lowerTeams ? true : false,
            fn_isChild: team.upperTeams ? true : false,
          };
        });
        if (this.userService.isManager) {
          // Sort the rows by Parent Teams
          const groupedTeamSource = [];
          teamData.forEach(r => {
            if (r.fn_isChild) {
              return;
            }
            if (r.fn_isParent) {
              groupedTeamSource.push(r);
              r.fn_team.lowerTeams.forEach(childTeamID => {
                groupedTeamSource.push(
                  teamData.filter(team => team.fn_teamID === childTeamID)[0]
                );
              });
            } else {
              groupedTeamSource.push(r);
            }
          });
          teamData = groupedTeamSource;
        }
        return teamData;
      })
    )
  }

  setJobTypeDict() {
    this.jobTypeDic = groupBy(Object.values(this.globalJobRolesDict), 'jobRoleType')
  }

  getJobRoleByTypes(jobTypes: string[]) {
    return jobTypes.map(type => this.jobTypeDic[type]).flat();
  }

  getTeamMembers(teamIDs: string[]) {
    return this.userService.userList
      .filter(u => intersection((u.teams || []), teamIDs).length)
  }

  getJobRoleMembers(jobRoleIDs: string[]) {
    return this.userService.userList
      .filter(u => intersection(pluck((u.activeJobRoleTakens || []), 'jobRole'), jobRoleIDs).length)
  }

  getAllJobIDs() {
    return Object.keys(this.globalJobRolesDict);
  }

  fetchCachedDefaultJobRole() {
    return this.fetchedCacheOrgJobRoles().pipe(
      take(1),
      map(j => {
        return j.length === 1 && j[0].title === DEFAULT_JOB_ROLE
          ? j[0].jobRoleID
          : null
      })
    )
  }
}

export interface IGroupForm {
  name?: string; // for team
  title?: string; // for job role
  description?: string;
}

export interface IGroup {
  name?: string;
  title?: string;
  description?: string;
}

export interface ITeamForm {
  name: string;
  description?: string;
  upperTeam?: string;
  managers?: IOrgUser[];
  teamType: TeamType;
  inputTeamType?: string;
}

export interface IParentTeam extends ITeam {
  childTeams: ITeam[];
}

export interface ISubmitTeamForm {
  name: string;
  description?: string;
  upperTeam?: string;
  teamType: TeamType;
  managers?: string[];
  orgLocationIDs?: string[];
}

export interface ITeam {
  teamID: string;
  orgID: string;
  name: string;
  managers?: IOrgUser[];
  members?: IOrgUser[];
  description?: string;
  upperTeam?: string;
  upperTeams?: string[];
  lowerTeams?: string[];
  teamMember?: boolean;
  teamManager?: boolean;
  parentTeam?: ITeam;
  childTeams?: ITeam[];
  createDate: string;
  updateDate: string;
  teamType?: TeamType;
  upperTeamName?: string;
  noAccess?: boolean;
  orgLocationIDs?: string[];
  detail?: { address?: IAddress };
}

export type TeamType = 'Business Unit' | 'Department' | 'Facility' | 'Region' | 'Ward' | 'Other' | string;

export interface IJobRoleTaken {
  jobRoleTakenID?: string;
  orgUser?: string;
  jobRole: string;
  jobRoleModel?: IJobRole;
  startDate?: string;
  endDate?: string;
  startDateUTC?: string;
  endDateUTC?: string;
}

export interface IJobRole {
  jobRoleID: string;
  orgID: string;
  isActive: boolean;
  title: string;
  description?: string;
  createDate: string;
  updateDate: string;
  jobRoleType?: JobRoleType;
}

export interface IJobRoleWithMembers extends IJobRole {
  members: any[];
}

export interface IJobRoleForm {
  jobRoleID: string;
  title: string;
  description?: string;
  jobRoleType?: JobRoleType;
}

export type JobRoleType = 'clinical' | 'nonClinical'

export function getColJobRoleType(jobRoles: IJobRole[]) {
  jobRoles = jobRoles || [];
  if (!jobRoles.length) {
    return '-'
  }
  if (jobRoles.every(j => j.jobRoleType === 'clinical')) {
    return jobRoleTypeCaptionDisplay.clinical;
  }
  if (jobRoles.every(j => j.jobRoleType === 'nonClinical')) {
    return jobRoleTypeCaptionDisplay.nonClinical;
  }
  if (jobRoles.some(j => j.jobRoleType)) {
    return 'Both';
  }
  return '-'

}

export const DEFAULT_JOB_ROLE = 'Default';

export const maxTeamNum = 27;
