<ul ngbNav [destroyOnHide]="true" #nav="ngbNav" [activeId]="activeTabId()" (activeIdChange)="activeTabId.set($event)"
class="nav-tabs bg-white align-items-center">

<li *ngFor="let item of items; index as idx" [ngbNavItem]="idx">
    <a ngbNavLink>
        @if (item.icon) {
            <ui-streamline-icon [slIcon]="item.icon"></ui-streamline-icon>
        }
        {{item.name}} 
        <span class="badge badge-pill" [ngClass]="{'badge-primary-light': activeTabId() === idx, 'badge-light': activeTabId() !== idx}">{{item.numRow}}</span>
    </a>
</li>
