import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as weekday from 'dayjs/plugin/weekday';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as minMax from 'dayjs/plugin/minMax';
import { LocaliserService } from './localiser.service';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';


@Injectable({
  providedIn: 'root'
})
export class DayjsService {
  dayjs: any;

  constructor(private localiser: LocaliserService) {
  }

  dayJsInit() {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(isBetween);
    dayjs.extend(objectSupport);
    dayjs.extend(weekday);
    dayjs.extend(minMax);
    dayjs.extend(quarterOfYear)
    dayjs.extend(customParseFormat)
    dayjs.extend(isSameOrBefore)
    dayjs.extend(isSameOrAfter)

    dayjs.tz.setDefault(this.localiser.timezone);
    this.dayjs = dayjs;
  }
}
