// to add a new PRODUCT_TYPE, please update the featureConfigs & setMenu func accordingly
export enum PRODUCT_TYPES {
  LIBRARY = 'Ausmed Library',
  LMS = 'Ausmed LMS Premium',
  HIPPOTLIAN = 'Hippotlian LMS',
  LMS_FREE = 'Ausmed LMS Free',
  CONTENT_PARTNER = 'Content Partner',
}

export enum MENU_TYPES {
  ADMIN = 'Admin',
  LEARNING = 'Learning',
  LIBRARY = 'Library',
  PP = 'Policies',
  SETTINGS = 'Settings',
  SHORTCUTS = 'ShortCuts',
  ANALYTICS = 'Analytics',
  PERFORMANCE = 'Performance'
}

