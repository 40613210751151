<div class="m-mandatory-record-container mb-4">
  <div class="m-learning-record-title-container">
    <p class="m-label mb-1">Item Title</p>
    <p class="m-title-text-style mb-4">{{ mandatoryRecord?.resourceBrief?.resourceTitle || mandatoryRecord?.resource?.resourceTitle || mandatoryRecord?.resource?.title || '-'}}</p>
    <p class="m-label mb-1">User</p>
    <div class="d-flex align-items-center mb-4 cursor-pointer" (click)="jumpToNewTab()">
      <ui-avatar size="md" [fullName]="user.fullName" [url]="user.profilePicURL" class="me-3"></ui-avatar>
      <div>
        <p class="m-user-name">{{user.fullName || '-'}}</p>
        <span class="m-user-email">{{user.email || '-'}}</span>
      </div>
    </div>
    <p class="m-label mb-1">Status</p>
    <div class="col d-flex justify-content-start align-items-center mb-4">
      <span class="badge {{complianceStatusBadgeStyle}}">{{mandatoryRecord.status || '-'}}</span>
    </div>
  </div>

  <div>
    <!-- record details -->
    <div class="m-sidepanel-section pt-4">
      <p class="fw-semibold f-paragraph mb-2">Details</p>
      <p class="m-label mb-2 d-flex align-items-center">Source:   <training-plan-display-link [value]="mandatoryRecord.mtPlanCell"></training-plan-display-link></p>
      <p class="m-label mb-2">Assignment Type: <span  class="m-detail-content">{{ mandatoryRecord.complianceType || '-'}}</span></p>
      <p class="m-label mb-2">Open Date: <span class="m-detail-content">{{ dateFormat(mandatoryRecord.assignDate) || '-' }}</span></p>
      <p class="m-label mb-2">Due Date: <span class="m-detail-content">{{ dateFormat(mandatoryRecord.dueDate) || '-' }}</span></p>
      <p class="m-label mb-2">Overdue End Date: <span  class="m-detail-content">{{ dateFormat(mandatoryRecord.lateRecogDate) || '-' }}</span></p>
      <p class="m-label mb-2">Date Completed: <span  class="m-detail-content">{{ dateFormat(mandatoryRecord.completion?.completeDate)  || '-' }}</span></p>
      <p class="m-label mb-2">Days Overdue: <span  class="m-detail-content">{{ mandatoryRecord.daysOverdue || 0 }}</span></p>
      <p class="m-label mb-2">Days Extended: <span  class="m-detail-content">{{ mandatoryRecord.daysExtended || 0 }}</span></p>
      <p class="m-label mb-2">Number of Extensions Given: <span  class="m-detail-content">{{ mandatoryRecord.extensions ? mandatoryRecord.extensions.length : 0}}</span></p>
    </div>
  </div>
</div>
