<div [style]="'margin-top: ' + topMargin + 'px;'">
  <div class="m-requirements-type p-4" *ngIf="mandatoryNum">
    <!-- Mandatory records -->
    <h3 class="fw-semibold mb-2">Mandatory Requirements</h3>
    <ng-container *ngFor="let mandatoryRecord of mandatoryRecords">
      <app-mandatory-record-section [mandatoryRecord]="mandatoryRecord"></app-mandatory-record-section>
    </ng-container>
  </div>

  <div class="m-requirements-type pt-4 px-4" *ngIf="optionalNum">
    <!-- Optional records -->
    <h3 class="fw-semibold mb-2">Optional Requirements</h3>
    <ng-container *ngFor="let optionalRecord of optionalRecords">
      <app-mandatory-record-section [mandatoryRecord]="optionalRecord"></app-mandatory-record-section>
    </ng-container>
  </div>
</div>
