import * as dayjs from "dayjs";
import { IPerformanceReviewAssign, TASK_ASSIGN_STATUS_ENUM } from "../performance-review.service";

export function getTaskStatusDisplay(task: IPerformanceReviewAssign) {
    if (task.status === TASK_ASSIGN_STATUS_ENUM.completed) {
        return TASK_ASSIGN_STATUS_ENUM.completed;
    }
    const today = dayjs().format('YYYY-MM-DD');
    return today < task.openDate ? TASK_ASSIGN_STATUS_ENUM.scheduled
        : today > task.dueDate ? TASK_ASSIGN_STATUS_ENUM.overdue
        : TASK_ASSIGN_STATUS_ENUM.open
}